import { TypographyProps } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { lang } from "helpers/common/lang";
import { IntlMessageID } from "helpers/enum";

type IErrorMsg = {
  text?: string;
  messageId?: IntlMessageID;
  show: boolean;
} & TypographyProps;

export function ErrorMsg(props: IErrorMsg) {
  const text = props?.messageId ? lang(props.messageId) : props.text;
  return (
    <>
      {props.show && text ? (
        <SLTypography
          variant="validationError"
          tabIndex={0}
          title={text}
          aria-aria-label={text}
          aria-errormessage="true"
          {...props}
        >
          {text}
        </SLTypography>
      ) : null}
    </>
  );
}
