import { Container } from "@mui/material";
import MyListMediaDetail from "components/list/listDetail";
import MeditationListDetail from "components/list/meditationListDetail";

const ListItemDetail = () => {
  return (
    <Container
      className="main-container p-relative"
      sx={{
        minHeight: "80vh",
        width: { xl: "93.2%", lg: "95%" },
      }}
      maxWidth={false}
    >
      {location.pathname.includes("/app/my-list/media") ? (
        <MyListMediaDetail />
      ) : (
        <MeditationListDetail />
      )}
    </Container>
  );
};

export default ListItemDetail;
