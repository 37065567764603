/* eslint-disable */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLStorage } from "utils/slStorage";

export const ShareMeditation = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const auth = SLStorage.getItem("auth");
  const guestAuth = SLStorage.getItem("guestAuth");
  const dispatch = useDispatch();
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);

  useEffect(() => {
    if (auth) {
      dispatch(SLThunk.getSubscription.call());
    } else {
      if (guestAuth) {
        SLStorage.removeItem("guestAuth");
        SLStorage.removeItem("guestLogin");
      }
      navigate("/login");
    }
  }, []);

  useEffect(() => {
    if (userSubscription) {
      const meditationId = queryParams.get("meditationId");
      const mediaId = queryParams.get("mediaId");
      const noteId = queryParams.get("noteId");
      if (meditationId) {
        saveSharedMeditation(parseInt(meditationId));
      } else if (mediaId) {
        navigate("/app/media-player/" + mediaId);
      } else if (noteId) {
        navigate("/app/my-notes/" + noteId);
      }
    } else {
      navigate("/subscription-activation");
    }
  }, [userSubscription]);

  const saveSharedMeditation = async (meditationId: number) => {
    const makeCallObj: any = {
      method: "post",
      url: "/meditation/shared-meditation/" + meditationId,
    };
    const response: any = await makeSLAPICall(makeCallObj);
    if (response) {
      navigate("/app/play-back/" + response?.id);
    }
  };
  return <></>;
};
