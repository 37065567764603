import ResetTutorial from "components/helpCenterScreens/tutorial/resetTutorial";
import HelpCenterPages from "../../../layouts/helpPages";

const Tutorial = () => {
  return (
    <HelpCenterPages title="Reset Tutorial">
      <ResetTutorial />
    </HelpCenterPages>
  );
};

export default Tutorial;
