import { Dispatch } from "react";
import { Auth } from "./authService";
import { SLThunk } from "./slRTKHelper";
import { SLStorage } from "./slStorage";

export function onNewLogin(dispatch: Dispatch<any>) {
  resetStates(dispatch);

  //
  dispatch(SLThunk.getConfig.call());
  dispatch(SLThunk.getDailyInspirationGetTodaysQuote.call());
  dispatch(SLThunk.getSubscription.call());
  dispatch(SLThunk.getUserProfile.call());
}

export function onLogout(dispatch: Dispatch<any>) {
  Auth.user.logout();
  Auth.guest.logout();

  resetStates(dispatch);
  dispatch(SLThunk.getUserProfile.reset());
  dispatch(SLThunk.getSubscription.reset());
  SLStorage.removeItem("selectedMeditationDataCreate");
}

export function resetStates(dispatch: Dispatch<any>) {
  dispatch(SLThunk.deleteUser.reset());
  dispatch(SLThunk.deleteUserSocial.reset());
  dispatch(SLThunk.getFilters.reset());
  dispatch(SLThunk.getMediaByType.reset());
  dispatch(SLThunk.getMeditationId.reset());
  dispatch(SLThunk.getMeditationMyMeditations.reset());
  dispatch(SLThunk.patchUserUpdateProfile.reset());
  dispatch(SLThunk.postAuthGuestLogin.reset());
  dispatch(SLThunk.postAuthLoginWeb.reset());
  dispatch(SLThunk.postAuthLoginSocial.reset());
  dispatch(SLThunk.postAuthLogout.reset());
  dispatch(SLThunk.postAuthRegister.reset());
  dispatch(SLThunk.postAuthRegisterSocial.reset());
  dispatch(SLThunk.postFileUploadUserimageSocial.reset());
  SLStorage.removeItem("meditationSessionId");
}
