import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@mui/material";
import { SLTypography } from "components/slTypography";

const HowItWorksCard = (props: any) => {
  return (
    <>
      <Card
        sx={{
          maxWidth: { xs: "auto", lg: "440px", xl: "100%" },
          boxShadow: "none",
          borderRadius: "10px",
        }}
      >
        <CardActionArea tabIndex={-1}>
          <CardMedia
            component="img"
            sx={{ height: "280px" }}
            image={props.imageSrc}
            alt={props.altText}
            tabIndex={0}
          />
          <CardContent sx={{ minHeight: "120px", padding: "30px" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start",
                gap: "10px",
                paddingBottom: "10px",
              }}
            >
              <Box
                component="img"
                sx={{
                  height: "30px",
                  width: "30px",
                }}
                tabIndex={0}
                aria-label="Icon"
                src={props.iconImage}
              />
              <SLTypography
                tabIndex={0}
                variant="heading"
                color="text-deepPurple"
                sx={{
                  letterSpacing: "-.3px",
                  fontSize: { xs: "22px", sm: "25px", md: "27px" },
                }}
                role="heading"
              >
                {props.title}
              </SLTypography>
            </Box>
            <SLTypography
              tabIndex={0}
              variant="smallBody"
              sx={{ color: "var(--sl-deep-purple)" }}
            >
              {props.discription}
            </SLTypography>
          </CardContent>
        </CardActionArea>
      </Card>
    </>
  );
};

export default HowItWorksCard;
