import { PageHeading } from "components/pageHeading";
import { RemindersScreen } from "components/profile/reminders";
import { lang } from "helpers/common/lang";
import ProfilePages from "layouts/profilePages";

const Reminder = () => {
  return (
    <>
      <ProfilePages title={lang("profile.meditationReminders")}>
        <>
          <PageHeading messageId="profile.meditationReminders" />

          <RemindersScreen />
        </>
      </ProfilePages>
    </>
  );
};

export default Reminder;
