import { Skeleton, Stack } from "@mui/material";

interface IParagraphSkeleton {
  lines?: number;
  fontSize?: number;
}

export function ParagraphSkeleton(props: IParagraphSkeleton) {
  return (
    <Stack direction="column" gap="5px">
      {Array.from(
        {
          length: props?.lines && props?.lines > 0 ? props.lines : 3,
        },
        (_, _i) => (
          <Skeleton
            key={_i}
            sx={{
              transform: "unset",
            }}
            animation="wave"
            height={!Number.isNaN(props.fontSize || NaN) ? props.fontSize : 17}
          />
        )
      )}
    </Stack>
  );
}
