import { MenuItem, TextField } from "@mui/material";
import { useState } from "react";
import "./index.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

interface ISelectOption {
  selectId: string;
  placeholder: string;
  value: string;
  setValue(value: string): void;
  valueChanged(value: boolean): void;
  options: {
    value: string;
    label: string;
  }[];
}

const SelectOption = (props: ISelectOption) => {
  const [selectedOption, setSelectedOption] = useState(props.value);

  const handleChange = (e: any) => {
    const current = e.target.value;

    props.valueChanged(current !== selectedOption);
    setSelectedOption(current);
    props.setValue(current);
  };

  return (
    <>
      <TextField
        select
        className={"sl-input sl-input2 "}
        SelectProps={{ MenuProps }}
        value={selectedOption}
        label={props.placeholder}
        aria-label="press key to open drop down"
        title="press key to open drop down"
        onChange={handleChange}
      >
        {props.options.map((option, i) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </>
  );
};

export default SelectOption;
