import { PsychologyOutlined } from "@mui/icons-material";
import { Avatar, Box, List, ListItem, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  ArrowForwardIosIcon,
  CeoImage,
  ForumOutlined,
  SupernalWorldIcon,
} from "helpers/enum/constants";
import { useNavigate } from "react-router-dom";

const profileLinks = [
  {
    iconButton: <PsychologyOutlined sx={{ width: "18px", height: "18px" }} />,
    name: "How it Works",
    redirectionLink: "/app/how-it-works",
  },
  {
    iconButton: (
      <Box
        component="img"
        className="purple-icon"
        src={SupernalWorldIcon}
        alt="/"
        sx={{ width: "18px", height: "18px" }}
      />
    ),
    name: "About Supernal World Creations",
    redirectionLink: "/app/supernal-world-creation",
  },
  {
    iconButton: <ForumOutlined sx={{ width: "18px", height: "18px" }} />,
    name: "Contact Us",
    redirectionLink: "/app/contact-us",
  },
];

const InfoAboutCeo = () => {
  const navigate = useNavigate();

  return (
    <>
      <Box>
        <Stack>
          <Avatar
            tabIndex={0}
            alt="image of Dr. Dawn Ella"
            src={CeoImage}
            sx={{
              width: { xs: "90px", sm: "120px" },
              height: { xs: "90px", sm: "120px" },
              marginTop: "30px",
              marginBottom: { xs: "15px", sm: "20px" },
            }}
          />
          <SLTypography
            tabIndex={0}
            variant="heading"
            sx={{
              paddingBottom: "15px",
              letterSpacing: "-.3px",
              fontSize: { xs: "19px", sm: "27px" },
            }}
            role="heading"
          >
            Dr. Dawn Ella – President, Inventor & Creative Director
          </SLTypography>
          <SLTypography
            tabIndex={0}
            variant="smallBody"
            sx={{
              paddingTop: "12px",
              paddingBottom: "50px",
              fontSize: { xs: "14px", sm: "17px" },
            }}
          >
            Dr. Dawn Ella, is a Soul Physician, Doctor of Divinity, Doctor of
            Metaphysics, and Ordained Minister, who embarked on a profound
            journey inspired by the birth of her son Michael. Then, 10 years
            later in 2006, she left this world (the result of a near-death
            experience) and returned with an undeniable gift for understanding
            the higher vibrations of the Soul. Guided by divine wisdom, she
            recognized the programmable nature of the young mind and discovered
            that the most influential perceptual programming occurs from birth
            through age six. Harnessing her own near-death experience and
            subsequent gift for understanding the higher vibrations of the Soul,
            Dr. Ella shares her transformative insights in the memoir "The
            Energy Tree, The Reflection." Through her work as a Soul Physician
            and Divine Teacher, she dedicates herself to removing soul blockages
            and mentoring individuals through her business, The Soul Medic. In
            2019, she established the non-profit organization Supernal World
            Creations, dedicated to improving the health and well-being of
            individuals, families, and communities through humanitarian
            innovations. Grounded in extensive research since 1996, Soul Link,
            is an audiovisual stimulation-based application, that offers a
            unique method for personal growth and transformation, accessible to
            all, irrespective of race, religion, gender, or nationality.
          </SLTypography>
          <List
            sx={{
              //   width: { lg: "100%", md: "100%", xs: "100%" },
              width: 1,
            }}
          >
            {profileLinks.map((data: any, index) => {
              return (
                <>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {/* <Link
                      key={"sidebaritem_id" + index}
                      to={data.redirectionLink}
                      style={{ textDecoration: "none", width: "100%" }}
                      tabIndex={-1}
                    > */}
                    <ListItem
                      //   onClick={() => handleListItems(data)}
                      key={index}
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                        borderRadius: "10px",
                        background: "#FFFFFF",
                        cursor: "pointer",
                        color: "var(--sl-deep-purple)",
                        height: "50px",
                        textDecoration: "none",
                      }}
                      tabIndex={0}
                      onClick={() => {
                        navigate(data.redirectionLink);
                      }}
                      onKeyPress={() => {
                        navigate(data.redirectionLink);
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px",
                          fontSize: { xs: "14px" },
                        }}
                      >
                        {data.iconButton}

                        <SLTypography
                          variant="title"
                          sx={{ fontSize: { xs: "14px" } }}
                        >
                          {data.name}
                        </SLTypography>
                      </Box>
                      <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
                    </ListItem>
                    {/* </Link> */}
                  </Box>
                </>
              );
            })}
          </List>
        </Stack>
      </Box>
    </>
  );
};

export default InfoAboutCeo;
