import { Typography, TypographyProps } from "@mui/material";
import { IntlMessageID } from "helpers/enum";
import { FormattedMessage } from "react-intl";
import "./index.css";

declare module "@mui/material/styles" {
  interface TypographyVariants {
    heading: React.CSSProperties;
    largeHeader: React.CSSProperties;
    headingLarge: React.CSSProperties;
    subHeading: React.CSSProperties;
    title: React.CSSProperties;
    body: React.CSSProperties;
    bodyUnderlined: React.CSSProperties;
    bodyParagraph: React.CSSProperties;
    smallBody: React.CSSProperties;
    validationError: React.CSSProperties;
    smallBodyUnderLined: React.CSSProperties;
    extraSmall: React.CSSProperties;
    smallFooter: React.CSSProperties;
    extraSmallUnderlined: React.CSSProperties;
    //home slides
    homeParagraphText: React.CSSProperties;
    mediumHeading: React.CSSProperties;
    subheadLight: React.CSSProperties;
    mediaSubHeading: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    largeHeader?: React.CSSProperties;
    heading?: React.CSSProperties;
    headingLarge?: React.CSSProperties;
    subHeading?: React.CSSProperties;
    title?: React.CSSProperties;
    body?: React.CSSProperties;
    bodyUnderlined?: React.CSSProperties;
    bodyParagraph?: React.CSSProperties;
    smallBody?: React.CSSProperties;
    validationError: React.CSSProperties;
    smallBodyUnderLined?: React.CSSProperties;
    smallFooter?: React.CSSProperties;
    extraSmall?: React.CSSProperties;
    extraSmallUnderlined?: React.CSSProperties;
    //home slide text
    homeParagraphText?: React.CSSProperties;
    mediumHeading?: React.CSSProperties;
    subheadLight?: React.CSSProperties;
    mediaSubHeading?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    heading: true; // headLine
    headingLarge: true; // largeHeader
    subHeading: true; // subHead
    mediaSubHeading: true;
    title: true; // buttonOrTitle
    headLine: true;
    body: true;
    subhead: true;
    bodyUnderlined: true;
    bodyParagraph: true;
    verificationDialog: true;
    smallBody: true;
    smallBodyCopy: true;
    validationError: true;
    smallBodyUnderLined: true;
    smallFooter: true;
    bodyCopy: true;
    extraSmall: true;
    extraSmallBody: true;
    extraSmallUnderlined: true;
    largeHeader: true;
    subheadLight: true;
    h1: false;
    h2: false;
    h3: false;
    h4: false;
    h5: false;
    h6: false;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    //home slide text
    homeParagraphText: true;
    mediumHeading: true;
  }
}

type SLTypographyProps = {
  messageId?: IntlMessageID;
} & TypographyProps;

export const SLTypography = ({
  messageId,
  children,
  variant,
  color,
  ...props
}: SLTypographyProps) => {
  return (
    <Typography
      {...props}
      variant={variant}
      className={`sl-${variant} ${color}`}
    >
      {messageId ? <FormattedMessage id={messageId} /> : children}
    </Typography>
  );
};
