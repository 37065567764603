import {
  Box,
  InputAdornment,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { onEnterOrSpace } from "helpers/common/keypress";
import { CrossIcon, SearchIcon } from "helpers/enum/constants";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import {
  FaqCategories,
  Faqs,
  SLPaginationResponse,
} from "utils/slRTKHelper/slAPI.models";
import FaqAccordion from "../../faqAccordion";
import Loadmore from "../../loadmore";
import { PageHeading } from "../../pageHeading";
import { PillsSkeleton } from "../../skeletonUI/PillsSkeleton";
import { InputStandardField } from "../../slTextField";
import { NotFoundMessage } from "../../slTypography/NotFoundMessage";
import "./index.css";

const FaqScreen = () => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const timerRef = useRef<any>();

  // FAQ data states
  const [faqsData, setFaqsData] = useState<SLPaginationResponse<Faqs>>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [ariaLive, setAriaLive] = useState<any>(undefined);

  // FAQ filter states
  const [searchText, setSearchText] = useState<string>("");
  const [selectedCategory, setSelectedCategory] = React.useState<
    "all" | number
  >("all");

  // FAQ Categories data states
  const [faqCategoriesData, setFaqCategoriesData] = useState<FaqCategories[]>(
    []
  );

  // Selectors
  const { data, loading, error } = useSelector(SLSelector.getFaq);
  const {
    data: dataFaqCat,
    loading: loadingFaqCat,
    error: errorFaqCat,
  } = useSelector(SLSelector.getFaqCategory);

  const handleCategoryChange = (
    event: React.MouseEvent<HTMLElement>,
    categoryId: "all" | number
  ) => {
    setFaqsData(undefined);
    setSelectedCategory(categoryId);
    setSearchText("");
    getFaqs({ categoryId, search: "", page: 1 });

    if (categoryId != "all") {
      const categoryTitle =
        faqCategoriesData.find((r: any) => r.id == categoryId)?.title ||
        String(categoryId);

      mixpanel.triggerTagUsedEvent("FAQ_FRAGMENT", categoryId, categoryTitle);
    }
  };

  const loadMoreFAQs = () => {
    getFaqs({ page: currentPage + 1 });
  };

  const handleSearch = (e: any) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setSearchText(e.target.value);
    timerRef.current = setTimeout(() => {
      getFaqs({ search: e.target.value, page: 1 });
    }, 1000);
  };

  const getFaqs = (props?: {
    search?: string | null;
    page?: number;
    categoryId?: "all" | number;
  }) => {
    const data: any = {
      page: currentPage,
      limit: 10,
      categoryId: selectedCategory,
      search: searchText,
      sortBy: "createdAt",
      sort: "DESC",
    };

    Object.assign(data, props);

    if (data?.categoryId && data?.categoryId == "all") {
      delete data.categoryId;
    }

    data.search = data.search.trim() || "";
    if (!data?.search) {
      delete data.search;
    }

    setCurrentPage(data.page);

    dispatch(SLThunk.getFaq.call(data));
  };

  //Manage UseEffects
  useEffect(() => {
    getFaqs();
    dispatch(SLThunk.getFaqCategory.call({}));
  }, []);

  useEffect(() => {
    if (data?.data && Array.isArray(data?.data)) {
      if (currentPage === 1) {
        setFaqsData(data);
      } else {
        const appendedData = {
          ...data,
          data: [...faqsData!.data, ...data.data],
        };
        setFaqsData(appendedData);
      }
    }

    if (data && searchText) {
      mixpanel.triggerSearchEvent("FAQ_FRAGMENT", searchText);
    }

    setAriaLive(
      `${Number(data?.totalCount) === 0 ? "No" : Number(data?.totalCount)} ${
        data?.totalCount === 1 ? "f a q" : "f a qs"
      } found`
    );
  }, [data]);

  useEffect(() => {
    if (dataFaqCat) {
      setFaqCategoriesData([{ id: "all", title: "All" }, ...dataFaqCat] as any);
    }
  }, [dataFaqCat]);
  const clearSearch = () => {
    setFaqsData(undefined);
    setSearchText("");
    getFaqs({ search: "", page: 1 });

    const searchEl = document.getElementById("searchField") as HTMLInputElement;
    if (searchEl) {
      searchEl.focus();
    }
  };
  return (
    <Box
      id="faqs-page"
      sx={{
        minHeight: { xs: "auto", md: "60vh", lg: "68vh" },
        paddingTop: { lg: "0px", xs: "32px" },
      }}
    >
      <PageHeading messageId={"faqsPage.title"} />

      <Search
        searchValue={searchText}
        handleSearch={handleSearch}
        clearSearch={clearSearch}
      />

      <FaqCategoriesList
        loading={loadingFaqCat}
        data={faqCategoriesData}
        category={selectedCategory}
        handleCategoryChange={handleCategoryChange}
      />

      {loading ? (
        <FaqAccordion loading={loading} />
      ) : !error && faqsData && faqsData!.totalCount > 0 ? (
        <>
          <FaqAccordion faqs={faqsData} />

          <Loadmore
            handleLoadmore={loadMoreFAQs}
            data={faqsData}
            focusElementId="searchField"
          />
        </>
      ) : (
        faqsData !== undefined && (
          <NotFoundMessage notFoundMessage={`No FAQs Found`} />
        )
      )}

      <div
        aria-live="assertive"
        style={{ height: 0, width: 0, overflow: "hidden" }}
        dangerouslySetInnerHTML={{ __html: ariaLive || "" }}
      />
    </Box>
  );
};
const Search = ({ searchValue, handleSearch, clearSearch }: any) => (
  <InputStandardField
    className="search-input-full-width"
    id="searchField"
    type="text"
    variant="standard"
    placeholderId="Search FAQ"
    value={searchValue}
    props={{ className: "search-input placeholder-light-text" }}
    inputProps={{
      disableUnderline: true,
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      ),
      endAdornment: (
        <InputAdornment
          sx={{ display: searchValue !== "" ? "" : "none" }}
          position="start"
        >
          <CrossIcon
            role="button"
            tabIndex={0}
            titleAccess="Clear Search"
            onClick={clearSearch}
            onKeyPress={onEnterOrSpace(clearSearch)}
            sx={{ cursor: "pointer" }}
          />
        </InputAdornment>
      ),
      onChange: (e: any) => handleSearch(e),
      // tabIndex: 0,
    }}
  />
);

const FaqCategoriesList = ({
  category,
  handleCategoryChange,
  data,
  loading,
}: any) => (
  <Stack
    className="deeppurple-toggle"
    spacing={1}
    sx={{
      overflowX: "auto",
      scrollbarWidth: "none",
      "&::-webkit-scrollbar": { height: "5px" },
      "&::-webkit-scrollbar-track": { display: "none" },
    }}
    marginTop="10px"
    borderBottom="1px solid var(--colorLightGray)"
  >
    <ToggleButtonGroup
      className="deeppurple-toggle"
      value={category}
      exclusive
      onChange={handleCategoryChange}
      sx={{
        gap: 1,
        paddingBottom: { lg: "", xs: "20px" },
      }}
      aria-label="FAQ category"
    >
      {loading ? (
        <PillsSkeleton />
      ) : (
        data &&
        data.map((tag: any, index: number) => (
          <ToggleButton
            key={"faq_catid" + index}
            value={tag.id}
            aria-label={tag.title}
            tabIndex={0}
          >
            {tag.title}
          </ToggleButton>
        ))
      )}
    </ToggleButtonGroup>
  </Stack>
);

export default FaqScreen;
