import { Box } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  EmptyHeart,
  FillHeart,
  VideoPlayMusicWhiteIcon,
} from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import { MiniplayerContext } from "../../contexts/miniplayerContext";
import ErrorDialogBox from "../dialogBoxes/errorDialog";

export const Miniplayer = () => {
  const dispatch = useDispatch();
  const appUrls = useAppUrls();
  const { showMiniplayer, miniplayerState, setMiniplayerState } =
    useContext(MiniplayerContext);

  const guestLogin = SLStorage.getItem("guestLogin");
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);

  const link = `/app/play-back/${miniplayerState?.track?.id}?resume=${miniplayerState?.currentTime}`;
  const [guestErrorMessage, setGuestErrorMessage] = useState<any>("");
  const [guestError, setGuestError] = useState(false);

  const FavouriteItem = () => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return;
    }

    if (miniplayerState?.track) {
      miniplayerState.track.isLikedByMe = true;
      setMiniplayerState(miniplayerState);
      dispatch(
        SLThunk.postMeditationAddFavourites.call({
          meditationId: miniplayerState.track.id,
        })
      );
    }
  };

  const RemoveFavouriteItem = () => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return;
    }

    if (miniplayerState?.track) {
      miniplayerState.track.isLikedByMe = false;
      setMiniplayerState(miniplayerState);
      dispatch(
        SLThunk.deleteMeditationFavouritesId.call({
          id: String(miniplayerState.track.id),
        })
      );
    }
  };

  useEffect(() => {
    if (guestLogin) {
      setGuestErrorMessage({
        errorHeading: "Oops! You Need to Log In",
        errorBody:
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed.",
      });
    } else {
      if (!userSubscription) {
        setGuestErrorMessage({
          errorHeading: "Oops! This is for Subscribers Only",
          errorBody:
            "Elevate your meditation practice with our premium features. Join Soul Link today to unlock personalized playlists, guided meditations, and more.",
        });
      }
    }
  }, [userSubscription]);

  return (
    <>
      {showMiniplayer && miniplayerState?.track ? (
        <Box
          sx={{
            position: "fixed",
            bottom: 0,
            right: 0,
            left: 0,
            backgroundColor: "white",
            zIndex: 5,
            boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
            borderRadius: "10px 10px 0px 0px",
            display: "flex",
            height: "80px",
            overflow: "hidden",
          }}
        >
          <Box sx={{ position: "relative", cursor: "pointer" }}>
            <Link to={link} style={{ textDecoration: "none" }}>
              <img
                src={appUrls.mediaImage(miniplayerState.track.thumbnail)}
                style={{
                  aspectRatio: "1",
                  height: "80px",
                  objectFit: "cover",
                  objectPosition: "left",
                }}
              />
              <img
                src={VideoPlayMusicWhiteIcon}
                style={{
                  position: "absolute",
                  top: "22px",
                  left: "22px",
                  aspectRatio: "1",
                  height: "35px",
                }}
              />
            </Link>
          </Box>
          <Box sx={{ width: "100%", padding: "22px" }}>
            <Link to={link} style={{ textDecoration: "none" }}>
              <SLTypography
                tabIndex={0}
                variant="title"
                color="text-deepPurple"
                display="block"
              >
                {miniplayerState.track.title}
              </SLTypography>
            </Link>
            <SLTypography
              tabIndex={0}
              variant="extraSmallBody"
              color="text-deepPurple"
              sx={{
                display: "inline-block",
                marginTop: "5px",
                marginRight: "10px",
              }}
            >
              {miniplayerState.currentTime} / {miniplayerState.totalTime}
            </SLTypography>
            <Link to={link} style={{ textDecoration: "none" }}>
              <SLTypography
                tabIndex={0}
                variant="extraSmallBody"
                color="text-light"
                sx={{
                  ":hover": {
                    textDecoration: "underline",
                  },
                }}
              >
                Resume Meditation
              </SLTypography>
            </Link>
          </Box>
          <Box sx={{ padding: "20px 23px", paddingRight: "80px" }}>
            {miniplayerState.track.isLikedByMe ? (
              <Box
                onClick={RemoveFavouriteItem}
                onKeyPress={RemoveFavouriteItem}
                sx={{ padding: "10px", cursor: "pointer" }}
              >
                <FillHeart className="text-deepPurple" sx={{ width: "20px" }} />
              </Box>
            ) : (
              <Box
                onClick={FavouriteItem}
                onKeyPress={FavouriteItem}
                sx={{ padding: "10px", cursor: "pointer" }}
              >
                <EmptyHeart
                  className="text-deepPurple"
                  sx={{ width: "20px" }}
                />
              </Box>
            )}
          </Box>
        </Box>
      ) : null}

      <ErrorDialogBox
        errorDialog={guestError}
        setErrorDialog={setGuestError}
        errorMessage={guestErrorMessage.errorBody}
        errorHeaderMessage={guestErrorMessage.errorHeading}
      />
    </>
  );
};
