import {
  Box,
  FormControl,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Theme,
  useTheme,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { Formik } from "formik";
import { lang } from "helpers/common/lang";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import * as Yup from "yup";
import { SLButton } from "../../slButton";
import { InputStandardField } from "../../slTextField";
import { SLVerificationMessage } from "../../verificationMessage";
import "./index.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const names = [
  "Login Issue",
  "Subscription & Payment Problem",
  "Audio or Video Problem",
  "Feedback and Suggestions",
  "App Crashes",
  "Accessibility Issues",
  "Other",
];

function getStyles(name: string, personName: readonly string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const validationSchema = Yup.object().shape({
  name: Yup.string().required(lang("general.error.EmptyFields")),
  email: Yup.string()
    .trim()
    .matches(
      /^[a-z\._\d]{1,}@[a-z0-9]{2,}\.[a-z0-9]{2,}$/,
      "Invalid email address"
    )
    .required(lang("general.error.EmptyFields")),
  subject: Yup.string().required("Please select any subject"),
  message: Yup.string()
    .min(3, "The message is too Short.")
    .max(500, "The message is too Long.")
    .required(lang("general.error.EmptyFields")),
});

const ReportAProblem = () => {
  const theme = useTheme();
  const [subject, setSubject] = React.useState<string>("");
  const form = useRef<HTMLFormElement>(null!);
  // const { data: profileData } = useSelector(SLSelector?.getUserProfile);
  const { data: profileData, loading: userProfileLoading } = useSelector(
    SLSelector.getUserProfile
  );
  const [messageValue, setMessageValue] = useState("");
  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [sentSuccessfullyMessage, setSentSuccessfullyMessage] =
    useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  // const [initialValues, setInitialValues] = useState({
  //   name: "",
  //   email: "",
  //   subject: "",
  //   message: "",
  // });
  const initialValues = useRef({
    subject: "",
    email: "",
    name: "",
    message: "",
  });
  const dispatch = useDispatch();

  const handleChangeSelect = (event: SelectChangeEvent<typeof subject>) => {
    const {
      target: { value },
    } = event;
    setSubject(
      // On autofill we get a stringified value.
      // typeof value === "string" ? value.split(",") : value
      value
    );
    //   setInitialValues({ ...initialValues, subject: value.toString() });
  };
  useEffect(() => {
    // if (profileData) {
    //   setShowForm(true);
    //   setInitialValues({
    //     ...initialValues,
    //     name: profileData.fullName,
    //     email: profileData.email,
    //     subject: "",
    //   });
    // } else {
    //   setShowForm(true);
    //   setInitialValues({
    //     ...initialValues,
    //     name: "",
    //     email: "",
    //     subject: "",
    //   });
    // }
    if (!userProfileLoading) {
      if (profileData) {
        initialValues.current = {
          email: profileData.email,
          name: profileData.fullName,
          message: "",
          subject: "",
        };
        setShowForm(true);
      } else {
        setShowForm(true);
      }
    }
  }, [profileData]);

  const signInForm = async (values: any) => {
    const payLoad = {
      name: values.name,
      email: values.email,
      subject: values.subject,
      message: values.message,
    };
    // dispatch(SLThunk.postReport.call(payLoad));
    const makeCallObj: any = {
      method: "post",
      url: "/report",
      urlParams: "",
      payload: payLoad,
      params: "",
    };
    const response = await makeSLAPICall(makeCallObj);
    if (response) {
      setSentSuccessfullyMessage(true);

      // setOpenDialog(false);
    }

    setTimeout(() => {
      setSentSuccessfullyMessage(false);
      // setInitialValues({ ...initialValues, subject: "", message: "" });
      setSubject("");
      setMessageValue("");
    }, 2000);
  };

  const handleSubmit = async (values: any) => {
    signInForm(values);
  };

  return (
    <Box
      id="sending-feedback-component "
      className="report-message-parent-container-selector"
      sx={{
        minHeight: { xs: "auto", md: "60vh", lg: "68vh" },
        paddingTop: { lg: "0px", xs: "32px" },
      }}
    >
      <Stack>
        <SLTypography
          role="heading"
          tabIndex={0}
          variant="largeHeader"
          sx={{
            color: "var(--sl-deep-purple)",
            paddingBottom: { xs: "25px", sm: "30px", md: "40px" },
            fontSize: { xs: "25px", sm: "35px", lg: "37px" },
          }}
        >
          Report Problem
        </SLTypography>
        <SLTypography
          variant="smallBody"
          sx={{
            color: "var(--sl-deep-purple)",
            paddingBottom: { xs: "20px", sm: "25px" },
            fontSize: { xs: "14px", sm: "17px" },
          }}
          tabIndex={0}
        >
          Encountered an issue or experiencing difficulties? We apologize for
          any inconvenience. Your satisfaction is important to us, and we're
          here to help resolve any problems you may be facing with the Soul Link
          app. Please provide us with details about the issue you're
          encountering, and our dedicated support team will promptly investigate
          and provide a solution. Rest assured, we're committed to ensuring your
          seamless experience and are grateful for your feedback. Together,
          let's overcome any challenges and continue on your path of
          transcendence and well-being.
        </SLTypography>

        {/* text mail */}
        <div className="form-for-reporting-messege">
          {showForm && (
            <Formik
              initialValues={initialValues.current}
              // initialValues={initialValues}
              validationSchema={validationSchema}
              // onSubmit={await handleSubmit}
              onSubmit={async (values) => {
                await handleSubmit(values);
                // resetForm();
              }}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setErrors,
              }) => (
                <>
                  <form autoComplete="off" onSubmit={handleSubmit}>
                    <InputStandardField
                      props={{
                        className: "report-message-name-field",
                        inputProps: {
                          "aria-label":
                            "Name, " +
                            (touched.name && errors.name ? errors.name : ""),
                        },
                      }}
                      error={Boolean(touched.name && errors.name)}
                      onFocus={(e: any) => {
                        setErrors({ name: "" });
                      }}
                      onChange={(e: any) => {
                        handleChange(e);
                        setErrors({ name: "" });
                      }}
                      onBlur={() => {
                        handleBlur("name");
                      }}
                      // value={initialValues.name}
                      value={values.name}
                      id="name"
                      variant="filled"
                      labelId="Name"
                      type="text"
                    />
                    {touched.name && errors.name ? (
                      <SLTypography
                        aria-live="assertive"
                        sx={{
                          color: "var(--sl-deep-purple)",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                        }}
                      >
                        {errors.name}
                      </SLTypography>
                    ) : null}
                    <InputStandardField
                      props={{
                        className: "report-message-email-field",
                        inputProps: {
                          "aria-label":
                            "Email, " +
                            (touched.email && errors.email ? errors.email : ""),
                        },
                      }}
                      error={Boolean(touched.email && errors.email)}
                      onFocus={(e: any) => {
                        setErrors({ email: "" });
                      }}
                      onChange={(e: any) => {
                        handleChange(e);
                        setErrors({ email: "" });
                      }}
                      onBlur={() => {
                        handleBlur("email");
                      }}
                      value={values.email}
                      id="email"
                      variant="filled"
                      labelId="Email"
                      type="text"
                    />
                    {touched.email && errors.email ? (
                      <SLTypography
                        aria-live="assertive"
                        sx={{
                          color: "var(--sl-deep-purple)",
                          paddingBottom: "6px",
                          paddingLeft: "14px",
                        }}
                      >
                        {errors.email}
                      </SLTypography>
                    ) : null}
                    <Box sx={{ marginBottom: "10px" }}>
                      <div>
                        <FormControl
                          sx={{
                            width: "100%",
                          }}
                        >
                          <span id="selectName" style={{ display: "none" }}>
                            press key to open the subject's list
                          </span>
                          <Select
                            labelId="selectName"
                            id="subject"
                            type="text"
                            sx={{ borderRadius: "10px" }}
                            inputProps={{
                              "aria-label":
                                "Subject, " +
                                (touched.subject && errors.subject
                                  ? errors.subject
                                  : ""),
                            }}
                            displayEmpty
                            // value={personName}
                            onFocus={(e: any) => {
                              setErrors({ subject: "" });
                            }}
                            onChange={(e: any) => {
                              handleChangeSelect(e);
                              setErrors({ subject: "" });
                              values.subject = e.target.value;
                            }}
                            error={Boolean(touched.subject && errors.subject)}
                            // helperText={touched.message && errors.message}
                            value={subject}
                            onBlur={() => {
                              handleBlur("subject");
                              values.subject = subject;
                            }}
                            input={<OutlinedInput />}
                            renderValue={(selected) => {
                              if (selected.length === 0) {
                                return (
                                  <SLTypography
                                    variant="smallBody"
                                    sx={{
                                      color: "#514C54",
                                    }}
                                    tabIndex={-1}
                                    // title="subject"
                                  >
                                    Select Subject
                                  </SLTypography>
                                );
                              }

                              return selected.toString();
                            }}
                            MenuProps={MenuProps}
                          >
                            {names.map((name) => (
                              <MenuItem
                                key={name}
                                value={name}
                                style={getStyles(name, [subject], theme)}
                              >
                                <SLTypography
                                  tabIndex={0}
                                  variant="smallBody"
                                  sx={{
                                    color: "var(--sl-deep-purple)",
                                  }}
                                >
                                  {name}
                                </SLTypography>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      {touched.subject && errors.subject && !subject ? (
                        <SLTypography
                          aria-live="assertive"
                          sx={{
                            color: "var(--sl-deep-purple)",
                            paddingTop: "7px",
                            paddingLeft: "14px",
                          }}
                        >
                          {errors.subject}
                        </SLTypography>
                      ) : null}
                    </Box>
                    <Box>
                      <TextField
                        value={messageValue}
                        type="text"
                        id="message"
                        label="Message"
                        multiline
                        rows={5}
                        defaultValue=""
                        variant="filled"
                        className="contactFormField contact-us-message"
                        sx={{
                          width: "100%",
                          borderRadius: "10px !important",
                          fontSize: "17px",
                          fontWeight: "500",
                          marginRight: "8px",
                        }}
                        inputProps={{
                          "aria-label":
                            "Message, " +
                            (touched.message && errors.message
                              ? errors.message
                              : ""),
                        }}
                        onFocus={(e: any) => {
                          setErrors({ message: "" });
                        }}
                        onChange={(e: any) => {
                          handleChange(e);
                          setMessageValue(e.target.value);
                          setErrors({ message: "" });
                        }}
                        error={Boolean(touched.message && errors.message)}
                        onBlur={handleBlur("message")}
                      />
                      {touched.message && errors.message ? (
                        <SLTypography
                          aria-live="assertive"
                          sx={{
                            color: "var(--sl-deep-purple)",
                            paddingTop: "9px",
                            paddingLeft: "14px",
                          }}
                        >
                          {errors.message}
                        </SLTypography>
                      ) : null}
                    </Box>
                    <FormControl
                      variant="standard"
                      fullWidth
                      sx={{
                        paddingTop: "20px",
                        display: "flex",
                        justifyContent: { xs: "center", sm: "flex-start" },
                        alignItems: { xs: "center", sm: "flex-start" },
                      }}
                      onFocus={(e) => {
                        setErrors({});
                      }}
                    >
                      <SLButton
                        type="submit"
                        sx={{ width: { xs: "180px", sm: "200px" } }}
                        aria-label={
                          "Send Message, " +
                          (Object.values(errors).length > 0
                            ? Object.values(errors)[0]
                            : "")
                        }
                        messageId="general.sendMessage"
                      />
                    </FormControl>
                  </form>
                </>
              )}
            </Formik>
          )}
        </div>
      </Stack>

      <SLVerificationMessage
        open={sentSuccessfullyMessage}
        message="Message Sent"
      />
    </Box>
  );
};

export default ReportAProblem;
