/* eslint-disable prettier/prettier */
import { Box, Divider, Grid, Paper, Stack } from "@mui/material";
import Loadmore from "components/loadmore";
import { SLTypography } from "components/slTypography";
import dayjs from "dayjs";
import { deepLink } from "helpers/common/deeplLink";
import {
  MyNotesDelete,
  MyNotesEidit,
  MyNotesPlay,
  MyNotesShare,
} from "helpers/enum/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { webShareOpen } from "store/webShareSlice";
import { webLink } from "utils/firebase";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import {
  MediaTags,
  NotesMy,
  NotesReactions,
  SLPaginationResponse,
} from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";
import { SLLoader } from "../../loader";
import NotesAddUpdateModal from "../../notes/addUpdateModal";
import { ShowMoreText } from "../../showMoreText";
import { NotFoundMessage } from "../../slTypography/NotFoundMessage";
import ConfirmationDialog from "./confirmationDialog";
import NotesSkelton from "./skeltonView";

const MyJournalScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const guestLogin = SLStorage.getItem("guestLogin");

  const { data, loading: notesLoading } = useSelector(SLSelector.getNotesMy);
  const {
    data: deleteNoteData,
    error: deleteNoteError,
    loading: deleteNoteLoading,
  } = useSelector(SLSelector.deleteNotesId);
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);

  const [openMyJournalNotes, setOpenMyJournalNotes] = useState<boolean>(false);
  const [openConfirmationDialogue, setOpenConfirmationDialogue] =
    useState<boolean>(false);
  const [editNoteModalMediaData, setEditNoteModaMedialData] =
    useState<any>(null);
  const [editNoteModalData, setEditNoteModalData] = useState<any>(null);
  const [deleteNoteId, setDeleteNoteId] = useState<any>(null);

  const [notesListing, setNotesListing] =
    useState<SLPaginationResponse<NotesMy>>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [dynamicURLLoading, setDynamicURLLoading] = useState(false);
  const [ariaLive, setAriaLive] = useState<any>(undefined);

  useEffect(() => {
    if (guestLogin ) {
      navigate("/app/home");
    } else {
      getListing();
      dispatch(SLThunk.getFaqCategory.call({}));
    }
  }, []);

  const getListing = (props?: { page?: number }) => {
    setDynamicURLLoading(true);

    const data: any = {
      page: currentPage,
      limit: 5,
      sortBy: "updatedAt",
      sort: "DESC",
    };

    Object.assign(data, props);

    setCurrentPage(data.page);

    dispatch(SLThunk.getNotesMy.call(data));
  };

  const loadMoreListing = () => {
    getListing({ page: currentPage + 1 });
  };

  useEffect(() => {
    (async () => {
      if (data?.data && Array.isArray(data?.data)) {
        const notesWithLink = await setNotesWithLink(data);
        updateNotesListing(notesWithLink);
      }
      updateAriaLive(Number(data?.totalCount));
    })();
  }, [data]);

  const updateNotesListing = (notes: NotesMy[]) => {
    if (currentPage === 1) {
      setNotesListing({
        ...data,
        data: notes,
      } as any);
    } else {
      setNotesListing({
        ...data,
        data: [...notesListing!.data, ...notes],
      } as any);
    }
  };

  const setNotesWithLink = async (notes: any) => {
    try {
      if (Array.isArray(notes?.data) && notes?.data?.length > 0) {
        const updatedNotes = await Promise.all(
          notes.data.map(async (note: NotesMy) => {
            const noteURL = await getDynamicLinkUrl(note?.id);
            return {
              ...note,
              showMore: false,
              noteURL: noteURL,
            };
          })
        );
        setDynamicURLLoading(false);
        return updatedNotes;
      }
    } catch (error) {
      console.error("Error while setting notes with link:", error);
    }
    setDynamicURLLoading(false);
    return [];
  };

  const getDynamicLinkUrl = async (noteid: number) => {
    return await deepLink(`${webLink}note?noteId=${noteid}`);
  };

  const updateAriaLive = (totalCount: number) => {
    setAriaLive(
      `${totalCount === 0 ? "No" : totalCount} ${
        totalCount === 1 ? "note" : "notes"
      } found`
    );
  };

  const deleteNote = () => {
    setOpenConfirmationDialogue(false);
    if (deleteNoteId) {
      dispatch(SLThunk.deleteNotesId.call({ id: deleteNoteId }));
    }
  };

  useEffect(() => {
    if (deleteNoteData) {
      getListing({ page: 1 });
      dispatch(SLThunk.deleteNotesId.reset());
    }
  }, [deleteNoteData, deleteNoteError]);

  useEffect(() => {
    if (id) {
      getNoteDetails(parseInt(id));
    }
  }, [id]);

  const getNoteDetails = async (id: number) => {
    const response: any = await makeSLAPICall({
      method: "get",
      url: "/notes/{id}",
      urlParams: { id: String(id) },
    });
    if (response) {
      handleEditNotesModal(response);
    }
  };

  const handleEditNotesModal = (note: NotesMy) => {
    const mediaData = note?.media ?? note?.meditation;
    setEditNoteModalData(note);
    setEditNoteModaMedialData(mediaData);
    setOpenMyJournalNotes(true);
  };

  return (
    <>
      <span role="list" aria-label="My Journal Entries">
        {Array.isArray(notesListing?.data) && notesListing!.data?.length > 0
          ? notesListing?.data.map((note: NotesMy) => {
              return (
                <Paper
                  key={note.id}
                  role="listitem"
                  sx={{ marginBottom: { xs: "14px", borderRadius: "10px" } }}
                >
                  <Grid
                    container
                    sx={{ color: "var(--sl-deep-purple) !important" }}
                  >
                    <Grid
                      item
                      xs={12}
                      sx={{
                        paddingTop: "30px",
                        paddingRight: { xs: "16px", md: "30px" },
                        paddingLeft: { xs: "16px", md: "30px" },
                      }}
                    >
                      <Stack>
                        <SLTypography tabIndex={0} variant="extraSmallBody">
                          {dayjs(note?.updatedAt).format("LL")}
                        </SLTypography>
                        <SLTypography
                          tabIndex={0}
                          variant="subhead"
                          marginTop="5px"
                          role="heading"
                        >
                          {note?.media?.title ?? note?.meditation?.title ?? ""}
                        </SLTypography>
                      </Stack>
                      <Divider
                        sx={{
                          background: "#E7E7E7",
                          marginTop: "15px",
                          marginBottom: "15px",
                        }}
                      />

                      <ShowMoreText text={note?.description} lines={3} />

                      <Stack
                        role="list"
                        aria-label="Tags"
                        direction="row"
                        sx={{
                          paddingTop: "18px",
                          flexWrap: "wrap",
                          gap: "10px",
                        }}
                      >
                        {note?.tags?.length > 0 &&
                          note.tags.map((tag: MediaTags) => {
                            return (
                              <Box
                                key={tag.id}
                                role="listitem"
                                tabIndex={0}
                                sx={{
                                  width: "95px",
                                  height: "auto",
                                  padding: "5px",
                                  color: "var(--sl-deep-purple)",
                                  background: "white",
                                  border: "1px solid #CBC8CD ",
                                  borderRadius: "5px",
                                  display: "flex",
                                  alignItems: "center",
                                  textAlign: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <SLTypography variant="smallFooter"
                                  sx={{
                                    overflow: "hidden",
                                    display: "-webkit-box",
                                    WebkitBoxOrient: "vertical",
                                    WebkitLineClamp: 2,
                                    maxHeight: "2.4em", // Adjust this to control the number of lines
                                  }}
                                >
                                  {tag?.title ?? ""}
                                </SLTypography>
                              </Box>
                            );
                          })}
                      </Stack>

                      <Stack
                        direction="row"
                        paddingTop={"10px"}
                        paddingBottom={"20px"}
                        sx={{ flexWrap: "wrap", gap: "10px" }}
                      >
                        {note?.reactions?.length > 0 &&
                          note.reactions.map((reaction: NotesReactions) => (
                            <Box
                              key={reaction.id}
                              sx={{
                                display: "flex",
                                fontSize: "32px",
                                background: "none",
                                border: "1px solid transparent",
                                borderRadius: "0px",
                                padding: "5px 5px",
                              }}
                            >
                              {reaction?.emoji}
                            </Box>
                          ))}
                      </Stack>
                      <Divider
                        sx={{
                          background: "#E7E7E7",
                        }}
                      />
                      <Stack direction="row">
                        <Box
                          sx={{
                            paddingBottom: "30px",
                            gap: "30px",
                            paddingTop: "10px",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <img
                            role="button"
                            tabIndex={0}
                            aria-label={`Edit ${
                              note?.media?.title ?? note?.meditation?.title
                            } journal`}
                            className="cursor-pointer"
                            onClick={() => handleEditNotesModal(note)}
                            onKeyPress={() => handleEditNotesModal(note)}
                            src={MyNotesEidit}
                          />
                          <img
                            onClick={() => {
                              dispatch(webShareOpen({ url: note?.noteURL }));
                            }}
                            role="button"
                            tabIndex={0}
                            aria-label={`Share ${
                              note?.media?.title ?? note?.meditation?.title
                            } journal`}
                            className="cursor-pointer"
                            src={MyNotesShare}
                          />
                          <img
                            role="button"
                            tabIndex={0}
                            aria-label={`Play ${
                              note?.media?.title ?? note?.meditation?.title
                            } `}
                            className="cursor-pointer"
                            onClick={() =>
                              navigate(
                                `/app/${
                                  note?.meditation?.id
                                    ? "play-back"
                                    : "media-player"
                                }/${note?.media?.id ?? note?.meditation?.id}`
                              )
                            }
                            src={MyNotesPlay}
                          />
                          <img
                            role="button"
                            tabIndex={0}
                            aria-label={`Delete ${
                              note?.media?.title ?? note?.meditation?.title
                            } journal`}
                            className="cursor-pointer"
                            onClick={() => {
                              setDeleteNoteId(note?.id);
                              setOpenConfirmationDialogue(true);
                            }}
                            onKeyPress={() => {
                              setDeleteNoteId(note?.id);
                              setOpenConfirmationDialogue(true);
                            }}
                            src={MyNotesDelete}
                          />
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Paper>
              );
            })
          : null}
        
        {!dynamicURLLoading && Array.isArray(notesListing?.data) && notesListing!.data?.length > 0
          ? <Loadmore
            handleLoadmore={loadMoreListing}
            data={notesListing}
            focusElementId="searchField"
          /> : null}
      </span>

      {notesLoading || dynamicURLLoading ? (
        <>
          <NotesSkelton />
          <NotesSkelton />
          <NotesSkelton />
        </>
      ) : null}

      {!notesLoading && data?.data?.length === 0 ? (
        <NotFoundMessage notFoundMessage={`No Journals`} />
      ) : null}


      <SLLoader open={deleteNoteLoading} />

      {openMyJournalNotes ? (
        <NotesAddUpdateModal
          setOpenMyJournalNotes={() => setOpenMyJournalNotes(false)}
          openMyJournalNotes={openMyJournalNotes}
          mediaData={editNoteModalMediaData}
          editNoteData={editNoteModalData}
          getNotesData={() => getListing({ page: 1 })}
          isDeepLink={id ? true : false}
        />
      ) : null}

      {openConfirmationDialogue ? (
        <ConfirmationDialog
          openConfirmationDialogue={openConfirmationDialogue}
          setOpenConfirmationDialogue={setOpenConfirmationDialogue}
          confirmAction={deleteNote}
        />
      ) : null}

      <div
        aria-live="assertive"
        style={{ height: 0, width: 0, overflow: "hidden" }}
        dangerouslySetInnerHTML={{ __html: ariaLive || "" }}
      />
    </>
  );
};

export default MyJournalScreen;
