import { getAuth } from "@firebase/auth";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import axios from "axios";
import AuthenticationLayout from "components/authenticationLayout";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import SubscriptionDialog from "components/dialogBoxes/subscriptionDialog";
import { SLLoader } from "components/loader";
import { SLButton } from "components/slButton";
import { SLTypography } from "components/slTypography";
import { ArrowBackIosNewIcon, DefaultUserImage } from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import "./index.css";

const UploadPicture: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appUrls = useAppUrls();

  const [openDialog, setOpenDialog] = useState(false);
  const [profileImage, setProfileImage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [imageUploading, setImageUploading] = useState(false);
  const [imageFile, setImageFile] = useState<any>(null);
  const [imageFileName, setImageFileName] = useState<string>();
  const [errorDialog, setErrorDialog] = useState(false);
  const [validationError, setValidationError] = useState("");

  // Manage Selectors
  const {
    data: userUpdateProfile,
    loading: loadingUserUpdateProfile,
    error: errorUserUpdateProfile,
  } = useSelector(SLSelector.patchUserUpdateProfile);
  const { data, loading } = useSelector(SLSelector.getUserProfile);
  const socialLoginImageUplaod = useSelector(
    SLSelector.postFileUploadUserimageSocial
  );

  useEffect(() => {
    if (data?.registerType !== "email") {
      const user = getAuth().currentUser;
      if (user?.photoURL) {
        setProfileImage(user?.photoURL);
      }
    }

    setProfileImage(appUrls.profilePic(data?.image));
  }, [data]);

  useEffect(() => {
    if (!imageUploading) {
      return;
    }
    if (userUpdateProfile) {
      dispatch(SLThunk.patchUserUpdateProfile.reset());
      navigate("/subscription-activation");
      setImageUploading(false);
    }
    if (errorUserUpdateProfile) {
      const validationError = errorUserUpdateProfile;
      setErrorDialog(true);
      setValidationError(validationError);
      setImageUploading(false);
    }
  }, [userUpdateProfile, errorUserUpdateProfile]);

  useEffect(() => {
    if (socialLoginImageUplaod?.data) {
      dispatch(
        SLThunk.patchUserUpdateProfile.call({
          image: socialLoginImageUplaod.data.fileName,
        })
      );
    }
  }, [socialLoginImageUplaod]);

  useEffect(() => {
    dispatch(SLThunk.getUserProfile.call());
  }, []);

  const handleImageChange = (e: any) => {
    setErrorMessage("");
    const [file] = e.target.files;
    const fileSize = (file.size / 1024 / 1024).toFixed(2);
    const fileName = file?.name || "";
    if (!fileName.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
      setErrorMessage("only *.jpg, *.jpeg, *.png files are allowed");
    } else if (parseInt(fileSize) > 10) {
      setErrorMessage("Maximum image size is 10 MB");
    } else {
      setProfileImage(URL.createObjectURL(file));
      setImageFile(file);
      setImageFileName(fileName);
      e.target.value = null;
    }
  };
  const handleKeyImageChange = (e: any) => {
    if (e.key === "Enter") {
      setErrorMessage("");
      const [file] = e.target.files;
      const fileSize = (file.size / 1024 / 1024).toFixed(2);
      const fileName = file?.name || "";
      if (!fileName.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
        setErrorMessage("Only *.jpg, *.jpeg, *.png files are allowed");
      } else if (parseInt(fileSize) > 10) {
        setErrorMessage("Maximum image size is 10 MB");
      } else {
        setProfileImage(URL.createObjectURL(file));
        setImageFile(file);
        setImageFileName(fileName);
        e.target.value = null;
      }
    }
  };

  // Manage Functions

  const handleSaveImage = async () => {
    if (data?.registerType !== "email") {
      setImageUploading(true);
      await dispatch(
        SLThunk.postFileUploadUserimageSocial.call({
          url: profileImage ?? "",
        })
      );
    }
    if (imageFile) {
      const image = new FormData();
      image.append("image", imageFile, imageFileName);
      // dispatch(
      //   SLThunk.postFileUploadUserimage(
      //     { image },
      //     {},
      //     { "Content-Type": "multipart/form-data" }
      //   )
      // );
      setImageUploading(true);
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/file-upload/userimage`,
          image
        )
        .then(function (response) {
          dispatch(
            SLThunk.patchUserUpdateProfile.call({
              image: response.data.result.fileName,
            })
          );
        })
        .catch(function (error) {
          setImageUploading(false);
          setErrorDialog(true);
          setValidationError(error);
        });
    }
  };

  const handleRemoveImage = () => {
    if (profileImage) {
      setProfileImage("");
      setImageFile(null);
      setImageFileName("");
    }
  };

  const handleSkipOption = () => {
    dispatch(SLThunk.patchUserUpdateProfile.reset());
    navigate("/subscription-activation");
  };

  return (
    <>
      <AuthenticationLayout pageTitle="Upload Picture">
        <Grid
          item
          xs={10}
          justifyContent="center"
          alignItems="center"
          maxWidth="500px"
          sx={{
            zIndex: 2,
            position: "relative",
            color: "white",
            marginTop: "30px",
          }}
        >
          <Box
            display="flex"
            sx={{ marginBottom: { xs: "100px", sm: "150px" } }}
          >
            <ArrowBackIosNewIcon
              aria-label="Back Navigation"
              titleAccess="Back Navigation"
              tabIndex={0}
              sx={{ cursor: "pointer" }}
              onClick={() => navigate("/additionalInfo")}
              onKeyPress={() => navigate("/additionalInfo")}
            />
            <Divider
              sx={{
                height: 40,
                borderColor: "var(--colorGray)",
                opacity: "0.2",
                marginLeft: "2%",
                marginTop: "-10px",
                marginRight: "2%",
              }}
              orientation="vertical"
            />
            <SLTypography
              variant="subHeading"
              tabIndex={0}
              title="profile Image"
              aria-level={2}
              messageId="general.profileImage"
            />
          </Box>

          <Stack justifyContent="center" alignItems="center" gap={2}>
            <Avatar
              aria-label="User Profile Image"
              title="User Profile Image"
              tabIndex={0}
              src={profileImage ? profileImage : DefaultUserImage}
              sx={{
                width: 200,
                height: 200,
                borderRadius: "50%",
              }}
            />

            <Button
              tabIndex={0}
              disableRipple
              variant="text"
              component="label"
              id="upload_photo_input"
              className="upload-btn"
              title={profileImage ? "Upload a New Photo" : "Upload a Photo"}
              onKeyPress={(e: any) => handleKeyImageChange(e)}
              onChange={(e) => handleImageChange(e)}
            >
              {profileImage ? "Upload a New Photo" : "Upload a Photo"}
              <input
                hidden
                tabIndex={0}
                accept="image/png, image/jpeg, image/jpg"
                type="file"
              />
            </Button>

            <FormControl
              variant="standard"
              fullWidth
              sx={{ alignItems: "center !important" }}
            >
              <Typography
                className={profileImage ? "remove-photo" : "photo-size"}
                onClick={() => handleRemoveImage()}
                onKeyPress={() => handleRemoveImage()}
                tabIndex={0}
                title={profileImage ? "Remove" : "Max size 10 MB"}
                aria-level={2}
              >
                {profileImage ? "Remove" : "Max size 10 MB"}{" "}
              </Typography>
            </FormControl>

            <FormControl
              sx={{ alignItems: "center !important" }}
              variant="standard"
              fullWidth
            >
              {errorMessage ? (
                <SLTypography
                  color="text-errorDark"
                  fontFamily="var(--PrimaryFontFamily)"
                  marginBottom="14px !important"
                  textAlign="center"
                  tabIndex={0}
                >
                  {errorMessage}
                </SLTypography>
              ) : null}

              <SLButton
                disabled={profileImage === "" ? true : false}
                messageId="general.save"
                title="Save"
                onClick={() => handleSaveImage()}
                onKeyPress={() => handleSaveImage()}
                sx={{ opacity: profileImage ? null : 0.5, width: "180px" }}
                slClass="purple"
              />
            </FormControl>

            <FormControl
              variant="standard"
              fullWidth
              sx={{ alignItems: "center !important" }}
            >
              <SLTypography
                sx={{ cursor: "pointer", textDecoration: "underline" }}
                onClick={handleSkipOption}
                onKeyPress={handleSkipOption}
                title="Skip this step"
                messageId="general.skip"
                tabIndex={0}
                aria-level={2}
                variant="extraSmall"
              />
            </FormControl>
          </Stack>
        </Grid>
      </AuthenticationLayout>
      <SLLoader open={loadingUserUpdateProfile || imageUploading || loading} />
      <SubscriptionDialog open={openDialog} />
      <ErrorDialogBox
        errorDialog={errorDialog}
        setErrorDialog={setErrorDialog}
        errorMessage={validationError}
        errorHeaderMessage="Oops!"
      />
    </>
  );
};

export default UploadPicture;
