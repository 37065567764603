import { forwardRef, ReactNode, Ref } from "react";
import PageTitle from "./pageTitle";

interface Props {
  title: string;
  children: ReactNode;
  className: string;
}

const Page = forwardRef(
  ({ title, children, className }: Props, ref: Ref<HTMLDivElement>) => {
    return (
      <>
        <PageTitle title={title} />
        <div ref={ref} className={className} style={{ height: "100%" }}>
          {children}
        </div>
      </>
    );
  }
);

export default Page;
