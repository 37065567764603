import { Box } from "@mui/material";

const ResetTutorial = () => {
  return (
    <Box
      sx={{
        fontSize: { xs: "21px", md: "37px" },
        fontFamily: "Figtree",
        fontWeight: "600",
        minHeight: "87vh",
      }}
      tabIndex={0}
    >
      Under Development
    </Box>
  );
};

export default ResetTutorial;
