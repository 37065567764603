import { Box } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { useRef, useState } from "react";
import useLineClamp from "use-line-clamp";
import { ParagraphWithShowMoreSkeleton } from "../skeletonUI/ParagraphWithShowMoreSkeleton";

interface IShowMore {
  text?: string | null;
  lines: number;
  textVariant?: string;
  showMoreVariant?: string;
  loading?: boolean;
}

export const ShowMoreText = ({
  text,
  lines,
  textVariant = "bodyCopy",
  showMoreVariant = "bodyUnderlined",
  loading,
}: IShowMore) => {
  if (loading) {
    return <ParagraphWithShowMoreSkeleton lines={lines} />;
  } else {
    const ref = useRef<HTMLDivElement>(null);
    const clamps = useLineClamp(ref, { lines });
    const [visible, setVisible] = useState(false);

    return text ? (
      <>
        <SLTypography
          color="text-deepPurple"
          variant={textVariant as any}
          tabIndex={0}
        >
          <div
            ref={ref}
            className={visible ? "" : `line-clamp line-clamp-${lines}`}
            dangerouslySetInnerHTML={{ __html: text.replaceAll("\n", "<br/>") }}
          />
        </SLTypography>

        {clamps ? (
          <Box sx={{ marginTop: "8px" }}>
            <SLTypography
              tabIndex={0}
              color="text-purple"
              variant={showMoreVariant as any}
              onClick={() => setVisible(!visible)}
              onKeyPress={() => setVisible(!visible)}
              sx={{ cursor: "pointer" }}
            >
              {visible ? "Less" : "More"}
            </SLTypography>
          </Box>
        ) : null}
      </>
    ) : (
      <></>
    );
  }
};
