function PrivacyPolicy() {
  return (
    <>
      <center>
        <h2>
          Supernal World Creations, Inc.
          <br />
          Privacy Policy
        </h2>
      </center>

      <p>
        Last modified: May 12, 2023
        <br />
        Last reviewed: May 12, 2023
      </p>
      <p>
        Supernal World Creations, Inc. (“<u>SWC</u>”, “<u>we</u>”, “<u>us</u>”,
        “<u>our</u>”, and “<u>Company</u>”) is committed to protecting your
        privacy through our compliance with this Privacy Policy (“
        <u>Privacy Policy</u>”). This Privacy Policy describes the types of
        information we may collect from you or that you may provide through our
        website(s), our mobile application, our marketing materials, the
        services we provide, and any products and other materials we may provide
        (collectively, the “<u>Services</u>”) and our practices for collecting,
        using, maintaining, protecting, and disclosing that information.
      </p>
      <p>This Privacy Policy applies to information we collect:</p>
      <ul>
        <li>through the Services;</li>
        <li>
          In email, text, and other electronic messages between you and the
          Services; and
        </li>
        <li>
          When you interact with our advertising and applications on third party
          websites and services, if those applications or advertising include
          links to this Privacy Policy.
        </li>
      </ul>
      <p>This Privacy Policy does not apply to information collected by:</p>
      <ul>
        <li>
          us offline or through any other means, including without limitation on
          any other website operated by us or any third party; or
        </li>
        <li>
          any third party including without limitation through any application
          or content (including without limitation advertising) that may link to
          or be accessible from or through the Services.
        </li>
      </ul>
      <p>
        By accessing or using the Services, you agree to this Privacy Policy. If
        you do not agree with this Privacy Policy, your choice is not to use the
        Services. This Privacy Policy may change from time to time. Your
        continued use of the Services after we make changes is deemed to be
        acceptance of those changes.
      </p>
      <h3>Children Under the Age of 16</h3>
      <p>
        The Services are not intended for children under 16 years of age. No one
        under age 16 may provide any information to the Services. We do not
        knowingly collect personal information from children under 16. If you
        are under 16, do not use or provide any information on the Services or
        provide any information about yourself to us, including your name,
        address, telephone number, email address, or any screen name or username
        you may use. If we learn we have collected or received personal
        information from a child under 16 without verification of parental
        consent, we will delete that information. If you believe we might have
        any information from or about a child under 16, please contact us at
        Support@SupernalWorldCreations.org.
      </p>
      <h3>Information We Collect About You</h3>
      <p>
        We collect several types of information from and about you, including
        without limitation information:
      </p>
      <ul>
        <li>
          That identifies, relates to, describes, references, is reasonably
          capable of being associated with, or could reasonably be linked,
          directly or indirectly with you, your household, or a device, such as
          your first and last name, mailing address, email address, telephone
          number, social security number, and any other identifier by which you
          may be contacted online or offline (collectively “
          <u>Personal Information</u>”);
        </li>
        <li>
          That is about you but does not individually identify you, such as your
          age and gender; and
        </li>
        <li>
          About your internet connection, the equipment you use to access the
          Services, and usage details.
        </li>
      </ul>
      <p>Personal Information does not include:</p>
      <ul>
        <li>Publicly available information from government records;</li>
        <li>Deidentified or aggregated consumer information;</li>
        <li>
          Health or medical information covered by the Health Insurance
          Portability and Accountability Act; and
        </li>
        <li>
          Personal information covered by certain sector-specific privacy laws,
          including without limitation the Fair Credit Reporting Act and the
          Gramm-Leach-Bliley Act.
        </li>
      </ul>
      <p>
        In particular, we have not collected the following categories of
        information from users within the last twelve (12) months:
      </p>
      <table>
        <tbody>
          <tr>
            <td width="20%" valign="top">
              <center>
                <p>
                  <strong>Category</strong>
                </p>
              </center>
            </td>
            <td width="60%" valign="top">
              <center>
                <p>
                  <strong>Examples</strong>
                </p>
              </center>
            </td>
            <td width="20%" valign="top">
              <center>
                <p>
                  <strong>Collected</strong>
                </p>
              </center>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>A. Identifiers.</p>
            </td>
            <td width="60%" valign="top">
              <p>
                A real name, alias, postal address, unique personal identifier,
                online identifier, Internet Protocol address, email address,
                account name, Social Security number, driver's license number,
                passport number, or other similar identifiers.
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>
                B. Personal information categories listed in the California
                Customer Records statute.
              </p>
            </td>
            <td width="60%" valign="top">
              <p>
                A name, signature, Social Security number, physical
                characteristics or description, address, telephone number,
                passport number, driver's license or state identification card
                number, insurance policy number, education, employment,
                employment history, bank account number, credit card number,
                debit card number, or any other financial information, medical
                information, or health insurance information.
              </p>
              <p>
                Some personal information included in this category may overlap
                with other categories.
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>
                C. Protected classification characteristics under certain state
                or federal law.
              </p>
            </td>
            <td width="60%" valign="top">
              <p>
                Age (40 years or older), race, color, ancestry, national origin,
                citizenship, religion or creed, marital status, medical
                condition, physical or mental disability, sex (including gender,
                gender identity, gender expression, pregnancy or childbirth and
                related medical conditions), sexual orientation, veteran or
                military status, genetic information (including familial genetic
                information).
              </p>
              <p>
                <br />
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>D. Commercial information.</p>
            </td>
            <td width="60%" valign="top">
              <p>
                Records of personal property, products or services purchased,
                obtained, or considered, or other purchasing or consuming
                histories or tendencies.
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>E. Biometric information.</p>
            </td>
            <td width="60%" valign="top">
              <p>
                Genetic, physiological, behavioral, and biological
                characteristics, or activity patterns used to extract a template
                or other identifier or identifying information, such as,
                fingerprints, faceprints, and voiceprints, iris or retina scans,
                keystroke, gait, or other physical patterns, and sleep, health,
                or exercise data.
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>F. Internet or other similar network activity.</p>
            </td>
            <td width="60%" valign="top">
              <p>
                Browsing history, search history, information on a consumer's
                interaction with a website, application, or advertisement.
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>G. Geolocation data.</p>
            </td>
            <td width="60%" valign="top">
              <p>Physical location or movements.</p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>H. Sensory data.</p>
            </td>
            <td width="60%" valign="top">
              <p>
                Audio, electronic, visual, thermal, olfactory, or similar
                information.
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>I. Professional or employment-related information.</p>
            </td>
            <td width="60%" valign="top">
              <p>Current or past job history or performance evaluations.</p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>J. Non-public education information.</p>
            </td>
            <td width="60%" valign="top">
              <p>
                Education records directly related to a student maintained by an
                educational institution or party acting on its behalf, such as
                grades, transcripts, class lists, student schedules, student
                identification codes, student financial information, or student
                disciplinary records.
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
          <tr>
            <td width="20%" valign="top">
              <p>K. Inferences drawn from other personal information.</p>
            </td>
            <td width="60%" valign="top">
              <p>
                Profile reflecting a person's preferences, characteristics,
                psychological trends, predispositions, behavior, attitudes,
                intelligence, abilities, and aptitudes.
              </p>
            </td>
            <td width="20%" valign="top">
              <p>
                <strong>NO</strong>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>How We Collect Information About You</h3>
      <p>We collect information about you:</p>
      <ul>
        <li>Directly from you when you provide it to us;</li>
        <li>Automatically as you navigate through the Services;</li>
        <li>From third parties, for example, our business partners; and</li>
        <li>
          From internet service providers, data analytic providers, operating
          systems, and social networks.
        </li>
      </ul>
      <h3>
        <em>Information You Provide to Us</em>
      </h3>
      <p>The information we collect on or through the Services may include:</p>
      <ul>
        <li>
          Information that you provide by filling in forms on the Services. This
          includes information provided at the time of registration, subscribing
          to the Services, posting material, or requesting further services. We
          may also ask you for information when you report a problem with the
          Services.
        </li>
        <li>
          Records and copies of your correspondence including without limitation
          email addresses, if you contact us.
        </li>
        <li>
          Your responses to surveys that we might ask you to complete for
          research purposes.
        </li>
        <li>
          Details of transactions you carry out through the Services and of the
          fulfillment of your orders. You may be required to provide financial
          information before placing an order through the Services.
        </li>
        <li>Your search queries on the Services.</li>
        <li>Any other type of information you provide to us.</li>
      </ul>
      <p>
        You also may provide or create information to be transmitted to other
        users of the Services or third parties ("<u>User Contributions</u>").
        Your User Contributions are transmitted to others at your own risk.
        Although we limit access to certain pages, you may set certain privacy
        settings for such information by logging into your account profile. Be
        aware that no security measures are perfect or impenetrable.
        Additionally, we cannot control the actions of other users of the
        Services with whom you may choose to share your User Contributions.
        Therefore, we cannot and do not guarantee that your User Contributions
        will not be viewed by unauthorized persons.
      </p>
      <h3>
        <em>
          Information We Collect Through Automatic Data Collection Technologies
        </em>
      </h3>
      <p>
        As you navigate through and interact with the Services, we may use
        automatic data collection technologies to collect certain information
        about your equipment, browsing actions, and patterns, including without
        limitation:
      </p>
      <ul>
        <li>
          Details of your visits to the Services, including without limitation
          usage details, time zone, individual webpages that you view, what
          website or search terms referred you to the Services, and information
          collected through cookies, web beacons, log files, tags, and other
          tracking technologies; and
        </li>
        <li>
          Information about your computer and internet connection, including
          your IP address, operating system, and browser type.
        </li>
      </ul>
      <p>
        We also may use these technologies to collect information about your
        online activities over time and across third-party websites or other
        online services. This data is used to deliver customized content and
        advertising within the Services to customers whose behavior indicates
        that they are interested in a particular subject area.
      </p>
      <p>
        There is no consensus among industry participants as to what “Do Not
        Track” means in this context. Like many websites and online services, we
        currently do not alter our practices when we receive a “Do Not Track”
        signal from a visitor’s browser.
      </p>
      <p>
        The information we collect automatically may include Personal
        Information, or we may maintain it or associate it with personal
        information we collect in other ways or receive from third parties. It
        helps us to improve the Services and to deliver a better and more
        personalized experience, including by enabling us to:
      </p>
      <ul>
        <li>Estimate our audience size and usage patterns.</li>
        <li>
          Store information about your preferences, allowing us to customize our
          Services according to your individual interests.
        </li>
        <li>Speed up your searches.</li>
        <li>Recognize you when you return to our Services.</li>
      </ul>
      <p>
        The technologies we use for this automatic data collection may include:
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li>
          <strong>Cookies (or browser cookies).</strong> A cookie is a small
          file placed on the hard drive of your computer. You may refuse to
          accept browser cookies by activating the appropriate setting on your
          browser. However, if you select this setting you may be unable to
          access certain parts of the Services. Unless you have adjusted your
          browser setting so that it will refuse cookies, our system will issue
          cookies when you direct your browser to the Services.
        </li>
        <li>
          <strong>Web Beacons.</strong> The Services and our emails may contain
          small electronic files known as web beacons (also referred to as clear
          gifs, pixel tags, and single-pixel gifs) that permit us to count users
          who have visited those pages or opened an email and for other related
          website statistics.
        </li>
      </ul>
      <p>
        We do not collect Personal Information automatically, but we may tie
        this information to personal information about you that we collect from
        other sources or you provide to us.
      </p>
      <h3>Third Party Use of Cookies and Other Tracking Technologies</h3>
      <p>
        Some content or applications, including advertisements, on the Services
        are served by third parties, including advertisers, ad networks and
        servers, content providers, and application providers. These third
        parties may use cookies alone or in conjunction with web beacons or
        other tracking technologies to collect information about you when you
        use the Services. The information they collect may be associated with
        your Personal Information or they may collect information, including
        Personal Information, about your online activities over time and across
        different websites and other online services. They may use this
        information to provide you with interest-based (behavioral) advertising
        or other targeted content.
      </p>
      <p>
        We do not control these third parties' tracking technologies or how they
        may be used. If you have any questions about an advertisement or other
        targeted content, you should contact the responsible provider directly.
      </p>
      <h3>How We Use Your Information</h3>
      <p>
        We may use, sell, or disclose the personal information we collect for
        one or more of the following purposes:
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li>To fulfill or meet the reason you provided the information.</li>
        <li>To provide, support, personalize, and develop the Services.</li>
        <li>
          To create, maintain, customize, and secure your account with us.
        </li>
        <li>
          To process your requests, purchases, transactions, and payments and
          prevent transactional fraud.
        </li>
        <li>
          To provide you with support and to respond to your inquiries,
          including to investigate and address your concerns and monitor and
          improve our responses.
        </li>
        <li>
          To personalize your experience and to deliver content and product and
          service offerings relevant to your interests, including targeted
          offers and ads through the Services, third-party sites, and via email
          or text message (with your consent, where required by law).
        </li>
        <li>
          To help maintain the safety, security, and integrity of the Services,
          databases and other technology assets, and business.
        </li>
        <li>
          For testing, research, analysis, and product development, including to
          develop and improve the Services.
        </li>
        <li>
          To respond to law enforcement requests and as required by applicable
          law, court order, or governmental regulations.
        </li>
        <li>
          As described to you when collecting your personal information or as
          otherwise set forth in applicable law.
        </li>
        <li>
          To evaluate or conduct a merger, divestiture, restructuring,
          reorganization, dissolution, or other sale or transfer of some or all
          of our assets, whether as a going concern or as part of bankruptcy,
          liquidation, or similar proceeding, in which personal information held
          by us about our users is among the assets transferred.
        </li>
        <li>For any other purpose with your consent.</li>
      </ul>
      <p>
        We will not collect additional categories of personal information or use
        the personal information we collected for materially different,
        unrelated, or incompatible purposes without providing you notice.
      </p>
      <p>
        We may also use your information to contact you about our own and third
        parties' goods and services that may be of interest to you. If you do
        not want us to use your information in this way, please adjust your user
        preferences in your account profile.
      </p>
      <p>
        We may use the information we have collected from you to enable us to
        display advertisements to our advertisers' target audiences. Even though
        we do not disclose your personal information for these purposes without
        your consent, if you click on or otherwise interact with an
        advertisement, the advertiser may assume that you meet its target
        criteria.
      </p>
      <h3>Sharing Personal Information</h3>
      <p>
        We may share your personal information by disclosing it to a third party
        for a business purpose. We only make these business purpose disclosures
        under written contracts that describe the purposes, require the
        recipient to keep the personal information confidential, and prohibit
        using the disclosed information for any purpose except performing the
        contract. In the preceding twelve (12) months, Company has not disclosed
        personal information for a business purpose.
      </p>
      <p>
        We may disclose aggregated information about our users, and information
        that does not identify any individual without restriction.
      </p>
      <p>
        We may disclose personal information that we collect or you provide as
        described in this privacy policy:
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li>To our subsidiaries and affiliates.</li>
        <li>
          To contractors, service providers, and other third parties we use to
          support our business and who are bound by contractual obligations to
          keep personal information confidential and use it only for the
          purposes for which we disclose it to them.
        </li>
        <li>
          To a buyer or other successor in the event of a merger, divestiture,
          restructuring, reorganization, dissolution, or other sale or transfer
          of some or all of our assets, whether as a going concern or as part of
          bankruptcy, liquidation, or similar proceeding, in which Personal
          Information held by us about our users is among the assets
          transferred.
        </li>
        <li>
          To third parties to market their products or services to you if you
          have consented to or not opted out of these disclosures.
        </li>
        <li>
          To fulfill the purpose for which you provide it. For example, if you
          give us an email address to use the "email a friend" feature of our
          Services, we will transmit the contents of that email and your email
          address to the recipients.
        </li>
        <li>
          For any other purpose disclosed by us when you provide the
          information.
        </li>
        <li>With your consent.</li>
      </ul>
      <p>We may also disclose your personal information:</p>
      <ul style={{ listStyleType: "disc" }}>
        <li>
          To comply with any court order, law, or legal process, including to
          respond to any government or regulatory request.
        </li>
        <li>
          To enforce or apply our terms of use
          https://SupernalWorldCreations.org/TermsOfService and other
          agreements, including for billing and collection purposes.
        </li>
        <li>
          If we believe disclosure is necessary or appropriate to protect our
          rights, property, or the safety of our customers, or others. This
          includes exchanging information with other companies and organizations
          for the purposes of fraud protection and credit risk reduction.
        </li>
      </ul>
      <p>The categories of Personal Information we may disclose include:</p>
      <ul style={{ listStyleType: "disc" }}>
        <li>
          Identifying Information, such as name, email address, physical
          location, for example, street address, city, state, and zip code
        </li>
        <li>Credit card information</li>
        <li>Device information</li>
        <li>Tracking information</li>
      </ul>
      <p>We do not sell your Personal Information.</p>
      <p>
        In the preceding twelve (12) months, Company has not sold the following
        categories of personal information to the categories of third parties
        indicated in the chart below. For more on your personal information sale
        rights, see Personal Information Sales Opt-Out and Opt-In Rights.
      </p>
      <table border={1} cellSpacing={0} cellPadding={0} width="100%">
        <tbody>
          <tr>
            <td width="28%" rowSpan={2} valign="top">
              <center>
                <p>
                  <strong>Personal Information Category</strong>
                </p>
              </center>
            </td>
            <td width="71%" colSpan={2} valign="top">
              <center>
                <p>
                  <strong>Category of Third-Party Recipients</strong>
                </p>
              </center>
            </td>
          </tr>
          <tr>
            <td width="36%" valign="top">
              <center>
                <p>
                  <strong>Business Purpose Disclosures</strong>
                </p>
              </center>
            </td>
            <td width="34%" valign="top">
              <center>
                <p>
                  <strong>Sales</strong>
                </p>
              </center>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>A: Identifiers.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>
                B: California Customer Records personal information categories.
              </p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>
                C: Protected classification characteristics under California or
                federal law.
              </p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>D: Commercial information.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>E: Biometric information.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>F: Internet or other similar network activity.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>G: Geolocation data.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>H: Sensory data.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>I: Professional or employment-related information.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>J: Non-public education information.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
          <tr>
            <td width="28%" valign="top">
              <p>K: Inferences drawn from other personal information.</p>
            </td>
            <td width="36%" valign="top">
              <p>None</p>
            </td>
            <td width="34%" valign="top">
              <p>None</p>
            </td>
          </tr>
        </tbody>
      </table>
      <h3>Reselling Personal Information</h3>
      <p>
        The California Consumer Privacy Act (“<u>CCPA</u>”) prohibits a third
        party from reselling Personal Information unless you have received
        explicit notice and an opportunity to opt-out of further sales.
      </p>
      <h3>Choices About How We Use and Disclose Your Information</h3>
      <p>
        We strive to provide you with choices regarding the personal information
        you provide to us. We have created mechanisms to provide you with the
        following control over your information:
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li>
          <strong>Tracking Technologies and Advertising.</strong> You can set
          your browser to refuse all or some browser cookies, or to alert you
          when cookies are being sent. If you disable or refuse cookies, please
          note that some parts of the Services may then be inaccessible or not
          function properly.
        </li>
        <li>
          <strong>
            Disclosure of Your Information for Third-Party Advertising.
          </strong>{" "}
          If you do not want us to share your personal information with
          unaffiliated or non-agent third parties for promotional purposes. You
          can also always opt-out by logging into the Services and adjusting
          your user preferences in your account profile or by sending us an
          email with your request to Support@SupernalWorldCreations.org
        </li>
        <li>
          <strong>Promotional Offers from the Company.</strong> If you do not
          wish to have your contact information used by the Company to promote
          our own or third parties’ products or services, you can opt-out by
          logging into the Services and adjusting your user preferences in your
          account profile or by sending us an email stating your request to
          Support@SupernalWorldCreations.org. If we have sent you a promotional
          email, you may send us a return email asking to be omitted from future
          email distributions. This opt-out does not apply to information
          provided to the Company as a result of a product purchase, product
          service experience, or other transactions.
        </li>
        <li>
          <strong>Targeted Advertising.</strong> If you do not want us to use
          information that we collect or that you provide to us to deliver
          advertisements according to our advertisers' target-audience
          preferences, you can opt-out by sending your request to
          Support@SupernalWorldCreations.org
        </li>
      </ul>
      <p>
        We do not control third parties' collection or use of your information
        to serve interest-based advertising. However, these third parties may
        provide you with ways to choose not to have your information collected
        or used in this way. You can opt out of receiving targeted ads from
        members of the Network Advertising Initiative ("<u>NAI</u>") on the
        NAI's{" "}
        <a href="https://thenai.org/opt-out/" target="_new">
          website
        </a>
        .
      </p>
      <h3>
        <u>Your Rights and Choices</u>
      </h3>
      <p>
        The CCPA provides consumers who are California residents with specific
        rights regarding their personal information. This section describes your
        CCPA rights and explains how to exercise those rights.
      </p>
      <h3>
        <em>Right to Know and Data Portability</em>
      </h3>
      <p>
        You have the right to request that we disclose certain information to
        you about our collection and use of your personal information over the
        past 12 months ("<u>right to know</u>"). Once we receive your request
        and confirm your identity (see{" "}
        <a href="https://1.next.westlaw.com/Document/Iee78ce09f4ae11e8a5b3e3d9e23d7429/View/FullText.html?originationContext=document&transitionType=DocumentItem&ppcid=daece58c13c34319affa3d630212866d&contextData=(sc.History*oc.Search)&view=showalldraftingnotes#co_anchor_a585939">
          Exercising Your Rights to Know or Delete
        </a>
        ), we will disclose to you:
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li>The categories of personal information we collected about you.</li>
        <li>
          The categories of sources for the personal information we collected
          about you.
        </li>
        <li>
          Our business or commercial purpose for collecting or selling that
          personal information.
        </li>
        <li>
          The categories of third parties with whom we share that personal
          information.
        </li>
        <li>
          If we sold or disclosed your personal information for a business
          purpose, two separate lists disclosing:
          <ul style={{ listStyleType: "disc" }}>
            <li>
              sales, identifying the personal information categories that each
              category of recipient purchased; and
            </li>
            <li>
              disclosures for a business purpose, identifying the personal
              information categories that each category of recipient obtained.
            </li>
          </ul>
        </li>
        <li>
          The specific pieces of personal information we collected about you
          (also called a data portability request).
        </li>
      </ul>
      <p>
        We do not provide a right to know or data portability disclosure for B2B
        personal information.
      </p>
      <h3>
        <em>Right to Delete</em>
      </h3>
      <p>
        You have the right to request that we delete any of your personal
        information that we collected from you and retained, subject to certain
        exceptions ("<u>right to delete</u>"). Once we receive your request and
        confirm your identity (see{" "}
        <a href="https://1.next.westlaw.com/Document/Iee78ce09f4ae11e8a5b3e3d9e23d7429/View/FullText.html?originationContext=document&transitionType=DocumentItem&ppcid=daece58c13c34319affa3d630212866d&contextData=(sc.History*oc.Search)&view=showalldraftingnotes#co_anchor_a585939">
          Exercising Your Rights to Know or Delete
        </a>
        ), we will review your request to see if an exception allowing us to
        retain the information applies. We may deny your deletion request if
        retaining the information is necessary for us or our service provider(s)
        to:
      </p>
      <ol start={1}>
        <li>
          Complete the transaction for which we collected the personal
          information, provide a good or service that you requested, take
          actions reasonably anticipated within the context of our ongoing
          business relationship with you, fulfill the terms of a written
          warranty or product recall conducted in accordance with federal law,
          or otherwise perform our contract with you.
        </li>
        <li>
          Detect security incidents, protect against malicious, deceptive,
          fraudulent, or illegal activity, or prosecute those responsible for
          such activities.
        </li>
        <li>
          Debug products to identify and repair errors that impair existing
          intended functionality.
        </li>
        <li>
          Exercise free speech, ensure the right of another consumer to exercise
          their free speech rights, or exercise another right provided for by
          law.
        </li>
        <li>
          Comply with the California Electronic Communications Privacy Act (Cal.
          Penal Code § 1546 et. seq.).
        </li>
        <li>
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when the information's deletion
          may likely render impossible or seriously impair the research's
          achievement, if you previously provided informed consent.
        </li>
        <li>
          Enable solely internal uses that are reasonably aligned with consumer
          expectations based on your relationship with us.
        </li>
        <li>Comply with a legal obligation.</li>
        <li>
          Make other internal and lawful uses of that information that are
          compatible with the context in which you provided it.
        </li>
      </ol>
      <p>
        We will delete or de-identify personal information not subject to one of
        these exceptions from our records and will direct our service providers
        to take similar action.
      </p>
      <p>
        We do not provide these deletion rights for B2B personal information.
      </p>
      <h3>
        <em>Exercising Your Rights to Know or Delete</em>
      </h3>
      <p>
        To exercise your rights to know or delete described above, please submit
        a request by either:
      </p>
      <ul>
        <li>
          [Visiting{" "}
          <a href="http://www.supernalworldcreations.org">
            www.supernalworldcreations.org
          </a>{" "}
          or <a href="http://www.soul-link.org">www.soul-link.org</a>
        </li>
        <li>
          [Emailing us at{" "}
          <a href="mailto:Support@SupernalWorldCreations.org">
            Support@SupernalWorldCreations.org
          </a>
          .
        </li>
      </ul>
      <p>
        Only you, or someone legally authorized to act on your behalf, may make
        a request to know or delete related to your personal information.
      </p>
      <p>
        You may also make a request to know or delete on behalf of your child by
        contacting support at Support@SupernalWorldCreations.org
      </p>
      <p>
        You may only submit a request to know twice within a 12-month period.
        Your request to know or delete must:
      </p>
      <ul style={{ listStyleType: "disc" }}>
        <li>
          Provide sufficient information that allows us to reasonably verify you
          are the person about whom we collected personal information or an
          authorized representative, which may include:
          <ul style={{ listStyleType: "disc" }}>
            <li>Providing a photo copy of your Driver’s License</li>
            <li>
              PARENT OR GUARDIAN VERIFICATION REQUIREMENTS:
              <ul style={{ listStyleType: "disc" }}>
                <li>Last 4 digits of Social Security Number</li>
                <li>Date of Birth</li>
                <li>Mailing Address</li>
              </ul>
            </li>
          </ul>
        </li>
        <li>
          Describe your request with sufficient detail that allows us to
          properly understand, evaluate, and respond to it.
        </li>
      </ul>
      <p>
        We cannot respond to your request or provide you with personal
        information if we cannot verify your identity or authority to make the
        request and confirm the personal information relates to you.
      </p>
      <p>
        You do not need to create an account with us to submit a request to know
        or delete. However, we do consider requests made through your
        password-protected account sufficiently verified when the request
        relates to personal information associated with that specific account.
      </p>
      <p>
        We will only use personal information provided in the request to verify
        the requestor's identity or authority to make it.
      </p>
      <p>
        For instructions on exercising your sale opt-out or opt-in rights, see{" "}
        <a href="https://1.next.westlaw.com/Document/Iee78ce09f4ae11e8a5b3e3d9e23d7429/View/FullText.html?originationContext=document&transitionType=DocumentItem&ppcid=daece58c13c34319affa3d630212866d&contextData=(sc.History*oc.Search)&view=showalldraftingnotes#co_anchor_a794300">
          Personal Information Sales Opt-Out and Opt-In Rights
        </a>
        .
      </p>
      <h3>
        <em>Response Timing and Format</em>
      </h3>
      <p>
        We will confirm receipt of your request within ten (10) business days.
        If you do not receive confirmation within the 10-day timeframe, please
        contact Michael G. Pierne at{" "}
        <a href="mailto:michaelgpierne@SupernalWorldCreations.org">
          michaelgpierne@SupernalWorldCreations.org
        </a>{" "}
        or write us at 3463 Pine Ridge Road #101 Naples, FL 34109.
      </p>
      <p>
        We endeavor to substantively respond to a verifiable consumer request
        within forty-five (45) days of its receipt. If we require more time (up
        to another 45 days), we will inform you of the reason and extension
        period in writing.
      </p>
      <p>
        If you have an account with us, we will deliver our written response to
        that account. If you do not have an account with us, we will deliver our
        written response by mail or electronically, at your option.
      </p>
      <p>
        Any disclosures we provide will only cover the 12-month period preceding
        our receipt of your request. The response we provide will also explain
        the reasons we cannot comply with a request, if applicable. For data
        portability requests, we will select a format to provide your personal
        information that is readily useable and should allow you to transmit the
        information from one entity to another entity without hindrance.
      </p>
      <p>
        We do not charge a fee to process or respond to your verifiable consumer
        request unless it is excessive, repetitive, or manifestly unfounded. If
        we determine that the request warrants a fee, we will tell you why we
        made that decision and provide you with a cost estimate before
        completing your request.
      </p>
      <h3>
        <em>Personal Information Sales Opt-Out and Opt-In Rights</em>
      </h3>
      <p>
        If you are age 16 or older, you have the right to direct us to not sell
        your personal information at any time ("<u>right to opt-out</u>"). We do
        not sell the personal information of consumers we actually know are less
        than 16 years old, unless we receive affirmative authorization ("
        <u>right to opt-in</u>") from either the consumer who is between 13 and
        15 years old, or the parent or guardian of a consumer less than 13 years
        old. Consumers who opt-in to personal information sales may opt-out of
        future sales at any time.
      </p>
      <p>
        To exercise the right to opt-out, you (or your authorized
        representative) may submit a request to us by visiting the following
        Internet Web page link:
      </p>
      <p>
        <a href="http://www.soul-link.org/Support">
          <strong>Do Not Sell My Personal Information</strong>
        </a>
      </p>
      <p>
        Once you make an opt-out request, we will wait at least twelve (12)
        months before asking you to reauthorize personal information sales.
        However, you may change your mind and opt back in to personal
        information sales at any time by visiting{" "}
        <a href="http://www.soul-link.org">www.soul-link.org</a> and entering
        your email address.
      </p>
      <p>
        You do not need to create an account with us to exercise your opt-out
        rights. We will only use personal information provided in an opt-out
        request to review and comply with the request.
      </p>
      <h3>
        <u>Accessing and Correcting Your Information</u>
      </h3>
      <p>
        You can review and change your Personal Information by logging into the
        Services and visiting your account profile page.
      </p>
      <p>
        You may also send us an email at Support@SupernalWorldCreations.org to
        request access to, correct, or delete any personal information that you
        have provided to us. We cannot delete your personal information except
        by also deleting your user account. We may not accommodate a request to
        change information if we believe the change would violate any law or
        legal requirement or cause the information to be incorrect.
      </p>
      <p>
        If you delete your User Contributions from the Services, copies of your
        User Contributions may remain viewable in cached and archived pages or
        might have been copied or stored by other users. Proper access and use
        of information provided on the Services, including User Contributions,
        is governed by our terms of use
        https://SupernalWorldCreations.org/TermsOfService.
      </p>
      <h3>We will not discriminate against you for exercising your rights.</h3>
      <p>
        We will not discriminate against you for exercising any of your privacy
        rights. Unless permitted under applicable laws, solely due to the
        exercise of your rights hereunder, we will not:
      </p>
      <ul>
        <li>deny you goods or services;</li>
        <li>
          charge you different prices or rates for goods or services, including
          through granting discounts or other benefits, or imposing penalties;
        </li>
        <li>
          provide you a different level or quality of goods or services; or
        </li>
        <li>
          suggest that you may receive a different price or rate for goods or
          services or a different level or quality of goods or services.
        </li>
      </ul>
      <p>
        However, we may offer you certain financial incentives permitted by the
        CCPA that <strong>can result</strong> in different prices, rates, or
        quality levels. Any CCPA-permitted financial incentive we offer will
        reasonably relate to your personal information's value and contain
        written terms that describe the program's material aspects.
        Participation in a financial incentive program requires your prior
        opt-in consent, which you may revoke at any time.
      </p>
      <h3>
        <u>Other California Privacy Rights</u>
      </h3>
      <p>
        California's "Shine the Light" law (Civil Code Section § 1798.83)
        permits users of our Services that are California residents to request
        certain information regarding our disclosure of personal information to
        third parties for their direct marketing purposes. To make such a
        request, please send an email to Support@SupernalWorldCreations.org or
        write us at: 3463 Pine Ridge Road #101 Naples, FL 34109.
      </p>
      <h3>
        <u>Data Security</u>
      </h3>
      <p>
        We have implemented measures designed to secure your personal
        information from accidental loss and from unauthorized access, use,
        alteration, and disclosure. The safety and security of your information
        also depends on you. You are responsible for keeping your log-in
        credentials confidential. We ask you not to share your password with
        anyone. Unfortunately, the transmission of information via the internet
        is not completely secure. Although we do our best to protect your
        personal information, we cannot guarantee the security of your personal
        information transmitted to our Services. Any transmission of personal
        information is at your own risk. We are not responsible for
        circumvention of any privacy settings or security measures contained on
        the Services.
      </p>
      <h3>Changes to This Privacy Policy</h3>
      <p>
        We reserve the right to amend this Privacy Policy at our discretion and
        at any time. When we make changes to this Privacy Policy, we will post
        the updated notice on the Services and update the notice's effective
        date. Your continued use of the Services following the posting of
        changes constitutes your acceptance of such changes.
      </p>
      <h3>Contact Information</h3>
      <p>
        If you have any questions or comments about this notice, the ways in
        which we collect and use your information described in this Privacy
        Policy, your choices, and rights regarding such use, or wish to exercise
        your rights, please contact us at:
      </p>
      <p style={{ marginLeft: "2rem" }}>
        Supernal World Creations, Inc.
        <br />
        3463 Pine Ridge Rd.
        <br />
        Unit 101
        <br />
        Naples, FL 34109
        <br />
        Email Address:{" "}
        <a href="mailto:Info@SupernalWorldCreations.org">
          Info@SupernalWorldCreations.org
        </a>
      </p>
      <p>
        If you need to access this Privacy Policy in an alternative format due
        to having a disability, please contact us at
        <a href="mailto:Support@SupernalWorldCreations.org">
          Support@SupernalWorldCreations.org
        </a>
      </p>
      <p>
        <a href="mailto:Support@SupernalWorldCreations.org" />
      </p>
      <p>
        <a href="mailto:Support@SupernalWorldCreations.org" />
      </p>
    </>
  );
}

export default PrivacyPolicy;
