import { ShareMeditation } from "components/shareMeditation";
import AboutAuthor from "pages/about/aboutAuthor";
import ViewArtistDetail from "pages/about/aboutAuthor/viewAuthor";
import AboutUs from "pages/about/aboutUs";
import ContactUs from "pages/about/contactUs";
import DynamicAboutPage from "pages/about/dynamicAboutPage";
import SupernalWorldCreation from "pages/about/supernalWorldCreation";
import AdditionalInfo from "pages/additionalInfo";
import AudioPlayer from "pages/audioPlayer";
import CreateMeditation from "pages/createMeditation";
import DataDeletionInstructions from "pages/docs/dataDeletionInstructions";
import DocsLayout from "pages/docs/layout";
import PrivacyPolicy from "pages/docs/privacyPolicy";
import TermsOfUse from "pages/docs/termsOfUse";
import Explore from "pages/explore";
import ExploreMedia from "pages/explore/exploreMedia";
import FileDetail from "pages/fileDetails";
import ForgotPassword from "pages/forgotPassword";
import FAQ from "pages/helpCenter/faqs";
import ReportProblem from "pages/helpCenter/reportProblem";
import SupportRequests from "pages/helpCenter/supportRequestsHistory";
import Tutorial from "pages/helpCenter/tutorialScreen";
import Home from "pages/home";
import HowItWorks from "pages/howItWorks";
import Login from "pages/login";
import MeditationDetail from "pages/meditationDetails";
import MyList from "pages/myList";
import ListDetail from "pages/myList/listItemDetail";
import NotFound from "pages/notFound";
import PlayBack from "pages/playBack";
import PopUp from "pages/popUp";
import AccountSettingPage from "pages/profile/accountSetting";
import DailyInspiration from "pages/profile/dailyInspirationProfile";
import MyJournalPage from "pages/profile/myNotes";
import MyProfile from "pages/profile/myProfile";
import MySubscriptionPage from "pages/profile/mySubscription";
import Reminder from "pages/profile/reminder";
import ResetPassword from "pages/resetPassword";
import Settings from "pages/settings";
import Signup from "pages/signup";
import SubscriptionActivate from "pages/subscriptionActivate/";
import UploadPicture from "pages/uploadPicture";
import { Fragment, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { SLStorage } from "utils/slStorage";
import ProtectedRoute from "./protectedRoutes";

const AppRoutes: React.FC = () => {
  type AuthenticationRouteProps = {
    // Define any props required by the authenticationRoute component
  };
  const AuthenticationRoute: React.FC<AuthenticationRouteProps> = () => {
    const auth = SLStorage.getItem("auth");
    const guestAuth = SLStorage.getItem("guestAuth");
    return auth || guestAuth ? (
      <Navigate to="/app/home" />
    ) : (
      <Navigate to="/login" />
    );
  };
  return (
    <>
      <Fragment>
        <Suspense
          fallback={
            <div
              style={{
                display: "flex",
                height: "100vh",
                alignItems: "center",
                justifyContent: "center",
              }}
            />
          }
        >
          <Routes>
            <Route path="/app" element={<ProtectedRoute />}>
              <Route path="/app" element={<Navigate to="/app/home" />} />
              <Route path="/app/home" element={<Home />} />
              <Route
                path="/app/meditation/create"
                element={<CreateMeditation />}
              />
              <Route
                path="/app/meditation/create/:id"
                element={<CreateMeditation />}
              />
              <Route path="/app/author-details" element={<AboutAuthor />} />
              <Route path="/app/file-detail/:id" element={<FileDetail />} />
              <Route
                path="/app/view-author/:id"
                element={<ViewArtistDetail />}
              />
              <Route path="/app/explore/:type" element={<ExploreMedia />} />
              <Route path="/app/my-list/media/:type" element={<ListDetail />} />
              <Route
                path="/app/my-list/meditation/:type"
                element={<ListDetail />}
              />
              <Route path="/app/about-us" element={<AboutUs />} />
              <Route path="/app/contact-us" element={<ContactUs />} />
              <Route path="/app/about/:id" element={<DynamicAboutPage />} />
              <Route
                path="/app/supernal-world-creation"
                element={<SupernalWorldCreation />}
              />
              <Route path="/app/play-back/:id" element={<PlayBack />} />
              <Route path="/app/media-player/:id" element={<AudioPlayer />} />
              <Route path="/app/explore" element={<Explore />} />
              <Route
                path="/app/browse-account"
                element={<AccountSettingPage />}
              />
              <Route path="/app/profile" element={<MyProfile />} />
              <Route path="/app/meditation-reminders" element={<Reminder />} />
              <Route path="/app/my-notes" element={<MyJournalPage />} />
              <Route path="/app/my-notes/:id" element={<MyJournalPage />} />
              <Route
                path="/app/my-subscription"
                element={<MySubscriptionPage />}
              />
              <Route path="/app/settings" element={<Settings />} />
              <Route path="/app/my-list" element={<MyList />} />
              <Route
                path="/app/meditation-details/:id"
                element={<MeditationDetail />}
              />
              <Route path="/app/how-it-works" element={<HowItWorks />} />
              <Route path="/app/my-popUp" element={<PopUp />} />
              {/* <Route path="/app/help-center" element={<HelpCenter />} /> */}
              <Route path="/app/help-center/faq" element={<FAQ />} />
              <Route path="/app/help-center/tutorial" element={<Tutorial />} />
              <Route
                path="/app/help-center/report-problem"
                element={<ReportProblem />}
              />
              <Route
                path="/app/help-center/support-requests-history"
                element={<SupportRequests />}
              />
              <Route path="*" element={<NotFound />} />
              <Route
                path="/app/daily-inspiration"
                element={<DailyInspiration />}
              />
            </Route>
            <Route path="/" element={<AuthenticationRoute />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/additionalInfo" element={<AdditionalInfo />} />
            <Route path="/uploadPhoto" element={<UploadPicture />} />
            <Route path="/meditation" element={<ShareMeditation />} />
            <Route path="/media" element={<ShareMeditation />} />
            <Route path="/note" element={<ShareMeditation />} />
            <Route path="/docs" element={<DocsLayout />}>
              <Route
                path="/docs/data-deletion-instructions"
                element={<DataDeletionInstructions />}
              />
              <Route path="/docs/terms-of-use" element={<TermsOfUse />} />
              <Route path="/docs/privacy-policy" element={<PrivacyPolicy />} />
            </Route>
            <Route
              path="/subscription-activation"
              element={<SubscriptionActivate />}
            />
            {/* <Route path="/payment" element={<Payment />} /> */}
            <Route path="*" element={<AuthenticationRoute />} />
          </Routes>
        </Suspense>
      </Fragment>
    </>
  );
};

export default AppRoutes;
