import AuthorDetails from "components/aboutpages/author-details";
import { lang } from "helpers/common/lang";
import AboutPages from "../../../layouts/aboutPages";

const AboutAuthor = () => {
  return (
    <AboutPages title={lang("authorDetail.title")}>
      <AuthorDetails />
    </AboutPages>
  );
};

export default AboutAuthor;
