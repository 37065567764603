// Import the functions you need from the SDKs you need
import { getDatabase, ref } from "@firebase/database";
import {
  FacebookAuthProvider,
  GoogleAuthProvider,
  OAuthProvider,
  getAuth,
} from "firebase/auth";
import firebase from "firebase/compat/app";
import { getMessaging } from "firebase/messaging";
import { SLStorage } from "utils/slStorage";
import { v4 as uuidv4 } from "uuid";

const {
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FCM_VAPID_KEY,
  REACT_APP_FIREBASE_DYNAMIC_WEB_LINK,
} = process.env;

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
export const database = getDatabase(app);
export const onConnectingDatabase = ref(database, ".info/connected");
export const activeUsers = ref(
  database,
  process.env.REACT_APP_FIREBASE_CURRENT_MEDITATION + "-users"
);

let messaging = null;

try {
  messaging = getMessaging();
} catch (err) {
  console.error("firebase getMessaging", err);
}
// const db = getFirestore(app);

export const deviceId =
  SLStorage.getItem("deviceId") ||
  SLStorage.setItem("deviceId", uuidv4()) ||
  "";
export const deviceInfo = navigator.userAgent;
export const authFirebase = getAuth(app);
// export const database = db;
export const googleProvider = new GoogleAuthProvider();
export const fbprovider = new FacebookAuthProvider();
export const appleProvider = new OAuthProvider("apple.com");

const publicKey = REACT_APP_FCM_VAPID_KEY;
export const firebaseApiKey = REACT_APP_FIREBASE_API_KEY;
export const webLink = REACT_APP_FIREBASE_DYNAMIC_WEB_LINK;
