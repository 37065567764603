import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { CardContent, Checkbox } from "@mui/material";
import { SLTypography } from "components/slTypography";

export const SubscriptionPlanCard = (props: any) => {
  return (
    <>
      <CardContent>
        <SLTypography
          tabIndex={0}
          sx={{
            fontStyle: "normal",
            fontWeight: 600,
            fontSize: "15px",
            lineHeight: "22px",
            color: "black",
          }}
        >
          <Checkbox
            icon={<RadioButtonUncheckedIcon className="checkbox-icon" />}
            checkedIcon={<CheckCircleOutlineIcon className="checkbox-icon" />}
          />
          {props.subscriptionPlan}
        </SLTypography>
        <SLTypography
          tabIndex={0}
          sx={{
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "36px",
            lineHeight: "44px",
            color: "black",
            marginLeft: "41px",
          }}
        >
          {props.payment}
        </SLTypography>
        <SLTypography
          tabIndex={0}
          sx={{
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "15px",
            lineHeight: "22px",
            color: "#A3AFCE",
            marginLeft: "41px",
          }}
        >
          {props.duration}
        </SLTypography>
      </CardContent>
    </>
  );
};
