import { Box, Link, List, ListItem } from "@mui/material";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import { SLTypography } from "components/slTypography";
import { lang } from "helpers/common/lang";
import { ArrowForwardIosIcon } from "helpers/enum/constants";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SLSelector } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import "./index.css";

interface AboutProps {
  redirectionData: any;
  handlePopup?: (popup: string) => void;
}

const LayoutOutlet = ({ redirectionData, handlePopup }: AboutProps) => {
  const navigate = useNavigate();
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);
  const guestLogin = SLStorage.getItem("guestLogin");

  const [guestError, setGuestError] = useState(false);
  const [unsubscribedError, setUnsubscribedError] = useState(false);

  const noSubscriptionDialog = useDialog({
    title: lang("subscription.no-subscription.title"),
    message: lang("subscription.no-subscription.message"),
  });

  const resetTutorialsDialog = useDialog({
    title: lang("general.settings.tutorials.reset.title"),
    message: lang("general.settings.tutorials.reset.message"),
  });
  const [alreadyResetTutorial, setAlreadyResetTutorial] = useState(false);

  const navigationLink = (data: any) => {
    console.log({ data });
    if (guestLogin && window.location.pathname === "/app/profile") {
      setGuestError(true);
    } else if (["/app/my-notes"].includes(data.redirectionLink) && guestLogin) {
      if (guestLogin) {
        setGuestError(true);
      } else {
        setUnsubscribedError(true);
      }
    } else if (
      ["/app/my-subscription"].includes(data.redirectionLink) &&
      (!userSubscription || guestLogin)
    ) {
      noSubscriptionDialog.show();
    } else if (data.redirectionLink === "/app/help-center/tutorial") {
      debugger;
      const tutorialKeys = [
        "homeMeditationTutorial",
        "createMeditationTutorial",
        "listMeditationTutorial",
        "ExploreMediaTutorial",
        "myProfileTutorial",
        "MeditationDetailTutorial",
      ];

      if (tutorialKeys.some((key) => SLStorage.getItem(key as any))) {
        resetTutorialsDialog.show();
        setAlreadyResetTutorial(false);
      } else {
        setAlreadyResetTutorial(true);
        resetTutorialsDialog.show({
          message: lang("general.settings.tutorials.reset.already.message"),
        });
      }
    } else {
      navigate(data.redirectionLink);
    }
  };

  const confirmPurchase = () => {
    noSubscriptionDialog.hide();
    sessionStorage.setItem("prvUrl", location.pathname);
    navigate("/subscription-activation");
  };

  const resetTutorials = () => {
    resetTutorialsDialog.hide();
    SLStorage.removeItem("homeMeditationTutorial");
    SLStorage.removeItem("createMeditationTutorial");
    SLStorage.removeItem("listMeditationTutorial");
    SLStorage.removeItem("ExploreMediaTutorial");
    SLStorage.removeItem("myProfileTutorial");
    SLStorage.removeItem("MeditationDetailTutorial");
  };
  return (
    <>
      <Box sx={{ width: "100%" }} className="profileSidebar">
        <List
          sx={{
            width: { lg: "100%", md: "100%", xs: "100%" },
            backgroundColor: "#F8F8F8",
            marginRight: { lg: "24px", md: "24px", xs: "16px" },
            borderRadius: "10px",
          }}
          className="listItem"
        >
          {redirectionData.map((data: any, index: any) =>
            data ? (
              <Link
                key={data.id}
                onClick={() => {
                  navigationLink(data);
                }}
                onKeyPress={() => {
                  navigationLink(data);
                }}
                style={{ textDecoration: "none" }}
                tabIndex={0}
                title={data.name}
                aria-label={data.name}
              >
                <ListItem
                  key={index}
                  className={
                    `${
                      location.pathname === data.redirectionLink
                        ? "item_active"
                        : ""
                    }` +
                    " " +
                    `${index + 1 == data.length ? "last_item" : ""}`
                  }
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    borderRadius: "10px",
                    cursor: "pointer",
                    color: "var(--sl-deep-purple)",
                    height: "50px",
                    textDecoration: "none",
                    gap: "10px",
                    overflow: "hidden",

                    "&.item_active img": {
                      filter: "brightness(0) invert(1) ",
                    },
                  }}
                  style={{
                    backgroundColor:
                      location.pathname === data.redirectionLink
                        ? "#4B2B5A"
                        : "white",
                    color:
                      location.pathname === data.redirectionLink
                        ? "#FFFFFF"
                        : "var(--sl-deep-purple)",
                    borderBottomLeftRadius:
                      index + 1 === data.length ? "10px" : "",
                    borderBottomRightRadius:
                      index + 1 === data.length ? "10px" : "",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      width: "100%",
                    }}
                  >
                    <Box
                      flexShrink={0}
                      sx={{
                        width: { xs: "19px", md: "24px" },
                        height: { xs: "19px", md: "24px" },
                      }}
                    >
                      {data.linkIcons}
                    </Box>
                    <Box
                      flexGrow={1}
                      sx={{
                        textOverflow: "ellipsis",
                        display: "inline-block",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <SLTypography
                        variant="title"
                        sx={{ fontSize: { xs: "14px" } }}
                      >
                        {data.name}
                      </SLTypography>
                    </Box>
                    <Box flexShrink={0}>
                      <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
                    </Box>
                  </Box>
                </ListItem>
              </Link>
            ) : null
          )}
        </List>
      </Box>

      <ErrorDialogBox
        errorDialog={guestError}
        setErrorDialog={setGuestError}
        errorMessage={
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed."
        }
        errorHeaderMessage="Oops! You Need to Log In"
      />

      <ErrorDialogBox
        errorDialog={unsubscribedError}
        setErrorDialog={setUnsubscribedError}
        errorMessage={lang("errors.subscription.subscribe.text")}
        errorHeaderMessage={lang("errors.subscription.subscribe.title")}
      />

      <SLDialog
        dismissable={true}
        dialog={noSubscriptionDialog}
        actionButtons={{
          yesMessageId: "subscription.no-subscription.button",
          noMessageId: "general.cancel",
          onYesButtonClicked: () => confirmPurchase(),
          onNoButtonClicked: () => noSubscriptionDialog.hide(),
        }}
      />

      <SLDialog
        dismissable={true}
        dialog={resetTutorialsDialog}
        actionButtons={{
          yesMessageId: alreadyResetTutorial ? "general.ok" : "general.confirm",
          noMessageId: alreadyResetTutorial ? false : "general.cancel",
          onYesButtonClicked: () => resetTutorials(),
          onNoButtonClicked: () => resetTutorialsDialog.hide(),
        }}
      />
    </>
  );
};

export default LayoutOutlet;
