import {
  Card,
  CardContent,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import Page from "components/pageTitle";
import { SLButton } from "components/slButton";
import { SLTypography } from "components/slTypography";
import { SubscriptionPlanCard } from "components/subscription/subscriptionPlanCard";
import { lang } from "helpers/common/lang";
import React from "react";
import "./index.css";

const SubscriptionDetail: React.FC = () => {
  const subscriptionCard = (
    <>
      <CardContent>
        {Array.from({ length: 8 }, (_i) => (
          <SLTypography
            key={""}
            sx={{
              fontStyle: "normal",
              fontWeight: 400,
              fontSize: "15px",
              lineHeight: "22px",
              color: "#262626",
              marginBottom: "10px",
              display: "list-item",
              marginLeft: "42px",
            }}
            tabIndex={0}
          >
            Subscription Plan Terms
          </SLTypography>
        ))}
      </CardContent>
    </>
  );

  return (
    <>
      <Page title="Subscription Details" className="">
        <Stack direction="column" textAlign="center" spacing={2}>
          <DialogTitle
            sx={{
              fontWeight: 700,
              fontSize: "32px",
              lineHeight: "40px",
              padding: 0,
            }}
          >
            Subscription Details
          </DialogTitle>
          <DialogContent
            sx={{
              fontWeight: 400,
              fontSize: "18px",
              lineHeight: "28px",
            }}
          >
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. <br />
            Curabitur fringilla vel est non pretium
          </DialogContent>
        </Stack>
        <Stack direction="row" spacing={2}>
          <Stack direction="column">
            <h4>What do you get with a subscription:</h4>
            <Card variant="outlined" className="subscription-card">
              {subscriptionCard}
            </Card>
          </Stack>
          <Stack direction="column">
            <h4>Choose your plan:</h4>
            <Card
              variant="outlined"
              className="subscription-card"
              sx={{
                width: "440px",
                height: "116px",
                marginBottom: "5px",
              }}
            >
              <SubscriptionPlanCard
                subscriptionPlan="Monthly Subscription"
                payment={`$${lang("app.pricing.monthly")}/month`}
                duration="7 day free trial"
              />
            </Card>
            <Card
              variant="outlined"
              className="subscription-card"
              sx={{
                width: "440px",
                height: "116px",
                marginBottom: "5px",
              }}
            >
              <SubscriptionPlanCard
                subscriptionPlan="Annual Subscription"
                payment={`$${lang("app.pricing.yearly")}/year`}
                duration="7 day free trial"
              />
            </Card>
          </Stack>
        </Stack>
        <Stack width="50%" direction="row" spacing={2} marginTop="20px">
          <SLButton
            slClass="gray"
            messageId={"general.close"}
            variant="text"
            fullWidth
            href="/uploadPhoto"
          />
          {/* <Button
            variant="text"
            sx={{
              width: "210px",
              height: "64px",
              backgroundColor: "#F8F8F8",
              borderRadius: "8px",
              color: "#697382",
              "&:hover": {
                backgroundColor: "#F8F8F8",
              },
            }}
            href="/uploadPhoto"
          >
            Close
          </Button> */}
          <SLButton
            fullWidth
            messageId={"general.subscribe"}
            variant="contained"
          />
          {/* <Button
            variant="text"
            sx={{
              width: "270px",
              height: "64px",
              backgroundColor: "#A3AFCE",
              borderRadius: "8px",
              color: "white",
              "&:hover": {
                backgroundColor: "#A3AFCE",
              },
            }}
          >
            Subscribe
          </Button> */}
        </Stack>
      </Page>
    </>
  );
};

export default SubscriptionDetail;
