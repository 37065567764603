import { Container, Grid, IconButton } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  EmptyHeart,
  FillHeart,
  InfoOutlined,
  ShareIcon,
  SpeakerNotesOutlined,
} from "helpers/enum/constants";
import { useDispatch } from "react-redux";
import { webShareOpen } from "store/webShareSlice";
import { getMediaSharingText } from "utils/getMediaSharingText";
import { SLMeditationDetail } from "utils/slRTKHelper/slAPI.models";

interface IMeditationPlayerFileInfo {
  mediaData: SLMeditationDetail;
  redirectMeditationDetails: any;
  isLiked: boolean;
  RemoveFavouriteItem: any;
  FavouriteItem: any;
  openJournalNote: any;
  shareUrl: string;
}

export function MeditationPlayerFileInfo(props: IMeditationPlayerFileInfo) {
  const dispatch = useDispatch();

  return (
    <Container maxWidth="lg" sx={{ padding: { lg: 0 } }}>
      <Grid
        container
        id="playBack"
        direction={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ sm: "flex-start" }}
        wrap="nowrap"
      >
        <Grid item xs={12} sm={8}>
          <SLTypography
            tabIndex={0}
            variant="heading"
            color="text-deepPurple"
            aria-label={props.mediaData?.title ? props.mediaData?.title : "N/A"}
            title={props.mediaData?.title ? props.mediaData?.title : "N/A"}
            display="inline-block"
            width="100%"
            overflow="hidden"
            letterSpacing="0px"
            lineHeight={1}
            sx={{ fontSize: { md: "20px", xs: "16px" } }}
          >
            {props.mediaData?.title ? props.mediaData?.title : "N/A"} <br />
            <SLTypography
              variant="smallBodyCopy"
              color="text-deepPurple"
              aria-label="Created Meditation"
              title="Created Meditation"
              tabIndex={0}
            >
              Created Meditation
            </SLTypography>
          </SLTypography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          display="flex"
          gap="2px"
          flexWrap="wrap"
          justifyContent={{ xs: "center", sm: "flex-end" }}
          alignItems="center"
        >
          <IconButton
            aria-label="Information of the Video"
            title="Information of the Video"
            onClick={props.redirectMeditationDetails}
            sx={{ cursor: "pointer", color: "var(--sl-deep-purple)" }}
          >
            <InfoOutlined />
          </IconButton>
          {props.isLiked ? (
            <IconButton
              aria-label="Image Favourite, button, click to activate"
              title="Image Favourite, button, click to activate"
              onClick={props.RemoveFavouriteItem}
              sx={{ cursor: "pointer", color: "var(--sl-deep-purple)" }}
            >
              <FillHeart />
            </IconButton>
          ) : (
            <IconButton
              aria-label="Image add to Favourite, button, click to activate"
              title="Image add to Favourite, button, click to activate"
              onClick={props.FavouriteItem}
              sx={{ cursor: "pointer", color: "var(--sl-deep-purple)" }}
            >
              <EmptyHeart />
            </IconButton>
          )}
          <IconButton
            aria-label={"Share " + props?.mediaData?.title}
            title={"Share " + props?.mediaData?.title}
            onClick={() => {
              dispatch(
                webShareOpen({
                  text: getMediaSharingText(
                    props?.mediaData?.title,
                    props?.mediaData?.type
                  ),
                  url: props.shareUrl,
                })
              );
            }}
            sx={{ cursor: "pointer", color: "var(--sl-deep-purple)" }}
          >
            <ShareIcon />
          </IconButton>
          {props?.mediaData?.type !== "daily" && (
            <IconButton
              aria-label="Speaker Notes"
              title="Speaker Notes"
              onClick={() => props.openJournalNote()}
              sx={{ cursor: "pointer", color: "var(--sl-deep-purple)" }}
            >
              <SpeakerNotesOutlined />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
