import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Card,
  CardContent,
  Skeleton,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { onEnterOrSpace } from "helpers/common/keypress";
import { useState } from "react";
import { Faqs, SLPaginationResponse } from "utils/slRTKHelper/slAPI.models";
import "./index.css";

type IFaqAccordion = {
  faqs?: SLPaginationResponse<Faqs>;
  loading?: boolean;
};

const FaqAccordion = ({ faqs, loading }: IFaqAccordion) => {
  const [accordion, setAccordion] = useState<number | null>(null);

  const handleAccordion = (index: number) => {
    if (accordion === index) {
      setAccordion(null);
    } else {
      setAccordion(index);
    }
  };

  return (
    <div
      role="list"
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        marginTop: "20px",
      }}
    >
      {loading
        ? Array.from({ length: 3 }, (_i) => (
            <FaqCard
              key={_i}
              accordion={accordion}
              handleAccordion={handleAccordion}
              faq={null}
              index={0}
              loading={loading}
            />
          ))
        : faqs &&
          faqs.data.map((faq, index) => (
            <FaqCard
              key={faq.id}
              accordion={accordion}
              handleAccordion={handleAccordion}
              faq={faq}
              index={index}
              loading={loading}
            />
          ))}
    </div>
  );
};

function FaqCard(props: any) {
  return (
    <Card
      tabIndex={-1}
      role="listitem"
      className="faq-card"
      sx={{
        borderRadius: "10px",
        border: "none",
        boxShadow: "none",
      }}
    >
      <div tabIndex={-1}>
        <CardContent tabIndex={-1}>
          <Accordion
            tabIndex={-1}
            id={"accordion-" + props.index}
            className="faq-accordion custom-accordion"
            onChange={() => props.handleAccordion(props.index)}
            expanded={props.accordion == props.index}
          >
            <AccordionSummary
              role="none"
              tabIndex={-1}
              sx={{ padding: 0 }}
              expandIcon={
                props.loading ? (
                  <Skeleton
                    sx={{
                      transform: "unset",
                      height: "24px",
                      width: "24px",
                    }}
                  />
                ) : (
                  <Box
                    role="listitem"
                    className="text-deepPurple"
                    tabIndex={0}
                    aria-label={
                      props.accordion == props.index
                        ? "List item expanded, tap to collapse"
                        : "List item collapsed, tap to expand and see answer"
                    }
                    aria-expanded={props.accordion == props.index}
                    onChange={(e) => {
                      props.handleAccordion(props.index);
                      adaAnnouncementHack(e);
                    }}
                    onKeyPress={onEnterOrSpace((e) => {
                      props.handleAccordion(props.index);
                      adaAnnouncementHack(e);
                    })}
                  >
                    <Box
                      component={
                        props.accordion == props.index ? RemoveIcon : AddIcon
                      }
                    />
                  </Box>
                )
              }
            >
              <div>
                <SLTypography
                  role="columnheader"
                  aria-label={"Category: " + props.faq?.category?.title || ""}
                  tabIndex={0}
                  variant="extraSmall"
                  sx={{ display: "block" }}
                >
                  {props.loading ? (
                    <Skeleton
                      sx={{
                        transform: "unset",
                        width: "5rem",
                      }}
                    />
                  ) : (
                    props.faq?.category?.title || ""
                  )}
                </SLTypography>
                <SLTypography
                  tabIndex={0}
                  variant="subhead"
                  color="text-deepPurple"
                  sx={{
                    width: "80%",
                    overflow: "hidden",
                  }}
                >
                  {props.loading ? (
                    <Skeleton
                      sx={{
                        transform: "unset",
                        width: "20rem",
                        marginTop: "5px",
                      }}
                    />
                  ) : (
                    <div
                      dangerouslySetInnerHTML={{
                        __html: String(props.faq.question || "").replace(
                          /\n/g,
                          "<br/>"
                        ),
                      }}
                    />
                    // props.faq.question //asdsada
                  )}
                </SLTypography>
              </div>
            </AccordionSummary>

            {props.loading ? null : (
              <AccordionDetails
                tabIndex={0}
                id={"accordion-details-" + props.index}
                sx={{ padding: 0 }}
              >
                <SLTypography color="text-deepPurple" variant="smallBody">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: props.faq.question.includes(
                        "How do I update the Soul Link app to make sure I have access to all the latest content?"
                      )
                        ? String(props.faq.answer || "")
                            .replace(/\n/g, "<br/>")
                            .replace(
                              /here/g,
                              '<a href="https://support.apple.com/en-gb/108324" target="_blank">here</a>'
                            )
                            .replace(
                              /Google Play store/g,
                              '<a href="https://play.google.com/store/apps/details?id=com.swc.soullink" target="_blank">Google Play store</a>'
                            )
                        : String(props.faq.answer || "").replace(
                            /\n/g,
                            "<br/>"
                          ),
                    }}
                  />
                </SLTypography>
              </AccordionDetails>
            )}
          </Accordion>
        </CardContent>
      </div>
    </Card>
  );

  function adaAnnouncementHack(e: any) {
    e.target.parentElement.parentElement.focus();
    setTimeout(() => {
      e.target.focus();
    }, 100);
  }
}

export default FaqAccordion;
