import { Link } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { onEnterOrSpace } from "helpers/common/keypress";
import { useDistinctNavigate } from "helpers/useDistinctNavigate";

export function NavigationLink(props: any) {
  const navigate = useDistinctNavigate();

  function onClick() {
    navigate(props.navigateTo);

    if (props.closeDrawer) {
      props.closeDrawer();
    }
  }

  return (
    <Link
      sx={{
        background: { sm: "inherit", xs: "#FFFFFF" },
        width: { xs: "65px", sm: "50px" },
        borderRadius: { xs: "8px" },
        paddingTop: { md: 0, xs: props.fromDrawer ? "15px" : 0 },
        paddingBottom: { md: 0, xs: props.fromDrawer ? "15px" : 0 },

        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        cursor: "pointer",
        textDecoration: "none",
      }}
      onClick={onClick}
      onKeyPress={onEnterOrSpace((e) => {
        e.preventDefault();
        onClick();
      })}
      title={props.title}
      tabIndex={0}
    >
      <img
        src={props.isWhiteIconPage ? props.whiteIcon : props.purpleIcon}
        style={{ height: "22px" }}
        alt={props.title}
      />

      <SLTypography
        variant="smallFooter"
        sx={{
          color: {
            sm: props.isWhiteIconPage ? "white" : null,
            xs: "var(--sl-deep-purple)",
          },
          fontWeight: { md: "500", xs: "600" },
          paddingTop: { md: "6px", xs: "7px" },
        }}
      >
        {props.title}
      </SLTypography>
    </Link>
  );
}
