/* eslint-disable indent */
import { Box, Grid } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  CrossIcon,
  KeyboardArrowLeft,
  LogoWhiteSvg,
  VerticalLineAppbar,
} from "helpers/enum/constants";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { MiniplayerContext } from "../../contexts/miniplayerContext";
import { checkRouteTitles } from "../../routes/routeTitles";
import Header from "../header";
import { HeaderTopGradient } from "./HeaderTopGradient";
import "./index.css";

const AppHeader = () => {
  const [headerName, setHeaderName] = useState("");
  const location = useLocation();
  const { closePlayer } = useContext(MiniplayerContext);

  const navigate = useNavigate();
  const handleClosePlayer = () => {
    closePlayer();

    setTimeout(() => {
      // do context processing before leaving
      navigate(-1);
    }, 300);
  };

  useEffect(() => {
    setHeaderName(checkRouteTitles());
    const el = document.querySelector(".updateControls") as HTMLDivElement;
    if (el) {
      el.focus();
    }
  }, [location]);

  const checkPathname = () => {
    if (
      [
        "/app/home",
        "/app/explore",
        "/app/how-it-works",
        "/app/my-list",
      ].includes(location.pathname)
    ) {
      return "absolute";
    } else if (
      [
        "/app/file-detail",
        "/app/meditation/create",
        "/app/meditation-details",
      ].some((item: string) => location.pathname.startsWith(item))
    ) {
      return "absolute";
    } else {
      return "default";
    }
  };
  const navigatToHome = () => {
    navigate("/app/home");
  };

  return (
    <>
      <HeaderTopGradient />
      <div className="updateControls" tabIndex={0} />
      <Box
        sx={{
          zIndex: "2",
          paddingLeft: { xs: 2, sm: 3 },
          paddingRight: { xs: 2, sm: 3 },
          paddingTop: { lg: "55px", xs: "16px" },
          paddingBottom: { lg: "50px", xs: "16px" },
          width: { lg: "95%" },
          margin: "auto",
          color: "var(--sl-deep-purple)",
          position: checkPathname(),
          left: 0,
          right: 0,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between !important",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={12}
            sx={{ paddingLeft: "0px", maxWidth: "0%" }}
          >
            {location.pathname === "/app/home" ||
            location.pathname.includes("/app/meditation/create") ||
            location.pathname === "/app/explore" ||
            location.pathname === "/app/how-it-works" ||
            location.pathname === "/app/my-list" ? (
              <>
                <Box
                  component="img"
                  tabIndex={0}
                  src={LogoWhiteSvg}
                  title="Soul Link"
                  sx={{
                    color: "white",
                    width: "150px",
                    cursor: "pointer",
                  }}
                  onClick={navigatToHome}
                />
              </>
            ) : location.pathname.substring(0, 14) === "/app/play-back" ||
              location.pathname.substring(0, 17) === "/app/media-player" ? (
              <Box>
                <CrossIcon
                  role="button"
                  tabIndex={0}
                  titleAccess="Back To Home"
                  style={{
                    cursor: "pointer",
                  }}
                  onKeyPress={handleClosePlayer}
                  onClick={handleClosePlayer}
                />
              </Box>
            ) : (
              <Box
                sx={{
                  display: { lg: "flex", md: "flex", sm: "flex", xs: "none" },
                  flexDirection: "row",
                  alignItems: "center ",
                  width: "500px",
                }}
              >
                <KeyboardArrowLeft
                  onClick={() => navigate(-1)}
                  tabIndex={0}
                  titleAccess="Back to Previous Page"
                  aria-label="Back to Previous Page"
                  onKeyPress={() => navigate(-1)}
                  sx={{
                    width: "32px",
                    height: "32px",
                    cursor: "pointer",
                    color:
                      location.pathname.startsWith("/app/file-detail") ||
                      location.pathname.startsWith("/app/meditation-details")
                        ? "white"
                        : "var(--sl-deep-purple)",
                  }}
                />
                {location.pathname.startsWith("/app/file-detail") ||
                location.pathname.startsWith("/app/meditation-details") ||
                location.pathname.startsWith("*") ? (
                  ""
                ) : (
                  <Box
                    component="img"
                    src={VerticalLineAppbar}
                    alt="/"
                    sx={{
                      paddingLeft: "33px",
                      paddingRight: "25px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "white",
                      position: "relative",
                    }}
                  />
                )}

                <SLTypography
                  sx={{
                    fontSize: "27px",
                    fontWeight: "600",
                    lineHeight: "32.4px",
                    color: "var(--sl-deep-purple)",
                  }}
                  tabIndex={headerName ? 0 : 1}
                >
                  {headerName}
                </SLTypography>
              </Box>
            )}
          </Grid>

          <Header />
        </Box>
      </Box>
    </>
  );
};

export default AppHeader;
