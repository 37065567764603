/* eslint-disable */
import { Box } from "@mui/material";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import { SLLoader } from "components/loader";
import NotesAddUpdateModal from "components/notes/addUpdateModal";
import Page from "components/pageTitle";
import { SLVerificationMessage } from "components/verificationMessage";
import VideoPlayer from "components/videoPlayer";
import { deepLink } from "helpers/common/deeplLink";
import { lang } from "helpers/common/lang";
import { useAppUrls } from "helpers/url";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { webLink } from "utils/firebase";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import { MiniplayerContext } from "../../contexts/miniplayerContext";
import { MeditationPlayerFileInfo } from "./MeditationPlayerFileInfo";
import "./index.css";

const PlayBack: React.FC = () => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { id } = useParams();
  const guestLogin = SLStorage.getItem("guestLogin");

  const {
    data: addFavoriteData,
    loading,
    error,
  } = useSelector(SLSelector.postMeditationAddFavourites);
  const {
    data: removeFavourite,
    loading: removeLoading,
    error: removeError,
  } = useSelector(SLSelector.deleteMeditationFavouritesId);
  const {
    data: mediaData,
    loading: meditationLoading,
    error: meditationError,
  } = useSelector(SLSelector.getMeditationId);
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);

  const {
    isOnPlayerPage,
    awayFromPlayerPage,
    closePlayer,
    meditationSession,
    meditationSessionStatus,
  } = useContext(MiniplayerContext);
  const [allowMeditationSession, setAllowMeditationSession] = useState(false);

  const sessionAlreadyInUseDialog = useDialog({
    title: lang("meditation.sessionInProgess.title"),
    message: lang("meditation.sessionInProgess.message"),
    show: undefined,
  });
  const sessionOvertakenDialog = useDialog({
    title: lang("meditation.overtaken.title"),
    message: lang("meditation.overtaken.message"),
  });

  const guestDialog = useDialog({
    title: lang(
      guestLogin
        ? "errors.subscription.loggedin.title"
        : "errors.subscription.subscribe.title"
    ),
    message: lang(
      guestLogin
        ? "errors.subscription.loggedin.text"
        : "errors.subscription.subscribe.text"
    ),
  });

  const [savedSucessfully, setSavedSuccessfully] = useState(false);
  const [deleteSucessfully, setDeleteSuccessfully] = useState(false);
  const [openMyJournalNotes, setOpenMyJournalNotes] = useState<boolean>(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [guestErrorMessage, setGuestErrorMessage] = useState<any>("");
  const [dynamicLinkUrlResponse, setDynamicLinkUrlResponse] =
    useState<string>("");
  const [guestError, setGuestError] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [videoThumbnail, setVideoThumbnail] = useState("");

  function FavouriteItem() {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return false;
    }
    setIsLiked(!isLiked);
    dispatch(
      SLThunk.postMeditationAddFavourites.call({
        meditationId: id ? parseInt(id) : 23,
      })
    );
  }

  function RemoveFavouriteItem() {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return false;
    }
    setIsLiked(!isLiked);
    dispatch(
      SLThunk.deleteMeditationFavouritesId.call({
        id: id ?? "",
      })
    );
  }

  useEffect(() => {
    if (mediaData) {
      isOnPlayerPage(mediaData as any);
      setIsLiked(mediaData.isLikedByMe);
      if (webLink && mediaData?.id && Object.keys(mediaData).length !== 0) {
        //alert(meditationData?.type);
        getDeepURL();
      }
      mixpanel.triggerUserActiveStatusEvent();
      mixpanel.triggerUserCurrentlyMeditationEvent();
      mixpanel.triggerMeditationSessionStartedEvent(mediaData);
      mixpanel.triggerMeditationCompilationMostUsedEvent(mediaData);
    }
  }, [mediaData]);

  const getDeepURL = async () => {
    const getURL = await deepLink(
      `${webLink}meditation?meditationId=${mediaData?.id}`
    );
    if (getURL !== undefined) {
      setDynamicLinkUrlResponse(getURL);
    }
  };

  useEffect(() => {
    if (mediaData) {
      if (mediaData.locked) {
        guestDialog.show();
        return;
      }

      setVideoThumbnail(appUrls.mediaImage(mediaData.thumbnail));
      setVideoUrl(appUrls.mediaMeditation(mediaData?.url));
    }
  }, [mediaData]);

  useEffect(() => {
    if (addFavoriteData) {
      dispatch(SLThunk.postMeditationAddFavourites.reset());
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
      }, 2000);
    }
    if (error) {
      setIsLiked(!isLiked);
      setErrorDialog(true);
      setValidationError(error);
    }
  }, [addFavoriteData, error]);

  useEffect(() => {
    if (removeFavourite) {
      dispatch(SLThunk.deleteMeditationFavouritesId.reset());
      setDeleteSuccessfully(true);
      setTimeout(() => {
        setDeleteSuccessfully(false);
      }, 2000);
    }
    if (removeError) {
      setIsLiked(!isLiked);
      setErrorDialog(true);
      setValidationError(removeError);
    }
  }, [removeFavourite, removeError]);

  useEffect(() => {
    dispatch(
      SLThunk.getMeditationId.call({
        id: id ?? "",
      })
    );

    return () => {
      dispatch(SLThunk.getMeditationId.reset());
    };
  }, []);

  useEffect(() => {
    if (meditationError) {
      dispatch(SLThunk.getMeditationId.reset());
      navigate("/app/meditation/create");
    }
  }, [meditationError]);

  useEffect(() => {
    return () => {
      awayFromPlayerPage();
    };
  }, []);

  const gotoMeditationDetails = () => {
    navigate("/app/meditation-details/" + id);
  };

  const openJournalNote = () => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return false;
    }
    setOpenMyJournalNotes(true);
  };

  useEffect(() => {
    if (meditationSessionStatus === "ALREADY_MEDIATING") {
      if (allowMeditationSession) {
        sessionOvertakenDialog.show();
      } else {
        sessionAlreadyInUseDialog.show();
      }
    } else if (meditationSessionStatus === "AVAILABLE") {
      sessionAlreadyInUseDialog.hide();
      setAllowMeditationSession(true);
    }
  }, [meditationSessionStatus]);

  const doNotOvertakeSession = () => {
    closePlayer();
    gotoMeditationDetails();
  };

  useEffect(() => {
    if (guestLogin) {
      setGuestErrorMessage({
        errorHeading: "Oops! You Need to Log In",
        errorBody:
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed.",
      });
    } else {
      if (!userSubscription) {
        setGuestErrorMessage({
          errorHeading: "Oops! This is for Subscribers Only",
          errorBody:
            "Elevate your meditation practice with our premium features. Join Soul Link today to unlock personalized playlists, guided meditations, and more.",
        });
      }
    }
  }, [userSubscription]);
  return (
    <Box sx={{ minHeight: "100vh" }}>
      {openMyJournalNotes && (
        <NotesAddUpdateModal
          setOpenMyJournalNotes={() => setOpenMyJournalNotes(false)}
          openMyJournalNotes={openMyJournalNotes}
          mediaData={mediaData}
        />
      )}
      <Page title={mediaData?.title || ""} className="">
        {mediaData && videoUrl && videoThumbnail ? (
          !allowMeditationSession ||
          meditationSessionStatus !== "AVAILABLE" ? null : (
            <>
              <div
                style={{
                  overflow: "hidden",
                  backgroundImage: `url(${videoThumbnail})`,
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  position: "relative",
                  zIndex: 1,
                  maxWidth: "1200px",
                  aspectRatio: "16/9",
                  margin: "auto",
                }}
              >
                <VideoPlayer
                  mediaData={mediaData}
                  videoUrl={
                    // "https://soul-media-dev.s3.amazonaws.com/meditations/211-206a.m3u8" // juni bhai testing
                    // "https://devstreaming-cdn.apple.com/videos/streaming/examples/img_bipbop_adv_example_fmp4/master.m3u8" // bipbop
                    // "https://d2iouuu86go54j.cloudfront.net/meditations/121-87.m3u8" // omer bhai testing
                    videoUrl
                  }
                  videoThumbnail={videoThumbnail}
                  isLiked={isLiked}
                  setIsLiked={setIsLiked}
                  dynamicLinkUrlResponse={dynamicLinkUrlResponse}
                />
              </div>

              <br />
              <MeditationPlayerFileInfo
                mediaData={mediaData}
                isLiked={isLiked}
                redirectMeditationDetails={gotoMeditationDetails}
                openJournalNote={openJournalNote}
                RemoveFavouriteItem={RemoveFavouriteItem}
                FavouriteItem={FavouriteItem}
                shareUrl={dynamicLinkUrlResponse}
              />
            </>
          )
        ) : null}

        <SLLoader open={meditationLoading || loading || removeLoading} />
      </Page>

      <ErrorDialogBox
        errorDialog={errorDialog}
        setErrorDialog={setErrorDialog}
        errorMessage={validationError}
        errorHeaderMessage="Oops!"
      />
      <ErrorDialogBox
        errorDialog={guestError}
        setErrorDialog={setGuestError}
        errorMessage={guestErrorMessage.errorBody}
        errorHeaderMessage={guestErrorMessage.errorHeading}
      />
      <SLVerificationMessage
        open={savedSucessfully}
        message={"Added to My Favorites"}
      />
      <SLVerificationMessage
        open={deleteSucessfully}
        message={"Removed from My Favorites"}
      />

      <ErrorDialogBox
        errorDialog={guestDialog.open}
        setErrorDialog={(e) => {
          navigate(guestLogin ? "/app/my-list" : "/app/explore");
          guestDialog.setOpen(e);
        }}
        errorMessage={guestDialog.message}
        errorHeaderMessage={guestDialog.title}
      />

      <SLDialog
        dismissable={true}
        dialog={sessionAlreadyInUseDialog}
        onDismiss={doNotOvertakeSession}
        actionButtons={{
          yesMessageId: "general.yes",
          onYesButtonClicked: meditationSession.overtake,
          noMessageId: "general.no",
          onNoButtonClicked: doNotOvertakeSession,
        }}
      />

      <SLDialog
        dismissable={true}
        dialog={sessionOvertakenDialog}
        onDismiss={doNotOvertakeSession}
        actionButtons={{
          yesMessageId: "general.ok",
          onYesButtonClicked: doNotOvertakeSession,
          noMessageId: false,
        }}
      />
    </Box>
  );
};

export default PlayBack;
