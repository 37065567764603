import axios from "axios";
export const deepLink = async (pageURL: string) => {
  try {
    const response = await axios.post(
      `https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=${process.env.REACT_APP_FIREBASE_API_KEY}`,
      JSON.stringify({
        dynamicLinkInfo: {
          domainUriPrefix: `${process.env.REACT_APP_DOMAIN_URI_PREFIX}`,
          link: pageURL,
          androidInfo: {
            androidPackageName: `${process.env.REACT_APP_ANDROID_BUNDLE_NAME}`,
          },
          iosInfo: {
            iosBundleId: `${process.env.REACT_APP_IOS_BUNDLE_NAME}`,
          },
        },
      }),
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    return response.data.shortLink;
  } catch (error) {
    // console.error(error);
    throw error; // Optional: Rethrow the error if necessary
  }
};
