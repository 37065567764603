import { Box, Dialog, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { memo, useEffect, useRef, useState } from "react";
import { DialogActionButtons } from "../DialogActionButtons";
import { MeditationCountdown } from "./countdown";

interface IMeditationLoopDialog {
  open: boolean;
  yesButtonClicked: any;
  noButtonClicked: any;
}

const MeditationLoopDialog = memo(
  ({
    open,
    yesButtonClicked,
    noButtonClicked,
    ...props
  }: IMeditationLoopDialog) => {
    const stillMeditatingCountdownRef = useRef<any>();
    const [stillMeditatingCountdown, setStillMeditatingCountdown] =
      useState(60);

    useEffect(() => {
      if (open) {
        stillMeditatingCountdownRef.current = setInterval(() => {
          setStillMeditatingCountdown((prv) => prv - 1);
        }, 1000);
      } else {
        if (stillMeditatingCountdownRef.current) {
          clearInterval(stillMeditatingCountdownRef.current);
        }
        setStillMeditatingCountdown(60);
      }
    }, [open]);

    useEffect(() => {
      if (stillMeditatingCountdown <= 0) {
        if (stillMeditatingCountdownRef.current) {
          clearInterval(stillMeditatingCountdownRef.current);
        }

        noButtonClicked();
      }
    }, [stillMeditatingCountdown]);

    return (
      <Dialog {...props} open={open} className="backdrop-blur">
        <Stack
          direction="column"
          gap={2}
          sx={{
            background: "var(--bg-gradient)",
            padding: { xs: 2, sm: 4 },
            width: { xs: "280px", md: "340px" },
            maxWidth: "90%",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <SLTypography
            variant="subhead"
            sx={{ color: "var(--sl-deep-purple)" }}
            tabIndex={0}
          >
            Are you still meditating?
          </SLTypography>

          <Box display="flex" justifyContent="center">
            <MeditationCountdown
              chartSize={100}
              total={60}
              value={stillMeditatingCountdown}
              fontSize={30}
            />
          </Box>

          <DialogActionButtons
            onYesButtonClicked={yesButtonClicked}
            onNoButtonClicked={noButtonClicked}
          />
        </Stack>
      </Dialog>
    );
  }
);
export default MeditationLoopDialog;
