import PlayCircleIcon from "@mui/icons-material/PlayCircle";
import { Box, Grid } from "@mui/material";
import { SLTypography } from "components/slTypography";
import Notes from "../notes";

const LovingAndKindness = () => {
  return (
    <>
      <Box
        id="love-and-kindness-main-wrpper"
        sx={{
          width: "88.2%",
          paddingLeft: { lg: "80px", md: "24px", xs: "16px" },
        }}
      >
        <SLTypography tabIndex={0}>File Description Details</SLTypography>
        <Box>
          <Grid container>
            <Grid item lg={3}>
              <img
                src="https://cdn.pixabay.com/photo/2018/08/27/10/11/radio-cassette-3634616_960_720.png"
                alt="this is our image"
                style={{ width: "90%" }}
              />
            </Grid>
            <Grid item lg={7}>
              <SLTypography tabIndex={0} variant="subHeading" pt={"12px"}>
                Loving-Kindness
              </SLTypography>
              <SLTypography tabIndex={0} pt={"20px"}>
                Category: Video
              </SLTypography>
              <SLTypography tabIndex={0}>Filmed: May 28,2021</SLTypography>
              <SLTypography tabIndex={0}>
                Artist: Harrison Courtsdness{" "}
              </SLTypography>
            </Grid>
            <Grid item lg={2}>
              <PlayCircleIcon
                sx={{
                  color: "#A3AFCE",
                  fontSize: { lg: "3.7rem", md: "3.4rem", xs: "3rem" },
                  paddingTop: "12px",
                  paddingLeft: { lg: "", md: "", xs: "27px" },
                }}
              />
            </Grid>
          </Grid>
          <Box>
            <SLTypography tabIndex={0}>Description</SLTypography>
            <SLTypography tabIndex={0}>
              “Loving-kindness ” is designed to Facilitate Change, by combining
              frequencies of vibration known to induce feelings of thankfulness,
              love and gratitude. These sound formulas transition the listener
              from Self-Doubt to Self-Love and activate DNA repair. Liberation
              from guilt and fear and increases the ability to problem solve.
              Clears the mind and reconnects to Source. This formula is designed
              to addresses borderline personality disorder, removes doubt &
              pride and create certainty. Leaves the listener with balance and
              aesthetically pleasing energy.”
            </SLTypography>
          </Box>
        </Box>
      </Box>
      <Notes />
    </>
  );
};

export default LovingAndKindness;
