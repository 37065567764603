import { Divider, Paper, Skeleton, Stack } from "@mui/material";
import { ParagraphWithShowMoreSkeleton } from "../../../skeletonUI/ParagraphWithShowMoreSkeleton";

const NotesSkelton = () => {
  return (
    <>
      <Paper
        sx={{
          padding: {
            xs: "30px 16px",
            lg: "30px",
          },
          marginBottom: "14px",
        }}
      >
        <Skeleton
          sx={{ transform: "unset" }}
          animation="wave"
          height={12}
          width="6rem"
        />

        <Skeleton
          sx={{ transform: "unset", marginTop: "5px" }}
          animation="wave"
          height={17}
          width="50%"
        />

        <Divider sx={{ margin: "15px 0" }} />

        <ParagraphWithShowMoreSkeleton />

        <Stack direction="row" sx={{ marginTop: "2rem", gap: "25px" }}>
          {Array.from({ length: 4 }, (_, _i) => (
            <Skeleton
              key={_i}
              animation="wave"
              variant="circular"
              width={40}
              height={40}
            />
          ))}
        </Stack>

        <Divider sx={{ margin: "15px 0px" }} />

        <Stack sx={{ gap: "25px" }} direction="row">
          {Array.from({ length: 4 }, (_, _i) => (
            <Skeleton
              key={_i}
              sx={{ transform: "unset" }}
              animation="wave"
              variant="rectangular"
              width={20}
              height={20}
            />
          ))}
        </Stack>
      </Paper>
    </>
  );
};

export default NotesSkelton;
