import {
  Box,
  Card,
  CardMedia,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { MediaType } from "helpers/enum";
import { CircleTimeToggle } from "helpers/enum/constants";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router";
import { getIdealPageSize } from "utils/getIdealPageSize";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import ListingPageSkeleton from "../../listingSkeleton";
import MediationCard from "../../mediationCard";
import { NotFoundMessage } from "../../slTypography/NotFoundMessage";
import { SLVerificationMessage } from "../../verificationMessage";

const pageSize = getIdealPageSize();

const styles = {
  media: {
    width: "60px",
    height: "60px",
  },
  card: {
    position: "relative",
    width: "60px",
    height: "60px",
    background: "transparent",
    outline: "none",
    boxShadow: "none",
  },
  overlay: {
    position: "absolute",
    top: "20px",
    left: "20px",
    color: "black",
    backgroundColor: "white",
  },
};

const ExploreSuggestedMedia = () => {
  const { type } = useParams();
  const dispatch = useDispatch();
  const timerRef = useRef<any>();

  // Manage Use States
  const guestLogin = SLStorage.getItem("guestLogin");
  const [alignment, setAlignment] = useState<string>("Any Time");
  const [toggleBtn, setToggleBtn] = useState<number>(0);
  const [mediaData, setMediaData] = useState<any>();
  const [mediaType, setMediaType] = useState<string>("");
  const [mediaDuration, setMediaDuration] = useState<number>();
  const [addedToFav, setAddedToFav] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const mediaByType = useSelector(SLSelector.getMeditationSuggested);

  useEffect(() => {
    if (type) {
      setMediaType(type);
      getMediaData({ limit: pageSize, page: 1, isFavourite: false });
    }
  }, [type]);

  useEffect(() => {
    if (mediaByType.data) {
      setLoading(false);
      setMediaData(mediaByType.data);
      dispatch(SLThunk.getMediaByType.reset());
    }
  }, [mediaByType]);

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    setLoading(true);
    setAlignment(newAlignment);
    if (newAlignment == null) {
      setAlignment("Any Time");
      newAlignment = "Any Time";
    }
    setMediaData(null); //for removing glitch on load the page
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (newAlignment !== "Any Time") {
        const [a, b] = newAlignment.split(" ");
        setMediaDuration(a);
        getMediaData({
          limit: pageSize,
          page: 1,
          isFavourite: false,
          duration: a,
        });
      } else {
        setMediaDuration(0);
        getMediaData({
          limit: pageSize,
          page: 1,
          isFavourite: false,
        });
      }
    }, 700);
  };

  const handleLikedMediaCard = (text: string) => {
    // console.log("heeelo");
    setAddedToFav(text);
    setOpenDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 1500);
  };

  const getMediaData = (payload: any) => {
    dispatch(SLThunk.getMeditationSuggested.call(payload));
  };

  return (
    <>
      <Box
        sx={{
          width: "100%",
          //   padding: "0px 82px",
        }}
      >
        <Box>
          <Stack spacing="20px">
            {mediaType !== MediaType.FrequencyWave && (
              <ToggleButtonGroup
                className="aqua-toggle"
                exclusive
                value={alignment}
                onChange={handleAlignment}
                sx={{ gap: 1 }}
              >
                {["Any Time", "5 min", "12 min", "22 min"].map(
                  (value: string) => {
                    const [a, b] = value.split(" ");
                    return (
                      <ToggleButton value={value}>
                        <Card sx={styles.card}>
                          <CardMedia
                            image={CircleTimeToggle}
                            style={styles.media}
                            className="media-image"
                          />
                          <SLTypography
                            variant="extraSmallBody"
                            sx={{
                              position: "absolute",
                              flexDirection: "column",
                              top: 0,
                              display: "flex",
                              textAlign: "center",
                              alignItems: "center",
                              justifyContent: "center",
                              left: "10px",
                              right: "10px",
                              height: "100%",
                              textTransform: "capitalize",
                            }}
                          >
                            {a === "Any" ? (
                              <>
                                <span>{a}</span>
                              </>
                            ) : (
                              <span className="number-style-change">{a}</span>
                            )}
                            {b === "Time" ? (
                              <span>{b}</span>
                            ) : (
                              <span className="min-value">{b}</span>
                            )}
                          </SLTypography>
                        </Card>
                      </ToggleButton>
                    );
                  }
                )}
              </ToggleButtonGroup>
            )}
            {!loading ? (
              <Stack
                direction="row"
                flexWrap="wrap"
                spacing={1}
                sx={{ gap: "12px" }}
              >
                {mediaData?.data.map((res: any) => (
                  <MediationCard
                    selectedCardData={res}
                    cardType="Meditation"
                    likedMediaCard={handleLikedMediaCard}
                  />
                ))}
                {mediaData?.data.length === 0 && (
                  <NotFoundMessage
                    notFoundMessage={`No Suggested Meditation Found`}
                  />
                )}
              </Stack>
            ) : (
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "15px",
                  overflow: "hidden",
                  marginRight: "0px",
                  flexWrap: "wrap",
                }}
              >
                <ListingPageSkeleton
                  showSkeleton={loading}
                  type="card"
                  count={15}
                />
              </Stack>
            )}
          </Stack>
        </Box>
      </Box>
      <SLVerificationMessage
        open={openDialog}
        sx={{ zIndex: 9999 }}
        message={addedToFav}
      />
    </>
  );
};

export default ExploreSuggestedMedia;
