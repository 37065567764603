import { Skeleton } from "@mui/material";

export function DynamicAboutPageSkeleton() {
  return (
    <>
      <Skeleton
        sx={{
          width: "25rem",
          height: {
            md: "45px",
            xs: "22px",
          },
          lineHeight: "40px",
          transform: "unset",
          marginBottom: {
            md: "40px",
            sm: "30px",
            xs: "12px",
          },
        }}
      />

      <Skeleton
        style={{
          height: "1rem",
          marginBottom: "5px",
          transform: "unset",
        }}
      />
      <Skeleton
        style={{
          height: "1rem",
          marginBottom: "5px",
          transform: "unset",
        }}
      />
      <Skeleton
        style={{
          height: "1rem",
          marginBottom: "5px",
          transform: "unset",
        }}
      />
    </>
  );
}
