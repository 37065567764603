import { Container } from "@mui/material";
import ExploreMediaType from "components/explore/exploreMediaType";
import ExploreSuggestedMedia from "components/explore/exploreSuggestedMedia";
import { MeditationType } from "helpers/enum";
import { useParams } from "react-router-dom";

const ExploreAllTypeMedia = () => {
  const { type } = useParams();

  return (
    <Container
      className="main-container p-relative"
      sx={{
        minHeight: "84vh",
        width: { xl: "93.2%", lg: "95%" },
      }}
      maxWidth={false}
    >
      {type !== MeditationType.SuggestedMeditation ? (
        <ExploreMediaType />
      ) : (
        <ExploreSuggestedMedia />
      )}
    </Container>
  );
};

export default ExploreAllTypeMedia;
