import { Stack, SxProps, Theme } from "@mui/material";
import { onEnterOrSpace } from "helpers/common/keypress";
import { IntlMessageID } from "helpers/enum";
import { PopupCancelButton, SLButton } from "../slButton";

export interface IDialogActionButtons {
  marginTop?: string;
  yesMessageId?: IntlMessageID;
  yesAriaLabel?: string;
  onYesButtonClicked: (...props: any) => any;
  yesAutofocus?: boolean;
  yesButtonWidth?: string;
  yesButtonSx?: SxProps<Theme>;
  yesButtonType?: "submit" | "button";

  noMessageId?: IntlMessageID | false;
  onNoButtonClicked?: (...props: any) => any;
  noButtonWidth?: string;
  noButtonSx?: SxProps<Theme>;
}

export function DialogActionButtons({
  yesMessageId = "general.yes",
  yesButtonWidth = "100%",
  noMessageId = "general.no",
  yesAutofocus = true,
  yesButtonType = "button",
  ...props
}: IDialogActionButtons) {
  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        marginTop: props?.marginTop || null,
      }}
      gap={1}
    >
      <SLButton
        type={yesButtonType}
        autoFocus={yesAutofocus}
        onClick={props.onYesButtonClicked}
        onKeyPress={onEnterOrSpace(props.onYesButtonClicked)}
        messageId={yesMessageId}
        aria-label={props.yesAriaLabel || undefined}
        sx={{
          width: yesButtonWidth,
          ...props.yesButtonSx,
        }}
      />

      {noMessageId !== false ? (
        <PopupCancelButton
          onClick={props.onNoButtonClicked}
          onKeyPress={
            props.onNoButtonClicked
              ? onEnterOrSpace(props.onNoButtonClicked)
              : () => null
          }
          messageId={noMessageId}
          sx={{
            width: props?.noButtonWidth || null,
            ...props.noButtonSx,
          }}
        />
      ) : null}
    </Stack>
  );
}
