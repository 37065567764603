import { Box, Dialog, Paper, Skeleton, Stack, TextField } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { useState, useEffect } from "react";
import { Cell, Label, Pie, PieChart, ResponsiveContainer } from "recharts";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLButton } from "../../../slButton";
import { SLVerificationMessage } from "../../../verificationMessage";
import "./index.css";

const DailyMeditaionGoal = (props: any) => {
  const { goalData, setStatsUpdate } = props;
  const currentDate = new Date().toLocaleDateString("en-us", {
    weekday: "long",
    month: "long",
    day: "numeric",
  });
  const [errorMessage, seterrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [goalTitle, setgoalTitle] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [currentTime, setCurrentTime] = useState("");
  const editMeditationName = () => {
    setgoalTitle(goalData?.goal);
    setOpenDialog(true);
  };

  const Circledata = [{ name: "Group A", value: 10 }];
  const onUserType = (e: any) => {
    const regex = /(^[0-9]+$|^$)/;
    if (!regex.test(e.target.value)) {
      return false;
    }
    if (e.target.value > 1440) {
      return false;
    }
    setgoalTitle(e.target.value);
  };
  const cancelEditGoal = () => {
    seterrorMessage("");
    setOpenDialog(false);
  };
  useEffect(() => {
    if (goalData) {
      setgoalTitle(goalData?.goal);
      setCurrentTime(
        360 - (goalData?.meditationTime / goalData?.goal) * 360 + ""
      );
    }
  }, [goalData]);

  const confirmChanges = async () => {
    seterrorMessage("");
    if (parseInt(goalTitle) < 15) {
      seterrorMessage(
        "Please choose a goal duration that falls within the range of 15 to 1440 mintues."
      );
      return false;
    }

    const urlParams = { goal: parseInt(goalTitle) };
    const makeCallObj: any = {
      method: "patch",
      url: "/stats/{goal}",
      urlParams,
    };
    const response = await makeSLAPICall(makeCallObj);
    if (response) {
      setStatsUpdate(true);
      setOpenDialog(false);
      setSuccessMessage("Changes Saved");
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false);
      }, 2000);
    }
  };

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          height: "100%",
          background: "white",
          boxShadow: "none",
          borderRadius: "10px",
        }}
        className=""
      >
        <Box className="dailyMeditationStats" sx={{ padding: "25px" }}>
          <SLTypography
            tabIndex={0}
            variant="smallBodyCopy"
            sx={{ textAlign: "center", display: "block", marginTop: "10px" }}
            color="text-deepPurple"
          >
            {props.loading ? (
              <Skeleton
                sx={{
                  transform: "unset",
                  display: "inline-block",
                  width: "5rem",
                }}
              />
            ) : (
              currentDate
            )}
          </SLTypography>
          <h1 className="goalHeading" tabIndex={0}>
            {props.loading ? (
              <Skeleton
                sx={{
                  transform: "unset",
                  display: "block",
                }}
              />
            ) : (
              <>
                <span style={{ color: "var(--sl-aqua)" }}>
                  {goalData?.meditationTime.toFixed(0)} minutes
                </span>{" "}
                of meditation time today
              </>
            )}
          </h1>

          {props.loading || !goalData ? (
            <Box display="flex" justifyContent="center">
              <Skeleton
                variant="circular"
                sx={{
                  width: "160px",
                  height: "160px",
                }}
              />
            </Box>
          ) : (
            <div className="ciruclarProgressBar">
              <div className="circleEdge" />
              <div className="circleEdge2" />

              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                    data={Circledata}
                    cx={75}
                    cy={67}
                    innerRadius={68}
                    outerRadius={73}
                    tabIndex={-1}
                    cornerRadius={40}
                    startAngle={807}
                    paddingAngle={
                      parseInt(currentTime) == 0
                        ? parseInt(currentTime) + 2
                        : parseInt(currentTime)
                    }
                    fill="#8884d8"
                    dataKey="value"
                  >
                    {Circledata.map((entry, index) => (
                      <Cell
                        style={{ outline: "none" }}
                        key={`cell-${index}`}
                        fill="var(--sl-aqua)"
                      />
                    ))}
                    <Label
                      value={goalData?.meditationTime.toFixed(0) + " min"}
                      position="center"
                      style={{
                        fill: "var(--sl-aqua)",
                        outline: "none",
                        transform: "translate(0px, -28px)",
                      }}
                    />

                    <Label
                      style={{
                        fill: "#E7E7E7",
                        outline: "none",
                        transform: "translate(0px, -16px)",
                        fontWeight: "300",
                      }}
                      position="center"
                    >
                      ___________
                    </Label>
                    <Label
                      value={goalData?.goal + " min"}
                      position="center"
                      style={{
                        fill: "#4B2B5A",
                        outline: "none",
                        transform: "translate(0px, 10px)",
                      }}
                    />
                    <Label
                      value="Goal"
                      position="center"
                      style={{
                        fill: "#4B2B5A",
                        outline: "none",
                        transform: "translate(0px, 30px)",
                      }}
                    />
                  </Pie>
                </PieChart>
              </ResponsiveContainer>
            </div>
          )}
          <SLTypography
            variant="smallBodyCopy"
            color="text-purple"
            sx={{
              textDecoration: "underline",
              cursor: "pointer",
              display: "block",
              textAlign: "center",
              marginTop: "15px",
              fontWeight: "600",
              letterSpacing: "0.5px",
            }}
            onClick={editMeditationName}
            onKeyPress={editMeditationName}
            tabIndex={0}
          >
            Edit Daily Meditation Goal
          </SLTypography>
        </Box>
      </Paper>
      <Dialog
        open={openDialog}
        onClose={cancelEditGoal}
        sx={{ borderRadius: "10px" }}
        className="dailyMeditationGoalPopup"
      >
        <Stack
          direction="column"
          gap={2}
          sx={{
            padding: "25px 25px",
            background: "var(--bg-gradient)",
            width: "300px",
          }}
        >
          <SLTypography
            tabIndex={0}
            id="sl-dialog-title"
            variant="subhead"
            sx={{
              fontSize: { xs: "17px", sm: "19px" },
              lineHeight: { xs: "20px", sm: "23px" },
              fontWeight: 700,
            }}
          >
            Your Daily Meditation Goal
          </SLTypography>
          <TextField
            label="Minutes Per Day"
            name="editMeditation"
            className="sl-input"
            sx={{ marginTop: "0 !important" }}
            onChange={onUserType}
            value={goalTitle}
            fullWidth
          />
          {errorMessage ? (
            <SLTypography
              sx={{
                fontWeight: "300",
                fontSize: "14px",
                textAlign: "left",
                display: "block",
              }}
              tabIndex={0}
            >
              {errorMessage}
            </SLTypography>
          ) : null}

          <SLButton
            sx={{
              width: "100%",
              pointerEvents: goalTitle != "" ? "" : "none",
              opacity: goalTitle != "" ? "" : "0.3",
            }}
            variant="contained"
            messageId={"general.saveChanges"}
            onClick={() => confirmChanges()}
            onKeyPress={() => confirmChanges()}
          />

          <SLTypography
            tabIndex={0}
            onKeyPress={cancelEditGoal}
            onClick={cancelEditGoal}
            sx={{
              color: "var(--sl-purple)",
              textDecoration: "underline",
              margin: "auto",
              cursor: "pointer",
              fontSize: "14px",
              display: "inline-block",
            }}
            messageId={"general.cancel"}
          />
        </Stack>
      </Dialog>
      <SLVerificationMessage
        open={showSuccessMessage}
        sx={{ zIndex: 9999 }}
        message={successMessage}
      />
    </>
  );
};

export default DailyMeditaionGoal;
