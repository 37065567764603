import { Box } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { useEffect, useRef, useState } from "react";
import { SLButton } from "../../slButton";

interface MediationProps {
  seconds: number;
}

const MeditationTimer = ({ seconds }: MediationProps) => {
  const [countdown, setCountdown] = useState(seconds);
  const timerId = useRef<any>(null);

  useEffect(() => {
    timerId.current = setInterval(() => {
      setCountdown((prev: number) => prev - 1);
    }, 1000);
    return () => clearInterval(timerId.current);
  }, []);

  useEffect(() => {
    if (countdown <= 0) {
      clearInterval(timerId?.current);
      alert("End");
    }
  }, [countdown]);

  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <SLTypography tabIndex={0} p={"0px"}>
          Are you still Meditating?
        </SLTypography>
        <Box
          sx={{
            width: "120px",
            height: "120px",
            borderRadius: "50%",
            border: "1px solid #A3AFCE",
            margin: "35px 0px",
            color: "#A3AFCE",
            fontSize: "32px",
            fontWeight: "700",
          }}
        >
          <SLTypography tabIndex={0} variant="subHeading" p={"29px"}>
            {countdown}
          </SLTypography>
        </Box>

        <Box
          sx={{
            flexDireciton: "row",
            width: { lg: "75%", md: "", xs: "100%" },
            gap: "5px",
            paddingBottom: "15px",
          }}
        >
          <SLButton messageId={"general.no"} variant="text" slClass="none" />
          {/* <Button
            variant="text"
            sx={{
              width: { lg: "20%", md: "28%", xs: "70%" },
              height: "64px",
              marginRight: "5px",
            }}
          >
            No
          </Button> */}
          <SLButton messageId={"general.yes"} variant="contained" />
          {/* <Button
            variant="contained"
            sx={{
              width: { lg: "20%", md: "28%", xs: "60%" },
              height: "64px",
              background: "#A3AFCE",
            }}
          >
            Yes
          </Button> */}
        </Box>
      </Box>
    </>
  );
};

export default MeditationTimer;
