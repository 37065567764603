import { Bootloader } from "components/bootloader";
import { Oscillator } from "helpers/common/Oscillator";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onNewLogin } from "utils/authHooks";
import { Auth } from "utils/authService";
import { deviceId, deviceInfo } from "utils/firebase";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLAuthAPI } from "utils/slRTKHelper/axiosConfigs";
import { SLStorage } from "utils/slStorage";
import "./App.css";
import initAndCreateCustomer from "./createCustomer";
import AppRoutes from "./routes";

const authRoutes = ["/signup", "/login", "/forgot-password", "/reset-password"];
const authRoutesLoggedin = [
  "/additionalInfo",
  "/uploadPhoto",
  "/subscription-activation",
];

const App = () => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { data: config, error: configError } = useSelector(
    SLSelector.getConfig
  );
  const { data: userProfile } = useSelector(SLSelector.getUserProfile);
  const { data: guestLogin } = useSelector(SLSelector.postAuthGuestLogin);
  const { data: userLogin } = useSelector(SLSelector.postAuthLoginWeb);
  const { data: authRegisterData } = useSelector(SLSelector.postAuthRegister);
  const { data: socialUserLogin } = useSelector(SLSelector.postAuthLoginSocial);
  const { data: socialUserRegister } = useSelector(
    SLSelector.postAuthRegisterSocial
  );

  const [loading, setLoading] = useState(true);

  const appInitializeTokens = async () => {
    const guestLogin = Auth.guest.loggedin();
    const loggedIn = Auth.user.loggedin();

    if (loggedIn) {
      SLAuthAPI({
        method: "GET",
        url: "/user/profile",
      })
        .then((response: any) => {
          SLStorage.setItem("profile", response);
          handleOnLogin();
        })
        .catch((error: any) => {
          if (error.response.status === 401) {
            SLAuthAPI({
              method: "POST",
              url: "/auth/refresh",
              headers: {
                refreshToken: String(loggedIn.refreshToken),
              },
            })
              .then((response: any) => {
                const authType: any = SLStorage.getItem("authType") || "email";
                Auth.user.login(response, authType);
                handleOnLogin();
              })
              .catch(() => {
                handleNotLoggedIn();
              });
          } else {
            handleNotLoggedIn();
          }
        });
    } else if (guestLogin) {
      if (!guestLogin.time || Date.now() - guestLogin.time > 60 * 60 * 1000) {
        dispatch(
          SLThunk.postAuthGuestLogin.call({
            platform: "web",
            deviceInfo,
            deviceId,
          })
        );
      } else {
        handleOnLogin();
      }
    } else {
      handleNotLoggedIn();
    }
  };

  function handleOnLogin() {
    onNewLogin(dispatch);
    mixpanel.login();
    setLoading(false);
    if (guestLogin) {
      navigate("/app/home");
    }
  }

  function handleNotLoggedIn() {
    Auth.user.logout();
    dispatch(SLThunk.getConfig.call());
    setLoading(false);
  }

  function handleOnSignup() {
    handleOnLogin();

    const path = window.location.pathname;
    if (!authRoutesLoggedin.includes(path)) {
      navigate("/app/home");
    }
  }

  useEffect(() => {
    const osc = Oscillator.getInstance(); // workaround for user getsture warning
    SLStorage.removeItem("sceneId"); // remove it so that any updates from other devices are taken into effect

    (async () => {
      appInitializeTokens();
    })();
  }, []);

  useEffect(() => {
    if (!config && configError) {
      setTimeout(() => dispatch(SLThunk.getConfig.call()), 2000);
    }
  }, [configError]);

  useEffect(() => {
    window.scrollTo(0, 0);
    const path = window.location.pathname;
    const auth = SLStorage.getItem("auth");
    const signupCompleted = SLStorage.getItem("signupCompleted");
    if (auth && signupCompleted === true) {
      if (
        window?.location?.pathname &&
        authRoutes.includes(window?.location?.pathname)
      ) {
        navigate("/app/home");
      }
    } else {
      if (!auth && authRoutesLoggedin.includes(path)) {
        navigate("/signup");
      }
    }
    setTimeout(() => {
      mixpanel.trackPageview();
    });
  }, [window.location.href]);

  useEffect(() => {
    if (userLogin) {
      handleOnLogin();
    }
  }, [userLogin]);

  useEffect(() => {
    if (authRegisterData) {
      handleOnSignup();
      initAndCreateCustomer(authRegisterData);
    }
  }, [authRegisterData]);

  useEffect(() => {
    if (socialUserLogin) {
      handleOnLogin();
    }
  }, [socialUserLogin]);

  useEffect(() => {
    if (socialUserRegister) {
      handleOnSignup();
      initAndCreateCustomer(socialUserRegister);
    }
  }, [socialUserRegister]);

  useEffect(() => {
    if (guestLogin) {
      handleOnLogin();
    }
  }, [guestLogin]);

  useEffect(() => {
    if (userProfile) {
      mixpanel.login();
    }
  }, [userProfile]);

  return config && !loading ? (
    <>
      <AppRoutes />
    </>
  ) : (
    <Bootloader />
  );
};

export default App;
