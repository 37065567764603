import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  Box,
  Dialog,
  FormControl,
  FormLabel,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import AuthenticationLayout from "components/authenticationLayout";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import { ErrorMsg } from "components/errorMsg/ErrorMsg";
import { SLLoader } from "components/loader";
import { SLButton } from "components/slButton";
import { InputStandardField } from "components/slTextField";
import { SLTypography } from "components/slTypography";
import dayjs, { Dayjs } from "dayjs";
import { onEnterOrSpace } from "helpers/common/keypress";
import { CrossIcon } from "helpers/enum/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getServerTime } from "utils/getServerTime";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import "./index.css";
import region from "./statesAndCites/index.json";

//for select
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 400,
    },
  },
};

const AdditionalInfo = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { data: profileData } = useSelector(SLSelector.getUserProfile);

  const {
    data: userUpdatedData,
    error,
    loading,
  } = useSelector(SLSelector.patchUserUpdateProfile);

  const [dateError, setDateError] = useState("");
  const [phoneError, setPhoneError] = useState<string | false>(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [submitAdditionalInfo, setSubmitAdditionalInfo] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [serverTime, setServerTime] = useState<Date>();

  const [city, setCity] = useState<string | null>("");
  const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
  const [gender, setGender] = useState<string | null>("");
  const [phoneNumber, setPhoneNumber] = useState<string>();
  const [stateName, setStateName] = useState<string | null>("");

  useEffect(() => {
    dispatch(SLThunk.getUserProfile.call());

    if (!serverTime) {
      getServerTime().then((time: Date) => {
        setServerTime(time);
      });
    }
  }, []);

  useEffect(() => {
    if (profileData) {
      setDateOfBirth(
        profileData?.dob
          ? dayjs(profileData.dob.slice(0, 10)).format("MM/DD/YYYY")
          : ""
      );
      setGender(profileData?.gender || "");
      setPhoneNumber(profileData?.phone ? profileData?.phone : "");
      setStateName(profileData?.state ? profileData?.state : "");
      setCity(profileData?.city ? profileData?.city : "");
    }
  }, [profileData]);

  useEffect(() => {
    if (!submitAdditionalInfo) {
      return;
    }
    if (userUpdatedData) {
      navigate("/uploadPhoto");
      setSubmitAdditionalInfo(false);
    }
    if (error) {
      setErrorDialog(true);
      setValidationError(error);
      setSubmitAdditionalInfo(false);
    }
  }, [userUpdatedData, error]);

  const customSelected = (e: any) => {
    setGender(e.target.name);
  };

  const formattedPhoneNumber = (e: any) => {
    const x = e.target.value
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    e.target.value = !x[2]
      ? x[1]
      : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    setPhoneNumber(e.target.value);
    setPhoneError("");
  };

  const saveAdditionalInfo = () => {
    if (gender || phoneNumber || city || stateName || dateOfBirth) {
      let errors = false;
      if (dateOfBirth) {
        if (dayjs(dateOfBirth).isValid()) {
          if (dayjs(dateOfBirth).isAfter(serverTime || dayjs())) {
            errors = true;
            setDateError("Future date not allowed");
          }
        } else {
          errors = true;
          setDateError("Invalid date format (MM/DD/YYYY)");
        }
      }

      if (phoneNumber && phoneNumber.length < 14) {
        errors = true;
        setPhoneError("Phone number invalid");
      }

      if (errors) {
        return;
      }

      setSubmitAdditionalInfo(true);
      dispatch(
        SLThunk.patchUserUpdateProfile.call({
          gender: gender || null,
          dob: dateOfBirth || null,
          phone: phoneNumber || null,
          city: city || null,
          state: stateName || null,
        })
      );
      // navigate("/uploadPhoto");
    }
    navigate("/uploadPhoto");
  };

  const handleStateChange = (event: any) => {
    setStateName(event.target.value);
    setCity("");
  };

  const handleCityChange = (event: any) => {
    setCity(event.target.value);
  };

  const stateCities = () => {
    if (stateName) {
      const state = region.find((row) => row.state == stateName);
      if (state) {
        return state.cities;
      }
      return [];
    }
    return [];
  };

  return (
    <>
      <AuthenticationLayout pageTitle="Additional Account Info">
        <Grid
          item
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          className="auth-wrapper"
          maxWidth="500px"
          padding="0 2rem"
          zIndex={2}
        >
          <SLTypography
            variant="heading"
            tabIndex={0}
            display="block"
            sx={{
              textAlign: { xs: "left !important", sm: "center !important" },
            }}
          >
            Additional Account Info
          </SLTypography>

          <SLTypography
            variant="bodyParagraph"
            fontSize={14}
            display="block"
            tabIndex={0}
            marginTop={3}
            marginBottom={3}
          >
            Please enter additional information about yourself or you can skip
            this step and complete it later.
            <InfoOutlinedIcon
              style={{
                cursor: "pointer",
                marginBottom: "-6px",
              }}
              onClick={() => setOpenDialog(true)}
              onKeyPress={() => setOpenDialog(true)}
              tabIndex={0}
              titleAccess="Informational Icon"
              aria-label="Informational Icon"
            />
          </SLTypography>

          <Dialog open={openDialog} className="blur-backdrop">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "10px",
              }}
            >
              <InfoOutlinedIcon tabIndex={-1} aria-hidden={true} />

              <CrossIcon
                role="button"
                tabIndex={0}
                titleAccess="Close Dialog"
                style={{ cursor: "pointer" }}
                onClick={() => setOpenDialog(false)}
                onKeyPress={() => setOpenDialog(false)}
              />
            </div>

            <SLTypography
              tabIndex={0}
              sx={{
                fontSize: "15px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "22px",
                color: "var(--sl-deep-purple)",
              }}
            >
              We use your gender to make Soul Link services more personal. When
              you specify your gender, you help us to personalize messages and
              other text that refer to you.
              <div style={{ marginTop: "10px" }}>
                If you don't specify your gender, we'll refer to you in
                gender-neutral terms, like "Send them a message."
              </div>
            </SLTypography>
          </Dialog>

          <div style={{ width: "100%" }}>
            <DatePicker
              disableFuture
              label="Date Of Birth (MM/DD/YYYY)"
              value={dateOfBirth}
              onChange={(date: Dayjs | null) => {
                let value;
                if (!date || !date?.isValid()) {
                  value = "";
                } else {
                  value = date.format("MM/DD/YYYY");
                }
                setDateOfBirth(value);
                setDateError("");
              }}
              renderInput={(params) => (
                <TextField
                  className="font-figtree mt-32"
                  variant="standard"
                  fullWidth
                  {...params}
                  error={false}
                  sx={{ ".MuiSvgIcon-root": { color: "white" } }}
                />
              )}
            />
            <ErrorMsg
              show={Boolean(dateError)}
              text={dateError}
              sx={{ marginLeft: 0 }}
            />
          </div>

          <InputStandardField
            onChange={(e: any) => formattedPhoneNumber(e)}
            helperText={phoneError}
            value={phoneNumber}
            id="phoneNumber"
            title="Phone Number"
            labelId="Phone Number"
            type="text"
            variant="standard"
            props={{
              className: "set-placeholder",
            }}
          />

          <FormControl
            className="city-selection-list"
            sx={{
              width: "100%",
              border: "none",
              borderBottom: "1px solid white",
              color: "white",
              padding: "0px",
              margin: "0px",
            }}
          >
            <InputLabel
              id="demo-multiple-name-label"
              sx={{
                color: "white !important",
                padding: "0px",
                margin: "0px",
                fontFamily: "Figtree",
                zIndex: 0,
              }}
              shrink={!!stateName}
            >
              State
            </InputLabel>

            <Select
              className="vertical-scroll-handling"
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={stateName}
              onChange={handleStateChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
            >
              <MenuItem value="" sx={{ fontFamily: "Figtree" }}>
                None
              </MenuItem>

              {region.map((state, i) => (
                <MenuItem key={state.state + i} value={state.state}>
                  {state.state}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <FormControl
            className={`city-selection-list ${
              stateName === "" ? `disable` : ``
            }`}
            sx={{
              width: "100%",
              border: "none",
              borderBottom: "1px solid white",
              color: "white",
              padding: "0px",
              margin: "0px",
              marginTop: "8px",
            }}
          >
            <InputLabel
              id="demo-multiple-name-label"
              sx={{
                color: "white !important",
                padding: "0px",
                margin: "0px",
                fontFamily: "Figtree",
                zIndex: 0,
              }}
              shrink={city ? true : false}
            >
              City
            </InputLabel>
            <Select
              labelId="demo-multiple-name-label"
              id="demo-multiple-name"
              value={city}
              onChange={handleCityChange}
              input={<OutlinedInput label="Name" />}
              MenuProps={MenuProps}
              sx={{ padding: "0px", margin: "0px" }}
            >
              <MenuItem value="" sx={{ fontFamily: "Figtree" }}>
                None
              </MenuItem>
              {stateCities().map((stateCity, i) => (
                <MenuItem key={stateCity + i} value={stateCity}>
                  {stateCity}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <div style={{ width: "100%" }}>
            <FormControl variant="standard" sx={{ marginTop: "5px" }}>
              <FormLabel
                className="label-input"
                id="demo-radio-buttons-group-label label-input"
                sx={{
                  color: "var(--colorWhite)",
                  fontWeight: "600",
                  fontFamily: "Figtree",
                }}
                title="Gender"
                tabIndex={0}
              >
                Gender
              </FormLabel>

              <div style={{ marginTop: "6%" }} title="Female" tabIndex={0}>
                <input
                  tabIndex={0}
                  type="radio"
                  name="Female"
                  id="radio-option-1"
                  onClick={(e) => customSelected(e)}
                  onKeyDown={(e) => customSelected(e)}
                  checked={gender === "Female" ? true : false}
                />

                <label htmlFor="radio-option-1" className="radio-style">
                  <Box sx={{ display: "flex", gap: "7px" }}>
                    <span />

                    <SLTypography>Female</SLTypography>
                  </Box>
                </label>
              </div>

              <div style={{ marginTop: "6%" }} title="Male" tabIndex={0}>
                <input
                  tabIndex={0}
                  type="radio"
                  name="Male"
                  title="Male"
                  id="radio-option-2"
                  onKeyPress={(e) => customSelected(e)}
                  onClick={(e) => customSelected(e)}
                  checked={gender === "Male" ? true : false}
                />

                <label htmlFor="radio-option-2" className="radio-style">
                  <Box sx={{ display: "flex", gap: "7px" }}>
                    <span />

                    <SLTypography>Male</SLTypography>
                  </Box>
                </label>
              </div>

              <div style={{ marginTop: "6%" }} title="Non-binary" tabIndex={0}>
                <input
                  tabIndex={0}
                  type="radio"
                  name="Non-binary"
                  id="radio-option-3"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      customSelected(e);
                    }
                  }}
                  onClick={(e) => customSelected(e)}
                  checked={gender === "Non-binary" ? true : false}
                />

                <label htmlFor="radio-option-3" className="radio-style">
                  <Box sx={{ display: "flex", gap: "7px" }}>
                    <span />

                    <SLTypography>Non-binary</SLTypography>
                  </Box>
                </label>
              </div>

              <div
                tabIndex={0}
                style={{ marginTop: "6%" }}
                title="Prefer not to say"
              >
                <input
                  tabIndex={0}
                  type="radio"
                  name="Prefer not to say"
                  id="radio-option-4"
                  onClick={(e) => customSelected(e)}
                  onKeyDown={(e) => {
                    if (e.key === "enter") {
                      customSelected(e);
                    }
                  }}
                  checked={gender === "Prefer not to say" ? true : false}
                />

                <label htmlFor="radio-option-4" className="radio-style">
                  <Box sx={{ display: "flex", gap: "7px" }}>
                    <span />

                    <SLTypography>Prefer not to say</SLTypography>
                  </Box>
                </label>
              </div>
            </FormControl>
          </div>

          <FormControl
            sx={{ alignItems: "center !important", marginTop: "20px" }}
            variant="standard"
            fullWidth
          >
            <SLButton
              title="Next"
              messageId="general.next"
              onClick={saveAdditionalInfo}
              onKeyPress={onEnterOrSpace(saveAdditionalInfo)}
              sx={{ width: "50%" }}
            />
          </FormControl>

          <FormControl
            variant="standard"
            fullWidth
            sx={{
              alignItems: "center !important",
              marginTop: "20px",
            }}
          >
            <SLTypography
              title="Skip this Step"
              sx={{
                cursor: "pointer",
                textDecoration: "underline",
                fontSize: "14px",
              }}
              onClick={() => navigate("/uploadPhoto")}
              onKeyPress={() => navigate("/uploadPhoto")}
              messageId="general.skip"
              tabIndex={0}
              aria-level={2}
            />
          </FormControl>
        </Grid>
        <SLLoader open={loading} />
      </AuthenticationLayout>
      <ErrorDialogBox
        errorDialog={errorDialog}
        setErrorDialog={setErrorDialog}
        errorMessage={validationError}
        errorHeaderMessage="Oops!"
      />
    </>
  );
};

export default AdditionalInfo;
