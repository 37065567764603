import { useSelector } from "react-redux";
import { SLSelector } from "utils/slRTKHelper";
import { kebabCase } from "./common/changeCase";

export function useAppUrls() {
  const { data: config } = useSelector(SLSelector.getConfig);

  return {
    profilePic: (path?: string | null, otherwise = "") =>
      !path
        ? otherwise
        : config!.awsS3StorageBaseUrl
            .concat(config!.awsS3ImagesContainer)
            .concat("/")
            .concat(config!.userOriginalImagePrefix)
            .concat(path),

    profilePicThumb: (path?: string | null) =>
      !path
        ? ""
        : config!.awsS3StorageBaseUrl
            .concat(config!.awsS3ImagesContainer)
            .concat("/")
            .concat(config!.userThumbnailImagePrefix)
            .concat(path),

    mediaAudio: (path?: string | null) =>
      !path
        ? ""
        : String(config!.awsS3StorageBaseUrlMedia)
            .concat(config!.awsS3AudioContainer)
            .concat("/")
            .concat(config!.userOriginalImagePrefix)
            .concat(path),

    mediaScene: (path?: string | null) =>
      !path
        ? ""
        : String(config!.awsS3StorageBaseUrlMedia)
            .concat(config!.awsS3SceneContainer)
            .concat("/")
            .concat(config!.userOriginalImagePrefix)
            .concat(path),

    mediaVideo: (path?: string | null) =>
      !path
        ? ""
        : String(config!.awsS3StorageBaseUrlMedia)
            .concat(config!.awsS3VideoContainer)
            .concat("/")
            .concat(config!.userOriginalImagePrefix)
            .concat(path),

    mediaMeditation: (path?: string | null) =>
      !path
        ? ""
        : String(config!.awsS3StorageBaseUrlMedia)
            .concat(config!.awsS3MeditationContainer)
            .concat("/")
            .concat(path),

    mediaImage: (path?: string | null) =>
      !path
        ? ""
        : String(config!.awsS3StorageBaseUrlMedia)
            .concat(config!.awsS3ImagesContainer)
            .concat("/")
            .concat(config!.userOriginalImagePrefix)
            .concat(path),

    mediaImageThumb: (path?: string | null) =>
      !path
        ? ""
        : String(config!.awsS3StorageBaseUrlMedia)
            .concat(config!.awsS3ImagesContainer)
            .concat("/")
            .concat(config!.userThumbnailImagePrefix)
            .concat(path),

    webPageUrl: (path?: string | null) =>
      !path ? "" : config!.awsS3WebPageUrl.concat(path),

    webPageRoute: (title?: string) => "/app/about/".concat(kebabCase(title)),
    // config!.awsS3WebPageUrl.concat("/" ).concat(path),

    webPageImage: (path?: string | null) =>
      !path
        ? ""
        : config!.awsS3WebPageUrl
            .concat(config!.awsS3ImagesContainer)
            .concat("/")
            .concat(path),
  };
}
