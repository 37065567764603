import { SLTypography } from "components/slTypography";
import { Quotes } from "helpers/enum/constants";
import { useSelector } from "react-redux";
import { SLSelector } from "utils/slRTKHelper";

export function DailyInspirationQuote() {
  const { data: todaysQuoteData } = useSelector(
    SLSelector.getDailyInspirationGetTodaysQuote
  );

  return (
    <>
      {todaysQuoteData?.dailyQuote ? (
        <div>
          <img style={{ display: "block" }} src={Quotes} />
          <SLTypography
            variant="headLine"
            sx={{
              color: "#fff",
              display: "inline-block",
              fontSize: { xs: "19px", lg: "26px" },
              lineHeight: { xs: "22.5px", lg: "32px" },
              maxHeight: { xs: "150px", lg: "192px" },
              maxWidth: { xs: "100%", md: "700px" },
              overflow: "hidden",
              paddingLeft: 0,
              paddingTop: { md: "6px" },
              position: "relative",
              zIndex: "1",
            }}
            tabIndex={0}
          >
            {todaysQuoteData?.dailyQuote}
            <img
              style={{
                display: "inline",
                transform: "rotate(180deg)",
                marginBottom: "5px",
                marginLeft: "3px",
              }}
              src={Quotes}
            />
          </SLTypography>
        </div>
      ) : null}
    </>
  );
}
