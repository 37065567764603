import { Dialog, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { lang } from "helpers/common/lang";
import { SLButton } from "../../slButton";

interface IStopCastingDialog {
  open: boolean;
  deviceName: string;
  handleStopCasting: () => void;
  handleKeepOnCasting: () => void;
}

const StopCastingDialog = ({
  open,
  deviceName,
  handleStopCasting,
  handleKeepOnCasting,
}: IStopCastingDialog) => {
  return (
    <Dialog open={open} className="backdrop-blur dailogBoxWidth">
      <Stack
        direction="column"
        gap={2}
        sx={{
          background: "var(--bg-gradient)",
          padding: { xs: 2, sm: 4 },
          width: { xs: "280px", md: "340px" },
          maxWidth: "90%",
          borderRadius: "10px",
          textAlign: "center",
        }}
      >
        <SLTypography variant="smallBody" color="text-deepPurple">
          Currently casting to {deviceName}
        </SLTypography>

        <SLButton
          tabIndex={0}
          onClick={handleStopCasting}
          title={lang("casting.stop")}
          messageId={"casting.stop"}
        />
        <SLButton
          tabIndex={0}
          slClass="aqua"
          onClick={handleKeepOnCasting}
          title={lang("casting.resume")}
          messageId={"casting.resume"}
        />
      </Stack>
    </Dialog>
  );
};

export default StopCastingDialog;
