import { Box, Grid, List, ListItem, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  ArrowForwardIosIcon,
  InfoOutlined,
  SupernalWorldCreationsLogo,
} from "helpers/enum/constants";

const profileLinks = [
  {
    iconButton: <InfoOutlined sx={{ width: "18px", height: "18px" }} />,
    name: "Learn More",
  },
];

const SupernalWorld = () => {
  return (
    <>
      <Box
        id="super-world"
        sx={{
          color: "var(--sl-deep-purple)",
          minHeight: {
            xl: "87vh",
            lg: "85vh",
            md: "65vh",
            sm: "65vh",
            xs: "65vh",
          },
        }}
      >
        <Grid container>
          <Grid
            item
            lg={12}
            // className="grid-container-main"
            sx={{
              paddingTop: {
                xl: "0px",
                lg: "0px",
                md: "30px",
                sm: "30px",
                xs: "30px",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: {
                  xl: "350px",
                  lg: "350px",
                  md: "350px",
                  sm: "250px",
                  xs: "215px",
                },
              }}
              alt="The Supernal World Creations"
              src={SupernalWorldCreationsLogo}
              tabIndex={0}
            />
          </Grid>
          <Grid
            item
            sx={{
              paddingTop: {
                xl: "50px",
                lg: "50px",
                md: "30px",
                sm: "25px",
                xs: "20px",
              },
            }}
          >
            <Stack spacing={"10px"}>
              <SLTypography
                variant="smallBody"
                sx={{ fontSize: { xs: "14px", sm: "17px" } }}
                tabIndex={0}
              >
                <b>Supernal World Creations</b> is an organization that helps
                humanity by providing the App Soul Link, an innovative sound &
                visual tool that yields beneficial effects on physical,
                emotional, mental health and cognitive performance, to
                transform, heal, connect, and harmonize all living beings.
              </SLTypography>
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ fontSize: { xs: "14px", sm: "17px" } }}
              >
                Based in Southwest Florida and established in 2019, Supernal
                World Creations is an organization that helps humanity by
                providing Innovative Applications that have a sound & visual
                Patented Formulated Embodiment: a blending of Digital
                Audio/Video that incorporates modern sound technology and
                ancient auditory schemes for the purpose of positively and
                effectively changing the brain structure of the user.
              </SLTypography>
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ fontSize: { xs: "14px", sm: "17px" } }}
              >
                This patented technology effortlessly brings the user to a
                higher vibration through a highly formulated proprietary blend
                of harmonic and melodic sound elements; meditation through
                binaural beats and visual stimuli rooted in quantum theory.
              </SLTypography>
            </Stack>
          </Grid>
          <Grid item lg={12} md={12} xs={12}>
            <List
              sx={{
                width: 1,
                paddingTop: "0px",
              }}
              // onClick={() => toggleDrawer(false)}
            >
              {profileLinks.map((data: any, index) => {
                return (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        paddingTop: {
                          xl: "50px",
                          lg: "50px",
                          md: "50px",
                          sm: "20px",
                          xs: "20px",
                        },
                        paddingBottom: {
                          xl: "150px",
                          lg: "150px",
                          md: "30px",
                          sm: "20px",
                          xs: "20px",
                        },
                        paddingRight: { md: "24px", lg: "0px", xl: "0px" },
                      }}
                    >
                      <a
                        key={"sidebaritem_id" + index}
                        href="https://supernalworldcreations.org/"
                        target="_blank"
                        style={{ textDecoration: "none", width: "100%" }}
                      >
                        <ListItem
                          //   onClick={() => handleListItems(data)}
                          key={index}
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "10px",
                            borderRadius: "10px",
                            background: "#FFFFFF",
                            cursor: "pointer",
                            color: "var(--sl-deep-purple)",
                            height: "50px",
                            textDecoration: "none",
                          }}
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "center",
                              gap: "10px",
                              fontSize: { xs: "14px" },
                            }}
                          >
                            {data.iconButton}

                            <SLTypography
                              variant="title"
                              sx={{ fontSize: { xs: "14px" } }}
                            >
                              {data.name}
                            </SLTypography>
                          </Box>
                          <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
                        </ListItem>
                      </a>
                    </Box>
                  </>
                );
              })}
            </List>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SupernalWorld;
