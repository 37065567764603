import { TypographyProps } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { IntlMessageID } from "helpers/enum";

type IPageHeading = {
  messageId?: IntlMessageID;
} & TypographyProps;

export function PageHeading(props: IPageHeading) {
  return (
    <SLTypography
      id="page-heading"
      {...props}
      role="heading"
      tabIndex={0}
      variant="largeHeader"
      color="text-deepPurple"
      display="block"
      sx={{
        marginBottom: { md: "40px", sm: "30px", xs: "12px" },
        marginTop: 0,
        fontSize: { md: "37px", xs: "22px" },
      }}
      messageId={props.messageId ? props.messageId : undefined}
    >
      {props.children}
    </SLTypography>
  );
}
