/* eslint-disable indent */
import AddIcon from "@mui/icons-material/Add";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Stack,
} from "@mui/material";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import ListingPageSkeleton from "components/listingSkeleton";
import MeditationTags from "components/meditationTag";
import { SLTypography } from "components/slTypography";
import { lang } from "helpers/common/lang";
import { useDragger } from "helpers/common/useDragger";
import { MediaType } from "helpers/enum";
import { ArrowDownWhiteIcon, SoulPurpleIcon } from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import { meditationSlickSettings } from "utils/meditationSlickSettings";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { makeCancelableSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLStorage } from "utils/slStorage";
import MediationCard from "../mediationCard";
import { NotFoundMessage } from "../slTypography/NotFoundMessage";
import "./index.css";

const PAGE_SIZE = 20;
const cancellableRequests: Record<string, any> = {};

const MeditationAccordian = (props: any) => {
  const mixpanel = useMixpanel();
  const appUrls = useAppUrls();
  const dragger = useDragger();

  const sliderRef: any = useRef(null);

  const {
    mediatags,
    mediatype,
    duration,
    mediaicon,
    mediawhiteicon,
    selectedmedia,
    mediatitle,
    addedToFavourites,
    successMessageText,
    accordianhandleChange,
    open,
    selectedMeditationData,
    setSelectedMeditationDataflag,
    selectedMeditationDataFlag,
    setCardExistingData,
    chooseMediaDescription,
    setTutorialMeditation,
    fromPage,
  } = props;

  const [backgroundImageAccordian, setBackgroundImageAccordian] = useState("");
  const [chooseAudio, setChooseAudioText] = useState<any>(
    "Choose " + mediatitle
  );

  const [getAllMediaByType, setAllMediaByType] = useState<any>();
  const [onSelectMediaType, setOnSelectMediaType] = useState<any>();
  const [tagId, setTagId] = useState<string>("All");
  const [totalCount, setTotalCount] = useState(0);
  const [isFavorite, setIsFavorite] = useState(false);
  const [data, setData] = useState<any>();
  const [customLoader, setCustomLoader] = useState(false);
  const [loading, setLoading] = useState(true);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [mediaID, setmediaID] = useState("");
  const [BackgroundImage, setBackgroundImage] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const confirmChangeDialog = useDialog({
    title: lang("meditation.creator.confirmChange.title"),
  });
  const [onMeditationSelection, setOnMeditationSelection] = useState<any>("");

  const nextClick = (currentSlide: any) => {
    const elementSelect = document.querySelector(".slick-next.slick-disabled");
    if (elementSelect?.matches(".slick-disabled")) {
      if (getAllMediaByType?.data?.length < totalCount) {
        setCustomLoader(true);
        setPageNo(pageNo + 1);
      }
    }
  };
  const settings = meditationSlickSettings(nextClick);

  useEffect(() => {
    if (mediatags) {
      mediatags?.unshift({ id: "myfavorites", title: "My Favorites" });
      mediatags?.unshift({ id: "All", title: "All" });
    }
  }, [mediatags]);

  useEffect(() => {
    if (selectedMeditationData && selectedMeditationData.id !== undefined) {
      setChooseAudioText(selectedMeditationData.title);
      setBackgroundImageAccordian(
        "url(" +
          appUrls.mediaImage(selectedMeditationData.image) +
          "),url(	https://d3jg041fdli8ap.cloudfront.net/images/original_JlxgaB9mo2EviRAXoW6ez09kDeFmly7R)"
      );
      setmediaID(selectedMeditationData.id);

      if (getAllMediaByType?.data?.length > 0) {
        setAllMediaByType({
          ...getAllMediaByType,
          data: getAllMediaByType?.data?.map((obj: any) => {
            return {
              ...obj,
              isSelected: obj.id === selectedMeditationData.id ? true : false,
            };
          }),
        });
      }

      // selectedmedia({ ...selectedMeditationData }); Commented as on selected file update duration creates jerk due to this data load.
      //getAllMediaData();
    } else {
      setOnSelectMediaType("");
      setDynamicTitle("");
      setmediaID("");
      setBackgroundImage("");
      setBackgroundImageAccordian("");
      setChooseAudioText("Choose " + mediatitle);

      if (getAllMediaByType?.data?.length > 0) {
        // setAllMediaByType({
        //   ...getAllMediaByType,
        //   data: getAllMediaByType?.data?.map((obj: any) => {
        //     return {
        //       ...obj,
        //       isSelected: false,
        //     };
        //   }),
        // });
      }
    }
  }, [selectedMeditationData]);

  useEffect(() => {
    const matchedItem = onSelectMediaType?.data?.some(
      (item: any) => item.isSelected === true
    );
    const selectedMatchedItem = onSelectMediaType?.data?.filter(
      (item: any) => item.isSelected === true
    );
    setBackgroundImageAccordian(
      !matchedItem
        ? ""
        : "url(" +
            BackgroundImage +
            "),url(https://d3jg041fdli8ap.cloudfront.net/images/original_JlxgaB9mo2EviRAXoW6ez09kDeFmly7R)"
    );

    setChooseAudioText(!matchedItem ? "Choose " + mediatitle : dynamicTitle);
    const selectedObj = {
      id: mediaID,
      title: dynamicTitle,
      image: selectedMatchedItem && selectedMatchedItem[0]?.image,
    };
    !matchedItem ? selectedmedia(null) : selectedmedia(selectedObj);
    // }
  }, [onSelectMediaType]);

  useEffect(() => {
    getAllMediaData();
  }, [tagId]);

  useEffect(() => {
    if (pageNo > 1) {
      getAllMediaData();
    }
  }, [pageNo]);

  const callApiMediaByType = async (page = 1) => {
    const params = {
      limit: PAGE_SIZE,
      page,
      sort: "ASC",
      sortBy: "title",
      type: mediatype.trim(),
      duration: duration === undefined ? 12 : duration,
      tag: tagId === "My Favorites" || tagId === "all" ? "" : tagId,
      isFavourite: tagId === "My Favorites" ? true : false,
    };
    const makeCallObj: any = {
      method: "get",
      url: "/media/by-type",
      urlParams: "",
      payload: "",
      params: params,
    };

    if (pageNo > 1) {
      setCustomLoader(true);
    } else {
      setLoading(true);
    }

    if (cancellableRequests[mediatype.trim()]) {
      cancellableRequests[mediatype.trim()]();
    }

    const { axiosPromise, cancel } = makeCancelableSLAPICall(makeCallObj);

    cancellableRequests[mediatype.trim()] = cancel;

    const response = (await axiosPromise)?.data?.result;
    return { response, params };
  };

  const getAllMediaData = async () => {
    try {
      const { response, params } = await callApiMediaByType(pageNo);

      if (response) {
        delete cancellableRequests[mediatype.trim()];
        const tagTitle = isFavorite ? "My Favorites" : params.tag;
        const tagId = isFavorite ? "My Favorites" : params.tag;
        let screen: keyof typeof mixpanel.constants.SCREENS;

        if (params.type === MediaType.Audio) {
          screen =
            fromPage == "HOME" ? "HOME_AUDIO" : "CREATE_MEDITATION_AUDIO";
        } else if (params.type === MediaType.Video) {
          screen =
            fromPage == "HOME" ? "HOME_VIDEO" : "CREATE_MEDITATION_VIDEO";
        } else {
          screen =
            fromPage == "HOME"
              ? "HOME_FREQUENCY_WAVE"
              : "CREATE_MEDITATION_FREQUENCY_WAVE";
        }

        if (tagTitle) {
          mixpanel.triggerTagUsedEvent(screen, tagId, tagTitle);
        }

        setData(response);
        if (setTutorialMeditation) {
          if (mediatype.trim() === "audio") {
            const mediaObj = {
              audio: {
                id: response?.data[0]?.id,
                title: response?.data[0]?.title,
                image: response?.data[0]?.image,
              },
            };
            setTutorialMeditation(mediaObj);
          } else if (mediatype.trim() === "video") {
            const mediaObj = {
              video: {
                id: response?.data[0]?.id,
                title: response?.data[0]?.title,
                image: response?.data[0]?.image,
              },
            };
            setTutorialMeditation(mediaObj);
          } else if (mediatype.trim() === "frequencyWave") {
            const mediaObj = {
              frequencywave: {
                id: response?.data[0]?.id,
                title: response?.data[0]?.title,
                image: response?.data[0]?.image,
              },
            };
            setTutorialMeditation(mediaObj);
          }
        }
      } else {
        setLoading(false);
        setCustomLoader(false);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (data && mediatype == MediaType.FrequencyWave) {
      return;
    }

    const getMediaByType = async () => {
      setOnSelectMediaType("");
      setDynamicTitle("");
      setmediaID("");
      setBackgroundImage("");

      const { response } = await callApiMediaByType(1);

      if (response) {
        delete cancellableRequests[mediatype.trim()];
        setData(response);
      } else {
        setLoading(false);
        setCustomLoader(false);
      }
    };

    getMediaByType();
  }, [duration]);

  useEffect(() => {
    if (data) {
      if (pageNo > 1) {
        const newMergedData = [...getAllMediaByType.data, ...data.data];

        setAllMediaByType({
          ...getAllMediaByType,
          data: newMergedData?.map((obj: any) => {
            return {
              ...obj,
              isSelected: obj.id === mediaID,
            };
          }),
        });
      } else {
        setTotalCount(data.totalCount);
        setAllMediaByType({
          data: data?.data?.map((obj: any) => {
            return {
              ...obj,
              isSelected: obj.id === mediaID,
            };
          }),
        });
      }
    }
  }, [data]);

  const onTagChange = (tagID: string) => {
    setPageNo(1);
    debugger;
    setTagId(tagID);
    if (tagID === "null") {
      setTagId("All");
    }
  };

  const onSelectionChanged = (
    id: any,
    mediaCardImage: string,
    selectedCardData: any
  ) => {
    if (selectedMeditationDataFlag) {
      setOnMeditationSelection({
        id: id,
        mediaCardImage: mediaCardImage,
        selectedCardData: selectedCardData,
      });
      confirmChangeDialog.show();
    } else {
      onSelectionCard(id, mediaCardImage, selectedCardData);
    }
  };

  const onSelectionCard = (
    id: any,
    mediaCardImage: string,
    selectedCardData: any
  ) => {
    const lowerCaseType = selectedCardData?.type
      .toLowerCase()
      .replace("frequencywave", "frequency");
    const parts = mediaCardImage.split("/");
    const lastString = parts[parts.length - 1];
    const modifiedLastString = lastString.replace("original_", "");
    let MeditationData = SLStorage.getItem("selectedMeditationDataCreate");
    if (!MeditationData) {
      MeditationData = {};
    }
    if (
      MeditationData &&
      MeditationData.hasOwnProperty(lowerCaseType) &&
      MeditationData[lowerCaseType]
    ) {
      if (MeditationData[lowerCaseType].id === id) {
        delete MeditationData[lowerCaseType];
      } else {
        MeditationData[lowerCaseType] = {
          id: id,
          title: selectedCardData?.title,
          image: modifiedLastString,
        };
      }
    } else {
      MeditationData[lowerCaseType] = {
        id: id,
        title: selectedCardData?.title,
        image: modifiedLastString,
      };
    }
    if (selectedCardData?.duration) {
      MeditationData.duration = selectedCardData?.duration;
    }
    SLStorage.setItem("selectedMeditationDataCreate", MeditationData);
    setAllMediaByType({
      ...getAllMediaByType,
      data: getAllMediaByType?.data?.map((obj: any) => {
        return {
          ...obj,
          isSelected: obj.isSelected ? false : obj.id === id,
        };
      }),
    });
    setOnSelectMediaType({
      ...getAllMediaByType,
      data: getAllMediaByType?.data?.map((obj: any) => {
        return {
          ...obj,
          isSelected: obj.isSelected ? false : obj.id === id,
        };
      }),
    });
    setDynamicTitle(selectedCardData?.title);
    setBackgroundImage(mediaCardImage);
    setmediaID(id);
  };

  useEffect(() => {
    if (getAllMediaByType) {
      setCustomLoader(false);
      setLoading(false);
    }
  }, [getAllMediaByType]);

  const handleLikedMediaCard = (text: string) => {
    addedToFavourites(true);
    successMessageText(text);
    setTimeout(() => {
      addedToFavourites(false);
    }, 1500);
  };

  const filterUnFavouriteMediaItem = async (id: string) => {
    if (tagId === "111") {
      const filteredMedia = getAllMediaByType?.data?.filter(
        (obj: any) => obj.id !== id
      );
      setAllMediaByType({
        data: filteredMedia,
      });
    }
  };

  const confirmDialogue = () => {
    setSelectedMeditationDataflag(false);
    setCardExistingData("");
    onSelectionCard(
      onMeditationSelection?.id,
      onMeditationSelection?.mediaCardImage,
      onMeditationSelection
    );
    confirmChangeDialog.hide();
  };

  return (
    <Box
      sx={{
        marginTop: { xs: "20px", md: "30px" },
      }}
    >
      <Accordion
        disabled={props.loading}
        defaultExpanded={false}
        // className="custom-accordian"
        className={`custom-accordian ${
          !backgroundImageAccordian ? `notselectedAccordian` : ``
        }`}
        onChange={accordianhandleChange(mediatype)}
        expanded={open}
        sx={{ background: "transparent" }}
      >
        <AccordionSummary
          expandIcon={
            open ? (
              backgroundImageAccordian ? (
                <KeyboardArrowDownIcon />
              ) : (
                <RemoveIcon />
              )
            ) : backgroundImageAccordian ? (
              <img style={{ margin: "0 5px" }} src={ArrowDownWhiteIcon} />
            ) : (
              <AddIcon />
            )
          }
          className={backgroundImageAccordian ? "whiteIcon" : ""}
          sx={{
            backgroundImage: backgroundImageAccordian
              ? `linear-gradient(rgba(0,0,0,.3),rgba(0,0,0,.3)),${backgroundImageAccordian}`
              : "",
            borderRadius: backgroundImageAccordian
              ? "10px !important"
              : "10px 10px 0px 0px",
            "&.MuiButtonBase-root.MuiAccordionSummary-root:focus": {
              outline: "2px solid black",
              backgroundColor: "white",
            },
          }}
        >
          <img
            className="audio-icon"
            tabIndex={-1}
            aria-hidden={true}
            alt={"icon"}
            src={!backgroundImageAccordian ? mediaicon : mediawhiteicon}
          />
          <SLTypography
            variant="subhead"
            color={!backgroundImageAccordian ? "text-deepPurple" : "text-white"}
            sx={{
              width: "80%",
              height: "20px",
              overflow: "hidden",
              fontSize: { xs: "14px", md: "16px" },
              fontWeight: "700",
            }}
          >
            {chooseAudio}
          </SLTypography>
          <SLTypography
            variant="smallFooter"
            style={{
              display: backgroundImageAccordian ? "inline-block" : "none",
              position: "absolute",
              right: "50px",
              lineHeight: "13px",
            }}
            color={!backgroundImageAccordian ? "text-deepPurple" : "text-white"}
          >
            {mediatitle}
          </SLTypography>
        </AccordionSummary>
        <AccordionDetails sx={{ paddingTop: 0 }}>
          <Box
            className="background-change-text"
            sx={{ borderRadius: "0px 0px 10px 10px" }}
          >
            <SLTypography
              variant="extraSmall"
              color="text-deepPurple"
              sx={{
                maxWidth: "550px",
                display: "inline-block",
                fontSize: { xs: "12px", md: "14px" },
                margin: "5px 0 1rem 3rem",
              }}
              tabIndex={0}
            >
              {chooseMediaDescription}
            </SLTypography>
          </Box>

          <Box
            className="d-flex justify-space-between align-items-center p-relative tagContainer"
            sx={{
              marginBottom: { sm: "20px", md: "0px" },
              paddingLeft: { xs: "0px", sm: "25px" },
              marginTop: !backgroundImageAccordian
                ? {
                    xs: "12px",
                    sm: "15px",
                    md: "20px",
                  }
                : "7px",
            }}
          >
            <MeditationTags
              filteredTags={props?.mediatags}
              onTagChange={onTagChange}
            />
          </Box>

          <div
            className="slider-container"
            onMouseMove={dragger.preventClickWhenDragging}
            onMouseUp={dragger.detectStopDragging}
            onMouseLeave={dragger.detectStopDragging}
          >
            {loading ? (
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "15px",
                  overflow: "hidden",
                  marginRight: "0px",
                }}
              >
                <ListingPageSkeleton
                  showSkeleton={true}
                  type="card"
                  count={PAGE_SIZE}
                />
              </Stack>
            ) : getAllMediaByType?.data?.length ? (
              <>
                {customLoader ? (
                  <Box className="loaderContainer">
                    <div className="loader" />
                    <Avatar
                      className="rotate"
                      alt="Loading Image"
                      src={SoulPurpleIcon}
                      sx={{ width: 120, height: 120, margin: "auto" }}
                    />
                  </Box>
                ) : null}

                <Slider
                  ref={sliderRef}
                  {...settings}
                  arrows={true}
                  className="meditationSlider"
                >
                  {getAllMediaByType?.data?.map((data: any, index: number) => (
                    <div key={data.id} className="meditation-cards">
                      <MediationCard
                        selectedCardData={data}
                        onSelectionChanged={onSelectionChanged}
                        cardSelection="createMeditation"
                        likedMediaCard={handleLikedMediaCard}
                        filterUnFavouriteMediaItem={filterUnFavouriteMediaItem}
                        cardType="Media"
                        plusIcon={true}
                        dragging={dragger.isDragging}
                      />
                    </div>
                  ))}
                </Slider>
              </>
            ) : (
              <NotFoundMessage
                notFoundMessage={`No ${props.mediatitle} Found`}
                height="100px"
              />
            )}
          </div>
        </AccordionDetails>
      </Accordion>

      <SLDialog
        dialog={confirmChangeDialog}
        dismissable={true}
        onDismiss={confirmChangeDialog.hide}
        actionButtons={{
          yesMessageId: "general.confirm",
          onYesButtonClicked: confirmDialogue,
          noMessageId: "general.cancel",
          onNoButtonClicked: confirmChangeDialog.hide,
        }}
      />
    </Box>
  );
};
export default MeditationAccordian;
