import { Box } from "@mui/material";
import ExploreSoulLink from "components/explore/parentExplore";
import PageTitle from "components/pageTitle/pageTitle";
import React from "react";

const Explore: React.FC = () => {
  return (
    <>
      <Box>
        <PageTitle title="Explore" />
        <ExploreSoulLink />
      </Box>
    </>
  );
};

export default Explore;
