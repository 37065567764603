/* eslint-disable indent */
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { lang } from "helpers/common/lang";
import {
  AuthBackgroundImage,
  CrossWhiteIconWithBorder,
  SubscriptionActivation,
} from "helpers/enum/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reduceMotionState } from "store/reduceMotionSlice";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import { SLButton } from "../slButton";
import DonationColumn from "./donationColumn";
import SubscriptionTextualColumn from "./textInformationColumn";

const SubscriptionDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const signupCheck = SLStorage.getItem("signupCompleted");
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const reduceMotion = useSelector(reduceMotionState);
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);

  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    dispatch(SLThunk.getSubscription.call());
  }, []);

  useEffect(() => {
    if (userSubscription && signupCheck !== false) {
      navigate("/app/home");
    }
  }, [userSubscription]);

  const navigateToHome = () => {
    const prvUrl = sessionStorage.getItem("prvUrl");
    sessionStorage.removeItem("prvUrl");
    prvUrl ? navigate(prvUrl) : navigate("/app/home");
  };

  const restorePurchase = () => {
    if (userSubscription) {
      navigate("/app/home");
    } else {
      setOpenDialog(true);
    }
  };

  return (
    <>
      <Box
        sx={{
          background: `url(${AuthBackgroundImage})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          minHeight: "100vh",
        }}
      >
        {!reduceMotion ? (
          <video
            autoPlay={true}
            muted
            loop
            playsInline
            id="myVideo"
            className="authenticationLayout"
            style={{ position: "fixed" }}
          >
            <source src="/assets/videos/auth.webm" />
          </video>
        ) : null}
        <Box
          sx={{
            zIndex: 2,
            position: "relative",
            minHeight: "100%",
          }}
        >
          <Grid container>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                paddingRight: {
                  xs: "25px",
                  sm: "45px",
                  md: "65px",
                  lg: "100px",
                },
                paddingTop: { xs: "25px", sm: "45px", md: "65px" },
              }}
            >
              <Box
                component="img"
                className="cross-icon"
                src={CrossWhiteIconWithBorder}
                aria-label="Remove it"
                title="Remove it"
                tabIndex={0}
                sx={{ width: "30px", height: "30px", cursor: "pointer" }}
                onClick={() => navigateToHome()}
                onKeyPress={() => navigateToHome()}
              />
            </Grid>
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                paddingBottom: { xs: "25px", sm: "50px" },
              }}
            >
              <Box
                component="img"
                className="purple-icon"
                tabIndex={0}
                title="Soul Link Logo Image"
                src={SubscriptionActivation}
                aria-label="Soul Link Logo Image"
                sx={{
                  width: { xs: "140px", sm: "200px" },
                  height: { xs: "140px", sm: "200px" },
                }}
              />
            </Grid>
            <Divider sx={{ background: "white", opacity: "0.2" }} />
            <Grid
              item
              lg={12}
              md={12}
              xs={12}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
              }}
            >
              <Grid
                item
                lg={6}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "center", md: "flex-end" },
                  alignItems: "flex-start",
                }}
              >
                <SubscriptionTextualColumn />
              </Grid>
              <Divider
                orientation={isSmallScreen ? "horizontal" : "vertical"}
                sx={{
                  background: "white",
                  opacity: "0.2",
                }}
              />
              <Grid
                item
                lg={6}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <DonationColumn />
              </Grid>
            </Grid>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              color: "white",
              gap: "20px",
              paddingTop: "30px",
              paddingLeft: { xs: "16px", sm: "0px" },
            }}
          >
            <Stack direction={"row"} sx={{ gap: { xs: "12px", sm: "20px" } }}>
              <SLTypography
                tabIndex={0}
                variant="bodyUnderlined"
                onClick={() => navigateToHome()}
                sx={{ fontSize: { xs: "12px", sm: "14px" }, cursor: "pointer" }}
              >
                Begin Free Meditations
              </SLTypography>
              <SLTypography>•</SLTypography>
              <SLTypography
                tabIndex={0}
                onClick={() => restorePurchase()}
                variant="bodyUnderlined"
                sx={{ fontSize: { xs: "12px", sm: "14px" }, cursor: "pointer" }}
              >
                Restore Purchase
              </SLTypography>
              <SLTypography>•</SLTypography>
              <a
                href={lang("general.terms-url")}
                aria-label={lang("general.terms")}
                target="_blank"
              >
                <SLTypography
                  tabIndex={0}
                  variant="bodyUnderlined"
                  sx={{
                    fontSize: { xs: "12px", sm: "14px" },
                    cursor: "pointer",
                    color: "white",
                  }}
                  messageId="general.terms"
                />
              </a>
            </Stack>
          </Box>
        </Box>
      </Box>

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "10px" }}
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
            gap: "0.5rem",
            padding: "15px 25px",
            color: "var(--sl-deep-purple)",
            maxWidth: "350px",
          }}
        >
          <SLTypography
            sx={{ fontWeight: "600", fontSize: "20px", fontFamily: "Figtree" }}
          >
            Oops!
          </SLTypography>
          <SLTypography
            sx={{ fontWeight: "400", fontSize: "14px", fontFamily: "Figtree" }}
          >
            No Subscription Found.
          </SLTypography>

          <SLButton
            sx={{ width: "200px" }}
            variant="contained"
            messageId={"general.ok"}
            onClick={() => setOpenDialog(false)}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SubscriptionDetails;
