import { Box, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  VideoCardHowItWorks,
  VideoCardHowItWorksStatic,
} from "helpers/enum/constants";
import { useSelector } from "react-redux";
import { reduceMotionState } from "store/reduceMotionSlice";

const CreationOfSoulLink = () => {
  const reduceMotion = useSelector(reduceMotionState);

  return (
    <>
      <Box>
        <Stack>
          <SLTypography
            tabIndex={0}
            role="heading"
            variant="headLine"
            sx={{ paddingBottom: "15px", fontSize: { xs: "19px", md: "27px" } }}
            title="Why was Soul Link created?"
          >
            Why was Soul Link created?
          </SLTypography>
          <SLTypography
            tabIndex={0}
            variant="smallBody"
            sx={{ fontSize: { xs: "14px", md: "17px" } }}
            title="Your body is like a musical instrument. Every bone, cell, and tissue
            in your body can be tuned to a specific frequency to function
            optimally. Toxins, stress, negative life experiences, and many other
            factors can pull your cells out of tune. When cells in the body
            resonate at their optimal vibrational frequency it creates harmony,
            promoting health, balance, and relaxation. If cells vibrate in
            disharmony, it creates imbalance leaving the body susceptible to
            disease. This is explained in mainstream physics which states that
            Resonance Shapes Reality, therefore Optimal Resonance = Perfect
            Harmonic Equilibrium."
          >
            Your body is like a musical instrument. Every bone, cell, and tissue
            in your body can be tuned to a specific frequency to function
            optimally. Toxins, stress, negative life experiences, and many other
            factors can pull your cells out of tune. When cells in the body
            resonate at their optimal vibrational frequency it creates harmony,
            promoting health, balance, and relaxation. If cells vibrate in
            disharmony, it creates imbalance leaving the body susceptible to
            disease. This is explained in mainstream physics which states that
            Resonance Shapes Reality, therefore Optimal Resonance = Perfect
            Harmonic Equilibrium.
          </SLTypography>
          <Box
            component="img"
            src={reduceMotion ? VideoCardHowItWorksStatic : VideoCardHowItWorks}
            sx={{
              borderRadius: "10px",
              marginTop: "40px",
              marginBottom: "7px",
            }}
            tabIndex={0}
            alt="Animation Video"
          />
          <SLTypography
            tabIndex={0}
            variant="smallBody"
            sx={{ fontSize: { xs: "14px", md: "17px" }, marginBottom: "40px" }}
            title="[Over rugged, dry mountains, white clouds drift, casting fleeting shadows on the sunlit peaks]"
          >
            [Over rugged, dry mountains, white clouds drift, casting fleeting
            shadows on the sunlit peaks]
          </SLTypography>
          <SLTypography
            role="heading"
            variant="headLine"
            tabIndex={0}
            sx={{ paddingBottom: "15px", fontSize: { xs: "19px", md: "27px" } }}
          >
            The Proven benefits of Soul Link.
          </SLTypography>
          <SLTypography
            variant="smallBody"
            tabIndex={0}
            sx={{ fontSize: { xs: "14px", md: "17px" } }}
          >
            Negative life experiences that created emotions such as anger, fear,
            stress, resentment, guilt, and sadness can become trapped in
            specific areas of the physical body. Trapped emotions, often called
            energy blocks, create physical, emotional, and mental disharmony. We
            can hold these energy blocks with little or no awareness as some of
            the causal events may have occurred at a very young age causing us
            to enact unhealthy patterns to compensate for these blocks.
          </SLTypography>
          <SLTypography
            variant="smallBody"
            sx={{
              paddingTop: "15px",
              paddingBottom: "12px",
              fontSize: { xs: "14px", md: "17px" },
            }}
            tabIndex={0}
          >
            The combination of the Musical Mantras and the Visual formula has
            transformational abilities. It positively reprograms the brain
            without requiring much effort from the user. The transformation
            happens for the user as it doesn’t need to “teach” the user how to
            meditate. Just by creating a habit of experiencing the sound and
            video formula the user will reap cognitive and physical benefits.
            Soul Link is the only meditation formula that will effectively and
            positively reprogram the perception of the user without the need for
            hypnosis or verbal repetition.
          </SLTypography>
        </Stack>
      </Box>
    </>
  );
};

export default CreationOfSoulLink;
