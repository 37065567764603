import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {
  Box,
  FilledInput,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  Switch,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { SLButton } from "../../slButton";

const SetUpAchievement = () => {
  return (
    <>
      <Box
        sx={{
          width: { lg: "46.7%", md: "95%", xs: "100%" },
          fontSize: "16px",
          fontWeight: "400",
          testAlign: { lg: "center", md: "left", xs: "left" },
        }}
      >
        <Box
          sx={{
            testAlign: { lg: "center", md: "left", xs: "left" },
            display: "flex",
            flexDirection: "column",
            justifyContent: { lg: "center", md: "left", xs: "left" },
            alignItems: { lg: "center", md: "left", xs: "left" },
            paddingTop: { lg: "90px", md: "60px", xs: "40px" },
          }}
        >
          <SLTypography
            tabIndex={0}
            sx={{
              fontSize: "32px",
              fontWeight: "700",
              paddingLeft: { lg: "", md: "24px", xs: "16px" },
            }}
          >
            Set up Achievement
          </SLTypography>
          <SLTypography
            tabIndex={0}
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              paddingLeft: { lg: "", md: "24px", xs: "16px" },
            }}
          >
            Set the right Achievement for yourself
          </SLTypography>
        </Box>
        <Box
          sx={{
            paddingLeft: { lg: "105px", md: "24px", xs: "16px" },
          }}
        >
          <SLTypography tabIndex={0} mt={"20px"} pl={"7px"}>
            Current Password*
          </SLTypography>

          <FormControl
            sx={{ m: 1, width: { lg: "65%", md: "95%", xs: "90%" } }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Inna_2022_2022
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"password"}
              label="Password"
            />
          </FormControl>
          {/* enter ur description */}
          <SLTypography tabIndex={0} pl={"7px"}>
            Add Notes
          </SLTypography>

          <FormControl
            sx={{
              width: { lg: "65%", md: "90%", xs: "90%" },
              paddingLeft: "7px",
            }}
            variant="filled"
          >
            <FilledInput
              id="filled-adornment-weight"
              aria-describedby="filled-weight-helper-text"
              startAdornment={
                <InputAdornment
                  position="start"
                  sx={{ padding: "19px 0px 50px 20px", fontSize: "15px" }}
                >
                  Enter you Emotion
                </InputAdornment>
              }
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          {/* end of ur description */}
          <SLTypography tabIndex={0} pl={"7px"}>
            Current Password*
          </SLTypography>

          <FormControl
            sx={{ m: 1, width: { lg: "65%", md: "90%", xs: "90%" } }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Inna_2022_2022
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <SLTypography tabIndex={0} pl={"7px"}>
            Current Password*
          </SLTypography>

          <FormControl
            sx={{ m: 1, width: { lg: "65%", md: "90%", xs: "90%" } }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Inna_2022_2022
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <KeyboardArrowDownIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          {/* choose a month section */}
          <Box
            sx={{
              margin: "0px",
              paddingLeft: { lg: "0px", md: "", xs: "10px" },
              gap: "0px",
            }}
          >
            <Grid container>
              <Grid item lg={6}>
                <InputLabel htmlFor="component-simple">Frequency*</InputLabel>
                <FormControl
                  sx={{ width: { lg: "65%", md: "90%", xs: "97%" } }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Yearly{" "}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6}>
                <InputLabel htmlFor="component-simple">
                  Choose a month*
                </InputLabel>
                <FormControl
                  sx={{
                    width: { lg: "65%", md: "90%", xs: "97%" },
                    padding: "0px",
                    margin: "0px",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    {" "}
                    June{" "}
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6}>
                <InputLabel htmlFor="component-simple">Days of week</InputLabel>
                <FormControl
                  sx={{
                    m: 1,
                    width: { lg: "65%", md: "90%", xs: "97%" },
                    padding: "0px",
                    margin: "0px",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    First
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6}>
                <InputLabel htmlFor="component-simple">
                  <Switch
                    sx={{ marginLeft: { lg: "98px", md: "", xs: "-10px" } }}
                    name="loading"
                    color="primary"
                    // position="end"
                  />
                </InputLabel>
                <FormControl
                  sx={{
                    m: 1,
                    width: { lg: "65%", md: "90%", xs: "97%" },
                    padding: "0px",
                    margin: "0px",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Sunday
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6}>
                <InputLabel
                  htmlFor="component-simple"
                  sx={{ marginTop: { lg: "", md: "15px", xs: "" } }}
                >
                  Year
                </InputLabel>
                <FormControl
                  sx={{
                    m: 1,
                    width: { lg: "65%", md: "90%", xs: "97%" },
                    margin: "0px",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Inna_2022_2022
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item lg={6}>
                <InputLabel htmlFor="component-simple">3</InputLabel>
                <FormControl
                  sx={{
                    m: 1,
                    width: { lg: "65%", md: "90%", xs: "97%" },
                    padding: "0px",
                    margin: "0px",
                  }}
                  variant="outlined"
                >
                  <InputLabel htmlFor="outlined-adornment-password">
                    Inna_2022_2022
                  </InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={"password"}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                        >
                          <KeyboardArrowDownIcon />
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
            </Grid>
            <SLTypography
              tabIndex={0}
              sx={{ fontSize: "13px", paddingTop: "10px" }}
            >
              Synchronized with your Google Calendar
            </SLTypography>
          </Box>
          <Stack
            spacing={0.5}
            direction="row"
            mt="30px"
            mb={"90px"}
            sx={{
              marginTop: "30px",
              marginBottom: "90px",
              paddingLeft: { lg: "", md: "", xs: "10px" },
            }}
          >
            <SLButton
              slClass="none"
              messageId={"general.cancel"}
              variant="text"
            />
            {/* <Button variant="text" sx={{ width: "33%", height: "58px" }}>
              Cancel
            </Button> */}
            <SLButton variant="contained" messageId={"general.save"} />
            {/* <Button
              variant="contained"
              sx={{
                width: { lg: "33.5%", md: "50%", xs: "58%" },
                height: "58px",
              }}
            >
              Save Goal
            </Button> */}
          </Stack>
        </Box>
      </Box>
    </>
  );
};

export default SetUpAchievement;
