import { combineReducers } from "@reduxjs/toolkit";
import { SLReducers, SLWhiteListedReducers } from "utils/slRTKHelper";
import reduceMotion from "./reduceMotionSlice";
import videoControls from "./videoControlsSlice";
import volumeFade from "./volumeFadeSlice";
import webShare from "./webShareSlice";

const apiReducers: any = {
  SLReducers: combineReducers(SLReducers),
};

if (Object.keys(SLWhiteListedReducers).length) {
  apiReducers["SLWhiteListedReducers"] = combineReducers(SLWhiteListedReducers);
}

const reducers = combineReducers({
  ...apiReducers,
  reduceMotion,
  videoControls,
  volumeFade,
  webShare,
});

export default reducers;
