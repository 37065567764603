import { Dialog, Stack } from "@mui/material";
import { ResponsiveStyleValue } from "@mui/system/styleFunctionSx";
import { SLTypography } from "components/slTypography";
import { Property } from "csstype";
import { memo } from "react";
import {
  DialogActionButtons,
  IDialogActionButtons,
} from "../DialogActionButtons";
import { IDialogResponse } from "./useDialog";

type ISLDialog = {
  dialog: IDialogResponse;
  actionButtons?: IDialogActionButtons;
  children?: any;
  dismissable?: boolean;
  onDismiss?: () => void;
  width?: ResponsiveStyleValue<Property.Width<string | number>>;
};

const SLDialog = memo((props: ISLDialog) => {
  const dismiss = () => {
    if (props.dismissable) {
      if (props.actionButtons?.onNoButtonClicked) {
        props.actionButtons.onNoButtonClicked();
      } else if (props.actionButtons?.onYesButtonClicked) {
        props.actionButtons.onYesButtonClicked();
      }
      if (props.onDismiss) {
        props.onDismiss();
      }
    }
  };
  return (
    <Dialog
      open={Boolean(props.dialog.open)}
      className="backdrop-blur"
      onClose={dismiss}
      aria-labelledby="sl-dialog-title"
      aria-describedby="sl-dialog-description"
      PaperProps={{ sx: { margin: 2 } }}
    >
      <Stack
        direction="column"
        gap={2}
        sx={{
          background: "var(--bg-gradient)",
          padding: { xs: 2, sm: 3 },
          paddingBottom: 1,
          width: props.width || { xs: "280px", sm: "340px" },
          boxSizing: "border-box",
          borderRadius: "10px",
        }}
      >
        {props.dialog.title ? (
          <SLTypography
            tabIndex={0}
            id="sl-dialog-title"
            variant="subhead"
            sx={{
              fontSize: { xs: "17px", sm: "19px" },
              lineHeight: { xs: "20px", sm: "23px" },
              fontWeight: 700,
            }}
          >
            {props.dialog.title}
          </SLTypography>
        ) : null}

        {props.dialog.message ? (
          <SLTypography
            tabIndex={0}
            id="sl-dialog-description"
            variant="bodyCopy"
            color="text-light"
            sx={{
              fontSize: { xs: "12px", sm: "14px", md: "16px" },
              lineHeight: { xs: "19px", sm: "20px" },
              fontWeight: 600,
            }}
          >
            {props.dialog.message}
          </SLTypography>
        ) : props.children ? (
          props.children
        ) : null}

        {props.actionButtons ? (
          <DialogActionButtons {...props.actionButtons} />
        ) : null}
      </Stack>
    </Dialog>
  );
});
export default SLDialog;
