import {
  Avatar,
  Box,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import ListingPageSkeleton from "components/listingSkeleton";
import { useDragger } from "helpers/common/useDragger";
import { SoulPurpleIcon } from "helpers/enum/constants";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Slider from "react-slick";
import { meditationSlickSettings } from "utils/meditationSlickSettings";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import MediationCard from "../mediationCard";
import { NotFoundMessage } from "../slTypography/NotFoundMessage";
import "./index.css";

const errorTitle = "Meditations";
const meditationTags = [
  { id: 0, title: "All" },
  { id: 1, title: "Recent" },
  { id: 2, title: "My Favorites" },
  { id: 3, title: "Popular" },
  { id: 4, title: "Suggested" },
];

export const MyCreatedMeditations = (props: any) => {
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const dragger = useDragger();

  const {
    data: getMeditationMyMeditations,
    loading,
    error,
  } = useSelector(SLSelector.getMeditationMyMeditations);
  const { data: getMeditationMyFavourites } = useSelector(
    SLSelector.getMeditationMyFavourites
  );
  const { data: getMeditationPopular } = useSelector(
    SLSelector.getMeditationPopular
  );
  const { data: getMeditationSuggested } = useSelector(
    SLSelector.getMeditationSuggested
  );

  const sliderRef: any = useRef(null);

  const {
    duration,
    addedToFavourites,
    successMessageText,
    setSelectedMeditationData,
    allMediaSelected,
    setNoMeditationFound,
    setInitialMeditationCounter,
    initialMeditationCounter,
    setCardExistingData,
    cardExistingData,
    showTags,
    sortByLastPlayed,
    fromPage,
    setChangeMeditationFlag,
  } = props;

  const [pageNo, setPageNo] = useState(1);
  const [meditationData, setMeditationData] = useState<any>();
  const [customLoader, setCustomLoader] = useState(false);
  const [initialCount, setInitialCount] = useState(0);
  const [skeletonLoading, setSkeletonLoading] = useState(false);
  const [currentMeditationApi, setCurrentMeditationApi] = useState(0);

  const getAllMediaData = () => {
    setSkeletonLoading(true);
    let sortBy = "";
    if (sortByLastPlayed) {
      sortBy = "lastPlayed";
    } else {
      sortBy = "createdAt";
    }
    dispatch(
      SLThunk.getMeditationMyMeditations.call({
        limit: 20,
        page: pageNo,
        sort: "DESC",
        sortBy: sortBy,
        duration: duration,
        isFavourite: false,
      })
    );
  };

  useEffect(() => {
    getAllMediaData();
  }, []);

  useEffect(() => {
    if (error) {
      setSkeletonLoading(false);
    }
  }, [error]);

  useEffect(() => {
    const payload = {
      limit: 20,
      page: pageNo,
      sort: "DESC",
      sortBy: "createdAt",
      duration: duration,
      isFavourite: false,
    };
    if (pageNo > 1 && currentMeditationApi < 1) {
      getAllMediaData();
    } else if (pageNo > 1 && currentMeditationApi == 2) {
      dispatch(SLThunk.getMeditationMyFavourites.call(payload));
    } else if (pageNo > 1 && currentMeditationApi == 3) {
      dispatch(SLThunk.getMeditationPopular.call(payload));
    } else if (pageNo > 1 && currentMeditationApi == 4) {
      dispatch(SLThunk.getMeditationSuggested.call(payload));
    }
  }, [pageNo]);

  useEffect(() => {
    if (currentMeditationApi) {
      const payload = {
        limit: 20,
        page: pageNo,
        sort: "DESC",
        sortBy: "createdAt",
        duration: duration,
        isFavourite: false,
      };
      if (currentMeditationApi == 2) {
        dispatch(SLThunk.getMeditationMyFavourites.call(payload));
      } else if (currentMeditationApi == 3) {
        dispatch(SLThunk.getMeditationPopular.call(payload));
      } else if (currentMeditationApi == 4) {
        dispatch(SLThunk.getMeditationSuggested.call(payload));
      }
    }
  }, [currentMeditationApi]);

  useEffect(() => {
    if (getMeditationMyMeditations) {
      getAlleditationData(getMeditationMyMeditations);

      const tagTitle =
        meditationTags.find((r) => r.id == alignment)?.title || "";

      if (tagTitle && alignment && fromPage == "HOME") {
        mixpanel.triggerTagUsedEvent(
          "HOME_MY_LIST",
          String(alignment),
          tagTitle
        );
      }
    }
  }, [getMeditationMyMeditations]);

  useEffect(() => {
    if (getMeditationMyFavourites) {
      getAlleditationData(getMeditationMyFavourites);

      const tagTitle =
        meditationTags.find((r) => r.id == alignment)?.title || "";

      if (tagTitle) {
        mixpanel.triggerTagUsedEvent(
          "HOME_MY_LIST",
          String(alignment),
          tagTitle
        );
      }
    }
  }, [getMeditationMyFavourites]);

  useEffect(() => {
    if (getMeditationPopular) {
      getAlleditationData(getMeditationPopular);

      const tagTitle =
        meditationTags.find((r) => r.id == alignment)?.title || "";

      if (tagTitle) {
        mixpanel.triggerTagUsedEvent(
          "HOME_MY_LIST",
          String(alignment),
          tagTitle
        );
      }
    }
  }, [getMeditationPopular]);

  useEffect(() => {
    if (getMeditationSuggested) {
      getAlleditationData(getMeditationSuggested);

      const tagTitle =
        meditationTags.find((r) => r.id == alignment)?.title || "";

      if (tagTitle) {
        mixpanel.triggerTagUsedEvent(
          "HOME_MY_LIST",
          String(alignment),
          tagTitle
        );
      }
    }
  }, [getMeditationSuggested]);

  const getAlleditationData = (data: any) => {
    if (currentMeditationApi > 0) {
      if (data) {
        if (data && pageNo < 2) {
          setInitialCount(initialCount + data.totalCount);
        }
        const newMeditationData = data?.data;
        setMeditationData([...meditationData, ...newMeditationData]);

        setSkeletonLoading(false);
      }
    } else {
      if (data && pageNo < 2) {
        setSkeletonLoading(false);
        setMeditationData(data.data);
        //  dispatch(SLThunk.getMeditationMyMeditations.reset());
        setInitialCount(data.totalCount);
      } else if (data && pageNo > 1) {
        setSkeletonLoading(false);
        const newMeditationData = data?.data;
        setMeditationData([...meditationData, ...newMeditationData]);
      }
    }
  };

  useEffect(() => {
    if (currentMeditationApi > 0) {
      setCustomLoader(false);
    } else {
      if (meditationData && pageNo > 1) {
        setCustomLoader(false);
      }
      if (!meditationData?.length && !loading) {
        setNoMeditationFound(true);
      } else {
        setNoMeditationFound(false);
      }
    }
  }, [meditationData]);

  const handleLikedMediaCard = (text: string) => {
    addedToFavourites(true);
    successMessageText(text);
    setTimeout(() => {
      addedToFavourites(false);
    }, 1500);
  };
  const currentMeditationAPi = (id: number) => {
    setCurrentMeditationApi(id);
    setCustomLoader(true);
    setPageNo(1);
  };

  useEffect(() => {
    if (duration) {
      setPageNo(1);
      getAllMediaData();
      setInitialMeditationCounter(false);
    }
  }, [duration]);

  const nextClick = (currentSlide: any) => {
    const elementSelect = document.querySelector(".slick-next.slick-disabled");
    if (elementSelect?.matches(".slick-disabled")) {
      if (alignment === 0) {
        if (meditationData?.length < initialCount) {
          setCustomLoader(true);
          setPageNo(pageNo + 1);
        } else {
          if (currentMeditationApi === 0) {
            currentMeditationAPi(2);
          } else if (currentMeditationApi === 2) {
            currentMeditationAPi(3);
          } else if (currentMeditationApi === 3) {
            currentMeditationAPi(4);
          }
        }
      } else {
        if (meditationData?.length < initialCount) {
          setCustomLoader(true);
          setPageNo(pageNo + 1);
        }
      }
    }
  };
  const settings = meditationSlickSettings(nextClick);

  const selectedMeditation = (obj: any) => {
    const objDetail = {
      audio: {
        id: obj?.audio?.audioId,
        title: obj?.audio?.title,
        image: obj?.audio?.image,
      },
      video: {
        id: obj?.video?.videoId,
        title: obj?.video?.title,
        image: obj?.video?.image,
      },
      frequencywave: {
        id: obj?.frequencywave?.frequencywaveId,
        title: obj?.frequencywave?.title,
        image: obj?.frequencywave?.image,
      },
    };

    setChangeMeditationFlag(false);
    setSelectedMeditationData(objDetail);
  };

  const [alignment, setAlignment] = useState<number | null>(0);
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    let sortBy = "";
    if (newAlignment == null) {
      newAlignment = 0;
    }
    if (newAlignment == 1 || newAlignment == 0) {
      sortBy = "lastPlayed";
    } else {
      sortBy = "createdAt";
    }
    const payload = {
      limit: 20,
      page: pageNo,
      sort: "DESC",
      sortBy: sortBy,
      duration: duration,
      isFavourite: false,
    };
    setSkeletonLoading(true);
    setPageNo(1);
    if (newAlignment == 0) {
      dispatch(SLThunk.getMeditationMyMeditations.call(payload));
    } else if (newAlignment == 1) {
      dispatch(SLThunk.getMeditationMyMeditations.call(payload));
    } else if (newAlignment == 2) {
      dispatch(SLThunk.getMeditationMyFavourites.call(payload));
    } else if (newAlignment == 3) {
      dispatch(SLThunk.getMeditationPopular.call(payload));
    } else if (newAlignment == 4) {
      dispatch(SLThunk.getMeditationSuggested.call(payload));
    }
    setAlignment(newAlignment);
    //onTagChange("" + newAlignment);
  };
  return (
    <Box className="listLoaderHomeScreen">
      <div
        onMouseMove={dragger.preventClickWhenDragging}
        onMouseUp={dragger.detectStopDragging}
        onMouseLeave={dragger.detectStopDragging}
        className={`meditationContainer mt-6`}
      >
        {customLoader ? (
          <Box className="loaderContainer">
            <div className="loader" />
            <Avatar
              className="rotate"
              alt="Loading Image"
              src={SoulPurpleIcon}
              sx={{ width: 120, height: 120, margin: "auto" }}
            />
          </Box>
        ) : null}

        {showTags ? (
          <Stack spacing={1} marginTop="5px">
            <ToggleButtonGroup
              className="deeppurple-toggle"
              value={alignment}
              onChange={handleAlignment}
              exclusive
              sx={{
                gap: 1,
                display: "inline-block",
                paddingBottom: { xs: "42px", lg: "" },
              }}
            >
              {meditationTags?.map((tag: any, index: number) => (
                <ToggleButton
                  key={"artist_catid" + index}
                  value={tag.id}
                  aria-label={tag.title}
                  title={tag.title}
                  className="toggleBtn"
                  tabIndex={0}
                >
                  {tag.title}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Stack>
        ) : null}

        {!skeletonLoading || customLoader ? (
          meditationData?.length ? (
            <Slider
              ref={sliderRef}
              {...settings}
              className="meditationSlider myCreatedMeditation"
            >
              {meditationData?.map((data: any, index: number) => (
                <div key={"Cardindex_" + index}>
                  <MediationCard
                    selectedCardData={data}
                    cardSelection="mySelectedMaditation"
                    cardType="Meditation"
                    meditationPlayerNavigate={true}
                    likedMediaCard={handleLikedMediaCard}
                    setSelectedMeditationData={selectedMeditation}
                    setInitialMeditationCounter={setInitialMeditationCounter}
                    initialMeditationCounter={initialMeditationCounter}
                    setCardExistingData={setCardExistingData}
                    cardExistingData={cardExistingData}
                    plusIcon={false}
                    dragging={dragger.isDragging}
                  />
                </div>
              ))}
            </Slider>
          ) : (
            <NotFoundMessage
              notFoundMessage={`No ${errorTitle} Found`}
              height="100px"
            />
          )
        ) : (
          <Stack
            sx={{
              flexDirection: "row",
              gap: "15px",
              overflow: "hidden",
              marginRight: "0px",
            }}
          >
            <ListingPageSkeleton
              showSkeleton={skeletonLoading}
              type="card"
              count={15}
            />
          </Stack>
        )}
      </div>
    </Box>
  );
};
