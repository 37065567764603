export function meditationSlickSettings(
  nextClick: (newindex: string) => void
): any {
  return {
    dots: false,
    infinite: false,
    loop: false,
    slidesToScroll: 1,
    slidesToShow: 8,
    speed: 500,
    swipeToSlide: true,
    variableWidth: true,
    afterChange: nextClick,
    responsive: [
      { breakpoint: 1600, settings: { slidesToShow: 8 } },
      { breakpoint: 1450, settings: { slidesToShow: 7 } },
      { breakpoint: 1300, settings: { slidesToShow: 6 } },
      { breakpoint: 1150, settings: { slidesToShow: 5 } },
      { breakpoint: 980, settings: { slidesToShow: 4 } },
      { breakpoint: 800, settings: { slidesToShow: 3 } },
      { breakpoint: 640, settings: { slidesToShow: 2 } },
      { breakpoint: 480, settings: { slidesToShow: 1 } },
    ],
  };
}
