import { RadialBar, RadialBarChart } from "recharts";

interface IMeditationCountdown {
  fontSize?: number;
  chartSize: number;
  total: number;
  value: number;
}

export const MeditationCountdown = (props: IMeditationCountdown) => {
  const chartSize = props.chartSize || 0;
  const fontSize = props.fontSize || 16;
  const data = [{ uv: props.total || 100 }, { uv: props.value || 100 }];

  return (
    <>
      <RadialBarChart
        width={chartSize}
        height={chartSize}
        innerRadius={0}
        outerRadius={chartSize * 0.875}
        barSize={5}
        data={data}
        startAngle={-265}
        endAngle={85}
      >
        <text
          x={chartSize / 2}
          y={chartSize / 2}
          textAnchor="middle"
          dominantBaseline="middle"
          fontFamily="Figtree"
          fill="#4B2B5A"
          fontSize={fontSize}
        >
          {props.value}
        </text>
        <RadialBar
          fill="#762B90"
          cornerRadius={40}
          label={false}
          dataKey="uv"
        />
      </RadialBarChart>
    </>
  );
};
