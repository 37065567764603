import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Auth } from "utils/authService";
import { deviceId } from "utils/firebase";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLMeditationDetail } from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";

export interface IMiniplayerState {
  playing?: boolean;
  currentTime?: number | string;
  totalTime?: number | string;
  track?: SLMeditationDetail;
  frequencyVolume?: number;
}

interface IMeditationSession {
  start: () => void;
  continue: () => void;
  end: () => void;
  overtake: () => void;
  getSession: () => string | undefined;
  clearSession: () => void;
}

type MeditationSessionStatus = undefined | "AVAILABLE" | "ALREADY_MEDIATING";

let meditationSessionTimerRef: NodeJS.Timeout | undefined;

export const MiniplayerContext = createContext<{
  miniplayerState: IMiniplayerState;
  setMiniplayerState: (miniplayerState: IMiniplayerState) => void;
  showMiniplayer: boolean;
  closePlayer: () => void;
  isOnPlayerPage: (track?: SLMeditationDetail) => void;
  awayFromPlayerPage: () => void;
  setFrequencyVolume: (frequencyVolume: number) => void;
  setMiniplayerIsLiked: () => void;
  setMiniplayerIsUnliked: () => void;
  isClosing: number;
  meditationSession: IMeditationSession;
  meditationSessionStatus: MeditationSessionStatus;
  // eslint-disable-next-line
}>(null as any);

export const MiniplayerContextProvider = (props: any) => {
  const dispatch = useDispatch();

  const { data: isliked } = useSelector(SLSelector.postMeditationAddFavourites);
  const { data: unliked } = useSelector(
    SLSelector.deleteMeditationFavouritesId
  );
  const { data: postMeditationSession, error: postMeditationSessionError } =
    useSelector(SLSelector.postMeditationSession);

  const [showMiniplayer, setShowMiniplayer] = useState<boolean>(false);
  const [miniplayerState, setState] = useState<IMiniplayerState>({
    playing: undefined,
    currentTime: undefined,
    totalTime: undefined,
    track: undefined,
  });
  const [isClosing, setClosing] = useState<number>(0);
  const [onPlayerPage, setOnPlayerPage] = useState<boolean>(false);
  const [meditationSessionStatus, setMeditationSessionStatus] =
    useState<MeditationSessionStatus>(undefined);

  const closePlayer = () => {
    setClosing(isClosing + 1);
    setShowMiniplayer(false);
    setOnPlayerPage(false);
    meditationSession.end();
    setMiniplayerState({
      playing: false,
      currentTime: undefined,
      totalTime: undefined,
      track: undefined,
      frequencyVolume: 20,
    });
  };

  const isOnPlayerPage = (track?: SLMeditationDetail) => {
    if (track) {
      miniplayerState.track = { ...track };
    }
    setMeditationSessionStatus(undefined);
    meditationSession.start();
    setOnPlayerPage(true);
    setShowMiniplayer(false);
    setClosing(0);
  };

  const awayFromPlayerPage = () => {
    setMeditationSessionStatus(undefined);
    setOnPlayerPage(false);
  };

  const setFrequencyVolume = (frequencyVolume: number) => {
    console.warn({ frequencyVolume });
    setState((prv) => ({ ...prv, frequencyVolume }));
  };

  const setMiniplayerState = (state: IMiniplayerState) => {
    if (!isClosing) {
      setState(state);
    }
  };

  useEffect(() => {
    if (
      isClosing ||
      onPlayerPage ||
      !miniplayerState.playing ||
      miniplayerState.currentTime == miniplayerState.totalTime
    ) {
      setShowMiniplayer(false);
    } else {
      setShowMiniplayer(true);
    }
  }, [onPlayerPage, isClosing, miniplayerState.playing]);

  useEffect(() => {
    if (isliked?.meditationId == miniplayerState?.track?.id) {
      setMiniplayerIsLiked();
    }
  }, [isliked]);

  useEffect(() => {
    if (unliked?.meditationId == miniplayerState?.track?.id) {
      setMiniplayerIsUnliked();
    }
  }, [unliked]);

  const setMiniplayerIsLiked = () => {
    if (miniplayerState?.track) {
      miniplayerState.track.isLikedByMe = true;
    }
  };

  const setMiniplayerIsUnliked = () => {
    if (miniplayerState?.track) {
      miniplayerState.track.isLikedByMe = false;
    }
  };

  const startMeditationSessionTimer = () => {
    clearInterval(meditationSessionTimerRef);
    meditationSessionTimerRef = setInterval(() => {
      if (!navigator.onLine) {
        return;
      }
      meditationSession.continue();
    }, 10000);
  };

  useEffect(() => {
    if (postMeditationSession?.id) {
      meditationSession.setSession(postMeditationSession?.id);
      setMeditationSessionStatus("AVAILABLE");
      startMeditationSessionTimer();
    } else if (postMeditationSessionError) {
      if (!navigator.onLine) {
        return;
      }
      setMeditationSessionStatus("ALREADY_MEDIATING");
      meditationSession.clearSession();
      clearInterval(meditationSessionTimerRef);
    }
  }, [postMeditationSession, postMeditationSessionError]);

  const patchRequest = () => {
    const sessionId = meditationSession.getSession();
    if (sessionId) {
      makeSLAPICall({
        method: "PATCH",
        url: "meditation-session/" + sessionId,
        params: { deviceId },
      })
        .then((res: any) => {
          if (res?.id) {
            meditationSession.setSession(res?.id);
          }
        })
        .catch(() => {
          if (!navigator.onLine) {
            return;
          }
          setMeditationSessionStatus("ALREADY_MEDIATING");
          meditationSession.clearSession();
          clearInterval(meditationSessionTimerRef);
        });
    }
  };

  const deleteOldSessionsRequest = () => {
    makeSLAPICall({
      method: "DELETE",
      url: "meditation-session/old-sessions/" + deviceId,
    })
      .then((res: any) => {
        if (res?.id) {
          meditationSession.setSession(res?.id);
          setMeditationSessionStatus("AVAILABLE");
          startMeditationSessionTimer();
        }
      })
      .catch(() => {
        if (!navigator.onLine) {
          return;
        }
        setMeditationSessionStatus("ALREADY_MEDIATING");
        meditationSession.clearSession();
        clearInterval(meditationSessionTimerRef);
      });
  };

  const meditationSession = {
    start: () => {
      if (Auth.user.loggedin()) {
        dispatch(
          SLThunk.postMeditationSession.call({
            meditationId: miniplayerState?.track?.id || 0,
            deviceId,
          })
        );
      } else {
        meditationSession.setSession("guest");
        setMeditationSessionStatus("AVAILABLE");
        startMeditationSessionTimer();
      }
    },
    continue: () => {
      if (Auth.user.loggedin()) {
        patchRequest();
      } else {
        meditationSession.setSession("guest");
        setMeditationSessionStatus("AVAILABLE");
        startMeditationSessionTimer();
      }
    },
    end: () => {
      if (Auth.user.loggedin()) {
        if (meditationSession.getSession()) {
          dispatch(
            SLThunk.deleteMeditationSessionId.call({
              id: meditationSession.getSession() || "",
            })
          );
          meditationSession.clearSession();
        }
      } else {
        meditationSession.setSession("guest");
        setMeditationSessionStatus("AVAILABLE");
        startMeditationSessionTimer();
      }
    },
    overtake: () => {
      clearInterval(meditationSessionTimerRef);
      meditationSession.clearSession();
      deleteOldSessionsRequest();
    },
    getSession: () => {
      return SLStorage.getItem("meditationSessionId") || undefined;
    },
    setSession: (sessionId: string) => {
      SLStorage.setItem("meditationSessionId", sessionId);
    },
    clearSession: () => {
      meditationSession.setSession("");
    },
  };

  return (
    <MiniplayerContext.Provider
      value={{
        isClosing,
        miniplayerState,
        setMiniplayerState,
        showMiniplayer,
        closePlayer,
        isOnPlayerPage,
        awayFromPlayerPage,
        setFrequencyVolume,
        setMiniplayerIsLiked,
        setMiniplayerIsUnliked,
        meditationSession,
        meditationSessionStatus,
      }}
    >
      {props.children}
    </MiniplayerContext.Provider>
  );
};
