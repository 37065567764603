import ReportAProblem from "components/helpCenterScreens/reportAProblem";
import HelpCenterPages from "../../../layouts/helpPages";

const ReportProblem = () => {
  return (
    <HelpCenterPages title="Report Problem">
      <ReportAProblem />
    </HelpCenterPages>
  );
};

export default ReportProblem;
