import { Box } from "@mui/material";
import { dynamicPageSx } from "components/aboutpages/dynamicAboutPage";
import { Outlet } from "react-router-dom";

function DocsLayout() {
  return (
    <Box
      sx={{
        padding: 2,
        background: "white",
        fontFamily: "Figtree",
        color: "var(--sl-deep-purple)",
      }}
    >
      <Box sx={{ maxWidth: "800px" }}>
        <Box sx={dynamicPageSx}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}

export default DocsLayout;
