import ProfileDetails from "components/profile/myProfile/profileDetails";
import { profilePicture } from "helpers/profilePicture";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SLSelector } from "utils/slRTKHelper";
import ProfilePages from "../../../layouts/profilePages";

const MyProfile: React.FC = () => {
  const [userImage, setUserImage] = useState("");
  const { data: userProfileData } = useSelector(SLSelector.getUserProfile);
  const { data: config } = useSelector(SLSelector.getConfig);
  const { data: profileStats, loading: profileStatsLoading } = useSelector(
    SLSelector.getStatsAll
  );

  useEffect(() => {
    if (userProfileData && config) {
      const profileImage = profilePicture(userProfileData?.image, config);
      setUserImage(profileImage);
    }
  }, [userProfileData, config]);

  return (
    <>
      <ProfilePages title="My Profile">
        <ProfileDetails
          userProfileData={userProfileData}
          userImage={userImage}
          profileStats={profileStats}
          profileStatsLoading={profileStatsLoading}
        />
      </ProfilePages>
    </>
  );
};

export default MyProfile;
