// Icons
import MuiAccessibilityOutlined from "@mui/icons-material/AccessibilityOutlined";
import MuiAccountCircleOutlined from "@mui/icons-material/AccountCircleOutlined";
import MuiAdd from "@mui/icons-material/Add";
import MuiAirplay from "@mui/icons-material/Airplay";
import MuiArrowBackIosNew from "@mui/icons-material/ArrowBackIosNew";
import MuiArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import MuiBorderColorOutlined from "@mui/icons-material/BorderColorOutlined";
import MuiCardMembershipOutlined from "@mui/icons-material/CardMembershipOutlined";
import MuiCastOutlined from "@mui/icons-material/CastOutlined";
import MuiCheckCircle from "@mui/icons-material/CheckCircle";
import MuiClear from "@mui/icons-material/Clear";
import MuiCloseFullscreen from "@mui/icons-material/CloseFullscreen";
import MuiDescriptionOutlined from "@mui/icons-material/DescriptionOutlined";
import MuiDone from "@mui/icons-material/Done";
import MuiExpandMoreOutlined from "@mui/icons-material/ExpandMoreOutlined";
import MuiFavoriteBorderOutlined from "@mui/icons-material/FavoriteBorderOutlined";
import MuiFavoriteOutlined from "@mui/icons-material/FavoriteOutlined";
import MuiFormatQuote from "@mui/icons-material/FormatQuote";
import MuiForumOutlined from "@mui/icons-material/ForumOutlined";
import MuiForward10Outlined from "@mui/icons-material/Forward10Outlined";
import MuiGavelOutlined from "@mui/icons-material/GavelOutlined";
import MuiHelpOutlineOutlined from "@mui/icons-material/HelpOutlineOutlined";
import MuiHomeOutlined from "@mui/icons-material/HomeOutlined";
import MuiHorizontalRuleOutlined from "@mui/icons-material/HorizontalRuleOutlined";
import MuiInfoOutlined from "@mui/icons-material/InfoOutlined";
import MuiKeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import MuiKeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import MuiKeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";
import MuiLockResetOutlined from "@mui/icons-material/LockResetOutlined";
import MuiManageAccountsOutlined from "@mui/icons-material/ManageAccountsOutlined";
import MuiOpenInFull from "@mui/icons-material/OpenInFull";
import MuiPauseCircleFilledOutlined from "@mui/icons-material/PauseCircleFilledOutlined";
import MuiPermIdentityOutlined from "@mui/icons-material/PermIdentityOutlined";
import MuiPersonOutline from "@mui/icons-material/PersonOutline";
import MuiPlayCircleFilled from "@mui/icons-material/PlayCircleFilled";
import MuiPlayCircleFilledOutlined from "@mui/icons-material/PlayCircleFilledOutlined";
import MuiPsychologyOutlined from "@mui/icons-material/PsychologyOutlined";
import MuiReplay10Outlined from "@mui/icons-material/Replay10Outlined";
import MuiReportGmailerrorredOutlined from "@mui/icons-material/ReportGmailerrorredOutlined";
import MuiSearchCircle from "@mui/icons-material/Search";
import MuiSearchOutlined from "@mui/icons-material/SearchOutlined";
import MuiSettingsOutlined from "@mui/icons-material/SettingsOutlined";
import MuiShareOutlined from "@mui/icons-material/ShareOutlined";
import MuiShieldOutlined from "@mui/icons-material/ShieldOutlined";
import MuiSpeakerNotesOutlined from "@mui/icons-material/SpeakerNotesOutlined";
import MuiSubscriptionsOutlined from "@mui/icons-material/SubscriptionsOutlined";
import MuiVolumeMute from "@mui/icons-material/VolumeOff";
import MuiVolumeUp from "@mui/icons-material/VolumeUp";

//Images
import plusIcon from "../../assets/images/Icon.png";
import aboutUsLogo from "../../assets/images/aboutUsLogo.svg";
import accessWhite from "../../assets/images/access_white.svg";
import addSvgIcon from "../../assets/images/addIcon.svg";
import annualDonationBackgroundImage from "../../assets/images/annualDonationBackgroundImage.png";
import appBarCreate from "../../assets/images/appBarCreate.svg";
import appBarCreateWhite from "../../assets/images/appBarCreateWhite.svg";
import appBarExplore from "../../assets/images/appBarExplore.svg";
import appBarExploreWhite from "../../assets/images/appBarExploreWhite.svg";
import appBarHome from "../../assets/images/appBarHome.svg";
import appBarHomeWhite from "../../assets/images/appBarHomeWhite.svg";
import appBarMyList from "../../assets/images/appBarMyList.svg";
import appBarMyListWhite from "../../assets/images/appBarMyListWhite.svg";
import Apple from "../../assets/images/apple.png";
import arrowDownWhiteIcon from "../../assets/images/arrowDownIcon.svg";
import arrowDownPurple from "../../assets/images/arrowDownPurple.svg";
import arrowDownWhite from "../../assets/images/arrowDownWhite.svg";
import audioImage1 from "../../assets/images/audio-1.png";
import audioImage2 from "../../assets/images/audio-2.png";
import audioImage3 from "../../assets/images/audio-3.png";
import audioCardHowItWorks from "../../assets/images/audioCardHowItWorks.png";
import audioIcon from "../../assets/images/audioIcon.svg";
import audioMusicIcon from "../../assets/images/audioMusicIcon.svg";
import authBackgroundImage from "../../assets/images/authBackgroundImage.png";
import backIcon from "../../assets/images/back_icon.svg";
import bestValuesAnnualDonation from "../../assets/images/bestValuesAnnualDonation.svg";
import MuiClosedCaption from "../../assets/images/cc.svg";
import MuiClosedCaptionDisabled from "../../assets/images/ccOff.svg";
import ceoImage from "../../assets/images/ceoImage.svg";
import changeAudio from "../../assets/images/changeAudio.svg";
import checkBoxChecked from "../../assets/images/checkbox-check.png";
import checkBoxUnChecked from "../../assets/images/checkbox-uncheck.png";
import conciousness from "../../assets/images/conciousness.png";
import createMeditationLogo from "../../assets/images/createMeditationLogo.svg";
import crossIcon from "../../assets/images/crossIcon.svg";
import crossWhite from "../../assets/images/crossIconWhite.svg";
import crossWhiteIconWithBorder from "../../assets/images/crossWhiteIconWithBorder.svg";
import currentMediations from "../../assets/images/currentMediations.svg";
import dailyInpirationMenuSvg from "../../assets/images/dailyInpirationMenuSvg.svg";
import dailyMeditationIcon from "../../assets/images/dailyMeditationIcon.svg";
import doubleQoutes from "../../assets/images/doubleQoutes.png";
import exploreAltHeader from "../../assets/images/explore-header.png";
import faceBookIcon from "../../assets/images/faceBookIcon.svg";
import fequencyWavesCardHowItWorks from "../../assets/images/fequencyWavesCardHowItWorks.png";
import frequancyImage1 from "../../assets/images/frequancy-1.png";
import frequancyImage2 from "../../assets/images/frequancy-2.png";
import frequencyDisabledIcon from "../../assets/images/frequencyDisabledIcon.svg";
import frequencyIcon from "../../assets/images/frequencyIcon.svg";
import frequencyMusicIcon from "../../assets/images/frequencyMusicIcon.svg";
import frequencyWhiteIcon from "../../assets/images/frequencyWhiteIcon.svg";
import gifCreateHeader from "../../assets/images/gifCreateHeader.gif";
import harmony from "../../assets/images/harmony.png";
import headerLeftArrowIcon from "../../assets/images/headerLeftArrowIcon.svg";
import headerRightArrowIcon from "../../assets/images/headerRightArrowIcon.svg";
import smilyFace from "../../assets/images/heartEyes.svg";
import heartFace from "../../assets/images/heartFace.svg";
import heartIconPurple from "../../assets/images/heartIconPurple.svg";
import homeMeditationIcon from "../../assets/images/homeMeditationIcon.svg";
import homeScreenBackground from "../../assets/images/homeScreenBackground.png";
import howToUseAltHeader from "../../assets/images/how-to-use-header.png";
import heroImage from "../../assets/images/image-5.jpg";
import instagramIcon from "../../assets/images/instagramIcon.svg";
import introScreenLogo from "../../assets/images/introScreenLogo.png";
import leftArrowWhite from "../../assets/images/leftArrowWhite.svg";
import lockIcon from "../../assets/images/lockIcon.svg";
import logoWhiteSvg from "../../assets/images/logoWhiteSvg.svg";
import loopIconOff from "../../assets/images/loop-off.svg";
import loopIconOn from "../../assets/images/loop-on.svg";
import {
  default as cardImage,
  default as videoImage4,
} from "../../assets/images/mediation-card.png";
import meditationCreatorAlt from "../../assets/images/meditationCreator.png";
import melody from "../../assets/images/melody.svg";
import SendIcon from "../../assets/images/messageIcon.png";
import monthlyDonationBackgroundImage from "../../assets/images/monthlyDonationBackgroundImage.png";
import muteVolume from "../../assets/images/muteVolume.svg";
import myListAltHeader from "../../assets/images/my-list-header.png";
import myNotesDelete from "../../assets/images/myNotesDelete.svg";
import myNotesEidit from "../../assets/images/myNotesEidit.svg";
import myNotesPlay from "../../assets/images/myNotesPlay.svg";
import myNotesShare from "../../assets/images/myNotesShare.svg";
import myProfileAcheivementSelected from "../../assets/images/myProfileAcheivementSelected.svg";
import myProfileAcheivementUnSelected from "../../assets/images/myProfileAcheivementUnSelected.svg";
import navigateHowItWorksPlay from "../../assets/images/navigateHowItWorksPlay.svg";
import navigateHowItWorksPlayGreen from "../../assets/images/navigateHowItWorksPlayGreen.svg";
import patentedFormulated from "../../assets/images/patentedFormulated.png";
import defaultUserImage from "../../assets/images/placeholderIcon.svg";
import profileMenu from "../../assets/images/profileMenu.png";
import quotes from "../../assets/images/quotes.svg";
import recentMeditationIcon from "../../assets/images/recentMeditationIcon.svg";
import reminderSvg from "../../assets/images/reminderSvg.svg";
import ringSubscriptionActivation from "../../assets/images/ringSubscriptionActivation.svg";
import sadFace from "../../assets/images/sadFace.svg";
import selectMediation from "../../assets/images/selectMeditation.svg";
import sendIcon2 from "../../assets/images/sendIcon.svg";
import heartEyes from "../../assets/images/smilyFace.svg";
import soulColorLogo from "../../assets/images/soul-logo-main-colored.png";
import soulBenefitsImage from "../../assets/images/soulBenefitsImage.png";
import soulLinkColredLogo from "../../assets/images/soulLinkColredLogo.svg";
import soulWhiteLoader from "../../assets/images/soulLoader.svg";
import soulPurpleIcon from "../../assets/images/soulPurpleIcon.svg";
import logoWhite from "../../assets/images/soul_logo.svg";
import subscribeButtonBackgroundImage from "../../assets/images/subscribeButtonBackgroundImage.png";
import subscribeSoulLinkBackground from "../../assets/images/subscribeSoulLinkBackground.png";
import subscriptionActivation from "../../assets/images/subscriptionActivation.svg";
import subscriptionCheckedIcon from "../../assets/images/subscriptionCheckedIcon.svg";
import suggestedMeditationIcon from "../../assets/images/suggestedMeditationIcon.svg";
import supernalWorld from "../../assets/images/supernalWorld.png";
import supernalWorldCreationsLogo from "../../assets/images/supernalWorldCreationsLogo.svg";
import supernalWorldIcon from "../../assets/images/supernalWorldIcon.png";
import supernalWorldSvg from "../../assets/images/supernalWorldSvg.svg";
import supernalWorldWhite from "../../assets/images/supernalWorldWhite.png";
import supportRequestPurpleIcon from "../../assets/images/supportRequestPurpleIcon.svg";
import supportRequestWhiteIcon from "../../assets/images/supportRequestWhiteIcon.svg";
import tickWithCircleBorder from "../../assets/images/tickWithCircleBorder.svg";
import circleTimeImage from "../../assets/images/timeToggle.svg";
import circleTimeImageWhite from "../../assets/images/timeToggleWhite.svg";
import timer from "../../assets/images/timer.svg";
import transcendental from "../../assets/images/transcendental.png";
import transformTheWay from "../../assets/images/transformTheWay.png";
import trashSvg from "../../assets/images/trash.svg";
import upIconImage from "../../assets/images/upIconImage.png";
import verificationMessage from "../../assets/images/verification-message.png";
import verticalLineAppbar from "../../assets/images/verticalLineAppbar.svg";
import videoImage1 from "../../assets/images/video-1.png";
import videoImage2 from "../../assets/images/video-2.png";
import videoImage3 from "../../assets/images/video-3.png";
import videoCardHowItWorks from "../../assets/images/videoCardHowItWorks.gif";
import videoCardHowItWorksStatic from "../../assets/images/videoCardHowItWorksStatic.png";
import videoIcon from "../../assets/images/videoIcon.svg";
import videoPlayMusicIcon from "../../assets/images/videoPlayMusicIcon.svg";
import videoPlayMusicWhiteIcon from "../../assets/images/videoPlayMusicWhiteIcon.svg";
import VideoQualityCheck from "../../assets/images/videoQualityCheckIcon.svg";
import VideoSettings from "../../assets/images/videoSettingsIcon.svg";
import videoWhiteIcon from "../../assets/images/videoWhiteIcon.svg";
import visual from "../../assets/images/visual.png";
import VolumeIcon from "../../assets/images/volume.svg";
import whiteAudioIcon from "../../assets/images/whiteAudioIcon.svg";
import whiteScreenLoader from "../../assets/images/whiteScreenLoader.svg";
import whiteTickWithWhiteCircle from "../../assets/images/whiteTickWithWhiteCircle.svg";
import youTubeIcon from "../../assets/images/youTubeIcon.svg";

// MUI
export const AccessibilityOutlined = MuiAccessibilityOutlined;
export const AccountCircleOutlined = MuiAccountCircleOutlined;
export const AddIcon = MuiAdd;
export const Airplay = MuiAirplay;
export const ArrowBackIosNewIcon = MuiArrowBackIosNew;
export const ArrowForwardIosIcon = MuiArrowForwardIos;
export const BorderColorOutlined = MuiBorderColorOutlined;
export const CardMembershipOutlined = MuiCardMembershipOutlined;
export const CheckIcon = MuiCheckCircle;
export const ClosedCaptionDisabledIcon = MuiClosedCaptionDisabled;
export const ClosedCaptionIcon = MuiClosedCaption;
export const CloseFullScreen = MuiCloseFullscreen;
export const CrossIcon = MuiClear;
export const DescriptionOutlined = MuiDescriptionOutlined;
export const DoneIcon = MuiDone;
export const DownIcon = MuiKeyboardArrowDown;
export const EmptyHeart = MuiFavoriteBorderOutlined;
export const ExpandMoreOutlined = MuiExpandMoreOutlined;
export const FastForward = MuiForward10Outlined;
export const FastRewind = MuiReplay10Outlined;
export const FillHeart = MuiFavoriteOutlined;
export const FormatQuote = MuiFormatQuote;
export const ForumOutlined = MuiForumOutlined;
export const FullScreen = MuiOpenInFull;
export const GavelOutlined = MuiGavelOutlined;
export const GoogleCast = MuiCastOutlined;
export const HelpOutlined = MuiHelpOutlineOutlined;
export const HomeOutlined = MuiHomeOutlined;
export const HorizontalRuleOutlined = MuiHorizontalRuleOutlined;
export const InfoOutlined = MuiInfoOutlined;
export const KeyboardArrowLeft = MuiKeyboardArrowLeft;
export const LockResetOutlined = MuiLockResetOutlined;
export const ManageAccountsOutlined = MuiManageAccountsOutlined;
export const MutedVloume = MuiVolumeMute;
export const Pause = MuiPauseCircleFilledOutlined;
export const PermIdentityOutlined = MuiPermIdentityOutlined;
export const Play = MuiPlayCircleFilledOutlined;
export const PlayCircleIcon = MuiPlayCircleFilled;
export const PsychologyOutlined = MuiPsychologyOutlined;
export const ReportGmailerrorredOutlined = MuiReportGmailerrorredOutlined;
export const SearchIcon = MuiSearchCircle;
export const SearchOutlined = MuiSearchOutlined;
export const SettingOutlined = MuiSettingsOutlined;
export const ShareIcon = MuiShareOutlined;
export const ShieldOutlined = MuiShieldOutlined;
export const SoundIcon = MuiVolumeUp;
export const SpeakerNotesOutlined = MuiSpeakerNotesOutlined;
export const SubscriptionsOutlined = MuiSubscriptionsOutlined;
export const UpIcon = MuiKeyboardArrowUp;
export const UserIcon = MuiPersonOutline;

//Images
// export const AccountProfile = accountProfile;
export const AboutUsLogo = aboutUsLogo;
export const AccessWhite = accessWhite;
export const AddSvgIcon = addSvgIcon;
export const AnnualDonationBackgroundImage = annualDonationBackgroundImage;
export const AppBarCreate = appBarCreate;
export const AppBarCreateWhite = appBarCreateWhite;
export const AppBarExplore = appBarExplore;
export const AppBarExploreWhite = appBarExploreWhite;
export const AppBarHome = appBarHome;
export const AppBarHomeWhite = appBarHomeWhite;
export const AppBarMyList = appBarMyList;
export const AppBarMyListWhite = appBarMyListWhite;
export const AppleIcon = Apple;
export const ArrowDownPurple = arrowDownPurple;
export const ArrowDownWhite = arrowDownWhite;
export const ArrowDownWhiteIcon = arrowDownWhiteIcon;
export const AudioCardHowItWorks = audioCardHowItWorks;
export const AudioIcon = audioIcon;
export const AudioImage1 = audioImage1;
export const AudioImage2 = audioImage2;
export const AudioImage3 = audioImage3;
export const AudioMusicIcon = audioMusicIcon;
export const AuthBackgroundImage = authBackgroundImage;
export const BackIcon = backIcon;
export const BestValuesAnnualDonation = bestValuesAnnualDonation;
export const CardImage = cardImage;
export const CeoImage = ceoImage;
export const ChangeAudio = changeAudio;
export const CheckBoxChecked = checkBoxChecked;
export const CheckBoxUnChecked = checkBoxUnChecked;
export const CircleTimeToggle = circleTimeImage;
export const CircleTimeToggleWhite = circleTimeImageWhite;
export const Conciousness = conciousness;
export const CreateMeditationLogo = createMeditationLogo;
export const CrossIconTutorial = crossIcon;
export const CrossWhite = crossWhite;
export const CrossWhiteIconWithBorder = crossWhiteIconWithBorder;
export const CurrentMediations = currentMediations;
export const DailyInpirationMenuSvg = dailyInpirationMenuSvg;
export const DailyMeditationIcon = dailyMeditationIcon;
export const DefaultUserImage = defaultUserImage;
export const DoubleQoutes = doubleQoutes;
export const ExploreAltHeader = exploreAltHeader;
export const FaceBookIcon = faceBookIcon;
export const FequencyWavesCardHowItWorks = fequencyWavesCardHowItWorks;
export const FrequancyImage1 = frequancyImage1;
export const FrequancyImage2 = frequancyImage2;
export const FrequencyIcon = frequencyIcon;
export const FrequencyMusicIcon = frequencyMusicIcon;
export const FrequencyWhiteIcon = frequencyWhiteIcon;
export const FrequencyDisabledIcon = frequencyDisabledIcon;
export const GifCreateHeader = gifCreateHeader;
export const Harmony = harmony;
export const HeaderLeftArrowIcon = headerLeftArrowIcon;
export const HeaderRightArrowIcon = headerRightArrowIcon;
export const HeartEyes = heartEyes;
export const HeartFace = heartFace;
export const HeartIconPurple = heartIconPurple;
export const HeroImage = heroImage;
export const HomeMeditationIcon = homeMeditationIcon;
export const HomeScreenBackground = homeScreenBackground;
export const HowToUseAltHeader = howToUseAltHeader;
export const InstagramIcon = instagramIcon;
export const IntroScreenLogo = introScreenLogo;
export const LeftArrowWhite = leftArrowWhite;
export const LockIcon = lockIcon;
export const LogoWhite = logoWhite;
export const LogoWhiteSvg = logoWhiteSvg;
export const LoopPlayIcon = loopIconOn;
export const LoopStopIcon = loopIconOff;
export const MeditationCreatorAlt = meditationCreatorAlt;
export const Melody = melody;
export const MessageIcon = SendIcon;
export const MonthlyDonationBackgroundImage = monthlyDonationBackgroundImage;
export const MyListAltHeader = myListAltHeader;
export const MyNotesDelete = myNotesDelete;
export const MyNotesEidit = myNotesEidit;
export const MyNotesPlay = myNotesPlay;
export const MyNotesShare = myNotesShare;
export const MyProfileAcheivementSelected = myProfileAcheivementSelected;
export const MyProfileAcheivementUnSelected = myProfileAcheivementUnSelected;
export const NavigateHowItWorksPlay = navigateHowItWorksPlay;
export const NavigateHowItWorksPlayGreen = navigateHowItWorksPlayGreen;
export const PatentedFormulatedLogo = patentedFormulated;
export const PlusIcon = plusIcon;
export const ProfileMenuImage = profileMenu;
export const Quotes = quotes;
export const RecentMeditationIcon = recentMeditationIcon;
export const ReminderIcon = reminderSvg;
export const RingSubscriptionActivation = ringSubscriptionActivation;
export const SadFace = sadFace;
export const SelectMediation = selectMediation;
export const SelectMeditationImage = selectMediation;
export const SendIcon2 = sendIcon2;
export const SmilyFace = smilyFace;
export const SoulBenefitsImage = soulBenefitsImage;
export const SoulColorLogo = soulColorLogo;
export const SoulLinkColredLogo = soulLinkColredLogo;
export const SoulPurpleIcon = soulPurpleIcon;
export const SoulWhiteLoader = soulWhiteLoader;
export const SubscribeButtonBackgroundImage = subscribeButtonBackgroundImage;
export const SubscribeSoulLinkBackground = subscribeSoulLinkBackground;
export const SubscriptionActivation = subscriptionActivation;
export const SubscriptionCheckedIcon = subscriptionCheckedIcon;
export const SuggestedMeditationIcon = suggestedMeditationIcon;
export const SupernalWorld = supernalWorld;
export const SupernalWorldCreationsLogo = supernalWorldCreationsLogo;
export const SupernalWorldIcon = supernalWorldIcon;
export const SupernalWorldSvg = supernalWorldSvg;
export const SupernalWorldWhite = supernalWorldWhite;
export const SupportRequestPurpleIcon = supportRequestPurpleIcon;
export const SupportRequestWhiteIcon = supportRequestWhiteIcon;
export const TickWithCircleBorder = tickWithCircleBorder;
export const TimerImage = timer;
export const Transcendental = transcendental;
export const TransformTheWayImg = transformTheWay;
export const TrashSvg = trashSvg;
export const UpIconImage = upIconImage;
export const VerificationMessage = verificationMessage;
export const VerticalLineAppbar = verticalLineAppbar;
export const VideoCardHowItWorks = videoCardHowItWorks;
export const VideoCardHowItWorksStatic = videoCardHowItWorksStatic;
export const VideoIcon = videoIcon;
export const VideoImage1 = videoImage1;
export const VideoImage2 = videoImage2;
export const VideoImage3 = videoImage3;
export const VideoImage4 = videoImage4;
export const VideoPlayMusicIcon = videoPlayMusicIcon;
export const VideoPlayMusicWhiteIcon = videoPlayMusicWhiteIcon;
export const VideoQualityCheckIcon = VideoQualityCheck;
export const VideoSettingsIcon = VideoSettings;
export const VideoWhiteIcon = videoWhiteIcon;
export const Visual = visual;
export const Volume = VolumeIcon;
export const MuteVolume = muteVolume;
export const WhiteAudioIcon = whiteAudioIcon;
export const WhiteScreenLoader = whiteScreenLoader;
export const WhiteTickWithWhiteCircle = whiteTickWithWhiteCircle;
export const YouTubeIcon = youTubeIcon;

export const daysList = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const frequencyDefaultVolume = 20;
