/* eslint-disable indent */
import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import MeditationAccordian from "components/createMeditationAccordian";
import { SLMeditationLoader } from "components/createMeditationAccordian/createMeditationLoader";
import { ErrorMsg } from "components/errorMsg/ErrorMsg";
import HeroSection from "components/homeComponent/menuHeader";
import { MyCreatedMeditations } from "components/myCreatedMeditations";
import { CustomizedSlider } from "components/numberSlider";
import Page from "components/pageTitle";
import { SLButton } from "components/slButton";
import { SLTypography } from "components/slTypography";
import { SLVerificationMessage } from "components/verificationMessage";
import { lang } from "helpers/common/lang";
import {
  AudioIcon,
  CrossIconTutorial,
  FrequencyIcon,
  FrequencyWhiteIcon,
  HomeMeditationIcon,
  VideoIcon,
  VideoWhiteIcon,
  WhiteAudioIcon,
} from "helpers/enum/constants";
import TransformTheWay from "pages/createMeditation/transformTheWay";
import SubscribeSoulLink from "pages/createMeditation/unlockSoulLink";
import { useEffect, useMemo, useRef, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSetState } from "react-use";
import { Auth } from "utils/authService";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import { v4 as uuidv4 } from "uuid";
import "./index.css";

interface State {
  run: boolean;
  steps: Step[];
}

let createMeditationLoader2 = false;

const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const checkTutorial = SLStorage.getItem("homeMeditationTutorial");
  const guestLogin = SLStorage.getItem("guestLogin");
  const userLogin = Auth.user.loggedin();
  const targetElementRef = useRef<HTMLDivElement>(null);

  const { data } = useSelector(SLSelector.getFilters);
  const { data: userSubscription, error: subscriptionError } = useSelector(
    SLSelector.getSubscription
  );
  const {
    data: meditationData,
    loading: meditationLoading,
    error: meditationError,
  } = useSelector(SLSelector.postMeditationCreate);

  const [uniqueId, setuniqueId] = useState("");
  const [userCancelMeditation, setuserCancelMeditation] = useState(false);
  const [createMeditationLoader, setMedidtationLoader] = useState(false);
  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaTags, setMediaTags] = useState<any>({});
  const [openDialog, setOpenDialog] = useState(false);
  const [createMeditation, setCreateMeditation] = useState<any>("");
  const [addedToFavouriteList, setAddedToFavouriteList] = useState(false);
  const [noMeditationFound, setNoMeditationFound] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorTitle, setErrorTitle] = useState(
    "Server Error, Please try again in a moment"
  );
  const [ErrorBody, setErrorBody] = useState("");
  const [tutorialStep, setTutorialStep] = useState(0);
  const [cancelStatus, setCancelStatus] = useState(false);
  const [turorialMeditation, setTutorialMeditation] = useState<any>("");
  const [videoTurorialMeditation, setVideoTutorialMeditation] =
    useState<any>("");
  const [frequencyTurorialMeditation, setFrequencyTutorialMeditation] =
    useState<any>("");

  const [accordian, setAccordian] = useState({
    audio: false,
    video: false,
    frequency: false,
  });
  const [accordianLoading, setAccordianLoading] = useState(true);
  const [selectedMeditationData, setSelectedMeditationData] = useState<{
    duration: number;
    audio: any;
    video: any;
    frequency: any;
  }>({
    duration: SLStorage.getItem("selectedMeditationDataCreate")?.duration || 12,
    audio: null,
    video: null,
    frequency: null,
  });
  const selectedDuration = Number(selectedMeditationData?.duration) || 12;
  const mediaType = {
    Audio: "audio",
    Video: "video",
    Frequency: "frequencyWave",
  };

  useEffect(() => {
    setAccordianLoading(true);
    if (targetElementRef.current) {
      targetElementRef.current.focus();
    }
    setuniqueId(uuidv4());
    selectMeditaionIds();
    dispatch(SLThunk.getFilters.call({}));
    createMeditationLoader2 = false;
  }, []);

  const selectMeditaionIds = async () => {
    const getSelectedMeditation = SLStorage.getItem(
      "selectedMeditationDataCreate"
    );
    if (
      getSelectedMeditation &&
      (getSelectedMeditation?.audio ||
        getSelectedMeditation?.video ||
        getSelectedMeditation?.frequency)
    ) {
      setTimeout(() => {
        setSelectedMeditationData(getSelectedMeditation);
        setTimeout(() => {
          setAccordianLoading(false);
        }, 100);
      }, 300);
    } else {
      setAccordianLoading(false);
    }
  };

  const mediaDurationSelect = (duration: number) => {
    if (duration !== selectedDuration) {
      setSelectedMeditationData({ duration } as any);
      SLStorage.setItem("selectedMeditationDataCreate", { duration });
    }
  };

  useEffect(() => {
    if (!createMeditationLoader) {
      setTimeout(() => {
        document.body.style.overflow = "";
      }, 300);
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [createMeditationLoader]);

  useEffect(() => {
    if (meditationError) {
      document.body.style.overflow = "";
      setErrorTitle("Oops!");
      setErrorBody(meditationError);
      dispatch(SLThunk.postMeditationCreate.reset());
      setOpenDialog(true);
    }
  }, [meditationError]);

  useEffect(() => {
    if (subscriptionError) {
      dispatch(SLThunk.getSubscription.reset());
    }
  }, [subscriptionError]);

  useEffect(() => {
    if (meditationData && !userCancelMeditation && !cancelStatus) {
      navigate(`/app/play-back/${meditationData?.id}`);
      dispatch(SLThunk.postMeditationCreate.reset());
      document.body.style.overflow = "";
    }
  }, [meditationData]);

  useEffect(() => {
    setMedidtationLoader(meditationLoading);
  }, [meditationLoading]);

  useEffect(() => {
    if (createMeditation) {
      SLStorage.removeItem("selectedMeditationDataCreate");
      dispatch(SLThunk.postMeditationCreate.call(createMeditation));
    }
  }, [createMeditation]);

  const createMeditationHandler = () => {
    if (createMeditationLoader2) {
      return;
    }

    createMeditationLoader2 = true;

    setCancelStatus(false);
    if (
      selectedMeditationData?.audio &&
      !selectedMeditationData?.video &&
      !selectedMeditationData?.frequency
    ) {
      sessionStorage.setItem("fromBuildMeditation", "true");
      sessionStorage.setItem("onDetailPage", "false");
      setMedidtationLoader(true);
      if (!userCancelMeditation) {
        setMedidtationLoader(false);
        createMeditationLoader2 = false;
        SLStorage.removeItem("previousURLStore");
        SLStorage.removeItem("selectedMeditationDataCreate");
        navigate(`/app/media-player/${selectedMeditationData?.audio.id}`);
        document.body.style.overflow = "";
      }
    } else if (
      !selectedMeditationData?.audio &&
      selectedMeditationData?.video &&
      !selectedMeditationData?.frequency
    ) {
      sessionStorage.setItem("fromBuildMeditation", "true");
      sessionStorage.setItem("onDetailPage", "false");
      setMedidtationLoader(true);
      if (!userCancelMeditation) {
        setMedidtationLoader(false);
        createMeditationLoader2 = false;
        SLStorage.removeItem("previousURLStore");
        SLStorage.removeItem("selectedMeditationDataCreate");
        navigate(`/app/media-player/${selectedMeditationData?.video.id}`);
        document.body.style.overflow = "";
      }
    } else if (
      selectedMeditationData?.audio &&
      selectedMeditationData?.video &&
      !selectedMeditationData?.frequency
    ) {
      setCreateMeditation({
        audioId: selectedMeditationData?.audio.id,
        videoId: selectedMeditationData?.video.id,
        title: mediaTitle,
        isDeepLink: false,
        image: selectedMeditationData?.video.image,
        thumbnail: selectedMeditationData?.video.image,
        uniqueIdentifier: uniqueId,
        duration: selectedMeditationData?.duration,
      });
    } else if (
      selectedMeditationData?.audio &&
      !selectedMeditationData?.video &&
      selectedMeditationData?.frequency
    ) {
      setCreateMeditation({
        audioId: selectedMeditationData?.audio.id,
        frequencyWaveId: selectedMeditationData?.frequency.id,
        title: mediaTitle,
        isDeepLink: false,
        image: selectedMeditationData?.frequency.image,
        thumbnail: selectedMeditationData?.frequency.image,
        uniqueIdentifier: uniqueId,
        duration: selectedMeditationData?.duration,
      });
    } else if (
      selectedMeditationData?.audio &&
      selectedMeditationData?.video &&
      selectedMeditationData?.frequency
    ) {
      setCreateMeditation({
        videoId: selectedMeditationData?.video.id,
        audioId: selectedMeditationData?.audio.id,
        frequencyWaveId: selectedMeditationData?.frequency.id,
        title: mediaTitle,
        isDeepLink: false,
        image: selectedMeditationData?.video.image,
        thumbnail: selectedMeditationData?.video.image,
        uniqueIdentifier: uniqueId,
        duration: selectedMeditationData?.duration,
      });
    }
  };

  useEffect(() => {
    if (selectedMeditationData?.audio && !selectedMeditationData?.video) {
      setMediaTitle(String(selectedMeditationData?.audio?.title).trim());
    } else if (
      !selectedMeditationData?.audio &&
      selectedMeditationData?.video
    ) {
      setMediaTitle(String(selectedMeditationData?.video?.title).trim());
    } else if (selectedMeditationData?.audio && selectedMeditationData?.video) {
      setMediaTitle(
        String(selectedMeditationData?.audio?.title).trim() +
          " in " +
          String(selectedMeditationData?.video?.title).trim()
      );
    }
  }, [selectedMeditationData?.audio?.id, selectedMeditationData?.video?.id]);

  const {
    buildMeditationNameError,
    showBuildMeditationName,
    buildMeditationDisabled,
  } = useMemo(() => {
    const buildMeditationNameError =
      !mediaTitle &&
      (selectedMeditationData?.video?.id ||
        selectedMeditationData?.audio?.id ||
        selectedMeditationData?.frequency?.id);

    const showBuildMeditationName =
      tutorialStep === 6 ||
      selectedMeditationData?.audio?.id ||
      (selectedMeditationData?.video?.id &&
        !selectedMeditationData?.frequency?.id);

    const buildMeditationDisabled =
      buildMeditationNameError || !showBuildMeditationName;

    return {
      buildMeditationNameError,
      showBuildMeditationName,
      buildMeditationDisabled,
    };
  }, [
    mediaTitle,
    tutorialStep,
    selectedMeditationData?.video?.id,
    selectedMeditationData?.audio?.id,
    selectedMeditationData?.frequency?.id,
  ]);

  useEffect(() => {
    if (data) {
      const result = data?.reduce(function (r: any, a: any) {
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
        return r;
      }, {});
      setMediaTags(result);
    }
  }, [data]);

  const changeText = (e: any) => {
    // setMediaTitle(String(e.target.value || "").trim());
    setMediaTitle(e.target.value);
  };
  const handleBlur = (e: any) => {
    // setMediaTitle(String(e.target.value || "").trim());
    setMediaTitle(mediaTitle.trim());
  };
  const handleErrorClose = () => {
    createMeditationLoader2 = false;
    setOpenDialog(false);
  };

  const handleOpenAccordian =
    (panel: "audio" | "video" | "frequency") =>
    (event: any, isExpanded: any) => {
      panel = panel.replace("frequencyWave", "frequency") as any;

      const accordianState = {
        audio: false,
        video: false,
        frequency: false,
      };

      if (isExpanded) {
        accordianState[panel] = true;
      }

      setAccordian(accordianState);
    };

  const userCancelMeditationHandler = (cancelStatus: boolean) => {
    setCancelStatus(cancelStatus);
    if (!cancelStatus) {
      if (
        selectedMeditationData?.audio &&
        !selectedMeditationData?.video &&
        !selectedMeditationData?.frequency
      ) {
        setMedidtationLoader(false);
        createMeditationLoader2 = false;
        SLStorage.setItem("previousURLStore", window.location.pathname);
        navigate(`/app/media-player/${selectedMeditationData?.audio.id}`);
        document.body.style.overflow = "";
      } else if (
        !selectedMeditationData?.audio &&
        selectedMeditationData?.video &&
        !selectedMeditationData?.frequency
      ) {
        setMedidtationLoader(false);
        createMeditationLoader2 = false;
        SLStorage.setItem("previousURLStore", window.location.pathname);
        navigate(`/app/media-player/${selectedMeditationData?.video.id}`);
        document.body.style.overflow = "";
      } else {
        if (meditationData) {
          navigate(`/app/play-back/${meditationData?.id}`);
          document.body.style.overflow = "";
        } else {
          document.body.style.overflow = "hidden";
          setuserCancelMeditation(false);
        }
      }
    } else {
      setMedidtationLoader(false);
      createMeditationLoader2 = false;
      setuserCancelMeditation(false);
      if (
        (selectedMeditationData?.audio &&
          selectedMeditationData?.video &&
          selectedMeditationData?.frequency) ||
        (selectedMeditationData?.audio && selectedMeditationData?.video) ||
        (selectedMeditationData?.audio && selectedMeditationData?.frequency)
      ) {
        dispatch(SLThunk.deleteMeditationCancelId.call({ uniqueId: uniqueId }));
      }
    }
  };

  const redirect = () => {
    navigate("/app/my-list");
  };

  const hideTolltip = () => {
    const elem = document.querySelector<HTMLElement>(
      ".meditationSliderContainer"
    );
    setState({ run: false });
    setAccordian({ audio: false, video: false, frequency: false });
    if (!checkTutorial) {
      SLStorage.setItem("homeMeditationTutorial", true);
    }
    if (elem) {
      elem.style.removeProperty("background-color");
      elem.style.removeProperty("border-radius");
      elem.style.removeProperty("padding-top");
    }
    setState({ run: false });
  };

  const [{ run, steps }, setState] = useSetState<State>({
    run: true,
    steps: [
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Start your day with our daily meditations! Newly assembled each
              morning to feature a wide range of Soul Link content."
            >
              <p className="bodyTextTutorial">
                Start your day with our daily meditations! Newly assembled each
                morning to feature a wide range of Soul Link content.
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 1 of 8"
              >
                Walkthrough – Step 1 of 8
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} tabIndex={0} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".btnDailyMeditation",
        title: (
          <object tabIndex={0} aria-label="Daily Meditation">
            <h4
              className="tutorialHeading"
              tabIndex={0}
              aria-label="Daily Meditation"
            >
              Daily Meditation
            </h4>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Make your homepage background more you by clicking the Change
              Scene button."
            >
              <p className="bodyTextTutorial">
                Make your homepage background more you by clicking the "Change
                Scene" button.
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 2 of 8"
              >
                Walkthrough – Step 2 of 8
              </object>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Close",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".paper-text-change-screen",
        title: (
          <object tabIndex={0} aria-label="Change Background Scene">
            <p
              className="tutorialHeading"
              tabIndex={0}
              aria-label="Change Background Scene"
            >
              Change Background Scene
            </p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label=" Pro tip: you can always access the Menu by clicking your profile
              image in the right-hand corner – from any page."
            >
              <p className="bodyTextTutorial">
                Pro tip: you can always access the Menu by clicking your profile
                image in the right-hand corner – from any page.
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 3 of 8"
              >
                Walkthrough – Step 3 of 8
              </object>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Close",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "right",
        target: ".header-profile-icon",
        title: (
          <object tabIndex={0} aria-label="Menu">
            <p className="tutorialHeading" tabIndex={0} aria-label="Menu">
              Menu
            </p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Make it your own! You can start by dragging the slider to choose a
              duration of your meditation. Then select your Audio, Video, and
              Frequency Wave files within each section by clicking the + symbol."
            >
              <p className="bodyTextTutorial">
                Make it your own! You can start by dragging the slider to choose
                a duration of your meditation. Then select your Audio, Video,
                and Frequency Wave files within each section by clicking the +
                symbol.
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 4 of 8"
              >
                Walkthrough – Step 4 of 8
              </object>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Close",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".meditationSliderContainer",
        title: (
          <object aria-label="Building Meditations" tabIndex={0}>
            <p className="tutorialHeading">Building Meditations</p>{" "}
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="You can click on the thumbnail to learn more about what goes into
              each file."
            >
              <p className="bodyTextTutorial">
                You can click on the thumbnail to learn more about what goes
                into each file.
              </p>
              <object
                className="footerTextTutorial"
                aria-label="Walkthrough – Step 5 of 8"
                tabIndex={0}
              >
                Walkthrough – Step 5 of 8
              </object>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Close",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".slick-list .slick-slide:nth-child(2) .mediation-card",
        title: (
          <object tabIndex={0} aria-label="File Details">
            <p
              className="tutorialHeading"
              tabIndex={0}
              aria-label="File Details"
            >
              File Details
            </p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Any compilation of files that includes at least an Audio or Video
              file is a Soul Link Meditation."
            >
              <p className="bodyTextTutorial">
                Any compilation of files that includes at least an Audio or
                Video file is a Soul Link Meditation.
              </p>

              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 6 of 8"
              >
                Walkthrough – Step 6 of 8
              </object>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Close",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".allMediaContainer",
        title: (
          <object tabIndex={0} aria-label="Building Meditations">
            <p className="tutorialHeading">Building Meditations</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Once you've selected your files, you can give your custom
              meditation a name. Then click Build Meditation to begin."
            >
              <p className="bodyTextTutorial">
                Once you've selected your files, you can give your custom
                meditation a name. Then click Build Meditation to begin.
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 7 of 8"
              >
                Walkthrough – Step 7 of 8
              </object>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Close",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".MeditationTitleTextField",
        title: (
          <object tabIndex={0} aria-label="Building Meditations">
            <p className="tutorialHeading">Building Meditations</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Access meditations again in the Recent Meditations section of My
              List on the Home Page, or by navigating to the My List page."
            >
              <p className="bodyTextTutorial">
                Access meditations again in the Recent Meditations section of My
                List on the Home Page, or by navigating to the My List page.
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 8 of 8"
              >
                Walkthrough – Step 8 of 8
              </object>
              <Button
                type="button"
                aria-live="off"
                aria-label=""
                data-action="skip"
                role="button"
                title=""
                className="lastStepBtn"
                onClick={hideTolltip}
                onKeyPress={hideTolltip}
                tabIndex={0}
              >
                <img src={CrossIconTutorial} tabIndex={0} />
              </Button>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Close",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "bottom",
        target: ".meditationHeading span",
        title: (
          <object tabIndex={0} aria-label="My List">
            <p className="tutorialHeading" tabIndex={0} aria-label="My List">
              My List
            </p>
          </object>
        ),
        disableBeacon: true,
      },
    ],
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    window.dispatchEvent(new Event("resize"));
    const { status, type, index } = data;
    const button = document.querySelector('button[data-action="skip"]');
    if (button) {
      button.setAttribute("title", "Skip tutorial");
      button.setAttribute("aria-label", "Skip tutorial");
    }
    const elem = document.querySelector<HTMLElement>(
      ".meditationSliderContainer"
    );
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    if (index === 0 && type === "step:after") {
      setTutorialStep(1);
    }
    if (index === 1 && type === "step:after") {
      setTutorialStep(2);
    }
    if (index === 2 && type === "step:after") {
      setTutorialStep(3);
    }
    if (index === 3) {
      if (elem) {
        elem.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
        elem.style.borderRadius = "4px";
        elem.style.paddingTop = "5px";
        // elementOverlay.style.marginTop = "1px";
      }
      const elementOverlay = document.querySelector<HTMLElement>(
        ".react-joyride__spotlight"
      );
      if (elementOverlay) {
        const screenWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
        if (
          screenWidth > 1850 ||
          (screenWidth > 1300 && screenWidth < 1390) ||
          (screenWidth > 1450 && screenWidth < 1800)
        ) {
          const currentWidth = parseInt(elementOverlay.style.width) || 0;
          elementOverlay.style.width = currentWidth - 1 + "px";
        } else if (screenWidth < 1281 || screenWidth > 1850) {
          const currentWidth = parseInt(elementOverlay.style.width) || 0;
          elementOverlay.style.width = currentWidth + 1 + "px";
        }
      }
    }
    if (index === 3 && type === "step:after") {
      if (elem) {
        elem.style.removeProperty("background-color");
        elem.style.removeProperty("border-radius");
        elem.style.removeProperty("padding-top");
      }
      setAccordian({ audio: true, video: false, frequency: false });
      setTimeout(() => {
        setTutorialStep(4);
      }, 400);
    }
    if (index === 4) {
      const elementOverlay = document.querySelector<HTMLElement>(
        ".react-joyride__spotlight"
      );
      if (elementOverlay) {
        elementOverlay.style.borderRadius = "10px";
        elementOverlay.style.marginLeft = "0px";
        elementOverlay.style.marginTop = "0px";
      }
    }
    if (index === 4 && type === "step:after") {
      setAccordian({ audio: false, video: false, frequency: false });
      const elementOverlay = document.querySelector<HTMLElement>(
        ".react-joyride__spotlight"
      );
      if (elementOverlay) {
        elementOverlay.style.removeProperty("border-radius");
        elementOverlay.style.backgroundColor = "transparent";
      }
      const element = document.querySelector(".allMediaContainer"); // Replace 'targetDiv' with if
      if (element) {
        window.scroll({
          top: element.scrollTop,
          behavior: "smooth",
        });
      }
      setTimeout(() => {
        setTutorialStep(5);
      }, 500);
    }
    if (index === 5) {
      const elementOverlay = document.querySelector<HTMLElement>(
        ".react-joyride__spotlight"
      );
      if (elementOverlay) {
        elementOverlay.style.backgroundColor = "transparent";
      }
    }
    if (index === 5 && type === "step:after") {
      const elementOverlay = document.querySelector<HTMLElement>(
        ".react-joyride__spotlight"
      );
      if (elementOverlay) {
        elementOverlay.style.removeProperty("background-color");
        elementOverlay.style.borderRadius = "10px";
      }
      setTimeout(() => {
        setTutorialStep(6);
      }, 700);
      const merged = {
        ...turorialMeditation,
        ...videoTurorialMeditation,
        ...frequencyTurorialMeditation,
      };

      setSelectedMeditationData(merged);
    }
    if (index === 6) {
      const elementOverlay = document.querySelector<HTMLElement>(
        ".react-joyride__spotlight"
      );
      if (elementOverlay) {
        elementOverlay.style.borderRadius = "10px";
      }
    }
    if (index === 6 && type === "step:after") {
      setTutorialStep(7);
    }
    if (index === 7 && type === "step:after") {
      setState({ run: false });
      setAccordian({ audio: false, video: false, frequency: false });
      if (!checkTutorial) {
        SLStorage.setItem("homeMeditationTutorial", true);
      }
      if (elem) {
        elem.style.removeProperty("background-color");
        elem.style.removeProperty("border-radius");
        elem.style.removeProperty("padding-top");
      }
    }
    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      setAccordian({ audio: false, video: false, frequency: false });
      if (!checkTutorial) {
        SLStorage.setItem("homeMeditationTutorial", true);
      }
      if (elem) {
        elem.style.removeProperty("background-color");
        elem.style.removeProperty("border-radius");
        elem.style.removeProperty("padding-top");
      }
    }
  };

  return (
    <>
      <Box className="home-screen-height-controller">
        <Page title="" className="menu-page-main-class">
          <Grid container>
            <Grid item xs={12} lg={12}>
              <HeroSection />
            </Grid>
          </Grid>
        </Page>
        {!guestLogin && !checkTutorial ? (
          <Joyride
            callback={handleJoyrideCallback}
            continuous
            hideCloseButton={true}
            run={run}
            scrollToFirstStep={false}
            showProgress={false}
            showSkipButton={true}
            disableOverlayClose={true}
            steps={steps}
            stepIndex={tutorialStep}
            spotlightPadding={0}
            disableScrolling={false}
            disableScrollParentFix={false}
            styles={{
              options: {
                zIndex: 10000,
              },
            }}
          />
        ) : null}
        <div className="p-relative main-wrapper">
          <img
            className="homeMeditationLogo"
            src={HomeMeditationIcon}
            tabIndex={0}
            title="Soul Image"
          />
          <Container
            className="main-container p-relative"
            sx={{ width: { xl: "93.2%", lg: "95%" } }}
            maxWidth={false}
          >
            <Grid container>
              <Grid item xs={12} lg={12}>
                <Stack
                  tabIndex={0}
                  sx={{
                    width: { lg: "700px" },
                    margin: "0 auto",
                    textAlign: "center",
                    marginBottom: "20px",
                    marginTop: { xs: "40px", md: "50px", lg: "60px" },
                  }}
                >
                  <SLTypography
                    sx={{
                      fontSize: { xs: "28px", md: "37px" },
                      fontWeight: "600",
                      lineHeight: "43px",
                      color: "var(--sl-deep-purple)",
                      paddingBottom: { xs: "6px", sm: "20px", md: "30px" },
                    }}
                  >
                    Build Your Custom Meditation
                  </SLTypography>
                  <SLTypography
                    variant="homeParagraphText"
                    color="text-deepPurple"
                    sx={{
                      fontSize: { xs: "14px", md: "21px" },
                      lineHeight: { xs: "20px", md: "30px" },
                    }}
                  >
                    This first of-it's-kind technology offers a unique library
                    of audio, video, and frequency waves – so you can create
                    more meaningful meditations.
                  </SLTypography>
                </Stack>
                <div className="meditationSliderContainer">
                  <CustomizedSlider
                    durationvalue={selectedDuration}
                    mediaDurationSelect={mediaDurationSelect}
                    audiosetstate={selectedMeditationData?.audio}
                    videosetstate={selectedMeditationData?.video}
                    frequencysetstate={selectedMeditationData?.frequency}
                  />
                  <div className="allMediaContainer">
                    <MeditationAccordian
                      fromPage="HOME"
                      loading={accordianLoading}
                      addedToFavourites={setAddedToFavouriteList}
                      successMessageText={setSuccessMessage}
                      mediatags={mediaTags?.audio || []}
                      mediatype={mediaType.Audio}
                      mediaicon={AudioIcon}
                      mediatitle={"Audio"}
                      mediawhiteicon={WhiteAudioIcon}
                      duration={selectedMeditationData?.duration}
                      selectedMeditationData={selectedMeditationData?.audio}
                      selectedmedia={(audio: any) =>
                        setSelectedMeditationData((prv) => ({ ...prv, audio }))
                      }
                      open={accordian.audio}
                      accordianhandleChange={handleOpenAccordian}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      setSelectedMeditationDataflag={() => {}}
                      selectedMeditationDataFlag={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      setCardExistingData={() => {}}
                      setTutorialMeditation={setTutorialMeditation}
                      chooseMediaDescription="Our unique blend of ancient auditory progressions raise vibrations
                in the brain and enhance your emotional, mental, and physical
                performance."
                    />
                    <MeditationAccordian
                      fromPage="HOME"
                      loading={accordianLoading}
                      addedToFavourites={setAddedToFavouriteList}
                      successMessageText={setSuccessMessage}
                      mediatags={mediaTags?.video}
                      mediatype={mediaType.Video}
                      mediaicon={VideoIcon}
                      mediatitle={"Video"}
                      mediawhiteicon={VideoWhiteIcon}
                      duration={selectedMeditationData?.duration}
                      selectedMeditationData={selectedMeditationData?.video}
                      selectedmedia={(video: any) =>
                        setSelectedMeditationData((prv) => ({ ...prv, video }))
                      }
                      open={accordian.video}
                      accordianhandleChange={handleOpenAccordian}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      setSelectedMeditationDataflag={() => {}}
                      selectedMeditationDataFlag={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      setCardExistingData={() => {}}
                      setTutorialMeditation={setVideoTutorialMeditation}
                      chooseMediaDescription="Immersive imagery creates a timeless experience of calming landscapes, cosmic journeys, and cinematic escapes that transport your soul to new sceneries."
                    />
                    <MeditationAccordian
                      fromPage="HOME"
                      loading={accordianLoading}
                      addedToFavourites={setAddedToFavouriteList}
                      successMessageText={setSuccessMessage}
                      mediatags={mediaTags?.frequencyWave}
                      mediatype={mediaType.Frequency}
                      mediaicon={FrequencyIcon}
                      mediatitle={"Frequency Wave"}
                      mediawhiteicon={FrequencyWhiteIcon}
                      duration={selectedMeditationData?.duration}
                      selectedMeditationData={selectedMeditationData?.frequency}
                      selectedmedia={(frequency: any) =>
                        setSelectedMeditationData((prv) => ({
                          ...prv,
                          frequency,
                        }))
                      }
                      open={accordian.frequency}
                      accordianhandleChange={handleOpenAccordian}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      setSelectedMeditationDataflag={() => {}}
                      selectedMeditationDataFlag={false}
                      // eslint-disable-next-line @typescript-eslint/no-empty-function
                      setCardExistingData={() => {}}
                      setTutorialMeditation={setFrequencyTutorialMeditation}
                      chooseMediaDescription="Transformative frequency files stimulate the brain and induce specific states of consciousness designed to calm anxiety, reduce stress, and improve cognition."
                    />
                  </div>
                </div>

                <Stack sx={{ alignItems: "center" }}>
                  {showBuildMeditationName ? (
                    <Box sx={{ width: { xs: "100%", sm: "500px !important" } }}>
                      <TextField
                        className="sl-input MeditationTitleTextField"
                        sx={{
                          marginTop: {
                            xs: "20px !important",
                            md: "30px !important",
                          },
                          "&,& .MuiInputBase-root": {
                            width: { xs: "100%", sm: "500px !important" },
                          },
                        }}
                        label="Meditation Name"
                        onChange={changeText}
                        onBlur={handleBlur}
                        value={mediaTitle}
                      />

                      <ErrorMsg
                        text={lang("general.error.EmptyFields")}
                        show={buildMeditationNameError}
                      />
                    </Box>
                  ) : null}

                  <Button
                    className="buildMeditationBtn mt-[30px] w-[200px]"
                    onClick={createMeditationHandler}
                    aria-disabled={buildMeditationDisabled}
                    sx={{
                      marginTop: {
                        xs: "20px !important",
                        md: "30px !important",
                      },
                      opacity: !buildMeditationDisabled ? "1" : "0.3",
                      pointerEvents: !buildMeditationDisabled ? "all" : "none",
                    }}
                  >
                    <SLTypography
                      variant="title"
                      sx={{ fontWeight: "500", letterSpacing: "0.5px" }}
                      messageId={"meditation.build"}
                    />
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            {!guestLogin ? (
              <Divider sx={{ marginTop: { xs: "30px", md: "40px" } }} />
            ) : null}
          </Container>

          {!guestLogin ? (
            <Grid container className="mt-6 s-container homeMeditationList">
              <Grid item xs={12} lg={12} className="myListHomeScreen">
                <Box
                  className="pr-7 meditationHeading"
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <SLTypography
                    role="heading"
                    variant="mediaSubHeading"
                    tabIndex={0}
                  >
                    My List
                  </SLTypography>
                  {!noMeditationFound ? (
                    <SLTypography
                      variant="smallBodyUnderLined"
                      color="text-purple"
                      onClick={redirect}
                      sx={{
                        cursor: "pointer",
                        fontSize: { xs: "12px", sm: "17px" },
                      }}
                      tabIndex={0}
                    >
                      View All
                    </SLTypography>
                  ) : null}
                </Box>

                <MyCreatedMeditations
                  fromPage="HOME"
                  duration={selectedDuration}
                  addedToFavourites={setAddedToFavouriteList}
                  successMessageText={setSuccessMessage}
                  setSelectedMeditationData={setSelectedMeditationData}
                  setNoMeditationFound={setNoMeditationFound}
                  allMediaSelected={
                    selectedMeditationData?.audio ||
                    selectedMeditationData?.video ||
                    selectedMeditationData?.frequency
                  }
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setInitialMeditationCounter={() => {}}
                  initialMeditationCounter={false}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setCardExistingData={() => {}}
                  cardExistingData={undefined}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setChangeMeditationFlag={() => {}}
                  showTags={true}
                  sortByLastPlayed={true}
                />
              </Grid>
            </Grid>
          ) : null}
          <Container
            className="main-container"
            sx={{
              width: { lg: "95%", xl: "93.2%" },
              zIndex: 2,
              position: "relative",
              background: "none !imprtant",
              backgroundColor: "none !important",
            }}
            maxWidth={false}
          >
            <Divider
              sx={{
                height: "2px",
                marginTop: { xs: "20px", md: "50px" },
                marginBottom: { xs: "30px", md: "50px" },
              }}
            />

            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "row" },
              }}
              gap={2}
            >
              {userLogin && !userSubscription ? <SubscribeSoulLink /> : null}
              <TransformTheWay />
            </Box>
          </Container>
        </div>

        <Dialog
          open={openDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={{ borderRadius: "10px" }}
        >
          <Box sx={{ padding: "15px 25px" }}>
            <Stack direction="column" textAlign="left" spacing={1}>
              <DialogContent
                sx={{
                  fontSize: "18px",
                  fontWeight: 400,
                  fontStyle: "normal",
                  padding: 0,
                  color: "var(--sl-deep-purple)",
                }}
              >
                <SLTypography
                  sx={{
                    fontWeight: "600",
                    fontSize: "20px",
                    maxWidth: "350px",
                    textAlign: "center",
                  }}
                >
                  {errorTitle}
                </SLTypography>
                <SLTypography
                  sx={{
                    fontWeight: "400",
                    fontSize: "14px",
                    maxWidth: "350px",
                    textAlign: "center",
                  }}
                >
                  {ErrorBody}
                </SLTypography>
              </DialogContent>
            </Stack>
            <Box
              sx={{
                marginTop: "3%",
                textAlign: "center",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SLButton
                sx={{ width: "200px" }}
                variant="contained"
                messageId={"general.ok"}
                onClick={handleErrorClose}
              />
            </Box>
          </Box>
        </Dialog>

        <SLMeditationLoader
          setuserCancelMeditation={setuserCancelMeditation}
          userCancelMeditationHandler={userCancelMeditationHandler}
          open={createMeditationLoader || userCancelMeditation}
        />

        <SLVerificationMessage
          open={addedToFavouriteList}
          sx={{ zIndex: 9999 }}
          message={successMessage}
        />
      </Box>
    </>
  );
};

export default Home;
