import { createSlice } from "@reduxjs/toolkit";

const reduceMotionSlice = createSlice({
  name: "reduceMotion",
  initialState: false,
  reducers: {
    on: (state) => {
      state = true;

      return state;
    },
    off: (state) => {
      state = false;

      return state;
    },
  },
});

export default reduceMotionSlice.reducer;
export const reduceMotionState = (state: any) => state.reduceMotion;
export const reduceMotionActions = reduceMotionSlice.actions;
