import { Box, List, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { useEffect, useState } from "react";
import {
  AchievementsData,
  ICustomAchievement,
} from "utils/slRTKHelper/slAPI.models";
import CreateAchievementPopUp from "../../../../popUps/createAchievementPopup";
import { SLButton } from "../../../../slButton";
import Achievement from "./achievement";
import "./index.css";

const AchievementsListData = [
  {
    name: "creator",
    title: "Creator",
    description: " Create a meditation",
  },
  {
    name: "weekStreak",
    title: "Week Streak",
    description: "Meditate 7 days straight",
  },
  {
    name: "centuryClub",
    title: "Century Club",
    description: "100 minutes of meditation",
  },
  {
    name: "aboveAverage",
    title: "Above Average",
    description: "Meditate 20 min/day for 7 days",
  },
  {
    name: "soulLove",
    title: "Soul Love",
    description: "Favorite 10 meditations",
  },
  {
    name: "anniversary",
    title: "Anniversary",
    description: "Active member for 1 year",
  },
  {
    name: "shareTheLove",
    title: "Share the Love",
    description: "Share 5 meditations",
  },
  {
    name: "downloader",
    title: "Downloader",
    description: "Download 10 meditations or files",
  },
  {
    name: "grandMember",
    title: "Grand Member",
    description: "1000 minutes of meditations",
  },
  {
    name: "colossalCreator",
    title: "Colossal Creator",
    description: "Create 100 meditations",
  },
];

export default function Achievements({
  achievementsData,
  reload,
}: {
  achievementsData?: AchievementsData;
  reload: () => void;
}) {
  const [achievements, setAchievements] = useState<ICustomAchievement[]>();
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (achievementsData && achievementsData.data && achievementsData.data[0]) {
      const data = achievementsData.data[0];
      const completed: ICustomAchievement[] = [];
      const uncompleted: any[] = [];

      // Sorting:
      // 1. Completed achievements in descending order of completed date
      // 2. Default and uncompleted achievements in the order provided in the AchievementsListData sequence
      // 3. Custom achievements at last

      AchievementsListData.forEach((row) => {
        const currentDate: any = (data as any)[row.name] || null;

        if (currentDate) {
          completed.push({ ...row, completedAt: currentDate });
        } else {
          uncompleted.push({ ...row, completedAt: null });
        }
      });

      data?.customAchievements.map((row) => {
        if (row.completedAt) {
          completed.push({ ...row, completedAt: row.completedAt });
        } else {
          uncompleted.push({ ...row, completedAt: null });
        }
      });

      completed.sort(
        (a, b) =>
          new Date(b.completedAt || 0).getTime() -
          new Date(a.completedAt || 0).getTime()
      );

      uncompleted.forEach((a) => completed.push(a));

      setAchievements(completed);
    }
  }, [achievementsData]);

  return (
    <Box>
      <Stack
        direction="row"
        justifyContent="space-between"
        sx={{ paddingBottom: "20px" }}
      >
        <SLTypography variant="subhead" tabIndex={0} role="heading">
          Achievements
        </SLTypography>
        {achievementsData && (
          <SLTypography
            color="text-deepPurple"
            tabIndex={0}
            variant="smallBodyCopy"
          >
            {achievementsData.completedCount}/{achievementsData.totalCount}{" "}
            Complete
          </SLTypography>
        )}
      </Stack>
      <Box
        className="parent-list"
        sx={{ borderRadius: "10px", overflow: "hidden" }}
      >
        <Stack className="Achievement-list">
          {achievements && (
            <List
              role="list"
              className="listItem"
              sx={{
                width: "100%",
                bgcolor: "background.paper",
                borderRadius: "10px",
              }}
            >
              {achievements.map((row, i: number) => (
                <Achievement key={row.id || row.title} data={row} />
              ))}
            </List>
          )}
        </Stack>
      </Box>
      <SLButton
        messageId={"achievement.createAnAchievement"}
        onClick={handleClickOpen}
        onKeyPress={handleClickOpen}
        sx={{
          width: { xs: "100%", sm: "250px", lg: "250px" },
          marginTop: "20px",
        }}
      />

      <CreateAchievementPopUp open={open} setOpen={setOpen} reload={reload} />
    </Box>
  );
}
