import { useState } from "react";

export interface IShowDialog {
  message?: string;
  title?: string;
}

export interface IDialog {
  message?: string;
  title?: string;
  show?: boolean;
}

export interface IDialogResponse {
  message: string | undefined;
  setMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  title: string | undefined;
  setTitle: React.Dispatch<React.SetStateAction<string | undefined>>;
  open: boolean;
  setOpen: React.Dispatch<boolean>;
  show: (showprops?: IShowDialog) => void;
  hide: () => void;
}

export const useDialog = (props?: IDialog): IDialogResponse => {
  const [message, setMessage] = useState<string | undefined>(
    props?.message || undefined
  );
  const [title, setTitle] = useState<string | undefined>(
    props?.title || undefined
  );
  const [open, setOpen] = useState<boolean>(props?.show || false);

  const show = (showprops?: IShowDialog) => {
    setOpen(true);
    if (showprops?.message !== undefined) {
      setMessage(showprops.message);
    }
    if (showprops?.title !== undefined) {
      setTitle(showprops.title);
    }
  };

  const hide = () => setOpen(false);

  return {
    message,
    setMessage,
    title,
    setTitle,
    open,
    setOpen,
    show,
    hide,
  };
};
