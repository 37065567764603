import { Box, Dialog, DialogContent, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import React from "react";
import { SLButton } from "../../slButton";

interface ConfirmationProps {
  confirmAction: any;
  openConfirmationDialogue: boolean;
  setOpenConfirmationDialogue: any;
}

export default function ConfirmationDialog({
  confirmAction,
  openConfirmationDialogue,
  setOpenConfirmationDialogue,
}: ConfirmationProps) {
  const handleClose = () => {
    setOpenConfirmationDialogue(false);
  };

  return (
    <React.Fragment>
      <Dialog
        open={openConfirmationDialogue}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box className="logout-dialog-box">
          <Stack direction="column" textAlign="left" spacing={1}>
            <DialogContent
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                padding: 0,
                color: "var(--sl-deep-purple)",
              }}
            >
              <SLTypography
                tabIndex={0}
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  maxWidth: "320px",
                }}
              >
                Are you sure you want to delete this journal?
              </SLTypography>
            </DialogContent>
          </Stack>
          <Box
            sx={{
              marginTop: "3%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SLButton
              sx={{ width: "110%" }}
              variant="contained"
              messageId={"general.confirm"}
              onClick={() => confirmAction()}
              onKeyPress={() => confirmAction()}
            />
            <SLTypography
              tabIndex={0}
              onKeyPress={() => handleClose()}
              onClick={() => handleClose()}
              sx={{
                color: "var(--sl-purple)",
                textDecoration: "underline",
                paddingLeft: "10%",
                marginTop: "3%",
                cursor: "pointer",
              }}
              messageId={"general.cancel"}
            />
          </Box>
        </Box>
      </Dialog>
    </React.Fragment>
  );
}
