import { Box } from "@mui/material";
import PageTitle from "components/pageTitle/pageTitle";
import SubscriptionDetails from "components/subscriptionActivateScreen";
import React from "react";

const SubscriptionActivate: React.FC = () => {
  return (
    <>
      <Box>
        <PageTitle title="Subscription Details" />
        <SubscriptionDetails />
      </Box>
    </>
  );
};

export default SubscriptionActivate;
