import {
  onDisconnect,
  onValue,
  push,
  serverTimestamp,
  set,
} from "@firebase/database";
import { ThemeProvider, createTheme, responsiveFontSizes } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers-pro/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Gleap from "gleap";
import ReactDOM from "react-dom/client";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store/configureStore";
import { activeUsers, onConnectingDatabase } from "utils/firebase";
import App from "./App";
import { MiniplayerContextProvider } from "./contexts/miniplayerContext";
import "./index.css";
import English from "./lang/en.json";
import reportWebVitals from "./reportWebVitals";

let theme = createTheme({
  typography: {
    fontFamily: "Figtree",
  } as any,
  palette: {
    primary: {
      main: "#4B2B5A",
    },
    secondary: {
      main: "#046A8F",
    },
  },
});

theme = responsiveFontSizes(theme);
const environment = process.env.REACT_APP_NODE_ENV ?? "dev";
if (environment !== "live") {
  Gleap.initialize("QeO6nWGQ6U3v2AmQaqfg4DixxjAx2wTt");
}

onValue(onConnectingDatabase, (snap) => {
  if (snap.val() === true) {
    const connected = push(activeUsers);
    onDisconnect(connected).remove();
    set(connected, serverTimestamp());
  }
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <>
    <BrowserRouter>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={theme}>
            <IntlProvider locale="en" messages={English}>
              <MiniplayerContextProvider>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <App />
                </LocalizationProvider>
              </MiniplayerContextProvider>
            </IntlProvider>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </BrowserRouter>
  </>
);

reportWebVitals();
