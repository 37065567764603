export const onEnterOrSpace = (
  callback: (e: React.KeyboardEvent<any>) => void
) => {
  return (e: React.KeyboardEvent<any>) => {
    if (e?.key === " ") {
      try {
        e?.preventDefault();
      } catch (error) {}
    }

    if (e?.key === "Enter" || e?.key === " ") {
      callback(e);
    }
  };
};

export const onEnter = (callback: (e: React.KeyboardEvent<any>) => void) => {
  return (e: React.KeyboardEvent<any>) => {
    if (e?.key === "Enter") {
      callback(e);
    }
  };
};
