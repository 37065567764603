/* eslint-disable indent */
import {
  Avatar,
  Box,
  Button,
  Drawer,
  Grid,
  Link,
  List,
  ListItem,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  ArrowForwardIosIcon,
  DefaultUserImage,
  ProfileMenuImage,
  SoulLinkColredLogo,
} from "helpers/enum/constants";
import { useDistinctNavigate } from "helpers/useDistinctNavigate";
import { FormattedMessage } from "react-intl";
import { Auth } from "utils/authService";
import { SLStorage } from "utils/slStorage";
import LougoutButton from "../logoutButton";
import HeaderNavigationLinks from "../navigationLinks";

interface ISidebar {
  toggle: boolean;
  toggleDrawer: (bool: boolean) => void;
  classes: any;
  data: any;
  profileImage: string;
  profileLinks: any[];
}
export const Sidebar = ({
  toggle,
  toggleDrawer,
  classes,
  profileImage,
  data,
  profileLinks,
}: ISidebar) => {
  const navigate = useDistinctNavigate();

  const guestLogin = SLStorage.getItem("guestLogin");
  const redirectLink = (link: string) => {
    navigate(link);
    toggleDrawer(false);
  };
  const navigateToHome = () => {
    navigate("/app/home");
  };
  return (
    <>
      {toggle && (
        <Drawer
          anchor="right"
          open={toggle}
          variant="temporary"
          onBackdropClick={(e) => toggleDrawer(false)}
          onClose={() => toggleDrawer(false)}
          ModalProps={{ onBackdropClick: (e) => toggleDrawer(false) }}
          classes={{ paper: classes.paper }}
        >
          <div
            className="sidebar-container"
            style={{
              background: "var(--bg-gradient)",
            }}
          >
            <Box role="presentation">
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "space-between", sm: "flex-end" },
                  margin: "2rem",
                  marginTop: { lg: "55px", sm: "16px", xs: "2rem" },
                }}
                onClick={() => toggleDrawer(false)}
                onKeyPress={() => toggleDrawer(false)}
              >
                <Box
                  component="img"
                  sx={{
                    display: { sm: "none", xs: "flex" },
                    width: "150px",
                    cursor: "pointer",
                  }}
                  src={SoulLinkColredLogo}
                  alt="/"
                  onClick={navigateToHome}
                />

                <Avatar
                  alt="user profile image"
                  src={profileImage ? profileImage : DefaultUserImage}
                  sx={{ cursor: "pointer" }}
                />
              </Box>
              <Box
                sx={{
                  display: { sm: "none", xs: "grid" },
                  margin: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: "1rem",
                }}
              >
                <HeaderNavigationLinks
                  fromDrawer={true}
                  closeDrawer={() => toggleDrawer(false)}
                />
              </Box>

              <List
                sx={{ margin: 4, padding: 0 }}
                onClick={() => toggleDrawer(false)}
                onKeyPress={() => toggleDrawer(false)}
              >
                {profileLinks.map((data: any) =>
                  Object.keys(data).length !== 0 ? (
                    <ListItem
                      key={data.name}
                      onClick={() => redirectLink(data.redirectionLink)}
                      onKeyPress={() => redirectLink(data.redirectionLink)}
                      tabIndex={0}
                      title={data.name}
                      sx={{
                        marginBottom: "10px",
                        borderRadius: "10px",
                        background: "#FFFFFF",
                        cursor: "pointer",
                        color: "var(--sl-deep-purple)",
                        height: "50px",
                        textDecoration: "none",
                      }}
                    >
                      <Grid container gap={1} sx={{ fontSize: { xs: "14px" } }}>
                        {data.iconButton}

                        <SLTypography
                          variant="title"
                          sx={{ fontSize: { xs: "14px" } }}
                        >
                          {data.name}
                        </SLTypography>
                      </Grid>
                      <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
                    </ListItem>
                  ) : null
                )}
              </List>

              <Box sx={{ margin: 4, marginBottom: 0 }}>
                {guestLogin ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <SLTypography
                      sx={{
                        textAlign: { md: "left", xs: "center" },
                        color: "var(--sl-deep-purple)",
                        fontSize: { md: "14px", xs: "12px" },
                        fontWeight: "500",
                      }}
                    >
                      Not Logged in
                    </SLTypography>
                    <p className="mt-0">
                      <Button
                        className="buildMeditationBtn mt-6"
                        onClick={() => {
                          Auth.guest.logout();
                          navigate("/login");
                        }}
                        onKeyPress={() => {
                          navigate("/login");
                        }}
                      >
                        <SLTypography
                          variant="title"
                          sx={{ fontWeight: "500", letterSpacing: "0.5px" }}
                        >
                          Log in
                        </SLTypography>
                      </Button>
                    </p>
                    <SLTypography
                      variant="title"
                      sx={{
                        fontWeight: "500",
                        textAlign: { md: "left", xs: "center" },
                        letterSpacing: "-0.3px",
                        paddingTop: "5px",
                        fontSize: { md: "14px", xs: "12px" },
                        color: "var(--sl-deep-purple)",
                        display: "block",
                      }}
                    >
                      New to Soul Link?{" "}
                      <Link
                        href="/signup"
                        role="link"
                        aria-label=" New to Soul Link sign up"
                        className="sign-up"
                        sx={{
                          color: "var(--sl-deep-purple) !important",
                          textDecorationColor: "var(--sl-deep-purple)",
                          letterSpacing: "-0.3px",
                        }}
                      >
                        <FormattedMessage id="onboarding.signup" />
                      </Link>
                    </SLTypography>
                  </Box>
                ) : null}

                {data && !guestLogin && <LougoutButton getUserProfile={data} />}
              </Box>

              <Box
                sx={{
                  display: { sm: "flex", xs: "none" },
                  justifyContent: "flex-end",
                  padding: "50px 0",
                }}
              >
                <Box
                  component="img"
                  src={ProfileMenuImage}
                  alt="/"
                  sx={{ height: "250px", width: "180px" }}
                />
              </Box>
            </Box>
          </div>
        </Drawer>
      )}
    </>
  );
};
