import { Box, Skeleton } from "@mui/material";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import { SLTypography } from "components/slTypography";
import { lang } from "helpers/common/lang";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLButton } from "../../slButton";
import "./index.css";

const MySubscriptionScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();

  const {
    data: userSubscription,
    loading: subscriptionLoading,
    error: subscriptionError,
  } = useSelector(SLSelector.getSubscription);

  const cancelSubscriptionPopup = useDialog({
    title: lang("subscription.cancelPopup.title"),
    message: "",
  });

  useEffect(() => {
    if (subscriptionError) {
      navigate("/app/profile");
    } else if (!subscriptionLoading && !userSubscription) {
      navigate("/app/profile");
    }
  }, [userSubscription, subscriptionError, subscriptionLoading]);

  const confirmCancelSubscription = async () => {
    cancelSubscriptionPopup.hide();
    const makeCallObj: any = {
      method: "patch",
      url: "/subscription/cancel/{platform}",
      urlParams: { platform: "web" },
      payload: {},
      params: "",
    };
    const response: string = await makeSLAPICall(makeCallObj);

    if (response) {
      if (userSubscription?.planId) {
        mixpanel.triggerSubscriptionCancelledEvent(userSubscription.planId);
      }

      dispatch(SLThunk.getSubscription.call());
    }
  };

  const manageSubscription = () => {
    if (userSubscription?.platform === "android") {
      window.open(
        "https://play.google.com/store/account/subscriptions",
        "_blank"
      );
    } else if (userSubscription?.platform === "ios") {
      window.open("https://apps.apple.com/account/subscriptions", "_blank");
    }
  };
  return (
    <>
      <Box
        className="subscriptionBox"
        sx={{ padding: { xs: "20px", sm: "30px" } }}
      >
        <SLTypography
          tabIndex={0}
          role="heading"
          variant="headLine"
          sx={{ fontSize: { xs: "19px", sm: "27px" } }}
        >
          {!userSubscription?.type || subscriptionLoading ? (
            <Skeleton
              sx={{
                transform: "unset",
                display: "inline-block",
                width: "20rem",
              }}
            />
          ) : userSubscription?.type == "monthly" ? (
            "Monthly Subscription"
          ) : (
            "Annual Subscription"
          )}
        </SLTypography>

        <h3 className="heading3" tabIndex={0}>
          {!userSubscription?.type || subscriptionLoading ? (
            <Skeleton
              sx={{
                transform: "unset",
                display: "inline-block",
                width: "5rem",
              }}
            />
          ) : userSubscription?.type == "monthly" ? (
            <>
              ${userSubscription?.price || lang("app.pricing.monthly")}
              <span className="smallText">/month</span>
            </>
          ) : (
            <>
              ${userSubscription?.price || lang("app.pricing.yearly")}
              <span className="smallText">/year</span>
            </>
          )}
        </h3>

        <p className="subscriptionLabel" tabIndex={0}>
          Status:
        </p>

        {subscriptionLoading || !userSubscription?.status ? (
          <Skeleton
            sx={{
              transform: "unset",
              display: "inline-block",
              width: "5rem",
            }}
          />
        ) : userSubscription?.status === "cancelled" ? (
          <p className={"subscriptionValue color-red"} tabIndex={0}>
            {userSubscription?.status}
          </p>
        ) : (
          <p className={"subscriptionValue"}>
            {subscriptionLoading ? (
              <Skeleton
                sx={{
                  transform: "unset",
                  display: "inline-block",
                  width: "5rem",
                }}
              />
            ) : (
              userSubscription?.status
            )}
          </p>
        )}

        <p className="subscriptionLabel" tabIndex={0}>
          Renewal Date:
        </p>
        <p className="subscriptionValue deepPurple" tabIndex={0}>
          {!userSubscription?.endDate || subscriptionLoading ? (
            <Skeleton
              sx={{
                transform: "unset",
                display: "inline-block",
                width: "5rem",
              }}
            />
          ) : (
            new Date("" + userSubscription?.endDate).toLocaleDateString("en-US")
          )}
        </p>
      </Box>

      <Box sx={{ marginTop: { xs: "22px" } }}>
        {subscriptionLoading ? null : userSubscription?.platform !== "web" ? (
          <SLButton
            sx={{ padding: "0px 40px" }}
            messageId={"subscription.details"}
            onClick={() => manageSubscription()}
            onKeyPress={() => manageSubscription()}
            tabIndex={0}
          />
        ) : userSubscription?.status !== "cancelled" ? (
          <SLButton
            sx={{ padding: "0px 40px" }}
            messageId={"subscription.cancel"}
            onClick={cancelSubscriptionPopup.show as any}
          />
        ) : null}
      </Box>

      <SLDialog
        dialog={cancelSubscriptionPopup}
        dismissable={true}
        onDismiss={cancelSubscriptionPopup.hide}
        actionButtons={{
          onYesButtonClicked: confirmCancelSubscription,
          yesMessageId: "general.confirm",
          onNoButtonClicked: cancelSubscriptionPopup.hide,
          noMessageId: "general.cancel",
        }}
      />
    </>
  );
};

export default MySubscriptionScreen;
