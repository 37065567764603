import {
  Avatar,
  Box,
  Button,
  Dialog,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers-pro";
import axios from "axios";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import { SLTypography } from "components/slTypography";
import dayjs, { Dayjs } from "dayjs";
import { Formik } from "formik";
import { lang } from "helpers/common/lang";
import { DefaultUserImage } from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import region from "pages/additionalInfo/statesAndCites/index.json";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getServerTime } from "utils/getServerTime";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import * as Yup from "yup";
import { DialogActionButtons } from "../../../dialogBoxes/DialogActionButtons";
import ErrorDialogBox from "../../../dialogBoxes/errorDialog";
import { ErrorMsg } from "../../../errorMsg/ErrorMsg";
import { SLLoader } from "../../../loader";
import { SLVerificationMessage } from "../../../verificationMessage";
import "./index.css";
import SelectOption from "./select";

let serverTime: Date;

const validationSchema = Yup.object().shape({
  fullName: Yup.string()
    .trim()
    .min(2, "The name is too short")
    .max(30, "The name is too long")
    .required("Fill the obligatory field, please"),
  email: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z\._\d]{1,}@[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}$/,
      "Invalid email address"
    )
    .email("Invalid email address")
    .required("Fill the obligatory field, please"),
  phone: Yup.string()
    .nullable()
    .trim()
    .matches(/^.*\d{3}.*\d{3}.*\d{4}$/, "Invalid phone number"),
  dateOfBirth: Yup.string()
    .nullable()
    .optional()
    .trim()
    .matches(
      /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/,
      "Invalid date format (MM/DD/YYYY)"
    )
    .test("test-future-date", "Future dates not allowed.", (value) => {
      if (!value) {
        // Handle empty string
        return true; // Allow empty string
      }
      return (
        dayjs(value).isValid() &&
        !dayjs(value).isAfter(serverTime || new Date())
      );
    }),
  //   dayjs(value).isValid()
  //     ? !dayjs(value).isAfter(serverTime || new Date())
  //     : true
  // ),
});

interface EditAccountInfoProps {
  openDialog: boolean;
  setOpenDialog: (value: boolean) => void;
}

const EditAccountInfo = ({
  openDialog,
  setOpenDialog,
}: EditAccountInfoProps) => {
  const dispatch = useDispatch();
  const appUrls = useAppUrls();

  const { data: profileData } = useSelector(SLSelector.getUserProfile);
  const {
    data: profileUpdate,
    loading,
    error,
  } = useSelector(SLSelector.patchUserUpdateProfile);

  const discardChangesDialog = useDialog({
    title: lang("profile.updateAccount.dialog"),
    message: lang("profile.updateAccount.dialogDetail"),
  });
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [savedsucessfully, setSavedSuccessfully] = useState(false);
  const [validationError, setValidationError] = useState("");

  const [checkOnChange, setCheckOnChange] = useState(false);
  const [city, setCity] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<string | null>("");
  const [gender, setGender] = useState("");
  const [profileImageUpload, setProfileImageUpload] = useState<any>(null);
  const [imageUploading, setImageUploading] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [profileImagePreview, setProfileImagePreview] = useState("");
  const [stateCities, setStateCities] = useState<any[]>([]);
  const [stateName, setStateName] = useState("");

  useEffect(() => {
    if (profileUpdate) {
      dispatch(SLThunk.patchUserUpdateProfile.reset());
      dispatch(SLThunk.getUserProfile.call());
      setOpenDialog(false);
      setSavedSuccessfully(true);
      setTimeout(() => {
        setSavedSuccessfully(false);
      }, 2000);
    }
    if (error) {
      setErrorDialog(true);
      setValidationError(error);
    }
  }, [profileUpdate, error]);

  // useEffect(() => {
  //   resetForm();
  // }, [profileData]);

  useEffect(() => {
    if (openDialog) {
      resetForm();
      if (!serverTime) {
        getServerTime().then((time: Date) => {
          serverTime = time;
        });
      }
    }
  }, [openDialog]);

  const resetForm = () => {
    dispatch(SLThunk.patchUserUpdateProfile.reset());
    setCheckOnChange(false);
    discardChangesDialog.hide();
    setErrorMessage("");
    setImageUploading(false);
    setProfileImagePreview("");
    setProfileImageUpload(null);

    if (profileData) {
      if (profileData?.image && profileData?.image !== "true") {
        const userImage = appUrls.profilePic(profileData?.image);
        setProfileImagePreview(userImage);
      }
      setDateOfBirth(
        profileData?.dob
          ? dayjs(profileData.dob.slice(0, 10)).format("MM/DD/YYYY")
          : null
      );
      setGender(profileData?.gender || "");
      setPhoneNumber(profileData?.phone ? profileData?.phone : "");
      setStateName(profileData?.state ? profileData?.state : "");
      setCity(profileData?.city ? profileData?.city : "");
    }
  };

  const formattedPhoneNumber = (e: any) => {
    let x = e.target.value.trim();

    if (x) {
      x = e.target.value
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);

      x = !x[2] ? x[1] : "(" + x[1] + ") " + x[2] + (x[3] ? "-" + x[3] : "");
    }

    setPhoneNumber(x);
    setCheckOnChange(x != profileData?.phone ? true : false);

    return x;
  };

  const handleImageChange = (e: any) => {
    setErrorMessage("");
    setCheckOnChange(true);
    const [file] = e.target.files;
    const fileName = file?.name || "";
    if (!fileName.toLowerCase().match(/\.(jpg|jpeg|png)$/)) {
      setErrorMessage("only *.jpg, *.jpeg, *.png files are allowed");
    } else if (file.size > 1000141) {
      setErrorMessage("Maximum image size is 10 MB");
    } else {
      setProfileImagePreview(URL.createObjectURL(file));
      setProfileImageUpload(file);
    }
  };

  const handleSaveImage = (values: any) => {
    if (profileImageUpload) {
      setImageUploading(true);
      const image = new FormData();
      image.append("image", profileImageUpload, "profile_picture");
      axios
        .post(
          `${process.env.REACT_APP_BACKEND_API}/file-upload/userimage`,
          image
        )
        .then(function (response) {
          setImageUploading(false);
          updateUserInfo(values, response);
        })
        .catch(function (error) {
          setImageUploading(false);
        });
    } else {
      updateUserInfo(values, "");
    }
  };

  const updateUserInfo = (values: any, response: any) => {
    const data = {
      email: values.email,
      fullName: values.fullName,
      gender: gender || null,
      dob: dateOfBirth || null,
      state: stateName || null,
      city: city || null,
      phone: phoneNumber || null,
      image:
        profileImageUpload && response?.data?.result?.fileName
          ? response?.data?.result?.fileName
          : profileImagePreview && profileData?.image
          ? profileData?.image
          : null,
    };

    if (profileData?.registerType !== "email") {
      delete data.email;
    }

    dispatch(SLThunk.patchUserUpdateProfile.call(data));
    setCheckOnChange(false);
  };

  const handleRemoveImage = () => {
    if (profileImagePreview) {
      setCheckOnChange(true);
      setErrorMessage("");
      setProfileImagePreview("");
      setProfileImageUpload(null);
    }
  };

  const handleCloseDialog = () => {
    discardChangesDialog.hide();
    setOpenDialog(false);
  };

  const initialValues = {
    fullName: profileData?.fullName ? profileData?.fullName : "",
    email: profileData?.email ? profileData?.email : "",
    phone: profileData?.phone ? profileData?.phone : "",
    dateOfBirth: dateOfBirth,
  };

  const formSubmit = async (values: any) => {
    if (checkOnChange) {
      if (profileImageUpload) {
        handleSaveImage(values);
      } else {
        updateUserInfo(values, "");
      }
    } else {
      handleCloseDialog();
    }
  };

  useEffect(() => {
    setStateCities(() => {
      if (stateName) {
        const state = region.find((row) => row.state == stateName);
        if (state) {
          return state.cities;
        }
        return [];
      }
      return [];
    });
  }, [stateName]);

  return (
    <>
      <Dialog
        className="profile-details-popup"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "650px",
              height: "auto",
            },
          },
          borderRadius: "10px",
        }}
        open={openDialog}
      >
        <Box
          className="infoDialog-box"
          sx={{ height: "inherit", borderRadius: "10px" }}
        >
          <Grid container>
            <Grid item xs={12} md={5} sx={{ textAlign: "center" }}>
              <SLTypography
                role="heading"
                tabIndex={0}
                sx={{
                  fontSize: {
                    xs: "19px",
                    md: "17px",
                  },
                }}
                textAlign="left"
                display="block"
                fontWeight="600"
                variant="body"
                color="text-deepPurple"
                messageId="profile.editAccountInfo"
              />
              <Stack
                sx={{
                  marginTop: "6%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Avatar
                  alt="Your profile picture"
                  src={
                    profileImagePreview ? profileImagePreview : DefaultUserImage
                  }
                  sx={{
                    width: { xs: "120px", sm: "200px" },
                    height: { xs: "120px", sm: "200px" },
                    borderRadius: "50%",
                    marginBottom: "20px",
                  }}
                  tabIndex={0}
                />
                <Button
                  disableRipple
                  variant="text"
                  component="label"
                  className="upload-btn"
                  onClick={(e: any) => {
                    e.preventDefault();
                    (
                      document.getElementById(
                        "upload_photo_input"
                      ) as HTMLInputElement
                    ).click();
                  }}
                  sx={{
                    textDecoration: "none",
                    fontFamily: "Figtree",
                    fontSize: {
                      xs: "12px !important",
                      md: "14px !important",
                    },
                    color: "var(--sl-purple)!important",
                  }}
                >
                  {profileImagePreview !== ""
                    ? "Upload a New Photo"
                    : "Upload a Photo"}
                </Button>
                <input
                  hidden
                  id="upload_photo_input"
                  accept="image/png, image/jpeg, image/jpg"
                  onChange={(e) => handleImageChange(e)}
                  onKeyDown={(e) => handleImageChange(e)}
                  type="file"
                  tabIndex={0}
                />
                <Typography
                  className={
                    profileImagePreview ? "remove-photo" : "photo-size"
                  }
                  onClick={() => handleRemoveImage()}
                  onKeyPress={() => handleRemoveImage()}
                  tabIndex={0}
                  aria-level={2}
                >
                  {profileImagePreview ? (
                    <SLTypography
                      variant="smallBodyCopy"
                      sx={{
                        fontSize: {
                          xs: "12px !important",
                          md: "14px !important",
                        },
                      }}
                    >
                      Remove
                    </SLTypography>
                  ) : (
                    "Max size 10 MB"
                  )}{" "}
                </Typography>
                {errorMessage ? (
                  <SLTypography
                    tabIndex={0}
                    fontSize="12px"
                    marginTop="20px"
                    style={{
                      textAlign: "center",
                      color: "var(--sl-purple)",
                    }}
                  >
                    {errorMessage}
                  </SLTypography>
                ) : null}
              </Stack>
            </Grid>
            <Grid item xs={12} md={7}>
              <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={formSubmit}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  setFieldValue,
                }) => (
                  <Box
                    component="form"
                    autoComplete="off"
                    onSubmit={handleSubmit}
                    sx={{ marginTop: { xs: "15px", md: "30px" } }}
                  >
                    <TextField
                      id="fullName"
                      className="sl-input sl-input2"
                      label="Full Name"
                      onBlur={handleBlur("fullName")}
                      onChange={(e) => {
                        setCheckOnChange(
                          e.target.value !== profileData?.fullName
                        );
                        handleChange(e);
                        delete errors.fullName;
                      }}
                      value={values.fullName.trim()}
                    />
                    <ErrorMsg
                      show={Boolean(touched.fullName && errors.fullName)}
                      text={errors.fullName || ""}
                    />

                    <TextField
                      id="email"
                      className="sl-input sl-input2"
                      label="Email"
                      onBlur={handleBlur("email")}
                      onChange={(e) => {
                        setCheckOnChange(e.target.value !== profileData?.email);
                        handleChange(e);
                        delete errors.email;
                      }}
                      value={values.email.trim()}
                    />
                    <ErrorMsg
                      show={Boolean(touched.email && errors.email)}
                      text={errors.email || ""}
                    />

                    <DatePicker
                      disableFuture
                      className="sl-input sl-input2"
                      label="Date Of Birth (MM/DD/YYYY)"
                      value={dateOfBirth}
                      onChange={(date: Dayjs | null) => {
                        let value;
                        if (!date || !date?.isValid()) {
                          value = "";
                        } else {
                          value = date.format("MM/DD/YYYY");
                        }
                        setCheckOnChange(true);
                        setDateOfBirth(value);
                        setFieldValue("dateOfBirth", value);
                        delete errors.dateOfBirth;
                      }}
                      renderInput={(params) => (
                        <TextField
                          className="w-full font-figtree mt-32"
                          {...params}
                          error={false}
                        />
                      )}
                    />
                    <ErrorMsg
                      show={Boolean(errors.dateOfBirth)}
                      text={errors.dateOfBirth || ""}
                    />

                    <TextField
                      id="phone"
                      className="sl-input sl-input2"
                      label="Phone Number"
                      onBlur={handleBlur("phone")}
                      onChange={(e) => {
                        const value = formattedPhoneNumber(e);
                        setFieldValue("phone", value);

                        delete errors.phone;
                      }}
                      value={phoneNumber}
                    />
                    <ErrorMsg
                      show={Boolean(touched.phone && errors.phone)}
                      text={errors.phone || ""}
                    />

                    <SelectOption
                      selectId="gender-select"
                      placeholder="Gender"
                      value={profileData?.gender || ""}
                      setValue={setGender}
                      valueChanged={setCheckOnChange}
                      options={[
                        { value: "Female", label: "Female" },
                        { value: "Male", label: "Male" },
                        { value: "Non-binary", label: "Non-binary" },
                        {
                          value: "Prefer not to say",
                          label: "Prefer not to say",
                        },
                      ]}
                    />
                    {/* put here */}

                    <SelectOption
                      selectId="state-select"
                      placeholder="State"
                      value={profileData?.state || ""}
                      setValue={(e) => {
                        setStateName(e);
                        setCity("");
                      }}
                      valueChanged={setCheckOnChange}
                      options={region.map((state) => ({
                        value: state.state,
                        label: state.state,
                      }))}
                    />
                    {/* {stateName ? ( */}

                    <SelectOption
                      selectId="city-select"
                      placeholder="City"
                      value={profileData?.city || ""}
                      setValue={(e) => {
                        setCity(e);
                      }}
                      valueChanged={setCheckOnChange}
                      options={stateCities.map((city) => ({
                        value: city,
                        label: city,
                      }))}
                    />

                    <DialogActionButtons
                      marginTop="1rem"
                      onYesButtonClicked={() => {
                        handleSubmit();
                      }}
                      yesMessageId="general.saveChanges"
                      onNoButtonClicked={() => {
                        checkOnChange
                          ? discardChangesDialog.show()
                          : setOpenDialog(false);
                      }}
                      noMessageId="general.cancel"
                    />
                  </Box>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Box>
        <SLLoader open={loading || imageUploading} />
      </Dialog>

      <SLDialog
        dialog={discardChangesDialog}
        onDismiss={discardChangesDialog.hide}
        actionButtons={{
          yesMessageId: "general.confirm",
          noMessageId: "general.cancel",
          onYesButtonClicked: handleCloseDialog,
          onNoButtonClicked: discardChangesDialog.hide,
        }}
      />

      <ErrorDialogBox
        errorDialog={errorDialog}
        setErrorDialog={setErrorDialog}
        errorMessage={validationError}
        errorHeaderMessage="Oops!"
      />
      <SLVerificationMessage
        open={savedsucessfully}
        message={"Changes Saved"}
      />
    </>
  );
};

export default EditAccountInfo;
