let serverTime: Date;

export const getServerTime = async () => {
  const data = await fetch("https://worldtimeapi.org/api/ip").then((response) =>
    response.json()
  );

  serverTime = new Date(data.datetime);

  return serverTime;
};
