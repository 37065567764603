import { useRef } from "react";

const allowedMovementDistance = 20;

const calculateDistance = (point1: any, point2: any) => {
  const deltaX = point2.x - point1.x;
  const deltaY = point2.y - point1.y;
  const distance = Math.sqrt(deltaX ** 2 + deltaY ** 2);
  return distance;
};

export const useDragger = () => {
  const initialMousePosition = useRef({ x: null, y: null });
  const draggingTimeout = useRef<any>();
  const isDragging = useRef(false);

  const preventClickWhenDragging = (e: any) => {
    if (e?.buttons !== 0) {
      if (
        initialMousePosition.current.x === null &&
        initialMousePosition.current.y === null
      ) {
        initialMousePosition.current = { x: e.clientX, y: e.clientY };
      } else {
        const distanceMoved = calculateDistance(initialMousePosition.current, {
          x: e.clientX,
          y: e.clientY,
        });

        if (distanceMoved >= allowedMovementDistance) {
          isDragging.current = true;
        }
      }
    }
  };

  const detectStopDragging = () => {
    if (draggingTimeout.current) {
      clearTimeout(draggingTimeout.current);
    }
    draggingTimeout.current = setTimeout(() => {
      isDragging.current = false;
      initialMousePosition.current = { x: null, y: null };
    });
  };

  return {
    preventClickWhenDragging,
    detectStopDragging,
    isDragging,
  };
};
