import { Avatar, Box, Grid, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { DefaultUserImage } from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLArtist } from "utils/slRTKHelper/slAPI.models";
import MediaSection from "../../homeComponent/mediaSection";
import { SLLoader } from "../../loader";
import { NotFoundMessage } from "../../slTypography/NotFoundMessage";
import { SLVerificationMessage } from "../../verificationMessage";
import "./index.css";

const ViewAuthorDetail = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const appUrls = useAppUrls();

  // Manage State
  const [audioSliderRef, setAudioSliderRef] = useState<Slider | null>(null);
  const [artistData, setArtistData] = useState<SLArtist>();
  const [mediaAudio, setMediaAudio] = useState();
  const [mediaScene, setMediaScene] = useState();
  const [mediaVideo, setMediaVideo] = useState();
  const [addedToFavouriteList, setAddedToFavouriteList] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");

  // Manage Selector

  const { data, loading, error } = useSelector(SLSelector.getArtistId);

  useEffect(() => {
    dispatch(SLThunk.getArtistId.call({ id: id ?? "" }));
  }, []);

  useEffect(() => {
    if (data) {
      setArtistData(data);
      if (data.groupedMedia) {
        if (data?.groupedMedia.audio) {
          setMediaAudio(data?.groupedMedia.audio);
        } else setMediaAudio(undefined);
        if (data?.groupedMedia.frequencyWave) {
          setMediaScene(data?.groupedMedia.frequencyWave);
        } else setMediaScene(undefined);
        if (data?.groupedMedia.video) {
          setMediaVideo(data?.groupedMedia.video);
        } else setMediaVideo(undefined);
      }
    }
    setTimeout(() => {
      dispatch(SLThunk.getArtistId.reset());
    }, 2000);
  }, [data]);

  return (
    <>
      <Grid
        sx={{
          marginTop: "30px",
          minHeight: "71vh",
        }}
      >
        <Grid>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              "@media screen and (max-width: 950px)": {
                flexDirection: "column",
                gap: "10px",
                alignItems: "center",
              },
            }}
          >
            <Stack
              direction="row"
              alignItems="center"
              height="fit-content"
              spacing={4}
              sx={{
                "@media screen and (max-width: 415px)": {
                  flexDirection: "column",
                  width: "auto",
                },
              }}
            >
              <Avatar
                tabIndex={0}
                alt={artistData?.fullName}
                src={appUrls.profilePic(artistData?.image) || DefaultUserImage}
                sx={{ width: "200px", height: "200px" }}
              />
              <SLTypography
                variant="heading"
                width="340px"
                sx={{
                  fontSize: "37px",
                  lineHeight: "42px",
                  color: "var(--sl-deep-purple)",
                  "@media screen and (max-width: 950px)": {
                    width: "100%",
                    marginLeft: "0px !important",
                    textAlign: "center",
                  },
                }}
                tabIndex={0}
              >
                {artistData?.fullName}
              </SLTypography>
            </Stack>
            <Stack
              sx={{
                width: "613px",
                height: "auto",
                background: "white",
                borderRadius: "10px",
                padding: "30px 0 30px 0",
                "@media screen and (max-width: 950px)": {
                  width: "100%",
                },
              }}
            >
              {artistData?.specialization ? (
                <Stack
                  sx={{
                    marginBottom: "20px",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                  }}
                >
                  <SLTypography
                    role="heading"
                    variant="extraSmall"
                    sx={{ color: "var(--sl-deep-purple)" }}
                    tabIndex={0}
                  >
                    Specialization
                  </SLTypography>
                  <SLTypography
                    variant="bodyCopy"
                    sx={{ color: "var(--sl-deep-purple)" }}
                    tabIndex={0}
                  >
                    {artistData?.specialization}
                  </SLTypography>
                </Stack>
              ) : null}
              {artistData?.about ? (
                <Stack
                  sx={{
                    borderTop: "1px solid #E7E7E7",
                    paddingLeft: "30px",
                    paddingRight: "30px",
                  }}
                >
                  <SLTypography
                    role="heading"
                    sx={{ marginTop: "20px", color: "var(--sl-deep-purple)" }}
                    variant="extraSmall"
                    tabIndex={0}
                  >
                    About Artist
                  </SLTypography>
                  <SLTypography
                    sx={{ color: "var(--sl-deep-purple)" }}
                    variant="bodyCopy"
                    tabIndex={0}
                  >
                    {artistData?.about}
                  </SLTypography>
                </Stack>
              ) : null}
            </Stack>
          </Box>
        </Grid>
        <Grid sx={{ borderTop: "1px solid #CBC8CD", marginTop: "50px" }}>
          {mediaAudio || mediaVideo || mediaScene ? (
            <>
              {mediaAudio ? (
                <Stack>
                  <MediaSection
                    addedToFavourites={setAddedToFavouriteList}
                    successMessageText={setSuccessMessage}
                    name="Audio"
                    data={mediaAudio}
                    sliderRef={audioSliderRef}
                    setSliderRef={setAudioSliderRef}
                  />
                </Stack>
              ) : null}
              {mediaVideo ? (
                <Stack>
                  <MediaSection
                    addedToFavourites={setAddedToFavouriteList}
                    successMessageText={setSuccessMessage}
                    name="Video"
                    data={mediaVideo}
                    sliderRef={audioSliderRef}
                    setSliderRef={setAudioSliderRef}
                  />
                </Stack>
              ) : null}
              {mediaScene ? (
                <Stack>
                  <MediaSection
                    addedToFavourites={setAddedToFavouriteList}
                    successMessageText={setSuccessMessage}
                    name="Frequency"
                    data={mediaScene}
                    sliderRef={audioSliderRef}
                    setSliderRef={setAudioSliderRef}
                  />
                </Stack>
              ) : null}
            </>
          ) : (
            <NotFoundMessage notFoundMessage={`No Media`} />
          )}
        </Grid>
      </Grid>
      <SLLoader open={loading} />
      <SLVerificationMessage
        open={addedToFavouriteList}
        sx={{ zIndex: 9999 }}
        message={successMessage}
      />
    </>
  );
};
export default ViewAuthorDetail;
