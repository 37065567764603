/* eslint-disable indent */
import {
  Avatar,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogContent,
  Divider,
  Stack,
  TextField,
} from "@mui/material";
import { SLMeditationLoader } from "components/createMeditationAccordian/createMeditationLoader";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import { SLTypography } from "components/slTypography";
import dayjs from "dayjs";
import { deepLink } from "helpers/common/deeplLink";
import { lang } from "helpers/common/lang";
import { MediaCardType, MediaType } from "helpers/enum";
import {
  CrossIconTutorial,
  DefaultUserImage,
  PlayCircleIcon,
  ShareIcon,
  SpeakerNotesOutlined,
} from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import React, { useEffect, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { useSetState } from "react-use";
import { webShareOpen } from "store/webShareSlice";
import { webLink } from "utils/firebase";
import { getMediaSharingText } from "utils/getMediaSharingText";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLArtist, SLMediaFileDetail } from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";
import ErrorDialogBox from "../dialogBoxes/errorDialog";
import Media from "../explore/media";
import FavouriteMediaIcon from "../favouriteMedia";
import MeditationDetailsAccordian from "../meditationDetailsAccordian";
import NotesAddUpdateModal from "../notes/./addUpdateModal";
import PageTitle from "../pageTitle/pageTitle";
import { SLButton } from "../slButton";
import { SLVerificationMessage } from "../verificationMessage";
import "./index.css";

interface IFileDetail {
  mediaData: SLMediaFileDetail;
  id: number;
  likedMediaCard: (text: string) => void;
  typeMedia: string;
}
interface State {
  run: boolean;
  steps: Step[];
}
const FileDetailsComponent = ({
  mediaData,
  id,
  likedMediaCard,
  typeMedia,
}: IFileDetail) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const guestLogin = SLStorage.getItem("guestLogin");

  const { data: userSubscription } = useSelector(SLSelector.getSubscription);
  const { data, error } = useSelector(SLSelector.getFilters);
  const { data: postMeditationMergeId, error: meditationMergeError } =
    useSelector(SLSelector.postMeditationMergeId);

  const selectMediaType = {
    Audio: "audio",
    Video: "video",
    Frequency: "frequencyWave",
  };

  const [accordian, setAccordian] = useState({
    audio: false,
    video: false,
    frequency: false,
  });
  const [meditationData, setMeditationData] = useState<any>("");
  const [mediaType, setMediaType] = useState<string>("");
  const [openDialog, setOpenDialog] = React.useState(false);
  const [meditationTitle, setMeditationTitle] = useState("");
  const [openErrorDialog, setOpenErrorDialog] = React.useState(false);
  const [openMyJournalNotes, setOpenMyJournalNotes] = useState<boolean>(false);
  const [dynamicLinkUrlResponse, setDynamicLinkUrlResponse] =
    useState<string>("");
  const [errorTitle] = useState("Server Error, Please try again in a moment");
  const [tutorialStep, setTutorialStep] = useState(0);
  const [guestErrorMessage, setGuestErrorMessage] = useState<any>("");
  const [guestError, setGuestError] = useState(false);
  const [, setExploreAllButton] = useState({});
  const [createMeditationLoader, setCreateMeditationLoader] = useState(false);
  const [addedToFavouriteList, setAddedToFavouriteList] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [mediaTags, setMediaTags] = useState<any>({});

  const clearSelectionDialog = useDialog({
    title: lang("meditation.creator.clearSlection.title"),
    message: lang("meditation.creator.clearSlection.message"),
  });

  useEffect(() => {
    if (mediaData) {
      setMeditationData(mediaData);
    }
  }, [mediaData]);

  useEffect(() => {
    if (meditationMergeError || error) {
      setOpenErrorDialog(true);
      setCreateMeditationLoader(false);
    }
  }, [meditationMergeError, error]);

  useEffect(() => {
    if (data) {
      const result = data?.reduce(function (r: any, a: any) {
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
        return r;
      }, {});
      setTimeout(() => {
        setState({ run: true });
      }, 800);
      //setState({run:true});
      // console.log(result);
      setMediaTags(result);
    }
  }, [data]);

  useEffect(() => {
    if (postMeditationMergeId?.id) {
      setMeditationData(postMeditationMergeId);
      navigate(`/app/play-back/${postMeditationMergeId?.id}`);
    }
  }, [postMeditationMergeId]);

  useEffect(() => {
    if (meditationData?.type) {
      MediaCardType.MediaCard === typeMedia
        ? meditationData.type === "frequencyWave"
          ? setMediaType("Frequency Wave")
          : setMediaType(
              meditationData?.type + " • " + meditationData?.duration + ":00"
            )
        : setMediaType(
            "created meditation • " + meditationData?.duration + ":00"
          );
      setMeditationTitle(meditationData?.title);
      if (webLink && id && Object.keys(meditationData).length !== 0) {
        getDeepURL();
      }
    }
  }, [meditationData]);

  const getDeepURL = async () => {
    const getURL = await deepLink(
      MediaCardType.MediaCard === typeMedia
        ? `${webLink}media?mediaId=${id}&mediaType=${
            mediaData?.type
          }&mediaTitle=${mediaData?.title || ""}`
        : `${webLink}meditation?meditationId=${id}`
    );
    if (getURL !== undefined) {
      setDynamicLinkUrlResponse(getURL);
    }
  };

  useEffect(() => {
    selectMeditaionDetailsIds();
  }, []);

  const selectMeditaionDetailsIds = async () => {
    if (
      typeof SLStorage.getItem("selectedMeditationDataDetail") !== "undefined"
    ) {
      const getSelectedMeditation = SLStorage.getItem(
        "selectedMeditationDataDetail"
      );
      const keyType = Object.keys(getSelectedMeditation)[0];
      setTimeout(() => {
        if (keyType === "audio" && !accordian.audio) {
          setAccordian({ audio: true, video: false, frequency: false });
        } else if (keyType === "video" && !accordian.video) {
          setAccordian({ audio: false, video: true, frequency: false });
        } else if (keyType === "frequencywave" && !accordian.frequency) {
          setAccordian({ audio: false, video: false, frequency: true });
        }
      }, 100);
    }
  };

  useEffect(() => {
    if (guestLogin) {
      setGuestErrorMessage({
        errorHeading: "Oops! You Need to Log In",
        errorBody:
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed.",
      });
    } else {
      if (!userSubscription) {
        setGuestErrorMessage({
          errorHeading: "Oops! This is for Subscribers Only",
          errorBody:
            "Elevate your meditation practice with our premium features. Join Soul Link today to unlock personalized playlists, guided meditations, and more.",
        });
      }
    }
  }, [userSubscription]);

  const confirmChanges = async () => {
    setSuccessMessage("Changes Saved");

    const makeCallObj: any = {
      method: "patch",
      url: "/meditation/update/{id}",
      urlParams: { id: id },
      payload: { title: meditationTitle },
      params: "",
    };

    const response = await makeSLAPICall(makeCallObj);
    if (response) {
      setMeditationData(response);
      setOpenDialog(false);
      setAddedToFavouriteList(true);
      setTimeout(() => {
        setAddedToFavouriteList(false);
      }, 2000);
    }
  };

  const onUserType = (e: any) => {
    const inputValue = e.target.value.trim();
    if (inputValue === "") {
      setMeditationTitle("");
      return false;
    }
    setMeditationTitle(e.target.value);
  };

  const editMeditationName = () => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return false;
    }
    setOpenDialog(true);
  };

  useEffect(() => {
    dispatch(SLThunk.getFilters.call({}));
    sessionStorage.setItem("onDetailPage", "true");
  }, []);

  const playMedia = () => {
    if (meditationData?.locked) {
      setGuestError(true);
      return false;
    }
    if (MediaCardType.MediaCard === typeMedia) {
      sessionStorage.setItem("fromBuildMeditation", "false");
      SLStorage.setItem("previousURLStore", window.location.pathname);
      navigate(`/app/media-player/${id}`);
    } else {
      if (meditationData?.url != null) {
        navigate(`/app/play-back/${id}`);
      } else {
        setCreateMeditationLoader(true);
        dispatch(
          SLThunk.postMeditationMergeId.call({ id: meditationData?.id })
        );
      }
    }
  };

  const handleChange = (panel: any) => {
    //  console.log(isExpanded);
    if (panel == "audio" && !accordian.audio) {
      setAccordian({ audio: true, video: false, frequency: false });
    } else if (panel == "video" && !accordian.video) {
      setAccordian({ audio: false, video: true, frequency: false });
    } else if (panel == "frequencyWave" && !accordian.frequency) {
      setAccordian({ audio: false, video: false, frequency: true });
    } else if (panel == "audio" && accordian.audio) {
      setAccordian({
        audio: false,
        video: accordian.video,
        frequency: accordian.frequency,
      });
    } else if (panel == "video" && accordian.video) {
      setAccordian({
        audio: accordian.audio,
        video: false,
        frequency: accordian.frequency,
      });
    } else if (panel == "frequencyWave" && accordian.frequency) {
      setAccordian({
        audio: accordian.audio,
        video: accordian.video,
        frequency: false,
      });
    }
  };

  const getPreviousURLStore = () =>
    SLStorage.getItem("previousURLStore") ?? "/app/meditation/create";

  const getMeditationSelection = () => {
    const previousURLStore = getPreviousURLStore();

    let MeditationData =
      previousURLStore === "/app/meditation/create"
        ? SLStorage.getItem("selectedMeditationDataCreate")
        : previousURLStore === "/app/home"
        ? SLStorage.getItem("selectedMeditationDataCreate")
        : "";

    if (!MeditationData) {
      MeditationData = {};
    }

    return MeditationData;
  };

  const saveMeditationSelection = (MeditationData: any) => {
    const previousURLStore = getPreviousURLStore();

    previousURLStore.startsWith("/app/meditation/create") ||
    previousURLStore.includes("/app/view-author") ||
    previousURLStore.includes("/app/explore") ||
    previousURLStore.startsWith("/app/my-list") ||
    previousURLStore.startsWith("/app/home")
      ? SLStorage.setItem("selectedMeditationDataCreate", MeditationData)
      : SLStorage.setItem("selectedMeditationDataDetail", MeditationData);
  };

  const handleAddMeditationButton = () => {
    if (meditationData?.locked) {
      setGuestError(true);
      return false;
    }

    const selectionData = getMeditationSelection();

    if (meditationData.type !== MediaType.FrequencyWave) {
      if (
        selectionData.duration &&
        selectionData.duration != meditationData.duration
      ) {
        clearSelectionDialog.show();
        return;
      }

      if (meditationData?.duration) {
        selectionData.duration = meditationData.duration;
      }
    }

    selectionData[
      meditationData?.type.toLowerCase().replace("frequencywave", "frequency")
    ] = {
      id: meditationData?.id,
      title: meditationData?.title || "",
      image: meditationData?.image,
    };

    saveMeditationSelection(selectionData);

    const previousURLStore = getPreviousURLStore();

    if (
      previousURLStore.includes("/app/view-author") ||
      previousURLStore.includes("/app/explore") ||
      previousURLStore === "/app/my-list"
    ) {
      navigate(`/app/meditation/create`);
    } else {
      navigate(previousURLStore);
    }
  };

  const selectionDialogCancel = () => {
    clearSelectionDialog.hide();
  };

  const selectionDialogConfirm = () => {
    const selectionData = getMeditationSelection();

    delete selectionData.duration;
    delete selectionData.audio;
    delete selectionData.video;

    saveMeditationSelection(selectionData);

    clearSelectionDialog.hide();
    handleAddMeditationButton();
  };

  const cancelEditName = () => {
    setMeditationTitle(meditationData?.title || "");
    setOpenDialog(false);
  };

  const shareMedia = () => {
    setGuestError(true);
  };

  const openMyJournal = () => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return false;
    }
    setOpenMyJournalNotes(true);
  };

  const MediaDetailTutorial = SLStorage.getItem("ExploreMediaTutorial");
  const MeditationDetailTutorial = SLStorage.getItem(
    "MeditationDetailTutorial"
  );
  //if (MediaCardType.MediaCard === typeMedia) {
  const hideTolltip = () => {
    setOpenMyJournalNotes(false);
    setState({ run: false });
    if (!MediaDetailTutorial && MediaCardType.MediaCard === typeMedia) {
      //  alert(true);
      SLStorage.setItem("ExploreMediaTutorial", true);
    } else if (
      !MeditationDetailTutorial &&
      MediaCardType.MediaCard !== typeMedia
    ) {
      SLStorage.setItem("MeditationDetailTutorial", true);
    }
  };

  const [{ run, steps }, setState] = useSetState<State>({
    run: false,
    steps: [
      MediaCardType.MediaCard === typeMedia
        ? {
            content: (
              <>
                <object
                  tabIndex={0}
                  aria-label="You can add the file to your Meditation Creator from the file
                  detail page."
                >
                  <p className="bodyTextTutorial">
                    You can add the file to your Meditation Creator from the
                    file detail page.
                  </p>
                  <object
                    className="footerTextTutorial"
                    tabIndex={0}
                    aria-label="Walkthrough – Step 5 of 5"
                  >
                    Walkthrough – Step 5 of 5
                  </object>
                </object>
              </>
            ),
            locale: { skip: <img src={CrossIconTutorial} />, last: "Close" },
            floaterProps: {
              disableAnimation: true,
            },
            placement: "top",
            target: ".selectMediaBtn button",
            title: (
              <object tabIndex={0} aria-label="Add File to Meditation">
                <p className="tutorialHeading">Add File to Meditation</p>
              </object>
            ),
            disableBeacon: true,
          }
        : {
            content: (
              <>
                <object
                  aria-label="Each meditation has a details page where you can see the file
                  elements, edit the content, download it, and even share it!"
                  tabIndex={0}
                >
                  <p className="bodyTextTutorial">
                    Each meditation has a details page where you can see the
                    file elements, edit the content, download it, and even share
                    it!
                  </p>
                  <object
                    className="footerTextTutorial"
                    tabIndex={0}
                    aria-label="Walkthrough – Step 1 of 4"
                  >
                    Walkthrough – Step 1 of 4
                  </object>
                </object>
              </>
            ),
            locale: { skip: <img src={CrossIconTutorial} /> },
            floaterProps: {
              disableAnimation: true,
            },
            placement: "top",
            target: ".sl-headLine",
            title: (
              <object tabIndex={0} aria-label="Meditation Details">
                <p className="tutorialHeading">Meditation Details</p>
              </object>
            ),
            disableBeacon: true,
          },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="To swap out a file, you can tap the Change Audio, Change Video
              or Change Frequency Wave buttons."
            >
              <p className="bodyTextTutorial">
                To swap out a file, you can tap the "Change Audio," "Change
                Video" or "Change Frequency Wave" buttons.{" "}
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 2 of 4"
              >
                Walkthrough – Step 2 of 4
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".changeAudioBlock",
        title: (
          <object tabIndex={0} aria-label="Change Files">
            <p className="tutorialHeading">Change Files</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="You can also take notes on any meditation. Click the My Notes
              button to write down your thoughts or feelings about the experience."
            >
              <p className="bodyTextTutorial">
                You can also take notes on any meditation. Click the "My Notes"
                button to write down your thoughts or feelings about the
                experience.
              </p>
              <object
                tabIndex={0}
                aria-label="Walkthrough – Step 3 of 4"
                className="footerTextTutorial"
              >
                Walkthrough – Step 3 of 4
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".journalBtn",
        title: (
          <object tabIndex={0} aria-label="Meditation Notes">
            <p className="tutorialHeading">Meditation Notes</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Let it all out! Your notes are completely private, unless you
              choose to share it."
            >
              <p className="bodyTextTutorial">
                Let it all out! Your notes are completely private, unless you
                choose to share it.
              </p>
              <object
                tabIndex={0}
                aria-label="Walkthrough – Step 4 of 4"
                className="footerTextTutorial"
              >
                Walkthrough – Step 4 of 4
              </object>
              <Button
                type="button"
                aria-live="off"
                aria-label=""
                data-action="skip"
                role="button"
                title=""
                className="lastStepBtn"
                onClick={hideTolltip}
                onKeyPress={hideTolltip}
              >
                <img src={CrossIconTutorial} tabIndex={0} />
              </Button>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} />, last: "Close" },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".noteText",
        title: (
          <object tabIndex={0} aria-label="Meditation Notes">
            <p className="tutorialHeading">Meditation Notes</p>
          </object>
        ),
        disableBeacon: true,
      },
    ],
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const elementOverlaySpotLight = document.querySelector<HTMLElement>(
      ".react-joyride__spotlight"
    );
    const button = document.querySelector('button[data-action="skip"]');
    if (button) {
      button.setAttribute("title", "Skip tutorial");
      button.setAttribute("aria-label", "Skip tutorial");
    }
    if (index === 0 && MediaCardType.MediaCard === typeMedia) {
      const elementOverlay = document.querySelector<HTMLElement>(
        ".react-joyride__tooltip > div > button"
      );

      if (elementOverlay) {
        elementOverlay.textContent = "Close";
      }
      if (elementOverlaySpotLight) {
        elementOverlaySpotLight.style.borderRadius = "25px";
        elementOverlaySpotLight.style.marginTop = "1px";
      }
    }
    if (
      index === 0 &&
      MediaCardType.MediaCard === typeMedia &&
      type === "step:after"
    ) {
      setState({ run: false });
      if (!MediaDetailTutorial && MediaCardType.MediaCard === typeMedia) {
        SLStorage.setItem("ExploreMediaTutorial", true);
      }
    }
    if (
      index === 0 &&
      MediaCardType.MediaCard !== typeMedia &&
      type === "step:after"
    ) {
      if (elementOverlaySpotLight) {
        elementOverlaySpotLight.style.removeProperty("border-radius");
        elementOverlaySpotLight.style.removeProperty("margin-top");
      }
      setTutorialStep(1);
    }
    if (index === 1 && type === "step:after") {
      setTutorialStep(2);
    }
    if (index === 2 && type === "step:after") {
      setOpenMyJournalNotes(true);
      setTimeout(() => {
        setTutorialStep(3);
      }, 500);
    }
    if (index === 3 && type === "step:after") {
      setOpenMyJournalNotes(false);
      setState({ run: false });
      if (!MediaDetailTutorial && MediaCardType.MediaCard === typeMedia) {
        //  alert(true);
        SLStorage.setItem("ExploreMediaTutorial", true);
      } else if (
        !MeditationDetailTutorial &&
        MediaCardType.MediaCard !== typeMedia
      ) {
        SLStorage.setItem("MeditationDetailTutorial", true);
      }
    }
    if (finishedStatuses.includes(status)) {
      setOpenMyJournalNotes(false);
      setState({ run: false });
      if (!MediaDetailTutorial && MediaCardType.MediaCard === typeMedia) {
        SLStorage.setItem("ExploreMediaTutorial", true);
      } else if (
        !MeditationDetailTutorial &&
        MediaCardType.MediaCard !== typeMedia
      ) {
        SLStorage.setItem("MeditationDetailTutorial", true);
      }
    }
  };

  return (
    <>
      {openMyJournalNotes && (
        <NotesAddUpdateModal
          setOpenMyJournalNotes={() => setOpenMyJournalNotes(false)}
          openMyJournalNotes={openMyJournalNotes}
          mediaData={meditationData}
        />
      )}
      {(!guestLogin &&
        !MediaDetailTutorial &&
        MediaCardType.MediaCard === typeMedia) ||
      (!guestLogin &&
        !MeditationDetailTutorial &&
        MediaCardType.MediaCard !== typeMedia) ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton={true}
          run={run}
          scrollToFirstStep={false}
          showProgress={false}
          showSkipButton={true}
          disableOverlayClose={true}
          spotlightPadding={0}
          stepIndex={tutorialStep}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      ) : null}
      <PageTitle title={meditationData?.title} />

      <Stack pt={5} gap={5}>
        <Stack
          direction={{ xs: "column", md: "row" }}
          justifyContent="space-between"
          alignItems="flex-start"
          width="100%"
        >
          <Stack spacing="2px" maxWidth="600px">
            <SLTypography
              role="heading"
              tabIndex={0}
              variant="headLine"
              color="text-deepPurple"
            >
              {meditationData?.title || ""}
            </SLTypography>

            <SLTypography
              tabIndex={0}
              variant="smallBodyCopy"
              sx={{
                color: "var(--sl-deep-purple)",
                textTransform: "capitalize",
              }}
            >
              {mediaType}
            </SLTypography>

            {MediaCardType.MediaCard !== typeMedia &&
            meditationData?.type != "daily" ? (
              <SLTypography
                role="button"
                tabIndex={0}
                variant="smallBodyCopy"
                color="text-purple"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={editMeditationName}
                onKeyPress={editMeditationName}
              >
                Edit Name
              </SLTypography>
            ) : null}
          </Stack>

          <Stack
            gap="20px"
            width={{ xs: "100%", md: "unset" }}
            direction={{ xs: "column", sm: "row" }}
            justifyContent="center"
            flexWrap="wrap"
          >
            <Stack
              direction="row"
              gap="20px"
              alignItems="center"
              justifyContent={{ xs: "center", sm: "flex-start" }}
              className="selectMediaBtn"
            >
              <FavouriteMediaIcon
                cardData={meditationData}
                cardId={id}
                cardType={
                  MediaCardType.MediaCard === typeMedia ? "Media" : "Meditation"
                }
                likedMediaCard={likedMediaCard}
                emptyHeartClass="empty-purple-heart"
                fillHeartClass="fill-purple-heart"
                setGuestError={setGuestError}
              />
              {meditationData?.type != "frequencyWave" && (
                <PlayCircleIcon
                  role="button"
                  tabIndex={0}
                  titleAccess={`Play ${mediaData?.type} ${meditationData?.title}`}
                  sx={{
                    fill: "var(--sl-purple)",
                    width: "50px",
                    height: "50px",
                    cursor: "pointer",
                  }}
                  onClick={() => playMedia()}
                  onKeyPress={() => playMedia()}
                />
              )}
              {MediaCardType.MediaCard === typeMedia ? (
                <SLButton
                  sx={{ width: "240px" }}
                  slClass="aqua"
                  onClick={() => handleAddMeditationButton()}
                  messageId={"fileDetail.addMeditation.button"}
                />
              ) : null}
            </Stack>

            <Box
              display={{ xs: "none", sm: "block" }}
              borderLeft="1px solid #CBC8CD"
              height="100%"
            />

            <Stack direction="row">
              <ButtonGroup
                sx={{
                  width: "100%",
                  padding: "10px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  height: "47px",
                  justifyContent: "space-evenly",
                }}
              >
                {!mediaData?.locked ? (
                  <>
                    <Button
                      sx={{
                        borderColor: "transparent",
                        borderRadius: "10px",
                        width: "84px",
                      }}
                      value="center"
                      tabIndex={0}
                      aria-label={`Share ${mediaData.title || ""}`}
                      disabled={!dynamicLinkUrlResponse}
                      onClick={() => {
                        dispatch(
                          webShareOpen({
                            text: getMediaSharingText(
                              mediaData?.title,
                              mediaData?.type
                            ),
                            url: dynamicLinkUrlResponse,
                          })
                        );
                      }}
                    >
                      <Stack alignItems="center">
                        <ShareIcon sx={{ color: "var(--sl-deep-purple)" }} />
                        <SLTypography
                          variant="extraSmallBody"
                          sx={{
                            color: "var(--sl-deep-purple)",
                            textTransform: "capitalize",
                          }}
                        >
                          Share
                        </SLTypography>
                      </Stack>
                    </Button>
                  </>
                ) : (
                  <Button
                    onClick={() => shareMedia()}
                    sx={{
                      borderColor: "transparent",
                      borderRadius: "10px",
                      width: "84px",
                    }}
                    value="center"
                    aria-label={`Share ${mediaData.title || ""}`}
                  >
                    <Stack alignItems="center">
                      <ShareIcon sx={{ color: "var(--sl-deep-purple)" }} />
                      <SLTypography
                        variant="extraSmallBody"
                        sx={{
                          color: "var(--sl-deep-purple)",
                          textTransform: "capitalize",
                        }}
                      >
                        Share
                      </SLTypography>
                    </Stack>
                  </Button>
                )}

                {mediaData.type !== "daily" && (
                  <>
                    <div
                      style={{
                        background: "#CBC8CD",
                        width: "1px",
                        height: "100%",
                      }}
                    />
                    <Button
                      value="right"
                      sx={{
                        borderColor: "transparent",
                        borderRadius: "10px",
                        width: "84px",
                      }}
                      aria-pressed="true"
                      aria-label="My Journal"
                      tabIndex={0}
                      className="journalBtn"
                      onClick={() => openMyJournal()}
                      onKeyPress={() => openMyJournal()}
                    >
                      <Stack alignItems="center">
                        <SpeakerNotesOutlined
                          sx={{ color: "var(--sl-deep-purple)" }}
                        />
                        <SLTypography
                          variant="extraSmallBody"
                          sx={{
                            color: "var(--sl-deep-purple)",
                            textTransform: "capitalize",
                            width: "80px !important",
                          }}
                        >
                          My Journal
                        </SLTypography>
                      </Stack>
                    </Button>
                  </>
                )}
              </ButtonGroup>
            </Stack>
          </Stack>
        </Stack>

        <Divider />

        {MediaCardType.MediaCard === typeMedia ? (
          <Box>
            <SLTypography
              role="heading"
              tabIndex={0}
              variant="subhead"
              color="text-deepPurple"
              display="block"
              mb={5}
            >
              File Information
            </SLTypography>
            <Box
              sx={{
                backgroundColor: "var(--colorWhite)",
                borderRadius: "10px",
              }}
            >
              <Stack>
                {Array.isArray(meditationData.artist) &&
                meditationData.artist.length > 0 ? (
                  <Stack
                    sx={{
                      borderBottom: "1px solid #E7E7E7",
                      padding: "35px 30px 35px 30px",
                    }}
                  >
                    <SLTypography
                      role="heading"
                      tabIndex={0}
                      variant="extraSmallBody"
                      color="text-deepPurple"
                      sx={{
                        fontWeight: 600,
                        fontSize: { md: "12px" },
                        marginBottom: "5px",
                      }}
                    >
                      {meditationData.artist.length === 1
                        ? "Artist"
                        : "Artists"}
                    </SLTypography>
                    <span role="list">
                      {meditationData.artist.map((artist: SLArtist) => (
                        <span role="listitem">
                          <Link
                            key={artist.id}
                            to={`/app/view-author/${artist.id}`}
                            style={{ textDecoration: "none" }}
                            tabIndex={0}
                          >
                            <Stack
                              padding="1rem 0"
                              direction="row"
                              gap={{ xs: 2, md: 3 }}
                              alignItems="center"
                              sx={{
                                borderBottom: "1px solid var(--colorVeryGray)",
                                boxSizing: "border-box",
                              }}
                            >
                              <Avatar
                                aria-hidden={true}
                                sx={{
                                  height: { xs: "40px", md: "80px" },
                                  width: { xs: "40px", md: "80px" },
                                }}
                                src={
                                  appUrls.profilePicThumb(artist.image) ||
                                  DefaultUserImage
                                }
                              />

                              <Stack>
                                <SLTypography
                                  variant="subHeading"
                                  color="text-deepPurple"
                                  sx={{
                                    display: "block",
                                    fontSize: { xs: "17px" },
                                    lineHeight: 1.2,
                                    fontWeight: 700,
                                    cursor: "pointer",
                                    textDecoration: "none",
                                    "&:hover": {
                                      textDecoration: "underline",
                                    },
                                  }}
                                >
                                  {artist?.fullName}
                                </SLTypography>
                                <SLTypography
                                  variant="extraSmall"
                                  color="text-deepPurple"
                                  sx={{ fontSize: { md: "16px" } }}
                                  aria-label={
                                    ",Specialization: " + artist?.specialization
                                  }
                                  fontSize={"17px"}
                                >
                                  {artist?.specialization}
                                </SLTypography>
                              </Stack>
                            </Stack>
                          </Link>
                        </span>
                      ))}
                    </span>
                  </Stack>
                ) : null}
                {mediaData.location && (
                  <Stack
                    sx={{
                      borderBottom: "1px solid #E7E7E7",
                      padding: "35px 30px 35px 30px",
                    }}
                  >
                    <SLTypography
                      role="heading"
                      tabIndex={0}
                      variant="extraSmallBody"
                      color="text-deepPurple"
                      sx={{
                        fontWeight: 600,
                        fontSize: { md: "12px" },
                        marginBottom: "5px",
                      }}
                    >
                      Location
                    </SLTypography>
                    <SLTypography
                      tabIndex={0}
                      variant="bodyCopy"
                      color="text-deepPurple"
                      fontSize={"17px"}
                    >
                      {mediaData?.location}
                    </SLTypography>
                  </Stack>
                )}
                {mediaData.type !== "frequencyWave" && (
                  <Stack
                    sx={{
                      borderBottom: "1px solid #E7E7E7",
                      padding: "35px 30px 35px 30px",
                    }}
                  >
                    <SLTypography
                      role="heading"
                      tabIndex={0}
                      variant="extraSmallBody"
                      color="text-deepPurple"
                      sx={{
                        fontWeight: 600,
                        fontSize: { md: "12px" },
                        marginBottom: "5px",
                      }}
                    >
                      Date Added
                    </SLTypography>
                    <SLTypography
                      tabIndex={0}
                      variant="bodyCopy"
                      color="text-deepPurple"
                      fontSize={"17px"}
                    >
                      {mediaData?.filmDate
                        ? dayjs(mediaData?.filmDate).format("ll")
                        : "N/A"}
                    </SLTypography>
                  </Stack>
                )}
                {mediaData.description && (
                  <Stack sx={{ padding: "35px 30px 35px 30px" }}>
                    <SLTypography
                      role="heading"
                      tabIndex={0}
                      variant="extraSmallBody"
                      color="text-deepPurple"
                      sx={{
                        fontWeight: 600,
                        fontSize: { md: "12px" },
                        marginBottom: "5px",
                      }}
                    >
                      Description
                    </SLTypography>
                    <SLTypography
                      tabIndex={0}
                      variant="bodyCopy"
                      color="text-deepPurple"
                      fontSize={"17px"}
                    >
                      <div
                        dangerouslySetInnerHTML={{
                          __html: String(mediaData?.description || "").replace(
                            /\n/g,
                            "<br/>"
                          ),
                        }}
                      />
                    </SLTypography>
                  </Stack>
                )}
              </Stack>
            </Box>
          </Box>
        ) : (
          <>
            <Box>
              <SLTypography
                tabIndex={0}
                variant="subhead"
                aria-label="included files, heading"
                color="text-deepPurple"
                display="block"
                mb={5}
              >
                Included Files
              </SLTypography>

              {meditationData?.audioId ? (
                <MeditationDetailsAccordian
                  addedToFavourites={setAddedToFavouriteList}
                  successMessageText={setSuccessMessage}
                  duration={meditationData?.duration}
                  mediatags={mediaTags?.audio || []}
                  mediatype={selectMediaType.Audio}
                  mediatitle={"Audio"}
                  setAccordian={accordian.audio}
                  accordianhandleChange={handleChange}
                  selectedMeditationData={meditationData}
                  dynamicLinkUrlResponse={dynamicLinkUrlResponse}
                  setMeditationData={setMeditationData}
                />
              ) : null}
              {meditationData?.videoId ? (
                <MeditationDetailsAccordian
                  addedToFavourites={setAddedToFavouriteList}
                  successMessageText={setSuccessMessage}
                  duration={meditationData?.duration}
                  mediatags={mediaTags?.video || []}
                  mediatype={selectMediaType.Video}
                  mediatitle={"Video"}
                  setAccordian={accordian.video}
                  accordianhandleChange={handleChange}
                  selectedMeditationData={meditationData}
                  dynamicLinkUrlResponse={dynamicLinkUrlResponse}
                  setMeditationData={setMeditationData}
                />
              ) : null}
              {meditationData?.frequencyWaveId ? (
                <MeditationDetailsAccordian
                  addedToFavourites={setAddedToFavouriteList}
                  successMessageText={setSuccessMessage}
                  duration={meditationData?.duration}
                  mediatags={mediaTags?.frequencyWave || []}
                  mediatype={selectMediaType.Frequency}
                  mediatitle={"Frequency Wave"}
                  setAccordian={accordian.frequency}
                  accordianhandleChange={handleChange}
                  selectedMeditationData={meditationData}
                  dynamicLinkUrlResponse={dynamicLinkUrlResponse}
                  setMeditationData={setMeditationData}
                />
              ) : null}
            </Box>

            {!guestLogin && userSubscription ? (
              <>
                <Divider />

                <Box>
                  <SLTypography
                    variant="bodyCopy"
                    color="text-deepPurple"
                    sx={{ fontWeight: "600" }}
                    tabIndex={0}
                    title="Made For You"
                    aria-label="Made For You, heading"
                    display="block"
                  >
                    Suggested Meditations
                  </SLTypography>

                  <Box className="meditationDetails">
                    <Media
                      addedToFavourites={setAddedToFavouriteList}
                      successMessageText={setSuccessMessage}
                      mediaUrl={"/meditation/suggested"}
                      notFoundMessage="No Meditation Found"
                      setExploreAllButton={setExploreAllButton}
                      meditationDetailNavigate={true}
                      mediaType={MediaCardType.MeditationCard}
                      myMeditation={true}
                      isFavourite={false}
                    />
                  </Box>
                </Box>
              </>
            ) : null}
          </>
        )}
      </Stack>

      <Dialog
        open={openDialog}
        onClose={cancelEditName}
        sx={{ borderRadius: "10px" }}
        className="dailyMeditationGoalPopup"
      >
        <Stack
          direction="column"
          gap={2}
          sx={{
            padding: "25px 25px",
            background: "var(--bg-gradient)",
            width: "300px",
          }}
        >
          <SLTypography
            tabIndex={0}
            id="sl-dialog-title"
            variant="subhead"
            sx={{
              fontSize: { xs: "17px", sm: "19px" },
              lineHeight: { xs: "20px", sm: "23px" },
              fontWeight: 700,
            }}
          >
            Edit Meditation Name
          </SLTypography>
          <TextField
            label="Meditation Name"
            name="editMeditation"
            sx={{ marginTop: "0 !important" }}
            className="sl-input"
            onChange={onUserType}
            value={meditationTitle}
          />

          <SLButton
            sx={{
              width: "100%",
              pointerEvents: meditationTitle != "" ? "" : "none",
              opacity: meditationTitle != "" ? "" : "0.3",
            }}
            variant="contained"
            messageId={"general.saveChanges"}
            onClick={() => confirmChanges()}
            onKeyPress={() => confirmChanges()}
          />

          <SLTypography
            tabIndex={0}
            onKeyPress={cancelEditName}
            onClick={cancelEditName}
            sx={{
              color: "var(--sl-purple)",
              textDecoration: "underline",
              margin: "auto",
              cursor: "pointer",
              fontSize: "14px",
              display: "inline-block",
            }}
            messageId={"general.cancel"}
          />
        </Stack>
      </Dialog>

      <SLVerificationMessage
        open={addedToFavouriteList}
        sx={{ zIndex: 9999 }}
        message={successMessage}
      />
      <SLMeditationLoader
        open={createMeditationLoader}
        type="meditationDetails"
      />
      <Dialog
        open={openErrorDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={{ padding: "15px 25px" }}>
          <Stack direction="column" textAlign="left" spacing={1}>
            <DialogContent
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                padding: 0,
                color: "var(--sl-deep-purple)",
              }}
            >
              <SLTypography
                tabIndex={0}
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  maxWidth: "300px",
                  textAlign: "center",
                }}
              >
                {errorTitle}
              </SLTypography>
            </DialogContent>
          </Stack>
          <Box
            sx={{
              marginTop: "3%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SLButton
              sx={{ width: "200px" }}
              variant="contained"
              messageId={"general.ok"}
              onClick={() => setOpenErrorDialog(false)}
              onKeyPress={() => setOpenErrorDialog(false)}
            />
          </Box>
        </Box>
      </Dialog>

      <SLDialog
        dismissable={true}
        onDismiss={selectionDialogCancel}
        dialog={clearSelectionDialog}
        actionButtons={{
          yesMessageId: "general.confirm",
          onYesButtonClicked: selectionDialogConfirm,
          noMessageId: "general.cancel",
          onNoButtonClicked: selectionDialogCancel,
        }}
      />

      <ErrorDialogBox
        errorDialog={guestError}
        setErrorDialog={setGuestError}
        errorMessage={guestErrorMessage.errorBody}
        errorHeaderMessage={guestErrorMessage.errorHeading}
      />
    </>
  );
};

export default FileDetailsComponent;
