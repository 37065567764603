import { Box, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import {
  SLPaginationResponse,
  SupportRequest,
} from "utils/slRTKHelper/slAPI.models";
import { PageHeading } from "../../pageHeading";
import { NotFoundMessage } from "../../slTypography/NotFoundMessage";
import "./index.css";
import SupportCard from "./supportCard/supportCard";

const SupportRequestsHistoryScreen = () => {
  const dispatch = useDispatch();

  // States
  const [supportRequests, setSupportRequests] =
    useState<SLPaginationResponse<SupportRequest>>();
  const [lengthSupportRequests, setLengthSupportRequests] = useState<number>(0);
  const [hasMoreSupportRequests, setHasMoreSupportRequests] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);

  // Selectors
  const { data, loading, error } = useSelector(SLSelector.getReportMy);

  // Methods
  const getSupportRequests = (props?: {
    page?: number;
    categoryId?: "all" | number;
  }) => {
    const data: any = {
      page: currentPage,
      limit: 5,
      sort: "DESC",
      sortBy: "createdAt",
    };

    Object.assign(data, props);

    if (data?.categoryId && data?.categoryId == "all") {
      delete data.categoryId;
    }

    setCurrentPage(data.page);

    dispatch(SLThunk.getReportMy.call(data));
  };

  const loadMoreSupportRequests = () => {
    getSupportRequests({ page: currentPage + 1 });
  };

  // Manage UseEffects
  useEffect(() => {
    getSupportRequests();
  }, []);

  useEffect(() => {
    if (data?.data) {
      if (currentPage === 1) {
        setSupportRequests(data);
      } else {
        const appendedData = {
          ...data,
          data: [...supportRequests!.data, ...data.data],
        };
        setSupportRequests(appendedData);
      }
    }
  }, [data]);

  useEffect(() => {
    if (supportRequests?.data) {
      setLengthSupportRequests(supportRequests?.data.length);
      if (supportRequests.data.length < supportRequests.totalCount) {
        setHasMoreSupportRequests(true);
      } else {
        setHasMoreSupportRequests(false);
      }
    }
  }, [supportRequests]);

  return (
    <Box
      id="requests-page"
      sx={{
        minHeight: { xs: "auto", md: "60vh", lg: "68vh" },
        paddingTop: { lg: "0px", xs: "32px" },
      }}
    >
      <PageHeading messageId="support-requests.title" />

      {!error && supportRequests && supportRequests!.totalCount > 0 ? (
        <InfiniteScroll
          dataLength={lengthSupportRequests}
          next={loadMoreSupportRequests}
          hasMore={hasMoreSupportRequests}
          loader={<></>}
        >
          <Stack direction="column" gap="20px">
            {supportRequests?.data.map((row: SupportRequest, i: number) => (
              <SupportCard data={row} key={i} loading={loading} />
            ))}
          </Stack>
        </InfiniteScroll>
      ) : (
        supportRequests !== undefined && (
          <NotFoundMessage notFoundMessage={`No Support Request History`} />
        )
      )}
    </Box>
  );
};

export default SupportRequestsHistoryScreen;
