import { useLocation, useNavigate } from "react-router-dom";

/** A React hook that navigates to a URL only if the URL is not the current URL.
 *
 * @param targetUrl The URL to navigate to.
 * @returns {Function} navigate A function that can be used to navigate to the URL.
 */
export const useDistinctNavigate = () => {
  const navigateReact = useNavigate();
  const currentLocation = useLocation();

  const navigate = (targetUrl: string) => {
    const currentUrl = currentLocation.pathname;

    if (currentUrl != targetUrl) {
      navigateReact(targetUrl);
    }
  };

  return navigate;
};
