import {
  Box,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Select,
  Skeleton,
  Stack,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { useState } from "react";
import "./index.css";

const MeditationStatistics = (props: any) => {
  const { setMeditationTime } = props;
  const [age, setAge] = useState("");

  const handleChange = (event: any) => {
    setAge(event.target.value);
    setMeditationTime(event.target.value);
  };
  return (
    <div>
      <Box
        className="meditation-statistics"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "var(--sl-deep-purple)",
          paddingBottom: {
            xs: "10px",
            sm: "14px",
            md: "20px",
          },
        }}
      >
        <SLTypography
          tabIndex={0}
          variant="subhead"
          sx={{
            fontSize: {
              xs: "14px",
              sm: "16px",
              md: "17px",
            },
          }}
          role="heading"
        >
          {props.meditationstatistic}
        </SLTypography>

        {props.allTime !== "" && (
          <Stack direction={"row"}>
            <>
              {/* <SLTypography variant="smallBodyCopy">
                {props.allTime}
              </SLTypography> */}

              <FormControl sx={{ border: "none" }}>
                <Select
                  value={age}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "all time button drop down" }}
                  sx={{ border: "none" }}
                  onKeyPress={handleChange}
                >
                  <MenuItem value="">
                    <SLTypography
                      title="all time"
                      sx={{
                        margin: "0px",
                        fontSize: {
                          xs: "12.5px",
                          sm: "14px",
                        },
                      }}
                    >
                      {props.allTime}
                    </SLTypography>
                  </MenuItem>
                  <MenuItem
                    value={"year"}
                    sx={{
                      fontFamily: "Figtree",
                    }}
                  >
                    Year
                  </MenuItem>
                  <MenuItem
                    value={"month"}
                    sx={{
                      fontFamily: "Figtree",
                    }}
                  >
                    Month
                  </MenuItem>
                </Select>
              </FormControl>
            </>
            {/* <Box component="img" src={props.downArrowPurpleIcon} alt="" /> */}
          </Stack>
        )}
      </Box>

      <Paper sx={{ boxShadow: "none", borderRadius: "10px" }}>
        <Grid container sx={{ color: "var(--sl-deep-purple)" }}>
          <Grid
            item
            lg={6}
            xs={6}
            sx={{ borderRight: "1px solid #E7E7E7", lineHeight: "100px" }}
          >
            <Stack
              sx={{
                paddingTop: {
                  xs: "20px",
                  md: "30px",
                },
                paddingLeft: {
                  xs: "20px",
                  md: "30px",
                },
                paddingBottom: {
                  xs: "20px",
                  md: "30px",
                },
              }}
            >
              <SLTypography
                tabIndex={0}
                variant="smallFooter"
                sx={{
                  fontSize: {
                    xs: "11px",
                    md: "12px",
                  },
                }}
              >
                {props.sessions}
              </SLTypography>
              <SLTypography
                tabIndex={0}
                role="heading"
                variant="largeHeader"
                sx={{
                  fontSize: {
                    xs: "27px",
                    sm: "30px",
                    md: "37px",
                  },
                }}
              >
                {props.loading ? (
                  <Skeleton
                    sx={{
                      transform: "unset",
                      display: "inline-block",
                      width: "5rem",
                    }}
                  />
                ) : (
                  props.sessionCount
                )}
              </SLTypography>
            </Stack>
          </Grid>

          <Grid
            item
            lg={6}
            xs={6}
            sx={{
              paddingTop: {
                xs: "20px",
                md: "30px",
              },
              paddingLeft: {
                xs: "20px",
                md: "30px",
              },
              paddingBottom: {
                xs: "20px",
                md: "30px",
              },
            }}
          >
            <Stack>
              <SLTypography
                tabIndex={0}
                variant="smallFooter"
                sx={{
                  fontSize: {
                    xs: "11px",
                    md: "12px",
                  },
                }}
              >
                {props.sessionTime}
              </SLTypography>
              <SLTypography
                tabIndex={0}
                role="heading"
                variant="largeHeader"
                sx={{
                  fontSize: {
                    xs: "27px",
                    sm: "30px",
                    md: "37px",
                  },
                }}
              >
                {props.loading ? (
                  <Skeleton
                    sx={{
                      transform: "unset",
                      display: "inline-block",
                      width: "5rem",
                    }}
                  />
                ) : (
                  props?.sessionTimeCount?.toFixed(0)
                )}
              </SLTypography>
            </Stack>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default MeditationStatistics;
