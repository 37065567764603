import { Stack, ToggleButton, ToggleButtonGroup } from "@mui/material";
import React from "react";
import "./index.css";

interface mediaTagProps {
  filteredTags: any;
  onTagChange: any;
}
const MeditationTags = ({ filteredTags, onTagChange }: mediaTagProps) => {
  const [alignment, setAlignment] = React.useState<string | null>("All");
  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    setAlignment(newAlignment);
    if (newAlignment === null) setAlignment("All");
    onTagChange("" + newAlignment);
  };
  return (
    <>
      <Stack spacing={1} marginTop="5px">
        <ToggleButtonGroup
          className="deeppurple-toggle"
          value={alignment}
          onChange={handleAlignment}
          exclusive
          sx={{
            gap: 1,
            display: "inline-block",
            paddingBottom: { lg: "", xs: "20px" },
          }}
        >
          {filteredTags?.map((tag: any, index: number) => (
            <ToggleButton
              key={"artist_catid" + index}
              value={tag.title}
              aria-label={tag.title}
              title={tag.title}
              className="toggleBtn"
              tabIndex={0}
            >
              {tag?.title}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>

      {/* <SLLoader open={loading}></SLLoader> */}
    </>
  );
};

export default MeditationTags;
