import AccessTimeIcon from "@mui/icons-material/AccessTime";
import {
  ClickAwayListener,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { ClockPicker } from "@mui/x-date-pickers/ClockPicker";
import { SLTypography } from "components/slTypography";
import dayjs, { Dayjs } from "dayjs";
import { lang } from "helpers/common/lang";
import { daysList } from "helpers/enum/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import SLDialog from "../../../dialogBoxes/slDialog";
import { ErrorMsg } from "../../../errorMsg/ErrorMsg";
import "./index.css";

let adding = false;
const ROUND_TO_MINUTES = 15;

export const AddReminderScreen = ({ dialog }: any) => {
  const dispatch = useDispatch();
  const [showClock, setShowClock] = useState(false);
  const { data: saveReminderData } = useSelector(SLSelector.postReminders);
  const [time, setTime] = useState<Dayjs | null>(null);
  const [clockTime, setClockTime] = useState<string>("");
  const [daysOfWeek, setDaysOfWeek] = useState(["Tuesday", "Wednesday"]);
  const [errors, setErrors] = useState<Record<string, any>>({
    time: false,
    daysOfWeek: false,
  });

  const handleSubmit = () => {
    if (!time && !errors.time) {
      setErrors((prv) => ({ ...prv, time: lang("general.error.EmptyFields") }));
      errors.time = lang("general.error.EmptyFields");
    }
    if (!daysOfWeek.length) {
      setErrors((prv) => ({
        ...prv,
        daysOfWeek: lang("profile.meditationReminders.addReminder.chooseDays"),
      }));
      errors.daysOfWeek = lang(
        "profile.meditationReminders.addReminder.chooseDays"
      );
    }

    if (Object.values(errors).some((v) => v)) {
      console.error(errors);
      return;
    }

    const data = {
      daysOfWeek: sortArray2BasedOnArray1(daysList, daysOfWeek).map((r) =>
        r.toLowerCase()
      ),

      // Local time to UTC conversion
      time: (time as Dayjs)
        .subtract((time as Dayjs).utcOffset(), "minutes")
        .format("HH:mm"),

      platform: "web",
    };

    if (adding) {
      return;
    }

    adding = true;

    dispatch(SLThunk.postReminders.call(data));
  };
  useEffect(() => {
    if (time) {
    }
  }, [time]);
  useEffect(() => {
    dispatch(SLThunk.getReminders.call());
    closeDialog();
  }, [saveReminderData]);

  const closeDialog = () => {
    dialog.hide();
    dispatch(SLThunk.postReminders.reset());
    setTime(null);
    setDaysOfWeek([]);
    setErrors({ time: false, daysOfWeek: false });
    adding = false;
    setShowClock(false);
    setClockTime("");
  };

  function sortArray2BasedOnArray1(array1: string[], array2: string[]) {
    return array2.slice().sort((a, b) => {
      return array1.indexOf(a) - array1.indexOf(b);
    });
  }

  const validateBlurTime = (e: any) => {
    let value = String(e.target.value || "")
      .toUpperCase()
      .trim()
      .replace(/^\D+/, "")
      .replace(/^00/, "12")
      .replace(/[^0-9:APM]/g, "") as any;

    value = value.trim();

    if (!value) {
      setTime(null);
      return true;
    }

    let hours, minutes, ampmstring;

    ampmstring = value.replace(/[^AP]+/g, "");
    if (ampmstring === "P") {
      ampmstring = "PM";
    } else {
      ampmstring = "AM";
    }

    if (value.includes(":")) {
      const split = value.split(":");

      hours = split[0];
      minutes = split[1].replace(/\D+/g, "");
    } else {
      value = value.replace(/\D+/g, "");

      if (Number(value) >= 100 && Number(value) <= 239) {
        value = value.replace(/^0/, "");
        hours = Number(value.slice(0, 1));
        minutes = value.slice(1).slice(0, 2).replace(/\D+/g, "");
      } else if (Number(value.slice(0, 2)) <= 12) {
        hours = Number(value.slice(0, 2));
        minutes = value.slice(2).slice(0, 2).replace(/\D+/g, "");
      } else if (Number(value) >= 24 && Number(value) <= 99) {
        hours = Number(value.slice(0, 1));
        minutes = Number(value.slice(1, 2)) + "0";
      } else if (
        Number(value.slice(0, 2)) >= 12 &&
        Number(value.slice(0, 2)) <= 23
      ) {
        hours = Number(value.slice(0, 2)) - 12;
        minutes = value.slice(2).slice(0, 2).replace(/\D+/g, "");
        ampmstring = "PM";
      } else if (
        Number(value.slice(0, 2)) >= 24 &&
        Number(value.slice(0, 2)) <= 95
      ) {
        hours = Number(value.slice(0, 1));
        minutes = value.slice(1).slice(0, 2).replace(/\D+/g, "");
      }
    }

    if (!hours) {
      setClockTime("");
      setTime(null);
      return true;
    }

    if (minutes) {
      minutes =
        Math.round(Math.min(minutes, 45) / ROUND_TO_MINUTES) * ROUND_TO_MINUTES;
    }

    value = `${String(Math.min(hours, 12)).padStart(2, "0")}:${String(
      minutes
    ).padStart(2, "0")} ${ampmstring.trim()}`;

    setClockTime(value);

    const completeDateTimeString =
      new Date().toISOString().split("T")[0] + " " + value;

    if (dayjs(completeDateTimeString).isValid()) {
      setTime(dayjs(completeDateTimeString));
      setErrors((prv) => ({ ...prv, time: false }));
    } else {
      setTime(null);
      setErrors((prv) => ({ ...prv, time: "Invalid time" }));
      errors.time = "Invalid time";
    }
  };
  return (
    <div
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setShowClock(false);
        }
      }}
    >
      <SLDialog
        dismissable={true}
        onDismiss={closeDialog}
        dialog={dialog}
        width={{ xs: "300px", sm: "460px" }}
        actionButtons={{
          yesMessageId: "general.save",
          yesAriaLabel:
            "Save, " +
            (Object.values(errors).filter((e) => e).length > 0
              ? `${Object.keys(errors).find((e) => e)}: ${Object.values(
                  errors
                ).find((e) => e)}`
              : ""),
          yesAutofocus: false,

          noMessageId: "general.cancel",
          onYesButtonClicked: handleSubmit,
          onNoButtonClicked: closeDialog,
        }}
      >
        <Stack gap={3}>
          <SLTypography
            tabIndex={0}
            variant="bodyCopy"
            color="text-deepPurple"
            messageId="profile.meditationReminders.addReminder.description"
          />

          <div>
            <ToggleButtonGroup
              sx={{
                gap: 1,
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              value={daysOfWeek}
              onChange={(e, val) => {
                setDaysOfWeek(val);
                setErrors((prv) => ({ ...prv, daysOfWeek: false }));
              }}
            >
              {daysList.map((day) => (
                <ToggleButton
                  sx={{
                    padding: 0,
                    fontSize: { xs: "10px", sm: "12px" },
                    textTransform: "unset",
                    boxSizing: "border-box",
                    margin: "0!important",
                    border: "1px solid var(--colorLightGray) !important",
                    borderRadius: "50% !important",
                    aspectRatio: "1",
                    width: { xs: "40px", sm: "50px" },
                    color: "var(--sl-deep-purple)",
                    "&.Mui-selected,&.Mui-selected:hover": {
                      backgroundColor: "var(--sl-aqua-contrast)",
                    },
                    "&.Mui-selected:hover": { filter: "saturate(0.5)" },
                  }}
                  value={day}
                  aria-label={day}
                >
                  {day.slice(0, 3)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>

            <ErrorMsg
              text={errors.daysOfWeek}
              show={errors.daysOfWeek}
              color="text-errorLightMode"
            />
          </div>

          <div className="mb-5 p-relative">
            <TextField
              value={clockTime}
              title="Time"
              label="Time"
              type="text"
              className="reminder-input sl-input"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowClock((prv) => !prv)}
                      edge="end"
                    >
                      <AccessTimeIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              onKeyDown={(e) => {
                if (e?.key === "Enter") {
                  validateBlurTime(e);
                }
              }}
              onBlur={(e) => {
                validateBlurTime(e);
              }}
              onChange={(e: any) => {
                setErrors((prv) => ({ ...prv, time: false }));
                setClockTime(e.target.value);
              }}
              inputProps={{
                placeholder: "Enter Time",
                maxLength: "10",
              }}
              sx={{
                ".MuiInputAdornment-positionEnd": {
                  top: "-3px",
                  position: "relative",
                },
              }}
            />
            <ErrorMsg
              text={errors.time}
              show={errors.time}
              color="text-errorLightMode"
            />
          </div>
        </Stack>
        {showClock ? (
          <ClickAwayListener onClickAway={() => setShowClock(false)}>
            <ClockPicker
              date={time}
              minutesStep={15}
              ampm={true}
              ampmInClock={true}
              className="customClock"
              onChange={(e: Dayjs | null) => {
                let timeString = e;
                if (typeof e !== "string" && e !== null) {
                  const roundedMinute =
                    Math.round(e.minute() / ROUND_TO_MINUTES) *
                    ROUND_TO_MINUTES;
                  timeString = e.minute(roundedMinute);
                  const hours = e?.hour();
                  const minutes = e?.minute();
                  const period = hours < 12 ? "AM" : "PM";

                  // Convert 24-hour format to 12-hour format
                  const formattedHours = (hours % 12 || 12)
                    .toString()
                    .padStart(2, "0");
                  const formattedTime = `${formattedHours}:${minutes
                    .toString()
                    .padStart(2, "0")} ${period}`;
                  const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (am|pm)$/i;
                  if (timeRegex.test(formattedTime)) {
                    setClockTime(formattedTime);
                  }
                }

                setTime(timeString);
                setErrors((prv) => ({ ...prv, time: false }));
              }}
            />
          </ClickAwayListener>
        ) : null}
      </SLDialog>
    </div>
  );
};
