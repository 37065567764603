import { Box, Container, Grid } from "@mui/material";
import Page from "components/pageTitle";
import { lang } from "helpers/common/lang";
import {
  AccountCircleOutlined,
  CardMembershipOutlined,
  DailyInpirationMenuSvg,
  ManageAccountsOutlined,
  ReminderIcon,
  SpeakerNotesOutlined,
} from "helpers/enum/constants";
import LayoutOutlet from "../layoutSidebar";

interface ProfileProps {
  title: string;
  children: JSX.Element;
}

const profileLinks = [
  {
    linkIcons: <AccountCircleOutlined />,
    name: "My Profile",
    redirectionLink: "/app/profile",
  },
  {
    linkIcons: <ManageAccountsOutlined />,
    name: "Account Settings",
    redirectionLink: "/app/browse-account",
  },
  {
    linkIcons: <Box component="img" src={ReminderIcon} />,
    name: lang("profile.meditationReminders"),
    redirectionLink: "/app/meditation-reminders",
  },
  {
    linkIcons: <CardMembershipOutlined />,
    name: "My Subscription",
    redirectionLink: "/app/my-subscription",
  },
  {
    linkIcons: <Box component="img" src={DailyInpirationMenuSvg} />,
    name: "Daily Inspiration",
    redirectionLink: "/app/daily-inspiration",
  },
  {
    linkIcons: <SpeakerNotesOutlined />,
    name: "My Journal",
    redirectionLink: "/app/my-notes",
  },
];

const ProfilePages = ({ title, children }: ProfileProps) => (
  <>
    <Page title={title} className="">
      <Container
        className="page-container"
        sx={{ width: { xl: "93.2%", lg: "95%" }, minHeight: "60vh" }}
        maxWidth={false}
      >
        <Grid
          container
          sx={{
            display: "flex",
            flexDirection: { xs: "column-reverse", md: "row" },
          }}
        >
          <Grid item xs={12} md={3.3} lg={3.6} xl={3.7}>
            <Box
              sx={{
                paddingRight: {
                  xl: "100px",
                  lg: "60px",
                  md: "24px",
                  xs: "0px",
                },
                position: "sticky",
                top: "100px",
                paddingTop: { xs: 3, md: "0px" },
              }}
            >
              <LayoutOutlet redirectionData={profileLinks} />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            md={8.7}
            lg={8.4}
            xl={8.3}
            sx={{ paddingTop: { xs: 0, sm: 0, lg: 0, md: "20px" } }}
          >
            {children}
          </Grid>
        </Grid>
      </Container>
    </Page>
  </>
);

export default ProfilePages;
