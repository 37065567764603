import { createSlice } from "@reduxjs/toolkit";

interface IVolumeFadeInitialState {
  subtitleToggle: boolean;
  playerVolume: number;
}

const initialState: IVolumeFadeInitialState = {
  playerVolume: 0.5,
  subtitleToggle: false,
};
const videoControlSlice = createSlice({
  name: "videoControls",
  initialState,
  reducers: {
    subtitleToggle: (state, action) => {
      return { ...state, subtitleToggle: action.payload };
    },
    playerVolume: (state, action) => {
      // Use action.payload to update the playerVolume
      return { ...state, playerVolume: action.payload };
    },
  },
});

export default videoControlSlice.reducer;
export const videoControlsState = (state: any) =>
  state.videoControls as IVolumeFadeInitialState;
export const videoControlsActions = videoControlSlice.actions;
