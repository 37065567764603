export function titleCase(str: string | number | null | undefined): string {
  // check if input is a string and convert to string if necessary
  str = typeof str === "string" ? str.trim() : String(str ?? "");

  // split string by space or underscore and capitalize each word
  const words = str
    .split(/\s+|_+/g)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase());

  // join words and return
  return words.join(" ");
}

export function snakeCase(
  str: string | number | null | undefined,
  joiner = "_"
): string {
  // Check if input is a string and convert to string if necessary
  str = typeof str === "string" ? str.trim() : String(str ?? "");

  // Convert camel case to snake case
  str = str.replace(
    /[a-z][A-Z]/g,
    (match) => `${match[0]}_${match[1].toLowerCase()}`
  );

  // Convert any remaining pascal case, constant case, title case, and sentence case to snake case
  str = str.replace(/[-\s]+/g, joiner).toLowerCase();

  return str;
}

export function kebabCase(
  str: string | number | null | undefined,
  joiner = "-"
): string {
  return snakeCase(str, joiner);
}
