import { Box, Slider, styled } from "@mui/material";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import { SLTypography } from "components/slTypography";
import { lang } from "helpers/common/lang";
import { useEffect, useState } from "react";
import "./index.css";

const marks = [{ value: 5 }, { value: 13.5 }, { value: 22 }];

const IOSSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.mode === "dark" ? "#3880ff" : "#3880ff",
  height: 5,
  padding: "15px 0",
  "& .MuiSlider-thumb": {
    height: 28,
    width: 28,
    backgroundColor: "#fff",
  },
  "& .MuiSlider-valueLabel": {
    fontSize: 12,
    fontWeight: "normal",
    top: -6,
    backgroundColor: "unset",
    color: theme.palette.text.primary,
    "&:before": {
      display: "none",
    },
    "& *": {
      background: "transparent",
      color: theme.palette.mode === "dark" ? "#fff" : "#000",
    },
  },
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
  "& .MuiSlider-mark": {
    backgroundColor: "#bfbfbf",
    height: 8,
    width: 1,
    "&.MuiSlider-markActive": {
      opacity: 1,
      backgroundColor: "currentColor",
    },
  },
}));

export const CustomizedSlider = (props: any) => {
  const clearSelectionDialog = useDialog({
    title: lang("meditation.creator.clearSlection.title"),
    message: lang("meditation.creator.clearSlection.message"),
  });
  const { mediaDurationSelect, audiosetstate, videosetstate, durationvalue } =
    props;
  const [value, setValue] = useState<number>(22);
  const [oldvalue, setOldValue] = useState(0);
  const [ariaAssert, setAriaAssert] = useState(false);

  useEffect(() => {
    if (durationvalue && [22, 12, 5].includes(durationvalue)) {
      setValue(durationvalue);
      mediaDurationSelect(durationvalue);
    }
  }, [durationvalue]);

  const handleChange = (event: any, newValue: number | number[]) => {
    newValue = newValue === 13.5 ? 12 : newValue;

    if (value != newValue) {
      setOldValue(value);
      setValue(newValue as number);
      if (audiosetstate || videosetstate) {
        clearSelectionDialog.show();
      } else {
        setAriaAssert(true);
        setTimeout(() => setAriaAssert(false), 500);
        mediaDurationSelect(newValue);
      }
    }
  };

  const selectionDialogCancel = () => {
    setValue(oldvalue);
    clearSelectionDialog.hide();
  };

  const selectionDialogConfirm = () => {
    setAriaAssert(true);
    setTimeout(() => setAriaAssert(false), 500);
    mediaDurationSelect(value);
    clearSelectionDialog.hide();
  };

  const calculateNewValue = (
    currentValue: number,
    direction: "increase" | "decrease"
  ): number | null => {
    const values = [5, 12, 22];
    const currentIndex = values.indexOf(currentValue);

    if (direction === "increase" && currentIndex < values.length - 1) {
      return values[currentIndex + 1];
    } else if (direction === "decrease" && currentIndex > 0) {
      return values[currentIndex - 1];
    }

    return null;
  };

  const handleKeyDown = (event: any) => {
    let newValue = null;
    if (event.code === "Space") {
      event.preventDefault(); // Prevent default arrow key behavior
      newValue = calculateNewValue(value, "increase") || 5; // Loop back to 5 if increase is not possible
    } else if (event.key === "ArrowRight") {
      event.preventDefault(); // Prevent default arrow key behavior
      newValue = calculateNewValue(value, "increase");
    } else if (event.key === "ArrowLeft") {
      event.preventDefault(); // Prevent default arrow key behavior
      newValue = calculateNewValue(value, "decrease");
    }

    if (newValue !== null) {
      handleChange(null, newValue);
    }
  };

  return (
    <>
      <Box
        className="slider-Container meditationDuration"
        sx={{
          width: 320,
          margin: "0 auto",
          textAlign: "center",
          padding: "0 30px",
        }}
        onKeyDown={handleKeyDown}
        role="slider"
        tabIndex={0}
        aria-label={`Current value ${value} minutes - minimum 5 minutes - maximum 22 minutes, Meditation Duration`}
        aria-live={ariaAssert ? "assertive" : "off"}
      >
        <div aria-hidden={true}>
          <IOSSlider
            value={value === 12 ? 13.5 : value}
            onChange={handleChange}
            step={null}
            marks={marks}
            max={22}
            min={5}
            tabIndex={-1}
          />
          <SLTypography
            variant="smallBodyCopy"
            color="text-deepPurple"
            sx={{ fontSize: { xs: "12px", md: "14px" } }}
          >
            Duration: <span>{value}</span> min
          </SLTypography>
        </div>
      </Box>

      <SLDialog
        dismissable={true}
        onDismiss={selectionDialogCancel}
        dialog={clearSelectionDialog}
        actionButtons={{
          yesMessageId: "general.confirm",
          onYesButtonClicked: selectionDialogConfirm,
          noMessageId: "general.cancel",
          onNoButtonClicked: selectionDialogCancel,
        }}
      />
    </>
  );
};
