/* eslint-disable */
import { Container } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { AudioPlayerScreen } from "components/audioPlayer";
import { SLMeditationLoader } from "components/createMeditationAccordian/createMeditationLoader";
import { SLLoader } from "components/loader";
import PageTitle from "components/pageTitle/pageTitle";
import { useAppUrls } from "helpers/url";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { MediaType, SLMediaFileDetail } from "utils/slRTKHelper/slAPI.models";

const useStyles = makeStyles(() => ({
  playerWrapper: {
    aspectRatio: "16/9",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    overflow: "hidden",
    position: "relative",
    zIndex: 1,
  },
}));

function AudioPlayer() {
  const buildMeditationLoader = sessionStorage.getItem("fromBuildMeditation");
  const onDetailPage = sessionStorage.getItem("onDetailPage");
  const classes = useStyles();
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appUrls = useAppUrls();

  const { data: mediaAPIData } = useSelector(SLSelector.getMediaId);
  const { data: unlikedMediaFile } = useSelector(
    SLSelector.deleteMediaFavouritesId
  );
  const { data: likedMediaFile } = useSelector(
    SLSelector.postMediaAddFavourites
  );

  const [mediaType, setMediaType] = useState<MediaType>();
  const [playBackUrl, setPlayBackUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [playBackThumbnail, setPlayBackThumbnail] = useState("");
  const [mediaData, setMediaData] = useState<SLMediaFileDetail | undefined
  >(undefined);

  useEffect(() => {
    if (id) {
      dispatch(SLThunk.getMediaId.call({ id }));
    }
  }, [id]);

  useEffect(() => {
    if (unlikedMediaFile && mediaData) {
      setMediaData(prv => {
        if (prv) {
          return ({ ...prv, isLikedByMe: false })
        }
      })
      dispatch(SLThunk.deleteMediaFavouritesId.reset());

    }
  }, [unlikedMediaFile]);

  useEffect(() => {
    if (likedMediaFile && mediaData) {
      setMediaData(prv => {
        if (prv) {
          return ({ ...prv, isLikedByMe: true })
        }
      })
      dispatch(SLThunk.postMediaAddFavourites.reset());

    }
  }, [likedMediaFile]);

  useEffect(() => {
    if (mediaAPIData) {
      setMediaData({ ...mediaAPIData })
    }
  }, [mediaAPIData]);

  useEffect(() => {
    setPlayBackThumbnail("")
    setPlayBackUrl("")
    setLoader(true)
    if (mediaData) {
      setLoader(false)
      dispatch(SLThunk.getMediaId.reset());
      if (mediaData?.type === "frequencyWave") {
        navigate("/app/file-detail/" + mediaData?.id);
      }
      if (mediaData?.url) {
        const mediaType =
          mediaData?.type?.toLocaleLowerCase() === "audio" ? "audio" : "video";

        setMediaType(mediaType);

        if (mediaData?.locked) {
          navigate("/app/home");
        }
        if (mediaData?.type && mediaType === "audio") {
          setPlayBackUrl(appUrls.mediaAudio(mediaData?.url));
        } else {
          setPlayBackUrl(appUrls.mediaVideo(mediaData?.url));
        }
      }
      if (mediaData?.thumbnail) {
        setPlayBackThumbnail(appUrls.mediaImage(mediaData?.image));
      }
    }
  }, [mediaData]);

  return (
    <>
      <PageTitle title={mediaData?.title || ""} />
      <Container maxWidth="lg" sx={{ overflow: "hidden", padding: 0 }}>
        <div
          className={classes.playerWrapper}
          style={{ backgroundImage: `url(${playBackThumbnail})` }}
        >
          {mediaData && mediaType && playBackUrl && playBackThumbnail ? (
            <AudioPlayerScreen
              mediaData={mediaData}
              mediaType={mediaType}
              playBackUrl={playBackUrl}
              playBackThumbnail={playBackThumbnail}
              dynamicLinkUrlResponse={playBackUrl}
            />
          ) : (
            <></>
          )}
          {buildMeditationLoader == 'true' && (!onDetailPage || onDetailPage !== "true") ?
            <SLMeditationLoader
            open={loader}
          /> :<SLLoader open={loader} />}
        
        </div>
        
      </Container>
    </>
  );
}

export default AudioPlayer;
