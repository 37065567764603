import {
  Box,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  Stack,
} from "@mui/material";
import AuthenticationLayout from "components/authenticationLayout";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import Footer from "components/footer";
import { SLLoader } from "components/loader";
import { SLButton } from "components/slButton";
import { InputStandardField } from "components/slTextField";
import { SLTypography } from "components/slTypography";
import { ArrowBackIosNewIcon, MessageIcon } from "helpers/enum/constants";
import { validateEmail } from "helpers/validations";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import "./index.css";

const ForgotPassword = () => {
  const [open, setOpen] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [validationError, setValidationError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const { data, loading, error } = useSelector(
    SLSelector.postAuthEmailForgotpassword
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const token = SLStorage.getItem("auth");
    if (token) {
      navigate("/app/home");
    }
    if (data) {
      setOpen(true);
    }
    if (error) {
      setErrorDialog(true);
      setValidationError(error);
    }
  }, [data, error]);

  const handleClose = () => {
    setOpen(false);
  };

  const ForgotPassword = (event: any) => {
    event.preventDefault();
    if (email === "") {
      setEmailError("Fill the obligatory field, please");
    } else if (!validateEmail(email)) {
      setEmailError("Invalid email address");
    } else {
      dispatch(
        SLThunk.postAuthEmailForgotpassword.call({
          email: email,
        })
      );
    }
  };

  const resetState = () => {
    dispatch(SLThunk.postAuthEmailForgotpassword.reset());
    navigate("/login");
  };

  return (
    <div className="page-container forgotPasswordContainer">
      <AuthenticationLayout
        pageTitle="Forgot Password"
        colorChange="background-color-change"
      >
        <Container
          sx={{
            width: { xl: "93.2%", lg: "95%" },
            minHeight: "100vh",
          }}
          maxWidth={false}
          className="footer-page-wrapper"
        >
          <Stack>
            <div
              style={{
                marginTop: "4%",
                marginBottom: "5%",
                display: "flex",
              }}
            >
              <ArrowBackIosNewIcon
                aria-label="Back Navigation"
                titleAccess="Back Navigation"
                tabIndex={0}
                sx={{ cursor: "pointer", color: "var(--sl-deep-purple)" }}
                onClick={() => navigate("/login")}
                onKeyPress={() => navigate("/login")}
              />
              <Divider
                sx={{
                  height: 40,
                  borderColor: "var(--sl-deep-purple)",
                  opacity: "0.2",
                  marginLeft: "2%",
                  marginTop: "-10px",
                  marginRight: "2%",
                }}
                orientation="vertical"
              />
              <SLTypography
                variant="heading"
                tabIndex={0}
                aria-level={2}
                color="text-deepPurple"
                messageId="general.forgot.password"
              />
            </div>

            <SLTypography
              fontSize={17}
              fontWeight={600}
              variant="extraSmall"
              aria-level={2}
              color="text-deepPurple"
              messageId="general.forgot.password.message"
              tabIndex={0}
            />
            <Grid container sx={{ marginTop: "1%" }}>
              <Grid item lg={6} md={9} sm={12}>
                <Box
                  onSubmit={ForgotPassword}
                  component="form"
                  noValidate
                  sx={{
                    color: "black",
                    fontSize: 16,
                    fontWeight: 400,
                    lineHeight: "21.79px",
                  }}
                  autoComplete="off"
                >
                  <InputStandardField
                    error={emailError ? true : false}
                    helperText={emailError}
                    id="Email"
                    variant="filled"
                    labelId="Email"
                    type="text"
                    title="Email"
                    props={{
                      className: "forgot-password-email-input",
                    }}
                    inputProps={{
                      onChange: (e: any) => {
                        setEmail(e.target.value);
                        setEmailError("");
                      },
                      disableUnderline: true,
                    }}
                  />
                  <FormControl
                    variant="standard"
                    sx={{ marginTop: "10px" }}
                    fullWidth
                  >
                    <SLButton
                      type="submit"
                      variant="contained"
                      title="Send"
                      messageId={"general.send"}
                      sx={{ width: { lg: "35%", md: "35%", xs: "40%" } }}
                    />
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          </Stack>
        </Container>

        <SLLoader open={loading} />
        <div className="sticky-bottom-footer">
          <Footer />
        </div>
      </AuthenticationLayout>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box className="forgotPassword-dialog-box">
          <Stack tabIndex={0} direction="column" textAlign="left" spacing={1}>
            <img
              title="Soul Link"
              tabIndex={0}
              src={MessageIcon}
              alt=""
              width="6%"
            />
            <DialogTitle
              sx={{
                fontWeight: 700,
                fontSize: "32px",
                width: "auto",
                padding: 0,
                fontStyle: "normal",
                color: "var(--sl-deep-purple)",
                fontFamily: "Figtree",
              }}
            >
              <FormattedMessage id="general.forgot.password.dialog.title" />
            </DialogTitle>
            <DialogContent
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                padding: 0,
                color: "var(--sl-deep-purple)",
              }}
            >
              <SLTypography fontFamily="Figtree" fontSize="18px" tabIndex={0}>
                <FormattedMessage id="general.forgot.password.dialog.message" />
              </SLTypography>
            </DialogContent>
          </Stack>
          <Stack
            direction="row"
            justifyContent="center"
            marginTop="20px"
            spacing={2}
          >
            <SLButton
              variant="contained"
              fullWidth
              messageId={"general.ok"}
              onClick={() => resetState()}
              onKeyPress={() => resetState()}
            />
          </Stack>
          <Stack marginTop="20px">
            <SLTypography
              variant="extraSmall"
              textAlign={"center"}
              color="text-deepPurple"
              fontWeight={600}
              tabIndex={0}
              messageId="general.forgot.password.dialog.not.received"
            />
            <SLTypography
              fontWeight={600}
              variant="extraSmall"
              tabIndex={0}
              color="text-deepPurple"
            >
              <FormattedMessage id="general.forgot.password.dialog.spam" />
              <span
                tabIndex={0}
                aira-level={2}
                style={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  color: "var(--sl-purple)",
                }}
                onClick={handleClose}
                onKeyPress={handleClose}
              >
                {" "}
                <FormattedMessage id="general.forgot.password.dialog.try.again" />
              </span>
            </SLTypography>
          </Stack>
        </Box>
      </Dialog>
      <ErrorDialogBox
        errorDialog={errorDialog}
        setErrorDialog={setErrorDialog}
        errorMessage={validationError}
        errorHeaderMessage="Oops!"
      />
    </div>
  );
};

export default ForgotPassword;
