export const MixpanelConstants = {
  //keys for data to be passed against events
  KEYS: {
    EMAIL: "email",
    NAME: "name",
    IS_GUEST: "Is Guest",
    LOGGED_IN_AS: "Logged In As",
    AGE: "Age",
    GENDER: "Gender",
    MEDITATION_ID: "Meditation ID",
    MEDITATION_TITLE: "Meditation Title",
    AUDIO_ID: "Audio ID",
    AUDIO_TITLE: "Audio Title",
    VIDEO_ID: "Video ID",
    VIDEO_TITLE: "Video Title",
    START_TIME: "Start Time",
    END_TIME: "End Time",
    ELAPSED_TIME: "Elapsed Time",
    FREQUENCY_ID: "Frequency ID",
    FREQUENCY_TITLE: "Frequency Title",
    MESSAGE: "Message",
    FULL_NAME: "Full Name",
    TIME_STAMP: "Time Stamp",
    USER_NAME: "User Name",
    USER_ID: "User ID",
    SCREEN: "Screen",
    SEARCH_TEXT: "Search Text",
    TAG_ID: "Tag ID",
    TAG_TITLE: "Tag Title",
    MEDITATION_WATCH_TIME: "Watch Time",
    SUBSCRIPTION_PLAN_ID: "Subscription Plan ID",
    END_DATE: "End Date",
    START_DATE: "Start Date",
    USE_TIME: "Use Time",
  },

  // events Name
  EVENTS: {
    MEDITATION_SESSION_STARTED: "Meditation Session Started",
    MEDITATION_SESSION_COMPLETED: "Meditation Session Completed",
    MEDIA_SESSION_COMPLETED: "Media Session Completed",
    AUDIO_TRACK_PLAYED: "Audio Track Played",
    VIDEO_TRACK_PLAYED: "Video Track Played",
    FREQUENCY_USED: "Frequency Used",
    TIME_SPENT_WATCHING_VIDEO: "Time Spent Watching Video",
    TIME_SPENT_WATCHING_AUDIO: "Time Spent Watching Audio",
    TIME_SPENT_WATCHING_MEDITATION: "Time Spent Watching Meditation",
    AUDIO_VIEWED: "Audio Viewed",
    VIDEO_VIEWED: "Video Viewed",
    USER_FEEDBACK: "User Feedback",
    TAG_USED: "Tag Used",
    MEDITATION_COMPILATION_MOST_VIEWED: "Meditation Compilation Most Viewed",
    SUBSCRIPTION_PURCHASED: "Subscription Purchased",
    SUBSCRIPTION_CANCELED: "Subscription Canceled",
    SEARCH: "Search",
    USER_CURRENTLY_MEDITATING: "User Currently Meditating",
    USER_ACTIVE: "User Active Status",
    LAST_PAGE: "Last Page",
    TIME_SPENT_NOT_MEDITATION: "Time Spent Not Meditating",
  },

  // page names
  SCREENS: {
    ABOUT_FRAGMENT: "About Screen",
    ABOUT_SUPERNAL_WORLD_FRAGMENT: "About Supernal World Screen",
    ABOUT_US_FRAGMENT: "About Us Screen",
    ACCOUNT_SETTINGS_FRAGMENT: "Accounts Settings Screen",
    ADDITIONAL_INFORMATION_FRAGMENT: "Add Additional Information Screen",
    AUTHOR_DETAIL_FRAGMENT: "Author Detail Screen",
    BROWSE_SETTINGS_FRAGMENT: "Browse Setting Screen",
    CHANGE_PASSWORD_FRAGMENT: "Change Password Screen",
    CONTACT_US_FRAGMENT: "Contact Us Screen",
    CREATE_ACCOUNT_FRAGMENT: "Create Account Screen",
    CREATE_ACHIEVEMENT_FRAGMENT: "Create Achievement Screen",

    CREATE_FRAGMENT: "Create Screen",
    CREATE_MEDITATION_AUDIO: "Create Meditation: Choose Audio",
    CREATE_MEDITATION_VIDEO: "Create Meditation: Choose Video",
    CREATE_MEDITATION_FREQUENCY_WAVE:
      "Create Meditation: Choose Frequency Wave",

    DAILY_INSIPRATION_GRAGMENT: "Daily Inspiration Screen",
    DOWNLOADS_FRAGMENT: "Download Screen",
    EDIT_ACCOUNT_FRAGMENT: "Edit Account Screen",
    EDIT_NOTES_FRAGMENT: "Add/Edit Notes Screen",

    EXPLORE_ALL_FILES_FRAGMENT: "Explore All Files Screen",
    EXPLORE_ALL_FILES_AUDIO: "Explore All Files: Audio",
    EXPLORE_ALL_FILES_VIDEO: "Explore All Files: Video",
    EXPLORE_ALL_FILES_FREQUENCY_WAVE: "Explore All Files: Frequency Wave",
    EXPLORE_ALL_SUGGESSTED_FRAGMENT: "Explore All Files: Suggested",
    EXPLORE_FRAGMENT: "Explore Screen",

    FAQ_FRAGMENT: "FAQ Screen",
    FILES_PLAYER_FRAGMENT: "Files Player Screen",
    FORGOT_PASSWORD_FRAGMENT: "Forgot Password Screen",
    HELP_CENTER_FRAGMENT: "Help Center Screen",
    HELP_REQUEST_HISTORY: "Help History Screen",

    HOME_FRAGMENT: "Home Screen",
    HOME_AUDIO: "Home: Choose Audio",
    HOME_VIDEO: "Home: Choose Video",
    HOME_FREQUENCY_WAVE: "Home: Choose Frequency Wave",
    HOME_MY_LIST: "Home: My List",

    HOW_IT_WORKS_FRAGMENT: "How to Use Screen",
    INTRIODUCTION_FRAGMENT: "Introduction Screen",
    LOGIN_FRAGMENT: "Login Screen",
    MAIN_SCREEN: "Main Screen",
    MANAGE_SUBSCRIPTION_FRAGMENT: "Manage Subscription Screen",
    MEDIA_DETAIL_FRAGMENT: "Media Detail Screen",
    MEDITATIN_PLAYER_FRAGMENT: "Meditation Player Screen",

    MEDITATION_DETIAL_FRAGMENT: "Meditation Detail Screen",
    MEDITATION_DETAIL_AUDIO: "Meditation Detail: Audio",
    MEDITATION_DETAIL_VIDEO: "Meditation Detail: Video",
    MEDITATION_DETAIL_FREQUENCY_WAVE: "Meditation Detail: Frequency Wave",

    MENU_FRAGMENT: "Menu Screen",
    MY_LIST_ALL_FILES_FRAGMENT: "My List All Files Screen",
    MY_LIST_ALL_MEDITATION_FRAGMENT: "My List All Meditation Screen",

    MY_LIST_FRAGMENT: "My List Screen",
    MY_LIST_RECENT_MEDITATIONS: "My List: My Recent Meditations",
    MY_LIST_CREATED_MEDITATIONS: "My List: My Created Meditations",
    MY_LIST_FAVORITE_MEDITATIONS: "My List: My Favorite Meditations",
    MY_LIST_AUDIO_FILES: "My List: Audio Files",
    MY_LIST_VIDEO_FILES: "My List: Video Files",
    MY_LIST_FREQUENCY_WAVE_FILES: "My List: Frequency Wave Files",

    MY_NOTES_FRAGMENT: "My Notes Screen",
    MY_PROFILE_FRAGMENT: "My Profile Screen",
    NOTIFICATION_SETTINGS_FRAGMENT: "Notification Screen",
    PROFILE_PICTURE_FRAGMENT: "App Profile Picture Screen",
    REPORT_PROBLEM_FRAGMENT: "Report Problem Screen",
    RESET_PASSEORD_FRAGMENT: "Reset Password Screen",
    SPLASH_FRAGMENT: "Splash Screen",
    SUBSCRIPTION_FRAGMENT: "Subscription Screen",
  },
};
