import axios, {
  AxiosRequestConfig,
  AxiosRequestHeaders,
  AxiosResponse,
} from "axios";
import { Auth } from "../authService";
import { SLStorage } from "../slStorage";
import { SLAPIResponse, SLAuth } from "./slAPI.models";
const slAxios = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_API ?? "",
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
    secret: process.env.REACT_APP_BACKEND_SECRET,
  },
});
slAxios.interceptors.request.use(
  (request) => {
    const auth = SLStorage.getItem("auth");
    const guestAuth = SLStorage.getItem("guestAuth");
    const accessToken = auth ? auth.accessToken : guestAuth?.accessToken;
    const headers = request.headers as AxiosRequestHeaders;
    headers["platform"] = "web";
    if (auth || guestAuth) {
      headers["Authorization"] = `Bearer ${accessToken}`;
    }
    request.headers = headers;
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * Wrap the interceptor in a function, so that i can be re-instantiated
 */
function createAxiosResponseInterceptor() {
  const interceptor = slAxios.interceptors.response.use(
    (response) => response,
    (error) => {
      // console.log("API Error", error);
      // Reject promise if usual error
      if (error !== undefined && error?.response?.status !== 401) {
        return Promise.reject(error.response?.data?.message[0] ?? "");
      }
      /*
       * When response code is 401, try to refresh the token.
       * Eject the interceptor so it doesn't loop in case
       * token refresh causes the 401 response.
       *
       * Must be re-attached later on or the token refresh will only happen once
       */
      const auth = Auth.user.loggedin();
      if (auth) {
        slAxios.interceptors.response.eject(interceptor);
        return slAxios
          .post(
            "/auth/refresh",
            {},
            {
              headers: {
                refreshToken: auth.refreshToken,
              },
            }
          )
          .then((response) => {
            const newAuth = response.data.result as SLAuth;
            const authType: any = SLStorage.getItem("authType") || "email";
            Auth.user.login(newAuth, authType);
            error.response.config.headers[
              "Authorization"
            ] = `Bearer ${newAuth.accessToken}`;
            error.response.config.headers["secret"] =
              process.env.REACT_APP_BACKEND_SECRET;
            // Retry the initial call, but with the updated token in the headers.
            // Resolves the promise if successful
            return makeRequest(error.response.config);
          })
          .catch((error2) => {
            // Retry failed, clean up and reject the promise
            Auth.user.logout();
            Auth.guest.logout();

            window.location.href = "/login";
            return Promise.reject(error2);
          })
          .finally(createAxiosResponseInterceptor); // Re-attach the interceptor by running the method
      } else {
        return Promise.reject(error);
      }
    }
  );
}
createAxiosResponseInterceptor();
async function makeRequest<ResponseType, PayloadType>(
  configs: AxiosRequestConfig<PayloadType>
) {
  if (!navigator.onLine) {
    throw new Error("Please check your internet connection or try again.");
  }
  // console.log("API called: ", configs);
  const response: AxiosResponse<SLAPIResponse<ResponseType>> = await slAxios(
    configs
  );
  // console.log("response", response);
  return response?.data?.result;
}

export function makeSLAPICall<ResponseType, PayloadType, QueryParamsType>({
  method,
  url,
  payload,
  params,
  urlParams,
  headers,
}: {
  method: string;
  url: string;
  params?: QueryParamsType;
  payload?: PayloadType;
  urlParams?: { [key: string]: string };
  headers?: { [key: string]: string };
}): Promise<ResponseType> {
  if (urlParams) {
    for (const key of Object.keys(urlParams)) {
      url = url.replace(`{${key}}`, urlParams[key]);
    }
  }
  if (params) {
    const paramsString = new URLSearchParams(params).toString();
    url = `${url}?${paramsString}`;
  }
  // console.log(headers, payload);
  return makeRequest<ResponseType, PayloadType>({
    method,
    url,
    data: payload,
    // headers,
  });
}

/** API call without any interceptor */
export async function SLAuthAPI<ResponseType, PayloadType>(
  configs: AxiosRequestConfig<PayloadType>
) {
  configs.baseURL = process.env.REACT_APP_BACKEND_API ?? "";
  configs.timeout = 30000;
  configs.headers = {
    ...configs.headers,
    "Content-Type": "application/json",
    secret: process.env.REACT_APP_BACKEND_SECRET,
    platform: "web",
  };
  // configs.validateStatus = () => true;
  configs.validateStatus = (status) => status === 200;

  const auth = SLStorage.getItem("auth");
  const guestAuth = SLStorage.getItem("guestAuth");
  const accessToken = auth ? auth.accessToken : guestAuth?.accessToken;
  if (auth || guestAuth) {
    configs.headers["Authorization"] = `Bearer ${accessToken}`;
  }

  if (!navigator.onLine) {
    throw new Error("Please check your internet connection or try again.");
  }
  // console.log("API called: ", configs);
  const response: AxiosResponse<SLAPIResponse<ResponseType>> = await axios(
    configs
  );
  SLStorage.removeItem("selectedMeditationDataCreate");
  return response?.data?.result;
}

export function makeCancelableSLAPICall({
  method,
  url,
  payload,
  params,
  urlParams,
}: {
  method: string;
  url: string;
  params?: Record<string, any>;
  payload?: Record<string, any>;
  urlParams?: Record<string, any>;
}) {
  const controller = new AbortController();

  if (urlParams) {
    for (const key of Object.keys(urlParams)) {
      url = url.replace(`{${key}}`, urlParams[key]);
    }
  }
  if (params) {
    const paramsString = new URLSearchParams(params).toString();
    url = `${url}?${paramsString}`;
  }

  const configs = {
    method,
    url,
    data: payload,
    signal: controller.signal,
  };

  if (!navigator.onLine) {
    throw new Error("Please check your internet connection or try again.");
  }

  const axiosPromise = slAxios(configs);

  return {
    axiosPromise,
    cancel: () => controller.abort(),
  };
}
