import { Box, InputAdornment, Stack } from "@mui/material";
import { onEnterOrSpace } from "helpers/common/keypress";
import { MediaDetailPages, MediaType, MeditationType } from "helpers/enum";
import { CrossIcon, SearchIcon } from "helpers/enum/constants";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getIdealPageSize } from "utils/getIdealPageSize";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import MediaDetail from "../../mediaDetail";
import { InputStandardField } from "../../slTextField";
import { SLVerificationMessage } from "../../verificationMessage";

const MeditationListDetail = () => {
  const mixpanel = useMixpanel();
  const { type } = useParams();
  const dispatch = useDispatch();
  const timerRef = useRef<any>();

  // Manage Use States

  const [alignment, setAlignment] = useState<string>("Any Time");
  const [toggleBtn, setToggleBtn] = useState<string>("All");
  const [mediaData, setMediaData] = useState<any>();
  const [mediaType, setMediaType] = useState<string>();
  const [mediaDuration, setMediaDuration] = useState<number>(0);
  const [searchPlaceHolder, setSearchPlaceHolder] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [addedToFav, setAddedToFav] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [favMeditations, setFavMeditations] = useState<boolean>(false);
  const [tagArray, setTagArray] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [allMediaTags, setMediaTags] = useState<any>();
  const [alltag, setalltags] = useState([
    {
      id: 0,
      title: "All",
      type: type,
    },
  ]);
  const [defaultPayload, setDefaultPayload] = useState({
    limit: getIdealPageSize(),
    page: 1,
    sort: "DESC",
    sortBy: "createdAt",
    tag: "All",
  });

  // Manage Use Selectors
  const meditationData = useSelector(SLSelector.getMeditationMyMeditations);
  const favoriteMeditationData = useSelector(
    SLSelector.getMeditationMyFavourites
  );
  const getMediaTags = useSelector(SLSelector.getFilters);

  // Manage UseEffects

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(SLThunk.getFilters.call());
  }, []);

  useEffect(() => {
    if (getMediaTags.data) {
      setMediaTags(getMediaTags.data);
      dispatch(SLThunk.getFilters.reset());
    }
  }, [getMediaTags]);

  useEffect(() => {
    if (getMediaTags.data && type) {
      const arr = getMediaTags.data.filter(
        (tags: any) => tags.type != MediaType.Notes
      );
      setTagArray(arr);
      setTagArray((pre: any) => [...alltag, ...pre]);
    }
  }, [type, getMediaTags.data]);

  useEffect(() => {
    if (type) {
      let meditationStatus;
      let onlyFavoriteMeditation = false;
      if (type === MeditationType.RecentMeditation) {
        setSearchPlaceHolder("Recent");
        meditationStatus = "lastPlayed";
      } else if (type === MeditationType.CreatedMeditation) {
        setSearchPlaceHolder("Created");
        meditationStatus = "createdAt";
      } else {
        setSearchPlaceHolder("Favorites");
        meditationStatus = "createdAt";
        onlyFavoriteMeditation = true;
      }
      setFavMeditations(onlyFavoriteMeditation);
      setMediaType(meditationStatus as any);
      getMediaData({
        ...defaultPayload,
        sortBy: meditationStatus,
        isFavourite: onlyFavoriteMeditation,
        search: searchValue,
      });
    }
  }, [type]);

  useEffect(() => {
    if (meditationData.data) {
      setLoading(false);

      if (searchValue) {
        if (type === MeditationType.RecentMeditation) {
          mixpanel.triggerSearchEvent(
            "MY_LIST_RECENT_MEDITATIONS",
            searchValue
          );
        } else if (type === MeditationType.CreatedMeditation) {
          mixpanel.triggerSearchEvent(
            "MY_LIST_CREATED_MEDITATIONS",
            searchValue
          );
        } else {
          debugger;
        }
      }

      setMediaData({ ...meditationData.data });
      dispatch(SLThunk.getMeditationMyMeditations.reset());
    }
  }, [meditationData.data]);

  useEffect(() => {
    if (favoriteMeditationData.data) {
      setLoading(false);

      if (searchValue && type === MeditationType.FavoriteMeditation) {
        mixpanel.triggerSearchEvent(
          "MY_LIST_FAVORITE_MEDITATIONS",
          searchValue
        );
      }

      setMediaData(favoriteMeditationData.data);
      dispatch(SLThunk.getMeditationMyFavourites.reset());
    }
  }, [favoriteMeditationData.data]);

  // Manage Functions

  const getMediaData = (payload: any) => {
    if (payload.duration === 0) {
      delete payload.duration;
    }

    if (type === MeditationType.FavoriteMeditation) {
      dispatch(SLThunk.getMeditationMyFavourites.call(payload));
    } else {
      dispatch(SLThunk.getMeditationMyMeditations.call(payload));
    }
  };

  const handleLikedMediaCard = async (text: string) => {
    setAddedToFav(text);
    setOpenDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 1500);
  };

  const updateMediaDataOfUnfavourites = (id: number) => {
    if (searchPlaceHolder === "Favorites") {
      setLoading(true);
      const filteredMedia = mediaData.data.filter((obj: any) => obj.id !== id);
      setMediaData({ data: filteredMedia });
      setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  };

  const handleSearch = (e: any) => {
    setLoading(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setSearchValue(e.target.value);
    timerRef.current = setTimeout(() => {
      getMediaData({
        ...defaultPayload,
        sortBy: mediaType,
        isFavourite: favMeditations,
        search: e.target.value,
        duration: mediaDuration,
      });
    }, 1000);
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    setLoading(true);
    setAlignment(newAlignment);
    setMediaData(null);
    if (newAlignment == null) {
      setAlignment("Any Time");
      newAlignment = "Any Time";
    }
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (newAlignment !== "Any Time") {
        const [a, b] = newAlignment.split(" ");
        setMediaDuration(a);
        getMediaData({
          ...defaultPayload,
          sortBy: mediaType,
          isFavourite: favMeditations,
          duration: a,
          tag: toggleBtn || "All",
          search: searchValue,
        });
      } else {
        setMediaDuration(0);
        getMediaData({
          ...defaultPayload,
          sortBy: mediaType,
          isFavourite: favMeditations,
          tag: toggleBtn || "All",
          search: searchValue,
        });
      }
    }, 700);
  };

  const handleToggleBtn = (
    event: React.MouseEvent<HTMLElement>,
    tagValue: any
  ) => {
    setLoading(true);
    setMediaData(null); // for removing glitch on data load
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setToggleBtn(tagValue);
    if (tagValue === null) {
      setToggleBtn("All");
    }
    timerRef.current = setTimeout(() => {
      if (mediaDuration !== 0) {
        getMediaData({
          ...defaultPayload,
          sortBy: mediaType,
          isFavourite: favMeditations,
          tag: tagValue || "All",
          duration: mediaDuration,
          search: searchValue,
        });
      } else {
        getMediaData({
          ...defaultPayload,
          sortBy: mediaType,
          isFavourite: favMeditations,
          search: searchValue,
          tag: tagValue || "All",
        });
      }
    }, 700);
  };

  const LoadMoreCards = (limit: number, pageNumber: number) => {
    getMediaData({
      ...defaultPayload,
      limit: limit,
      page: pageNumber,
      isFavourite: favMeditations,
      tag: toggleBtn || "All",
      duration: mediaDuration,
      search: searchValue,
      sortBy: mediaType,
    });
  };

  const clearSearch = () => {
    setLoading(true);
    setSearchValue("");
    getMediaData({
      ...defaultPayload,
      sortBy: mediaType,
      isFavourite: favMeditations,
      duration: mediaDuration,
    });

    const searchEl = document.getElementById("searchField") as HTMLInputElement;
    if (searchEl) {
      searchEl.focus();
    }
  };
  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Stack>
            <InputStandardField
              id="searchField"
              type="text"
              variant="standard"
              placeholderId={`Search ${searchPlaceHolder}`}
              value={searchValue}
              props={{ className: "list-search-input" }}
              inputProps={{
                disableUnderline: true,
                startAdornment: (
                  <>
                    <InputAdornment position="start">
                      <SearchIcon />
                    </InputAdornment>
                  </>
                ),
                endAdornment: (
                  <InputAdornment
                    sx={{ display: searchValue !== "" ? "" : "none" }}
                    position="start"
                  >
                    <CrossIcon
                      role="button"
                      tabIndex={0}
                      titleAccess="Clear Search"
                      onClick={clearSearch}
                      onKeyPress={onEnterOrSpace(clearSearch)}
                      sx={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
                onChange: (e: any) => handleSearch(e),
                // tabIndex: 0,
              }}
            />
          </Stack>
        </Box>
        <MediaDetail
          handleAlignment={handleAlignment}
          mediaType={mediaType as any}
          durationTimeValue={alignment}
          handleToggleBtn={handleToggleBtn}
          tagValue={toggleBtn}
          tagArray={tagArray}
          loading={loading}
          mediaData={mediaData}
          likedMediaCard={handleLikedMediaCard}
          searchPlaceHolder={`${searchPlaceHolder} Meditation`}
          whichPage={MediaDetailPages.MyList}
          updateMediaData={updateMediaDataOfUnfavourites}
          loadMoreCards={LoadMoreCards}
          cardTyped="Meditation"
          searchValue={searchValue}
          willShowLoader={meditationData.loading}
        />
      </Box>
      <SLVerificationMessage
        open={openDialog}
        sx={{ zIndex: 9999 }}
        message={addedToFav}
      />
    </>
  );
};

export default MeditationListDetail;
