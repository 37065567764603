/* eslint-disable prettier/prettier */
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Stack,
} from "@mui/material";
import AuthenticationLayout from "components/authenticationLayout";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import { SLLoader } from "components/loader";
import { ReduceMotionPopup } from "components/popUps/reduceMotion";
import { SLButton } from "components/slButton";
import { InputStandardField } from "components/slTextField";
import { SLTypography } from "components/slTypography";
import { SocialMedia } from "components/social";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { onLogout, onNewLogin } from "utils/authHooks";
import { Auth } from "utils/authService";
import { deviceId, deviceInfo } from "utils/firebase";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import * as Yup from "yup";
import SLLogo from "../../assets/images/logo.svg";
import "./index.css";

const recaptchaRef:any = React.createRef();


const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .matches(
      /^[a-zA-Z\._\d]{1,}@[a-zA-Z0-9]{2,}\.[a-zA-Z0-9]{2,}$/,
      "Invalid email address"
    )
    .required("Fill the obligatory field, please"),
  password: Yup.string().required("Fill the obligatory field, please"),
});

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { loading: userLoginLoading, error: userLoginError } = useSelector(
    SLSelector.postAuthLoginWeb
  );

  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  useEffect(() => {
    if (Auth.user.loggedin()) {
      onNewLogin(dispatch);
      navigate("/app/home");
    } else {
      debugger;
      onLogout(dispatch);
      Auth.user.logout();
    }
  }, []);
  useEffect(() => {
    debugger;
    if (userLoginError) {
      setErrorDialog(true);
      setErrorMessage(userLoginError);
    }
  }, [userLoginError]);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const signInForm = (values: any) => {
    dispatch(
      SLThunk.postAuthLoginWeb.call({
        email: values.email.replaceAll(" ", ""),
        password: values.password,
        fcmToken: deviceId,
        platform: "web",
        deviceInfo: deviceId,
        token: values.token
      })
    );
  };

  const initialValues = {
    email: "",
    password: "",
  };

  const onChange = async (value: any) => {
    console.log(value);
};

  const handleSubmit = async (values: any) => {
    values.token = await recaptchaRef.current.executeAsync();
    signInForm(values);
  };

  const continueAsGuest = () => {
    dispatch(
      SLThunk.postAuthGuestLogin.call({
        platform: "web",
        deviceInfo,
        deviceId,
      })
    );
  };
  return (
    <>
      <div className="auth-container emailPasswordStyles">
        <AuthenticationLayout pageTitle="Login">
          <Grid
            item
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            className="auth-wrapper"
            maxWidth="500px"
            padding="0 2rem"
            zIndex={2}
          >
            <img
              className="m-auto"
              tabIndex={0}
              src={SLLogo}
              title="Soul Link Logo"
              style={{}}
              width="80px"
            />
            <div className="mt-4">
              <SLTypography
                title="Soul Link"
                variant="headingLarge"
                tabIndex={0}
                aria-level={2}
                messageId="app.home.header"
              />
            </div>

            <Formik
              className="email-input-field"
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
              validateOnChange={false}
              validateOnBlur={false}
            >
              {({
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                values,
                setErrors,
              }) => (
                <form
                  autoComplete="off"
                  onSubmit={handleSubmit}
                  style={{ width: "100%" }}
                >
                  <InputStandardField
                    onChange={(e) => {
                      handleChange(e);
                      setErrors({ email: "" });
                    }}
                    onBlur={(e: any) => {
                      handleBlur("email");
                    }}
                    // shrink={true}
                    helperText={touched.email && errors.email}
                    inputProps2={{
                      "aria-invalid":
                        touched.email && errors.email ? "true" : "false",
                      "aria-label": "Email, ".concat(
                        touched.email && errors.email ? errors.email : ""
                      ),
                    }}
                    value={values.email.replaceAll(" ", "")}
                    id="email"
                    variant="standard"
                    labelId="Email"
                    title="Email"
                    type="text"
                  />
                  <InputStandardField
                    onChange={(e: any) => {
                      handleChange(e);
                      setErrors({ password: "" });
                    }}
                    onBlur={(e: any) => {
                      handleBlur("password");
                    }}
                    // shrink={true}
                    helperText={touched.password && errors.password}
                    value={values.password}
                    id="password"
                    variant="standard"
                    labelId="Password"
                    type={showPassword ? "text" : "password"}
                    inputProps2={{
                      "aria-invalid":
                        touched.password && errors.password ? "true" : "false",
                      "aria-label": "Password, ".concat(
                        touched.password && errors.password
                          ? errors.password
                          : ""
                      ),
                    }}
                    inputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onKeyPress={handleClickShowPassword}
                            edge="end"
                          >
                            {!showPassword ? (
                              <VisibilityOffOutlinedIcon
                                sx={{ color: "var(--colorWhite)" }}
                              />
                            ) : (
                              <VisibilityOutlinedIcon
                                sx={{ color: "var(--colorWhite)" }}
                              />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ textAlign: "right" }}>
                    <SLTypography variant="extraSmall">
                      <Link
                        className="redirection-link"
                        href="/forgot-password"
                        title="Forgot Password"
                      >
                        <FormattedMessage id="general.forgotPassword" />
                      </Link>
                    </SLTypography>
                  </div>

                  <FormControl variant="standard" fullWidth>
                    <Stack
                      spacing={2}
                      sx={{
                        alignItems: "center",
                        marginTop: "3%",
                      }}
                    >
                      <SLButton
                        type="submit"
                        sx={{ width: "40%" }}
                        messageId="general.login"
                        aria-label={
                          "Log in, " +
                          (Object.values(errors).length > 0
                            ? `${Object.keys(errors)[0]}: ${
                                Object.values(errors)[0]
                              }`
                            : "")
                        }
                      />
                    </Stack>
                    <SLTypography
                      variant="extraSmall"
                      tabIndex={0}
                      sx={{ marginTop: "5%", textAlign: "center" }}
                      messageId="general.signInWith"
                    />
                  </FormControl>
                </form>
              )}
            </Formik>
            <SocialMedia />
            <div
              style={{
                marginTop: "8%",
                textAlign: "center",
              }}
            >
              <SLTypography
                variant="extraSmall"
                tabIndex={0}
                title=" New to Soul Link"
              >
                <FormattedMessage id="general.newToSoul" />{" "}
                <Link
                  href="/signup"
                  role="link"
                  title="Sign Up"
                  className="redirection-link"
                >
                  <FormattedMessage id="onboarding.signup" />
                </Link>{" "}
                <FormattedMessage id="general.or" />{" "}
                <Link
                  href="javascript::void(0)"
                  role="link"
                  aria-label=" continue as a guest"
                  className="redirection-link"
                  onClick={continueAsGuest}
                >
                  <FormattedMessage id="general.continueasguest" />
                </Link>
              </SLTypography>
            </div>
            <ReCAPTCHA
              ref={recaptchaRef}
              sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY || ""}
              size="invisible"
              onChange={onChange}
            />
          </Grid>
          <SLLoader open={userLoginLoading} />
        </AuthenticationLayout>
        <ErrorDialogBox
          errorDialog={errorDialog}
          setErrorDialog={setErrorDialog}
          errorMessage={errorMessage}
          errorHeaderMessage="Oops!"
        />
      </div>

      <ReduceMotionPopup open={true} />
    </>
  );
};

export default Login;
