/* eslint-disable indent */
import { Box, Container, Grid } from "@mui/material";
import Page from "components/pageTitle";
import { lang } from "helpers/common/lang";
import {
  ForumOutlined,
  InfoOutlined,
  PermIdentityOutlined,
  SupernalWorldSvg,
} from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SLSelector } from "utils/slRTKHelper";
import { SLWebPage } from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";
import LayoutOutlet from "../layoutSidebar";

interface AboutProps {
  title: string;
  children: JSX.Element;
}

const hardcoded: any = [
  // {
  //   image: <DescriptionOutlined />,
  //   title: lang("general.terms"),
  // },
  // {
  //   image: <ShieldOutlined />,
  //   title: lang("general.privacy"),
  // },
];

const AboutPages = ({ title, children }: AboutProps) => {
  const { data: config } = useSelector(SLSelector.getConfig);
  const appUrls = useAppUrls();

  const guestLogin = SLStorage.getItem("guestLogin");

  const defaultProfileLinks = [
    !guestLogin
      ? {
          linkIcons: <PermIdentityOutlined />,
          name: lang("authorDetail.title"),
          redirectionLink: "/app/author-details",
        }
      : "",
    {
      linkIcons: <InfoOutlined />,
      name: "About Us",
      redirectionLink: "/app/about-us",
    },
    {
      linkIcons: <ForumOutlined />,
      name: "Contact Us",
      redirectionLink: "/app/contact-us",
    },
    {
      linkIcons: <Box component="img" src={SupernalWorldSvg} />,
      name: "About Supernal World Creations",
      redirectionLink: "/app/supernal-world-creation",
    },
  ];

  const [profileLinks, setProfileLinks] = useState<typeof defaultProfileLinks>(
    []
  );

  useEffect(() => {
    const profileLinks = [...defaultProfileLinks];

    if (config) {
      hardcoded.forEach((newItem: SLWebPage) => {
        pushToMenu(profileLinks, newItem);
      });

      if (Array.isArray(config.webPages) && config.webPages.length > 0) {
        config.webPages.forEach((newItem) => {
          pushToMenu(profileLinks, newItem);
        });
      }
    }

    setProfileLinks(profileLinks);
  }, [config]);

  function pushToMenu(profileLinks: any, newItem: any) {
    profileLinks.splice(profileLinks.length - 1, 0, {
      linkIcons:
        typeof newItem.image === "string" ? (
          <img height="24px" src={appUrls.webPageImage(newItem.image || "")} />
        ) : (
          newItem.image
        ),
      name: newItem.title,
      redirectionLink: appUrls.webPageRoute(newItem.title),
    });
  }

  return (
    <>
      <Page title={title} className="">
        <Container
          className="page-container"
          sx={{ width: { xl: "93.2%", lg: "95%" } }}
          maxWidth={false}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item xs={12} md={3.3} lg={3.6} xl={3.7}>
              <Box
                sx={{
                  paddingRight: {
                    xl: "100px",
                    lg: "60px",
                    md: "24px",
                    xs: "0px",
                  },
                  position: "sticky",
                  top: "100px",
                  paddingTop: { xs: 3, md: "0px" },
                }}
              >
                <LayoutOutlet redirectionData={profileLinks} />
              </Box>
            </Grid>
            <Grid item xs={12} md={8.7} lg={8.4} xl={8.3}>
              {children}
            </Grid>
          </Grid>
        </Container>
      </Page>
    </>
  );
};

export default AboutPages;
