import { AboutUsScreen } from "components/aboutpages/about-us-page/aboutUsParent";
import AboutPages from "../../../layouts/aboutPages";

const AboutUs = () => {
  return (
    <AboutPages title="About Us">
      <AboutUsScreen />
    </AboutPages>
  );
};

export default AboutUs;
