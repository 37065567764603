import { Box } from "@mui/material";
import { MiniplayerContext } from "contexts/miniplayerContext";
import { Oscillator } from "helpers/common/Oscillator";
import { onEnterOrSpace } from "helpers/common/keypress";
import {
  FrequencyDisabledIcon,
  FrequencyWhiteIcon,
  frequencyDefaultVolume,
} from "helpers/enum/constants";
import { memo, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { videoControlsState } from "store/videoControlsSlice";
import { volumeFadeActions, volumeFadeRedux } from "store/volumeFadeSlice";
import { SLSelector } from "utils/slRTKHelper";
import { SLMeditationDetail } from "utils/slRTKHelper/slAPI.models";
import { VolumeIcon, VolumeSlider } from "..";
interface IFrequencyVolume {
  playing: boolean;
  initialMuted: boolean | null;
  buffer: boolean;
  controlsShowing: boolean;
  showControls: () => void;
  mediaData?: SLMeditationDetail;
  completion: number;
  audioVolume: number;
  frequencyVolume: number;
  onFrequencyVolumeChange: (e: any, value: number) => any;
}

const osc = Oscillator.getInstance();
let playToneTimeout: any = undefined;
let unfadeVolumeTimeout: any = undefined;
const TONE_PLAY_DURATION = 200; // ms

export const FrequencyVolume = memo((props: IFrequencyVolume) => {
  const dispatch = useDispatch();
  const { data: config } = useSelector(SLSelector.getConfig);
  const videoControls = useSelector(videoControlsState);
  const volumeFadeState = useSelector(volumeFadeRedux);
  const [frequencies, setFrequencies] = useState<number[]>([]);
  const [muted, setMuted] = useState(false);
  const [suspended, setSuspended] = useState(true);
  const [paused, setPaused] = useState(false);
  const [showFrequency, setShowFrequency] = useState<boolean>(false);

  const { setFrequencyVolume } = useContext(MiniplayerContext);

  const handleVolumeChange = (e: any, value: any) => {
    dispatch(volumeFadeActions.targetFrequencyVolume(value));
    props.onFrequencyVolumeChange(null, value);
  };

  const handleFrequencyChange = () => {
    const f = pickFrequencyByCompletion(frequencies, props.completion);

    if (osc) {
      osc.frequency(f);
    }
  };

  const unsuspend = () => {
    if (osc && frequencies.length > 0) {
      const vol = paused || muted ? 0 : props.frequencyVolume;
      osc.resume(vol).then((started: boolean) => {
        if (!started) {
          setSuspended(true);
        } else {
          setSuspended(false);
        }
      });
    }
  };

  const onPauseOrBuffer = () => {
    if (!props.playing || props.buffer || props.initialMuted) {
      setPaused(true);
    } else {
      setPaused(false);
      unsuspend();
    }
  };

  const onVolumeEffect = () => {
    const vol =
      paused || muted || props.initialMuted || props.buffer
        ? 0
        : Math.max(0, props.frequencyVolume);
    console.log({
      paused,
      muted,
      buffer: props.buffer,
      initialMuted: props.initialMuted,
      vol,
    });
    if (osc && frequencies.length > 0) {
      if (suspended) {
        unsuspend();
      } else {
        osc.volume(vol).then((started: boolean) => {
          if (started) {
            unsuspend();
          }
        });

        if (vol <= 0) {
          setTimeout(() => {
            osc.volume(0);
          }, 300);
        }
      }
    }
  };

  const handleShowFrequency = (show: boolean) => {
    setShowFrequency(show);
  };

  useEffect(() => {
    setTimeout(() => {
      if (showFrequency) {
        const rail = document.querySelector(
          "#frequency-slider input"
        ) as HTMLInputElement;
        if (rail) {
          rail.focus();
        }
        props.showControls();
      }
    }, 400);
  }, [showFrequency]);

  useEffect(() => {
    return () => {
      if (osc) {
        osc.pause();
      }
    };
  }, []);

  useEffect(() => {
    if (props.controlsShowing === false) {
      setShowFrequency(false);
    }
  }, [props.controlsShowing]);

  useEffect(() => {
    onVolumeEffect();
  }, [muted]);

  useEffect(() => {
    onVolumeEffect();
  }, [suspended]);

  useEffect(() => {
    onVolumeEffect();
  }, [paused]);

  useEffect(() => {
    onPauseOrBuffer();
  }, [props.buffer]);

  useEffect(() => {
    onPauseOrBuffer();
  }, [props.initialMuted]);

  useEffect(() => {
    onPauseOrBuffer();
  }, [props.playing]);

  useEffect(() => {
    handleFrequencyChange();
  }, [props.completion]);

  useEffect(() => {
    handleFrequencyChange();
  }, [frequencies]);

  useEffect(() => {
    const frequencyValues = props?.mediaData?.frequencyWave?.frequencyValues;
    if (Array.isArray(frequencyValues)) {
      setFrequencies(frequencyValues);
    } else {
      setFrequencies([]);
    }
  }, [props?.mediaData?.frequencyWave?.frequencyValues]);

  const pickFrequencyByCompletion = (
    frequencies: any[],
    completion: number
  ) => {
    const lastIndex = frequencies.length - 1;
    let index = Math.round(lastIndex * completion);
    index = Math.min(index, lastIndex);

    return frequencies[index];
  };

  function endFreqChange(value: number) {
    console.log("end freq change");
    clearTimeout(playToneTimeout);
    playToneTimeout = setTimeout(() => {
      dispatch(volumeFadeActions.playTone());
    }, 300);
  }

  function startFreqChange(e: any) {
    console.log("start freq change");
    dispatch(
      volumeFadeActions.fadeVolume({
        prevVolume: props.audioVolume,
        prevFrequency: osc.getFrequency(),
      })
    );
  }

  useEffect(() => {
    if (volumeFadeState?.fading) {
      osc.fadeVolume()?.then(() => {
        dispatch(volumeFadeActions.faded());
      });
    }
  }, [volumeFadeState?.fading]);

  useEffect(() => {
    if (volumeFadeState) {
      console.log({ volumeFadeState });
      if (volumeFadeState.playingTone && volumeFadeState?.faded) {
        osc.playTone(
          volumeFadeState.targetFVolume || 0,
          config?.frequencyTone || 1000
        );

        clearTimeout(unfadeVolumeTimeout);
        unfadeVolumeTimeout = setTimeout(() => {
          dispatch(volumeFadeActions.unfadeVolume());
        }, TONE_PLAY_DURATION);
      } else if (volumeFadeState.unfading) {
        osc
          .unfadeVolume(
            volumeFadeState.targetFVolume || 0,
            volumeFadeState.prevFrequency || 0
          )
          ?.then(() => {
            if (!volumeFadeState?.fading) {
              dispatch(volumeFadeActions.completed());
              setFrequencyVolume(volumeFadeState.targetFVolume || 0);
            }
          });
      }
    }
  }, [volumeFadeState]);

  const handleToggleFrequencyMute = (e: any) => {
    setMuted((prv) => {
      const muted = !prv;

      if (!muted && !props.frequencyVolume) {
        props.onFrequencyVolumeChange(null, frequencyDefaultVolume);
      }

      return muted;
    });
  };
  return (
    <>
      <Box
        onMouseLeave={() => {
          handleShowFrequency(false);
        }}
        onBlur={({ relatedTarget, currentTarget }) => {
          if (!hasFocusWithin(relatedTarget, currentTarget)) {
            handleShowFrequency(false);
          }
        }}
        sx={{ display: "flex" }}
        flexDirection="column"
        alignItems="center"
        gap="15px"
        height="120px"
      >
        <VolumeSlider
          id="frequency-slider"
          className="add-shadow"
          min={-5}
          max={100}
          orientation="vertical"
          value={
            props.initialMuted || suspended || muted ? 0 : props.frequencyVolume
          }
          onChange={handleVolumeChange}
          onChangeCommitted={(e, v) => {
            endFreqChange(v as unknown as number);
          }}
          onMouseDown={startFreqChange}
          onTouchStart={startFreqChange}
          onKeyUp={(e) => {
            if (e.key === "ArrowUp" || e.key === "ArrowDown") {
              startFreqChange(e);
            }
          }}
          sx={{
            opacity: showFrequency ? 100 : 0,
            pointerEvents: showFrequency ? "initial" : "none",
          }}
          tabIndex={showFrequency ? 0 : -1}
        />

        <VolumeIcon
          tabIndex={0}
          src={
            props.initialMuted || muted
              ? FrequencyDisabledIcon
              : FrequencyWhiteIcon
          }
          onMouseEnter={() => handleShowFrequency(true)}
          onFocus={() => handleShowFrequency(true)}
          onClick={handleToggleFrequencyMute}
          onKeyPress={onEnterOrSpace(handleToggleFrequencyMute)}
          alt="Frequency Icon"
        />
      </Box>
    </>
  );
});

function hasFocusWithin(element: any, context: any): any {
  if (!element) {
    return false;
  }

  if (element === context) {
    return true;
  }

  return element.parentElement
    ? hasFocusWithin(element.parentElement, context)
    : false;
}
