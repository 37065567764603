import { FormControl, TextField } from "@mui/material";
import "./index.css";

type SLInputStandardFieldProps = {
  onChange?: (value: string) => void;
  onBlur?: (value: string) => void;
  onFocus?: (value: string) => void;
  id: string;
  placeholderId?: string;
  labelId?: string;
  labelClassName?: string;
  props?: any;
  type: string;
  inputProps?: any;
  inputProps2?: any;
  variant: string;
  helperText?: string | false | undefined;
  error?: boolean;
  value?: string;
  shrink?: boolean;
  title?: string;
  className?: string;
};

export const InputStandardField = ({
  onChange,
  onBlur,
  id,
  placeholderId,
  labelId,
  labelClassName,
  type,
  props,
  inputProps,
  inputProps2,
  variant,
  error,
  helperText,
  value,
  shrink,
  title,
  onFocus,
  className,
}: SLInputStandardFieldProps) => {
  return (
    <>
      <FormControl className={className || ""} fullWidth variant="standard">
        <TextField
          className="textFieldGeneric"
          readonly
          onfocus="this.removeAttribute('readonly');"
          autoComplete="off"
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          error={error}
          helperText={helperText}
          type={type}
          placeholder={placeholderId}
          id={id}
          label={labelId}
          title={title}
          labelClassName={labelClassName}
          variant={variant}
          {...props}
          InputProps={inputProps}
          inputProps={inputProps2}
          InputLabelProps={{ shrink }}
          sx={{
            width: "100%",
            marginTop: "4px",
            marginBottom: "10px",
          }}
        />
      </FormControl>
    </>
  );
};
