import { Box, Grid, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  NavigateHowItWorksPlay,
  TransformTheWayImg,
} from "helpers/enum/constants";
import { Link, useNavigate } from "react-router-dom";

const TransformTheWay = () => {
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        sx={{
          height: "300px",
          backgroundImage: `url(${TransformTheWayImg})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          borderRadius: "10px",
          border: "none",
          padding: "16px",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/app/how-it-works");
        }}
      >
        <Grid
          item
          lg={12}
          md={12}
          xs={12}
          sx={{
            textAlign: "center",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
            color: "white",
          }}
        >
          <Stack>
            <SLTypography
              role="heading"
              tabIndex={0}
              variant="headLine"
              sx={{
                fontSize: { xs: "19px", md: "22px", lg: "27px" },
                paddingLeft: { xs: "0px", md: "16px", lg: "0px" },
                paddingRight: { xs: "0px", md: "16px", lg: "0px" },
              }}
            >
              Transform the way you experience your day.
            </SLTypography>
            <SLTypography
              sx={{
                maxWidth: "500px",
                paddingTop: "15px",
                paddingBottom: "20px",
                alignSelf: "center",
                fontSize: "14px",
                paddingLeft: { xs: "30px", sm: "12px", lg: "0px" },
                paddingRight: { xs: "30px", sm: "12px", lg: "0px" },
              }}
              tabIndex={0}
            >
              Learn how Soul Link creates a meditative experience scientifically
              designed to link your Physical Self and Soul Self together.
            </SLTypography>
            <Link
              to="/app/how-it-works"
              style={{ textDecoration: "none", color: "white" }}
            >
              <Box
                sx={{
                  display: "flex",
                  direction: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  cursor: "pointer",
                  gap: "8px",
                }}
              >
                <Box
                  component="img"
                  src={NavigateHowItWorksPlay}
                  alt="/"
                  sx={{ width: "36px", height: "36px" }}
                />
                <SLTypography
                  sx={{
                    fontSize: "15px",
                    fontWeight: "600",
                  }}
                >
                  How to Use
                </SLTypography>
              </Box>
            </Link>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default TransformTheWay;
