/* eslint-disable indent */
import mixpanel from "mixpanel-browser";
import { Auth } from "../authService";
import {
  SLMediaFileDetail,
  SLMeditationDetail,
} from "../slRTKHelper/slAPI.models";
import { SLStorage } from "../slStorage";
import { MixpanelConstants } from "./MixpanelConstants";

export class Mixpanel {
  private static instance: Mixpanel;
  constants = MixpanelConstants;
  user = {
    id: "",
    name: "",
    email: "",
    loginType: SLStorage.getItem("authType"),
    registerType: "",
    age: "",
    gender: "",
  };

  public static getInstance() {
    if (!Mixpanel.instance) {
      Mixpanel.instance = new Mixpanel();

      mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN || "", {
        debug: false,
      });
    }

    return Mixpanel.instance;
  }

  login() {
    const guestProfile = Auth.guest.loggedin();
    const userProfile = Auth.user.profile();

    if (guestProfile) {
      this.user.id = guestProfile.id!.toString();
      this.user.name = "Guest";
      this.user.registerType = "Guest";
      this.user.email = "guest@guest.com";
    } else if (userProfile) {
      this.user.id = userProfile.id.toString();
      this.user.name = userProfile.fullName || "";
      this.user.email = userProfile.email || "";
      this.user.age = userProfile.dob as any;
      this.user.gender = userProfile.gender as any;
      this.user.registerType = userProfile.registerType;
    }

    if (this.user.id && this.user.id != mixpanel.get_distinct_id()) {
      mixpanel.reset();
      mixpanel.identify(this.user.id);

      mixpanel.people.set({
        [MixpanelConstants.KEYS.NAME]: this.user.name,
        [MixpanelConstants.KEYS.IS_GUEST]: this.user.loginType === "guest",
        [MixpanelConstants.KEYS.LOGGED_IN_AS]: this.user.registerType,
        [MixpanelConstants.KEYS.EMAIL]: this.user.email,
        $email: this.user.email,
        [MixpanelConstants.KEYS.AGE]: this.user.age,
        [MixpanelConstants.KEYS.GENDER]: this.user.gender,
      });
    } else {
      mixpanel.identify(this.user.id);
    }
  }

  /**
   * on meditation player page opened
   */
  triggerUserCurrentlyMeditationEvent() {
    if (!this.user.id) {
      this.login();
    }

    const json = {
      [MixpanelConstants.KEYS.TIME_STAMP]: new Date().toString(),
      [MixpanelConstants.KEYS.USER_ID]: this.user.id,
      [MixpanelConstants.KEYS.USER_NAME]: this.user.name,
    };

    this.sendEvent(MixpanelConstants.EVENTS.USER_CURRENTLY_MEDITATING, json);
  }

  /**
   * on meditation player page opened
   */
  triggerMeditationSessionStartedEvent(item: SLMeditationDetail) {
    const json = this.meditationJson(item);

    this.sendEvent(MixpanelConstants.EVENTS.MEDITATION_SESSION_STARTED, json);
  }

  /**
   * on meditation player page opened
   */
  triggerUserActiveStatusEvent() {
    if (!this.user.id) {
      this.login();
    }

    const json = {
      [MixpanelConstants.KEYS.TIME_STAMP]: new Date().toString(),
      [MixpanelConstants.KEYS.USER_ID]: this.user.id,
      [MixpanelConstants.KEYS.USER_NAME]: this.user.name,
    };

    this.sendEvent(MixpanelConstants.EVENTS.USER_ACTIVE, json);
  }

  /**
   * on meditation player page opened
   */
  triggerMeditationCompilationMostUsedEvent(item: SLMeditationDetail) {
    const json = this.meditationJson(item);

    this.sendEvent(
      MixpanelConstants.EVENTS.MEDITATION_COMPILATION_MOST_VIEWED,
      json
    );
  }

  /**
   * on full time OR when paused and completed clicked
   */
  triggerMeditationSessionCompletedEvent(item: SLMeditationDetail) {
    const json = this.meditationJson(item);

    this.sendEvent(MixpanelConstants.EVENTS.MEDITATION_SESSION_COMPLETED, json);
  }
  triggerMediaSessionCompletedEvent(
    item: SLMediaFileDetail,
    type: "audio" | "video" | "frequency"
  ) {
    const json = this.mediaIdTitleJson(item, type);

    this.sendEvent(MixpanelConstants.EVENTS.MEDIA_SESSION_COMPLETED, json);
  }

  /**
   * every second
   */
  triggerTimeSpentWatchingMeditationEvent(
    item: SLMeditationDetail,
    elapsedTime: string
  ) {
    const json = this.meditationJson(item);
    json[this.constants.KEYS.ELAPSED_TIME] = elapsedTime;

    this.sendEvent(
      MixpanelConstants.EVENTS.TIME_SPENT_WATCHING_MEDITATION,
      json
    );
  }

  /**
   * every second
   */
  incrementTimeSpentWatchingMeditation() {
    this.incrementEvent(MixpanelConstants.KEYS.MEDITATION_WATCH_TIME);
  }

  /**
   * on meditation create
   */
  triggerFrequencyUsedEvent(item: SLMediaFileDetail) {
    const json = this.mediaIdTitleJson(item, "frequency");

    this.sendEvent(MixpanelConstants.EVENTS.FREQUENCY_USED, json);
  }

  /**
   * every second
   */
  triggerTimeSpentWatchingAudioEvent(
    item: SLMediaFileDetail,
    elapsedTime: string
  ) {
    const json = this.mediaIdTitleJson(item, "audio");
    json[this.constants.KEYS.ELAPSED_TIME] = elapsedTime;

    this.sendEvent(MixpanelConstants.EVENTS.TIME_SPENT_WATCHING_AUDIO, json);
  }

  /**
   * atleast 60 seconds cumulative played
   */
  triggerAudioViewedEvent(item: SLMediaFileDetail) {
    const json = this.mediaIdTitleJson(item, "audio");

    this.sendEvent(MixpanelConstants.EVENTS.AUDIO_VIEWED, json);
  }

  /**
   * on seek & on end
   */
  triggerAudioTrackPlayedEvent(
    item: SLMediaFileDetail,
    startTime: number,
    endTime: number
  ) {
    const json = {
      ...this.mediaIdTitleJson(item, "audio"),
      [MixpanelConstants.KEYS.START_TIME]: startTime,
      [MixpanelConstants.KEYS.END_TIME]: endTime,
    };

    this.sendEvent(MixpanelConstants.EVENTS.AUDIO_TRACK_PLAYED, json);
  }

  /**
   * every second
   */
  triggerTimeSpentWatchingVideoEvent(
    item: SLMediaFileDetail,
    elapsedTime: string
  ) {
    const json = this.mediaIdTitleJson(item, "video");
    json[this.constants.KEYS.ELAPSED_TIME] = elapsedTime;

    this.sendEvent(MixpanelConstants.EVENTS.TIME_SPENT_WATCHING_VIDEO, json);
  }

  /**
   * atleast 60 seconds cumulative played
   */
  triggerVideoViewedEvent(item: SLMediaFileDetail) {
    const json = this.mediaIdTitleJson(item, "video");

    this.sendEvent(MixpanelConstants.EVENTS.VIDEO_VIEWED, json);
  }

  /**
   * on seek & on end
   */
  triggerVideoTrackPlayedEvent(
    item: SLMediaFileDetail,
    startTime: number,
    endTime: number
  ) {
    const json = {
      ...this.mediaIdTitleJson(item, "video"),
      [MixpanelConstants.KEYS.START_TIME]: startTime,
      [MixpanelConstants.KEYS.END_TIME]: endTime,
    };

    this.sendEvent(MixpanelConstants.EVENTS.VIDEO_TRACK_PLAYED, json);
  }

  /**
   * EXPLORE_ALL_FILES_FRAGMENT ✅ -- suggested: @TODO
   * ARTIST_DETAIL ✅
   * FAQ_FRAGMENT ✅
   * MY_LIST_ALL_FILES_FRAGMENT ✅
   * MY_LIST_ALL_MEDITATION_FRAGMENT ✅
   */
  triggerSearchEvent(
    screen: keyof typeof MixpanelConstants.SCREENS,
    search: string
  ) {
    const json = {
      [MixpanelConstants.KEYS.SEARCH_TEXT]: search,
      [MixpanelConstants.KEYS.SCREEN]: this.constants.SCREENS[screen],
    };

    this.sendEvent(MixpanelConstants.EVENTS.SEARCH, json);
  }

  /**
   * HOME --- 'Choose ' & audio|video|frequencyWave ✅
   * CREATE --- 'Choose ' & audio|video|frequencyWave ✅
   * HOME_SCREEN_MY_LIST ✅
   * ARTIST_DETAIL ✅
   * EXPLORE_ALL_FILES_FRAGMENT -- audio|video|frequencyWave ✅
   * FAQ_FRAGMENT ✅
   * MEDITATION_DETIAL_FRAGMENT ✅
   * MY_LIST_FRAGMENT ✅
   */
  triggerTagUsedEvent(
    screen: keyof typeof MixpanelConstants.SCREENS,
    tagId: string | number,
    tagTitle: string
  ) {
    const json = {
      [MixpanelConstants.KEYS.SCREEN]: this.constants.SCREENS[screen],
      [MixpanelConstants.KEYS.TAG_ID]: tagId,
      [MixpanelConstants.KEYS.TAG_TITLE]: tagTitle,
    };

    this.sendEvent(MixpanelConstants.EVENTS.TAG_USED, json);
  }

  /**
   * On cancel
   */
  triggerSubscriptionCancelledEvent(planId: string | number) {
    const json = {
      [MixpanelConstants.KEYS.SUBSCRIPTION_PLAN_ID]: planId,
    };

    this.sendEvent(MixpanelConstants.EVENTS.SUBSCRIPTION_CANCELED, json);
  }

  /**
   * Contact us
   */
  triggerUserFeedbackEvent(fullName: string, email: string, message: string) {
    const json = {
      [MixpanelConstants.KEYS.FULL_NAME]: fullName,
      [MixpanelConstants.KEYS.EMAIL]: email,
      [MixpanelConstants.KEYS.MESSAGE]: message,
    };

    this.sendEvent(MixpanelConstants.EVENTS.USER_FEEDBACK, json);
  }

  /**
   * On purchase
   * ❌ implemented on backend: technical limitation
   *
   * @param string planId;
   * @param string startDate As coming from backend;
   * @param string endDate As coming from backend;
   */
  triggerSubscriptionSelectedEvent(
    planId: string | number,
    startDate: string,
    endDate: string
  ) {
    const json = {
      [MixpanelConstants.KEYS.SUBSCRIPTION_PLAN_ID]: planId,
      [MixpanelConstants.KEYS.START_DATE]: startDate,
      [MixpanelConstants.KEYS.END_DATE]: endDate,
    };

    this.sendEvent(MixpanelConstants.EVENTS.SUBSCRIPTION_PURCHASED, json);
  }

  /**
   * ❌ skip for now: technical limitation
   * on website exit
   */
  triggerLastScreenEvent(screen: typeof MixpanelConstants.SCREENS) {
    const json = {
      [MixpanelConstants.KEYS.SCREEN]: screen,
    };

    this.sendEvent(MixpanelConstants.EVENTS.LAST_PAGE, json);
  }

  /**
   * ❌ skip for now: technical limitation
   * send event on meditation start
   * restart timer on player page ext;
   */
  triggerTimeSpentNotMeditating(watchTime: string) {
    const json = {
      [MixpanelConstants.KEYS.USE_TIME]: watchTime,
    };

    this.sendEvent(MixpanelConstants.EVENTS.TIME_SPENT_NOT_MEDITATION, json);
  }

  trackPageview() {
    (mixpanel as any).track_pageview();
  }

  private mediaIdTitleJson(
    item: SLMediaFileDetail,
    type: "audio" | "video" | "frequency"
  ) {
    switch (type) {
      case "audio":
        return {
          [MixpanelConstants.KEYS.AUDIO_ID]: item.id,
          [MixpanelConstants.KEYS.AUDIO_TITLE]: item.title,
        };

      case "video":
        return {
          [MixpanelConstants.KEYS.VIDEO_ID]: item.id,
          [MixpanelConstants.KEYS.VIDEO_TITLE]: item.title,
        };

      case "frequency":
        return {
          [MixpanelConstants.KEYS.FREQUENCY_ID]: item.id,
          [MixpanelConstants.KEYS.FREQUENCY_TITLE]: item.title,
        };
    }
  }

  private meditationJson(item: SLMeditationDetail) {
    const json = {
      [MixpanelConstants.KEYS.MEDITATION_ID]: item.id,
      [MixpanelConstants.KEYS.MEDITATION_TITLE]: item.title,
    };

    if (item?.audio?.id) {
      json[MixpanelConstants.KEYS.AUDIO_ID] = item.audio.id;
      json[MixpanelConstants.KEYS.AUDIO_TITLE] = item.audio.title;
    }

    if (item?.video?.id) {
      json[MixpanelConstants.KEYS.VIDEO_ID] = item.video.id;
      json[MixpanelConstants.KEYS.VIDEO_TITLE] = item.video.title;
    }

    if (item?.frequencyWave?.id) {
      json[MixpanelConstants.KEYS.FREQUENCY_ID] = item.frequencyWave.id;
      json[MixpanelConstants.KEYS.FREQUENCY_TITLE] = item.frequencyWave.title;
    }
    return json;
  }

  private sendEvent(key: string, params: Record<string, any>) {
    mixpanel.track(key, params);
  }

  private incrementEvent(key: string) {
    mixpanel.people.increment(key, 1);
  }
}
