import { getAuth, reauthenticateWithPopup } from "@firebase/auth";
import { LockResetOutlined } from "@mui/icons-material";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Avatar,
  Box,
  Fade,
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Stack,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { lang } from "helpers/common/lang";
import { UserRegisterType } from "helpers/enum";
import { BorderColorOutlined, DefaultUserImage } from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useState } from "react";
import { Props } from "react-intl/src/components/relative";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Auth } from "utils/authService";
import {
  appleProvider,
  deviceId,
  deviceInfo,
  fbprovider,
  googleProvider,
} from "utils/firebase";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import ErrorDialogBox from "../../../dialogBoxes/errorDialog";
import { SLLoader } from "../../../loader";
import { SLButton } from "../../../slButton";
import { InputStandardField } from "../../../slTextField";
import { SLVerificationMessage } from "../../../verificationMessage";
import EditAccountInfo from "../../editAccountInfo/accountInfo";
import ChangePasswordModal from "../../editAccountInfo/changepassword";
import ChangePassword from "../changePassword";
import "./index.css";
dayjs.extend(utc);

//interface
type IAccountSettingScreen = {
  getUserProfile: any;
} & Props;

const AccountSettingScreen = ({ getUserProfile }: IAccountSettingScreen) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const appUrls = useAppUrls();

  const { data, loading, error } = useSelector(SLSelector.deleteUser);
  const deletedSocialAccount = useSelector(SLSelector.deleteUserSocial);
  const loginWithGuestUser = useSelector(SLSelector.postAuthGuestLogin);

  // Manage States
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [deleteErrorDialog, setDeleteErrorDialog] = useState(false);
  const [deletedAccSuccess, setDeletedAccSuccess] = useState(false);
  const [socialLoginUser, setsocialLoginUser] = useState(false);
  const [deleteAccountToggle, setDeleteAccountToggle] = useState(false);
  const [validationError, setValidationError] = useState(
    "Fill the obligatory field, please"
  );
  const [accessToken, setAccessToken] = useState("");
  const [displayError, setDisplayError] = useState(false);
  const [socialUser, setSocialUser] = useState(Object);
  const [formData, setFormData] = useState({
    Password: "",
  });
  const [errorMessage, setErrorMessage] = useState({
    header: "Oops!",
    message: "",
  });
  const [loader, setLoader] = useState(false);
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [openPasswordDialogBox, setOpenPasswordDialogBox] = useState(false);

  useEffect(() => {
    dispatch(SLThunk.getUserProfile.call());
  }, []);

  const deleteAccount = (e: any) => {
    setDeleteAccountToggle(true);
    e.preventDefault();
    if (
      (!formData.Password || formData.Password == "") &&
      getUserProfile.registerType === UserRegisterType.EmailRegister
    ) {
      setDisplayError(true);
      return;
    }
    if (!socialLoginUser) {
      dispatch(
        SLThunk.deleteUser.call({
          password: formData.Password,
        })
      );
    } else {
      reauthenticateUser(getUserProfile.registerType);
    }
    setDeleteErrorDialog;
    setDisplayError(false);
  };

  const initialStage = () => {
    setDeleteDialog(false);
    formData.Password = "";
    setShowPassword(false);
    setDeleteErrorDialog(false);
    setDeletedAccSuccess(false);
    dispatch(SLThunk.deleteUser.reset());
  };

  const popupOpenProvider = async (provider: any) => {
    await reauthenticateWithPopup(socialUser, provider)
      .then((result) => {
        setLoader(false);
        dispatch(
          SLThunk.deleteUserSocial.call({
            socialId: accessToken,
          })
        );
      })
      .catch((error) => {
        setLoader(false);
        setDeleteErrorDialog(true);
        // console.error(" error generated : ", error);
        if (error.code === "auth/user-mismatch") {
          setErrorMessage({
            ...errorMessage,
            message: "Invalid Social user",
          });
        } else {
          setErrorMessage({
            ...errorMessage,
            message: "This operation was cancelled by the user.",
          });
        }
      });
  };

  const reauthenticateUser = (registerType: string) => {
    let provider: any;
    setLoader(true);
    if (registerType === UserRegisterType.GoogleRegister) {
      provider = googleProvider;
    } else if (registerType === UserRegisterType.AppleRegister) {
      provider = appleProvider;
    } else {
      provider = fbprovider;
    }
    popupOpenProvider(provider);
  };

  const deleteUserAccount = async () => {
    if (getUserProfile?.registerType != UserRegisterType.EmailRegister) {
      setsocialLoginUser(true);
      const user = getAuth().currentUser;
      setSocialUser(user);
      const token = await user?.getIdToken();
      setAccessToken(token ?? "");
    } else {
      setsocialLoginUser(false);
    }
    setDeleteDialog(true);
  };
  const deleteAccountPassword = (e: any) => {
    const passowrdValue = e.target.value;
    setFormData({
      Password: passowrdValue,
    });
    e.target.value == "" ? setDisplayError(true) : setDisplayError(false);
  };
  //Manage UseEffects

  useEffect(() => {
    if (loading == false && error) {
      setDeleteErrorDialog(true);
      setErrorMessage({
        ...errorMessage,
        message: "The password is incorrect",
      });
    } else if ((loading == false && data) || deletedSocialAccount.data) {
      setDeleteDialog(false);
      setDeletedAccSuccess(true);
      dispatch(SLThunk.getUserProfile.reset());
      dispatch(
        SLThunk.postAuthGuestLogin.call({
          platform: "web",
          deviceInfo,
          deviceId,
        })
      );
      setDeletedAccSuccess(false);
      setDeleteErrorDialog(false);
      SLStorage.removeItem("UserAppFirst");
      SLStorage.removeItem("signupCompleted");
      Auth.user.logout();
      dispatch(SLThunk.deleteUserSocial.reset());
      dispatch(SLThunk.deleteUser.reset());
      dispatch(SLThunk.getUserProfile.reset());
      dispatch(SLThunk.postAuthLoginSocial.reset());
      dispatch(SLThunk.postAuthRegisterSocial.reset());
      dispatch(SLThunk.postFileUploadUserimageSocial.reset());
      dispatch(SLThunk.patchUserUpdateProfile.reset());
      dispatch(SLThunk.postAuthGuestLogin.reset());
      setTimeout(() => {
        navigate("/app/home");
      }, 1000);
    }
  }, [data, error, deletedSocialAccount.data, deletedSocialAccount.error]);

  useEffect(() => {
    if (deletedSocialAccount.data) {
      dispatch(SLThunk.deleteUserSocial.reset());
    }
  }, [deletedSocialAccount]);

  useEffect(() => {
    if (loginWithGuestUser.data) {
      SLStorage.setItem("guestLogin", true);
      dispatch(SLThunk.postAuthGuestLogin.reset());
    }
  }, [loginWithGuestUser]);

  return (
    <>
      <Box
        className="p-relative"
        sx={{
          color: "#4B2B5A",
          background: "var( --colorWhite)",
          fontSize: "15px",
          fontWeight: "400",
          padding: { lg: "30px", xs: "16px" },
          borderRadius: "10px",
          lineHeight: "43px",
          paddingBottom: { xs: "6px" },
        }}
      >
        <Grid container gap={2}>
          <Grid item lg={3} xs={12}>
            <Avatar
              tabIndex={0}
              alt="Your profile picture"
              src={appUrls.profilePic(getUserProfile?.image, DefaultUserImage)}
              sx={{
                width: { xl: "200px", xs: "180px" },
                height: { xl: "200px", xs: "180px" },
              }}
            />
          </Grid>
          <Grid item lg={4} xs={12}>
            <Stack>
              <SLTypography
                sx={{
                  fontSize: { xs: "20px", lg: "30px" },
                  fontWeight: "600",
                  paddingBottom: { xs: "15px", sm: "20px" },
                  lineHeight: "32.4px",
                }}
                tabIndex={0}
              >
                {getUserProfile?.fullName || "--"}
              </SLTypography>
              <SLTypography
                tabIndex={0}
                messageId="profile.userEmail"
                sx={{
                  fontSize: { xs: "13px", lg: "14px" },
                  fontWeight: "500",
                  lineHeight: "13px",
                }}
              />
              <SLTypography
                sx={{
                  fontSize: { xs: "15px", md: "17px" },
                  fontWeight: "600",
                  lineHeight: "22px",
                  wordWrap: "break-word",
                }}
                tabIndex={0}
              >
                {getUserProfile?.email || "--"}
              </SLTypography>
              <SLTypography
                messageId="profile.dateOfBirth"
                sx={{
                  paddingTop: { xs: "15px", md: "20px" },
                  fontSize: { xs: "13px", lg: "14px" },
                  fontWeight: "500",
                  lineHeight: "13px",
                }}
                tabIndex={0}
              />
              <SLTypography
                sx={{
                  fontSize: { xs: "15px", md: "17px" },
                  fontWeight: "600",
                  lineHeight: "22px",
                }}
                tabIndex={0}
              >
                {getUserProfile?.dob
                  ? dayjs.utc(getUserProfile?.dob).format("LL")
                  : "--"}
              </SLTypography>
              <SLTypography
                messageId="profile.phoneNumber"
                sx={{
                  paddingTop: { xs: "15px", md: "20px" },
                  fontSize: { xs: "13px", lg: "14px" },
                  fontWeight: "500",
                  lineHeight: "13px",
                }}
                tabIndex={0}
              />
              <SLTypography
                sx={{
                  fontSize: { xs: "15px", md: "17px" },
                  fontWeight: "600",
                  lineHeight: "22px",
                }}
                tabIndex={0}
              >
                {getUserProfile?.phone || "--"}
              </SLTypography>
            </Stack>
          </Grid>
          <Grid item lg={4} xs={12} sx={{ paddingTop: { xs: 0, lg: "32px" } }}>
            <SLTypography
              messageId="profile.gender"
              sx={{
                paddingTop: { xs: "15px", md: "20px" },
                fontSize: { xs: "13px", lg: "14px" },
                fontWeight: "500",
                lineHeight: "13px",
              }}
              tabIndex={0}
            />
            <SLTypography
              tabIndex={0}
              sx={{
                fontSize: { xs: "15px", md: "17px" },
                fontWeight: "600",
                lineHeight: "22px",
              }}
            >
              {getUserProfile?.gender || "--"}
            </SLTypography>
            <SLTypography
              sx={{
                paddingTop: { xs: "15px", md: "20px" },
                fontSize: { xs: "13px", lg: "14px" },
                fontWeight: "500",
                lineHeight: "13px",
              }}
              tabIndex={0}
            >
              State
            </SLTypography>
            <SLTypography
              sx={{
                fontSize: { xs: "15px", md: "17px" },
                fontWeight: "600",
                lineHeight: "22px",
              }}
              tabIndex={0}
            >
              {getUserProfile?.state || "--"}
            </SLTypography>
            <SLTypography
              sx={{
                paddingTop: { xs: "15px", md: "20px" },
                fontSize: { xs: "13px", lg: "14px" },
                fontWeight: "500",
                lineHeight: "13px",
              }}
              tabIndex={0}
            >
              City
            </SLTypography>
            <SLTypography
              sx={{
                fontSize: { xs: "15px", md: "17px" },
                fontWeight: "600",
                lineHeight: "22px",
              }}
              tabIndex={0}
            >
              {getUserProfile?.city || "--"}
            </SLTypography>
          </Grid>
        </Grid>

        <Box
          sx={{
            position: "absolute",
            right: "0.5rem",
            top: "0.5rem",
          }}
        >
          <IconButton
            tabIndex={0}
            onClick={() => setOpenDialogBox(true)}
            onKeyPress={() => setOpenDialogBox(true)}
            aria-label="press key to edit account"
            sx={{ cursor: "pointer" }}
          >
            <BorderColorOutlined sx={{ fontSize: "24px" }} />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={getUserProfile?.registerType !== "email" ? { opacity: 0.5 } : {}}
        onClick={() => {
          getUserProfile?.registerType === "email"
            ? setOpenPasswordDialogBox(true)
            : "";
        }}
        onKeyPress={() => {
          getUserProfile?.registerType === "email"
            ? setOpenPasswordDialogBox(true)
            : "";
        }}
        tabIndex={0}
      >
        <ChangePassword
          icon={
            <LockResetOutlined
              sx={{
                fontSize: { lg: "24px", md: "24px", sm: "22px", xs: "20px" },
              }}
            />
          }
          messageId={"general.changePassword"}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center", md: "flex-start" },
          justifyContent: { xs: "center", md: "flex-start" },
        }}
      >
        <SLButton
          sx={{
            marginTop: { lg: "20px", xs: "15px" },
            width: { lg: "200px", md: "200px", sm: "190px", xs: "180px" },
            background: "#514C54 !important",
            padding: "17px 14px !important",
            marginBottom: { lg: "32px", sm: "25px", xs: "27px" },
          }}
          className="profile-delete-btn"
          messageId={"profile.deleteAccount"}
          onClick={() => deleteUserAccount()}
          onKeyPress={() => deleteUserAccount()}
        />
      </Box>

      <EditAccountInfo
        openDialog={openDialogBox}
        setOpenDialog={setOpenDialogBox}
      />
      <ChangePasswordModal
        openDialog={openPasswordDialogBox}
        setOpenDialog={setOpenPasswordDialogBox}
      />
      <Modal
        id="delete-account-modal"
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={deleteDialog}
        closeAfterTransition
      >
        <Fade in={deleteDialog}>
          <Box
            className="delete-dialog-style"
            gap={2}
            padding={3}
            component="form"
            autoComplete="off"
            onSubmit={(e) => {
              deleteAccount(e);
            }}
            tabIndex={0}
            sx={{
              width: { sm: "400px", xs: "81%" },
              right: { lg: " 40%", xs: "0%" },
              top: { lg: " 27%", xs: "10%" },
              bottom: "20%",
              maxHeight: "390px",
              height: "fit-content",
              display: " flex",
              flexDirection: "column",
              background: "linear-gradient(180deg, #F0E1D9 0%, #EFF2F3 58.85%)",
              borderRadius: "10px",
            }}
          >
            <SLTypography
              tabIndex={0}
              variant="subhead"
              sx={{
                color: "var(--sl-deep-purple)",
                fontSize: { xs: "19px", md: "17px" },
              }}
            >
              Are you sure you want to delete your Soul Link account?
            </SLTypography>
            <SLTypography
              tabIndex={0}
              variant="smallBodyCopy"
              sx={{
                color: "var(--sl-light-text)",
                fontSize: { xs: "12px", md: "14px" },
              }}
            >
              {socialLoginUser
                ? lang("profile.deleteAccountConfirmationSocial")
                : lang("profile.deleteAccountConfirmation")}
            </SLTypography>
            {!socialLoginUser ? (
              <InputStandardField
                value={formData.Password}
                error={displayError ? true : false}
                id="deleteAccount"
                labelId="Password"
                type={showPassword ? "text" : "password"}
                variant="filled"
                props={{
                  className: "forgot-password-email-input",
                }}
                inputProps={{
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onKeyPress={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        {!showPassword ? (
                          <VisibilityOffOutlinedIcon
                            sx={{ color: "var(--sl-deep-purple)" }}
                          />
                        ) : (
                          <VisibilityOutlinedIcon
                            sx={{ color: "var(--sl-deep-purple)" }}
                          />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  onChange: (e: any) => deleteAccountPassword(e),
                }}
              />
            ) : null}
            {displayError && !socialLoginUser ? (
              <SLTypography
                tabIndex={0}
                style={{ textAlign: "left", color: "var(--sl-purple)" }}
              >
                {validationError}
              </SLTypography>
            ) : null}

            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap={1}
            >
              <SLButton
                type="submit"
                fullWidth
                messageId={"general.delete"}
                // onClick={() => {
                //   deleteAccount();
                // }}
              />
              <SLButton
                slClass="none"
                variant="text"
                sx={{
                  height: "28px !important",
                  color: "var(--sl-deep-purple)",
                  textDecoration: "underline",
                  " &:hover": {
                    textDecoration: "underline",
                    backgroundColor: "transparent",
                    fontSize: { xs: "12px", md: "14px" },
                  },
                }}
                onClick={() => initialStage()}
                onKeyPress={() => initialStage()}
                messageId={"general.cancel"}
              />
            </Box>
          </Box>
        </Fade>
      </Modal>
      <ErrorDialogBox
        errorDialog={deleteErrorDialog}
        setErrorDialog={setDeleteErrorDialog}
        errorMessage={errorMessage.message}
        errorHeaderMessage={errorMessage.header}
      />
      {/* Error Display Delete Input field */}
      {deleteAccountToggle && (
        <SLVerificationMessage
          open={deletedAccSuccess}
          sx={{ zIndex: 9999 }}
          message="Account deleted"
        />
      )}
      <SLLoader open={loading || deletedSocialAccount.loading || loader} />
    </>
  );
};

export default AccountSettingScreen;
