import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, Card, Skeleton } from "@mui/material";
import { SLTypography } from "components/slTypography";
import dayjs from "dayjs";
import { SendIcon2 } from "helpers/enum/constants";
import { SupportRequest } from "utils/slRTKHelper/slAPI.models";
import { ShowMoreText } from "../../../showMoreText";

interface ISupportCard {
  data?: SupportRequest;
  loading?: boolean;
}

const statusMap = (status: string) => {
  const map: Record<string, string> = {
    open: "Open",
    inProcess: "In Process",
    close: "Closed",
  };

  return map[status] || status;
};

export default function SupportCard({ data, loading }: ISupportCard) {
  return (
    <Card
      tabIndex={0}
      role="listitem"
      aria-label="Support Request Item"
      sx={{ boxShadow: "none", borderRadius: "10px" }}
    >
      <Box sx={{ display: "block", padding: "25px 30px" }}>
        <SLTypography
          color="text-deepPurple"
          variant="extraSmallBody"
          tabIndex={0}
        >
          {loading ? (
            <Skeleton
              sx={{
                transform: "unset",
                width: "5rem",
              }}
            />
          ) : data?.createdAt ? (
            dayjs(data.createdAt).format("LL")
          ) : (
            "-"
          )}
        </SLTypography>
      </Box>

      <Box
        className="request-wrapper"
        sx={{ borderTop: "1px solid #E7E7E7", padding: "25px 30px" }}
      >
        <Box
          className="card-header"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "var(--sl-deep-purple)",
            gap: "8px",
            marginBottom: "15px",
          }}
          tabIndex={0}
        >
          <Box component="img" src={SendIcon2} />
          <SLTypography variant="subhead">Your Request</SLTypography>
        </Box>

        <ShowMoreText text={data?.message} lines={2} loading={loading} />
      </Box>

      <Box
        className="response-wrapper"
        sx={{ borderTop: "1px solid #E7E7E7", padding: "25px 30px" }}
      >
        <Box
          className="card-header"
          sx={{
            display: "flex",
            alignItems: "center",
            color: "var(--sl-aqua)",
            gap: "8px",
            marginBottom: "15px",
          }}
          title="Support Response"
          tabIndex={0}
        >
          <SupportAgentIcon />
          <SLTypography variant="subhead" color="text-aqua">
            Support Response
          </SLTypography>
        </Box>
        <ShowMoreText
          text={
            "<b>" +
            statusMap(data?.status || "") +
            "</b>" +
            (data?.answer ? ": " : " ") +
            (data?.answer || "")
          }
          lines={2}
          loading={loading}
        />
      </Box>
    </Card>
  );
}
