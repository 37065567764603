import { Box } from "@mui/material";
import {
  AppBarCreate,
  AppBarCreateWhite,
  AppBarExplore,
  AppBarExploreWhite,
  AppBarHome,
  AppBarHomeWhite,
  AppBarMyList,
  AppBarMyListWhite,
} from "helpers/enum/constants";
import { NavigationLink } from "./NavigationLink";

const NAV_LINKS = [
  {
    navigateTo: "/app/home",
    title: "Home",
    whiteIcon: AppBarHomeWhite,
    purpleIcon: AppBarHome,
  },
  {
    title: "Create",
    navigateTo: "/app/meditation/create",
    whiteIcon: AppBarCreateWhite,
    purpleIcon: AppBarCreate,
  },
  {
    title: "Explore",
    navigateTo: "/app/explore",
    whiteIcon: AppBarExploreWhite,
    purpleIcon: AppBarExplore,
  },
  {
    title: "My List",
    navigateTo: "/app/my-list",
    whiteIcon: AppBarMyListWhite,
    purpleIcon: AppBarMyList,
  },
];

const HeaderNavigationLinks = ({ fromDrawer = false, closeDrawer }: any) => {
  const isWhiteIconPage =
    !fromDrawer &&
    (location.pathname === "/app/home" ||
      location.pathname.includes("/app/meditation/create") ||
      location.pathname === "/app/explore" ||
      location.pathname === "/app/how-it-works" ||
      location.pathname.includes("/app/meditation-details") ||
      location.pathname.includes("/app/file-detail") ||
      location.pathname === "/app/my-list");

  return (
    <>
      <Box
        sx={{
          display: {
            xs: fromDrawer ? "flex" : "none",
            sm: "flex",
          },
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "1rem",
        }}
      >
        {NAV_LINKS.map((r) => (
          <NavigationLink
            isWhiteIconPage={isWhiteIconPage}
            fromDrawer={fromDrawer}
            closeDrawer={closeDrawer}
            key={r.title}
            navigateTo={r.navigateTo}
            title={r.title}
            whiteIcon={r.whiteIcon}
            purpleIcon={r.purpleIcon}
          />
        ))}
      </Box>
    </>
  );
};

export default HeaderNavigationLinks;
