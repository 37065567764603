import { createSlice } from "@reduxjs/toolkit";
import { isIOS, isIPad13, isMobile, isSafari } from "react-device-detect";

interface IWebShareInitialState {
  show: boolean;
  title?: string;
  text?: string;
  url?: string;
}

interface OpenReducerPayload {
  payload: {
    title?: string;
    text?: string;
    url?: string;
  };
}

const initialState: IWebShareInitialState = {
  show: false,
  title: "",
  text: "",
  url: "",
};
const webShareSlice = createSlice({
  name: "webShare",
  initialState,
  reducers: {
    open: (state, { payload }: OpenReducerPayload) => {
      if (
        Boolean(window.navigator.share) &&
        (isIOS || isMobile || isIPad13 || isSafari)
      ) {
        navigator.share({
          text: (payload?.title || "")
            .concat("\n")
            .concat(payload?.text || "")
            .concat("\n")
            .concat(payload?.url || "")
            .trim(),
        });

        return state;
      }

      return {
        show: true,
        title: payload?.title || "",
        text: payload?.text || "",
        url: payload?.url || "",
      };
    },
    close: (state) => {
      state.show = false;
    },
  },
});

export default webShareSlice.reducer;
export const webShareState = (state: any) =>
  state.webShare as IWebShareInitialState;
export const { open: webShareOpen, close: webShareClose } =
  webShareSlice.actions;
