import SupernalWorld from "components/aboutpages/supernalWorld";
import AboutPages from "../../../layouts/aboutPages";

const SupernalWorldCreation = () => {
  return (
    <AboutPages title="Supernal World">
      <SupernalWorld />
    </AboutPages>
  );
};

export default SupernalWorldCreation;
