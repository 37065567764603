import { Avatar, Box, Skeleton, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { onEnterOrSpace } from "helpers/common/keypress";
import { DefaultUserImage } from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { ParagraphWithShowMoreSkeleton } from "../../skeletonUI/ParagraphWithShowMoreSkeleton";

export function ArtistDetail(props: any) {
  const appUrls = useAppUrls();

  return (
    <Box
      role="listitem"
      id={"artist-" + props.index}
      onKeyPress={onEnterOrSpace(() => props.viewArtist(props.artist))}
      tabIndex={0}
      sx={{
        padding: { md: "30px 0px", xs: "16px 0px" },
        borderBottom: "1px solid var(--colorLightGray)",
      }}
    >
      <Stack direction={{ lg: "row", xs: "column" }} spacing={3}>
        <Stack
          aria-hidden={true}
          sx={{
            display: "flex",
            justifyContent: { md: "start", xs: "center" },
            alignItems: { md: "start", xs: "center" },
            paddingTop: { md: "", xs: "9px" },
            marginBottom: { md: "6px", xs: "-10px" },
          }}
        >
          {props.loading ? (
            <Skeleton
              variant="circular"
              sx={{
                width: { lg: "200px", md: "180px", xs: "120px" },
                height: { lg: "200px", md: "180px", xs: "120px" },
              }}
            />
          ) : (
            <Avatar
              sx={{
                width: { lg: "200px", md: "180px", xs: "120px" },
                height: { lg: "200px", md: "180px", xs: "120px" },
              }}
              alt={props.artist.fullName}
              src={appUrls.profilePic(props.artist.image) || DefaultUserImage}
            />
          )}
        </Stack>
        <Stack
          direction="column"
          sx={{
            marginBottom: "27px",
            display: "flex",
            justifyContent: { md: "start", xs: "center" },
            alignItems: { md: "start", xs: "center" },
          }}
        >
          <SLTypography
            variant="heading"
            aria-label={
              props?.artist?.fullName
                ? "Author's name: " + props.artist.fullName + ", "
                : ""
            }
            sx={{
              textAlign: "center",
              color: "var(--sl-deep-purple)",
              marginTop: { lg: "57px", md: "0px", xs: "10px" },
              paddingBottom: "10px",
            }}
          >
            {props.loading ? (
              <Skeleton
                sx={{
                  transform: "unset",
                  display: "inline-block",
                  width: "15rem",
                  height: "37px",
                }}
              />
            ) : (
              props.artist.fullName
            )}
          </SLTypography>
          <SLTypography
            variant="smallBody"
            color="text-deep-purple"
            aria-label={
              props?.artist?.specialization
                ? "Job title: " + props.artist.specialization + ", "
                : ""
            }
          >
            {props.loading ? (
              <Skeleton
                sx={{
                  transform: "unset",
                  display: "inline-block",
                  width: "10rem",
                }}
              />
            ) : props.artist.specialization ? (
              props.artist.specialization
            ) : (
              "Job Title"
            )}
          </SLTypography>

          {props.loading ? (
            <div style={{ width: "100%", marginTop: "20px" }}>
              <ParagraphWithShowMoreSkeleton fontSize={14} />
            </div>
          ) : (
            <>
              <SLTypography
                variant="smallBodyCopy"
                sx={{
                  color: "#514C54",
                  width: { xs: "350px", sm: "100%" },
                  textAlign: { xs: "center", md: "left" },
                  paddingTop: { md: "15px", xs: "0" },
                }}
              >
                {props.artist.about}
              </SLTypography>
              <SLTypography
                sx={{
                  color: "var(--sl-purple)",
                  textDecoration: "underline",
                  cursor: "pointer",
                  width: "fit-content",
                  paddingTop: { md: "15px", xs: "6px" },
                }}
                onClick={() => props.viewArtist(props.artist)}
                tabIndex={0}
                aria-label="Tap to open more detail of this author"
              >
                More Details
              </SLTypography>
            </>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
