import { Box, Container, Divider, Grid } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  AudioCardHowItWorks,
  AudioMusicIcon,
  FequencyWavesCardHowItWorks,
  FrequencyMusicIcon,
  HowToUseAltHeader,
  VideoCardHowItWorks,
  VideoCardHowItWorksStatic,
  VideoPlayMusicIcon,
} from "helpers/enum/constants";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { reduceMotionState } from "store/reduceMotionSlice";
import ExploreHeader from "../explore/heroHeader";
import HowItWorksCard from "./card";
import SoulBenefits from "./soulBenefits";

const HowItWorksScreen = () => {
  const reduceMotion = useSelector(reduceMotionState);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Box sx={{ minHeight: "100vh" }}>
      <Box>
        <ExploreHeader
          staticImage={HowToUseAltHeader}
          videoSource="/assets/videos/how-to-use.webm"
        />
        <Box
          sx={{
            background: "var(--bg-gradient)",
            borderRadius: "20px 20px 0px 0px",
            position: "relative",
            top: "-15px",
          }}
        >
          <Container
            className="page-container"
            sx={{
              width: { xl: "93.2%", lg: "95%" },
              paddingTop: "50px",
            }}
            maxWidth={false}
          >
            <SLTypography
              role="heading"
              tabIndex={0}
              variant="largeHeader"
              sx={{
                color: "var(--sl-deep-purple)",
                fontSize: {
                  xl: "37px",
                  lg: "37px",
                  md: "35px",
                  sm: "30px",
                  xs: "28px",
                },
                letterSpacing: "-1px",
              }}
              title="how to use"
            >
              How to Use
            </SLTypography>

            <Grid
              container
              sx={{
                paddingTop: {
                  xl: "20px",
                  lg: "20px",
                  md: "20px",
                  sm: "7px",
                  xs: "4px",
                },
                color: "var(--sl-deep-purple)",
              }}
            >
              <Grid item lg={9} xs={12}>
                <SLTypography
                  tabIndex={0}
                  variant="homeParagraphText"
                  sx={{
                    color: "var(--sl-deep-purple)",
                    fontSize: {
                      xl: "21px",
                      lg: "21px",
                      md: "21px",
                      sm: "15px",
                      xs: "14px",
                    },
                    lineHeight: { xs: "22px", sm: "30px" },
                  }}
                >
                  Soul Link puts the power of life-changing transcendence in the
                  palm of your hand. Through our unique blend of formulas that
                  combine audio, visual and, frequency waves, users will go
                  deeper than ordinary meditations to heal like never before. Be
                  it rebuilding balance and regaining confidence or repairing
                  relationships and restoring health, our sound and visual
                  healing experience ignites a wide variety of long-lasting
                  benefits – so no matter the need, you have the tools to
                  transform your life.
                </SLTypography>
              </Grid>
            </Grid>
            <Divider
              sx={{
                margin: {
                  xl: "50px 0px",
                  lg: "50px 0px",
                  md: "50px 0px",
                  sm: "20px 0px",
                  xs: "20px 0px",
                },
              }}
            />
            <SLTypography
              role="heading"
              tabIndex={0}
              variant="heading"
              sx={{
                color: "var(--sl-deep-purple)",
                fontSize: {
                  xl: "27px",
                  lg: "27px",
                  md: "27px",
                  sm: "20px",
                  xs: "19px",
                },
                letterSpacing: "-.3px",
              }}
            >
              A highly formulated embodiment of 3 aspects that effectively
              imprint on the mind.
            </SLTypography>
            <Grid
              container
              spacing={"0px"}
              direction="row"
              alignItems="stretch"
              justifyContent="start"
              sx={{
                paddingTop: {
                  xl: "50px",
                  lg: "50px",
                  md: "50px",
                  sm: "20px",
                  xs: "20px",
                },
                gap: "10px",
              }}
            >
              <Grid
                item
                // lg={4}
                md={3.9}
                sm={12}
                xs={12}
              >
                <HowItWorksCard
                  tabIndex={0}
                  imageSrc={AudioCardHowItWorks}
                  title="Audio"
                  iconImage={AudioMusicIcon}
                  alt="audio card image"
                  discription="Our unique blend of ancient auditory progressions raise vibrations in the brain and enhance your emotional, mental, and physical performance."
                />
              </Grid>
              <Grid
                item
                // lg={4}
                md={3.9}
                sm={12}
                xs={12}
                sx={{
                  marginTop: {
                    xl: "0px",
                    lg: "0px",
                    md: "0px",
                    sm: "16px",
                    xs: "16px",
                  },
                }}
              >
                <HowItWorksCard
                  alt="video card image"
                  tabIndex={0}
                  imageSrc={
                    reduceMotion
                      ? VideoCardHowItWorksStatic
                      : VideoCardHowItWorks
                  }
                  title="Video"
                  iconImage={VideoPlayMusicIcon}
                  discription="Immersive imagery creates a timeless experience of calming landscapes, cosmic journeys, and cinematic escapes that transport your soul to new sceneries."
                />
              </Grid>
              <Grid
                item
                // lg={4}
                md={3.9}
                sm={12}
                xs={12}
                sx={{
                  marginTop: {
                    xl: "0px",
                    lg: "0px",
                    md: "0px",
                    sm: "16px",
                    xs: "16px",
                  },
                }}
              >
                <HowItWorksCard
                  imageSrc={FequencyWavesCardHowItWorks}
                  title="Frequency Waves"
                  iconImage={FrequencyMusicIcon}
                  discription="Transformative frequency files stimulate the brain and induce specific states of consciousness designed to calm anxiety, reduce stress, and improve cognition.
                  "
                  alt="frequency card image"
                  tabIndex={0}
                />
              </Grid>
            </Grid>
            <Divider sx={{ margin: "50px 0px" }} />
            <SoulBenefits />
          </Container>
        </Box>
      </Box>
    </Box>
  );
};

export default HowItWorksScreen;
