import { Box, Grid, ListItem, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import dayjs from "dayjs";
import { debounce } from "helpers/common/debounce";
import { onEnterOrSpace } from "helpers/common/keypress";
import { lang } from "helpers/common/lang";
import {
  MyProfileAcheivementSelected,
  MyProfileAcheivementUnSelected,
  TrashSvg,
} from "helpers/enum/constants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { SLThunk } from "utils/slRTKHelper";
import { ICustomAchievement } from "utils/slRTKHelper/slAPI.models";
import SLDialog from "../../../../../dialogBoxes/slDialog";
import { useDialog } from "../../../../../dialogBoxes/slDialog/useDialog";
import "./index.css";

interface IAchievement {
  data: ICustomAchievement;
}

const Achievement = (props: IAchievement) => {
  const dispatch = useDispatch();

  const [showTools, setShowTools] = useState(false);
  const [deleteId, setDeleteId] = useState<number>();

  const deleteDialog = useDialog({
    title: lang("app.setting.achievements.delete.title"),
    message: lang("app.setting.achievements.delete.message"),
  });

  const cannotDeleteDialog = useDialog({
    title: lang("app.setting.achievements.cannot-delete.title"),
  });

  const handleDelete = () => {
    if (props.data.id) {
      setDeleteId(props.data.id);
      deleteDialog.show();
    } else {
      cannotDeleteDialog.show();
    }
  };

  return (
    <>
      <ListItem
        id={"achivement-".concat(String(props.data.id) || props.data.title)}
        role="listitem"
        sx={{
          borderBottom: "1px solid #E7E7E7",
          padding: 0,
          color: "var(--sl-deep-purple)",
        }}
        onClick={() => {
          props.data.id ? setShowTools(true) : "";
        }}
        onMouseMove={() => {
          props.data.id ? setShowTools(true) : "";
        }}
        onFocus={() => {
          props.data.id ? setShowTools(true) : "";
        }}
        onMouseOut={() => {
          setShowTools(false);
        }}
        onBlur={({ relatedTarget, currentTarget }) => {
          setTimeout(() => {
            if (!currentTarget.contains(relatedTarget)) {
              setShowTools(false);
            }
          });
        }}
      >
        <Grid container flexWrap="nowrap">
          <Box
            tabIndex={-1}
            className="purple-icon"
            component="img"
            src={
              props.data.completedAt
                ? MyProfileAcheivementSelected
                : MyProfileAcheivementUnSelected
            }
            sx={{
              width: { xs: "40px", sm: "50px" },
              height: { xs: "40px", sm: "50px" },
              padding: "1rem",
              paddingLeft: { xs: "10px", sm: "20px" },
            }}
          />

          <Stack
            tabIndex={0}
            sx={{
              padding: "1rem 0",
              flexGrow: 1,
              justifyContent: "center",
            }}
          >
            <SLTypography variant="subhead">{props.data.title}</SLTypography>
            <SLTypography variant="smallBodyCopy">
              {props.data.description}
            </SLTypography>
          </Stack>

          <Grid item display="flex">
            {props.data.completedAt ? (
              <Stack
                tabIndex={0}
                sx={{
                  color: "var(--sl-light-text)",
                  height: "100%",
                  minWidth: "80px",
                  padding: "0 10px",
                  alignItems: "center",
                  justifyContent: "center",
                  flexShrink: 1,
                }}
              >
                <SLTypography variant="smallFooter">Completed</SLTypography>
                <SLTypography variant="smallFooter">
                  {dayjs(props.data.completedAt).format("MM/DD/YYYY")}
                </SLTypography>
              </Stack>
            ) : null}

            <Box
              role="button"
              tabIndex={0}
              sx={{
                width: showTools ? "50px" : 0,
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                overflow: "hidden",
                transition: "width 0.1s ease-in-out",
                outlineWidth: "2px",
              }}
              className="cursor-pointer"
              aria-label={`Delete ${props.data.title} achivement`}
              onClick={handleDelete}
              onKeyPress={onEnterOrSpace(handleDelete)}
            >
              <Box component="img" src={TrashSvg} />
            </Box>
          </Grid>
        </Grid>
      </ListItem>

      <SLDialog
        dismissable={true}
        dialog={deleteDialog}
        actionButtons={{
          noMessageId: "general.cancel",
          yesMessageId: "app.setting.achievements.delete.button",
          onYesButtonClicked: debounce((e: any) => {
            dispatch(
              SLThunk.deleteCustomAchievementId.call({ id: String(deleteId) })
            );
            setDeleteId(undefined);
            const el = document.getElementById(
              "achivement-".concat(String(props.data.id) || props.data.title)
            );
            if (el) {
              el.style.opacity = "0.2";
              setTimeout(() => {
                if (el) {
                  el.style.opacity = "";
                }
              }, 3000);
            }
            deleteDialog.hide();
          }, 100),
          onNoButtonClicked: () => deleteDialog.hide(),
        }}
      />
      <SLDialog
        dismissable={true}
        dialog={cannotDeleteDialog}
        actionButtons={{
          yesMessageId: "general.ok",
          onYesButtonClicked: () => cannotDeleteDialog.hide(),
          noMessageId: false,
        }}
      />
    </>
  );
};

export default Achievement;
