import {
  Box,
  InputAdornment,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { titleCase } from "helpers/common/changeCase";
import { onEnterOrSpace } from "helpers/common/keypress";
import { CrossIcon, SearchIcon } from "helpers/enum/constants";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLArtist, SLPaginationResponse } from "utils/slRTKHelper/slAPI.models";
import Loadmore from "../../loadmore";
import { PageHeading } from "../../pageHeading";
import { InputStandardField } from "../../slTextField";
import { NotFoundMessage } from "../../slTypography/NotFoundMessage";
import { ArtistDetail } from "./ArtistDetail";
import "./index.css";

interface ICategoryButton {
  id: number;
  messageId: string;
}

const AuthorDetails = () => {
  const categoryTags: ICategoryButton[] = [
    {
      id: 1,
      messageId: "all",
    },
  ];
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const timerRef = useRef<any>();

  const configData = useSelector(SLSelector.getConfig);
  const { data, loading, error } = useSelector(SLSelector.getArtist);

  const [artistData, setArtistData] =
    useState<SLPaginationResponse<SLArtist>>();
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchText, setSearchText] = useState("");
  const [ariaLive, setAriaLive] = useState<string | null>(null);
  const [categoryButton, setCategoryButton] = useState(categoryTags);
  const [category, setSelectedCategory] = useState<string | null>("all");

  const viewArtist = (artist: any) => {
    navigate(`/app/view-author/${artist.id}`);
  };

  const handleCategoryChange = (
    event: React.MouseEvent<HTMLElement>,
    category: string | null
  ) => {
    setArtistData(undefined);
    setSelectedCategory(category);
    setSearchText("");
    getArtists({ category, search: "", page: 1 });
  };

  const handleSearch = (e: any) => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setSearchText(e.target.value);
    timerRef.current = setTimeout(() => {
      getArtists({ search: e.target.value, page: 1 });
    }, 1000);
  };

  const loadMoreArtists = () => {
    getArtists({ page: currentPage + 1 });
  };

  const getArtists = (props?: {
    search?: string | null;
    page?: number;
    category?: string | null;
  }) => {
    const data: any = {
      page: currentPage,
      limit: 15,
      category: category,
      search: searchText,
      sortBy: "fullName",
      sort: "ASC",
    };

    Object.assign(data, props);

    if (data?.category && data?.category == "all") {
      delete data.category;
    }

    data.search = data.search.trim() || "";
    if (!data?.search) {
      delete data.search;
    }

    setAriaLive(
      `${Number(data?.totalCount) === 0 ? "No" : Number(data?.totalCount)} ${
        data?.totalCount === 1 ? "author" : "authors"
      } found`
    );

    setCurrentPage(data.page);

    dispatch(SLThunk.getArtist.call(data));
  };

  useEffect(() => {
    getArtists();
  }, []);

  useEffect(() => {
    if (configData) {
      configData?.data?.artistTags.map((item, index) => {
        const artistTag: ICategoryButton = { id: index + 2, messageId: item };
        const exist = categoryButton.some(
          (button) => button.messageId === item
        );
        if (!exist) {
          setCategoryButton((prevCategoryButton) => [
            ...prevCategoryButton,
            artistTag,
          ]);
        }
      });
    }
  }, [configData]);

  useEffect(() => {
    if (ariaLive) {
      setTimeout(() => {
        setAriaLive(null);
      }, 5000);
    }
  }, [ariaLive]);

  useEffect(() => {
    if (data) {
      if (searchText) {
        mixpanel.triggerSearchEvent("AUTHOR_DETAIL_FRAGMENT", searchText);
      }

      if (category != "all") {
        mixpanel.triggerTagUsedEvent(
          "AUTHOR_DETAIL_FRAGMENT",
          category || "",
          titleCase(category)
        );
      }
    }

    if (data?.data) {
      if (currentPage === 1) {
        setArtistData(data);
      } else {
        const appendedData = {
          ...data,
          data: [...artistData!.data, ...data.data],
        };
        setArtistData(appendedData);
      }
    }
  }, [data]);

  const clearSearch = () => {
    setArtistData(undefined);
    setSelectedCategory(category);
    setSearchText("");
    getArtists({ category, search: "", page: 1 });

    const searchEl = document.getElementById("searchField") as HTMLInputElement;
    if (searchEl) {
      searchEl.focus();
    }
  };
  return (
    <Box
      id="author-detail"
      sx={{
        minHeight: { xs: "auto", md: "60vh", lg: "68vh" },
        paddingTop: { lg: "0px", xs: "32px" },
      }}
    >
      <PageHeading messageId="authorDetail.title" />
      <InputStandardField
        className="search-input-full-width"
        id="searchField"
        type="text"
        variant="standard"
        placeholderId="Search Authors"
        value={searchText}
        props={{ className: "search-input" }}
        inputProps={{
          disableUnderline: true,
          startAdornment: (
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment
              sx={{ display: searchText !== "" ? "" : "none" }}
              position="start"
            >
              <CrossIcon
                role="button"
                tabIndex={0}
                titleAccess="Clear Search"
                onClick={clearSearch}
                onKeyPress={onEnterOrSpace(clearSearch)}
                sx={{ cursor: "pointer" }}
              />
            </InputAdornment>
          ),
          onChange: (e: any) => handleSearch(e),
          // tabIndex: 0,
        }}
      />
      <Stack
        direction="row"
        spacing={1}
        sx={{
          overflowX: "auto",
          scrollbarWidth: "none",
          "&::-webkit-scrollbar": { height: "5px" },
          "&::-webkit-scrollbar-track": { display: "none" },
        }}
        marginTop="10px"
        borderBottom="1px solid var(--colorLightGray)"
      >
        <ToggleButtonGroup
          className="deeppurple-toggle"
          value={category}
          exclusive
          onChange={handleCategoryChange}
          sx={{
            gap: 1,
            paddingBottom: { lg: "", xs: "20px" },
          }}
          aria-label="Filter categories"
        >
          {categoryButton.map((tag: any, index) => (
            <ToggleButton
              key={"artist_catid" + index}
              value={tag.messageId}
              aria-label={tag.messageId}
              tabIndex={0}
            >
              {tag.messageId}
            </ToggleButton>
          ))}
        </ToggleButtonGroup>
      </Stack>
      <Box>
        {artistData && artistData?.totalCount > 0 ? (
          <span id="artist-list" role="list">
            {artistData?.data?.map((artist: SLArtist, index) => (
              <ArtistDetail
                index={index}
                key={artist.id}
                viewArtist={viewArtist}
                artist={artist}
              />
            ))}
          </span>
        ) : !loading ? (
          <NotFoundMessage notFoundMessage={`No Authors Found`} />
        ) : null}

        {loading
          ? Array.from({ length: 3 }, (_i) => (
              <ArtistDetail
                mediaCardImage={""}
                viewArtist={viewArtist}
                artist={null}
                loading={true}
              />
            ))
          : null}

        {artistData && artistData?.totalCount > 0 ? (
          <Loadmore
            handleLoadmore={loadMoreArtists}
            data={artistData}
            focusElementId="artist-0"
          />
        ) : null}
      </Box>

      <div
        aria-live="assertive"
        style={{ height: 0, width: 0, overflow: "hidden" }}
        dangerouslySetInnerHTML={{ __html: ariaLive || "" }}
      />
    </Box>
  );
};

export default AuthorDetails;
