import { Box } from "@mui/material";

export function HeaderBottomGradient() {
  return (
    <Box
      className="header-bottom-gradient"
      sx={{
        // backgroundColor: "#808080",
        // backgroundSize: "cover",
        // backgroundBlendMode: "overlay",
        // filter: "blur(18px)",
        // width: "100%",
        zIndex: "0",
        height: {
          lg: "350px",
          xs: "250px",
        },
        background:
          "linear-gradient(180deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.50) 100%)",
        position: "absolute",
        bottom: 0,
        left: 0,
        right: 0,
      }}
    />
  );
}
