import { Box, Button, InputAdornment, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { snakeCase } from "helpers/common/changeCase";
import { onEnterOrSpace } from "helpers/common/keypress";
import { MediaDetailPages, MediaType } from "helpers/enum";
import {
  CrossIcon,
  CrossIconTutorial,
  SearchIcon,
} from "helpers/enum/constants";
import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useSetState } from "react-use";
import { getIdealPageSize } from "utils/getIdealPageSize";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLStorage } from "utils/slStorage";
import MediaDetail from "../../mediaDetail";
import { InputStandardField } from "../../slTextField";
import { SLVerificationMessage } from "../../verificationMessage";
import "./index.css";

const pageSize = getIdealPageSize();

interface State {
  run: boolean;
  steps: Step[];
}
const ExploreMediaType = () => {
  const mixpanel = useMixpanel();
  const { type } = useParams();
  const dispatch = useDispatch();
  const timerRef = useRef<any>();
  const [tutorialStep, setTutorialStep] = useState(0);
  const [ariaLive, setAriaLive] = useState<any>(undefined);
  // Manage Use States
  const guestLogin = SLStorage.getItem("guestLogin");
  const [alignment, setAlignment] = useState<string>("Any Time");
  const [toggleBtn, setToggleBtn] = useState<string>("all");
  const [mediaData, setMediaData] = useState<any>();
  const [mediaType, setMediaType] = useState<MediaType>();
  const [mediaDuration, setMediaDuration] = useState<number>(0);
  const [searchPlaceHolder, setSearchPlaceHolder] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [addedToFav, setAddedToFav] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [tagArray, setTagArray] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [allMediaTags, setMediaTags] = useState<any>();
  const [alltag, setalltags] = useState([
    {
      id: "all",
      title: "all",
      type: type,
    },
    {
      id: "1111",
      title: "Popular",
      type: type,
    },
  ]);
  const [imageToggle, setimageToggle] = useState<boolean>(false);
  // Manage Use Selectors
  const mediaByType = useSelector(SLSelector.getMediaByType);
  const getMediaTags = useSelector(SLSelector.getFilters);

  // Manage UseEffects

  useEffect(() => {
    dispatch(SLThunk.getFilters.call());
  }, []);

  useEffect(() => {
    if (getMediaTags.data) {
      setMediaTags(getMediaTags.data);
      dispatch(SLThunk.getFilters.reset());
      setTimeout(() => {
        setState({ run: true });
      }, 500);
    }
  }, [getMediaTags]);

  useEffect(() => {
    if (getMediaTags?.data && type) {
      const arr = getMediaTags.data.filter((tags: any) => tags.type === type);
      setTagArray(arr);
      setTagArray((pre: any) => [...alltag, ...pre]);
    }
  }, [type, getMediaTags.data]);

  useEffect(() => {
    if (type) {
      setMediaType(type as MediaType);
      getMediaData({
        type: type,
        limit: pageSize,
        page: 1,
        isFavourite: false,
      });
      if (type !== MediaType.FrequencyWave) {
        setSearchPlaceHolder(type);
      } else {
        setSearchPlaceHolder("Frequency Waves");
      }
    }
  }, [type]);

  useEffect(() => {
    if (mediaByType.data) {
      setLoading(false);
      setMediaData(mediaByType.data);
      dispatch(SLThunk.getMediaByType.reset());
      let screen: keyof typeof mixpanel.constants.SCREENS;

      if (mediaType === MediaType.Audio) {
        screen = "EXPLORE_ALL_FILES_AUDIO";
      } else if (mediaType === MediaType.Video) {
        screen = "EXPLORE_ALL_FILES_VIDEO";
      } else {
        screen = "EXPLORE_ALL_FILES_FREQUENCY_WAVE";
      }

      if (searchValue) {
        mixpanel.triggerSearchEvent(screen, searchValue);
      }
      // if (toggleBtn != "all") {
      const tagId =
        tagArray.find((r: any) => r.title == toggleBtn)?.id ||
        snakeCase(toggleBtn);
      mixpanel.triggerTagUsedEvent(screen, tagId, toggleBtn);
      // }

      setAriaLive(
        Number(mediaByType.data?.totalCount) === 0
          ? `No ${searchPlaceHolder} Found`
          : `${Number(mediaByType.data?.totalCount)} ${searchPlaceHolder}${
              mediaByType.data?.totalCount === 1 ? "" : "s"
            } found`
      );
    }
  }, [mediaByType]);

  // Manage Functions

  const getMediaData = (payload: any) => {
    dispatch(SLThunk.getMediaByType.call(payload));
  };

  const handleLikedMediaCard = (text: string) => {
    // console.log("heeelo");
    setAddedToFav(text);
    setOpenDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 1500);
  };

  const handleSearch = (e: any) => {
    setLoading(true);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setSearchValue(e.target.value);

    const formData: any = {
      type: type,
      limit: pageSize,
      page: 1,
      sort: "DESC",
      sortBy: "createdAt",
      isFavourite: false,
      search: e.target.value,
    };

    if (mediaDuration != 0) {
      formData.duration = mediaDuration;
    }

    timerRef.current = setTimeout(() => {
      getMediaData(formData);
    }, 1000);
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    setLoading(true);
    setAlignment(newAlignment);
    if (newAlignment == null) {
      setAlignment("Any Time");
      newAlignment = "Any Time";
    }
    setMediaData(null); // for removing glitch on data load
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (newAlignment !== "Any Time") {
        const [a, b] = newAlignment.split(" ");
        setMediaDuration(a);
        getMediaData({
          type: mediaType,
          limit: pageSize,
          page: 1,
          sort: "DESC",
          sortBy: "createdAt",
          isFavourite: false,
          duration: a,
          search: searchValue,
          tag: toggleBtn === "all" || toggleBtn === "All" ? "" : toggleBtn,
        });
      } else {
        setMediaDuration(0);
        getMediaData({
          type: mediaType,
          limit: pageSize,
          page: 1,
          sort: "DESC",
          sortBy: "createdAt",
          isFavourite: false,
          search: searchValue,
          tag: toggleBtn === "all" || toggleBtn === "All" ? "" : toggleBtn,
        });
      }
    }, 700);
  };

  const handleToggleBtn = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    setLoading(true);
    setMediaData(null); // for removing glitch on data load
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    setToggleBtn(newAlignment);
    if (newAlignment === null) {
      setToggleBtn("all");
    }
    timerRef.current = setTimeout(() => {
      if (newAlignment === "Popular") {
        getAllMediaData(pageSize);
      } else {
        if (mediaDuration !== 0) {
          getMediaData({
            type: mediaType,
            limit: pageSize,
            page: 1,
            sort: "DESC",
            sortBy: "createdAt",
            isFavourite: false,
            tag:
              newAlignment === "all" || newAlignment === null
                ? "All"
                : newAlignment,
            duration: mediaDuration,
            search: searchValue,
          });
        } else {
          getMediaData({
            type: mediaType,
            limit: pageSize,
            page: 1,
            sort: "DESC",
            sortBy: "createdAt",
            isFavourite: false,
            tag:
              newAlignment === "all" || newAlignment === null
                ? "All"
                : newAlignment,
            search: searchValue,
          });
        }
      }
    }, 700);
  };

  const LoadMoreCards = (limit: number, pageNumber: number) => {
    if (toggleBtn === "Popular") {
      getAllMediaData(limit);
    } else {
      getMediaData({
        type: mediaType,
        limit: limit,
        page: pageNumber,
        isFavourite: false,
        tag: toggleBtn === "all" || toggleBtn === "All" ? "" : toggleBtn,
        duration: mediaDuration,
      });
    }
  };

  const getAllMediaData = async (limit: number) => {
    const params = {
      limit: limit,
      page: 1,
      sort: "DESC",
      sortBy: "createdAt",
      duration: mediaDuration,
      isFavourite: false,
    };
    const makeCallObj: any = {
      method: "get",
      url: "/media/popular/{type}",
      urlParams: { type: type },
      payload: {},
      params: params,
    };
    const response = await makeSLAPICall(makeCallObj);
    if (response) {
      setLoading(false);
      let screen: keyof typeof mixpanel.constants.SCREENS;

      if (mediaType === MediaType.Audio) {
        screen = "EXPLORE_ALL_FILES_AUDIO";
      } else if (mediaType === MediaType.Video) {
        screen = "EXPLORE_ALL_FILES_VIDEO";
      } else {
        screen = "EXPLORE_ALL_FILES_FREQUENCY_WAVE";
      }

      mixpanel.triggerTagUsedEvent(screen as any, 1111, "Popular");
    }
    setMediaData(response);
  };
  const ExploreMediaTutorial = SLStorage.getItem("ExploreMediaTutorial");
  const hideTolltip = () => {
    if (!ExploreMediaTutorial) {
      SLStorage.setItem("ExploreMediaTutorial", true);
    }
    const elementOverlayToggleBtn = document.querySelectorAll(
      ".joyride-target-toggle .MuiButtonBase-root"
    );

    if (elementOverlayToggleBtn) {
      for (let i = 0; i < elementOverlayToggleBtn.length; i++) {
        elementOverlayToggleBtn[i].classList.remove("btnBorderColor");
      }
    }
    setimageToggle(false);
    setState({ run: false });
  };
  const [{ run, steps }, setState] = useSetState<State>({
    run: false,
    steps: [
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Within each category, you can filter by duration – as well as New,
              Popular, and Specific Experience."
            >
              <p className="bodyTextTutorial">
                Within each category, you can filter by duration – as well as
                New, Popular, and Specific Experience.
              </p>
              <object
                className="footerTextTutorial"
                aria-label="Walkthrough – Step 3 of 5"
                tabIndex={0}
              >
                Walkthrough – Step 3 of 5
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} tabIndex={0} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "bottom",
        target: ".joyride-target-toggle .MuiToggleButton-root:nth-child(5)",
        title: (
          <object tabIndex={0} aria-label="File Filtering">
            <p className="tutorialHeading">File Filtering</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="You can click on a thumbnail to access the file details."
            >
              <p className="bodyTextTutorial">
                You can click on a thumbnail to access the file details.
              </p>
              <object
                className="footerTextTutorial"
                aria-label="Walkthrough – Step 4 of 5"
                tabIndex={0}
              >
                Walkthrough – Step 4 of 5
              </object>
              <Button
                type="button"
                aria-live="off"
                aria-label=""
                data-action="skip"
                role="button"
                title=""
                className="lastStepBtn"
                onClick={hideTolltip}
                onKeyPress={hideTolltip}
              >
                <img src={CrossIconTutorial} tabIndex={0} />
              </Button>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Next",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".infinite-scroll-component div:nth-child(1) .mediation-card",
        title: (
          <object tabIndex={0} aria-label="File Details">
            <p className="tutorialHeading">File Details</p>
          </object>
        ),
        disableBeacon: true,
      },
    ],
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const elementOverlaySpotLight = document.querySelector<HTMLElement>(
      ".react-joyride__spotlight"
    );
    const button = document.querySelector('button[data-action="skip"]');
    if (button) {
      button.setAttribute("title", "Skip tutorial");
      button.setAttribute("aria-label", "Skip tutorial");
    }
    if (index === 0) {
      setimageToggle(true);
      const elementOverlayToggleBtn = document.querySelectorAll(
        ".joyride-target-toggle .MuiButtonBase-root"
      );

      if (elementOverlayToggleBtn) {
        for (let i = 0; i < elementOverlayToggleBtn.length; i++) {
          elementOverlayToggleBtn[i].classList.add("btnBorderColor");
        }
      }
      if (elementOverlaySpotLight) {
        elementOverlaySpotLight.style.borderRadius = "1rem";
      }
    }
    if (index === 1) {
      const elementOverlayToggleBtn = document.querySelectorAll(
        ".joyride-target-toggle .MuiButtonBase-root"
      );

      if (elementOverlayToggleBtn) {
        for (let i = 0; i < elementOverlayToggleBtn.length; i++) {
          elementOverlayToggleBtn[i].classList.remove("btnBorderColor");
        }
      }
      setimageToggle(false);
      if (elementOverlaySpotLight) {
        elementOverlaySpotLight.style.borderRadius = "10px";
        elementOverlaySpotLight.style.marginLeft = "-1px";
      }
    }
    if (index === 1 && type === "step:after") {
      const elementOverlay = document.querySelector<HTMLElement>(
        ".infinite-scroll-component div:nth-child(1) .mediation-card"
      );

      if (elementOverlay) {
        elementOverlay.click();
      }
    }
    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      if (index !== 1 && !ExploreMediaTutorial) {
        const elementOverlayToggleBtn = document.querySelectorAll(
          ".joyride-target-toggle .MuiButtonBase-root"
        );
        if (elementOverlayToggleBtn) {
          for (let i = 0; i < elementOverlayToggleBtn.length; i++) {
            elementOverlayToggleBtn[i].classList.remove("btnBorderColor");
          }
        }
        //  alert(true);
        setimageToggle(false);
        SLStorage.setItem("ExploreMediaTutorial", true);
      }
    }
  };
  const clearSearch = () => {
    setLoading(true);
    setSearchValue("");
    getMediaData({
      limit: pageSize,
      page: 1,
      sort: "DESC",
      sortBy: "createdAt",
      duration: mediaDuration,
      isFavourite: false,
      type: type,
      tag: toggleBtn === "all" ? "" : toggleBtn,
    });

    const searchEl = document.getElementById("searchField") as HTMLInputElement;
    if (searchEl) {
      searchEl.focus();
    }
  };
  return (
    <>
      {!guestLogin && !ExploreMediaTutorial ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton={true}
          run={run}
          scrollToFirstStep={false}
          showProgress={false}
          showSkipButton={true}
          disableOverlayClose={true}
          spotlightPadding={0}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      ) : (
        ""
      )}
      <Box
        sx={{
          width: "100%",
          // padding: "0px 100px",
        }}
      >
        <Box>
          <Stack
            sx={{
              paddingBottom: "30px",
              borderBottom: "1px solid var(--colorLightGray)",
            }}
          >
            <InputStandardField
              id="searchField"
              type="text"
              variant="standard"
              placeholderId={`Search ${searchPlaceHolder}`}
              value={searchValue}
              props={{ className: "search-input" }}
              inputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    sx={{ display: searchValue !== "" ? "" : "none" }}
                    position="start"
                  >
                    <CrossIcon
                      role="button"
                      tabIndex={0}
                      titleAccess="Clear Search"
                      onClick={clearSearch}
                      onKeyPress={onEnterOrSpace(clearSearch)}
                      sx={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
                onChange: (e: any) => handleSearch(e),
                // tabIndex: 0,
              }}
            />
            <SLTypography
              tabIndex={0}
              variant="homeParagraphText"
              sx={{ color: "var(--sl-deep-purple)", maxWidth: "928px" }}
            >
              {mediaType === MediaType.Audio ? (
                <FormattedMessage id={"explore.media.audio.text"} />
              ) : mediaType === MediaType.Video ? (
                <FormattedMessage id={"explore.media.video.text"} />
              ) : (
                <FormattedMessage
                  id={"explore.media.frequencyWave.updated.text"}
                />
              )}
            </SLTypography>
          </Stack>
        </Box>
        <MediaDetail
          handleAlignment={handleAlignment}
          mediaType={mediaType as any}
          durationTimeValue={alignment}
          handleToggleBtn={handleToggleBtn}
          tagValue={toggleBtn}
          tagArray={tagArray}
          loading={loading}
          mediaData={mediaData}
          likedMediaCard={handleLikedMediaCard}
          searchPlaceHolder={searchPlaceHolder || ""}
          whichPage={MediaDetailPages.Explore}
          loadMoreCards={LoadMoreCards}
          imageToggle={imageToggle}
          cardTyped="Media"
          searchValue={searchValue}
        />
      </Box>
      <SLVerificationMessage
        open={openDialog}
        sx={{ zIndex: 9999 }}
        message={addedToFav}
      />

      <div
        aria-live="assertive"
        className="expore-media-type"
        style={{ height: 0, width: 0, overflow: "hidden" }}
        dangerouslySetInnerHTML={{ __html: ariaLive || "" }}
      />
    </>
  );
};

export default ExploreMediaType;
