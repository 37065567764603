const createCustomerInGrowSumo = (customerId, email, name) => {
  if (window.growsumo) {
    window.growsumo.data.email = email;
    window.growsumo.data.name = name;
    window.growsumo.data.customer_key = `${customerId}`;
    console.log(window.growsumo.createSignup());
  }
};

const initAndCreateCustomer = async (user) => {
  createCustomerInGrowSumo(user?.id, user?.email, user?.fullName);
};

export default initAndCreateCustomer;
