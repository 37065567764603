import { Box, Button, Grid, IconButton, Stack, TextField } from "@mui/material";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import { SLTypography } from "components/slTypography";
import { SLVerificationMessage } from "components/verificationMessage";
import {
  ArrowForwardIosIcon,
  CrossIcon,
  CrossWhite,
  EmptyHeart,
  FillHeart,
  ShareIcon,
} from "helpers/enum/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { webShareOpen } from "store/webShareSlice";
import { getMediaSharingText } from "utils/getMediaSharingText";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import {
  CreateNotesPayload,
  NotesReactions,
  SLMediaFileDetail,
} from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";
import { SLButton } from "../../slButton";
import "./index.css";

interface IMeditationSummaryPopUp {
  mediaData: SLMediaFileDetail;
  openCompletionPopup: (bool: boolean) => any;
  handleCompletion: any;
  dynamicLinkUrlResponse: string;
}
const mediaTypes = ["audio", "video", "frequencyWave"];

const MediaSummaryPopUp = ({
  mediaData,
  openCompletionPopup,
  handleCompletion,
  dynamicLinkUrlResponse,
}: IMeditationSummaryPopUp) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // States
  const [tagsFiltered, setTagsFiltered] = useState<any[]>([]);
  const [reactions, setReactions] = useState<NotesReactions[]>([]);
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [description, setDescription] = useState<string>("");
  const [toastMessage, setToastMessage] = useState<any>(null);
  const [guestErrorMessage, setGuestErrorMessage] = useState<any>("");

  const [submit, setSubmit] = useState<boolean>(false);

  // Selectors
  const adminTagsData = useSelector(SLSelector.getAdminTag);
  const { data: reactionsData } = useSelector(SLSelector.getNotesReactions);
  const { data: userprofileData } = useSelector(SLSelector.getUserProfile);
  const postNotesResult = useSelector(SLSelector.postNotes);

  const [guestError, setGuestError] = useState(false);
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);
  const guestLogin = SLStorage.getItem("guestLogin");

  // const addMediaFavourite = useSelector(SLSelector.postMediaAddFavourites);
  const [addedToFavouriteList, setAddedToFavouriteList] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  // useEffect(() => {
  //   if (addMediaFavourite?.data) {
  //     setIsLiked(true);
  //     dispatch(SLThunk.postMediaAddFavourites.reset());
  //   }
  // }, [addMediaFavourite]);

  useEffect(() => {
    if (postNotesResult.data) {
      openCompletionPopup(false);
      handleCompletion(postNotesResult.data);
      dispatch(SLThunk.postNotes.reset());
    } else if (postNotesResult.error) {
      openCompletionPopup(false);
      handleCompletion(postNotesResult.error);
      dispatch(SLThunk.postNotes.reset());
    }
  }, [postNotesResult]);

  useEffect(() => {
    dispatch(SLThunk.getAdminTag.call({}));
    dispatch(SLThunk.getNotesReactions.call({}));
  }, []);

  useEffect(() => {
    if (guestLogin) {
      setGuestErrorMessage({
        errorHeading: "Oops! You Need to Log In",
        errorBody:
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed.",
      });
    } else {
      if (!userSubscription) {
        setGuestErrorMessage({
          errorHeading: "Oops! This is for Subscribers Only",
          errorBody:
            "Elevate your meditation practice with our premium features. Join Soul Link today to unlock personalized playlists, guided meditations, and more.",
        });
      }
    }
  }, [userSubscription]);

  useEffect(() => {
    let selectedTagIds: number[] = [];
    if (adminTagsData.data) {
      if (adminTagsData.data.length > 0) {
        selectedTagIds = adminTagsData.data.map((tag: any) => tag.id);
      }
      const data = adminTagsData.data
        .filter((tag: any) => tag.type === "note")
        .map((tag: any) => {
          return {
            ...tag,
            selected: !selectedTagIds.includes(tag.id),
          };
        });
      setTagsFiltered(data);
    }
  }, [adminTagsData]);

  useEffect(() => {
    let selectedReactionIds: number[] = [];
    if (reactionsData) {
      if (reactionsData.length > 0) {
        selectedReactionIds = reactionsData.map((tag: any) => tag.id);
      }
      if (reactionsData?.length) {
        const data = reactionsData.map((reaction: any) => {
          return {
            ...reaction,
            selected: !selectedReactionIds.includes(reaction.id),
          };
        });

        setReactions(data);
      }
    }
  }, [reactionsData]);

  const handleTagClick = (index: number, selected: boolean) => {
    const newArr = [...tagsFiltered];
    newArr[index]["selected"] = !selected;
    setTagsFiltered(newArr);
  };

  const handleReactionClick = (
    index: number,
    selected: boolean | undefined
  ) => {
    const newArr = [...reactions];
    newArr[index]["selected"] = !selected;
    setReactions(newArr);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const filterSelectArrayIds = (array: any) => {
    return array.filter((obj: any) => obj.selected).map((obj: any) => obj.id);
  };

  const handleFavoriteMeditation = () => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return false;
    }

    if (mediaData.isLikedByMe) {
      dispatch(
        SLThunk.deleteMediaFavouritesId.call({
          id: mediaData.id?.toString() ?? "",
        })
      );
      setAddedToFavouriteList(true);
      setSuccessMessage("Removed from My Favorites");
    } else {
      dispatch(
        SLThunk.postMediaAddFavourites.call({
          mediaId: mediaData.id ?? 23,
        })
      );
      setAddedToFavouriteList(true);
      setSuccessMessage("Added to My Favorites");
    }

    setTimeout(() => {
      setAddedToFavouriteList(false);
    }, 2000);
  };
  const handleSaveNotes = async () => {
    if (submit) {
      return;
    }

    const desc = description.trim();
    let msg = null;
    if (desc?.length === 0) {
      msg = "Fill the obligatory field, please";
      setErrorMsg("");
    } else if (desc?.length === 1) {
      msg = "The journal is too short";
    } else if (desc?.length > 1000) {
      msg = "The journal is too long";
    }
    if (msg) {
      setErrorMsg(msg);
      return;
    }
    setSubmit(true);
    setErrorMsg(msg);
    const payload: CreateNotesPayload = {
      description: desc,
      tags: filterSelectArrayIds(tagsFiltered),
      reactions: filterSelectArrayIds(reactions),
    };
    payload.userId = userprofileData?.id || 0;
    if (mediaData?.type && mediaTypes.includes(mediaData.type)) {
      payload.mediaId = mediaData.id;
    } else {
      payload.meditationId = mediaData.id;
    }
    dispatch(SLThunk.postNotes.call(payload));
  };

  const navigateMeditationDetail = () => {
    navigate("/app/file-detail/" + mediaData?.id);
  };

  return (
    <>
      <Box
        className="meditation-summary-main-container"
        sx={{
          background: "var(--bg-gradient)",
          overflow: "auto",
        }}
      >
        <IconButton
          tabIndex={0}
          title="Close Dialog"
          style={{
            cursor: "pointer",
            position: "absolute",
            right: "0.5rem",
            top: "0.5rem",
          }}
          onClick={() => {
            openCompletionPopup(false);
          }}
        >
          <CrossIcon />
        </IconButton>

        <Box
          sx={{
            borderBottom: "1px solid #CBC8CD",
            padding: "2rem",
            gap: "1rem",
          }}
        >
          <Stack direction="column" gap="20px">
            <Stack direction="row">
              <SLTypography
                tabIndex={0}
                variant="subhead"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Media Summary / {mediaData.title}
              </SLTypography>
            </Stack>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              gap="10px"
              className="meditation-summary-btn-text"
            >
              <SLButton
                onClick={() => handleFavoriteMeditation()}
                onKeyPress={() => handleFavoriteMeditation()}
                startIcon={
                  !mediaData.isLikedByMe ? (
                    <EmptyHeart sx={{ color: "var(--sl-deep-purple)" }} />
                  ) : (
                    <FillHeart sx={{ color: "var(--sl-deep-purple)" }} />
                  )
                }
                endIcon={
                  <ArrowForwardIosIcon
                    fontSize="small"
                    sx={{
                      color: "var(--sl-deep-purple)",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                }
                sx={{
                  width: "100%",
                  borderRadius: "10px !important",
                  justifyContent: "space-between",
                  paddingLeft: "17px",
                  paddingRight: "20px",
                }}
                slClass="white"
                messageId={
                  !mediaData.isLikedByMe
                    ? "meditation.summary.addToFavorite.button"
                    : "meditation.summary.addToFavorite.button.favorites"
                }
              />
              <SLButton
                onClick={() => {
                  dispatch(
                    webShareOpen({
                      text: getMediaSharingText(
                        mediaData?.title,
                        mediaData?.type
                      ),
                      url: dynamicLinkUrlResponse,
                    })
                  );
                }}
                startIcon={
                  <ShareIcon sx={{ color: "var(--sl-deep-purple)" }} />
                }
                endIcon={
                  <ArrowForwardIosIcon
                    sx={{
                      color: "var(--sl-deep-purple)",
                      width: "16px",
                      height: "16px",
                    }}
                  />
                }
                sx={{
                  width: "100%",
                  borderRadius: "10px !important",
                  justifyContent: "space-between",
                  paddingLeft: "17px",
                  paddingRight: "20px",
                }}
                slClass="white"
                messageId={"meditation.summary.share.button"}
              />
            </Stack>
          </Stack>
        </Box>
        {mediaData.type !== "daily" && (
          <Stack
            sx={{
              borderBottom: "1px solid #CBC8CD",
              padding: "1rem 2rem",
              gap: "1rem",
            }}
          >
            <Box>
              <SLTypography
                variant="subhead"
                tabIndex={0}
                sx={{ color: "var(--sl-deep-purple)" }}
                display="inline-block"
                marginBottom={"10px"}
              >
                How do you feel?
              </SLTypography>

              <TextField
                id="outlined-multiline-flexible"
                name="description"
                value={description}
                onChange={handleDescriptionChange}
                multiline
                placeholder="Add your emotions"
                rows={4}
                inputProps={{
                  style: {
                    fontFamily: "Figtree",
                    fontWeight: 500,
                    fontSize: "17px",
                    color: "#4B2B5A",
                    cursor: "text",
                  },
                }}
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  background: "white",
                  marginBottom: "5px",
                  marginTop: { xs: "10px", md: "0px" },
                  flexWrap: "wrap",
                  cursor: "text",
                }}
              />
              {errorMsg && (
                <SLTypography
                  tabIndex={0}
                  color="text-purple"
                  marginBottom="12px !important"
                  fontSize={"12px"}
                  textAlign="left"
                >
                  {errorMsg}
                </SLTypography>
              )}
            </Box>

            {Array.isArray(tagsFiltered) && tagsFiltered.length > 0 ? (
              <Grid item>
                <SLTypography
                  tabIndex={0}
                  variant="mediumHeading"
                  sx={{ color: "#4B2B5A" }}
                  messageId="notes.quickTags"
                  display="inline-block"
                  marginBottom={"10px"}
                />

                <Stack direction="row" sx={{ flexWrap: "wrap", gap: "8px" }}>
                  {tagsFiltered.map((tag: any, index: number) => (
                    <Box
                      component="button"
                      onClick={(e) => handleTagClick(index, tag.selected)}
                      onKeyPress={(e) => handleTagClick(index, tag.selected)}
                      sx={{
                        cursor: "pointer",
                        padding: "3px 10px",
                        color: tag.selected ? "white" : "var(--sl-deep-purple)",
                        background: tag.selected ? "#4B2B5A" : "none",
                        border: tag.selected ? "none" : "1px solid #CBC8CD",
                        borderRadius: "5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        flex: "none",
                      }}
                    >
                      <SLTypography variant="smallFooter" fontFamily="Figtree">
                        {tag.title}
                      </SLTypography>
                      {tag.selected && (
                        <Box sx={{ display: "flex", paddingLeft: "5px" }}>
                          <img src={CrossWhite} alt="" />
                        </Box>
                      )}
                    </Box>
                  ))}
                </Stack>
              </Grid>
            ) : null}

            {Array.isArray(reactions) && reactions.length > 0 ? (
              <Grid item>
                <SLTypography
                  tabIndex={0}
                  sx={{ color: "#4B2B5A" }}
                  variant="mediumHeading"
                  display="inline-block"
                  marginBottom={"10px"}
                >
                  Reactions
                </SLTypography>

                <Stack direction="row" gap={1} flexWrap="wrap">
                  {reactions.map((reaction, index) => (
                    <Button
                      onClick={(e) =>
                        handleReactionClick(index, reaction.selected)
                      }
                      sx={{
                        fontSize: "32px",
                        cursor: "pointer",
                        background: reaction.selected ? "#ffffff" : "none",
                        borderRadius: "50%",
                        aspectRatio: "1/1",
                        lineHeight: 1,
                        border: reaction.selected
                          ? "1px solid #CBC8CD"
                          : "1px solid transparent",
                      }}
                      tabIndex={0}
                    >
                      {reaction?.emoji}
                    </Button>
                  ))}
                </Stack>
              </Grid>
            ) : null}
          </Stack>
        )}

        <Box sx={{ padding: "2rem" }}>
          <Stack
            direction="row"
            gap="10px"
            className="meditation-summary-button"
          >
            {mediaData.type !== "daily" && (
              <SLButton
                fullWidth
                messageId={"notes.myNotes"}
                onClick={handleSaveNotes}
                onKeyPress={handleSaveNotes}
                // variant="title"
              />
            )}
            <SLButton
              fullWidth
              messageId={"media.details"}
              onClick={navigateMeditationDetail}
              onKeyPress={navigateMeditationDetail}
              sx={{
                color: "var(--sl-deep-purple) !important",
                backgroundColor: "white !important",
                fontFamily: "Figtree !important",
                fontWeight: "600 !important",
                fontSize: "15px !important",
                lineHeight: "18px !important",
              }}
            />
          </Stack>
        </Box>
        <SLVerificationMessage
          open={addedToFavouriteList}
          sx={{ zIndex: 9999 }}
          message={successMessage}
        />
        <ErrorDialogBox
          errorDialog={guestError}
          setErrorDialog={setGuestError}
          errorMessage={guestErrorMessage.errorBody}
          errorHeaderMessage={guestErrorMessage.errorHeading}
        />
      </Box>
    </>
  );
};

export default MediaSummaryPopUp;
