interface IFadeVolume {
  interval?: NodeJS.Timer;
  completed?: Promise<void>;
  reject?: () => void;
}

export function fadeVolume(
  currentVolume: number,
  targetVolume: number,
  volumeSetter: (volume: number) => void,
  type?: string
) {
  const returnvar: IFadeVolume = {
    interval: undefined,
    completed: undefined,
    reject: undefined,
  };

  returnvar.completed = new Promise<void>((resolve, reject) => {
    returnvar.reject = reject;
    currentVolume = Math.round(currentVolume);
    targetVolume = Math.round(targetVolume);

    const timeoutMs = 20;
    const totalSteps = 10;
    const stepAmount = (targetVolume - currentVolume) / totalSteps;

    returnvar.interval = setInterval(() => {
      currentVolume = Math.min(currentVolume + stepAmount, targetVolume);

      const difference = Math.abs(currentVolume - targetVolume);

      if (type?.startsWith("freq")) {
        // console.warn(`🚀 > fadeVolume:`, {
        //   currentVolume,
        //   targetVolume,
        //   difference,
        //   stepAmount,
        //   x: type,
        // });
      }
      if (difference <= stepAmount) {
        currentVolume = targetVolume;
      }

      currentVolume = Math.round(Math.min(Math.max(0, currentVolume), 100));

      volumeSetter(currentVolume);

      if (
        currentVolume === targetVolume ||
        currentVolume <= 0 ||
        currentVolume >= 100
      ) {
        clearInterval(returnvar.interval);
        resolve();
      }
    }, timeoutMs);
  });

  return returnvar;
}
