import { Box, InputAdornment, Stack } from "@mui/material";
import { snakeCase } from "helpers/common/changeCase";
import { onEnterOrSpace } from "helpers/common/keypress";
import { MediaDetailPages, MediaType } from "helpers/enum";
import { CrossIcon, SearchIcon } from "helpers/enum/constants";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getIdealPageSize } from "utils/getIdealPageSize";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import MediaDetail from "../../mediaDetail";
import { InputStandardField } from "../../slTextField";
import { SLVerificationMessage } from "../../verificationMessage";
import "./index.css";

const MyListMediaDetail = () => {
  const mixpanel = useMixpanel();
  const { type } = useParams();
  const dispatch = useDispatch();
  const timerRef = useRef<any>();

  // Manage Use States

  const [alignment, setAlignment] = useState<string>("Any Time");
  const [toggleBtn, setToggleBtn] = useState<string>("all");
  const [mediaData, setMediaData] = useState<any>();
  const [mediaType, setMediaType] = useState<string>("");
  const [mediaDuration, setMediaDuration] = useState<number>(0);
  const [searchPlaceHolder, setSearchPlaceHolder] = useState<string>();
  const [searchValue, setSearchValue] = useState<string>("");
  const [addedToFav, setAddedToFav] = useState<string>("");
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [tagArray, setTagArray] = useState<any>();
  const [loading, setLoading] = useState<boolean>(false);
  const [allMediaTags, setMediaTags] = useState<any>();
  const [alltag, setalltags] = useState([
    {
      id: "all",
      title: "all",
      type: type,
    },
  ]);
  const [payloadData, setPayloadData] = useState({
    limit: getIdealPageSize(),
    page: 1,
    sort: "DESC",
    sortBy: "createdAt",
    isFavourite: true,
  });

  // Manage Use Selectors
  const mediaByType = useSelector(SLSelector.getMediaByType);
  const getMediaTags = useSelector(SLSelector.getFilters);

  // Manage UseEffects

  useEffect(() => {
    setMediaData(null);
    dispatch(SLThunk.getFilters.call());
  }, []);

  useEffect(() => {
    if (getMediaTags.data) {
      setMediaTags(getMediaTags.data);
      dispatch(SLThunk.getFilters.reset());
    }
  }, [getMediaTags]);

  useEffect(() => {
    if (getMediaTags.data && type) {
      const arr = getMediaTags.data.filter((tags: any) => tags.type === type);
      setTagArray(arr);
      setTagArray((pre: any) => [...alltag, ...pre]);
    }
  }, [type, getMediaTags.data]);
  2;
  useEffect(() => {
    if (type) {
      setMediaData(null);
      setMediaType(type);
      getMediaData({ type: type, ...payloadData });
      if (type !== MediaType.FrequencyWave) {
        setSearchPlaceHolder(type);
      } else {
        setSearchPlaceHolder("Frequency Waves");
      }
    }
  }, [type]);

  useEffect(() => {
    if (mediaByType.data) {
      setLoading(false);
      let screen: keyof typeof mixpanel.constants.SCREENS;

      if (type === MediaType.Audio) {
        screen = "MY_LIST_AUDIO_FILES";
      } else if (type === MediaType.Video) {
        screen = "MY_LIST_VIDEO_FILES";
      } else {
        screen = "MY_LIST_FREQUENCY_WAVE_FILES";
      }

      if (searchValue) {
        mixpanel.triggerSearchEvent(screen, searchValue);
      }

      // if (toggleBtn != "all") {
      const tagId =
        tagArray?.find((r: any) => r.title == toggleBtn)?.id ||
        snakeCase(toggleBtn);

      mixpanel.triggerTagUsedEvent(screen, tagId, toggleBtn);
      // }

      setMediaData(mediaByType.data);
      dispatch(SLThunk.getMediaByType.reset());
    }
  }, [mediaByType]);

  const getMediaData = (payload: any) => {
    dispatch(SLThunk.getMediaByType.call(payload));
  };

  const handleLikedMediaCard = async (text: string) => {
    setAddedToFav(text);
    setOpenDialog(true);
    setTimeout(() => {
      setOpenDialog(false);
    }, 1500);
  };

  const updateMediaDataOfUnfavourites = (id: number) => {
    setLoading(true);
    const filteredMedia = mediaData.data.filter((obj: any) => obj.id !== id);
    setMediaData({ data: filteredMedia });
    setTimeout(() => {
      setLoading(false);
    }, 700);
  };
  const handleSearch = (e: any) => {
    setLoading(true);
    setSearchValue(e.target.value);
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (mediaDuration !== 0) {
        getMediaData({
          ...payloadData,
          type: type,
          search: e.target.value,
          duration: mediaDuration,
        });
      } else {
        getMediaData({
          ...payloadData,
          type: type,
          search: e.target.value,
        });
      }
    }, 1000);
  };

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    setLoading(true);
    setAlignment(newAlignment);
    if (newAlignment == null) {
      setAlignment("Any Time");
      newAlignment = "Any Time";
    }
    setMediaData(null); // for removing glitch on data load
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(() => {
      if (newAlignment !== "Any Time") {
        const [a, b] = newAlignment.split(" ");
        setMediaDuration(a);
        getMediaData({
          ...payloadData,
          type: mediaType,
          duration: a,
          tag: toggleBtn === "all" ? "" : toggleBtn,
          search: searchValue,
        });
      } else {
        setMediaDuration(0);
        getMediaData({
          ...payloadData,
          type: mediaType,
          tag: toggleBtn === "all" ? "" : toggleBtn,
          search: searchValue,
        });
      }
    }, 700);
  };

  const handleToggleBtn = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: any
  ) => {
    setLoading(true);
    setMediaData(null); // for removing glitch on data load
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    debugger;
    setToggleBtn(newAlignment);
    if (newAlignment === null) {
      setToggleBtn("all");
    }
    timerRef.current = setTimeout(() => {
      if (mediaDuration !== 0) {
        getMediaData({
          ...payloadData,
          type: mediaType,
          tag:
            newAlignment === "all" || newAlignment === null
              ? "All"
              : newAlignment,
          duration: mediaDuration,
          search: searchValue,
        });
      } else {
        getMediaData({
          ...payloadData,
          type: mediaType,
          tag:
            newAlignment === "all" || newAlignment === null
              ? "All"
              : newAlignment,
          search: searchValue,
        });
      }
    }, 700);
  };

  const LoadMoreCards = (limit: number, pageNumber: number) => {
    setLoading(true);
    getMediaData({
      ...payloadData,
      type: mediaType,
      page: pageNumber,
      limit: limit,
      tag: toggleBtn === "all" ? "" : toggleBtn,
      duration: mediaDuration,
      search: searchValue,
    });
  };

  const clearSearch = () => {
    setLoading(true);
    setSearchValue("");
    getMediaData({
      ...payloadData,
      type: type,
      isFavourite: true,
      tag: toggleBtn === "all" ? "" : toggleBtn,
      duration: mediaDuration,
    });

    const searchEl = document.getElementById("searchField") as HTMLInputElement;
    if (searchEl) {
      searchEl.focus();
    }
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Box>
          <Stack>
            <InputStandardField
              id="searchField"
              type="text"
              variant="standard"
              placeholderId={`Search ${searchPlaceHolder}`}
              value={searchValue}
              props={{ className: "list-search-input" }}
              inputProps={{
                disableUnderline: true,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment
                    sx={{ display: searchValue !== "" ? "" : "none" }}
                    position="start"
                  >
                    <CrossIcon
                      role="button"
                      tabIndex={0}
                      titleAccess="Clear Search"
                      onClick={clearSearch}
                      onKeyPress={onEnterOrSpace(clearSearch)}
                      sx={{ cursor: "pointer" }}
                    />
                  </InputAdornment>
                ),
                onChange: (e: any) => handleSearch(e),
                // tabIndex: 0,
              }}
            />
          </Stack>
        </Box>
        <MediaDetail
          handleAlignment={handleAlignment}
          mediaType={mediaType as any}
          durationTimeValue={alignment}
          handleToggleBtn={handleToggleBtn}
          tagValue={toggleBtn}
          tagArray={tagArray}
          loading={loading}
          mediaData={mediaData}
          likedMediaCard={handleLikedMediaCard}
          searchPlaceHolder={searchPlaceHolder || ""}
          whichPage={MediaDetailPages.MyList}
          updateMediaData={updateMediaDataOfUnfavourites}
          loadMoreCards={LoadMoreCards}
          cardTyped="Media"
          searchValue={searchValue}
          willShowLoader={mediaByType.loading}
        />
      </Box>
      <SLVerificationMessage
        open={openDialog}
        sx={{ zIndex: 9999 }}
        message={addedToFav}
      />
    </>
  );
};

export default MyListMediaDetail;
