import { Avatar, Box, Button, Divider, Grid, Link, Stack } from "@mui/material";
import LougoutButton from "components/header/logoutButton";
import EditAccountInfo from "components/profile/editAccountInfo/accountInfo";
import DailyMeditaionGoal from "components/profile/myProfile/dailyMeditationGoal";
import MeditationStatistics from "components/profile/myProfile/meditationStatistics";
import WeeklyMeditaionGoal from "components/profile/myProfile/weeklyMeditationGoal";
import { SLTypography } from "components/slTypography";
import {
  ArrowDownPurple,
  CrossIconTutorial,
  DefaultUserImage,
} from "helpers/enum/constants";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Props } from "react-intl/src/components/relative";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSetState } from "react-use";
import { Auth } from "utils/authService";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { getStats } from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";
import Achievements from "./achievements";
import "./index.css";

type UserProfile = {
  userProfileData: any;
  userImage: string;
  profileStats?: getStats;
  profileStatsLoading: boolean;
} & Props;
interface State {
  run: boolean;
  steps: Step[];
}
const ProfileDetails = ({
  userProfileData,
  userImage,
  profileStats,
  profileStatsLoading,
}: UserProfile) => {
  const guestLogin = SLStorage.getItem("guestLogin");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openDialogBox, setOpenDialogBox] = useState(false);
  const [stats, setStats] = useState(true);
  const [meditationTime, setMeditationTime] = useState("alltime");
  const [statsByDate, setStatsByDate] = useState<any>({});
  const [statsByDateLoading, setStatsByDateLoading] = useState(true);
  const [tutorialStep, setTutorialStep] = useState(0);

  const { data: deteleCustomAchievementData } = useSelector(
    SLSelector.deleteCustomAchievementId
  );

  useEffect(() => {
    if (deteleCustomAchievementData) {
      dispatch(SLThunk.getStatsAll.call({}));
    }
  }, [deteleCustomAchievementData]);

  useEffect(() => {
    if (stats) {
      dispatch(SLThunk.getStatsAll.call({}));
      setStats(false);
    }
  }, [stats]);

  useEffect(() => {
    if (meditationTime && Auth.user.loggedin()) {
      setStatsByDateLoading(true);
      const getStatsByDate = async (meditationTime: string) => {
        const urlParams = { date: "thisAllTime" };
        const makeCallObj: any = {
          method: "get",
          url: "/stats/by-date/{date}",
          urlParams,
        };
        const response = await makeSLAPICall(makeCallObj);
        if (response) {
          setState({ run: true });
          setStatsByDate(response);
          setStatsByDateLoading(false);
        }
      };
      getStatsByDate(meditationTime);
    }
  }, [meditationTime]);

  const myProfileTutorial = SLStorage.getItem("myProfileTutorial");
  const hideTolltip = () => {
    setState({ run: false });
    document.body.style.overflow = "unset";
    if (!myProfileTutorial) {
      //  alert(true);
      SLStorage.setItem("myProfileTutorial", true);
    }
  };

  const [{ run, steps }, setState] = useSetState<State>({
    run: false,
    steps: [
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="My Profile is where you can manage your account, set your own
              goals, view your statistics, and much more!"
            >
              <p className="bodyTextTutorial">
                My Profile is where you can manage your account, set your own
                goals, view your statistics, and much more!
              </p>
              <object
                tabIndex={0}
                className="footerTextTutorial"
                aria-label="Walkthrough – Step 1 of 4"
              >
                Walkthrough – Step 1 of 4
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "right",
        target: ".userPersonalInfo",
        title: (
          <object tabIndex={0} aria-label="My Profile">
            <p className="tutorialHeading">My Profile</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="The Meditation Statistics section shows your Daily Meditation
              Goal, recent stats, and your Meditation Streaks!"
            >
              <p className="bodyTextTutorial">
                The Meditation Statistics section shows your Daily Meditation
                Goal, recent stats, and your Meditation Streaks!
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 2 of 4"
              >
                Walkthrough – Step 2 of 4
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".meditationStats",
        title: (
          <object tabIndex={0} aria-label="Meditation Statistics">
            {" "}
            <p className="tutorialHeading">Meditation Statistics</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="The Achievements section has pre-set goals, but you can
                customize it to fit your needs at any given time."
            >
              <p className="bodyTextTutorial">
                The Achievements section has pre-set goals, but you can
                customize it to fit your needs at any given time.
              </p>
              <object
                tabIndex={0}
                aria-label="Walkthrough – Step 3 of 4"
                className="footerTextTutorial"
              >
                Walkthrough – Step 3 of 4
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".Achievement-list .listItem",
        title: (
          <object tabIndex={0} aria-label="Achievements">
            {" "}
            <p className="tutorialHeading">Achievements</p>{" "}
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="At the bottom is easy access to account details, daily
              inspirations, and all your notes!"
            >
              <p className="bodyTextTutorial">
                At the bottom is easy access to account details, daily
                inspirations, and all your notes!
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 4 of 4"
              >
                Walkthrough – Step 4 of 4
              </object>
              <Button
                type="button"
                aria-live="off"
                aria-label=""
                data-action="skip"
                role="button"
                title=""
                className="lastStepBtn"
                onClick={hideTolltip}
                onKeyPress={hideTolltip}
              >
                <img src={CrossIconTutorial} />
              </Button>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} />, last: "Close" },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "right",
        target: ".profileSidebar ul",
        title: (
          <object tabIndex={0} aria-label="Additional Links">
            <p className="tutorialHeading">Additional Links</p>
          </object>
        ),
        disableBeacon: true,
      },
    ],
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const elementOverlay = document.querySelector<HTMLElement>(
      ".react-joyride__spotlight"
    );
    const button = document.querySelector('button[data-action="skip"]');
    if (button) {
      button.setAttribute("title", "Skip tutorial");
      button.setAttribute("aria-label", "Skip tutorial");
    }
    const toolTipBox = document.querySelector<HTMLElement>(".__floater__open");
    const meditationStats =
      document.querySelector<HTMLElement>(".meditationStats");

    if (index === 0 && type === "step:after") {
      if (elementOverlay) {
        elementOverlay.style.removeProperty("background-color");
      }
      setTutorialStep(1);
    }
    if (index === 1) {
      if (meditationStats) {
        meditationStats.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
      }
      if (toolTipBox) {
        setTimeout(() => {
          toolTipBox.style.top = "25px";
        }, 450);
      }
    }
    if (index === 1 && type === "step:after") {
      if (toolTipBox) {
        toolTipBox.style.removeProperty("margin-top");
      }
      if (meditationStats) {
        meditationStats.style.removeProperty("background-color");
      }
      setTutorialStep(2);
    }
    if (index === 2 && type === "step:after") {
      document.body.style.overflow = "hidden";
      setTimeout(() => {
        setTutorialStep(3);
      }, 500);
    }
    if (index === 3) {
      if (elementOverlay) {
        elementOverlay.style.marginTop = "1px";
        elementOverlay.style.borderRadius = "10px";
      }
    }
    if (index === 3 && type === "step:after") {
      document.body.style.overflow = "unset";
      if (meditationStats) {
        meditationStats.style.removeProperty("background-color");
      }
      setState({ run: false });
      if (!myProfileTutorial) {
        //  alert(true);
        SLStorage.setItem("myProfileTutorial", true);
      }
    }
    if (finishedStatuses.includes(status)) {
      document.body.style.overflow = "unset";
      if (meditationStats) {
        meditationStats.style.removeProperty("background-color");
      }
      setState({ run: false });
      if (!myProfileTutorial) {
        //  alert(true);
        SLStorage.setItem("myProfileTutorial", true);
      }
    }
  };
  return (
    <Box display="flex" flexDirection="column" gap={5}>
      {!guestLogin && !myProfileTutorial ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton={true}
          run={run}
          scrollToFirstStep={false}
          showProgress={false}
          showSkipButton={true}
          disableOverlayClose={true}
          spotlightPadding={0}
          stepIndex={tutorialStep}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      ) : null}

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: { xs: "column", md: "row" },
          justifyContent: { xs: "center", sm: "flex-start" },
        }}
      >
        <Avatar
          tabIndex={0}
          alt="user's profile image"
          src={userImage ? userImage : DefaultUserImage}
          sx={{
            width: { xs: "120px", lg: "170px" },
            height: { xs: "120px", lg: "170px" },
            marginLeft: { xs: "0px" },
            marginTop: { xs: "0px" },
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            justifyContent: "flex-center",
            alignItems: "center",
          }}
        />

        <Stack
          spacing={1}
          sx={{
            marginTop: { xs: "0px" },
            textAlign: { xs: "center", md: "left" },
            paddingLeft: { xs: "0px", md: "25px", lg: "30px" },
          }}
        >
          {!guestLogin ? (
            <div className="userPersonalInfo">
              <SLTypography
                variant="headingLarge"
                color="text-deepPurple"
                fontWeight="600"
                sx={{
                  paddingTop: { xs: "16px", md: "0px" },
                  fontSize: { xs: "1.5rem", lg: "2rem" },
                  fontWeight: { xs: "800", sm: "600" },
                }}
                tabIndex={0}
                title={userProfileData?.fullName}
                aria-label={userProfileData?.fullName}
              >
                {userProfileData?.fullName}
              </SLTypography>
              <SLTypography
                color="text-deepPurple"
                sx={{
                  fontSize: { xs: "1rem", lg: "1.25rem" },
                  fontWeight: "500",
                }}
                tabIndex={0}
                title={userProfileData?.email}
                aria-label={userProfileData?.email}
              >
                {userProfileData?.email}
              </SLTypography>
              <SLTypography
                messageId="profile.editAccount"
                onClick={() => setOpenDialogBox(true)}
                onKeyPress={() => setOpenDialogBox(true)}
                color="text-purple"
                sx={{
                  cursor: "pointer",
                  textDecoration: "underline",
                  fontSize: { xs: "0.8rem", lg: "1rem" },
                  fontWeight: "500",
                }}
                tabIndex={0}
              />
            </div>
          ) : (
            <>
              {" "}
              <SLTypography
                sx={{
                  color: "var(--sl-deep-purple)",
                  textAlign: { xs: "center", md: "left" },
                  fontSize: { xs: "18px", sm: "20px", md: "22px", lg: "24px" },
                  fontWeight: "600",
                  lineHeight: "24px",
                  paddingBottom: "0px",
                  paddingTop: { xs: "15px", sm: "5px", md: "0px", lg: "5px" },
                }}
                tabIndex={0}
              >
                Welcome
              </SLTypography>
              <SLTypography
                color="text-deepPurple"
                sx={{
                  textAlign: { xs: "center", md: "left" },
                  fontSize: { xs: "12px", md: "14px" },
                  fontWeight: "600",
                  paddingBottom: "0px",
                  paddingTop: "0px",
                }}
                tabIndex={0}
              >
                Login to your account
              </SLTypography>
              <Button
                className="buildMeditationBtn"
                onClick={() => {
                  navigate("/login");
                }}
                onKeyPress={() => {
                  navigate("/login");
                }}
              >
                <SLTypography
                  variant="title"
                  sx={{ fontWeight: "500", letterSpacing: "0.5px" }}
                >
                  Log in
                </SLTypography>
              </Button>
              <SLTypography
                variant="title"
                sx={{
                  fontWeight: "500",
                  textAlign: { xs: "center", md: "left" },
                  letterSpacing: "0.5px",
                  paddingTop: "5px",
                  fontSize: { xs: "12px", md: "14px" },
                  color: "var(--sl-deep-purple)",
                  display: "block",
                }}
              >
                New to Soul Link?{" "}
                <Link
                  href="/signup"
                  role="link"
                  aria-label=" New to Soul Link sign up"
                  sx={{
                    color: "var(--sl-purple)",
                    textDecoration: "none",
                    borderBottom: "1px solid currentColor",
                  }}
                >
                  <FormattedMessage id="onboarding.signup" />
                </Link>
              </SLTypography>
            </>
          )}
        </Stack>
      </Box>

      {!guestLogin ? (
        <EditAccountInfo
          openDialog={openDialogBox}
          setOpenDialog={setOpenDialogBox}
        />
      ) : null}

      {!guestLogin ? (
        <>
          <Grid container spacing={3}>
            <Grid item lg={6} xs={12}>
              <DailyMeditaionGoal
                goalData={profileStats?.goal}
                setStatsUpdate={setStats}
                loading={profileStatsLoading}
              />
            </Grid>
            <Grid item lg={6} xs={12}>
              <WeeklyMeditaionGoal
                goalData={profileStats?.last10DaysStats}
                loading={profileStatsLoading}
              />
            </Grid>
          </Grid>

          <Box
            display="flex"
            flexDirection="column"
            gap={4}
            className="meditationStats"
          >
            <MeditationStatistics
              meditationstatistic="Meditation Statistics"
              allTime="All Time"
              downArrowPurpleIcon={ArrowDownPurple}
              sessions="Completed Sessions"
              sessionCount={statsByDate?.totalCompletedSessions}
              sessionTime="Total Minutes"
              sessionTimeCount={statsByDate?.totalMeditationTime}
              setMeditationTime={setMeditationTime}
              loading={statsByDateLoading}
            />
            <MeditationStatistics
              meditationstatistic="Meditation Streaks"
              allTime=""
              downArrowPurpleIcon={""}
              sessions="Current Streak (Days)"
              sessionCount={profileStats?.goal?.user?.currentStreak}
              sessionTime="Longest Streak (Days)"
              sessionTimeCount={profileStats?.goal?.user?.longestStreak}
              loading={profileStatsLoading}
            />
          </Box>

          <Achievements
            achievementsData={profileStats?.achievements}
            reload={() => {
              if (Auth.user.loggedin()) {
                dispatch(SLThunk.getStatsAll.call({}));
              }
            }}
          />
          <Divider />

          <LougoutButton getUserProfile={userProfileData} />
        </>
      ) : null}
    </Box>
  );
};

export default ProfileDetails;
