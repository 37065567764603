declare let Castjs: any;

export function useCjs() {
  return RCastjs.getInstance();
}

class RCastjs {
  static instance: ICastjs;

  public static getInstance() {
    if (!RCastjs.instance) {
      RCastjs.instance = new Castjs();
      // console.warn(RCastjs.instance);
    }

    return RCastjs.instance;
  }
}

interface ICastjs {
  /**   Create session with media */
  cast: (source: string, metadata: CJSMetadata) => void;

  /** Change volume */
  volume: (volume: number) => void;

  /** Play media */
  play: () => void;

  /** Pause media */
  pause: () => void;

  /** Mutes media */
  mute: () => void;

  /** Unmutes media */
  unmute: () => void;

  /** Seek 15.9% percentage */
  seek: (number: number, bool?: boolean) => void;

  /** Disconnect session */
  disconnect: () => void;

  // Castjs Events

  on: (
    event:
      | "available" // Casting is available
      | "connect" // Connected with device
      | "disconnect" // Disconnected with device
      | "statechange" // Device state
      | "timeupdate" // Current time changed
      | "volumechange" // Volume changed
      | "mute" // Muted state changed
      | "unmute" // Muted state changed
      | "playing" // Media is playing
      | "pause" // Media is paused
      | "end" // Media ended
      | "buffering" // Media is buffering / seeking
      | "event" // Catch all events except 'error'
      | "error", // Catch any errors
    cb: (e?: string) => any
  ) => void;

  off: (
    event:
      | "available" // Casting is available
      | "connect" // Connected with device
      | "disconnect" // Disconnected with device
      | "statechange" // Device state
      | "timeupdate" // Current time changed
      | "volumechange" // Volume changed
      | "mute" // Muted state changed
      | "unmute" // Muted state changed
      | "playing" // Media is playing
      | "pause" // Media is paused
      | "end" // Media ended
      | "buffering" // Media is buffering / seeking
      | "event" // Catch all events except 'error'
      | "error", // Catch any errors
    cb: () => any
  ) => void;

  // Castjs properties

  /** Castjs Version */
  version: number;

  /** Receiver ID */
  receiver: string;

  /** Casting is available */
  available: boolean;

  /** Connected with cast device */
  connected: boolean;

  /** Cast device name */
  device: string;

  /** Media source */
  src: string;

  /** Media title */
  title: string;

  /** Media description */
  description: string;

  /** Media poster image */
  poster: string;

  /** Volume level */
  volumeLevel: number;

  /** If muted */
  muted: boolean;

  /** If paused */
  paused: boolean;

  /** Time in seconds */
  time: number;

  /** Time formatted in time hh:mm:ss */
  timePretty: string;

  /** Duration in seconds */
  duration: number;

  /** Duration formatted in hh:mm:ss */
  durationPretty: string;

  /** Progress in percentage 0 - 100 */
  progress: number;

  /** State of cast device */
  state: string;
}

interface CJSMetadata {
  poster?: string;
  title?: string;
  description?: string;
  subtitles?: {
    active?: boolean;
    label?: string;
    src: string;
  }[];
}
