import AppleIcon from "@mui/icons-material/Apple";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import GoogleIcon from "@mui/icons-material/Google";
import { Avatar, Link, Stack } from "@mui/material";
import { signInWithPopup } from "firebase/auth";
import { SocialLoginTypes } from "helpers/enum";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  appleProvider,
  authFirebase,
  deviceId,
  fbprovider,
  googleProvider,
} from "utils/firebase";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import ErrorDialogBox from "../dialogBoxes/errorDialog";
import { SLLoader } from "../loader";

export const SocialMedia = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    data: socialLoginData,
    loading,
    error,
  } = useSelector(SLSelector.postAuthLoginSocial);
  const registerSocialResult = useSelector(SLSelector.postAuthRegisterSocial);

  const [userData, setUserData] = useState(Object);
  const [tokenSocial, setToken] = useState<string>("");
  const [flag, setFlag] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [errorPopupToggle, setErrorPopupToggle] = useState(false);
  const [loader, setLoader] = useState(false);
  const [errorMessages, setErrorMessages] = useState({
    header: "This option is unavailable",
    content: "",
  });

  useEffect(() => {
    setOpenDialog(false);
  }, []);

  useEffect(() => {
    if (!socialLoginData && flag) {
      if (userData?.email === "" && userData?.providerData[0]?.email === "") {
        setLoader(false);
        setOpenDialog(true);
        setErrorMessages({
          ...errorMessages,
          content: "Please, try another option",
        });
        return;
      }

      dispatch(
        SLThunk.postAuthRegisterSocial.call({
          fcmToken: deviceId,
          platform: "web",
          deviceInfo: deviceId,
          fullName: userData.displayName ?? "",
          email: userData?.email ?? userData?.providerData[0]?.email ?? "",
          socialId: tokenSocial,
        })
      );
      setFlag(false);
    } else if (socialLoginData) {
      setOpenDialog(false);
    }
    if (
      window.location.pathname.includes("login") &&
      error ===
        "Please use your email and password for login. The account linked to this email requires standard login."
    ) {
      setLoader(false);
      setOpenDialog(true);
      setErrorMessages({
        ...errorMessages,
        content: error,
        header: "Incorrect Login Method!",
      });
    }
  }, [error, socialLoginData]);

  useEffect(() => {
    if (registerSocialResult.data) {
      setOpenDialog(false);
      navigate("/additionalInfo");
    }
    if (registerSocialResult.error) {
      setLoader(false);
      setOpenDialog(true);
      setErrorMessages({
        ...errorMessages,
        content: registerSocialResult.error,
        header: "Oops!",
      });
    }
  }, [registerSocialResult]);

  const dispatchLoginCall = (email: string, socialId: string) => {
    if (email === "") {
      setLoader(false);
      setOpenDialog(true);
      setErrorMessages({
        ...errorMessages,
        content: "Please, try another option",
      });
      return;
    }
    setFlag(true);
    dispatch(
      SLThunk.postAuthLoginSocial.call({
        fcmToken: deviceId,
        platform: "web",
        deviceInfo: deviceId,
        email: email,
        socialId: socialId,
      })
    );
  };

  const loginWithSocialAccounts = async (socialLogin: string) => {
    setLoader(true);
    let provider: any;
    if (socialLogin === SocialLoginTypes.Apple) {
      provider = appleProvider;
      provider.setCustomParameters({
        // Localize the Apple authentication screen in English.
        locale: "en",
      });
      provider.addScope("name");
    } else if (socialLogin === SocialLoginTypes.Facebook) {
      provider = fbprovider;
      provider.setCustomParameters({
        display: "popup",
      });
    } else {
      provider = googleProvider;
      provider.setCustomParameters({
        login_hint: "user@example.com",
      });
    }
    provider.addScope("email");
    signInWithPopup(authFirebase, provider)
      .then(async (res) => {
        setErrorPopupToggle(true);
        const token = await res.user.getIdToken();
        // await dispatch(
        //   SLThunk.postFileUploadUserimageSocial.call({
        //     url: res.user.photoURL ?? "",
        //   })
        // );
        if (token === "") {
          setLoader(false);
          setOpenDialog(true);
          setErrorMessages({
            ...errorMessages,
            content: "Please, try another Social Network or use your email",
          });
        }
        setToken(token);
        setUserData(res.user);
        const user = res.user;
        const userEmail = user.email ?? user.providerData[0]?.email ?? "";
        dispatchLoginCall(userEmail, token);
      })
      .catch((error) => {
        setLoader(false);
        if (error.code === "auth/popup-closed-by-user") {
          setErrorPopupToggle(true);
          setOpenDialog(true);
          setErrorMessages({
            ...errorMessages,
            content: "This operation was cancelled by the user.",
          });
        }
      });
  };

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        justifyContent="center"
        sx={{ position: "relative", marginTop: "20px" }}
      >
        <Link
          onClick={() => loginWithSocialAccounts("Google")}
          onKeyPress={() => loginWithSocialAccounts("Google")}
          role="button"
          tabIndex={0}
          title={
            window.location.href.includes("/login")
              ? "Sign in with Google"
              : "Sign up with Google"
          }
        >
          <Avatar
            sx={{
              bgcolor: "#762b90 !important",
              cursor: "pointer",
              width: "90px",
              height: "50px",
              borderRadius: "10px",
              marginLeft: "6px !important",
            }}
          >
            <GoogleIcon sx={{ color: "white", opacity: "1" }} />
          </Avatar>
        </Link>
        <Avatar
          title={
            window.location.href.includes("/login")
              ? "Sign in with Facebook"
              : "Sign up with Facebook"
          }
          tabIndex={0}
          role="button"
          onKeyPress={() => loginWithSocialAccounts("Facebook")}
          onClick={() => loginWithSocialAccounts("Facebook")}
          sx={{
            bgcolor: "#762b90 !important",
            cursor: "pointer",
            width: "90px",
            height: "50px",
            borderRadius: "10px",
            marginLeft: "6px !important",
          }}
        >
          <FacebookOutlinedIcon sx={{ color: "white", opacity: "1" }} />
        </Avatar>
        <Avatar
          title={
            window.location.href.includes("/login")
              ? "Sign in with Apple"
              : "Sign up with Apple"
          }
          tabIndex={0}
          onKeyPress={() => loginWithSocialAccounts("Apple")}
          onClick={() => loginWithSocialAccounts("Apple")}
          sx={{
            bgcolor: "#762b90 !important",
            cursor: "pointer",
            width: "90px",
            height: "50px",
            borderRadius: "10px",
            marginLeft: "6px !important",
          }}
        >
          <AppleIcon
            sx={{
              color: "white",
            }}
          />
        </Avatar>
      </Stack>
      {errorPopupToggle && (
        <ErrorDialogBox
          errorDialog={openDialog}
          errorHeaderMessage={errorMessages.header}
          setErrorDialog={setOpenDialog}
          errorMessage={errorMessages.content}
        />
      )}
      <SLLoader open={loading || registerSocialResult.loading || loader} />
    </>
  );
};
