import { SLTypography } from "components/slTypography";
import { useEffect } from "react";
import { DialogActionButtons } from "../../dialogBoxes/DialogActionButtons";
import SLDialog from "../../dialogBoxes/slDialog";
import { useDialog } from "../../dialogBoxes/slDialog/useDialog";
import { ReduceMotionSetting } from "../../setting/emailCalendar";

export const ReduceMotionPopup = ({ open }: { open: boolean }) => {
  const dialog = useDialog({ title: "Animations", show: open });

  useEffect(() => {
    if (open) {
      dialog.show();
    } else {
      dialog.hide();
    }
  }, [open]);

  const closePopup = () => {
    dialog.hide();
  };

  return (
    <SLDialog dismissable={true} dialog={dialog} onDismiss={closePopup}>
      <ReduceMotionSetting popupDesign={true} />

      <SLTypography
        tabIndex={0}
        variant="smallBodyCopy"
        sx={{ padding: "0 15px" }}
        messageId="app.setting.reduceMotion.description"
      />

      <DialogActionButtons
        yesMessageId="general.close"
        onYesButtonClicked={closePopup}
        noMessageId={false}
      />
    </SLDialog>
  );
};
