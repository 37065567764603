import { PageHeading } from "components/pageHeading";
import MySubscriptionScreen from "components/profile/subscription";
import { lang } from "helpers/common/lang";
import ProfilePages from "layouts/profilePages";

const MySubscriptionPage = () => {
  return (
    <>
      <ProfilePages title={lang("profile.mySubscription")}>
        <>
          <PageHeading messageId="profile.mySubscription" />

          <MySubscriptionScreen />
        </>
      </ProfilePages>
    </>
  );
};

export default MySubscriptionPage;
