import CheckIcon from "@mui/icons-material/Check";
import { Box, List, ListItem, ListItemText } from "@mui/material";

const VideoQualityPopUp = () => {
  return (
    <>
      <Box sx={{ width: { lg: "25%", md: "80%", xs: "99%" } }}>
        <List>
          <ListItem>
            <ListItemText primary="1080p" />
            <CheckIcon />
          </ListItem>
          <ListItem>
            <ListItemText primary="760p" />
          </ListItem>
          <ListItem>
            <ListItemText primary="480p" />
          </ListItem>{" "}
          <ListItem>
            <ListItemText primary="360p" />
          </ListItem>{" "}
          <ListItem>
            <ListItemText primary="240p" />
          </ListItem>
          <ListItem>
            <ListItemText primary="144p" />
          </ListItem>{" "}
          <ListItem>
            <ListItemText primary="Auto" />
          </ListItem>
        </List>
      </Box>
    </>
  );
};

export default VideoQualityPopUp;
