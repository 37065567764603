import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import SLDialog from "../slDialog";
import { useDialog } from "../slDialog/useDialog";

interface ErrorDialogBoxProps {
  errorDialog: boolean;
  setErrorDialog: (value: boolean) => void;
  errorHeaderMessage?: string;
  errorMessage?: string;
  navigateUrl?: string;
}

const ErrorDialogBox = ({
  errorDialog,
  setErrorDialog,
  errorMessage,
  errorHeaderMessage,
  navigateUrl = "",
}: ErrorDialogBoxProps) => {
  const dialog = useDialog();
  const navigate = useNavigate();
  useEffect(() => {
    if (errorDialog) {
      dialog.show({ title: errorHeaderMessage, message: errorMessage });
    } else {
      dialog.hide();
    }
  }, [errorDialog]);
  const handleDialogClose = () => {
    setErrorDialog(false);
    if (navigateUrl) navigate(navigateUrl);
  };
  return (
    <SLDialog
      dismissable={true}
      onDismiss={() => setErrorDialog(false)}
      dialog={dialog}
      actionButtons={{
        yesMessageId: "general.ok",
        onYesButtonClicked: () => handleDialogClose(),
        noMessageId: false,
      }}
    />
  );
};

export default ErrorDialogBox;
