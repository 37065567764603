import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { reduceMotionState } from "store/reduceMotionSlice";

const ExploreHeader = (props: any) => {
  const reduceMotion = useSelector(reduceMotionState);

  return (
    <>
      <Box
        sx={{
          position: "relative",
          height: "400px",
          backgroundImage: [
            props.animationImage && !reduceMotion
              ? `url(${props.animationImage})`
              : null,
            props.staticImage ? `url(${props.staticImage})` : null,
          ]
            .filter((r) => r)
            .join(","),
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundOrigin: "center-box",
          color: "white",
        }}
      >
        {!reduceMotion && props.videoSource ? (
          <video autoPlay={true} muted loop playsInline id="myVideo">
            <source src={props.videoSource} />
          </video>
        ) : null}
      </Box>
    </>
  );
};

export default ExploreHeader;
