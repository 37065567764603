import {
  Avatar,
  Backdrop,
  BackdropProps,
  Box,
  CircularProgress,
  Stack,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import SLLogo from "../../assets/images/logo.svg";
import "./index.css";
type SLLoaderProps = BackdropProps & { open: boolean };

export const SLLoader = ({ open, ...props }: SLLoaderProps) => {
  return (
    <Backdrop {...props} open={open} className="backdrop-blur">
      <Stack direction="column" spacing="20px">
        <Box sx={{ position: "relative" }}>
          <Avatar
            className="rotate"
            alt="Loading Image"
            src={SLLogo}
            sx={{ width: 120, height: 120 }}
          />
          <CircularProgress
            size={140}
            sx={{
              color: "var(--sl-light-aqua)",
              position: "absolute",
              top: -10,
              left: -10,
              width: 2,
              zIndex: 1,
            }}
          />
        </Box>

        <SLTypography
          variant="smallBody"
          color="text-lightAqua"
          textAlign="center"
          tabIndex={0}
        >
          Loading...
        </SLTypography>
      </Stack>
    </Backdrop>
  );
};
