import { Box, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { onEnterOrSpace } from "helpers/common/keypress";
import { useAppUrls } from "helpers/url";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SLSelector } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";

export function DailyMeditation(props: any) {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const guestLogin = SLStorage.getItem("guestLogin");

  const { data: userSubscription } = useSelector(SLSelector.getSubscription);

  const handleSingup = () => {
    if (guestLogin || !userSubscription) {
      props.setGuestError(true);
      return false;
    }
    // sessionStorage.removeItem("guestAuth");
    navigate(`/app/play-back/${props.dailyMeditation?.id}`);
  };

  return props.dailyMeditation ? (
    <Box
      role="link"
      tabIndex={0}
      onClick={handleSingup}
      onKeyUp={onEnterOrSpace(handleSingup)}
      sx={{
        aspectRatio: "16/9",
        background: `url(${appUrls.mediaImage(props.dailyMeditation?.image)})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        borderRadius: "10px",
        cursor: "pointer",
        width: { lg: "530px", md: "440px", xs: "390px" },
        maxWidth: "100%",
        marginTop: { lg: "0", md: "35px", sm: "0px" },
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 0,
          height: "83px",
          width: "100%",
          background:
            "linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(0, 0, 0, 0.00) 100%)",
        }}
      />
      <Box
        sx={{
          position: "absolute",
          bottom: 0,
          height: "160px",
          width: "100%",
          background:
            "linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%)",
        }}
      />

      <Stack sx={{ padding: "15px" }}>
        <SLTypography
          aria-hidden="true"
          sx={{
            color: "white",
            fontSize: {
              xs: "10px",
              sm: "12px",
            },
            fontWeight: "600",
            zIndex: 1,
          }}
        >
          {props.dailyMeditation.duration} : 00
        </SLTypography>

        <Box
          sx={{
            position: "absolute",
            bottom: "20px",
          }}
        >
          <SLTypography
            variant="heading"
            sx={{
              display: "block",
              color: "#FFFFFF",
              fontSize: {
                md: "27px",
                xs: "21px",
              },
              lineHeight: {
                md: "30px",
                xs: "25px",
              },
              letterSpacing: "-0.3px",
            }}
          >
            Begin Your
          </SLTypography>
          <SLTypography
            variant="heading"
            sx={{
              display: "block",
              color: "#FFFFFF",
              fontSize: {
                md: "27px",
                xs: "19px",
              },
              lineHeight: {
                md: "30px",
                xs: "15px",
              },
              letterSpacing: "-0.3px",
            }}
          >
            Daily Meditation
          </SLTypography>
        </Box>
      </Stack>
    </Box>
  ) : (
    <></>
  );
}
