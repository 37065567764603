import PsychologyOutlined from "@mui/icons-material/PsychologyOutlined";
import { Box, List, ListItem } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  ArrowForwardIosIcon,
  FaceBookIcon,
  InstagramIcon,
  YouTubeIcon,
} from "helpers/enum/constants";
import { Link } from "react-router-dom";

const profileLinks = [
  {
    iconButton: <PsychologyOutlined sx={{ width: "18px", height: "18px" }} />,
    name: "Help Center",
    redirectionLink: "/app/help-center/faq",
  },
  {
    iconButton: (
      <Box
        component="img"
        className="purple-icon"
        src={FaceBookIcon}
        alt="Like us on Facebook"
        sx={{ width: "18px", height: "18px" }}
      />
    ),
    name: "Like us on Facebook",
    redirectionLink:
      "https://www.facebook.com/people/Soul-Link/100093361506392/",
  },
  {
    iconButton: (
      <Box
        component="img"
        className="purple-icon"
        src={YouTubeIcon}
        alt="Subscribe on YouTube"
        sx={{ width: "18px", height: "18px" }}
      />
    ),
    name: "Subscribe on YouTube",
    redirectionLink: "https://www.youtube.com/@SoulLinkOfficial",
  },
  {
    iconButton: (
      <Box
        component="img"
        className="purple-icon"
        src={InstagramIcon}
        alt="Follow us on Instagram"
        sx={{ width: "18px", height: "18px" }}
      />
    ),
    name: "Follow us on Instagram",
    redirectionLink: "https://www.instagram.com/soul_link_official/",
  },
];

const NavigationContactUs = () => {
  return (
    <>
      <List sx={{ width: 1 }}>
        {profileLinks.map((data: any, index) => {
          const isExternal = data.redirectionLink.startsWith("http");

          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  key={"sidebaritem_id" + index}
                  {...{
                    component: isExternal ? "a" : Link,
                    to: isExternal ? undefined : data.redirectionLink,
                    href: isExternal ? data.redirectionLink : undefined,
                    target: isExternal ? "_blank" : undefined,
                  }}
                  style={{ textDecoration: "none", width: "100%" }}
                >
                  <ListItem
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",

                      marginBottom: "10px",
                      borderRadius: "10px",
                      background: "#FFFFFF",
                      cursor: "pointer",
                      color: "var(--sl-deep-purple)",
                      height: "50px",
                      textDecoration: "none",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        fontSize: { xs: "14px" },
                      }}
                    >
                      {data.iconButton}

                      <SLTypography
                        variant="title"
                        sx={{ fontSize: { xs: "14px" } }}
                        title={data.name}
                      >
                        {data.name}
                      </SLTypography>
                    </Box>
                    <ArrowForwardIosIcon sx={{ fontSize: "12px" }} />
                  </ListItem>
                </Box>
              </Box>
            </>
          );
        })}
      </List>
    </>
  );
};

export default NavigationContactUs;
