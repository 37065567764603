import { Skeleton } from "@mui/material";
import { ParagraphSkeleton } from "./ParagraphSkeleton";

interface IParagraphWithShowMoreSkeleton {
  lines?: number;
  fontSize?: number;
}

export function ParagraphWithShowMoreSkeleton(
  props: IParagraphWithShowMoreSkeleton
) {
  return (
    <>
      <ParagraphSkeleton lines={props.lines} fontSize={props.fontSize} />

      <Skeleton
        animation="wave"
        height="1rem"
        width="3rem"
        sx={{
          marginTop: "1rem",
          marginBottom: 0,
          transform: "unset",
        }}
      />
    </>
  );
}
