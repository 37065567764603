import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { makeSLAPICall } from "./axiosConfigs";
import { Dict, SLAPIThunkType, SLReduxStates } from "./types";

const defaultInitialState = <T>(): SLReduxStates<T> => ({
  loading: false,
});

const generateSliceName = (method: string, url: string) =>
  `${method.toLowerCase()}${url
    .split(/[\/{}-]/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join("")}`;

function getInputFunction(
  method: string,
  url: string,
  thunkType: SLAPIThunkType
): (...data: any) => Promise<any> {
  // console.log(method, url, thunkType);
  if (thunkType === "Request") {
    return async (urlParams?: Dict<string>, headers?: Dict<string>) => {
      return await makeSLAPICall({ method, url, urlParams, headers });
    };
  } else if (thunkType === "PayloadRequest") {
    return async (
      payload: any,
      urlParams?: Dict<string>,
      headers?: Dict<string>
    ) => {
      return await makeSLAPICall({ method, url, payload, urlParams, headers });
    };
  } else if (thunkType === "QueryRequest") {
    return async (
      params: any,
      urlParams?: Dict<string>,
      headers?: Dict<string>
    ) => {
      return await makeSLAPICall({ method, url, params, urlParams, headers });
    };
  } else {
    return async (
      payload: any,
      params: any,
      urlParams?: Dict<string>,
      headers?: Dict<string>
    ) => {
      return await makeSLAPICall({
        method,
        url,
        payload,
        params,
        urlParams,
        headers,
      });
    };
  }
}
function generateSingleRedux(
  method: string,
  url: string,
  thunkType: SLAPIThunkType,
  whiteListed: boolean,
  extraActionOnFulfillment?: (data: ResponseType) => void
) {
  const name = generateSliceName(method, url);
  const thunk = createAsyncThunk(
    `${name}Store`,
    getInputFunction(method, url, thunkType)
  );
  const slice = createSlice({
    name,
    initialState: defaultInitialState<ResponseType>(),
    reducers: {
      reset: () => defaultInitialState<ResponseType>(),
    },
    extraReducers: (builder) => {
      builder
        .addCase(thunk.pending, () => ({ loading: true }))
        .addCase(thunk.fulfilled, (state, action) => {
          extraActionOnFulfillment?.(action.payload);
          return {
            loading: false,
            error: undefined,
            data: action.payload,
          };
        })
        .addCase(thunk.rejected, (state, action) => ({
          loading: false,
          error: action.error.message,
          data: undefined,
        }));
    },
  });
  const selector = (name: string) => (r: any) => {
    // console.log(r.SLReducers[name]);
    return r.SLReducers[name] as SLReduxStates<ResponseType>;
  };
  const whiteListedSelector = (name: string) => (r: any) => {
    // console.log(r.SLReducers[name]);
    return r.SLWhiteListedReducers[name] as SLReduxStates<ResponseType>;
  };
  return {
    name,
    thunk,
    reset: slice.actions.reset,
    reducer: slice.reducer,
    selector: whiteListed ? whiteListedSelector(name) : selector(name),
  };
}

export function generateRedux(apiInfoDict: any) {
  const finalResponse: any = {
    reducers: {},
    thunks: {},
    selectors: {},
    whiteListedReducers: {},
  };
  for (const method of Object.keys(apiInfoDict)) {
    const urlInfoDict = apiInfoDict[method];
    for (const url of Object.keys(urlInfoDict)) {
      const apiInfo = urlInfoDict[url];
      const { name, reducer, selector, thunk, reset } = generateSingleRedux(
        method,
        url,
        apiInfo.thunkType,
        apiInfo.whiteListed,
        apiInfo.extraActionOnFulfillment
      );
      finalResponse.selectors[name] = selector;
      finalResponse.thunks[name] = {
        call: thunk,
        reset,
      };
      if (apiInfo.whiteListed) {
        finalResponse.whiteListedReducers[name] = reducer;
      } else {
        finalResponse.reducers[name] = reducer;
      }
    }
  }
  // console.log(finalResponse.whiteListedReducers);
  return finalResponse;
}
