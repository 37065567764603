import enUS from "../../lang/en.json";

export type IntlMessageID = keyof typeof enUS;

export enum ButtonTypes {
  ButtonBlue = "blueButton",
  ButtonGray = "grayButton",
  ButtonBorder = "borderButton",
}

export enum MediaCardType {
  MeditationCard = "Meditation",
  MediaCard = "Media",
}
export enum SocialLoginTypes {
  Apple = "Apple",
  Facebook = "Facebook",
  Google = "Google",
}

export enum UserRegisterType {
  AppleRegister = "apple.com",
  GoogleRegister = "google.com",
  FacebookRegister = "facebook.com",
  EmailRegister = "email",
}
export enum CardSelection {
  CreateMeditation = "createMeditation",
  Artist = "artist",
  MySelectedMaditation = "mySelectedMaditation",
  AuthorDetail = "authorDetail",
  MyListFavouritesCards = "myListFavouriteCards",
}
export enum MediaType {
  Audio = "audio",
  Video = "video",
  FrequencyWave = "frequencyWave",
  Notes = "notes",
  // SuggestedMeditations = "suggestedMeditation",
}
export enum MediaDetailPages {
  Explore = "explore",
  MyList = "myList",
}

export enum MeditationType {
  RecentMeditation = "recent-meditation",
  CreatedMeditation = "created-meditation",
  FavoriteMeditation = "favorite-meditation",
  SuggestedMeditation = "suggested-meditation",
}
