import { Box, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { SLTypography } from "components/slTypography";
import { MiniplayerContext } from "contexts/miniplayerContext";
import { onEnterOrSpace } from "helpers/common/keypress";
import {
  CloseFullScreen,
  ClosedCaptionDisabledIcon,
  ClosedCaptionIcon,
  FastForward,
  FastRewind,
  FullScreen,
  GoogleCast,
  InfoOutlined,
  LoopPlayIcon,
  LoopStopIcon,
  MuteVolume,
  Pause,
  Play,
  Volume,
} from "helpers/enum/constants";
import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLThunk } from "utils/slRTKHelper";
import { SLMediaFileDetail } from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";
import { PrettoSlider, VolumeIcon, VolumeSlider } from "../videoControls";
import {
  IQualityLevel,
  QualitySelector,
} from "../videoControls/QualitySelector";
const guestLogin = SLStorage.getItem("guestLogin");
const useStyles = makeStyles((theme: any) => ({
  controlsWrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    height: "100%",
    visibility: "visible",
    zIndex: 3,
  },
  topContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "33%",
    padding: "0 16px",
    paddingTop: "10px",
  },
  midContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    height: "33%",
  },
  iconBtn: {
    cursor: "pointer",
    color: "white",
  },
  bottomContainer: {
    display: "flex",
    alignItems: "flex-end",
    gap: "20px",
    height: "33%",
    padding: "0 16px",
    paddingBottom: "10px",
    boxSizing: "border-box",
  },
  videoTimer: {
    color: "white",
    fontSize: "0.8rem",
    fontFamily: "Figtree !important",
  },
  volumeSlider: {
    width: "100px",
    color: "white",
  },
}));

interface PlayBackProps {
  controlsRef?: any;
  castingConnected: boolean;
  castingAvailable: boolean;
  onCastConnect: () => void;
  captionsAvailable: boolean;
  captionsShowing: boolean;
  onClosedCaptions: () => void;
  completion: number;
  currentQualityLevel: number;
  elapsedTime: string;
  mediaType: string;
  mediaData: SLMediaFileDetail;
  muted: boolean;
  loop: boolean;
  setLoopValue: (bool: boolean) => any;
  onToggleFullScreen: any;
  onChangeDispayFormat: () => void;
  onFastForward: () => void;
  onMute: () => void;
  onPause: () => void;
  onPlay: () => void;
  onRewind: () => void;
  onSeekChange: (e: any, value: any) => void;
  onSeekMouseUp: (e: any, value: any) => void;
  onVolumeChange: (e: any, value: any) => void;
  playing: boolean;
  buffer: boolean;
  qualityLevels: IQualityLevel[];
  selectedQualityLevel: number;
  setSelectedQualityLevel: (n: number) => any;
  totalDuration: string;
  volume: number;
  isFullScreen: boolean;
  onSeek: (...props: any) => any;
  openCompletionPopup: (bool: boolean) => any;
}

const AudioControls = ({
  captionsAvailable,
  captionsShowing,
  castingConnected,
  castingAvailable,
  onCastConnect,
  onClosedCaptions,
  setLoopValue,
  loop,
  controlsRef,
  mediaData,
  mediaType,
  playing,
  buffer,
  volume,
  muted,
  completion,
  elapsedTime,
  totalDuration,
  isFullScreen,
  onToggleFullScreen,
  onChangeDispayFormat,
  onFastForward,
  onMute,
  onPause,
  onPlay,
  onRewind,
  onSeekChange,
  onSeekMouseUp,
  onVolumeChange,
  currentQualityLevel,
  qualityLevels,
  selectedQualityLevel,
  setSelectedQualityLevel,
  openCompletionPopup,
  onSeek,
}: PlayBackProps) => {
  const buildMeditationLoader = sessionStorage.getItem("fromBuildMeditation");
  const mixpanel = useMixpanel();
  const classes = useStyles();
  const navigate = useNavigate();
  const { id } = useParams();
  const timeSpentWatching = useRef(0);
  const [showVolume, setShowVolume] = useState<boolean>(false);
  const dispatch = useDispatch();
  const { setMiniplayerState } = useContext(MiniplayerContext);
  const infoDetails = () => {
    navigate(`/app/file-detail/${id}`);
  };

  const MainSlider = (event: any) => {
    if (event.key === "ArrowRight") {
      onFastForward();
    } else if (event.key === "ArrowLeft") {
      onRewind();
    }
  };

  function isEligibleForStillMeditating(timestamp: number) {
    const currentTime = Date.now();
    const minutes = 60;

    return currentTime - timestamp > minutes * 60 * 1000;
  }

  useEffect(() => {
    timeSpentWatching.current++;
    if (elapsedTime && elapsedTime != "00:00" && elapsedTime != "0:00") {
      if (mediaType === "audio") {
        mixpanel.triggerTimeSpentWatchingAudioEvent(mediaData, elapsedTime);
      } else {
        mixpanel.triggerTimeSpentWatchingVideoEvent(mediaData, elapsedTime);
      }

      if (timeSpentWatching.current === 60) {
        if (mediaType === "audio") {
          mixpanel.triggerAudioViewedEvent(mediaData);
        } else {
          mixpanel.triggerVideoViewedEvent(mediaData);
        }
      }
    }
    if (buildMeditationLoader === "true") {
      if (timeSpentWatching.current % 10 === 0) {
        if (guestLogin !== true) {
          dispatch(SLThunk.patchStatsMeditationTime.call({}));
        }
      }
      // After 15 sec api call to recently played video
      if (timeSpentWatching.current === 15) {
        console.log("id===", id);
        if (guestLogin !== true)
          dispatch(SLThunk.patchMeditationPlayedId.call({ id: id ?? "" }));
      }
      // setMiniplayerState({
      //   playing,
      //   currentTime: elapsedTime,
      //   totalTime: totalDuration,
      //   track: { ...mediaData } as any,
      //   frequencyVolume: 0,
      // });

      // if (isEligibleForStillMeditating(interactionTime)) {
      //   setStillMeditating(true);
      // }

      // After completion of video api call
      if (
        elapsedTime == totalDuration &&
        elapsedTime != "0:00" &&
        elapsedTime !== "00:00"
      ) {
        if (!loop) {
          openCompletionPopup(true);
          dispatch(SLThunk.patchStatsCompleteSession.call({}));
        }
        if (loop && playing) {
          onSeek(null, 0);
          onPlay();
        }
      }
    }
  }, [elapsedTime]);

  const handleShowVolume = (volumeStaus: boolean) => {
    if (!showVolume) {
      setTimeout(() => {
        const slider = document.getElementById("volume-slider");
        if (slider) {
          const rail = slider.querySelector("input") as HTMLDivElement;
          if (rail) {
            rail.focus();
          }
        }
      }, 400);
    }

    setShowVolume(volumeStaus);
  };

  const handleLoop = () => {
    setLoopValue(!loop);
  };

  const handleToggleVolume = (e: any) => {
    volume ? onVolumeChange(e, 0) : onVolumeChange(e, 50);
  };
  return (
    <>
      <Grid
        container
        ref={controlsRef}
        className={"meditation-player " + classes.controlsWrapper}
        wrap="nowrap"
        flexDirection="column"
      >
        {/* title row */}
        <Grid className={classes.topContainer}>
          <Grid
            item
            xs={6}
            className="add-shadow"
            tabIndex={0}
            aria-label={
              mediaType + ": " + mediaData.title + ": " + mediaData.description
            }
          >
            <SLTypography
              variant="smallBodyCopy"
              color="text-white"
              sx={{ textTransform: "capitalize" }}
            >
              {mediaType}
            </SLTypography>
            <SLTypography
              variant="subhead"
              color="text-white"
              textOverflow="ellipsis"
              display="inline-block"
              width="100%"
              overflow="hidden"
              whiteSpace="nowrap"
              sx={{
                fontSize: {
                  lg: "27px",
                  md: "20px",
                  sm: "15px",
                  xs: "14px",
                },
                lineHeight: "normal",
              }}
            >
              {mediaData.title}
            </SLTypography>
          </Grid>

          <Grid item xs={6} style={{ textAlign: "right" }}>
            <div
              style={{
                position: "relative",
                left: 0,
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                gap: "20px",
              }}
            >
              {captionsAvailable ? (
                <img
                  role="button"
                  aria-selected={captionsShowing}
                  src={
                    captionsShowing
                      ? ClosedCaptionIcon
                      : ClosedCaptionDisabledIcon
                  }
                  title={
                    captionsShowing
                      ? "Closed Captions On, tap to turn off"
                      : "Closed Captions Off, tap to turn on"
                  }
                  onClick={onClosedCaptions}
                  onKeyPress={onEnterOrSpace(onClosedCaptions)}
                  style={{
                    cursor: "pointer",
                    color: "white",
                  }}
                  tabIndex={0}
                />
              ) : null}
              {mediaType !== "audio" && (
                <QualitySelector
                  qualityLevels={qualityLevels}
                  selectedQualityLevel={selectedQualityLevel}
                  setSelectedQualityLevel={setSelectedQualityLevel}
                  currentQualityLevel={currentQualityLevel}
                />
              )}

              <img
                style={{
                  width: "26px",
                  color: "white",
                  fill: "white",
                  cursor: "pointer",
                }}
                src={loop ? LoopPlayIcon : LoopStopIcon}
                onClick={() => handleLoop()}
                onKeyPress={() => handleLoop()}
                aria-label="Loop"
                title="Loop"
                tabIndex={0}
              />

              {mediaType !== "audio" && castingAvailable ? (
                <GoogleCast
                  aria-label="Start casting"
                  titleAccess="Start casting"
                  onClick={onCastConnect}
                  onKeyPress={onEnterOrSpace(onCastConnect)}
                  style={{
                    cursor: "pointer",
                    color: "white",
                  }}
                  tabIndex={0}
                />
              ) : null}

              {isFullScreen ? (
                <CloseFullScreen
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={onToggleFullScreen}
                  onKeyPress={onEnterOrSpace(onToggleFullScreen)}
                  aria-label="Close Screen"
                  titleAccess="Close Screen"
                  tabIndex={0}
                />
              ) : (
                <FullScreen
                  style={{ cursor: "pointer", color: "white" }}
                  onClick={onToggleFullScreen}
                  onKeyPress={onEnterOrSpace(onToggleFullScreen)}
                  aria-label="Full Screen"
                  titleAccess="Full Screen"
                  tabIndex={0}
                />
              )}

              <InfoOutlined
                role="button"
                onClick={infoDetails}
                onKeyPress={onEnterOrSpace(infoDetails)}
                aria-label={"Information of the " + mediaData.type}
                titleAccess={"Information of the " + mediaData.type}
                tabIndex={0}
                sx={{
                  cursor: "pointer",
                  color: "white",
                }}
              />
            </div>
          </Grid>
        </Grid>

        <Box className={classes.midContainer}>
          <div
            role="button"
            className={classes.iconBtn}
            onClick={onRewind}
            onKeyPress={onEnterOrSpace(onRewind)}
            aria-label="rewind"
            title="rewind"
            tabIndex={0}
          >
            <FastRewind fontSize="medium" />
          </div>
          <div
            role="button"
            className={classes.iconBtn}
            onClick={playing ? onPause : onPlay}
            onKeyPress={onEnterOrSpace(playing ? onPause : onPlay)}
            aria-label={playing ? "Pause" : "Play"}
            title={playing ? "Pause" : "Play"}
            tabIndex={0}
          >
            {playing ? <Pause fontSize="large" /> : <Play fontSize="large" />}
          </div>
          <div
            role="button"
            className={classes.iconBtn}
            onClick={onFastForward}
            onKeyPress={onEnterOrSpace(onFastForward)}
            aria-label="forward"
            tabIndex={0}
          >
            <FastForward fontSize="medium" />
          </div>
        </Box>

        {/* bottom row */}
        <Box className={classes.bottomContainer}>
          <Box flexGrow={2}>
            <Box
              display="flex"
              alignItems="center"
              gap={2}
              sx={{ width: "100%" }}
            >
              <span className={"add-shadow  " + classes.videoTimer}>
                {elapsedTime}
              </span>

              <span style={{ flexGrow: 2 }} />

              <span className={"add-shadow  " + classes.videoTimer}>
                {totalDuration}
              </span>
            </Box>

            <Box>
              <PrettoSlider
                sx={{ visibility: castingConnected ? "hidden" : "unset" }}
                role="slider"
                aria-label="Seek slider"
                className="add-shadow"
                min={0}
                max={100}
                value={completion * 100}
                onChange={onSeekChange}
                onChangeCommitted={onSeekMouseUp}
                onKeyUp={MainSlider}
              />
            </Box>
          </Box>
          {mediaType === "audio" ? (
            <Box
              sx={{ display: "flex" }}
              flexDirection="column"
              alignItems="center"
              gap="15px"
              height="120px"
              onMouseLeave={() => handleShowVolume(false)}
            >
              <VolumeSlider
                role="slider"
                aria-label="Volume slider"
                id="volume-slider"
                min={0}
                max={100}
                orientation="vertical"
                value={muted ? 0 : volume * 100}
                onChange={onVolumeChange}
                onChangeCommitted={onVolumeChange}
                className={"add-shadow"}
                sx={{
                  opacity: showVolume ? 100 : 0,
                  pointerEvents: showVolume ? "initial" : "none",
                }}
                tabIndex={showVolume ? 0 : -1}
              />

              <VolumeIcon
                onMouseEnter={() => handleShowVolume(true)}
                src={volume ? Volume : MuteVolume}
                onClick={handleToggleVolume}
                onKeyPress={onEnterOrSpace(handleToggleVolume)}
                aria-label={"Tap to change volume"}
                tabIndex={0}
              />
            </Box>
          ) : null}
        </Box>
      </Grid>
    </>
  );
};

export default AudioControls;
