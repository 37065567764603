import AppHeader from "components/appHeader";
import Footer from "components/footer";
import { Miniplayer } from "components/miniplayer/miniplayer";
import { RWebShare } from "components/webShare";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";

const ProtectedRoute: React.FC = () => {
  const dispatch = useDispatch();
  const auth = SLStorage.getItem("auth");
  const guestAuth = SLStorage.getItem("guestAuth");

  const {
    data: userProfile,
    error: userProfileError,
    loading: userProfileLoading,
  } = useSelector(SLSelector.getUserProfile);

  useEffect(() => {
    if (!userProfile && !userProfileLoading && auth && userProfileError) {
      setTimeout(() => dispatch(SLThunk.getUserProfile.call()), 2000);
    }
  }, [userProfileError]);

  return auth || guestAuth ? (
    <>
      {(auth && userProfileLoading) || (auth && userProfile) || guestAuth ? (
        <>
          <AppHeader />
          <Outlet />
          <Footer />
          <Miniplayer />
          <RWebShare />
        </>
      ) : null}
    </>
  ) : (
    <Navigate to="/login" />
  );
};

export default ProtectedRoute;
