import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {
  Box,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { SLButton } from "../../slButton";

const ChangePassword = () => {
  return (
    <>
      <Box sx={{ width: { lg: "48.2%", md: "100%", xs: "100%" } }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: { lg: "center", md: "left", xs: "left" },
            paddingTop: { lg: "90px", md: "40", xs: "25px" },
            paddingLeft: { md: "24px", xs: "16px" },
          }}
        >
          <SLTypography
            tabIndex={0}
            sx={{
              fontSize: { lg: "32px", md: "25px", xs: "25px" },
              fontWeight: "700",
            }}
          >
            Change Password
          </SLTypography>
          <SLTypography
            sx={{
              fontSize: "18px",
              fontWeight: "400",
              paddingRight: { xs: "10px" },
            }}
            tabIndex={0}
          >
            A strong password can protect your account
          </SLTypography>
        </Box>

        <Box
          sx={{
            paddingLeft: { lg: "105px", md: "24px", xs: "16px" },
            paddingTop: "20px",
          }}
        >
          <SLTypography tabIndex={0} pl={"8px"}>
            Current Password*
          </SLTypography>

          <FormControl
            sx={{ m: 1, width: { lg: "65%", md: "90%", xs: "90%" } }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              Inna_2022_2022
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <SLTypography tabIndex={0} pl={"8px"}>
            New Password*
          </SLTypography>

          <FormControl
            sx={{ m: 1, width: { lg: "65%", md: "90%", xs: "90%" } }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              ********
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>

          <SLTypography tabIndex={0} pl={"8px"}>
            Confirm New Password*
          </SLTypography>

          <FormControl
            sx={{ m: 1, width: { lg: "65%", md: "95%", xs: "90%" } }}
            variant="outlined"
          >
            <InputLabel htmlFor="outlined-adornment-password">
              ********
            </InputLabel>
            <OutlinedInput
              id="outlined-adornment-password"
              type={"password"}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    edge="end"
                  >
                    <RemoveRedEyeIcon />
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
        </Box>
        <Stack
          spacing={1}
          direction="row"
          sx={{ paddingLeft: { lg: "113px", md: "30px", xs: "24px" } }}
        >
          <SLButton
            fullWidth
            messageId={"general.cancel"}
            variant="outlined"
            slClass="none"
          />
          <SLButton
            fullWidth
            messageId={"general.savePassword"}
            variant="contained"
          />
        </Stack>
      </Box>
    </>
  );
};

export default ChangePassword;
