/* eslint-disable @typescript-eslint/no-unused-vars */
import { AnyAction } from "@reduxjs/toolkit";
import APIs from "./apis";
type Split<S extends string, D extends string> = string extends S
  ? string[]
  : S extends ""
  ? []
  : S extends `${infer T}${D}${infer U}`
  ? [T, ...Split<U, D>]
  : [S];

type Join<S extends string[], D extends string> = S extends []
  ? ""
  : S extends [infer T, ...infer U]
  ? `${T extends string ? Capitalize<T> : ""}${D}${Join<
      U extends string[] ? U : [],
      D
    >}`
  : "";

type FunctionNameFromURL<Property> = Capitalize<
  Join<
    Split<
      Join<
        Split<
          Join<Split<Join<Split<string & Property, "/">, "">, "{">, "">,
          "}"
        >,
        ""
      >,
      "-"
    >,
    ""
  >
>;

export type SLAPIThunkType =
  | "Request"
  | "PayloadRequest"
  | "QueryRequest"
  | "PayloadAndQueryRequest";

type RequestTypeHolder<Response, Payload, QueryParam> = {
  whiteListed: boolean;
  thunkType: SLAPIThunkType;
  extraActionOnFulfillment?: (response: Response) => void;
};

export const Request = <Response>(
  whiteListed = false,
  extraActionOnFulfillment?: (response: Response) => void
): RequestTypeHolder<Response, void, void> => {
  return {
    whiteListed,
    thunkType: "Request",
    extraActionOnFulfillment,
  };
};

export const PayloadRequest = <Response, Payload>(
  whiteListed = false,
  extraActionOnFulfillment?: (response: Response) => void
): RequestTypeHolder<Response, Payload, void> => {
  return {
    whiteListed,
    thunkType: "PayloadRequest",
    extraActionOnFulfillment,
  };
};

export const QueryRequest = <Response, QueryParam>(
  whiteListed = false,
  extraActionOnFulfillment?: (response: Response) => void
): RequestTypeHolder<Response, void, QueryParam> => {
  return {
    whiteListed,
    thunkType: "QueryRequest",
    extraActionOnFulfillment,
  };
};

export const PayloadAndQueryRequest = <Response, Payload, QueryParam>(
  whiteListed = false,
  extraActionOnFulfillment?: (response: Response) => void
): RequestTypeHolder<Response, Payload, QueryParam> => {
  return {
    whiteListed,
    thunkType: "PayloadAndQueryRequest",
    extraActionOnFulfillment,
  };
};

export type SLReduxStates<T> = {
  loading: boolean;
  error?: string;
  data?: T; // TODO: key bhi change ho gi
};

export type Dict<T> = { [key: string]: T };

type ThunkAction<T> = { call: T; reset: () => AnyAction };

type EmptyCall = (urlParams?: Dict<string>, headers?: Dict<string>) => any;

type ParamsCall<T> = (
  params: T,
  urlParams?: Dict<string>,
  headers?: Dict<string>
) => any;

type PayloadCall<T> = (
  payload: T,
  urlParams?: Dict<string>,
  headers?: Dict<string>
) => any;

type PayloadAndParamCall<Y, Z> = (
  payload: Y,
  params: Z,
  urlParams?: Dict<string>,
  headers?: Dict<string>
) => any;

type ThunksTypeGenerator<Type, T2 extends keyof Type> = {
  [Property in keyof Type[T2] as `${T2 &
    string}${FunctionNameFromURL<Property>}`]: Type[T2][Property] extends RequestTypeHolder<
    infer X,
    infer Y,
    infer Z
  >
    ? Y extends void
      ? Z extends void
        ? ThunkAction<EmptyCall>
        : ThunkAction<ParamsCall<Z>>
      : Z extends void
      ? ThunkAction<PayloadCall<Y>>
      : ThunkAction<PayloadAndParamCall<Y, Z>>
    : never;
};

type SLGetThunks = ThunksTypeGenerator<typeof APIs, "get">;
type SLPostThunks = ThunksTypeGenerator<typeof APIs, "post">;
type SLPatchThunks = ThunksTypeGenerator<typeof APIs, "patch">;
type SLPutThunks = ThunksTypeGenerator<typeof APIs, "put">;
type SLDeleteThunks = ThunksTypeGenerator<typeof APIs, "delete">;

export type SLThunks = SLGetThunks &
  SLPostThunks &
  SLPutThunks &
  SLPatchThunks &
  SLDeleteThunks;

type SelectorsTypeGenerator<Type, T2 extends keyof Type> = {
  [Property in keyof Type[T2] as `${T2 &
    string}${FunctionNameFromURL<Property>}`]: Type[T2][Property] extends RequestTypeHolder<
    infer T,
    infer Y,
    infer Z
  >
    ? (state: any) => SLReduxStates<T>
    : never;
};

type SLGetSelectors = SelectorsTypeGenerator<typeof APIs, "get">;
type SLPostSelectors = SelectorsTypeGenerator<typeof APIs, "post">;
type SLPutSelectors = SelectorsTypeGenerator<typeof APIs, "put">;
type SLDeleteSelectors = SelectorsTypeGenerator<typeof APIs, "delete">;
type SLPatchSelectors = SelectorsTypeGenerator<typeof APIs, "patch">;

export type SLSelectors = SLGetSelectors &
  SLPostSelectors &
  SLPutSelectors &
  SLPatchSelectors &
  SLDeleteSelectors;
