import { Box } from "@mui/material";
import { SLTypography } from "components/slTypography";
import React from "react";

const NotFound: React.FC = () => {
  return (
    <div>
      <Box
        sx={{
          minHeight: {
            xs: "38vh",
            sm: "auto",
            md: "38vh",
            lg: "48vh",
            xl: "55vh",
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          // paddingLeft: "5.9%",
          color: "var(--sl-deep-purple)",
          // paddignTop: { xs: "50px" },
          // alignmentBaseline
          gap: "10px",
        }}
      >
        {/* <SLTypography variant="headingLarge" sx={{ color: "red" }}>
          Error!{" "}
        </SLTypography> */}
        <SLTypography tabIndex={0} variant="headingLarge">
          Page Not Found
        </SLTypography>
      </Box>
    </div>
  );
};

export default NotFound;
