import { PageHeading } from "components/pageHeading";
import { lang } from "helpers/common/lang";
import ProfilePages from "layouts/profilePages";
import DailyInspirationScreen from "pages/dailyInspiration";

const DailyInspirationProfile = () => {
  return (
    <>
      <ProfilePages title={lang("profile.dailyInspiration")}>
        <>
          <PageHeading messageId="profile.dailyInspiration" />

          <DailyInspirationScreen />
        </>
      </ProfilePages>
    </>
  );
};

export default DailyInspirationProfile;
