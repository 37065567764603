import { Card, CardContent, Skeleton, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import dayjs from "dayjs";
import { lang } from "helpers/common/lang";
import { TrashSvg } from "helpers/enum/constants";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLReminder } from "utils/slRTKHelper/slAPI.models";
import SLDialog from "../../dialogBoxes/slDialog";
import { useDialog } from "../../dialogBoxes/slDialog/useDialog";
import { SLButton } from "../../slButton";
import { SLRoundButton } from "../../slButton/SLRoundButton";
import { AddReminderScreen } from "./add";

const DATE = dayjs().format("YYYY-MM-DD");

export const RemindersScreen = () => {
  const dispatch = useDispatch();
  const addReminderDialog = useDialog({
    title: lang("profile.meditationReminders.addReminder"),
  });
  const deleteReminderDialog = useDialog({
    title: lang("profile.meditationReminders.deleteReminder"),
    message: lang("profile.meditationReminders.deleteReminder.message"),
  });

  const { data: remindersData, loading: remindersLoading } = useSelector(
    SLSelector.getReminders
  );
  const { error: deleteRemindersError } = useSelector(
    SLSelector.deleteRemindersId
  );

  const [reminders, setReminders] = useState<SLReminder[]>([]);
  const [deletingId, setDeletingId] = useState<string>();

  useEffect(() => {
    dispatch(SLThunk.getReminders.call());
  }, []);

  useEffect(() => {
    if (Array.isArray(remindersData)) {
      // UTC to local time conversion
      const reminders = remindersData.map((r) => {
        return {
          ...r,
          time: dayjs(DATE + " " + r.time)
            .add(dayjs().utcOffset(), "minutes")
            .format("HH:mm"),
        };
      });

      setReminders(reminders);
    }
  }, [remindersData]);

  useEffect(() => {
    if (deleteRemindersError) {
      dispatch(SLThunk.getReminders.call());
    }
  }, [deleteRemindersError]);

  function convertTimeFormat(timeString: string) {
    const inputFormat = "H:m";
    const outputFormat = "hh:mm A";

    const dayjsObj = dayjs(timeString, inputFormat);
    const formattedTime = dayjsObj.format(outputFormat);

    return formattedTime;
  }

  const handleDelete = (id: string) => {
    setDeletingId(id);
    deleteReminderDialog.show();
  };

  const handleDeleteConfirmed = () => {
    const id = deletingId;

    if (id) {
      dispatch(SLThunk.deleteRemindersId.call({ id }));
      setReminders(reminders.filter((r) => String(r.id) !== String(id)));
      deleteReminderDialog.hide();
    }
  };

  return (
    <>
      <Stack gap={3}>
        <SLTypography variant="bodyCopy" color="text-deepPurple">
          This page allows you to create reminders to meditate. Click Add
          Reminder to specify the days and time for your reminder. These
          reminders can be set for daily or weekly to suit your schedule.
        </SLTypography>

        <Stack role="list" gap="10px" maxWidth={{ xs: "350px", md: "500px" }}>
          {!reminders.length && remindersLoading
            ? Array.from({ length: 3 }, (_i) => (
                <Skeleton
                  sx={{
                    borderRadius: "10px",
                    transform: "unset",
                    height: "72px",
                  }}
                />
              ))
            : null}
          {reminders.map((r) => (
            <Card
              key={r.id}
              role="listitem"
              sx={{
                borderRadius: "10px",
                boxSizing: "border-box",
                border: "1px solid var(--colorLightGray)",
              }}
            >
              <CardContent
                sx={{
                  padding: "1rem 5px 1rem 1rem !important",
                  boxSizing: "border-box",
                  display: "flex",
                  alignItems: "center",
                }}
                tabIndex={-1}
              >
                <Stack
                  tabIndex={0}
                  sx={{ color: "var(--sl-deep-purple)", flexGrow: 1 }}
                >
                  <SLTypography
                    variant="extraSmall"
                    sx={{ fontSize: "12px", lineHeight: 1 }}
                  >
                    {r.daysLable}
                  </SLTypography>
                  <SLTypography
                    variant="subhead"
                    sx={{ fontSize: { xs: "14px", md: "17px" } }}
                  >
                    {convertTimeFormat(r.time)}
                  </SLTypography>
                </Stack>
                <SLRoundButton
                  aria-label="Delete this reminder"
                  onClick={() => handleDelete(r.id.toString())}
                  icon={TrashSvg}
                />
              </CardContent>
            </Card>
          ))}
        </Stack>

        <SLButton
          sx={{ width: "180px" }}
          messageId="profile.meditationReminders.addReminder"
          onClick={(e) => addReminderDialog.show()}
        />
      </Stack>

      <AddReminderScreen dialog={addReminderDialog} />

      <SLDialog
        dialog={deleteReminderDialog}
        dismissable={true}
        onDismiss={deleteReminderDialog.hide}
        actionButtons={{
          yesMessageId: "general.delete",
          onYesButtonClicked: handleDeleteConfirmed,
          onNoButtonClicked: deleteReminderDialog.hide,
        }}
      />
    </>
  );
};
