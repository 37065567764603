import { Box, Container, Divider, Grid, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { MiniplayerContext } from "contexts/miniplayerContext";
import { lang } from "helpers/common/lang";
import { useContext } from "react";
import { Link } from "react-router-dom";
import applestore from "../../assets/images/app-store.svg";
import googleplay from "../../assets/images/google-play.svg";
import Logo from "../../assets/images/soul-link-logo.svg";
import { SLButton } from "../slButton";
import "./footer.css";

const Footer = () => {
  const { showMiniplayer } = useContext(MiniplayerContext);

  return (
    <>
      <Container
        className="main-container"
        sx={{
          width: { xl: "93.2%", lg: "95%" },
          paddingBottom: showMiniplayer ? "80px" : "0px",
        }}
        maxWidth={false}
      >
        <Grid
          container
          sx={{ marginTop: "2rem", marginBottom: "2rem", alignItems: "center" }}
          spacing="2rem"
        >
          <Grid
            item
            xs={12}
            md={3}
            display="flex"
            justifyContent={{ xs: "center", md: "flex-start" }}
          >
            <Link to={"/app/home"}>
              <img
                tabIndex={-1}
                aria-label="Soul Link Home page"
                style={{ width: "197px", cursor: "pointer" }}
                src={Logo}
                loading="lazy"
              />
            </Link>
          </Grid>
          <Grid
            item
            xs={12}
            md={9}
            gap="10px"
            display="flex"
            flexWrap="wrap"
            justifyContent={{ xs: "center", md: "flex-end" }}
          >
            <Link to={"/app/help-center/faq"}>
              <SLButton
                tabIndex={-1}
                slClass="white"
                sx={{
                  minWidth: { sm: "130px", xs: "100%" },
                  boxShadow: "none",
                }}
                messageId={"app.home.helpCenter"}
                variant="contained"
              />
            </Link>

            <Link to={"/app/contact-us"}>
              <SLButton
                tabIndex={-1}
                slClass="white"
                sx={{
                  minWidth: { sm: "130px", xs: "100%" },
                  boxShadow: "none",
                }}
                messageId={"app.home.contactUs"}
                variant="contained"
              />
            </Link>

            <Link to={"/app/about-us"}>
              <SLButton
                tabIndex={-1}
                slClass="white"
                sx={{
                  minWidth: { sm: "130px", xs: "100%" },
                  boxShadow: "none",
                }}
                messageId={"app.home.aboutUs"}
                variant="contained"
              />
            </Link>

            <Link to={"/app/profile"}>
              <SLButton
                tabIndex={-1}
                slClass="white"
                sx={{
                  minWidth: { sm: "130px", xs: "100%" },
                  boxShadow: "none",
                }}
                messageId={"app.home.myProfile"}
                variant="contained"
              />
            </Link>
          </Grid>
        </Grid>

        <Divider />

        <Grid
          container
          sx={{
            flexDirection: { md: "row", xs: "column-reverse" },
            paddingBottom: "20px",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              marginTop: { md: "20px", xs: "10px" },
              display: "flex",
              justifyContent: "start",
              alignItems: "center",
              flexDirection: { lg: "row", xs: "column" },
            }}
          >
            <SLTypography
              tabIndex={0}
              variant="smallFooter"
              color="text-deepPurple"
            >
              © 2023 Supernal World Creative
            </SLTypography>
            <Stack
              sx={{
                flexDirection: { xs: "row" },
                marginTop: { lg: "0px", xs: "10px" },
              }}
            >
              <SLTypography
                sx={{ marginLeft: "15px" }}
                variant="smallBodyUnderLined"
                title="privacy policy"
              >
                <a
                  href={lang("general.privacy-url")}
                  style={{ color: "var(--sl-deep-purple)" }}
                  target="_blank"
                >
                  Privacy Policy
                </a>
              </SLTypography>

              <SLTypography
                sx={{ marginLeft: "15px" }}
                variant="smallBodyUnderLined"
                title="Terms of Use"
              >
                <a
                  href={lang("general.terms-url")}
                  target="_blank"
                  style={{ color: "var(--sl-deep-purple)" }}
                >
                  Terms of Use
                </a>
              </SLTypography>
            </Stack>
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{
              marginTop: { lg: "20px", md: "10px", xs: "15px" },
              alignItems: "center",
              flexDirection: { lg: "row", xs: "column" },
            }}
          >
            <SLTypography
              tabIndex={0}
              title="Get the soul link App"
              variant="extraSmall"
              color="text-deepPurple"
            >
              Get the soul link App
            </SLTypography>
            <Stack
              sx={{
                flexDirection: { xs: "row" },
                marginTop: { lg: "0px", xs: "10px" },
              }}
            >
              <a href={lang("general.app-store.apple")} target="_blank">
                <img
                  style={{ width: "130px", marginLeft: "10px" }}
                  src={applestore}
                  tabIndex={0}
                  loading="lazy"
                  alt="Download on the App store"
                />
              </a>
              <a href={lang("general.app-store.google")} target="_blank">
                <img
                  style={{ width: "130px", marginLeft: "10px" }}
                  src={googleplay}
                  loading="lazy"
                  tabIndex={0}
                  alt="Get it on the Google Play"
                />
              </a>
            </Stack>
          </Box>
        </Grid>
      </Container>
    </>
  );
};

export default Footer;
