import { Stack } from "@mui/material";
import { SLTypography } from ".";

interface INotFoundText {
  notFoundMessage?: string;
  height?: string | number;
  color?: string;
}
export function NotFoundMessage(props: INotFoundText) {
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={props.height || "200px"}
    >
      <SLTypography
        tabIndex={0}
        variant="subhead"
        color={props.color || "text-deepPurple"}
      >
        {props.notFoundMessage || "Not found"}
      </SLTypography>
    </Stack>
  );
}
