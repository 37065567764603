function DataDeletionInstructions() {
  return (
    <>
      <center>
        <h2>Data Deletion Instructions</h2>
      </center>

      <p>
        <strong>
          Deleting an account from Soul Mobile Application for normal logged in
          users:
        </strong>
      </p>
      <ol>
        <li>Log in to the Soul Application.</li>
        <li>Go to the Profile Menu.</li>
        <li>Scroll down and tap on Account Settings.</li>
        <li>
          In the Account Settings, tap on the "Delete Account" button, and a
          pop-up will appear.
        </li>
        <li>Enter your password and tap on the "Delete" button.</li>
        <li>
          This action will delete all user information associated with your
          account from the Soul Database.
        </li>
      </ol>
      <p>
        <strong>
          Deleting an account from Soul Mobile Application for Social logged in
          users:
        </strong>
      </p>
      <ol>
        <li>Log in to the Soul Application.</li>
        <li>Go to the Profile Menu.</li>
        <li>Scroll down and tap on Account Settings.</li>
        <li>
          In the Account Settings, tap on the "Delete Account" button, and a
          pop-up will appear.
        </li>
        <li>
          Tapping on the Delete Account button will authorize the account from
          Social App i.e. if Logged in using Facebook then it will authorize the
          account from Facebook.
        </li>
        <li>
          This action will delete all user information associated with your
          account from the Soul Database.
        </li>
      </ol>
      <p>
        <strong>Deleting an account from Soul Web Application:</strong>
      </p>
      <ol>
        <li>Log in to the Soul Application.</li>
        <li>Go to the Profile Menu.</li>
        <li>Scroll down and tap on Account Settings.</li>
        <li>
          In the Account Settings, tap on the "Delete Account" button, and a
          pop-up will appear.
        </li>
        <li>Enter your password and tap on the "Delete" button.</li>
        <li>
          This action will delete all user information associated with your
          account from the Soul Database.
        </li>
      </ol>
    </>
  );
}

export default DataDeletionInstructions;
