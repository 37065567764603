import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface IVolumeFadeInitialState {
  prevVolume?: number;
  prevFrequency?: number;
  targetFVolume?: number;
  fading: boolean;
  faded: boolean;
  unfading: boolean;
  playingTone: boolean;
  playToneTimeout: any;
  fadeOutTimeout: any;
}

const initialState: IVolumeFadeInitialState = {
  prevVolume: undefined,
  prevFrequency: undefined,
  fading: false,
  faded: false,
  unfading: false,
  playingTone: false,
  playToneTimeout: undefined,
  fadeOutTimeout: undefined,
};

const volumeFadeSlice = createSlice({
  name: "volumeFade",
  initialState,
  reducers: {
    fadeVolume: (
      state,
      action: PayloadAction<{
        prevVolume: number;
        prevFrequency: number;
      }>
    ) => {
      if (state.prevVolume === undefined || state.unfading == true) {
        state.prevVolume = action.payload.prevVolume;
        state.prevFrequency = action.payload.prevFrequency;
      }
      state.fading = true;
      state.unfading = false;
      state.playingTone = false;
    },
    prevVolume: (state, action: PayloadAction<number>) => {
      if (state.prevVolume !== undefined) {
        state.prevVolume = action.payload;
      }
    },
    faded: (state) => {
      if (state.fading) {
        state.fading = false;
        state.faded = true;
      }
    },
    playTone: (state) => {
      state.fading = false;
      state.playingTone = true;
      state.unfading = false;
    },
    targetFrequencyVolume: (state, action: PayloadAction<number>) => {
      state.targetFVolume = action.payload;
    },
    unfadeVolume: (state) => {
      state.playingTone = false;
      state.unfading = true;
    },
    completed: (state) => {
      Object.assign(state, initialState);
    },
  },
});

export default volumeFadeSlice.reducer;
export const volumeFadeRedux = (state: any) =>
  state.volumeFade as IVolumeFadeInitialState;
export const volumeFadeActions = volumeFadeSlice.actions;
