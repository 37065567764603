import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import { SLLoader } from "components/loader";
import MediationCard from "components/mediationCard";
import { PopupCancelButton, SLButton } from "components/slButton";
import { SLTypography } from "components/slTypography";
import { SLVerificationMessage } from "components/verificationMessage";
import { MediaCardType } from "helpers/enum";
import { CrossWhite } from "helpers/enum/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import {
  CreateNotesPayload,
  NotesReactions,
} from "utils/slRTKHelper/slAPI.models";
import "./index.css";

interface NotesProps {
  mediaData: any;
  // mediaData: SLMediaFileDetail;
  setOpenMyJournalNotes: () => any;
  openMyJournalNotes: boolean;
  editNoteData?: any;
  getNotesData?: any;
  isDeepLink?: boolean;
}

const mediaTypes = ["audio", "video", "frequencyWave"];
export default function NotesAddUpdateModal({
  mediaData,
  setOpenMyJournalNotes,
  openMyJournalNotes,
  editNoteData,
  getNotesData,
  isDeepLink,
}: NotesProps) {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState<boolean>(false);
  const [submit, setSubmit] = React.useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [errorMsg, setErrorMsg] = useState<string | null>(null);
  const [tagsFiltered, setTagsFiltered] = useState<any[]>([]);
  const [reactions, setReactions] = useState<NotesReactions[]>([]);
  const [toastMessage, setToastMessage] = useState<any>(null);
  const navigate = useNavigate();
  const {
    data: postNotesResponse,
    error: postNotesError,
    loading: loadingPostNotes,
  } = useSelector(SLSelector.postNotes);
  const { data: userprofileData } = useSelector(SLSelector.getUserProfile);
  const { data: tagsData } = useSelector(SLSelector.getAdminTag);
  const { data: reactionsData } = useSelector(SLSelector.getNotesReactions);

  useEffect(() => {
    dispatch(SLThunk.getAdminTag.call({}));
    dispatch(SLThunk.getNotesReactions.call({}));
  }, []);

  useEffect(() => {
    if (editNoteData?.description) {
      setDescription(editNoteData?.description);
    }
  }, [editNoteData]);

  useEffect(() => {
    setOpen(openMyJournalNotes);
  }, [openMyJournalNotes]);

  useEffect(() => {
    let selectedTagIds: number[] = [];
    if (editNoteData?.tags?.length > 0) {
      selectedTagIds = editNoteData.tags.map((tag: any) => tag.id);
    }
    if (tagsData?.length) {
      const data = tagsData
        .filter((tag: any) => tag.type === "note")
        .map((tag: any) => {
          return {
            ...tag,
            selected: selectedTagIds.includes(tag.id),
          };
        });

      setTagsFiltered(data);
    }
  }, [tagsData, editNoteData]);

  useEffect(() => {
    let selectedReactionIds: number[] = [];
    if (editNoteData?.reactions?.length > 0) {
      selectedReactionIds = editNoteData.reactions.map((tag: any) => tag.id);
    }
    if (reactionsData?.length) {
      const data = reactionsData.map((reaction: any) => {
        return {
          ...reaction,
          selected: selectedReactionIds.includes(reaction.id),
        };
      });

      setReactions(data);
    }
  }, [reactionsData, editNoteData]);

  useEffect(() => {
    if (!submit) {
      return;
    }
    if (postNotesResponse) {
      setToastMessage(postNotesResponse);
    }
    if (postNotesError) {
      setSubmit(false);
      setToastMessage(postNotesError);
      setTimeout(() => {
        dispatch(SLThunk.postNotes.reset());
        handleClose();
        setToastMessage(null);
      }, 2000);
    }
    if (postNotesResponse) {
      setTimeout(() => {
        dispatch(SLThunk.postNotes.reset());
        handleClose();
        setToastMessage(null);
        navigate("/app/my-notes");
        getNotesData();
      }, 2000);
      setSubmit(false);
    }
  }, [postNotesResponse, postNotesError]);

  const handleClose = () => {
    setOpenMyJournalNotes();
    setTimeout(() => {
      setOpen(false);
    }, 200);
  };

  const handleDescriptionChange = (e: any) => {
    setDescription(e.target.value);
  };

  const handleSaveNotes = async () => {
    if (submit) {
      return;
    }

    const desc = description.trim();
    let msg = null;
    if (desc?.length === 0) {
      msg = "Fill the obligatory field, please";
      setErrorMsg("");
    } else if (desc?.length === 1) {
      msg = "The journal is too short";
    } else if (desc?.length > 1000) {
      msg = "The journal is too long";
    }
    if (msg) {
      setErrorMsg(msg);
      return;
    }
    setSubmit(true);
    setErrorMsg(msg);
    const payload: CreateNotesPayload = {
      description: desc,
      tags: filterSelectArrayIds(tagsFiltered),
      reactions: filterSelectArrayIds(reactions),
    };

    if (editNoteData && !isDeepLink) {
      const urlParams = { id: editNoteData?.id };
      const makeCallObj: any = {
        method: "patch",
        url: "/notes/{id}",
        urlParams,
        payload,
      };
      const response = await makeSLAPICall(makeCallObj);

      setToastMessage("Changes Saved");

      setTimeout(() => {
        getNotesData();
        handleClose();
        setToastMessage(null);
      }, 2000);
      setSubmit(false);
    } else {
      payload.userId = userprofileData?.id || 0;
      if (mediaData?.type && mediaTypes.includes(mediaData.type)) {
        payload.mediaId = mediaData.id;
      } else {
        payload.meditationId = mediaData.id;
      }
      dispatch(SLThunk.postNotes.call(payload));
    }
  };

  const filterSelectArrayIds = (array: any) => {
    return array.filter((obj: any) => obj.selected).map((obj: any) => obj.id);
  };

  const handleTagClick = (index: number, selected: boolean) => {
    const newArr = [...tagsFiltered];
    newArr[index]["selected"] = !selected;
    setTagsFiltered(newArr);
  };

  const handleReactionClick = (
    index: number,
    selected: boolean | undefined
  ) => {
    const newArr = [...reactions];
    newArr[index]["selected"] = !selected;
    setReactions(newArr);
  };

  const handleLikedMediaCard = (text: string) => {
    setToastMessage(text);
    setTimeout(() => {
      setToastMessage(null);
    }, 1500);
  };

  return (
    <>
      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            padding: "2% !important",
            background: "var(--bg-gradient) !important",
          },
        }}
      >
        <DialogContent sx={{ padding: 0 }}>
          <SLTypography
            tabIndex={0}
            variant="mediumHeading"
            messageId="app.notes"
            sx={{ color: "var(--sl-deep-purple)" }}
            display="inline-block"
            mb={2}
          />
          <Grid
            container
            display="flex"
            flexDirection={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "center", sm: "flex-start" }}
            flexWrap="nowrap"
            spacing={2}
          >
            <Grid item className="notesCard">
              <MediationCard
                selectedCardData={mediaData}
                likedMediaCard={handleLikedMediaCard}
                cardType={
                  mediaData?.meditationType || mediaData?.meditation
                    ? MediaCardType.MeditationCard
                    : MediaCardType.MediaCard
                }
              />
            </Grid>

            <Grid item gap={3} display="flex" flexDirection="column">
              <TextField
                id="outlined-multiline-flexible"
                name="description"
                className="noteText"
                value={description}
                onChange={handleDescriptionChange}
                multiline
                rows={8.7}
                inputProps={{
                  style: {
                    fontFamily: "Figtree",
                    fontWeight: 500,
                    fontSize: "17px",
                    color: "#4B2B5A",
                    cursor: "text",
                  },
                }}
                sx={{
                  width: "100%",
                  borderRadius: "10px",
                  background: "white",
                  flexWrap: "wrap",
                  cursor: "text",
                }}
              />
              {errorMsg && (
                <SLTypography
                  tabIndex={0}
                  color="text-purple"
                  marginBottom="12px !important"
                  fontSize={"12px"}
                  textAlign="left"
                >
                  {errorMsg}
                </SLTypography>
              )}

              {Array.isArray(tagsFiltered) && tagsFiltered.length > 0 ? (
                <Grid item>
                  <SLTypography
                    tabIndex={0}
                    variant="mediumHeading"
                    sx={{ color: "#4B2B5A" }}
                    messageId="notes.quickTags"
                    display="inline-block"
                    marginBottom={"10px"}
                  />

                  <Stack direction="row" sx={{ flexWrap: "wrap", gap: "8px" }}>
                    {tagsFiltered.map((tag: any, index: number) => (
                      <Box
                        component="button"
                        onClick={(e) => handleTagClick(index, tag.selected)}
                        onKeyPress={(e) => handleTagClick(index, tag.selected)}
                        sx={{
                          cursor: "pointer",
                          padding: "3px 10px",
                          color: tag.selected
                            ? "white"
                            : "var(--sl-deep-purple)",
                          background: tag.selected ? "#4B2B5A" : "none",
                          border: tag.selected ? "none" : "1px solid #CBC8CD",
                          borderRadius: "5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          flex: "none",
                        }}
                      >
                        <SLTypography
                          variant="smallFooter"
                          fontFamily="Figtree"
                        >
                          {tag.title}
                        </SLTypography>
                        {tag.selected && (
                          <Box sx={{ display: "flex", paddingLeft: "5px" }}>
                            <img src={CrossWhite} alt="" />
                          </Box>
                        )}
                      </Box>
                    ))}
                  </Stack>
                </Grid>
              ) : null}

              {Array.isArray(reactions) && reactions.length > 0 ? (
                <Grid item>
                  <SLTypography
                    tabIndex={0}
                    sx={{ color: "#4B2B5A" }}
                    variant="mediumHeading"
                    display="inline-block"
                    marginBottom={"10px"}
                  >
                    Reactions
                  </SLTypography>

                  <Stack direction="row" gap={1} flexWrap="wrap">
                    {reactions.map((reaction, index) => (
                      <Button
                        onClick={(e) =>
                          handleReactionClick(index, reaction.selected)
                        }
                        sx={{
                          fontSize: "32px",
                          cursor: "pointer",
                          background: reaction.selected ? "#ffffff" : "none",
                          borderRadius: "50%",
                          aspectRatio: "1/1",
                          lineHeight: 1,
                          border: reaction.selected
                            ? "1px solid #CBC8CD"
                            : "1px solid transparent",
                        }}
                        tabIndex={0}
                      >
                        {reaction?.emoji}
                      </Button>
                    ))}
                  </Stack>
                </Grid>
              ) : null}

              <Stack alignItems="center" gap={1}>
                <SLButton
                  fullWidth
                  messageId={"notes.myNotes"}
                  onClick={handleSaveNotes}
                />

                <PopupCancelButton
                  messageId={"general.cancel"}
                  onClick={handleClose}
                />
              </Stack>
            </Grid>
          </Grid>
        </DialogContent>
        {toastMessage && toastMessage !== "" && (
          <SLVerificationMessage open={true} message={toastMessage} />
        )}

        <SLLoader open={loadingPostNotes || submit} />
      </Dialog>
    </>
  );
}
