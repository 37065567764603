import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  MenuItem,
  Paper,
  Radio,
  TextField,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { useState } from "react";
import { ErrorMsg } from "../../../errorMsg/ErrorMsg";
import "./index.css";

interface IAchievementForm {
  type: string | null;
  number: number | null;
  durationType: "week" | "month" | "day" | null;
  duration: number | null;
}

interface IAchievementMeditationColumn {
  slug: string;
  title: string;
  label: string;
  selectedAchievement: string;
  errors: Record<keyof IAchievementForm, boolean>;
  handleForm: (form: Record<string, any>) => void;
}

const AchievementMeditationColumn = (props: IAchievementMeditationColumn) => {
  const [form, setForm] = useState<IAchievementForm>({
    type: null,
    number: null,
    durationType: null,
    duration: null,
  });

  const handleCurrentForm = (data: any) => {
    const currentForm = { ...form, type: props.slug, ...data };

    setForm(currentForm);
    props.handleForm(currentForm);
  };

  return (
    <Grid item lg={4} xs={12}>
      <Paper sx={{ boxShadow: "none", border: "none", borderRadius: "10px" }}>
        <Box className="gray-wrapper quantity-of-mediatation">
          <Accordion
            expanded={props.selectedAchievement === props.slug}
            aria-label="accordion will open on key press"
          >
            <AccordionSummary
              onClick={() =>
                handleCurrentForm({
                  type:
                    props.selectedAchievement === props.slug ? "" : props.slug,
                })
              }
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              tabIndex={0}
              title={props.title}
              aria-label={props.title}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Radio
                  tabIndex={0}
                  checked={props.selectedAchievement === props.slug}
                  onChange={(e) =>
                    handleCurrentForm({
                      type:
                        props.selectedAchievement === props.slug
                          ? ""
                          : props.slug,
                    })
                  }
                  name="radio-buttons"
                  inputProps={{ "aria-label": "" }}
                  sx={{
                    margin: "0px",
                    padding: "0px",
                  }}
                />
                <SLTypography
                  tabIndex={0}
                  variant="subhead"
                  sx={{
                    paddingLeft: "10px",
                    color: "var(--sl-deep-purple)",
                    fontSize: {
                      xs: "14px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                    },
                    width: "100%",
                  }}
                >
                  {props.title}
                </SLTypography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <TextField
                className="sl-input"
                label={props.label}
                InputProps={{ inputProps: { min: 1, max: 1000 } }}
                onChange={(e) => {
                  let number: any = e.target.value.trim();

                  if (number !== "") {
                    const regex = /^[0-9]+$/; // Regex pattern to allow only digits

                    if (!regex.test(number)) {
                      // If the input value doesn't match the regex pattern, prevent the change
                      e.preventDefault();
                      e.target.value = String(form.number || "");
                      return false;
                    }
                  }

                  if (e.target.value) {
                    number = Number(e.target.value);
                    if (number < 1) {
                      number = 1;
                    }
                    if (number > 1000) {
                      number = 1000;
                    }
                  }
                  handleCurrentForm({ number });
                }}
                value={form.number}
              />
              <ErrorMsg
                show={props.errors.number}
                messageId="general.error.EmptyFields"
              />

              <TextField
                select
                className="sl-input"
                value={form.durationType}
                label="Day/Week/Month"
                aria-label="press key to open drop down"
                title="press key to open drop down"
                onChange={(e: any) => {
                  const durationType = e.target.value;

                  let duration = form.duration;
                  let maxValue = durationType === "week" ? 52 : 12;
                  if (durationType === "day") {
                    maxValue = 31;
                  }
                  if (duration && duration > maxValue) {
                    duration = maxValue;
                  }

                  handleCurrentForm({ durationType, duration });
                }}
              >
                <MenuItem value="day">Day</MenuItem>
                <MenuItem value="week">Week</MenuItem>
                <MenuItem value="month">Month</MenuItem>
              </TextField>
              <ErrorMsg
                show={props.errors.durationType}
                messageId="general.error.EmptyFields"
              />

              {form.durationType !== null ? (
                <>
                  <TextField
                    className="sl-input"
                    label="Duration"
                    onChange={(e) => {
                      let number: any = e.target.value.trim();

                      if (number !== "") {
                        const regex = /^[0-9]+$/; // Regex pattern to allow only digits

                        if (!regex.test(number)) {
                          // If the input value doesn't match the regex pattern, prevent the change
                          e.preventDefault();
                          e.target.value = String(form.duration || "");
                          return false;
                        }
                      }

                      if (e.target.value) {
                        number = Number(e.target.value) || 1;
                        // const maxValue = form.durationType === "week" ? 52 : 12;
                        let maxValue = form.durationType === "week" ? 52 : 12;
                        if (form.durationType === "day") {
                          maxValue = 31;
                        }
                        if (number < 1) {
                          number = 1;
                        }
                        if (number > maxValue) {
                          number = maxValue;
                        }
                      }

                      handleCurrentForm({ duration: number });
                    }}
                    value={form.duration}
                  />
                  <ErrorMsg
                    show={props.errors.duration}
                    messageId="general.error.EmptyFields"
                  />
                </>
              ) : null}
            </AccordionDetails>
          </Accordion>
        </Box>
      </Paper>
    </Grid>
  );
};

export default AchievementMeditationColumn;
