import {
  GuestAuth,
  SLAuth,
  SLConfigs,
  SLUser,
  SLUserSubscription,
} from "./slRTKHelper/slAPI.models";

type SLLocalStorageKeys = {
  auth?: SLAuth;
  authType?: "social" | "email" | "guest";
  guestAuth?: GuestAuth;
  configs?: SLConfigs;
  deviceId?: string;
  profile?: SLUser;
  subscription?: SLUserSubscription;
  newKey?: number;
  fcmToken?: string;
  password?: string;
  email?: string;
  rememberMe?: string;
  signupCompleted?: boolean;
  introScreenDone?: string;
  UserAppFirst?: boolean;
  sceneId?: string;
  guestLogin?: boolean;
  createMeditationTutorial?: boolean;
  homeMeditationTutorial?: boolean;
  listMeditationTutorial?: boolean;
  ExploreMeditationTutorial?: boolean;
  ExploreMediaTutorial?: boolean;
  MediaDetailTutorial?: boolean;
  MeditationDetailTutorial?: boolean;
  myProfileTutorial?: boolean;
  meditationShare?: boolean;
  selectedMeditationDataCreate?: any;
  selectedMeditationDataDetail?: any;
  reduceMotion?: boolean;
  previousURLStore?: string;
  meditationSessionId?: string;
  // NOTE: We register our local storage data keys here with there type
  // Example: sampleKey?: string;
};

let cachedData: SLLocalStorageKeys = {};

function setItem<K extends keyof SLLocalStorageKeys>(
  key: K,
  value: SLLocalStorageKeys[K]
) {
  cachedData[key] = value;
  // TODO (OR NOT): We can apply encryption here
  localStorage.setItem(`SL-${key}`, JSON.stringify({ value }));

  return value;
}

function getItem<K extends keyof SLLocalStorageKeys>(
  key: K
): SLLocalStorageKeys[K] {
  if (!cachedData[key]) {
    const result = localStorage.getItem(`SL-${key}`);
    if (result) {
      // TODO (OR NOT): decryption apply here
      cachedData[key] = JSON.parse(result).value;
    }
  }
  return cachedData[key];
}

function removeItem<K extends keyof SLLocalStorageKeys>(key: K) {
  delete cachedData[key];
  localStorage.removeItem(`SL-${key}`);
}

function clear() {
  cachedData = {};
  localStorage.clear();
}

export const SLStorage = { getItem, setItem, removeItem, clear };
