import { Button, ButtonProps } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { IntlMessageID } from "helpers/enum";
import "./index.css";

// TODO: Asmar configure image

type SLButtonProps = {
  messageId: IntlMessageID;
  slClass?: SLButtonClass;
  buttonTextClass?: any;
} & ButtonProps;

export type SLButtonClass =
  | "blue"
  | "bordered"
  | "gray"
  | "purple"
  | "aqua"
  | "white"
  | "none";

export const SLButton = ({
  messageId,
  slClass = "purple",
  buttonTextClass,
  ...props
}: SLButtonProps) => {
  return (
    <>
      <Button tabIndex={0} {...props} className={`${slClass} sl-button`}>
        <SLTypography
          tabIndex={-1}
          sx={{ userSelect: "none", pointerEvents: "none" }}
          {...buttonTextClass}
          messageId={messageId}
        />
      </Button>
    </>
  );
};

type IPopupCancelButton = {
  messageId: IntlMessageID;
  slClass?: SLButtonClass;
} & ButtonProps;

export const PopupCancelButton = ({
  slClass = "none",
  ...props
}: IPopupCancelButton) => {
  return (
    <SLButton
      {...props}
      slClass={slClass}
      messageId={props.messageId}
      size="small"
      sx={{
        height: "30px !important",
        textDecoration: "underline",
        color: "var(--sl-deep-purple)",
        "&:hover": {
          backgroundColor: "transparent",
        },
      }}
    />
  );
};
