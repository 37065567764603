import { Box, Stack } from "@mui/material";
import { onEnter } from "helpers/common/keypress";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import SettingCheckbox, { ReduceMotionSetting } from "../emailCalendar";

const PushNotification = () => {
  const dispatch = useDispatch();

  //Manage Selectors
  const { data, loading, error } = useSelector(SLSelector.getUserProfile);

  //Manage States
  const [valueChanged, setValueChanged] = useState(false);
  const [pushNotification, setPushNotification] = useState(true);
  const [formData, setFormData] = useState({
    remainderSetting: true,
    dailyInspirationSetting: true,
    productAnnouncementSetting: true,
    supportRequestSetting: true,
    otherUpdatesSetting: true,
    emailNotificationSetting: true,
    calenderNotificationSetting: true,
  });

  useEffect(() => {
    dispatch(SLThunk.getUserProfile.call());
  }, []);

  useEffect(() => {
    if (data) {
      if (
        data.settingPushReminder &&
        data.settingPushDailyInspiration &&
        data.settingPushAnnouncement &&
        data.settingPushAcheivement &&
        data.settingPushOther
      ) {
        setPushNotification(true);
      } else {
        setPushNotification(false);
      }
      setFormData({
        remainderSetting: data.settingPushReminder,
        dailyInspirationSetting: data.settingPushDailyInspiration,
        productAnnouncementSetting: data.settingPushAnnouncement,
        supportRequestSetting: data.settingPushAcheivement,
        otherUpdatesSetting: data.settingPushOther,
        emailNotificationSetting: data.settingEmailNotify,
        calenderNotificationSetting: data.settingSyncGoogleCalender,
      });
    }
  }, [data]);

  useEffect(() => {
    if (valueChanged == true) {
      dispatch(
        SLThunk.patchUserUpdateSettings.call({
          settingSyncGoogleCalender: formData.calenderNotificationSetting,
          settingPushReminder: formData.remainderSetting,
          settingPushDailyInspiration: formData.dailyInspirationSetting,
          settingPushAnnouncement: formData.productAnnouncementSetting,
          settingPushAcheivement: formData.supportRequestSetting,
          settingPushOther: formData.otherUpdatesSetting,
          settingEmailNotify: formData.emailNotificationSetting,
        })
      );
      setValueChanged(false);
    }
  }, [valueChanged]);

  const setAllSwitchesToOnOff = (flag: boolean) => {
    setFormData({
      ...formData,
      remainderSetting: flag,
      dailyInspirationSetting: flag,
      productAnnouncementSetting: flag,
      supportRequestSetting: flag,
      otherUpdatesSetting: flag,
    });
  };

  // const pushNotificationToggle = () => {
  //   setPushNotification(!pushNotification);
  //   if (pushNotification) {
  //     setAllSwitchesToOnOff(false);
  //   } else {
  //     setAllSwitchesToOnOff(true);
  //   }
  //   setValueChanged(true);
  // };

  const changeStatus = (value: string) => {
    if (value == "remainderSetting")
      setFormData({
        ...formData,
        remainderSetting: !formData.remainderSetting,
      });
    else if (value == "dailyInspirationSetting") {
      setFormData({
        ...formData,
        dailyInspirationSetting: !formData.dailyInspirationSetting,
      });
    } else if (value == "productAnnouncementSetting") {
      setFormData({
        ...formData,
        productAnnouncementSetting: !formData.productAnnouncementSetting,
      });
    } else if (value == "supportRequestSetting") {
      setFormData({
        ...formData,
        supportRequestSetting: !formData.supportRequestSetting,
      });
    } else if (value == "otherUpdatesSetting") {
      setFormData({
        ...formData,
        otherUpdatesSetting: !formData.otherUpdatesSetting,
      });
    }
    if (value == "emailNotificationSetting") {
      setFormData({
        ...formData,
        emailNotificationSetting: !formData.emailNotificationSetting,
      });
    }
    if (value == "calenderNotificationSetting") {
      setFormData({
        ...formData,
        calenderNotificationSetting: !formData.calenderNotificationSetting,
      });
    }
    setValueChanged(true);
  };

  return (
    <Box sx={{ minHeight: { sm: "45vh", md: "60vh", lg: "70vh", xl: "90vh" } }}>
      <Box sx={{ padding: { lg: "0px", xs: "16px" } }}>
        <Stack
          sx={{
            color: "#4B2B5A",
          }}
          spacing={2}
        >
          {/* <Card sx={{ boxShadow: "none", borderRadius: "10px" }}>
            <CardContent sx={{ padding: "0px" }}> */}
          {/* <SettingEmail
                messageId="app.setting.pushNotification"
                paragraphMessageId="app.setting.turnOnAllMsg"
                checkedValue={pushNotification}
                switchProps={{
                  onChange: () => pushNotificationToggle(),
                  // disabled: formData.pushNotificationSetting ? true : false,
                }}
              /> */}
          {/* </CardContent> */}
          {/* <CardContent
              sx={{
                borderTop: "1px solid #E7E7E7",
                paddingLeft: { lg: "18px" },
              }}
            >
              <PushNotifications
                messageId={"app.setting.reminder"}
                checkedKey={1}
                checkedValue={formData.remainderSetting}
                switchProps={{
                  onChange: () => changeStatus("remainderSetting"),
                  // disabled: pushNotification ? true : false,
                }}
              ></PushNotifications>
              <PushNotifications
                messageId={"app.setting.dailyInspiration"}
                checkedKey={2}
                checkedValue={formData.dailyInspirationSetting}
                switchProps={{
                  onChange: () => changeStatus("dailyInspirationSetting"),
                  // disabled: pushNotification ? true : false,
                }}
              ></PushNotifications>
              <PushNotifications
                messageId={"app.setting.productAnnouncements"}
                checkedKey={3}
                checkedValue={formData.productAnnouncementSetting}
                switchProps={{
                  onChange: () => changeStatus("productAnnouncementSetting"),
                  // disabled: pushNotification ? true : false,
                }}
              ></PushNotifications>
              <PushNotifications
                messageId={"app.setting.achievements"}
                checkedKey={4}
                checkedValue={formData.supportRequestSetting}
                switchProps={{
                  onChange: () => changeStatus("supportRequestSetting"),
                  // disabled: pushNotification ? true : false,
                }}
              ></PushNotifications>
              <PushNotifications
                messageId={"app.setting.otherUpdates"}
                checkedKey={5}
                checkedValue={formData.otherUpdatesSetting}
                switchProps={{
                  onChange: () => changeStatus("otherUpdatesSetting"),
                  // disabled: pushNotification ? true : false,
                }}
              ></PushNotifications>
            </CardContent> */}
          {/* </Card> */}
          <SettingCheckbox
            messageId="app.setting.emailNotification"
            paragraphMessageId="app.setting.emailNotificationText"
            checkedValue={formData.emailNotificationSetting}
            switchProps={{
              onChange: () => changeStatus("emailNotificationSetting"),
              onKeyPress: onEnter(() =>
                changeStatus("emailNotificationSetting")
              ),
              // disabled: formData.pushNotificationSetting ? true : false,
            }}
            loading={loading}
          />

          <ReduceMotionSetting />
          {/* <SettingCheckbox
            messageId="app.setting.calendarSetting"
            paragraphMessageId="app.setting.calenderSettingText"
            checkedValue={formData.calenderNotificationSetting}
            switchProps={{
              onChange: () => changeStatus("calenderNotificationSetting"),
              // disabled: formData.pushNotificationSetting ? true : false,
            }}
          /> */}
        </Stack>
      </Box>
    </Box>
  );
};

export default PushNotification;
