import {
  Avatar,
  Backdrop,
  BackdropProps,
  Box,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { LeftArrowWhite, SoulWhiteLoader } from "helpers/enum/constants";
import { useState } from "react";
import { SLButton } from "../../slButton";
import "./index.css";

type SLLoaderProps = BackdropProps & {
  open: boolean;
  setuserCancelMeditation?: any;
  userCancelMeditationHandler?: any;
  type?: string;
};

export const SLMeditationLoader = ({
  setuserCancelMeditation,
  userCancelMeditationHandler,
  type,
  open,
  ...props
}: SLLoaderProps) => {
  const [openDialog, setOpenDialog] = useState(false);

  const handleBtn = () => {
    setOpenDialog(false);
    userCancelMeditationHandler(true);
  };
  const closedialogue = () => {
    setOpenDialog(false);
    userCancelMeditationHandler(false);
  };
  const openConfirmDialogue = () => {
    setuserCancelMeditation(true);
    setOpenDialog(true);
  };
  return (
    <Backdrop {...props} open={open} className="backdrop-meditation-blur">
      {type != "meditationDetails" ? (
        <div className="back-btn-loader" onClick={openConfirmDialogue}>
          <img src={LeftArrowWhite} /> Cancel
        </div>
      ) : (
        ""
      )}
      <Stack direction="column" spacing="20px">
        <Box sx={{ position: "relative" }}>
          <div className="loader" />
          <Avatar
            className="rotate"
            alt="Loading Image"
            src={SoulWhiteLoader}
            sx={{ width: 120, height: 120, margin: "auto" }}
          />
        </Box>

        <SLTypography
          role="heading"
          tabIndex={0}
          variant="largeHeader"
          color="text-lightAqua"
          textAlign="center"
          sx={{ width: "230px", color: "white" }}
        >
          Building Your Meditation
        </SLTypography>
      </Stack>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={{ padding: "15px 25px" }}>
          <Stack direction="column" textAlign="left" spacing={1}>
            <DialogContent
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                padding: 0,
                color: "var(--sl-deep-purple)",
              }}
            >
              <SLTypography
                tabIndex={0}
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  maxWidth: "300px",
                  textAlign: "center",
                }}
              >
                Are you sure you want to cancel the meditaiton?
              </SLTypography>
            </DialogContent>
          </Stack>
          <Box
            sx={{
              marginTop: "3%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SLButton
              sx={{ width: "200px" }}
              variant="contained"
              messageId={"general.confirm"}
              onClick={() => handleBtn()}
              onKeyPress={() => handleBtn()}
            />
            <SLTypography
              tabIndex={0}
              onKeyPress={() => setOpenDialog(false)}
              onClick={closedialogue}
              sx={{
                color: "var(--sl-purple)",
                textDecoration: "underline",

                marginTop: "3%",
                cursor: "pointer",
              }}
              messageId={"general.cancel"}
            />
          </Box>
        </Box>
      </Dialog>
    </Backdrop>
  );
};
