import { Box, Paper } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  NavigateHowItWorksPlayGreen,
  SubscribeSoulLinkBackground,
} from "helpers/enum/constants";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { reduceMotionState } from "store/reduceMotionSlice";

const SubscribeSoulLink = () => {
  const navigate = useNavigate();
  const reduceMotion = useSelector(reduceMotionState);

  return (
    <>
      <Paper
        sx={{
          position: "relative",
          background: `url(${SubscribeSoulLinkBackground})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: { xs: "100%", sm: "400px" },
          height: "300px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          color: "white",
          textAlign: "center",
          borderRadius: "10px",
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={() => {
          navigate("/subscription-activation");
        }}
      >
        {!reduceMotion ? (
          <video autoPlay={true} muted playsInline loop id="myVideo">
            <source src="/assets/videos/auth.webm" />
          </video>
        ) : null}

        <Box
          sx={{
            paddingLeft: "30px",
            paddingRight: "30px",
            zIndex: 1,
          }}
        >
          <SLTypography
            role="heading"
            tabIndex={0}
            variant="headLine"
            sx={{
              fontSize: { xs: "19px", md: "22px", lg: "27px" },
              paddingLeft: { xs: "0px", md: "16px", lg: "0px" },
              paddingRight: { xs: "0px", md: "16px", lg: "0px" },
            }}
          >
            Unlock the power of Soul Link. Subscribe to get started.
          </SLTypography>
          <Link
            to="/subscription-activation"
            style={{ textDecoration: "none", color: "white" }}
          >
            <Box
              sx={{
                display: "flex",
                direction: "row",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                gap: "8px",
                paddingTop: { xs: "10px", sm: "15px", md: "30px" },
              }}
            >
              <Box
                component="img"
                src={NavigateHowItWorksPlayGreen}
                alt="/"
                sx={{ width: "36px", height: "36px" }}
              />
              <SLTypography
                sx={{
                  fontSize: "15px",
                  fontWeight: "600",
                }}
              >
                Begin Your Journey
              </SLTypography>
            </Box>
          </Link>
        </Box>
      </Paper>
    </>
  );
};

export default SubscribeSoulLink;
