import {
  GuestAuth,
  SLAuth,
  SLUser,
  SLUserSubscription,
} from "./slRTKHelper/slAPI.models";
import { SLStorage } from "./slStorage";

export const Auth = {
  guest: {
    loggedin: () => {
      const guestAuth = SLStorage.getItem("guestAuth");

      // handle legacy code
      if ("string" === typeof guestAuth || !guestAuth?.id) {
        Auth.guest.logout();

        return null;
      }

      return guestAuth;
    },

    login: (auth: GuestAuth) => {
      Auth.user.logout();

      SLStorage.setItem("guestLogin", true);
      SLStorage.setItem("authType", "guest");
      SLStorage.setItem("guestAuth", { ...auth, time: Date.now() } as any);
    },
    logout: () => {
      SLStorage.removeItem("authType");
      SLStorage.removeItem("guestLogin");
      SLStorage.removeItem("guestAuth");
    },
  },

  user: {
    loggedin: () => SLStorage.getItem("auth"),

    login: (auth: SLAuth, type: "email" | "social") => {
      Auth.guest.logout();

      if (auth) {
        SLStorage.setItem("auth", auth);
      }

      SLStorage.setItem("authType", type);
    },

    profile: (profile?: SLUser) => {
      if (profile !== undefined) {
        SLStorage.setItem("profile", profile);
      }

      return SLStorage.getItem("profile");
    },

    subscription: (subscription?: SLUserSubscription) => {
      if (subscription !== undefined) {
        SLStorage.setItem("subscription", subscription);
        SLStorage.setItem("signupCompleted", true);
      }

      return SLStorage.getItem("subscription");
    },

    logout: () => {
      SLStorage.removeItem("auth");
      SLStorage.removeItem("authType");
      SLStorage.removeItem("profile");
      SLStorage.removeItem("subscription");
      SLStorage.removeItem("signupCompleted");
      SLStorage.removeItem("sceneId");
    },
  },
};
