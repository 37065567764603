import { PageHeading } from "components/pageHeading";
import AccountSettingScreen from "components/profile/accountSetting/userDetails";
import { lang } from "helpers/common/lang";
import ProfilePages from "layouts/profilePages";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";

const AccountSettingPage = () => {
  const dispatch = useDispatch();
  const getUserProfile = useSelector(SLSelector.getUserProfile);

  useEffect(() => {
    if (!getUserProfile?.data?.id) {
      dispatch(SLThunk.getUserProfile.call());
    }
  }, []);

  return (
    <>
      <ProfilePages title={lang("profile.accountSetting")}>
        <>
          <PageHeading messageId="profile.accountSetting" />

          {getUserProfile && (
            <AccountSettingScreen getUserProfile={getUserProfile?.data} />
          )}
        </>
      </ProfilePages>
    </>
  );
};

export default AccountSettingPage;
