import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { ShareIcon } from "helpers/enum/constants";
import { useDispatch } from "react-redux";
import { webShareOpen } from "store/webShareSlice";
import "./index.css";

type DailyInspirationProps = {
  quotes: string;
};

export default function DailyInspirationCard({
  quotes,
}: DailyInspirationProps) {
  const dispatch = useDispatch();

  return (
    <>
      <Card
        sx={{
          display: "flex",
          borderRadius: "10px",
          border: "none",
          boxShadow: "none",
          boxSizing: "border-box",
        }}
        // tabIndex={-1}
      >
        <CardActionArea
          role="listitem"
          sx={{ boxSizing: "border-box" }}
          tabIndex={-1}
          className="selectAbleText"
          disableRipple
        >
          <CardContent
            className="quotes-text"
            sx={{ boxSizing: "border-box" }}
            tabIndex={-1}
          >
            <SLTypography
              variant="subhead"
              sx={{
                color: "var(--sl-deep-purple)",
                fontSize: { xs: "14px", md: "17px" },
                paddingLeft: { xs: "4px", md: "14px" },
                paddingTop: { xs: "4px", md: "8px" },
                paddingBottom: { xs: "4px", md: "8px" },
                paddingRight: { xs: "px", md: "14px" },
              }}
              tabIndex={0}
            >
              {quotes}
            </SLTypography>
          </CardContent>
        </CardActionArea>
        <CardActions
          sx={{
            borderLeft: "1px solid var(--colorVeryGray)",
            width: "50px",
            justifyContent: "center",
          }}
        >
          <Button
            sx={{ aspectRatio: "1", borderRadius: "50%" }}
            onClick={() => {
              dispatch(
                webShareOpen({
                  text: `Inspirational wisdom for your day from Soul Link.\n${String(
                    quotes
                  )}`,
                })
              );
            }}
            tabIndex={0}
            aria-label={"Share " + quotes}
          >
            <ShareIcon sx={{ color: "var(--sl-deep-purple)" }} />
          </Button>
        </CardActions>
      </Card>
    </>
  );
}
