import { Avatar, Backdrop, BackdropProps, Box, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { VerificationMessage } from "helpers/enum/constants";
import "./index.css";

type SLLoaderProps = BackdropProps & { open: boolean; message: string };

export const SLVerificationMessage = ({
  open,
  message,
  ...props
}: SLLoaderProps) => {
  return (
    <Backdrop {...props} open={open} className="backdrop-blur" tabIndex={0}>
      <Stack direction="column" spacing="20px">
        <Box
          sx={{
            backgroundColor: "var(--sl-aqua)",
            minWidth: "250px",
            maxWidth: "280px",
            maxHeight: "280px",
            minHeight: "250px",
            borderRadius: "10px",
            textAlign: "center",
          }}
        >
          <Stack spacing={2} alignItems="center" marginTop="55px">
            <Avatar
              className="rotate"
              alt="Loading Image"
              src={VerificationMessage}
              sx={{
                width: 60,
                height: 60,
              }}
            />
            <SLTypography
              sx={{
                color: "var(--colorWhite)",
                marginBottom: "30%",
                marginLeft: "15% !important",
                marginRight: "15% !important",
                marginTop: "8px",
                textAlign: "center",
              }}
              variant="verificationDialog"
              tabIndex={0}
              title={message}
              aria-label={message}
            >
              {message}
            </SLTypography>
          </Stack>
        </Box>
      </Stack>
    </Backdrop>
  );
};
