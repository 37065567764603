import { Box, Grid, Slider } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import { SLTypography } from "components/slTypography";
import { onEnterOrSpace } from "helpers/common/keypress";
import {
  Airplay,
  CloseFullScreen,
  ClosedCaptionDisabledIcon,
  ClosedCaptionIcon,
  CrossIcon,
  FastForward,
  FastRewind,
  FullScreen,
  GoogleCast,
  InfoOutlined,
  LoopPlayIcon,
  LoopStopIcon,
  MuteVolume,
  Pause,
  Play,
  Volume,
} from "helpers/enum/constants";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLThunk } from "utils/slRTKHelper";
import { SLMeditationDetail } from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";
import { MiniplayerContext } from "../../contexts/miniplayerContext";
import { FrequencyVolume } from "./FrequencyVolume/FrequencyVolume";
import { IQualityLevel, QualitySelector } from "./QualitySelector";
import "./index.css";
const guestLogin = SLStorage.getItem("guestLogin");
const useStyles = makeStyles((theme: any) => ({
  controlsWrapper: {
    position: "absolute",
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
    display: "flex",
    flexWrap: "nowrap",
    justifyContent: "space-between",
    height: "100%",
    zIndex: 3,
  },
  topContainer: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
    height: "33%",
    padding: "0 16px",
  },
  midContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
    height: "33%",
  },
  iconBtn: {
    cursor: "pointer",
    color: "white",
  },
  bottomContainer: {
    display: "flex",
    alignItems: "flex-end",
    gap: "0px",
    height: "33%",
    padding: "0 16px",
    paddingBottom: "20px",
    boxSizing: "border-box",
  },
  videoTimer: {
    color: "white",
    fontSize: "0.8rem",
    fontFamily: "Figtree !important",
  },
}));

export const PrettoSlider = styled(Slider)({
  color: "#fff !important",
  padding: "10px 0 !important",
  height: 4,
  "& .MuiSlider-track": {
    border: "none",
  },
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    color: "#fff",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": {
      display: "none",
    },
  },
  "& .MuiSlider-valueLabel": {
    lineHeight: 1.2,
    fontSize: 12,
    background: "unset",
    padding: 0,
    width: 32,
    height: 32,
    borderRadius: "50% 50% 50% 0",
    backgroundColor: "#fff",
    transformOrigin: "bottom left",
    transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
    "&:before": { display: "none" },
    "&.MuiSlider-valueLabelOpen": {
      transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
    },
    "& > *": {
      transform: "rotate(45deg)",
    },
  },
});

export const VolumeSlider = styled(Slider)({
  color: "#fff !important",
  width: 4,
  padding: 0,
  "& .MuiSlider-track": {
    border: "none",
    width: 5,
  },
  "& .MuiSlider-thumb": {
    height: 15,
    width: 15,
    backgroundColor: "#fff",
    color: "#fff",
    "&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
      boxShadow: "inherit",
    },
    "&:before": { display: "none" },
  },
});

export const VolumeIcon = styled("img")({
  height: "24px",
  width: "24px",
  paddingBottom: "5px",
  cursor: "pointer",
});

interface PlayBackProps {
  controlsShowing: boolean;
  showControls: () => void;
  castingConnected: boolean;
  castingAvailable: boolean;
  onCastConnect: () => void;
  captionsAvailable: boolean;
  captionsShowing: boolean;
  onClosedCaptions: () => void;
  onSeek: (...props: any) => any;
  onSeekMouseUp: (...props: any) => any;
  onPip: any;
  onRewind: any;
  onPlay: any;
  onPause: any;
  onFastForward: any;
  buffer: boolean;
  playing: any;
  elapsedTime: any;
  totalDuration: any;
  onMute: any;
  muted: any;
  initialMuted: boolean | null;
  onChangeDispayFormat: any;
  onToggleFullScreen: any;
  volume: number;
  frequencyVolume: number;
  onVolumeChange: any;
  onFrequencyVolumeChange: (e: any, value: number) => any;
  isFullScreen: boolean;
  loop: boolean;
  setLoopValue: (bool: boolean) => any;
  openCompletionPopup: (bool: boolean) => any;
  setStillMeditating: any;
  mediaData?: SLMeditationDetail;
  completion: number;
  qualityLevels: IQualityLevel[];
  selectedQualityLevel: number;
  setSelectedQualityLevel: (n: number) => any;
  currentQualityLevel: number;
  userInteracted: () => void;
  interactionTime: number;
}

const Controls = memo(
  ({
    controlsShowing,
    showControls,
    castingConnected,
    castingAvailable,
    onCastConnect,
    captionsAvailable,
    captionsShowing,
    onClosedCaptions,
    onSeek,
    onPip,
    onSeekMouseUp,
    onPlay,
    onPause,
    onFastForward,
    onRewind,
    buffer,
    playing,
    elapsedTime,
    totalDuration,
    onMute,
    muted,
    initialMuted,
    onChangeDispayFormat,
    onToggleFullScreen,
    isFullScreen,
    volume,
    frequencyVolume,
    onVolumeChange,
    onFrequencyVolumeChange,
    loop,
    setLoopValue,
    openCompletionPopup,
    setStillMeditating,
    mediaData,
    completion,
    qualityLevels,
    selectedQualityLevel,
    setSelectedQualityLevel,
    currentQualityLevel,
    userInteracted,
    interactionTime,
  }: PlayBackProps) => {
    const mixpanel = useMixpanel();
    const classes = useStyles();
    const navigate = useNavigate();
    const { id } = useParams();
    const dispatch = useDispatch();
    const secsPlayed = useRef(0);
    const { setMiniplayerState } = useContext(MiniplayerContext);
    const [showVolume, setShowVolume] = useState<boolean>(false);

    function isEligibleForStillMeditating(timestamp: number) {
      const currentTime = Date.now();
      const minutes = 60;

      return currentTime - timestamp > minutes * 60 * 1000;
    }

    useEffect(() => {
      secsPlayed.current++;

      // After every 10 sec video achievenment api call
      if (secsPlayed.current % 10 === 0) {
        if (guestLogin !== true) {
          dispatch(SLThunk.patchStatsMeditationTime.call({}));
        }
      }
      // After 15 sec api call to recently played video
      if (secsPlayed.current === 15) {
        if (guestLogin !== true)
          dispatch(SLThunk.patchMeditationPlayedId.call({ id: id ?? "" }));
      }
      setMiniplayerState({
        playing,
        currentTime: elapsedTime,
        totalTime: totalDuration,
        track: { ...mediaData } as any,
        frequencyVolume: frequencyVolume,
      });

      if (isEligibleForStillMeditating(interactionTime)) {
        setStillMeditating(true);
      }

      // After completion of video api call
      if (
        elapsedTime == totalDuration &&
        elapsedTime != "0:00" &&
        elapsedTime !== "00:00"
      ) {
        if (!loop) {
          openCompletionPopup(true);
          dispatch(SLThunk.patchStatsCompleteSession.call({}));
        }
        if (loop && playing) {
          onSeek(null, 0);
          onPlay();
        }
      }

      if (mediaData && completion != 0) {
        mixpanel.triggerTimeSpentWatchingMeditationEvent(
          mediaData,
          elapsedTime
        );
        mixpanel.incrementTimeSpentWatchingMeditation();
      }
    }, [elapsedTime]);

    useEffect(() => {
      if (controlsShowing === false) {
        setShowVolume(false);
      }
    }, [controlsShowing]);

    const MainSlider = (event: any) => {
      if (event.key === "ArrowRight") {
        onFastForward();
      } else if (event.key === "ArrowLeft") {
        onRewind();
      }
    };

    const handleLoop = () => {
      setLoopValue(!loop);
    };

    const gotoCreateMeditation = () => {
      navigate("/app/meditation/create");
    };

    const gotoMeditationDetails = () => {
      navigate(`/app/meditation-details/${id}`);
    };

    const handleShowVolume = (volumeStaus: boolean) => {
      if (!showVolume) {
        setTimeout(() => {
          const slider = document.getElementById("volume-slider");
          if (slider) {
            const rail = slider.querySelector("input") as HTMLDivElement;
            if (rail) {
              rail.focus();
            }
          }
        }, 400);
      }

      setShowVolume(volumeStaus);
    };

    const handleToggleVolume = () => {
      onMute();
    };

    return (
      <>
        <Grid
          container
          className={"meditation-player " + classes.controlsWrapper}
          wrap="nowrap"
          flexDirection="column"
          sx={{ visibility: controlsShowing ? "visible" : "hidden" }}
        >
          {/* title row */}
          <Grid className={classes.topContainer}>
            <Grid item xs={6}>
              {isFullScreen ? (
                <div
                  style={{
                    display: "flex",
                    paddingTop: "10px",
                  }}
                >
                  <CrossIcon
                    role="button"
                    tabIndex={0}
                    titleAccess="Back To Home"
                    style={{
                      color: "white",
                      cursor: "pointer",
                      marginRight: "10px",
                    }}
                    onClick={gotoCreateMeditation}
                    onFocus={() => showControls()}
                    onKeyPress={gotoCreateMeditation}
                  />

                  <SLTypography
                    className="add-shadow"
                    variant="subhead"
                    color="text-white"
                    aria-label="My Created Meditation"
                    textOverflow="ellipsis"
                    display="inline-block"
                    width="100%"
                    overflow="hidden"
                    whiteSpace="nowrap"
                    sx={{
                      fontSize: {
                        lg: "27px",
                        md: "20px",
                        sm: "15px",
                        xs: "14px",
                      },
                    }}
                  >
                    {mediaData?.title ? mediaData.title : "N/A"} <br />
                    <SLTypography
                      variant="smallBodyCopy"
                      color="text-white"
                      aria-label="My Created Meditation"
                      tabIndex={0}
                    >
                      Created Meditation
                    </SLTypography>
                  </SLTypography>
                </div>
              ) : null}
            </Grid>

            <Grid item xs={6} style={{ textAlign: "right" }}>
              <div
                style={{
                  position: "relative",
                  left: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                  gap: "20px",
                  paddingTop: "10px",
                }}
              >
                {isFullScreen ? (
                  <InfoOutlined
                    onClick={() => gotoMeditationDetails()}
                    onFocus={() => showControls()}
                    onKeyPress={() => gotoMeditationDetails()}
                    tabIndex={0}
                    sx={{
                      cursor: "pointer",
                      color: "white",
                    }}
                  />
                ) : null}
                {captionsAvailable ? (
                  <img
                    role="button"
                    aria-selected={captionsShowing}
                    src={
                      captionsShowing
                        ? ClosedCaptionIcon
                        : ClosedCaptionDisabledIcon
                    }
                    title={
                      captionsShowing
                        ? "Closed Captions On, tap to turn off"
                        : "Closed Captions Off, tap to turn on"
                    }
                    onClick={onClosedCaptions}
                    onKeyPress={onEnterOrSpace(onClosedCaptions)}
                    style={{
                      cursor: "pointer",
                      color: "white",
                    }}
                    tabIndex={0}
                  />
                ) : null}
                <img
                  style={{
                    width: "26px",
                    color: "white",
                    fill: "white",
                    cursor: "pointer",
                  }}
                  src={loop ? LoopPlayIcon : LoopStopIcon}
                  onClick={() => handleLoop()}
                  onKeyPress={() => handleLoop()}
                  aria-label="Loop"
                  title="Loop"
                  tabIndex={0}
                />
                {castingAvailable ? (
                  <GoogleCast
                    aria-label="Cast"
                    titleAccess="Cast"
                    onClick={onCastConnect}
                    onFocus={() => showControls()}
                    onKeyPress={onCastConnect}
                    style={{
                      cursor: "pointer",
                      color: "white",
                    }}
                    tabIndex={0}
                  />
                ) : null}
                {0 ? (
                  <Airplay
                    style={{
                      cursor: "pointer",
                      color: "white",
                    }}
                    tabIndex={0}
                  />
                ) : null}
                <QualitySelector
                  qualityLevels={qualityLevels}
                  selectedQualityLevel={selectedQualityLevel}
                  setSelectedQualityLevel={setSelectedQualityLevel}
                  currentQualityLevel={currentQualityLevel}
                />
                {isFullScreen ? (
                  <CloseFullScreen
                    style={{ cursor: "pointer", color: "white" }}
                    onClick={onToggleFullScreen}
                    onKeyPress={onToggleFullScreen}
                    aria-label="Close Screen"
                    titleAccess="Close Screen"
                    tabIndex={0}
                  />
                ) : (
                  <FullScreen
                    style={{ cursor: "pointer", color: "white" }}
                    onClick={onToggleFullScreen}
                    onFocus={() => showControls()}
                    onKeyPress={onToggleFullScreen}
                    aria-label="Full Screen"
                    titleAccess="Full Screen"
                    tabIndex={0}
                  />
                )}
              </div>
            </Grid>
          </Grid>

          <Box className={classes.midContainer}>
            <div
              className={classes.iconBtn}
              onClick={onRewind}
              onFocus={() => showControls()}
              onKeyPress={onRewind}
              aria-label="rewind"
              title="rewind"
              tabIndex={0}
            >
              <FastRewind fontSize="medium" />
            </div>
            <div
              className={classes.iconBtn}
              onClick={playing ? onPause : onPlay}
              onFocus={() => showControls()}
              onKeyPress={playing ? onPause : onPlay}
              aria-label={playing ? "pause" : "play"}
              title={playing ? "pause" : "play"}
              tabIndex={0}
            >
              {playing ? (
                <Pause
                  fontSize="large"
                  aria-label="Pause"
                  titleAccess="Pause"
                />
              ) : (
                <Play fontSize="large" titleAccess="Play" aria-label="Play" />
              )}
            </div>
            <div
              className={classes.iconBtn}
              onClick={onFastForward}
              onFocus={() => showControls()}
              onKeyPress={onFastForward}
              tabIndex={0}
              aria-label="forward"
              title="forward"
            >
              <FastForward fontSize="medium" />
            </div>
          </Box>

          {/* bottom row */}
          <Box className={classes.bottomContainer}>
            <Box flexGrow={2}>
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                sx={{ width: "100%" }}
              >
                <span className={"add-shadow  " + classes.videoTimer}>
                  {elapsedTime}
                </span>

                <span style={{ flexGrow: 2 }} />

                <span
                  onClick={onChangeDispayFormat}
                  onKeyPress={onChangeDispayFormat}
                  className={"add-shadow  " + classes.videoTimer}
                >
                  {totalDuration}
                </span>
              </Box>
              {totalDuration !== null &&
                totalDuration !== undefined &&
                totalDuration !== "00:00" && (
                  <Box
                    sx={{ visibility: castingConnected ? "hidden" : "unset" }}
                  >
                    <PrettoSlider
                      className="add-shadow"
                      min={0}
                      max={100}
                      value={completion * 100}
                      onChange={onSeek}
                      onChangeCommitted={onSeekMouseUp}
                      onKeyUp={MainSlider}
                    />
                  </Box>
                )}
            </Box>
            {mediaData?.frequencyWave?.frequencyValues &&
            mediaData?.frequencyWave?.frequencyValues?.length > 0 ? (
              <Box
                sx={{
                  display: castingConnected ? "none" : "flex",
                  marginLeft: "20px",
                }}
                flexDirection="column"
                alignItems="center"
                gap="15px"
                height="120px"
              >
                <FrequencyVolume
                  audioVolume={volume * 100}
                  controlsShowing={controlsShowing}
                  showControls={showControls}
                  playing={playing}
                  initialMuted={initialMuted || castingConnected}
                  buffer={buffer}
                  mediaData={mediaData}
                  completion={completion}
                  frequencyVolume={frequencyVolume}
                  onFrequencyVolumeChange={onFrequencyVolumeChange}
                />
              </Box>
            ) : null}
            <Box
              sx={{ display: "flex", marginLeft: "10px" }}
              flexDirection="column"
              alignItems="center"
              gap="15px"
              height="120px"
              onMouseLeave={() => handleShowVolume(false)}
            >
              <VolumeSlider
                role="slider"
                id="volume-slider"
                aria-label="Volume slider"
                min={0}
                max={100}
                orientation="vertical"
                value={initialMuted || muted ? 0 : volume * 100}
                onChange={onVolumeChange}
                onFocus={() => showControls()}
                onChangeCommitted={onVolumeChange}
                className={"add-shadow"}
                sx={{
                  opacity: showVolume ? 100 : 0,
                  pointerEvents: showVolume ? "initial" : "none",
                }}
                tabIndex={showVolume ? 0 : -1}
              />

              <VolumeIcon
                onMouseEnter={() => handleShowVolume(true)}
                src={initialMuted || muted ? MuteVolume : Volume}
                onClick={handleToggleVolume}
                onFocus={() => showControls()}
                onKeyPress={onEnterOrSpace(handleToggleVolume)}
                aria-label={"Tap to change volume"}
                tabIndex={0}
              />
            </Box>
          </Box>
        </Grid>
      </>
    );
  }
);

export default Controls;
