import ContactUsSection from "components/aboutpages/contact-us";
import AboutPages from "../../../layouts/aboutPages";

const ContactUs = () => {
  return (
    <>
      <AboutPages title="Contact Us">
        <ContactUsSection />
      </AboutPages>
    </>
  );
};

export default ContactUs;
