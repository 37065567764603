import { Box, CircularProgress, Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import canAutoPlay from "can-autoplay";
import { MiniplayerContext } from "contexts/miniplayerContext";
import { fadeVolume } from "helpers/common/fadeVolume";
import {
  convertSecondsToTime,
  convertTimeToSeconds,
} from "helpers/common/time";
import Hls from "hls.js";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import screenfull from "screenfull";
import {
  videoControlsActions,
  videoControlsState,
} from "store/videoControlsSlice";
import { volumeFadeRedux } from "store/volumeFadeSlice";
import { useCjs } from "utils/cjs/useCjs";
import { isiPhone } from "utils/isIPhone";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLMeditationDetail } from "utils/slRTKHelper/slAPI.models";
import MeditationLoopDialog from "../dialogBoxes/meditationLoopDialog";
import StopCastingDialog from "../dialogBoxes/stopCastingDialog";
import MeditaionSummaryPopUp from "../popUps/meditationSummery";
import { SLVerificationMessage } from "../verificationMessage";
import Controls from "../videoControls";
import { IQualityLevel } from "../videoControls/QualitySelector";

let hls: Hls | undefined;
let cjsAttached = false;
let hlsErrorRetryTimeout: NodeJS.Timeout | undefined;
let fadeVolumeInterval: NodeJS.Timer | undefined;
let unfadeVolumeInterval: NodeJS.Timer | undefined;

interface IVideoPlayer {
  mediaData: SLMeditationDetail;
  videoUrl: string;
  dynamicLinkUrlResponse: string;
  videoThumbnail: string;
  isLiked: boolean;
  setIsLiked: any;
}

export interface ReactControlerProps {
  pip: boolean;
  playing: boolean;
  controls: boolean;
  muted: boolean;
  duration: number;
  frequencyVolume: number;
  volume: number;
  loop: boolean;
  buffer: boolean;
  captions: boolean;
}

export interface ICastingState {
  available: boolean;
  device: string;
  state: string;
}

const useStyles = makeStyles(() => ({
  fullScreenWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
  },
  playerWrapper: {
    width: "100%",
    position: "relative",
    aspectRatio: "16/9",
    overflow: "hidden",
  },
}));

const VideoPlayer = memo((props: IVideoPlayer) => {
  const mixpanel = useMixpanel();
  const cjs = useCjs();
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { meditationSessionStatus, meditationSession, miniplayerState } =
    useContext(MiniplayerContext); // @TODO JUNAID: also retrieve frequencyVolume

  const videoErrorTimeout = useRef<any>();
  const volumeFadeState = useSelector(volumeFadeRedux);
  const videoControls = useSelector(videoControlsState);
  const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [autoPlayable, setAutoPlayable] = useState<boolean | null>(null);
  const [hlsInit, setHlsInit] = useState(false);
  const [initialMuted, setInitialMuted] = useState<boolean>(true);
  const [state, setState] = useState<ReactControlerProps>({
    pip: false,
    playing: false,
    controls: false,
    muted: false,
    duration: 0,
    volume: videoControls?.playerVolume ?? 0.5,
    frequencyVolume: 20,
    loop: false,
    buffer: true,
    captions: false,
  });

  const [castConnected, setCastConnected] = useState<boolean>(false);
  const [castingAvailable, setCastingAvailable] = useState<boolean>(false);
  const [captionsAvailable, setCaptionsAvailable] = useState<boolean>(false);
  const [stopCastingDialog, setStopCastingDialog] = useState<boolean>(false);
  const hlsStarting = useRef(false);
  const hlsError = useRef(false);
  const castStarting = useRef(false);
  const castingTime = useRef<number>(0);
  const [casting, setCasting] = useState<ICastingState>({
    available: false,
    device: "",
    state: "",
  });

  const playerContainerRef = useRef<HTMLDivElement | null>(null);
  const [controlsShowing, setShowControl] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  const timeoutRef = useRef<any>();

  const [qualityLevels, setQualityLevels] = useState<IQualityLevel[]>([]);
  const [selectedQualityLevel, setSelectedQualityLevel] = useState<number>(-1);
  const [currentQualityLevel, setCurrentQualityLevel] = useState<number>(-1);

  const [videoCompletion, openCompletionPopup] = useState<boolean>(false);
  const [openPauseDialog, setOpenPauseDialog] = useState<boolean>(false);

  const [showStillMeditating, setStillMeditating] = useState(false);
  const [interactionTime, setInteractionTime] = useState<number>(Date.now());

  const [success, setSuccess] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");

  const videoRef = useRef<HTMLVideoElement>(null);

  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTimePretty, setCurrentTimePretty] = useState("00:00");
  const [durationPretty, setDurationPretty] = useState("00:00");
  const [completion, setCompletion] = useState(0);
  const completed = () =>
    currentTimePretty == durationPretty &&
    currentTimePretty != "0:00" &&
    currentTimePretty !== "00:00";

  useEffect(() => {
    setState((prv) => ({
      ...prv,
      playing: meditationSessionStatus === "AVAILABLE",
    }));
  }, [meditationSessionStatus]);

  useEffect(() => {
    if (videoControls.playerVolume) {
      showControls();
    }
  }, [videoControls.playerVolume]);

  useEffect(() => {
    if (state.frequencyVolume) {
      showControls();
      handleInitialUnmute();
    }
  }, [state.frequencyVolume]);

  const userInteracted = () => {
    setInteractionTime(Date.now());
  };

  const handleVideoTime = () => {
    if (videoRef.current) {
      const ct = Math.floor(videoRef.current.currentTime || 0);

      if (currentTime != ct) {
        clearTimeout(hlsErrorRetryTimeout); // no more error
        setCurrentTimePretty(convertSecondsToTime(ct));

        let c = 0;

        if (!duration) {
          const d = videoRef.current.duration || 0;
          c = ct / d;
          setDuration(d);
          setDurationPretty(convertSecondsToTime(d));
        } else {
          c = ct / duration;
        }

        setCurrentTime(ct);
        c = Math.floor(isNaN(c) ? 0 : c * 100) / 100;
        setCompletion(c);
        setState((prv) => ({ ...prv, buffer: false }));
      }
    }
  };

  const handleVideoDuration = (e: any) => {
    if (castingTime.current) {
      setTimeout(() => {
        if (castingTime.current && videoRef.current) {
          videoRef.current.currentTime = castingTime.current;
          castingTime.current = 0;
          handleVideoPlaying();
        }
        hlsStarting.current = false;
        castStarting.current = false;
      }, 500);
    } else if (videoRef.current?.duration) {
      hlsStarting.current = false;
      castStarting.current = false;
    }
  };

  const handleVideoError = (e: any) => {
    // console.error(`🚀 > handleVideoError > e:`, e);
    if (videoRef.current) {
      const videoUrl = videoRef.current.src;
      const currentTime = Number(videoRef.current.currentTime);

      if (videoErrorTimeout.current) {
        clearTimeout(videoErrorTimeout.current);
      }

      videoErrorTimeout.current = setTimeout(() => {
        if (videoUrl && videoRef.current) {
          videoRef.current.src = ""; // Reset the video source
          videoRef.current.src = videoUrl; // Set the source URL again
          // videoRef.current.load(); // Reload the video
          videoRef.current.currentTime = Math.floor(currentTime);

          videoRef.current.play().catch((e) => {
            handleFailedPause(e);
          });
        }
      }, 2000);
    }
  };

  useEffect(() => {
    if (videoCompletion) {
      if (!state.loop) {
        setState((prv) => ({ ...prv, playing: false }));
      }
      mixpanel.triggerMeditationSessionCompletedEvent(props.mediaData);
      setOpenPauseDialog(false);
      meditationSession.end();
    }
  }, [videoCompletion]);

  const handleVideoBuffering = (e: any) => {
    if (videoRef.current) {
      setState((prv) => ({ ...prv, buffer: true }));
    }
  };

  const handleVideoPlaying = () => {
    if (videoRef.current) {
      const playing = !videoRef.current.paused;
      setTimeout(() => {
        setState((prv) => ({ ...prv, playing }));
      });
    }
  };

  useEffect(() => {
    userInteracted();

    if (state.playing && !meditationSession.getSession()) {
      meditationSession.start();
    }

    if (videoRef.current) {
      if (state.playing) {
        videoRef.current.play().catch((e) => {
          clearTimeout(hlsErrorRetryTimeout);
          hlsErrorRetryTimeout = setTimeout(() => {
            handleFailedPause(e);
          }, 10000);
        });
      } else {
        videoRef.current.pause();
      }
    }

    if (cjs.connected) {
      if (state.playing) {
        cjs.play();
      } else {
        cjs.pause();
      }
    }
  }, [state.playing]);

  useEffect(() => {
    userInteracted();

    if (videoRef.current) {
      videoRef.current.volume = state.volume;
    }

    if (cjs.connected) {
      cjs.volume(state.volume);
    }
  }, [state.volume]);

  useEffect(() => {
    userInteracted();

    if (videoRef.current) {
      videoRef.current.muted = state.muted;
    }

    if (cjs.connected) {
      if (state.muted) {
        // cjs.volume(0);
      } else {
        // cjs.volume(state.volume);
      }
    }
  }, [state.muted]);

  useEffect(() => {
    if (videoRef.current && !initialMuted) {
      videoRef.current.muted = false;
    }
  }, [initialMuted]);

  const handleVideoVolume = () => {
    if (videoRef.current) {
      setState((prv) => ({
        ...prv,
        muted: videoRef.current?.muted || false,
        volume: videoRef.current?.volume || 0,
      }));
    }
  };

  const checkHlsAutoplayable = () => {
    canAutoPlay
      .video({ muted: false, timeout: 1000 })
      .then(({ result, error }: any) => {
        setAutoPlayable(result);
        if (result) {
          setInitialMuted(false);
        }
      });
  };

  const handleEsc = (event: any) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }

    // if (event.code === "Space" || event.keyCode === 32) {
    //   // event?.preventDefault();
    //   // if (state.playing) {
    //   //   handlePause();
    //   // } else {
    //   //   handlePlay();
    //   // }
    // }
  };

  function handleFullscreen() {
    debugger;
    if (isiPhone) {
      setIsFullScreen(false);
    }
    if (playerContainerRef.current) {
      if (document.fullscreenElement === playerContainerRef.current) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    }
  }

  useEffect(() => {
    // const isiPhone = /iPhone/i.test(navigator.userAgent);
    // if (!isiPhone) {
    //   userInteracted();

    //   if (isFullScreen) {
    //     screenfull.request(playerContainerRef.current || undefined);
    //     if (playerContainerRef.current) {
    //       playerContainerRef.current.style.backgroundColor = "black";
    //     }
    //   } else {
    //     if (playerContainerRef.current) {
    //       playerContainerRef.current.style.backgroundColor = "";
    //     }
    //     try {
    //       // screenfull.exit();
    //     } catch (error) {
    //       console.error("Error during fullscreen exit:", error);
    //     }
    //   }
    // }

    userInteracted();
    try {
      // console.log(isFullScreen);
      if (isFullScreen) {
        screenfull.request(playerContainerRef.current || undefined);
        if (playerContainerRef.current) {
          playerContainerRef.current.style.backgroundColor = "black";
        }
      } else {
        if (playerContainerRef.current) {
          playerContainerRef.current.style.backgroundColor = "";
        }
        if (!isiPhone) screenfull.exit();
      }
    } catch (error) {
      console.error("Error during fullscreen exit:", error);
    }
  }, [isFullScreen]);

  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };

  useEffect(() => {
    userInteracted();

    if (hls) {
      hls.nextLevel = selectedQualityLevel;
    }
  }, [selectedQualityLevel]);

  const hanldeClosedCaptions = () => {
    if (captionsAvailable && hls) {
      const captionState = !state.captions;

      setState((prv) => ({ ...prv, captions: captionState }));
      hls.subtitleDisplay = captionState;
      dispatch(videoControlsActions.subtitleToggle(captionState));
      // hls.subtitleTrack = captionState ? 0 : -1;
    }
  };

  useEffect(() => {
    checkHlsAutoplayable();
    checkCastingAvailable();
    window.addEventListener("keydown", handleEsc);
    document.addEventListener("fullscreenchange", handleFullscreen);

    return () => {
      destroyCjs();
      removeVideoListeners();
      removeVideoListenersSafari();
      destroyHls();
      window.removeEventListener("keydown", handleEsc);
      document.removeEventListener("fullscreenchange", handleFullscreen);
      const isiPhone = /iPhone/i.test(navigator.userAgent);
      if (!isiPhone) {
        screenfull.exit();
      }
      // screenfull.exit();
    };
  }, []);

  useEffect(() => {
    if (videoRef.current && hlsInit && autoPlayable !== null) {
      videoRef.current.muted = !!initialMuted;
      setState((prv) => ({ ...prv, muted: !!initialMuted }));

      videoRef.current
        .play()
        .then(() => {
          const newState: any = { playing: true };

          if (hls) {
            setQualityLevels(hls?.levels || []);

            setCaptionsAvailable(hls?.subtitleTracks?.length > 0);

            newState.captions = videoControls?.subtitleToggle;
          }

          setState((prv) => ({ ...prv, ...newState }));
        })
        .catch((e) => {
          handleFailedPause(e);
        });
    }
  }, [autoPlayable, hlsInit]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.disablePictureInPicture = true;
      const isiPhone = /iPhone/i.test(navigator.userAgent);
      if (isiPhone) {
        //(videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        // videoRef.current.muted = true;
        videoRef.current.autoplay = true;
        attachVideoListeners();
        attachVideoListenersSafari();

        // For Safari
        videoRef.current.src = props.videoUrl;
        resumeFromMiniplayer();
        videoRef.current.playsInline = true;
      } else if (Hls.isSupported()) {
        // console.warn("HLS.JS attaching", { castConnected });
        attachHls();

        if (hls) {
          resumeFromMiniplayer();
        }
        // Resume from casting
        if (currentTime > 10) {
          setState((prv) => ({ ...prv, buffer: true }));
          videoRef.current.currentTime = currentTime;
        }
      }
    }
  }, [videoRef.current]);

  // useEffect(() => {
  //   console.log({ castConnected });
  // }, [castConnected]);

  function attachHls() {
    if (!videoRef.current) {
      // console.error("videoref not available");
      return;
    }
    if (hls) {
      // console.error("HLS.JS already attached");
      return;
    }

    attachVideoListeners();

    hls = new Hls({
      enableWorker: true,
      lowLatencyMode: true,
      capLevelToPlayerSize: true,
      maxMaxBufferLength: 15,
      abrEwmaDefaultEstimate: 5000000,
      startLevel: selectedQualityLevel,
    });
    hls.subtitleDisplay = videoControls?.subtitleToggle;
    /* eslint-disable indent */
    hls.on(Hls.Events.ERROR, function (event, data) {
      if (hls) {
        switch (data.type) {
          case Hls.ErrorTypes.MEDIA_ERROR:
            recoverMediaError(
              "HLS.JS: Media error occurred, attempting recovery..."
            );
            break;
          case Hls.ErrorTypes.NETWORK_ERROR:
            // Handle network error as desired
            recoverMediaError("HLS.JS: Network error occurred");
            break;
          default:
            // console.error("HLS.JS: Unhandled error occurred");
            // Handle other errors as desired
            break;
        }
      }
    });

    hls.on(Hls.Events.MANIFEST_PARSED, function () {
      setHlsInit(true);
    });

    hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
      setCurrentQualityLevel(data.level);
    });

    hls.loadSource(props.videoUrl);
    hls.attachMedia(videoRef.current);
  }

  function recoverMediaError(errorMsg: string) {
    // console.error(`🚀 > recoverMediaError:`, errorMsg);

    // prevent pause popup
    hlsError.current = true;
    setTimeout(() => {
      hlsError.current = false;
    }, 2000);

    // handle recovery --- not playing, buffering
    setState((prv) => ({ ...prv, playing: false, buffer: true }));

    // handle recovery -- re-attempt playing
    clearTimeout(hlsErrorRetryTimeout);
    hlsErrorRetryTimeout = setTimeout(() => {
      if (hls) {
        hls.recoverMediaError();
        setState((prv) => ({ ...prv, playing: true, buffer: true }));
      }
    }, 10000);
  }

  function destroyHls() {
    try {
      if (hls) {
        hls.removeAllListeners();
        hls.destroy();
        setHlsInit(false);
        hls = undefined;
      }
    } catch (error) {
      // console.error(`🚀 > destroyHls > error:`, error);
    }
  }

  function attachVideoListeners() {
    if (videoRef.current) {
      videoRef.current.addEventListener("volumechange", handleVideoVolume);
      videoRef.current.addEventListener("pause", handlePause);
      window.addEventListener("offline", handleFailedPause);
      videoRef.current.addEventListener("stalled", handleVideoPlaying);
      // videoRef.current.addEventListener("progress", handleVideoBuffering);
      videoRef.current.addEventListener("playing", handleVideoPlaying);
      videoRef.current.addEventListener("waiting", handleVideoBuffering);
      videoRef.current.addEventListener("timeupdate", handleVideoTime);
      videoRef.current.addEventListener("durationchange", handleVideoDuration);
    }
  }

  function removeVideoListeners() {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = "";
      videoRef.current.removeEventListener("volumechange", handleVideoVolume);
      videoRef.current.removeEventListener("pause", handlePause);
      window.removeEventListener("offline", handleFailedPause);
      videoRef.current.removeEventListener("stalled", handleVideoPlaying);
      // videoRef.current.removeEventListener("progress", handleVideoBuffering);
      videoRef.current.removeEventListener("playing", handleVideoPlaying);
      videoRef.current.removeEventListener("waiting", handleVideoBuffering);
      videoRef.current.removeEventListener("timeupdate", handleVideoTime);
      videoRef.current.removeEventListener("durationchange", handleVideoDuration); // prettier-ignore
    }
  }

  function attachVideoListenersSafari() {
    if (videoRef.current) {
      videoRef.current.addEventListener("error", handleVideoError);
      videoRef.current.addEventListener("abort", handleVideoError);
    }
  }

  function removeVideoListenersSafari() {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = "";
      videoRef.current.removeEventListener("error", handleVideoError);
      videoRef.current.removeEventListener("abort", handleVideoError);
    }
  }

  const resumeFromMiniplayer = () => {
    if (videoRef.current) {
      const resume = searchParams.get("resume");
      const seconds = convertTimeToSeconds(resume);

      if (seconds) {
        if (miniplayerState.playing) {
          setState((pre) => ({
            ...pre,
            frequencyVolume: miniplayerState.frequencyVolume || 20,
          }));
        }
        videoRef.current.currentTime = seconds;
      }
    }
  };

  const handleResume = () => {
    setState((prv) => ({ ...prv, playing: true }));
    setOpenPauseDialog(false);
    setStillMeditating(false);
    handleInitialUnmute();
  };

  const handlePause = () => {
    // console.trace("pause trace");
    handlePlainPause();
    if (
      !completed() &&
      !hlsStarting.current &&
      !castStarting.current &&
      !hlsError.current
    ) {
      setOpenPauseDialog(true);
    }
  };

  const handlePlainPause = () => {
    setState((prv) => ({ ...prv, playing: false }));
  };

  const handleFailedPause = (e: any = null) => {
    // console.error(`🚀 > handleFailedPause > e:`, e);
    setState((prv) => ({ ...prv, playing: false, buffer: false }));
  };

  const handlePauseForStillMeditating = () => {
    setState((prv) => ({ ...prv, playing: false }));
    setStillMeditating(false);
  };

  const handleControlsPause = () => {
    handlePause();
  };

  const handleLoopValue = (bool: boolean) => {
    userInteracted();

    setState((prv) => ({ ...prv, loop: bool }));
  };

  const handleSeekMouseUp = (e: any, value: any) => {
    userInteracted();

    if (videoRef.current) {
      const percentage = value / 100;
      videoRef.current.currentTime = Math.ceil(duration * percentage);

      setState((prv) => ({ ...prv, seeking: false, playing: true }));

      handleInitialUnmute();
    }
  };

  const handleRewind = () => {
    userInteracted();

    if (videoRef.current !== null) {
      videoRef.current.currentTime -= 10;
      handleInitialUnmute();
    }

    if (cjs.connected) {
      cjs.seek(cjs.time - 10);
      setCurrentTimePretty(convertSecondsToTime(cjs.time));
    }
  };

  const handleFastForward = () => {
    userInteracted();

    if (videoRef.current !== null) {
      videoRef.current.currentTime += 10;
      handleInitialUnmute();
    }

    if (cjs.connected) {
      cjs.seek(cjs.time + 10);
      setCurrentTimePretty(convertSecondsToTime(cjs.time));
    }
  };

  const handleInitialUnmute = () => {
    setTimeout(() => {
      setInitialMuted(false);
    });
  };

  const handleVolumeChange = (e: any, value: any) => {
    const vol = parseFloat(value) / 100;
    dispatch(videoControlsActions.playerVolume(vol));
    setState((prv) => ({
      ...prv,
      volume: vol,
      muted: Number(vol) === 0 ? true : false,
    }));
    handleInitialUnmute();
  };

  const handleFrequencyVolumeChange = (e: any, value: any) => {
    const vol = parseFloat(value);
    setState((prv) => ({
      ...prv,
      frequencyVolume: vol,
    }));
    handleInitialUnmute();
  };

  const handleMute = () => {
    setState((prv) => {
      const muted = !prv.muted;
      const volume = !muted && !prv.volume ? 0.2 : prv.volume;

      return { ...prv, muted, volume };
    });
    handleInitialUnmute();
  };

  const handlePictureinPicture = () => {
    setState((prv) => ({ ...prv, pip: !state.pip }));
    setIsFullScreen(false);
  };

  const handleMouseMove = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    showControls();
  };

  const hanldeMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    hideControls();
  };

  const hideControls = (timeout = 500) => {
    if (cjs.connected) {
      return;
    }

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      const activeElement = document.activeElement;
      setShowControl(false);
      const el = document.querySelector(
        ".showControlsViaKeyboard"
      ) as HTMLDivElement;
      const meditationPlayer = document.querySelector(
        ".meditation-player"
      ) as HTMLDivElement;
      if (meditationPlayer && meditationPlayer.contains(activeElement)) {
        el.focus();
      }
    }, timeout);
  };

  const showControls = () => {
    setShowControl(true);

    hideControls(5000);
  };

  const handleDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat === "normal" ? "remaining" : "normal"
    );
  };

  const handleCompleteMeditation = () => {
    userInteracted();

    setOpenPauseDialog(false);
    setStillMeditating(false);
    openCompletionPopup(true);
    setState((prv) => ({ ...prv, playing: false }));
    if (videoRef.current) {
      videoRef.current.currentTime = duration;
    }
  };

  const handleNotMeditating = () => {
    handleCompleteMeditation();
  };

  const handleContinueMeditation = () => {
    userInteracted();

    handleResume();
  };

  const handleCompletionSummary = (message: string) => {
    userInteracted();

    setSuccess(true);
    setToastMessage(message);

    setTimeout(() => {
      setSuccess(false);
      setToastMessage("");
    }, 2000);
  };

  const handleCastEvent = (e?: string) => {
    // if (["volumechange" /* "mute", "unmute" */].includes(e || "")) {
    //   handleCastVolume();
    // } else if ("timeupdate" === e) {
    //   handleCastTime();
    // }
    // console.log(`🚀 > handleCastEvent > e:`, e, cjs.state);
  };

  const checkCastingAvailable = () => {
    if (cjs) {
      const available = cjs.available;

      setCastingAvailable(available);

      return true;
    } else {
      // console.warn("Cjs not available");

      return false;
    }
  };

  function attachCjs() {
    if (checkCastingAvailable()) {
      if (!cjsAttached) {
        // console.warn("ATTACH CJS EVENTS");
        cjs.on("available", handleCastAvailable); // Casting is available
        cjs.on("connect", handleCastConnection); // Connected with device
        cjs.on("disconnect", handleCastDisconnection); // Disconnected with device
        cjs.on("timeupdate", handleCastTime); // Current time changed
        cjs.on("volumechange", handleCastVolume); // Volume changed
        cjs.on("mute", handleCastMute); // Muted state changed
        cjs.on("unmute", handleCastUnmute); // Muted state changed
        cjs.on("playing", handleCastPlaying); // Media is playing
        cjs.on("pause", handleCastPaused); // Media is paused
        cjs.on("end", handleCastCompletion); // Media ended
        cjs.on("buffering", handleCastBuffering); // Media is buffering / seeking
        cjs.on("event", handleCastEvent); // Catch all events except 'error'
        // cjs.on("error", console.error); // Catch any errors

        cjsAttached = true;
      } else {
        // console.error("cjs already attached");
      }
    } else {
      // console.error("no cjs 1");
    }
  }

  function destroyCjs() {
    if (cjsAttached) {
      setCastConnected(false);
      // console.warn("DETTACH CJS EVENTS");

      cjs.off("available", handleCastAvailable);
      cjs.off("connect", handleCastConnection);
      cjs.off("disconnect", handleCastDisconnection);
      cjs.off("timeupdate", handleCastTime);
      cjs.off("volumechange", handleCastVolume);
      cjs.off("mute", handleCastMute);
      cjs.off("unmute", handleCastUnmute);
      cjs.off("playing", handleCastPlaying);
      cjs.off("pause", handleCastPaused);
      cjs.off("end", handleCastCompletion);
      cjs.off("buffering", handleCastBuffering);
      cjs.off("event", handleCastEvent);
      // cjs.off("error", console.error);
      cjs.disconnect();

      cjsAttached = false;
    } else {
      // console.error("no cjs 2");
    }
  }

  const handleCastConnect = () => {
    if (checkCastingAvailable()) {
      if (cjs.connected) {
        setStopCastingDialog(true);
        return;
      } else {
        castingTime.current = currentTime;
        castStarting.current = true;
        hlsStarting.current = false;
        attachCjs();

        cjs.cast(props.videoUrl, {
          title: props.mediaData.title,
          description: props.mediaData.description,
          poster: props.videoThumbnail,
        });
      }
    }
  };

  const handleCastConnection = () => {
    setCastConnected(true);

    destroyHls();
    setCasting((prv) => ({ ...prv, device: cjs.device }));
    showControls();

    setTimeout(() => {
      cjs.volume(0.1);
      cjs.unmute();
      handleCastVolume();
    });
  };

  const handleCastDisconnection = () => {
    castingTime.current = cjs.time;
    hlsStarting.current = true;
    castStarting.current = false;
    setCasting((prv) => ({ ...prv, device: "" }));
    destroyCjs();
    // console.warn(`🚀 > handleCastDisconnection`);
    // handlePause();
  };

  const handleCastAvailable = () => {
    setCastingAvailable(cjs.available);
  };

  const handleCastCompletion = () => {
    if (cjs.connected) {
      // handleCompleteMeditation();
    }
  };

  const handleCastPaused = () => {
    if (cjs.connected) {
      handlePause();
    }
  };

  const handleCastBuffering = () => {
    if (cjs.connected) {
      setState((prv) => ({
        ...prv,
        buffer: true,
      }));
    }
  };

  const handleCastPlaying = () => {
    if (cjs.connected) {
      if (castingTime.current) {
        cjs.seek(castingTime.current);
        castingTime.current = 0;
      }
      hlsStarting.current = false;
      castStarting.current = false;

      handleResume();
      setState((prv) => ({ ...prv, buffer: false }));
      handleCastMute();
    }
  };

  const handleCastMute = () => {
    if (cjs.connected) {
      const muted = cjs.muted;

      setState((prv) => ({ ...prv, muted }));
    }
  };

  const handleCastUnmute = () => {
    if (cjs.connected) {
      const muted = cjs.muted;
      const volume = !cjs.muted && !cjs.volumeLevel ? 10 : cjs.volumeLevel;

      setState((prv) => ({ ...prv, muted, volume }));
    }
  };

  const handleCastVolume = () => {
    if (cjs.connected) {
      const volume = cjs.volumeLevel;
      const muted = cjs.muted;

      setState((prv) => ({ ...prv, volume, muted }));
    }
  };

  const handleStopCasting = () => {
    handleCastDisconnection();
    setStopCastingDialog(false);
  };

  const handleKeepOnCasting = () => {
    setStopCastingDialog(false);
  };

  const handleCastTime = () => {
    if (cjs.connected) {
      setCurrentTime(cjs.time);
      setCurrentTimePretty(convertSecondsToTime(cjs.time));
      setDuration(cjs.duration);
      setDurationPretty(convertSecondsToTime(cjs.duration));

      let c = cjs.time / cjs.duration;
      c = Math.floor(isNaN(c) ? 0 : c * 100) / 100;

      setCompletion(c);
    }
  };

  const volumeFadeSetter = (volume: number) => {
    if (hls && videoRef.current) {
      videoRef.current.volume = volume / 100;
    }
  };

  // fadings
  useEffect(() => {
    if (volumeFadeState) {
      if (volumeFadeState.fading) {
        setShowControl(true);
        clearInterval(unfadeVolumeInterval);
        const currentVolume = state.volume * 100;

        const fv = fadeVolume(currentVolume, 0, volumeFadeSetter, "volumedown");
        fadeVolumeInterval = fv.interval;
      } else if (volumeFadeState.unfading) {
        showControls();
        const prevVolume = volumeFadeState.prevVolume || 50;

        const fv = fadeVolume(0, prevVolume, volumeFadeSetter, "volumeup");
        unfadeVolumeInterval = fv.interval;
      }
    }
  }, [volumeFadeState]);

  return (
    <div
      onMouseMove={handleMouseMove}
      onClick={handleMouseMove}
      onMouseLeave={hanldeMouseLeave}
      ref={playerContainerRef}
      className={classes.fullScreenWrapper}
    >
      <div
        className="showControlsViaKeyboard"
        tabIndex={0}
        onKeyDown={() => showControls()}
      />
      <div
        className={classes.playerWrapper}
        style={{
          backgroundImage: `url(${props?.videoThumbnail})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {castConnected ? (
          <Box
            sx={{
              width: "100%",
              aspectRatio: "16/9",
              backgroundColor: "#0009",
              fontFamily: "'Figtree'",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "large",
              zIndex: 2,
            }}
          >
            <Box paddingTop="4rem">Casting to {casting.device}</Box>
          </Box>
        ) : (
          <Box sx={{ zIndex: 2, position: "relative" }}>
            <video
              style={{
                width: "100%",
                aspectRatio: "16/9",
                opacity: currentTime < 1 || currentTime >= duration ? 0 : 1,
              }}
              crossOrigin="anonymous"
              ref={videoRef}
              controls={false}
              autoPlay={false}
              muted={true}
              playsInline={true}
              disablePictureInPicture={true}
            />
          </Box>
        )}

        <CircularProgress
          size={50}
          sx={{
            display: state.buffer ? "initial" : "none",
            position: "absolute",
            zIndex: 2,
            color: "#0008",
            left: "calc(50% - 25px)",
            top: "calc(50% - 27px)",
          }}
        />
        <Controls
          controlsShowing={controlsShowing}
          showControls={showControls}
          castingAvailable={castingAvailable}
          castingConnected={cjs.connected}
          onCastConnect={handleCastConnect}
          captionsAvailable={captionsAvailable}
          captionsShowing={state.captions}
          onClosedCaptions={hanldeClosedCaptions}
          onSeek={handleSeekMouseUp}
          onSeekMouseUp={handleSeekMouseUp}
          onPip={handlePictureinPicture}
          onRewind={handleRewind}
          onFastForward={handleFastForward}
          onPlay={handleResume}
          onPause={handleControlsPause}
          playing={state.playing}
          elapsedTime={currentTimePretty}
          totalDuration={durationPretty}
          onMute={handleMute}
          muted={initialMuted || state.muted}
          initialMuted={initialMuted}
          buffer={state.buffer}
          onVolumeChange={handleVolumeChange}
          onFrequencyVolumeChange={handleFrequencyVolumeChange}
          onChangeDispayFormat={handleDisplayFormat}
          volume={state.volume}
          frequencyVolume={state.frequencyVolume}
          onToggleFullScreen={toggleFullScreen}
          isFullScreen={isFullScreen}
          loop={state.loop}
          setLoopValue={handleLoopValue}
          openCompletionPopup={openCompletionPopup}
          setStillMeditating={setStillMeditating}
          mediaData={props.mediaData}
          completion={completion}
          qualityLevels={qualityLevels}
          selectedQualityLevel={selectedQualityLevel}
          setSelectedQualityLevel={setSelectedQualityLevel}
          currentQualityLevel={currentQualityLevel}
          interactionTime={interactionTime}
          userInteracted={userInteracted}
        />
      </div>

      <Dialog
        open={videoCompletion}
        className="meditation-summary-popup"
        fullWidth={true}
        maxWidth={"sm"}
      >
        <MeditaionSummaryPopUp
          isLiked={props.isLiked}
          mediaData={props.mediaData}
          openCompletionPopup={openCompletionPopup}
          handleCompletion={handleCompletionSummary}
          setIsLiked={props.setIsLiked}
          dynamicLinkUrlResponse={props.dynamicLinkUrlResponse}
        />
      </Dialog>

      {/* <MeditationPauseDialog
        open={!videoCompletion && openPauseDialog}
        handleCompleteMeditation={handleCompleteMeditation}
        handleContinueMeditation={handleContinueMeditation}
      /> */}
      <StopCastingDialog
        open={stopCastingDialog}
        deviceName={casting.device}
        handleStopCasting={handleStopCasting}
        handleKeepOnCasting={handleKeepOnCasting}
      />
      <MeditationLoopDialog
        open={showStillMeditating}
        yesButtonClicked={handleContinueMeditation}
        noButtonClicked={handleNotMeditating}
      />
      {toastMessage !== "" && (
        <SLVerificationMessage open={success} message={toastMessage} />
      )}
    </div>
  );
});

export default VideoPlayer;
