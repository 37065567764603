// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-nocheck
import { Box, Paper, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import {
  Bar,
  BarChart,
  Cell,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
} from "recharts";
import "./index.css";

const WeeklyMeditaionGoal = (props: any) => {
  const { goalData } = props;
  const currentDay = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
    new Date().getDay()
  ];
  const [weeklyDataUpdated, setWeeklyDataUpdated] = useState<any>();
  const [ariaLabel, setAriaLabel] = useState<string>("");

  useEffect(() => {
    if (Array.isArray(goalData?.data)) {
      setWeeklyDataUpdated({
        data: goalData?.data.map((day: any) => {
          const mediTime =
            day.meditationTime > day.goal ? day.goal : day.meditationTime;

          return {
            name: day.day.substring(0, 3),
            x: ((mediTime / day.goal) * 100).toFixed(0),
            y: (100 - (mediTime / day.goal) * 100).toFixed(0),
          };
        }),
      });

      setAriaLabel(
        goalData?.data
          .reduce((carry: string, day: any) => {
            const mins = Math.round(day.meditationTime || 0);

            return carry.concat(
              `${day.day}: ${mins} minute${mins === 1 ? "" : "s"}. `
            );
          }, "")
          .trim()
      );
    }
  }, [goalData]);

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          background: "white",
          boxShadow: "none",
          borderRadius: "10px",
        }}
        className="weeklyMeditationStats"
      >
        <Box sx={{ padding: "25px" }}>
          {props.loading ? (
            <Skeleton
              sx={{
                transform: "unset",
                display: "block",
                width: "100%",
                margin: "25px 0",
              }}
            />
          ) : (
            <h1 className="goalHeading" tabIndex={0}>
              <span style={{ color: "var(--sl-aqua)" }}>
                {goalData?.average.toFixed(0)} minute average
              </span>{" "}
              over last 7 days
            </h1>
          )}

          {props.loading ? (
            <Skeleton
              sx={{
                transform: "unset",
                display: "block",
                width: 415,
                height: 240,
              }}
            />
          ) : weeklyDataUpdated?.data.length > 0 ? (
            <span tabIndex={0} aria-label={ariaLabel}>
              <ResponsiveContainer width="100%" height={240}>
                <BarChart
                  aria-hidden={true}
                  tabIndex={-1}
                  className="barChart"
                  data={weeklyDataUpdated?.data}
                  margin={{ top: 0, left: 0, right: 0, bottom: 0 }}
                  barCategoryGap="22%"
                >
                  <XAxis dataKey="name" />

                  <Bar
                    dataKey="x"
                    stackId="a"
                    fill="var(--sl-deep-purple)"
                    radius={[0, 0, 3, 3]}
                  >
                    {weeklyDataUpdated?.data.map((entry: any, index: number) =>
                      entry.name === currentDay ? (
                        <Cell
                          key={`cell-${entry.name}`}
                          radius={
                            entry.x === "100" ? [3, 3, 3, 3] : [0, 0, 3, 3]
                          }
                          fill="var(--sl-light-aqua)"
                        />
                      ) : (
                        <Cell
                          key={`cell-${entry.name}`}
                          radius={
                            entry.x === "100" ? [3, 3, 3, 3] : [0, 0, 3, 3]
                          }
                          fill="var(--sl-deep-purple)"
                        />
                      )
                    )}
                  </Bar>

                  <Bar
                    dataKey="y"
                    stackId="a"
                    fill="var(--colorDarkGrayContrast)"
                    radius={[3, 3, 3, 3]}
                  >
                    {weeklyDataUpdated?.data.map(
                      (entry: any, index: number) => (
                        <Cell
                          key={`cell-${index}`}
                          radius={
                            entry.y === "100" ? [3, 3, 3, 3] : [3, 3, 0, 0]
                          }
                          fill="var(--colorDarkGrayContrast)"
                        />
                      )
                    )}
                  </Bar>
                  {/* // )} */}
                  {goalData?.average > 0 ? (
                    <ReferenceLine
                      strokeWidth={2}
                      fill="#CBC8CD"
                      y={goalData?.average}
                    />
                  ) : (
                    ""
                  )}
                </BarChart>
              </ResponsiveContainer>
            </span>
          ) : null}
        </Box>
      </Paper>
    </>
  );
};

export default WeeklyMeditaionGoal;
