import { Dialog, Divider, Grid, Paper, Stack, TextField } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { createCustomAchievement } from "utils/slRTKHelper/slAPI.models";
import { DialogActionButtons } from "../../dialogBoxes/DialogActionButtons";
import { ErrorMsg } from "../../errorMsg/ErrorMsg";
import AchievementMeditationColumn from "./achievementMotivationColumns";
import "./index.css";

let formSubmitting = false;

const AchievementTypes = [
  {
    slug: "quantity",
    title: "Quantity of Meditations",
    label: "Number of Meditations",
  },

  {
    slug: "averageMinute",
    title: "Average Minutes Meditating",
    label: "Minutes Per Day",
  },

  {
    slug: "consistency",
    title: "Consistency of Meditations",
    label: "Meditations Per Week",
  },
];

interface ICreateAchievementPopUp {
  open: boolean;
  setOpen: (open: boolean) => any;
  reload: () => void;
}

const CreateAchievementPopUp = (props: ICreateAchievementPopUp) => {
  const dispatch = useDispatch();

  const [selectedAchievement, setSelectedAchievement] = useState("");

  const [errors, setErrors] = useState<Record<string, boolean>>({
    title: false,
    description: false,
    number: false,
    durationType: false,
    duration: false,
  });
  const initialFormState: createCustomAchievement = {
    title: "",
    description: "",
    number: 0,
    durationType: "",
    duration: 0,
    type: "",
  };

  const [form, setForm] = useState<createCustomAchievement>(initialFormState);

  const { data, loading, error } = useSelector(
    SLSelector.postCustomAchievement
  );

  const saveAchievements = () => {
    const errors: Record<string, boolean> = {
      title: !form.title,
      description: !form.description,
      number: !form.number,
      durationType: !form.durationType,
      duration: !!form.durationType && !form.duration,
    };
    setErrors(errors);

    if (Object.keys(errors).some((key) => errors[key])) {
      // has errors
      console.error("has errors", errors);
      return;
    }

    if (!formSubmitting) {
      dispatch(SLThunk.postCustomAchievement.call(form));
      formSubmitting = true;
    }
  };

  useEffect(() => {
    if (form.type != selectedAchievement) {
      setSelectedAchievement(form.type || "");
      setErrors({
        ...errors,
        number: false,
        durationType: false,
        duration: false,
      });
    }
  }, [form]);

  useEffect(() => {
    if (data && !error) {
      props.setOpen(false);
      props.reload();
      formSubmitting = false;
      setForm(initialFormState);
    } else if (error) {
      props.setOpen(true);
      formSubmitting = false;
    }
  }, [data, error]);

  return (
    <Dialog
      open={props.open}
      className="create-Achievement-dailogbox"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <Paper
        className="create-Achievement-popup-parent"
        sx={{
          background: "var(--bg-gradient)",
          color: "var(--sl-deep-purple)",
          boxShadow: "none",
          borderRadius: "10px",
          padding: "30px",
          paddingBottom: "1rem",
        }}
      >
        <SLTypography
          role="heading"
          tabIndex={0}
          variant="heading"
          messageId="achievement.createAnAchievement"
          sx={{ letterSpacing: "-0.3px" }}
        />

        <Divider sx={{ width: "100%", margin: "30px" }} />

        <SLTypography role="heading" tabIndex={0} variant="subhead">
          Name and Description
        </SLTypography>

        <Stack
          className="Achievement-name"
          direction={{ xs: "column", sm: "row" }}
          sx={{ gap: "14px", paddingTop: "5px" }}
        >
          <Grid item lg={6} xs={12}>
            <TextField
              autoFocus
              className="sl-input"
              label="Achievement Name"
              inputProps={{ maxLength: 20 }}
              onChange={(e: any) => {
                setErrors({ ...errors, title: false });
                setForm({ ...form, title: e.target.value });
              }}
            />
            <ErrorMsg
              show={errors.title}
              messageId="general.error.EmptyFields"
            />
          </Grid>

          <Grid item lg={6} xs={12}>
            <TextField
              label="Short Description"
              name="shortDescription"
              className="sl-input"
              inputProps={{ maxLength: 30 }}
              onChange={(e: any) => {
                setErrors({ ...errors, description: false });
                setForm({ ...form, description: e.target.value });
              }}
            />
            <ErrorMsg
              show={errors.description}
              messageId="general.error.EmptyFields"
            />
          </Grid>
        </Stack>

        <Divider sx={{ width: "100%", margin: "30px 0" }} />

        <SLTypography role="heading" tabIndex={0} variant="subhead">
          Select Type of Achievement
        </SLTypography>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          sx={{
            gap: "10px",
            paddingTop: "22px",
            minHeight: selectedAchievement ? "220px" : null,
          }}
        >
          {AchievementTypes.map((item, index) => (
            <AchievementMeditationColumn
              key={index}
              slug={item.slug}
              title={item.title}
              label={item.label}
              errors={form.type === item.slug ? errors : {}}
              selectedAchievement={selectedAchievement}
              handleForm={(e: any) => {
                const newForm = { ...form, ...e };
                setForm(newForm);
              }}
            />
          ))}
        </Stack>

        <Divider sx={{ width: "100%", margin: "30px 0" }} />

        <DialogActionButtons
          yesMessageId="achievement.saveAchievement"
          onYesButtonClicked={() => saveAchievements()}
          yesButtonSx={{ width: { xs: "250px", lg: "340px" } }}
          noMessageId="general.cancel"
          onNoButtonClicked={() => {
            setSelectedAchievement("");

            setErrors({
              title: false,
              description: false,
              number: false,
              durationType: false,
              duration: false,
            });
            props.setOpen(false);
          }}
        />
      </Paper>
    </Dialog>
  );
};

export default CreateAchievementPopUp;
