import { Box, Button, Container, Divider, Link, Paper } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { MediaCardType, MediaType } from "helpers/enum";
import {
  AudioMusicIcon,
  CrossIconTutorial,
  FrequencyMusicIcon,
  HeartIconPurple,
  MyListAltHeader,
  RecentMeditationIcon,
  VideoPlayMusicIcon,
} from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useState } from "react";
import { FormattedMessage } from "react-intl";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSetState } from "react-use";
import { SLSelector } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import ExploreHeader from "../../explore/heroHeader";
import Media from "../../explore/media";
import CardInformation from "../../mediationCard/cardInformation";
import { SLVerificationMessage } from "../../verificationMessage";
import "./index.css";

interface State {
  run: boolean;
  steps: Step[];
}
const ListScreen = (data: any) => {
  const appUrls = useAppUrls();
  const { data: config } = useSelector(SLSelector.getConfig);
  const [addedToFavouriteList, setAddedToFavouriteList] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [exploreAllButton, setExploreAllButton] = useState({});
  const [myFavouriteExploreAllButton, setMyFavouriteExploreAllButton] =
    useState({});
  const navigate = useNavigate();
  const guestLogin = SLStorage.getItem("guestLogin");
  const listMeditationTutorial = SLStorage.getItem("listMeditationTutorial");
  const [{ run, steps }, setState] = useSetState<State>({
    run: true,
    steps: [
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Once you've started to favorite or download meditations, you can
              access them here."
            >
              <p className="bodyTextTutorial">
                Once you've started to favorite or download meditations, you can
                access them here.
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 1 of 2"
              >
                Walkthrough – Step 1 of 2
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "right",
        target: ".myListHeading p",
        title: (
          <object tabIndex={0} aria-label="My List">
            <p className="tutorialHeading">My List</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Your meditations are sorted into two easy categories"
            >
              <p className="bodyTextTutorial">
                Your meditations are sorted into two easy categories:
                <br />
                <br />
                <object
                  tabIndex={0}
                  aria-label="1. My Recent Meditations. The meditations youve recently created, completed or started."
                >
                  <span className="tutorialBold">1. My Recent Meditations</span>{" "}
                  - The meditations youve recently created, completed or
                  started.
                </object>
                <br />
                <br />
                <object
                  tabIndex={0}
                  aria-label="2. My Favorite Meditations. The meditations you've used the heart icon to save as a
                favorite."
                >
                  <span className="tutorialBold">
                    2. My Favorite Meditations
                  </span>{" "}
                  - - The meditations youve used the heart icon to save as a
                  favorite.
                </object>
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 2 of 2"
              >
                Walkthrough – Step 2 of 2
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} />, last: "Close" },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".meditationContainer",
        title: (
          <object tabIndex={0} aria-label="My List Categories">
            <p className="tutorialHeading">My List Categories</p>
          </object>
        ),
        disableBeacon: true,
      },
    ],
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const elementOverlay = document.querySelector<HTMLElement>(
      ".react-joyride__spotlight"
    );
    const button = document.querySelector('button[data-action="skip"]');
    if (button) {
      button.setAttribute("title", "Skip tutorial");
      button.setAttribute("aria-label", "Skip tutorial");
    }
    if (elementOverlay) {
      elementOverlay.style.backgroundColor = "transparent";
    }
    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      if (!listMeditationTutorial) {
        //  alert(true);
        SLStorage.setItem("listMeditationTutorial", true);
      }
    }
  };
  return (
    <>
      {!guestLogin && !listMeditationTutorial ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton={true}
          run={run}
          scrollToFirstStep={false}
          showProgress={false}
          showSkipButton={true}
          disableOverlayClose={true}
          spotlightPadding={0}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      ) : (
        ""
      )}
      <Box>
        <ExploreHeader
          staticImage={
            appUrls.mediaImage(config?.myListPageScene?.thumbnail) ||
            MyListAltHeader
          }
          videoSource={appUrls.mediaScene(config?.myListPageScene?.url)}
        />
        <Box
          sx={{
            background: "var(--bg-gradient)",
            borderRadius: "20px 20px 0px 0px",
            position: "relative",
            marginTop: "-15px",
          }}
        >
          <Container
            className="main-container"
            sx={{
              width: { xl: "93.2%", lg: "95%" },
              gap: "10px",
            }}
            maxWidth={false}
          >
            <div className="myListHeading">
              <SLTypography
                role="heading"
                sx={{
                  color: "var(--sl-deep-purple)",
                  fontSize: { xs: "28px", sm: "30px", md: "37px" },
                  fontWeight: "600",
                  lineHeight: "43px",
                  paddingBottom: { xs: "6px", sm: "10px", md: "20px" },
                  paddingTop: { xs: "0px", sm: "20px" },
                  marginTop: { xs: "4px", sm: "15px", md: "35x", lg: "40px" },
                  display: "inline-block",
                }}
                tabIndex={0}
              >
                My List
              </SLTypography>
            </div>

            {!guestLogin ? (
              <>
                <Box>
                  <Box sx={{ paddingBottom: "0px" }}>
                    <CardInformation
                      suggestedMeditation={RecentMeditationIcon}
                      title="My Recent Meditations"
                      anchorText="Show All"
                      urlGenerated="meditation/recent-meditation"
                      // mediaType={"frequency"}
                      // mediaType={MediaCardType.MeditationCard}
                      mediaType={"meditation"}
                      exploreAllButton={exploreAllButton}
                      type={undefined}
                      cardDiscription=""
                      popularText=""
                    />
                  </Box>
                  <Media
                    addedToFavourites={setAddedToFavouriteList}
                    successMessageText={setSuccessMessage}
                    mediaType={MediaCardType.MeditationCard}
                    mediaUrl={"/meditation/my-meditations"}
                    setExploreAllButton={setExploreAllButton}
                    isFavourite={false}
                    myMeditation={true}
                    notFoundMessage="No recent meditations played. Please start a meditation session to see it in the list."
                    lastPlayed={true}
                    plusIcon={false}
                  />
                </Box>

                <Divider
                  sx={{
                    background: "#CBC8CD",
                    marginTop: {
                      xs: "25px",
                      sm: "20px",
                      md: "35px",
                      lg: "42px",
                    },
                    marginBottom: {
                      xs: "25px",
                      sm: "20px",
                      md: "35px",
                      lg: "40px",
                    },
                  }}
                />

                <Box sx={{ paddingBottom: "50px" }}>
                  <Box sx={{ paddingBottom: "0px" }}>
                    <CardInformation
                      suggestedMeditation={HeartIconPurple}
                      title="My Favorite Meditations"
                      anchorText="Show All"
                      urlGenerated="meditation/favorite-meditation"
                      // mediaType={"frequency"}
                      // mediaType={MediaCardType.MeditationCard}
                      mediaType={"meditation"}
                      exploreAllButton={myFavouriteExploreAllButton}
                      type={undefined}
                      cardDiscription=""
                      popularText=""
                    />
                  </Box>
                  <Media
                    addedToFavourites={setAddedToFavouriteList}
                    successMessageText={setSuccessMessage}
                    mediaType={MediaCardType.MeditationCard}
                    mediaUrl={"/meditation/my-favourites"}
                    setExploreAllButton={setMyFavouriteExploreAllButton}
                    isFavourite={true}
                    myMeditation={true}
                    notFoundMessage="No Meditations found. Please add some to your favorite."
                    plusIcon={false}
                  />
                </Box>
              </>
            ) : (
              <Box sx={{ textAlign: "center" }}>
                <SLTypography
                  sx={{
                    color: "var(--sl-deep-purple)",
                    fontSize: {
                      lg: "24px",
                      md: "22px",
                      sm: "20px",
                      xs: "18px",
                    },
                    fontWeight: "600",
                    lineHeight: "24px",
                    paddingBottom: "20px",
                    paddingTop: {
                      lg: "50px",
                      md: "50px",
                      sm: "35px",
                      xs: "25px",
                    },
                  }}
                >
                  Oops! You Need to Log In
                </SLTypography>
                <SLTypography
                  sx={{
                    color: "var(--sl-deep-purple)",
                    fontSize: {
                      lg: "16px",
                      md: "16px",
                      sm: "14px",
                      xs: "14px",
                    },
                    fontWeight: "500",
                    paddingBottom: "20px",
                    paddingTop: "0px",
                  }}
                >
                  It looks like you're trying to access a section that requires
                  you to be logged in. Don't worry, we've got you covered!
                  Simply login or create an account to proceed.
                </SLTypography>
                <SLTypography
                  sx={{
                    color: "var(--sl-deep-purple)",
                    fontSize: {
                      lg: "17px",
                      xs: "15px",
                    },
                    fontWeight: "500",
                    paddingBottom: "0px",
                    paddingTop: "0px",
                  }}
                >
                  Login to your account
                </SLTypography>
                <Button
                  className="buildMeditationBtn"
                  sx={{ margin: 0 }}
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <SLTypography
                    variant="title"
                    sx={{ fontWeight: "500", letterSpacing: "0.5px" }}
                  >
                    Log in
                  </SLTypography>
                </Button>
                <SLTypography
                  variant="title"
                  sx={{
                    fontWeight: "500",
                    letterSpacing: "0px",
                    paddingTop: "5px",
                    fontSize: {
                      lg: "14px",
                      md: "14px",
                      sm: "12px",
                      xs: "12px",
                    },
                    color: "var(--sl-deep-purple)",
                    display: "block",
                  }}
                >
                  New to Soul Link?{" "}
                  <Link
                    href="/signup"
                    role="link"
                    aria-label=" New to Soul Link sign up"
                    sx={{
                      color: "var(--sl-purple)",
                      textDecoration: "none",
                      borderBottom: "1px solid currentColor",
                    }}
                  >
                    <FormattedMessage id="onboarding.signup" />
                  </Link>{" "}
                </SLTypography>
              </Box>
            )}
          </Container>
        </Box>
      </Box>
      {!guestLogin ? (
        <Paper
          sx={{
            background: "var(--bg-gradient)",
            borderRadius: "20px 20px 0px 0px",
            position: "relative",
            marginTop: "-15px",
            boxShadow: "none",
          }}
        >
          <Container
            className="main-container"
            sx={{
              width: { xl: "93.2%", lg: "95%" },
            }}
            maxWidth={false}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: { xs: "5px", sm: "7px", md: "8px" },
                paddingTop: "50px",
              }}
            >
              <Box>
                <img
                  src={HeartIconPurple}
                  alt="audio-icon"
                  style={{ width: "85%", margin: "0px", padding: "0px" }}
                />
              </Box>
              <SLTypography
                role="heading"
                variant="mediaSubHeading"
                tabIndex={0}
              >
                My Favorite Files
              </SLTypography>
            </Box>
            <Divider
              sx={{
                background: "#CBC8CD",
                marginTop: { xs: "25px", sm: "20px", md: "35px", lg: "42px" },
              }}
            />
            <Box sx={{ paddingTop: "50px" }}>
              <Box sx={{ paddingBottom: "0px" }}>
                <CardInformation
                  suggestedMeditation={AudioMusicIcon}
                  title="Audio Files"
                  mediaType={MediaType.Audio}
                  urlGenerated="media/audio"
                  exploreAllButton={exploreAllButton}
                  anchorText="Show All"
                  // mediaType={"audio"}
                  type={undefined}
                  cardDiscription=""
                  popularText=""
                />
              </Box>

              <Media
                addedToFavourites={setAddedToFavouriteList}
                successMessageText={setSuccessMessage}
                mediaType={"audio"}
                mediaUrl={"/media/by-type"}
                setExploreAllButton={setExploreAllButton}
                isFavourite={true}
                notFoundMessage="No Audio Found"
                plusIcon={false}
              />
            </Box>
            <Divider
              sx={{
                background: "#CBC8CD",
                marginTop: { xs: "25px", sm: "20px", md: "35px", lg: "42px" },
              }}
            />
            <Box sx={{ paddingTop: "50px" }}>
              <Box sx={{ paddingBottom: "0px" }}>
                <CardInformation
                  suggestedMeditation={VideoPlayMusicIcon}
                  title="Video Files"
                  anchorText="Show All"
                  urlGenerated="media/video"
                  // mediaType={"video"}
                  // mediaType={MediaCardType?.MeditationCard}
                  mediaType={MediaType.Video}
                  exploreAllButton={exploreAllButton}
                  type={undefined}
                  cardDiscription=""
                  popularText=""
                />
              </Box>
              <Media
                addedToFavourites={setAddedToFavouriteList}
                successMessageText={setSuccessMessage}
                mediaType={"video"}
                mediaUrl={"/media/by-type"}
                setExploreAllButton={setExploreAllButton}
                isFavourite={true}
                notFoundMessage="No Video Found"
                plusIcon={false}
              />
            </Box>

            <Divider
              sx={{
                background: "#CBC8CD",
                marginTop: { xs: "25px", sm: "20px", md: "35px", lg: "42px" },
              }}
            />

            <Box sx={{ paddingTop: "50px", paddingBottom: "50px" }}>
              <Box sx={{ paddingBottom: "0px" }}>
                <CardInformation
                  suggestedMeditation={FrequencyMusicIcon}
                  title="Frequency Wave Files"
                  anchorText="Show All"
                  urlGenerated="media/frequencyWave"
                  // mediaType={"frequency"}
                  // mediaType={MediaCardType.MeditationCard}
                  mediaType={MediaType.FrequencyWave}
                  exploreAllButton={exploreAllButton}
                  type={undefined}
                  cardDiscription=""
                  popularText=""
                />
              </Box>
              <Media
                addedToFavourites={setAddedToFavouriteList}
                successMessageText={setSuccessMessage}
                mediaType={"frequencyWave"}
                mediaUrl={"/media/by-type"}
                setExploreAllButton={setExploreAllButton}
                isFavourite={true}
                notFoundMessage="No Frequency Wave Found"
                plusIcon={false}
              />
            </Box>
          </Container>
          <SLVerificationMessage
            open={addedToFavouriteList}
            sx={{ zIndex: 9999 }}
            message={successMessage}
          />
        </Paper>
      ) : null}
    </>
  );
};

export default ListScreen;
