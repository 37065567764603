import { DynamicAboutPageScreen } from "components/aboutpages/dynamicAboutPage";
import AboutPages from "../../../layouts/aboutPages";

const DynamicAboutPage = () => {
  return (
    <AboutPages title="">
      <DynamicAboutPageScreen />
    </AboutPages>
  );
};

export default DynamicAboutPage;
