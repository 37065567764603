import { Container } from "@mui/material";
import ViewAuthorDetail from "components/aboutpages/view-author";

const ViewArtistDetail = () => {
  return (
    <>
      <Container
        className="page-container"
        sx={{
          width: { xl: "93.2%", lg: "95%" },
        }}
        maxWidth={false}
      >
        <ViewAuthorDetail />
      </Container>
    </>
  );
};
export default ViewArtistDetail;
