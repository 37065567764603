import { Grid, Skeleton } from "@mui/material";
import DailyInspirationCard from "components/dailyInspiration";
import Loadmore from "components/loadmore";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLPaginationResponse } from "utils/slRTKHelper/slAPI.models";
import "./index.css";

interface IDailyInspiration {
  id: number;
  text: string;
  viewCount: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

const DailyInspirationScreen = () => {
  //Manage States
  const [dailyInspirationQuotes, setDailyInspirationQuotes] =
    useState<SLPaginationResponse<IDailyInspiration>>();
  const [currentPage, setCurrentPage] = useState<number>(1);

  //dispatch calls
  const dispatch = useDispatch();

  //Selector Calls
  const { data, loading, error } = useSelector(SLSelector.getDailyInspiration);

  const loadMoreQuotes = () => {
    getQuotes({ page: currentPage + 1 });
  };

  const getQuotes = (props?: {
    search?: string | null;
    page?: number;
    category?: string | null;
  }) => {
    const data: any = {
      page: currentPage,
      limit: 20,
      sort: "DESC",
      sortBy: "createdAt",
      status: "active",
    };

    Object.assign(data, props);

    setCurrentPage(data.page);

    dispatch(SLThunk.getDailyInspiration.call(data));
  };

  //Manage UseEffects
  useEffect(() => {
    getQuotes();
  }, []);

  useEffect(() => {
    if (data?.data) {
      if (currentPage === 1) {
        setDailyInspirationQuotes(data);
      } else {
        const appendedData = {
          ...data,
          data: [...dailyInspirationQuotes!.data, ...data.data],
        };
        setDailyInspirationQuotes(appendedData);
      }
    }
  }, [data]);

  return (
    <>
      <Grid
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "14px",
          marginTop: { xs: "16px", sm: "40px" },
        }}
        tabIndex={-1}
      >
        {dailyInspirationQuotes?.data?.map((quote: IDailyInspiration) => (
          <DailyInspirationCard quotes={quote.text} />
        ))}

        {loading &&
          Array.from({ length: 3 }, (_i) => (
            <Skeleton
              sx={{
                boxSizing: "border-box",
                height: "70px",
                transform: "unset",
              }}
            />
          ))}
      </Grid>

      {typeof dailyInspirationQuotes?.data !== "undefined" &&
        dailyInspirationQuotes?.data?.length > 0 && (
          <Loadmore
            data={dailyInspirationQuotes}
            handleLoadmore={loadMoreQuotes}
            focusElementId="page-heading"
          />
        )}
      {/* <SLLoader open={loading}></SLLoader> */}
    </>
  );
};

export default DailyInspirationScreen;
