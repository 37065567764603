/* eslint-disable indent */
import { Box } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { Link } from "react-router-dom";

interface ICardInformation {
  title: string;
  anchorText: string;

  mediaType: string;
  type?: any;
  urlGenerated?: string;
  cardDiscription: string;
  popularText: string;
  suggestedMeditation?: any;
  exploreAllButton?: any;
  showAnchorText?: boolean;
}

const CardInformation = ({
  title,
  anchorText,
  mediaType,
  cardDiscription,
  urlGenerated,
  type: any,
  popularText,
  suggestedMeditation,
  showAnchorText,
  exploreAllButton,
}: ICardInformation) => {
  return (
    <>
      <Box
        className="card-information-container"
        sx={{ fontFamily: "Figtree", color: "var(--sl-deep-purple)" }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: {
              xl: "20px",
              lg: "20px",
              md: "17px",
              sm: "15px",
              xs: "15px",
            },
            paddingTop: {
              xl: "0px",
              lg: "0px",
              md: "0px",
              sm: "14px",
              xs: "10px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              gap: { md: "8px", sm: "7px", xs: "5px" },
            }}
            role="heading"
            tabIndex={0}
          >
            <Box>
              <Box
                aria-hidden="true"
                component="img"
                src={suggestedMeditation}
                sx={{
                  width:
                    location.pathname === "/app/my-list"
                      ? "22px"
                      : { md: "30px", xs: "24px" },
                  margin: "0px",
                  padding: "0px",
                  marginTop:
                    location.pathname === "/app/my-list" ? "5px" : "0px",
                }}
              />
            </Box>
            <SLTypography
              tabIndex={-1}
              role="heading"
              variant="mediaSubHeading"
            >
              {title}
            </SLTypography>
          </Box>
          <Box
            sx={{
              fontFamily: "Figtree",
              fontSize: { xs: "12px", sm: "15px", md: "17px" },
              fontWeight: { xs: "600", sm: "500" },
              lineHeight: "20px",
              color: "#762B90",
              paddingRight: { xs: "12px", sm: "0px" },
            }}
          >
            {(exploreAllButton[mediaType.toLowerCase()] || showAnchorText) && (
              <Link
                className="exploreLink"
                to={urlGenerated ?? ""}
                style={{ color: "#762B90" }}
                aria-label={`${anchorText} ${title}`}
              >
                <SLTypography
                  variant="bodyCopy"
                  sx={{
                    whiteSpace: "nowrap",
                    color: "#762B90",
                    fontSize: {
                      xl: "17px",
                      lg: "17px",
                      md: "17px",
                      sm: "15px",
                      xs: "12px",
                    },
                  }}
                >
                  {anchorText}
                </SLTypography>
              </Link>
            )}
          </Box>
        </Box>
        {cardDiscription && (
          <SLTypography
            tabIndex={0}
            sx={{
              maxWidth: "928px",
              paddingBottom: { xs: "25px", md: "30px" },
              fontSize: { xs: "12px", sm: "14px", md: "15px", lg: "17px" },
              fontWeight: "500",
              lineHeight: { xs: "18px", md: "22px", lg: "22PX" },
              color: "#514C54",
            }}
          >
            {cardDiscription}
          </SLTypography>
        )}

        {popularText && (
          <SLTypography
            role="heading"
            tabIndex={0}
            sx={{
              fontSize: { xs: "14px", sm: "15px", lg: "17px" },
              fontWeight: "600",
              lineHeight: "20px",
            }}
          >
            {popularText}
          </SLTypography>
        )}
      </Box>
    </>
  );
};

export default CardInformation;
