import { Skeleton, Stack, Switch } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { onEnterOrSpace } from "helpers/common/keypress";
import { lang } from "helpers/common/lang";
import { IntlMessageID } from "helpers/enum";
import { Props } from "react-intl/src/components/relative";
import { useDispatch, useSelector } from "react-redux";
import {
  reduceMotionActions,
  reduceMotionState,
} from "store/reduceMotionSlice";
import "../emailCalendar/index.css";

type ISettingCheckbox = {
  messageId: IntlMessageID;
  paragraphMessageId: IntlMessageID;
  checkedValue?: boolean;
  value?: string;
  switchProps?: any;
  loading: boolean;
  popupDesign?: boolean;
} & Props;

type PushNotificationProps = {
  messageId: IntlMessageID;
  checkedKey: number;
  checkedValue?: boolean;
  switchProps?: any;
} & Props;

const SettingCheckbox = ({
  messageId,
  paragraphMessageId,
  value,
  checkedValue,
  switchProps,
  loading,
  popupDesign,
}: ISettingCheckbox) => {
  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          backgroundColor: "white",
          padding: { xs: "9px", sm: "15px", md: popupDesign ? "15px" : "30px" },
          borderRadius: "10px",
          color: "var(--sl-deep-purple)",
        }}
      >
        <Stack direction="column" gap={1} tabIndex={0}>
          <SLTypography
            variant="heading"
            messageId={messageId}
            sx={{
              fontSize: popupDesign
                ? { xs: "14px", md: "16px" }
                : { xs: "14px", md: "27px" },
              lineHeight: "normal",
              paddingTop: { xs: "6px", md: "0px" },
              letterSpacing: "0px",
            }}
          />
          <SLTypography
            variant="smallBodyCopy"
            messageId={paragraphMessageId}
            sx={{ fontSize: { xs: "12px", sm: "14px" } }}
          />
        </Stack>

        {loading ? (
          <Skeleton
            sx={{
              transform: "unset",
              width: "74px",
              height: "30px",
              padding: "12px",
            }}
          />
        ) : (
          <Switch
            {...switchProps}
            // value={value}
            checked={checkedValue}
            sx={{
              width: "74px",
              height: "56px",
              right: popupDesign ? "-10px" : null,
            }}
            tabIndex={0}
            inputProps={{
              "aria-label": lang(messageId),
              ...switchProps?.inputProps,
            }}
            // onKeyPress={}
          />
        )}
      </Stack>
    </>
  );
};

export const PushNotifications = ({
  messageId,
  checkedKey,
  checkedValue,
  switchProps,
}: PushNotificationProps) => {
  return (
    <>
      <Stack
        alignItems="center"
        sx={{
          dispay: "flex",
          justifyContent: { xs: "space-between", md: "start" },
          flexDirection: { xs: "row-reverse", md: "row" },
        }}
      >
        <Switch
          tabIndex={0}
          {...switchProps}
          key={checkedKey}
          checked={checkedValue}
          sx={{ width: "74px", height: "56px" }}
          aria-label={messageId}
          title={messageId}
        />
        <SLTypography
          sx={{
            color: "var(--sl-deep-purple)",
            fontSize: { xs: "14px", sm: "15px", md: "17px" },
            fontWeight: { xs: "600", md: "500" },
          }}
          variant="smallBody"
          tabIndex={0}
          messageId={messageId}
        />
      </Stack>
    </>
  );
};

export const ReduceMotionSetting = ({
  popupDesign,
}: {
  popupDesign?: boolean;
}) => {
  const dispatch = useDispatch();
  const reduceMotion = useSelector(reduceMotionState);

  const toggleReduceMotion = () => {
    if (reduceMotion) {
      dispatch(reduceMotionActions.off());
    } else {
      dispatch(reduceMotionActions.on());
    }
  };

  return (
    <SettingCheckbox
      popupDesign={popupDesign}
      messageId="app.setting.reduceMotion.title"
      paragraphMessageId="app.setting.reduceMotion.message"
      loading={false}
      checkedValue={!reduceMotion}
      switchProps={{
        onChange: toggleReduceMotion,
        onKeyPress: onEnterOrSpace(toggleReduceMotion),
      }}
    />
  );
};

export default SettingCheckbox;
