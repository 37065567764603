export const getMediaSharingText = (
  mediaTitle: string,
  mediaType: any,
  mediaUrl?: string
) =>
  String(
    "A moment of serenity shared is a gift to the soul. Enjoy this Soul Link %mediaType%."
  )
    .replace(
      "%mediaType%",
      (mediaType || "")
        .replace("frequencyWave", "frequency")
        .replace("regular", "meditation")
    )
    .concat(mediaUrl ? `\n${mediaUrl}` : "");
