/**
 * Converts `hh:mm:ss` or `mm:ss` time to integer number of seconds
 */
export const convertTimeToSeconds = (timeString: string | null) => {
  if (!timeString) {
    return null;
  }

  const timeArr = timeString.split(":");
  let hours = 0,
    minutes = 0,
    seconds = 0;

  // validate input format
  if (timeArr.length === 2) {
    // mm:ss format
    minutes = parseInt(timeArr[0]);
    seconds = parseInt(timeArr[1]);
  } else if (timeArr.length === 3) {
    // hh:mm:ss format
    hours = parseInt(timeArr[0]);
    minutes = parseInt(timeArr[1]);
    seconds = parseInt(timeArr[2]);
  } else {
    return null;
  }

  if (isNaN(hours) || isNaN(minutes) || isNaN(seconds)) {
    return null;
  }

  // calculate total number of seconds
  return hours * 60 * 60 + minutes * 60 + seconds;
};

export const convertSecondsToTime = (seconds: any) => {
  if (isNaN(seconds)) {
    return "00:00";
  }
  const date = new Date(seconds * 1000);
  const hh = date.getUTCHours().toString().padStart(2, "0");
  const mm = date.getUTCMinutes().toString().padStart(2, "0");
  const ss = date.getUTCSeconds().toString().padStart(2, "0");
  if (hh != "00") {
    return `${hh}:${mm}:${ss}`;
  }
  return `${mm}:${ss}`;
};
