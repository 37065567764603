import {
  Box,
  Button,
  Container,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Stack,
  TextField,
} from "@mui/material";
import MeditationAccordian from "components/createMeditationAccordian";
import { SLMeditationLoader } from "components/createMeditationAccordian/createMeditationLoader";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import { ErrorMsg } from "components/errorMsg/ErrorMsg";
import ExploreHeader from "components/explore/heroHeader";
import { MyCreatedMeditations } from "components/myCreatedMeditations";
import { CustomizedSlider } from "components/numberSlider";
import PageTitle from "components/pageTitle/pageTitle";
import { SLButton } from "components/slButton";
import { SLTypography } from "components/slTypography";
import { SLVerificationMessage } from "components/verificationMessage";
import { lang } from "helpers/common/lang";
import {
  AudioIcon,
  CreateMeditationLogo,
  CrossIconTutorial,
  FrequencyIcon,
  FrequencyWhiteIcon,
  MeditationCreatorAlt,
  VideoIcon,
  VideoWhiteIcon,
  WhiteAudioIcon,
} from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useMemo, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useSetState } from "react-use";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import { v4 as uuidv4 } from "uuid";
import "./index.css";

interface State {
  run: boolean;
  steps: Step[];
}

let createMeditationLoader2 = false;

const createMeditation = () => {
  const dispatch = useDispatch();
  const mixpanel = useMixpanel();
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const errorDialog = useDialog();
  const guestLogin = SLStorage.getItem("guestLogin");
  const checkTutorial = SLStorage.getItem("createMeditationTutorial");

  const { data: config } = useSelector(SLSelector.getConfig);
  const { data } = useSelector(SLSelector.getFilters);
  const {
    data: meditationData,
    loading: meditationLoading,
    error: meditationError,
  } = useSelector(SLSelector.postMeditationCreate);

  const [uniqueId, setuniqueId] = useState("");
  const [userCancelMeditation, setuserCancelMeditation] = useState(false);
  const [createMeditationLoader, setMedidtationLoader] = useState(false);
  const [mediaTitle, setMediaTitle] = useState("");
  const [mediaTags, setMediaTags] = useState<any>({});
  const [createMeditation, setCreateMeditation] = useState<any>("");
  const [addedToFavouriteList, setAddedToFavouriteList] = useState(false);
  const [noMeditationFound, setNoMeditationFound] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [cancelStatus, setCancelStatus] = useState(false);
  const [errorTitle, setErrorTitle] = useState(
    "Server Error, Please try again in a moment"
  );
  const [ErrorBody, setErrorBody] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [accordian, setAccordian] = useState({
    audio: false,
    video: false,
    frequency: false,
  });
  const [accordianLoading, setAccordianLoading] = useState(true);
  const [selectedMeditationData, setSelectedMeditationData] = useState<{
    duration: number;
    audio: any;
    video: any;
    frequency: any;
  }>({
    duration: SLStorage.getItem("selectedMeditationDataCreate")?.duration || 12,
    audio: null,
    video: null,
    frequency: null,
  });
  const selectedDuration = Number(selectedMeditationData?.duration) || 12;
  const mediaType = {
    Audio: "audio",
    Video: "video",
    Frequency: "frequencyWave",
  };

  useEffect(() => {
    selectMeditaionIds();
    setuniqueId(uuidv4());
    dispatch(SLThunk.getFilters.call({}));
    createMeditationLoader2 = false;
  }, []);

  const selectMeditaionIds = async () => {
    const getSelectedMeditation = SLStorage.getItem(
      "selectedMeditationDataCreate"
    );
    if (
      getSelectedMeditation &&
      (getSelectedMeditation?.audio ||
        getSelectedMeditation?.video ||
        getSelectedMeditation?.frequency)
    ) {
      setTimeout(() => {
        setSelectedMeditationData(getSelectedMeditation);
        setTimeout(() => {
          setAccordianLoading(false);
        }, 100);
      }, 300);
    } else {
      setAccordianLoading(false);
    }
  };

  const mediaDurationSelect = (duration: number) => {
    if (duration !== selectedDuration) {
      setSelectedMeditationData({ duration } as any);
      SLStorage.setItem("selectedMeditationDataCreate", { duration });
    }
  };

  useEffect(() => {
    if (!createMeditationLoader) {
      setTimeout(() => {
        document.body.style.overflow = "";
      }, 300);
    } else {
      document.body.style.overflow = "hidden";
    }
  }, [createMeditationLoader]);

  useEffect(() => {
    if (meditationError) {
      if (meditationError.includes("internet connection")) {
        document.body.style.overflow = "";
        setErrorTitle("Oops!");
        setErrorBody(meditationError);
        dispatch(SLThunk.postMeditationCreate.reset());
        setOpenDialog(true);
      } else {
        document.body.style.overflow = "";
        errorDialog.show({
          title: "Oops!",
          message: meditationError,
        });
        createMeditationLoader2 = false;
        dispatch(SLThunk.postMeditationCreate.reset());
      }
    }
  }, [meditationError]);

  useEffect(() => {
    if (meditationData && !userCancelMeditation && !cancelStatus) {
      if (selectedMeditationData?.frequency) {
        mixpanel.triggerFrequencyUsedEvent(selectedMeditationData?.frequency);
      }
      navigate(`/app/play-back/${meditationData?.id}`);
      dispatch(SLThunk.postMeditationCreate.reset());
      document.body.style.overflow = "";
    }
  }, [meditationData]);

  useEffect(() => {
    setMedidtationLoader(meditationLoading);
  }, [meditationLoading]);

  useEffect(() => {
    if (createMeditation) {
      SLStorage.removeItem("selectedMeditationDataCreate");
      dispatch(SLThunk.postMeditationCreate.call(createMeditation));
    }
  }, [createMeditation]);

  const createMeditationHandler = () => {
    if (createMeditationLoader2) {
      return;
    }
    createMeditationLoader2 = true;
    setCancelStatus(false);
    if (
      selectedMeditationData.audio &&
      !selectedMeditationData.video &&
      !selectedMeditationData?.frequency
    ) {
      sessionStorage.setItem("fromBuildMeditation", "true");
      setMedidtationLoader(true);
      if (!userCancelMeditation) {
        setMedidtationLoader(false);
        createMeditationLoader2 = false;
        SLStorage.removeItem("previousURLStore");
        SLStorage.removeItem("selectedMeditationDataCreate");
        navigate(`/app/media-player/${selectedMeditationData.audio.id}`);
        document.body.style.overflow = "";
      }
    } else if (
      !selectedMeditationData.audio &&
      selectedMeditationData.video &&
      !selectedMeditationData?.frequency
    ) {
      sessionStorage.setItem("fromBuildMeditation", "true");
      setMedidtationLoader(true);
      if (!userCancelMeditation) {
        setMedidtationLoader(false);
        createMeditationLoader2 = false;
        SLStorage.removeItem("previousURLStore");
        SLStorage.removeItem("selectedMeditationDataCreate");
        navigate(`/app/media-player/${selectedMeditationData.video.id}`);
        document.body.style.overflow = "";
      }
    } else if (
      selectedMeditationData.audio &&
      selectedMeditationData.video &&
      !selectedMeditationData?.frequency
    ) {
      setCreateMeditation({
        audioId: selectedMeditationData.audio.id,
        videoId: selectedMeditationData.video.id,
        title: mediaTitle,
        isDeepLink: false,
        image: selectedMeditationData.video.image,
        thumbnail: selectedMeditationData.video.image,
        uniqueIdentifier: uniqueId,
        duration: selectedDuration,
      });
    } else if (
      selectedMeditationData.audio &&
      !selectedMeditationData.video &&
      selectedMeditationData?.frequency
    ) {
      setCreateMeditation({
        audioId: selectedMeditationData.audio.id,
        frequencyWaveId: selectedMeditationData?.frequency.id,
        title: mediaTitle,
        isDeepLink: false,
        image: selectedMeditationData?.frequency.image,
        thumbnail: selectedMeditationData?.frequency.image,
        uniqueIdentifier: uniqueId,
        duration: selectedDuration,
      });
    } else if (
      selectedMeditationData.audio &&
      selectedMeditationData.video &&
      selectedMeditationData?.frequency
    ) {
      setCreateMeditation({
        videoId: selectedMeditationData.video.id,
        audioId: selectedMeditationData.audio.id,
        frequencyWaveId: selectedMeditationData?.frequency.id,
        title: mediaTitle,
        isDeepLink: false,
        image: selectedMeditationData.video.image,
        thumbnail: selectedMeditationData.video.image,
        uniqueIdentifier: uniqueId,
        duration: selectedDuration,
      });
    }
  };
  const handleErrorClose = () => {
    createMeditationLoader2 = false;
    setOpenDialog(false);
  };

  useEffect(() => {
    if (selectedMeditationData.audio && !selectedMeditationData.video) {
      setMediaTitle(String(selectedMeditationData.audio?.title).trim());
    } else if (!selectedMeditationData.audio && selectedMeditationData.video) {
      setMediaTitle(String(selectedMeditationData.video?.title).trim());
    } else if (selectedMeditationData.audio && selectedMeditationData.video) {
      setMediaTitle(
        String(selectedMeditationData.audio?.title).trim() +
          " in " +
          String(selectedMeditationData.video?.title).trim()
      );
    }
  }, [selectedMeditationData?.audio?.id, selectedMeditationData?.video?.id]);

  const {
    buildMeditationNameError,
    showBuildMeditationName,
    buildMeditationDisabled,
  } = useMemo(() => {
    const buildMeditationNameError =
      !mediaTitle &&
      (selectedMeditationData?.video?.id ||
        selectedMeditationData?.audio?.id ||
        selectedMeditationData?.frequency?.id);

    const showBuildMeditationName =
      selectedMeditationData?.audio?.id ||
      (selectedMeditationData?.video?.id &&
        !selectedMeditationData?.frequency?.id);

    const buildMeditationDisabled =
      buildMeditationNameError ||
      !showBuildMeditationName ||
      createMeditationLoader;

    return {
      buildMeditationNameError,
      showBuildMeditationName,
      buildMeditationDisabled,
    };
  }, [
    mediaTitle,
    createMeditationLoader,
    selectedMeditationData?.video?.id,
    selectedMeditationData?.audio?.id,
    selectedMeditationData?.frequency?.id,
  ]);

  useEffect(() => {
    if (data) {
      const result = data?.reduce(function (r: any, a: any) {
        r[a.type] = r[a.type] || [];
        r[a.type].push(a);
        return r;
      }, {});
      setMediaTags(result);
    }
  }, [data]);

  const changeText = (e: any) => {
    setMediaTitle(e.target.value);
  };
  const handleBlur = (e: any) => {
    setMediaTitle(mediaTitle.trim());
  };

  const handleOpenAccordian =
    (panel: "audio" | "video" | "frequency") =>
    (event: any, isExpanded: any) => {
      panel = panel.toLowerCase().replace("frequencywave", "frequency") as any;

      const accordianState = {
        audio: false,
        video: false,
        frequency: false,
      };

      if (isExpanded) {
        accordianState[panel] = true;
      }

      setAccordian(accordianState);
    };

  const userCancelMeditationHandler = (cancelStatus: boolean) => {
    setCancelStatus(cancelStatus);
    if (!cancelStatus) {
      if (
        selectedMeditationData.audio &&
        !selectedMeditationData.video &&
        !selectedMeditationData?.frequency
      ) {
        setMedidtationLoader(false);
        createMeditationLoader2 = false;
        SLStorage.setItem("previousURLStore", window.location.pathname);
        navigate(`/app/media-player/${selectedMeditationData.audio.id}`);
        document.body.style.overflow = "";
      } else if (
        !selectedMeditationData.audio &&
        selectedMeditationData.video &&
        !selectedMeditationData?.frequency
      ) {
        setMedidtationLoader(false);
        createMeditationLoader2 = false;
        SLStorage.setItem("previousURLStore", window.location.pathname);
        navigate(`/app/media-player/${selectedMeditationData.video.id}`);
        document.body.style.overflow = "";
      } else {
        if (meditationData) {
          navigate(`/app/play-back/${meditationData?.id}`);
          document.body.style.overflow = "";
        } else {
          document.body.style.overflow = "hidden";
          setuserCancelMeditation(false);
        }
      }
    } else {
      setMedidtationLoader(false);
      createMeditationLoader2 = false;
      setuserCancelMeditation(false);
      if (
        (selectedMeditationData.audio &&
          selectedMeditationData.video &&
          selectedMeditationData?.frequency) ||
        (selectedMeditationData.audio && selectedMeditationData.video) ||
        (selectedMeditationData.audio && selectedMeditationData?.frequency)
      ) {
        dispatch(SLThunk.deleteMeditationCancelId.call({ uniqueId: uniqueId }));
      }
    }
  };

  const hideTolltip = () => {
    setState({ run: false });
  };

  const [{ run, steps }, setState] = useSetState<State>({
    run: true,
    steps: [
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Just like the Build Meditation section of the Home Screen, you
              can use the Create section to build your custom meditations."
            >
              <p className="bodyTextTutorial">
                Just like the "Build Meditation" section of the Home Screen, you
                can use the "Create" section to build your custom meditations.
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 1 of 2"
              >
                Walkthrough – Step 1 of 2
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} tabIndex={0} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".slider-Container",
        title: (
          <object tabIndex={0} aria-label="Create">
            <p className="tutorialHeading">Create</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Files added to your custom meditations will be placed in the
              Create section. Here you can swap out files or access additional
              file details."
            >
              <p className="bodyTextTutorial">
                Files added to your custom meditations will be placed in the
                Create section. Here you can swap out files or access additional
                file details.
              </p>
              <object
                className="footerTextTutorial"
                aria-label="Walkthrough – Step 2 of 2"
                tabIndex={0}
              >
                Walkthrough – Step 2 of 2
              </object>
              <Button
                type="button"
                aria-live="off"
                aria-label=""
                data-action="skip"
                role="button"
                title=""
                className="lastStepBtn"
                onClick={hideTolltip}
                onKeyPress={hideTolltip}
              >
                <img src={CrossIconTutorial} tabIndex={0} />
              </Button>
            </object>
          </>
        ),
        locale: {
          skip: <img src={CrossIconTutorial} tabIndex={0} />,
          last: "Close",
        },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".createMeditationSelection",
        title: (
          <object tabIndex={0} aria-label="File Added">
            <p className="tutorialHeading">File Added</p>
          </object>
        ),
        disableBeacon: true,
      },
    ],
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const elementOverlay = document.querySelector<HTMLElement>(
      ".react-joyride__spotlight"
    );
    const button = document.querySelector('button[data-action="skip"]');
    if (button) {
      button.setAttribute("title", "Skip tutorial");
      button.setAttribute("aria-label", "Skip tutorial");
    }
    if (elementOverlay) {
      elementOverlay.style.backgroundColor = "transparent";
    }
    if (finishedStatuses.includes(status)) {
      if (!checkTutorial) {
        SLStorage.setItem("createMeditationTutorial", true);
      }

      setState({ run: false });
    }
  };

  return (
    <>
      <PageTitle title="Meditation Creator" />

      <ExploreHeader
        staticImage={
          appUrls.mediaImage(config?.createPageScene?.thumbnail) ||
          MeditationCreatorAlt
        }
        videoSource={appUrls.mediaScene(config?.createPageScene?.url)}
      />
      {!guestLogin && !checkTutorial ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton={true}
          run={run}
          scrollToFirstStep={false}
          showProgress={false}
          showSkipButton={true}
          disableOverlayClose={true}
          spotlightPadding={0}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      ) : (
        ""
      )}
      <div className="p-relative main-wrapper">
        <img
          width="170"
          style={{
            position: "absolute",
            right: "0px",
            top: "40px",
          }}
          src={CreateMeditationLogo}
        />
        <Container
          className="main-container p-relative"
          sx={{ width: { xl: "93.2%", lg: "95%" } }}
          maxWidth={false}
        >
          <Grid container>
            <Grid item xs={12} lg={12}>
              <Stack
                sx={{
                  width: { lg: "700px" },
                  margin: "0 auto",
                  textAlign: { xs: "left", md: "center" },
                  marginBottom: "20px",
                  marginTop: { xs: "2px", sm: "20px", md: "50px", lg: "60px" },
                }}
              >
                <SLTypography
                  sx={{
                    fontSize: { xs: "28px", md: "37px" },
                    fontWeight: "600",
                    lineHeight: "43px",
                    color: "var(--sl-deep-purple)",
                    paddingBottom: { xs: "6px", sm: "20px", md: "30px" },
                  }}
                  tabIndex={0}
                >
                  Meditation Creator
                </SLTypography>
                <SLTypography
                  variant="homeParagraphText"
                  color="text-deepPurple"
                  sx={{
                    fontSize: { xs: "14px", md: "21px" },
                    lineHeight: { xs: "20px", md: "30px" },
                  }}
                  tabIndex={0}
                >
                  This first of-it's-kind technology offers a unique library of
                  audio, video, and frequency waves – so you can create more
                  meaningful meditations.
                </SLTypography>
              </Stack>
              <CustomizedSlider
                durationvalue={selectedDuration}
                mediaDurationSelect={mediaDurationSelect}
                audiosetstate={selectedMeditationData.audio}
                videosetstate={selectedMeditationData.video}
                frequencysetstate={selectedMeditationData?.frequency}
              />
              <div className="createMeditationSelection">
                <MeditationAccordian
                  fromPage="CREATE_MEDITATION"
                  loading={accordianLoading}
                  addedToFavourites={setAddedToFavouriteList}
                  successMessageText={setSuccessMessage}
                  duration={selectedDuration}
                  mediatags={mediaTags?.audio || []}
                  mediatype={mediaType.Audio}
                  mediaicon={AudioIcon}
                  mediatitle={"Audio"}
                  changeMeditationFlag={false}
                  mediawhiteicon={WhiteAudioIcon}
                  selectedmedia={(audio: any) =>
                    setSelectedMeditationData((prv) => ({ ...prv, audio }))
                  }
                  open={accordian.audio}
                  accordianhandleChange={handleOpenAccordian}
                  selectedMeditationData={selectedMeditationData?.audio}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setSelectedMeditationDataflag={() => {}}
                  selectedMeditationDataFlag={false}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setCardExistingData={() => {}}
                  chooseMediaDescription="Our unique blend of ancient auditory progressions raise vibrations
                in the brain and enhance your emotional, mental, and physical
                performance."
                />
                <MeditationAccordian
                  fromPage="CREATE_MEDITATION"
                  loading={accordianLoading}
                  addedToFavourites={setAddedToFavouriteList}
                  successMessageText={setSuccessMessage}
                  duration={selectedDuration}
                  mediatags={mediaTags?.video}
                  mediatype={mediaType.Video}
                  mediaicon={VideoIcon}
                  mediatitle={"Video"}
                  changeMeditationFlag={false}
                  mediawhiteicon={VideoWhiteIcon}
                  selectedmedia={(video: any) =>
                    setSelectedMeditationData((prv) => ({ ...prv, video }))
                  }
                  open={accordian.video}
                  accordianhandleChange={handleOpenAccordian}
                  selectedMeditationData={selectedMeditationData?.video}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setSelectedMeditationDataflag={() => {}}
                  selectedMeditationDataFlag={false}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setCardExistingData={() => {}}
                  chooseMediaDescription="Immersive imagery creates a timeless experience of calming landscapes, cosmic journeys, and cinematic escapes that transport your soul to new sceneries."
                />
                <MeditationAccordian
                  fromPage="CREATE_MEDITATION"
                  loading={accordianLoading}
                  addedToFavourites={setAddedToFavouriteList}
                  successMessageText={setSuccessMessage}
                  duration={selectedDuration}
                  mediatags={mediaTags?.frequencyWave}
                  mediatype={mediaType.Frequency}
                  mediaicon={FrequencyIcon}
                  mediatitle={"Frequency Wave"}
                  mediawhiteicon={FrequencyWhiteIcon}
                  changeMeditationFlag={false}
                  selectedmedia={(frequency: any) =>
                    setSelectedMeditationData((prv) => ({ ...prv, frequency }))
                  }
                  open={accordian.frequency}
                  accordianhandleChange={handleOpenAccordian}
                  selectedMeditationData={selectedMeditationData?.frequency}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setSelectedMeditationDataflag={() => {}}
                  selectedMeditationDataFlag={false}
                  // eslint-disable-next-line @typescript-eslint/no-empty-function
                  setCardExistingData={() => {}}
                  chooseMediaDescription="Transformative frequency files stimulate the brain and induce specific states of consciousness designed to calm anxiety, reduce stress, and improve cognition."
                />
              </div>
              <Stack sx={{ alignItems: "center" }}>
                {showBuildMeditationName ? (
                  <Box sx={{ width: { xs: "100%", sm: "500px !important" } }}>
                    <TextField
                      className="sl-input MeditationTitleTextField"
                      sx={{
                        marginTop: {
                          xs: "20px !important",
                          md: "30px !important",
                        },
                        "&,& .MuiInputBase-root": {
                          width: { xs: "100%", sm: "500px !important" },
                        },
                      }}
                      label="Meditation Name"
                      onChange={changeText}
                      onBlur={handleBlur}
                      value={mediaTitle}
                    />

                    <ErrorMsg
                      text={lang("general.error.EmptyFields")}
                      show={buildMeditationNameError}
                    />
                  </Box>
                ) : null}
                <Button
                  className="buildMeditationBtn"
                  onClick={createMeditationHandler}
                  aria-disabled={buildMeditationDisabled}
                  sx={{
                    marginTop: {
                      xs: "20px !important",
                      md: "30px !important",
                    },
                    opacity: !buildMeditationDisabled ? "1" : "0.3",
                    pointerEvents: !buildMeditationDisabled ? "all" : "none",
                  }}
                >
                  <SLTypography
                    variant="title"
                    sx={{ fontWeight: "500", letterSpacing: "0.5px" }}
                    messageId={"meditation.build"}
                  />
                </Button>
              </Stack>
            </Grid>
          </Grid>
          {!guestLogin ? <Divider className="mt-7" /> : ""}
        </Container>

        {!guestLogin ? (
          <Grid container className="mt-6 s-container createPageLoader">
            <Grid item xs={12} lg={12}>
              <div className="pr-7 meditationHeading d-flex-space-between">
                <SLTypography variant="mediaSubHeading" tabIndex={0}>
                  My Created Meditations
                </SLTypography>
                {!noMeditationFound ? (
                  <SLTypography
                    variant="smallBodyUnderLined"
                    color="text-purple"
                    sx={{
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                      fontSize: {
                        lg: "17px",
                        sm: "15px",
                        xs: "12px",
                      },
                    }}
                    onClick={(e) => {
                      navigate("/app/my-list/meditation/created-meditation");
                    }}
                    onKeyPress={(e) => {
                      navigate("/app/my-list/meditation/created-meditation");
                    }}
                    tabIndex={0}
                  >
                    Show All
                  </SLTypography>
                ) : null}
              </div>

              <MyCreatedMeditations
                fromPage="CREATE_MEDITATION"
                duration={selectedDuration}
                addedToFavourites={setAddedToFavouriteList}
                successMessageText={setSuccessMessage}
                setSelectedMeditationData={setSelectedMeditationData}
                setNoMeditationFound={setNoMeditationFound}
                allMediaSelected={
                  selectedMeditationData.audio ||
                  selectedMeditationData.video ||
                  selectedMeditationData?.frequency
                }
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                setInitialMeditationCounter={() => {}}
                initialMeditationCounter={false}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                setCardExistingData={() => {}}
                cardExistingData={false}
                // eslint-disable-next-line @typescript-eslint/no-empty-function
                setChangeMeditationFlag={() => {}}
              />
            </Grid>
          </Grid>
        ) : null}
      </div>

      <SLDialog
        dialog={errorDialog}
        dismissable={true}
        onDismiss={errorDialog.hide}
        actionButtons={{
          onYesButtonClicked: errorDialog.hide,
          onNoButtonClicked: errorDialog.hide,
        }}
      />

      <SLMeditationLoader
        setuserCancelMeditation={setuserCancelMeditation}
        userCancelMeditationHandler={userCancelMeditationHandler}
        open={createMeditationLoader || userCancelMeditation}
      />

      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box sx={{ padding: "15px 25px" }}>
          <Stack direction="column" textAlign="left" spacing={1}>
            <DialogContent
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                padding: 0,
                color: "var(--sl-deep-purple)",
              }}
            >
              <SLTypography
                sx={{
                  fontWeight: "600",
                  fontSize: "20px",
                  maxWidth: "350px",
                  textAlign: "center",
                }}
              >
                {errorTitle}
              </SLTypography>
              <SLTypography
                sx={{
                  fontWeight: "400",
                  fontSize: "14px",
                  maxWidth: "350px",
                  textAlign: "center",
                }}
              >
                {ErrorBody}
              </SLTypography>
            </DialogContent>
          </Stack>
          <Box
            sx={{
              marginTop: "3%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <SLButton
              sx={{ width: "200px" }}
              variant="contained"
              messageId={"general.ok"}
              onClick={handleErrorClose}
            />
          </Box>
        </Box>
      </Dialog>

      <SLVerificationMessage
        open={addedToFavouriteList}
        sx={{ zIndex: 9999 }}
        message={successMessage}
      />
    </>
  );
};
export default createMeditation;
