/* eslint-disable indent */
import { Avatar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { onValue } from "firebase/database";
import {
  AccountCircleOutlined,
  DefaultUserImage,
  HelpOutlined,
  InfoOutlined,
  PsychologyOutlined,
  SettingOutlined,
} from "helpers/enum/constants";
import { profilePicture } from "helpers/profilePicture";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { activeUsers } from "utils/firebase";
import { SLSelector } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import "./index.css";
import HeaderNavigationLinks from "./navigationLinks";
import { Sidebar } from "./sideBar/sideBar";

const useStyles = makeStyles((theme: any) => ({
  paper: {
    width: "350px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  fonts: {
    [theme.breakpoints.up("xs")]: {
      fontSize: "14px",
      color: "red",
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  const guestLogin = SLStorage.getItem("guestLogin");

  const { data } = useSelector(SLSelector.getUserProfile);
  const { data: configData } = useSelector(SLSelector.getConfig);

  const [toggle, setToggle] = React.useState(false);
  const [profileImage, setProfileImage] = React.useState("");
  const [activeUserCount, setActiveUserCount] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    onValue(activeUsers, (snapshot) => {
      setActiveUserCount(String(snapshot.exists() ? snapshot.size : 0));
    });
  }, []);

  React.useEffect(() => {
    const userImage = profilePicture(data?.image, configData);
    setProfileImage(userImage);
  }, [configData, data]);

  const toggleDrawer = (flag: boolean) => {
    //alert(flag);
    if (flag == false) {
      setTimeout(() => {
        setToggle(flag);
      }, 500);
    } else {
      setToggle(flag);
    }
  };

  const profileLinks = [
    {
      iconButton: (
        <AccountCircleOutlined sx={{ width: "18px", height: "18px" }} />
      ),
      name: "Profile",
      redirectionLink: "/app/profile",
    },
    !guestLogin
      ? {
          iconButton: (
            <SettingOutlined sx={{ width: "18px", height: "18px" }} />
          ),
          name: "Settings",
          redirectionLink: "/app/settings",
        }
      : "",
    {
      iconButton: <HelpOutlined sx={{ width: "18px", height: "18px" }} />,
      name: "Help Center",
      redirectionLink: "/app/help-center/faq",
    },
    !guestLogin
      ? {
          iconButton: (
            <PsychologyOutlined sx={{ width: "18px", height: "18px" }} />
          ),
          name: "How to Use",
          redirectionLink: "/app/how-it-works",
        }
      : "",
    {
      iconButton: <InfoOutlined sx={{ width: "18px", height: "18px" }} />,
      name: "About",
      redirectionLink: !guestLogin ? "/app/author-details" : "/app/about-us",
    },
  ];
  // }

  const formatMeditationNumber = (number: string) => {
    const n = Number(number);
    if (n < 1e3) return n;
    if (n >= 1e3 && n < 1e6) return +(n / 1e3).toFixed(1) + "K";
    if (n >= 1e6 && n < 1e9) return +(n / 1e6).toFixed(1) + "M";
    if (n >= 1e9 && n < 1e12) return +(n / 1e9).toFixed(1) + "B";
    if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
  };

  return (
    <>
      <Box display="flex" gap="20px">
        <HeaderNavigationLinks />

        <div
          className="current-Meditation-Container"
          style={{ width: "110px", justifyContent: "flex-end" }}
        >
          {location.pathname === "/app/home" ? (
            <div
              className="current-Meditations"
              aria-label="Currently Meditating"
              title="Currently Meditating"
              tabIndex={0}
            >
              {typeof activeUserCount === "undefined" ? (
                <div id="covers-spin" />
              ) : (
                formatMeditationNumber(activeUserCount || "")
              )}
            </div>
          ) : null}
          <Avatar
            tabIndex={0}
            onClick={() => toggleDrawer(true)}
            onKeyPress={() => toggleDrawer(true)}
            title="Profile Detail"
            src={profileImage ? profileImage : DefaultUserImage}
            className="header-profile-icon"
            sx={{ cursor: "pointer" }}
          />
        </div>
      </Box>

      <Sidebar
        toggle={toggle}
        toggleDrawer={toggleDrawer}
        classes={classes}
        data={data}
        profileImage={profileImage}
        profileLinks={profileLinks}
      />
    </>
  );
};

export default Header;
