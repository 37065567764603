import { Avatar, Stack } from "@mui/material";
import { UpIconImage } from "helpers/enum/constants";
import { useEffect, useState } from "react";
import { SLButton } from "../slButton";

export default function Loadmore({
  data,
  handleLoadmore,
  focusElementId,
}: {
  data?: { data: any[]; totalCount: number };
  handleLoadmore: () => void;
  focusElementId?: string;
}) {
  const [hasMoreData, setHasMoreData] = useState<boolean>(false);

  useEffect(() => {
    if (data?.data) {
      if (data.data.length < data.totalCount) {
        setHasMoreData(true);
      } else {
        setHasMoreData(false);
      }
    }
  }, [data]);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    if (focusElementId) {
      const searchEl = document.getElementById(
        focusElementId
      ) as HTMLInputElement;
      if (searchEl) {
        setTimeout(() => {
          searchEl.focus();
        }, 500);
      }
    }
  };

  return (
    <Stack
      direction="row"
      alignItems="center"
      width="100%"
      spacing={2}
      marginTop="30px"
      marginBottom="25px"
      justifyContent="space-between"
    >
      <SLButton
        fullWidth
        disabled={!hasMoreData}
        onClick={() => handleLoadmore()}
        onKeyPress={() => handleLoadmore()}
        tabIndex={0}
        sx={{
          color: "var(--sl-deep-purple)",
          borderColor: "var(--colorLightGray) !important",
          width: "200px",
          backgroundColor: "transparent !important",
          // justifyContent:"center"
          "@media screen and (max-width: 820px)": {
            width: "275px",
          },
        }}
        slClass="bordered"
        messageId={"general.loadMore"}
      />
      <Avatar
        tabIndex={0}
        sx={{
          width: "24px",
          height: "24px",
          color: "var(--sl-deep-purple)",
          backgroundColor: "transparent",
          border: "0",
          cursor: "pointer",
        }}
        onClick={() => scrollToTop()}
        onKeyPress={() => scrollToTop()}
        aria-label="Press to go to top"
        src={UpIconImage}
      />
    </Stack>
  );
}
