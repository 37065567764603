import { Box, Divider, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { WhiteTickWithWhiteCircle } from "helpers/enum/constants";

const SubscriptionTextualColumn = () => {
  return (
    <>
      <Box
        className="subscription-textual-column"
        sx={{
          borderTop: {
            xs: "none",
            sm: "none",
            md: "1px solid rgba(255,255,255, 0.3)",
            lg: "1px solid rgba(255,255,255, 0.3)",
            xl: "1px solid rgba(255,255,255, 0.3)",
          },
          borderBottom: {
            xs: "none",
            sm: "none",
            md: "1px solid rgba(255,255,255, 0.3)",
            lg: "1px solid rgba(255,255,255, 0.3)",
            xl: "1px solid rgba(255,255,255, 0.3)",
          },
          paddingRight: {
            xl: "50px",
            lg: "50px",
            md: "50px",
            sm: "40px",
            xs: "16px",
          },
          marginLeft: {
            xl: "50px",
            lg: "50px",
            md: "50px",
            sm: "16px",
            xs: "16px",
          },
          paddingBottom: {
            xl: "27px",
            lg: "25px",
            md: "24px",
            sm: "40px",
            xs: "16px",
          },
        }}
      >
        <Stack
          sx={{
            color: "white",
            maxWidth: "500px",
            textAlign: "center",
            paddingTop: { xs: "0px", sm: "15px", md: "50px" },
          }}
        >
          <SLTypography
            variant="heading"
            tabIndex={0}
            sx={{
              lineHeight: "32px",
              fontSize: { xs: "19px", sm: "23", md: "27px" },
            }}
          >
            Soul Link is a product of Supernal World Creations, a 501(c)(3)
            nonprofit organization.
          </SLTypography>
          <Divider
            sx={{
              background: "white",
              marginTop: { xs: "15px", sm: "20px" },
              marginBottom: { xs: "15px", sm: "20px" },
              opacity: "0.2",
              marginLeft: {
                xs: "0px",
                sm: "16px",
                md: "24px",
                lg: "0px",
                xl: "0px",
              },
            }}
          />
          <SLTypography
            tabIndex={0}
            variant="smallBody"
            sx={{
              paddingLeft: {
                xs: "0px",
                sm: "16px",
                md: "20px",
                lg: "0px",
                xl: "0px",
              },
              maxWidth: "455px",
              fontSize: { xs: "12px", sm: "17px" },
            }}
          >
            Your subscription to Soul Link directly contributes to supporting
            our mission of Humanitarian Innovations. All subscriptions include:
          </SLTypography>
        </Stack>
        <Divider
          sx={{
            background: "white",
            marginTop: { xs: "15px", sm: "20px" },
            marginBottom: { xs: "15px", sm: "20px" },
            opacity: "0.2",
            marginLeft: {
              xs: "0px",
              sm: "16px",
              md: "24px",
              lg: "0px",
              xl: "0px",
            },
          }}
        />
        <Stack
          sx={{
            color: "white",
            maxWidth: "450px",
            textAlign: "left",
            paddingBottom: { xs: "", md: "0px", lg: "50px" },
            paddingLeft: {
              xs: "42px",
              sm: "16px",
              md: "24px",
              lg: "0px",
              xl: "0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              gap: "10px",
              paddingBottom: "20px",
            }}
          >
            <Box
              component="img"
              src={WhiteTickWithWhiteCircle}
              alt="/"
              sx={{ width: "20px", height: "20px" }}
            />
            <SLTypography
              variant="smallBody"
              tabIndex={0}
              sx={{ fontSize: { xs: "12px", sm: "17px" } }}
            >
              Unlock our entire library of transcendent audios, immersive
              visuals, and frequency waves to build an experience beyond
              meditation.
            </SLTypography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              gap: "10px",
              paddingBottom: "20px",
            }}
          >
            <Box
              component="img"
              src={WhiteTickWithWhiteCircle}
              alt="/"
              sx={{ width: "20px", height: "20px" }}
            />
            <SLTypography
              tabIndex={0}
              variant="smallBody"
              sx={{ fontSize: { xs: "12px", sm: "17px" } }}
            >
              Access free daily meditations and inspiration made to elevate your
              everyday.
            </SLTypography>
          </Box>{" "}
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              gap: "10px",
            }}
          >
            <Box
              component="img"
              src={WhiteTickWithWhiteCircle}
              alt="/"
              sx={{ width: "20px", height: "20px" }}
            />
            <SLTypography
              tabIndex={0}
              variant="smallBody"
              sx={{ fontSize: { xs: "12px", sm: "17px" } }}
            >
              Save your favorite meditations to get the Soul Link experience
              from anywhere.
            </SLTypography>
          </Box>
        </Stack>
      </Box>
    </>
  );
};

export default SubscriptionTextualColumn;
