import { lang } from "helpers/common/lang";

function TermsOfUse() {
  return (
    <>
      <center>
        <h2>Soul Link Terms of Service</h2>
      </center>

      <p>
        Welcome to Soul Link, owned and operated by Supernal World Creations,
        Inc. (for the purposes herein together and collectively &ldquo;
        <u>SWC</u>,&rdquo; &ldquo;<u>Soul Link</u>,&rsquo; &ldquo;<u>we</u>
        ,&rdquo; &ldquo;<u>us</u>,&rdquo; or &ldquo;<u>our</u>&rdquo;). These
        Terms of Service (these &ldquo;<u>Terms</u>&rdquo; or this &ldquo;
        <u>Agreement</u>&rdquo;) govern your access to and use of Soul Link;
        including without limitation our website and online or mobile services,
        and software provided on or in connection with those services
        (collectively, the &ldquo;<u>Service(s)</u>&rdquo;).
      </p>

      <p>
        When you use the Services, these Terms constitute a binding agreement
        between SWC, you, and any company that you represent or with which you
        are affiliated (&ldquo;<u>you</u>,&rdquo; or &ldquo;<u>your</u>
        ,&rdquo;). BY
      </p>

      <p>
        COMPLETING THE ACCOUNT REGISTRATION PROCESS OR OTHERWISE USING OUR
        SERVICES, YOU AGREE TO BE BOUND BY THESE TERMS AND ALL OF THE TERMS ARE
        INCORPORATED HEREIN BY REFERENCE. YOU MUST BE BOTH: (A) AT LEAST SIXTEEN
        (16) YEARS OF AGE; AND (B) OF THE AGE OF MAJORITY UNDER THE LAWS OF YOUR
        JURISDICTION TO ENTER INTO THIS AGREEMENT. If you do not meet these
        qualifications or agree to these Terms, you may not access or use the
        Services.
      </p>

      <p>
        SWC may modify these Terms from time to time, and modified Terms will
        take effect immediately upon posting to the Soul Link Platform (as
        defined herein). Your continued use of the Services after modified Terms
        have been posted will constitute your acceptance of such modified Terms.
      </p>

      <p>
        If you wish to receive electronic communications from SWC, you may
        opt-in to the SWC e-mail newsletter mailing list by submitting your
        information to SupernalWorldCreations.org.
      </p>

      <p>
        <strong>
          <u>Our Services Do Not Provide Medical Advice</u>
        </strong>
      </p>

      <p>
        The information provided on and by our Services is for educational
        purposes only and is not intended to diagnose, treat, cure, or prevent
        any medical condition or disease. The content on the Services should not
        be used as a substitute for professional medical advice, diagnosis, or
        treatment. Always seek the advice of your physician or other qualified
        healthcare provider with any question you may have regarding a medical
        condition. The owners, authors, and contributors of the Services do not
        assume any responsibility for any personal injury or damage resulting
        from the use or misuse of the information provided on the Services. By
        using the Services, accept full responsibility for your own actions and
        decisions related to the use of any information provided on the
        Services. If you think you may have a medical emergency, call your
        doctor or 911 immediately.
      </p>

      <h3>1. Definitions</h3>

      <ol style={{ listStyleType: "lower-roman" }}>
        <li>
          &ldquo;<u>Soul Link Channels</u>&rdquo; means any social media
          channels through which SWC decides, in its sole discretion, to
          communicate with you.
        </li>
        <li>
          &ldquo;<u>Soul Link Platform</u>&rdquo; means the SWC website, the
          Soul Link mobile application and website, and the Soul Link Channels.
        </li>
      </ol>

      <h3>2. Access to Services</h3>

      <p>
        SWC grants you a limited, non-exclusive, non-transferable, and revocable
        right to access and use the Services in accordance with these Terms. Use
        of Soul Link is offered on a monthly or yearly subscription period basis
        (&quot;<u>Subscription(s)</u>&quot; or &ldquo;<u>Subscription Period</u>
        &rdquo;) which you will choose prior to making your purchase and using
        the Services. Both monthly and annual subscribers are entitled to full
        use of the Services for the length of the Subscription Period chosen. To
        use the Services, you must create a user account (&ldquo;<u>Account</u>
        &rdquo;) and password using a valid email address.
      </p>

      <p>
        Additionally, SWC grants you the non-exclusive, non-transferable, and
        revocable right to access and participate in communications via the Soul
        Link Channels.
      </p>

      <h3>3. Access from Third Party Sites</h3>

      <p>
        SWC may integrate with third-party software to provide you the Services.
        SWC has no control over third-party software we may integrate with, or
        over the information you have entered into or have stored on that
        software. SWC also does not have control over the application
        programming interface (&ldquo;<u>API</u>&rdquo;) for third-party
        software or data-sharing protocols, and you acknowledge and agree that
        SWC is not responsible for any lost data, errors in data or in data
        transmission, downtime, unavailability, or any other loss or damage that
        might occur from using that software or the links on or connections to
        that software. We recommend you review the privacy policy governing any
        third-party software before using it.
      </p>

      <h3>4. Important Financial Information; Payments; Billing; Renewal</h3>

      <p>
        You agree to pay SWC all charges applicable for the Services at the time
        such Services are ordered by you and on an on-going basis, as
        applicable. Soul Link may offer promotional free or reduced-cost trial
        period Subscriptions (&ldquo;<u>Trial Period(s)</u>&rdquo;) from time to
        time. These Terms shall apply to such Trial Periods in the same manner
        as the Terms apply to any Subscription Period. Any unused portion of a
        Trial Period will be forfeited when you purchase a Subscription to Soul
        Link, and your Subscription Period will begin at the date of such
        purchase. Payment for the Services will be charged to your iTunes
        Account when you confirm your Subscription choice and payment
        information. You will be charged ${lang("app.pricing.monthly")} per
        month for a monthly Subscription or a single annual fee of $
        {lang("app.pricing.yearly")} for an annual Subscription. Your
        Subscription Period will renew automatically within twenty-four (24)
        hours prior to the end of your current Subscription Period at the
        then-current cost, at which time your Account will be charged. If you do
        not wish to renew your Subscription, you must manually disable the
        auto-renew function no less than twenty-four (24) hours prior to the end
        of the current Subscription Period. The current Subscription renewal
        cost will be identified at the time of renewal. You may manage your
        Subscription by logging into your Account and accessing your
        &ldquo;Account Settings&rdquo; after your purchase after your purchase
        is confirmed. You are responsible for providing SWC with up-to-date
        information including without limitation, valid credit card numbers and
        contact information, at the time you order Services and within a
        reasonable period of time in the event such information changes. In the
        event that any amounts due for Services remain unpaid, or in the event
        that any check, ACH payment, credit card charge or online payment
        service is denied, returned, or refused: (i) you agree to pay, in
        addition to the principal amount and any applicable interest thereon,
        all of the bank and payment provider charges assessed against you and
        SWC in addition to SWC&#39;s costs of collecting or attempting to
        collect, including without limitation reasonable attorney fees and
        expenses; and (ii) SWC may charge such remaining payment and any
        incidental charges against any other form of payment you have ever added
        to your SWC account. All transactions originating from your account are
        your responsibility.
      </p>

      <p>
        SWC may change billing procedures, prices, promotions, or policies at
        any time without advance notice. All such changes will be posted via the
        Soul Link Platform. Continued use of the Soul Link Platform or Services
        constitutes your affirmation that you have read and understood such
        notices and agree to the terms contained therein.
      </p>

      <h3>5. Authority</h3>

      <p>
        You represent either that: (i) you are an individual entering this
        Agreement for your personal use and you are over 16 years of age; or
        (ii) you are the authorized representative of a company or legal entity,
        duly organized, validly existing, and in good standing under the laws of
        the jurisdiction of its organization, and that you are duly authorized
        to accept and execute this Agreement.
      </p>

      <h3>6. User Conduct; Termination</h3>

      <p>
        You will use the Services solely in accordance with these Terms. By
        accessing or using the Soul Link Platform or the Services, you agree
        that you will comply with all applicable laws and regulations. SWC
        reserves the right at all times to disclose any information as necessary
        to satisfy any applicable law, regulation, legal process or governmental
        request. Without limitation, you will not use the Services to:
      </p>

      <ul>
        <li>
          abuse, harass, threaten, or intimidate other users or customer support
          personnel;
        </li>
        <li>
          submit or transmit any content whatsoever that is obscene or otherwise
          violates any law or right of any third party, that is hateful or
          discriminatory, or that incites violence or criminal conduct;
        </li>
        <li>attempt to impersonate another user or person;</li>
        <li>conduct any unlawful activity;</li>
        <li>
          solicit or obtain password, account, or private information from any
          user;
        </li>
        <li>
          conduct or promote unauthorized advertising, solicitations, or
          &ldquo;spam;&rdquo;
        </li>
        <li>
          use, reproduce, perform, display, distribute, reverse engineer, or
          prepare derivative works of any content, without authorization, that
          belongs to or is licensed to SWC, or that originates from the
          Services, or that belongs to another SWC user or to a third party, or
          that violates or infringes any third-party intellectual property
          rights; or
        </li>
        <li>
          upload, post, transmit, or make available any content or other
          material that contains software viruses, or any other malicious
          computer code designed to interrupt or limit the functionality of the
          Services.
        </li>
      </ul>

      <p>
        By agreeing to these Terms or accessing the Soul Link Platform, you
        assume full liability for any content that is in violation of the Terms
        set forth herein. In addition, SWC reserves the right to suspend,
        terminate, or cancel your Account and any access to all information on
        the Soul Link Platform, or any other means of communication used by SWC,
        including without limitation, the Soul Link Channels, for violation of
        these Terms, in SWC&rsquo;s sole discretion.
      </p>

      <p>
        Upon such termination, you agree to immediately pay SWC any accrued and
        unpaid amounts related to Services. Any accrued rights, remedies,
        obligations, or liabilities will not be affected including without
        limitation SWC&#39;s right to claim damages in respect of any breach of
        these Terms or SWC&#39;s right to indemnification by you under these
        Terms. In addition, any provisions of these Terms that expressly, or by
        implication, have effect after termination will continue in full force
        and effect.
      </p>

      <p>
        SWC retains the right to retain your information following termination
        of your Account as required by applicable regulations and SWC &#39;s
        internal policies in order to comply with our legal and reporting
        obligations, resolve disputes, enforce our agreements, complete any
        outstanding transactions, and for the detection and prevention of fraud.
      </p>

      <h3>7. Trademarks and Copyrights</h3>

      <p>
        The name &ldquo;Soul Link&rdquo; and &ldquo;Supernal World
        Creations&rdquo; and all other SWC marks, logos, taglines, custom
        graphics, and button icons (collectively, &ldquo;<u>Marks</u>&rdquo;)
        appearing on the Soul Link Platform or the Services are service marks,
        trademarks, or trade dress of SWC. You may not use such marks without
        the prior written consent of SWC. All other trademarks, product names,
        company names, and logos used on the Soul Link Platform or Services, if
        any, are the property of their respective owners.
      </p>

      <p>
        All software, audiovisual content, and audible-only content on the
        Services, the selection and arrangement thereof, and the compilation of
        all content on the Services (collectively, &ldquo;<u>SWC</u>{" "}
        <u>Conten</u>t&rdquo; and together with Marks, &ldquo;
        <u>SWC Proprietary Information</u>&rdquo;) are the exclusive
        intellectual property of SWC and are protected by U.S. and international
        copyright laws.
      </p>

      <p>
        If you are a copyright owner or an agent thereof and believe that any
        content on the Services infringes upon your copyrights, you may submit a
        notification to SWC pursuant to the Digital
      </p>

      <p>
        Millennium Copyright Act in accordance with our takedown policy recited
        in Section 1515 herein.
      </p>

      <h3>8. Proprietary Rights; Limited License</h3>

      <p>
        As between you and SWC, SWC exclusively owns all right, title, and
        interest in and to the Services, the SWC Platform, and the SWC
        Proprietary Information, including the intellectual property rights
        contained therein. SWC will have a royalty-free, worldwide,
        transferable, sublicensable, irrevocable, perpetual license to use or
        incorporate into the Services any suggestions, enhancement requests,
        recommendations, or other feedback provided by you, relating to the
        operation of the Services. Your use of the Platform does not grant you
        ownership of any content, code, data, or materials you may access on or
        through the Soul Link Platform. You may access and view the content on
        the Soul Link Platform, and you may use such content for your personal
        use only. Unless otherwise noted herein or on the Soul Link Platform,
        use of the Soul Link Platform is strictly for your personal,
        non-commercial use.
      </p>

      <h3>9. Privacy Policy; Cookies</h3>

      <p>
        SWC cares about and respects your privacy. Take the time to read
        SWC&rsquo;s privacy policy (&ldquo;<u>Privacy</u> <u>Policy</u>&rdquo;)
        found here: https://SupernalWorldCreations.org/PrivacyPolicy so you can
        see how we use any information we receive. You acknowledge that when you
        download, install, or use the Soul Link Platform, SWC may use automatic
        means (including, for example, cookies and web beacons) to collect
        information about your device and about your use of the Soul Link
        Platform. You also may be required to provide certain information about
        yourself as a condition to downloading, installing, or using the Soul
        Link Platform or certain of its features or functionality, and the Soul
        Link Platform may provide you with opportunities to share information
        about yourself with others. SWC will employ commercially reasonable
        measures to protect such personally identifiable information you enter
        into the Services. If you object to your information being transferred
        or used in this way, then you must not use the Soul Link Platform. By
        downloading, installing, using, and providing information to or through
        the Soul Link Platform, you consent to all actions taken by us with
        respect to your information in compliance with the Privacy Policy.
      </p>

      <h3>10. Using Your Account</h3>

      <p>
        Each registered user must register for their own Account and may not
        have more than one Account. You will provide accurate information when
        you sign up to use the Services and will keep your account information
        up to date. You will also use your real name, or the legal name of the
        entity you represent, use your own email address, or one that you have
        permission to use, and you will not let anyone else use your account.
        You are solely responsible for all activity on your account and agree
        that you will take reasonable care to safeguard your passwords and
        access to your account from unauthorized users. Neither the Terms nor
        your use of the Services create any agency, partnership, joint venture,
        employment, or franchisee relationship between you and SWC. SWC retains
        the right to close or suspend your account in whole or in part, and to
        terminate your use of its Services at its discretion and without notice.
        You may terminate your account with SWC at your discretion. Any
        outstanding amounts due to SWC related to your account after termination
        will be due at the time of termination.
      </p>

      <h3>11. Posting to the Soul Link Channels</h3>

      <p>
        When you post communications to the Soul Link Channels, you represent
        and warrant that you are in accordance with the terms of service of each
        third-party provider, and well as the terms listed herein. SWC reserves
        the right to terminate your access to the Services and the Soul Link
        Platform, in its sole discretion, if you are found to be in violation of
        the terms set forth herein.
      </p>

      <h3>12. Indemnification; Class Action Waiver</h3>

      <p>
        To the fullest extent permitted by applicable law, you agree to
        indemnify, defend, and hold harmless SWC, its employees, officers,
        directors, contractors, consultants, agents, representatives,
        affiliates, successors, and assigns from and against all actual or
        alleged thirdparty claims, damages, awards, judgments, losses,
        liabilities, obligations, penalties, interest, fees, costs, and expenses
        including without limitation reasonable attorney&rsquo;s fees, accruing
        to SWC as a result of your misuse of the Soul Link Platform, including
        without limitation the Services provided under these Terms . You further
        agree to indemnify, defend, and hold harmless SWC from and against all
        actual and alleged damages to property or personal injury, that are
        caused by, arise out of or are related to (a) your use or misuse of the
        Services, (b) your violation of these Terms, and (c) your violation of
        the rights of a third party, including without limitation another user.
        You agree to promptly notify SWC of any third-party claims and cooperate
        with SWC and its employees, officers, directors, contractors,
        consultants, agents, representatives, affiliates, successors, and
        assigns in defending such claims. You further agree that SWC shall have
        control of the defense or settlement of any third-party claims, in its
        sole discretion.
      </p>

      <p>
        In exchange for SWC&rsquo;s provision of the Services and your payment
        for those Services, you hereby waive the right to bring or participate
        in any class action lawsuit against SWC and its affiliates (which will
        include any party controlling, controlled by or in common control with
        SWC, or any of SWC&rsquo;s employees, directors or officers).
      </p>

      <h3>13. Disclaimer of Warranty</h3>

      <p>
        You will have sole responsibility for conducting your use of the
        Services responsibly and legally. Your use of the Services and any
        information obtained through or from SWC will be at your own risk. The
        Services, and the content contained therein, are provided on an
        &ldquo;AS IS&rdquo; and &ldquo;AS AVAILABLE&rdquo; basis without
        warranties or conditions of any kind, either express or implied. You
        acknowledge and agree that SWC exercises no control over, and accepts no
        responsibility for content other than such content as described herein
        and owned wholly by SWC. SWC MAKES NO WARRANTIES OF ANY KIND, EITHER
        EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION WARRANTIES OF TITLE,
        NON-INFRINGEMENT, MERCHANTABILITY, OR FITNESS FOR A PARTICULAR PURPOSE,
        NOR ANY WARRANTIES IMPLIED BY A COURSE OF PERFORMANCE, COURSE OF
        DEALING, OR USAGE OF TRADE. SWC may exercise no editorial control over
        content posted to the Services. Additionally, SWC will not monitor any
        content that is uploaded to the Soul Link Channels, but reserves the
        right to employ automated processes that will screen for and remove such
        content uploaded to the Soul Link Channels that is in direct violation
        of these Terms set forth herein. SWC will not be liable for any loss of
        any kind from any action taken or taken in reliance on material or
        information, contained on the Services. SWC cannot and does not
        represent or warrant that the Services, content, or our servers are free
        of viruses or other harmful components. We cannot guarantee the security
        of any data that you disclose online. You accept the inherent security
        risks of providing information and dealing online over the internet and
        will not hold SWC responsible for any breach of security unless it is
        due to our gross negligence. SWC makes no warranties that the Services
        will be uninterrupted or errorfree or that any results obtained from the
        use of Services will be accurate or reliable. SWC expressly disclaims
        any liability arising in connection with any content posted to the
        Services. No oral or written information given by SWC will create any
        warranty, nor may you rely on such information or advice.
      </p>

      <h3>14. Limitation of Liability</h3>

      <p>
        To the fullest extent permitted by law, neither SWC, nor its officers,
        directors, employees, shareholders, or agents will be liable for any
        consequential, indirect, incidental, special, or punitive damages, or
        loss of profits, revenue, data, or use by you or any third party,
        whether in an action in contract, tort, strict liability, or other legal
        theory. SWC will not be liable to you or any third party for any loss or
        damages that result or are alleged to have resulted from the use of or
        inability to use the Services, or that result from mistakes, omissions,
        interruptions, deletion of files, loss of data, errors, viruses,
        defects, delays in operations or transmission, or any failure of
        performance, whether or not limited to acts of nature, communications
        failure, theft, destruction, or unauthorized access to SWC&rsquo;s
        records, programs, equipment, or services, or any other condition
        whatsoever.
      </p>

      <p>
        Notwithstanding anything to the contrary in these Terms, SWC&rsquo;s
        maximum liability under these Terms for all damages, losses, costs, and
        causes of actions from any and all claims will not exceed the actual
        dollar amount paid by you for the portion of Services that gave rise to
        such claims during the three (3) month period prior to the date the
        damage or loss occurred or the cause of action arose.
      </p>

      <p>
        The Services may contain links to third-party websites not under
        SWC&rsquo;s control or ownership, or you may elect to use a third
        party&rsquo;s services or product in order to use the Services. Your
        access to all third-party services will be at your own risk.
      </p>

      <h3>15. Copyright, Content, and DMCA Takedown Policy</h3>

      <p>
        You may not submit any content that infringes or violates the personal,
        copyright, or intellectual property rights (including without limitation
        privacy and publicity) of any person or entity. Pursuant to Title 17,
        United States Code, Section 512(c)(2) or for any other claim of
        copyright infringement, you hereby agree that notifications of claimed
        copyright infringement be sent by certified mail to:
      </p>

      <p style={{ marginLeft: "2rem" }}>
        Supernal World Creations, Inc.
        <br />
        3463 Pine Ridge Road #101 Naples, FL 34109
        <br />
        Attention: Copyright Agent
      </p>

      <p>
        For copyright infringement notices, your notice must meet all
        requirements of the Digital Millennium Copyright Act (&ldquo;<u>DMCA</u>
        &rdquo;) (described below). We will only remove content in response to
        DMCA notices that contain all of the following:
      </p>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          a statement that you have identified content on the Services that
          infringes your copyright or the copyright of a third party on whose
          behalf you are entitled to act;
        </li>
        <li>
          a description of the copyright work(s) that you claim have been
          infringed;
        </li>
        <li>
          a description of the content that you claim is infringing and the
          service URL(s) where such content can be located;
        </li>
        <li>
          your full name, address, telephone number, and a valid email address
          through which you can be contacted, and your username if you have one;
        </li>
        <li>
          a statement by you that you have a good faith belief that the disputed
          use of the material is not authorized by the copyright owner, its
          agent, or the law; and
        </li>
        <li>
          a statement by you that the information in your notice is accurate and
          that you are authorized to act on behalf of the owner of the exclusive
          right that is allegedly infringed.
        </li>
      </ol>

      <p>
        In addition, if you wish for your notice to be a notice of alleged
        copyright infringement pursuant to the United States Digital Millennium
        Copyright Act 17 U.S.C. s. 512(c), please also include the following
        information:
      </p>

      <ol style={{ listStyleType: "lower-alpha" }}>
        <li>
          with respect to the statement that you are authorized to act on behalf
          of the owner of the exclusive right that is allegedly infringed,
          confirmation that such statement is made under penalty of perjury; and
        </li>
        <li>
          your electronic or physical signature (which may be a scanned copy).
        </li>
      </ol>

      <p>
        SWC will process any notice of alleged infringement which it receives
        and will take appropriate action as required by the Digital Millennium
        Copyright Act, 17 U.S.C. 512(c)(3) or other applicable copyright law.
        U.S. law provides significant penalties for submitting such a statement
        falsely.
      </p>

      <p>
        In SWC&rsquo;s sole discretion, persons who repeatedly submit infringing
        or unlawful material will be prohibited from accessing the Services. If
        you discover any content that you believe to be in violation of your
        trademark or other intellectual property rights, please report this to
        SWC by mail to the physical address above or at
        Support@SupernalWorldCreations.org. In all other cases, if you discover
        content that infringes any or violates any of your other rights, which
        you believe is defamatory, pornographic, obscene, racist, or otherwise
        liable to cause widespread offense, or which constitutes impersonation,
        harassment, spam, or otherwise violates these Terms or applicable law,
        please report this to SWC by mail to the physical address above or by
        email at Support@SupernalWorldCreations.org.
      </p>

      <h3>16. Governing Law; Arbitration.</h3>

      <p>
        This Agreement will be governed by the laws of the State of Florida,
        without regard to choice of laws provisions, no matter where in the
        world you live. In the event of a dispute arising out of this Agreement,
        the Parties will attempt to resolve any dispute by negotiation between
        the parties, in
      </p>

      <p>
        SWC&rsquo;s sole discretion. If they are unable to resolve the dispute,
        SWC may commence meditation or binding arbitration through the American
        Arbitration Association, or other forum mutually agreed to by the
        Parties. Judgment on the award rendered by the arbitrator may be entered
        into in any court having jurisdiction thereof. Any other disputes
        hereunder will be resolved before the courts of competent jurisdiction
        seated in or proximal to Collier County, Florida. All remedies available
        hereunder will be cumulative and not in lieu of any others.
      </p>

      <h3>17. Assignment</h3>

      <p>
        You do not have the right to assign this Agreement without the prior
        written consent of SWC. SWC may assign this Agreement without notice to
        you. This Agreement will be binding on the parties and their successors
        and permitted assigns.
      </p>

      <h3>18. Entire Agreement; Severability; Survival</h3>

      <p>
        This Agreement represents the entire agreement between the parties, and
        supersedes all previous and contemporaneous representations,
        understandings, and agreements, if any, express or implied, whether
        written or oral. If any provision of this Agreement is held by a court
        of competent jurisdiction to be invalid, unenforceable, or void, the
        remainder of this Agreement will remain in full force and effect.
        Sections hereof that by their nature should survive termination hereof
        will so survive.
      </p>

      <p>
        By accepting these Terms, you are also accepting the SWC Privacy Policy
        and Data Processing Addendum, which is included by reference in these
        Terms of Service. The Data Processing Addendum defines SWC&rsquo;s
        specific legal obligations under the data protection laws of the
        European Union and your agreement with respect to the terms governing
        the processing of your personal data.
      </p>

      <p>
        For customers located in the European Union, your acceptance of the
        Terms of Service, Privacy Policy and the Data Processing Addendum will
        be by separate acknowledgement. You may also request an acknowledgement
        or separate PDF copy of the Terms of Use, Privacy Policy and the Data
        Processing Agreement by contacting our customer support via chat, email,
        or phone, as available.
      </p>
    </>
  );
}

export default TermsOfUse;
