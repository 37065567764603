export const RouteTitles: Record<string, string[]> = {
  "Help Center": [
    "/app/help-center/faq",
    "/app/help-center/support-requests-history",
    "/app/help-center/tutorial",
    "/app/help-center/report-problem",
  ],
  About: [
    "/app/author-details",
    "/app/about-us",
    "/app/contact-us",
    "/app/data-privacy",
    "/app/terms-of-use",
    "/app/terms-and-conditions",
    "/app/accessiblity-policy",
    "/app/supernal-world-creation",
    "/app/about/*",
  ],
  "All Audio": ["app/explore/audio"],
  "All Frequency Waves": ["app/explore/frequencyWave"],
  "All Video": ["app/explore/video"],
  "Audio Files": ["app/my-list/media/audio"],
  Author: [
    //
    "/app/view-author/*",
  ],
  "Frequency Wave Files": ["app/my-list/media/frequencyWave"],
  "My Created Meditations": ["app/my-list/meditation/created-meditation"],
  "My Favorite Meditations": ["app/my-list/meditation/favorite-meditation"],
  "My Recent Meditations": ["app/my-list/meditation/recent-meditation"],
  Profile: [
    "/app/profile",
    "/app/browse-account",
    "/app/my-subscription",
    "/app/daily-inspiration",
    "/app/my-notes",
    "/app/meditation-reminders",
  ],
  Settings: [
    //
    "/app/settings",
  ],
  "Suggested Meditation": ["suggested-meditation"],
  "Video Files": ["app/my-list/media/video"],
  "": [
    //
    "app/file-detail/*",
    "app/meditation-details/*",
  ],
};

export const checkRouteTitles = () => {
  for (const key in RouteTitles) {
    if (RouteTitles[key].some((r) => new RegExp(r).test(location.pathname))) {
      return key;
    }
  }

  return "";
};
