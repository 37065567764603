import { Helmet, HelmetProvider } from "react-helmet-async";

interface Props {
  title: string;
}

const PageTitle = ({ title }: Props) => {
  return (
    <HelmetProvider>
      <Helmet>
        <title>{title ? title + " - Soul Link" : "Soul Link"}</title>
      </Helmet>
    </HelmetProvider>
  );
};

export default PageTitle;
