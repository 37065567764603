import { Box, Container, Grid } from "@mui/material";
import ResetTurorialPopup from "components/helpCenterScreens/tutorial";
import Page from "components/pageTitle";
import {
  HelpOutlined,
  ReportGmailerrorredOutlined,
  SubscriptionsOutlined,
  SupportRequestPurpleIcon,
} from "helpers/enum/constants";
import { useState } from "react";
import LayoutOutlet from "../layoutSidebar";

interface HelpCenterProps {
  title: string;
  children: JSX.Element;
}

const profileLinks = [
  {
    linkIcons: <HelpOutlined />,
    name: "FAQ",
    redirectionLink: "/app/help-center/faq",
  },
  {
    linkIcons: <ReportGmailerrorredOutlined />,
    name: "Report Problem",
    redirectionLink: "/app/help-center/report-problem",
  },
  {
    linkIcons: <SubscriptionsOutlined />,
    name: "Reset Tutorials",
    redirectionLink: "/app/help-center/tutorial",
    popup: "reset-tutorial",
  },
  {
    linkIcons: <Box component="img" src={SupportRequestPurpleIcon} />,
    name: "Support Requests History",
    redirectionLink: "/app/help-center/support-requests-history",
  },
];

const HelpCenterPages = ({ title, children }: HelpCenterProps) => {
  const [popup, setPopup] = useState<string | null>(null);

  return (
    <>
      <Page title={title} className="">
        <Container
          className="page-container"
          sx={{ width: { xl: "93.2%", lg: "95%" } }}
          maxWidth={false}
        >
          <Grid
            container
            sx={{
              display: "flex",
              flexDirection: { xs: "column-reverse", md: "row" },
            }}
          >
            <Grid item xs={12} md={3.3} lg={3.6} xl={3.7}>
              <Box
                sx={{
                  paddingRight: {
                    xl: "100px",
                    lg: "60px",
                    md: "24px",
                    xs: "0px",
                  },
                  position: "sticky",
                  top: "100px",
                  paddingTop: { xs: 3, md: "0px" },
                }}
              >
                <LayoutOutlet
                  redirectionData={profileLinks}
                  handlePopup={(e) => setPopup(e)}
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={8.7} lg={8.4} xl={8.3}>
              {children}
            </Grid>
          </Grid>
        </Container>

        <ResetTurorialPopup
          open={popup === "reset-tutorial"}
          handleClose={() => setPopup(null)}
        />
      </Page>
    </>
  );
};

export default HelpCenterPages;
