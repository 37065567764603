import { Skeleton } from "@mui/material";

export function PillsSkeleton() {
  return (
    <>
      {Array.from({ length: 3 }, () => (
        <Skeleton
          sx={{
            height: "40px !important",
            width: "90px",
            borderRadius: "20px !important",
            transform: "unset",
          }}
        />
      ))}
    </>
  );
}
