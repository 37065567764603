import { Box, Grid, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  SoulBenefitsImage,
  TickWithCircleBorder,
} from "helpers/enum/constants";

const SoulBenefits = () => (
  <>
    <Box>
      <Grid container>
        <Grid item lg={7}>
          <Stack>
            <SLTypography
              variant="heading"
              sx={{
                color: "var(--sl-deep-purple)",
                letterSpacing: "-.3px",
                fontSize: { xs: "19px", sm: "27px" },
              }}
              tabIndex={0}
              role="heading"
            >
              The benefits of Soul Link
            </SLTypography>
            <SLTypography
              tabIndex={0}
              variant="smallBody"
              sx={{
                paddingTop: "15px",
                fontSize: { xs: "17px", sm: "20px" },
                fontWeight: "500",
                color: "var(--sl-deep-purple)",
              }}
            >
              Soul Link brings your mental, physical, spiritual, and emotional
              systems into coherent alignment. This inner alignment helps you
              gain control of your life. It reduces heart rate, lowers blood
              pressure and cortisol in the body. It eases tension and can help
              improve mood. This allows you to approach situations and
              experiences in life with more emotional harmony, compassion,
              clarity, and personal confidence. Getting to know a peaceful, more
              mindful you.
            </SLTypography>
          </Stack>
          <Stack
            sx={{ paddingTop: "32px", paddingRight: "20px" }}
            spacing="21px"
          >
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Box
                component="img"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                alt="/"
                src={TickWithCircleBorder}
              />
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Soul Link for Stress Relief - Soul Link offers a transformative
                experience for stress relief, helping you find calm and balance
                in the midst of life's challenges.
              </SLTypography>
            </Box>
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Box
                component="img"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                alt="/"
                src={TickWithCircleBorder}
              />
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Soul Link for Improved Sleep - Discover a deeper and more
                restful sleep with Soul Link, as it guides you into a state of
                relaxation and rejuvenation.
              </SLTypography>
            </Box>
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Box
                component="img"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                alt="/"
                src={TickWithCircleBorder}
              />
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Soul Link for Pain Relief - Experience relief from pain through
                Soul Link's powerful audio and visual tools, promoting healing
                and well-being.
              </SLTypography>
            </Box>
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Box
                component="img"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                alt="/"
                src={TickWithCircleBorder}
              />
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Soul Link for Inner Peace - Soul Link provides a pathway to
                inner peace, allowing you to cultivate serenity and harmony in
                your mind, body, and soul.
              </SLTypography>
            </Box>
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Box
                component="img"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                alt="/"
                src={TickWithCircleBorder}
              />
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Soul Link for Increased Concentration - Enhance your focus and
                concentration with Soul Link, harnessing the power of sound and
                frequencies to optimize your cognitive abilities.
              </SLTypography>
            </Box>
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Box
                component="img"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                alt="/"
                src={TickWithCircleBorder}
              />
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Soul Link for Health Care - Soul Link offers holistic health
                care support, combining sound healing, visuals, and frequencies
                to nurture your overall well-being.
              </SLTypography>
            </Box>
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Box
                component="img"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                alt="/"
                src={TickWithCircleBorder}
              />
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Soul Link for Cognitive Care - Elevate your cognitive care with
                Soul Link, as it stimulates brain function, supports mental
                clarity, and promotes cognitive enhancement.
              </SLTypography>
            </Box>
            <Box sx={{ display: "flex", gap: "12px" }}>
              <Box
                component="img"
                sx={{
                  height: "24px",
                  width: "24px",
                }}
                alt="/"
                src={TickWithCircleBorder}
              />
              <SLTypography
                tabIndex={0}
                variant="smallBody"
                sx={{ color: "var(--sl-deep-purple)" }}
              >
                Soul Link for Empowering Yourself - Empower yourself with Soul
                Link, a transformative tool that helps you unlock your full
                potential, boost self-confidence, and align with your true
                purpose.
              </SLTypography>
            </Box>
          </Stack>
        </Grid>
        <Grid item lg={1} />
        <Grid
          item
          lg={4}
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: { xs: "30px" },
            backgroundRepeat: "no-repeat",
            borderRadius: "10px",
            background: `url(${SoulBenefitsImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        />
      </Grid>
    </Box>
  </>
);

export default SoulBenefits;
