import { Container } from "@mui/material";
import ExploreHeader from "components/explore/heroHeader";
import FileDetailsComponent from "components/fileDetails";
import { SLLoader } from "components/loader";
import { SLVerificationMessage } from "components/verificationMessage";
import { useAppUrls } from "helpers/url";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import "./index.css";

const MeditationDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const appUrls = useAppUrls();

  // Manage Use States
  const [mediaData, setMediaData] = useState(Object);
  const [cardId, setCardId] = useState<number>();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [dialogMessage, setDialogMessage] = useState<string>("");

  // Manage Use Selectors
  const media = useSelector(SLSelector.getMeditationId);

  // Manage Use Effects
  useEffect(() => {
    if (id) {
      dispatch(SLThunk.getMeditationId.call({ id: id }));
      setCardId(parseInt(id));
    }
  }, [id]);

  useEffect(() => {
    if (media.data) {
      setMediaData(media.data);
      dispatch(SLThunk.getMeditationId.reset());
    }
  }, [media]);

  // Manage Functions
  const handleLikedMediaCard = (text: string) => {
    setOpenDialog(true);
    setDialogMessage(text);
    setTimeout(() => {
      setOpenDialog(false);
      setDialogMessage("");
    }, 1500);
  };

  return (
    <>
      <ExploreHeader staticImage={appUrls.mediaImage(mediaData?.image)} />
      <div className="p-relative main-wrapper">
        <Container
          className="main-container p-relative"
          sx={{ width: { xl: "93.2%", lg: "95%" } }}
          maxWidth={false}
        >
          <FileDetailsComponent
            mediaData={mediaData}
            id={cardId ?? 0}
            likedMediaCard={handleLikedMediaCard}
            typeMedia="Meditation"
          />
        </Container>
      </div>
      <SLLoader open={media?.loading} />
      <SLVerificationMessage
        open={openDialog}
        sx={{ zIndex: 9999 }}
        message={dialogMessage}
      />
    </>
  );
};

export default MeditationDetails;
