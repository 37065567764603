import { Grid, Skeleton } from "@mui/material";

interface ListingSkeletonProps {
  showSkeleton: boolean;
  count: number;
  type: string;
}

const ListingPageSkeleton = ({
  showSkeleton,
  type,
  count,
}: ListingSkeletonProps) => {
  let i = 0;
  return (
    <>
      {Array.from({ length: count }, (_i) =>
        showSkeleton ? (
          <Grid
            key={++i}
            sx={{
              height: type === "listing" ? "100px" : "230px",
              width: type === "listing" ? "100%" : "143px",
            }}
          >
            <Skeleton
              sx={{
                transform: type === "card" ? "none" : "scale(1, 0.60)",
                height: type === "listing" ? "150px" : "230px",
                width: type === "listing" ? "100%" : "150px",
                borderRadius: "10px",
              }}
            />
          </Grid>
        ) : null
      )}
    </>
  );
};

export default ListingPageSkeleton;
