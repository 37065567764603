import {
  Avatar,
  Box,
  Card,
  CardMedia,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { MediaDetailPages, MediaType } from "helpers/enum";
import {
  CircleTimeToggle,
  CircleTimeToggleWhite,
  SoulPurpleIcon,
} from "helpers/enum/constants";
import { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { getIdealPageSize } from "utils/getIdealPageSize";
import ListingPageSkeleton from "../listingSkeleton";
import MediationCard from "../mediationCard";
import { NotFoundMessage } from "../slTypography/NotFoundMessage";
import "./index.css";

const pageSize = getIdealPageSize();

const styles = {
  media: {
    width: "60px",
    height: "60px",
  },
  card: {
    position: "relative",
    width: "60px",
    height: "60px",
    background: "transparent",
    outline: "none",
    boxShadow: "none",
  },
  overlay: {
    position: "absolute",
    top: "20px",
    left: "20px",
    color: "black",
    backgroundColor: "white",
  },
};

interface IMediaDetail {
  handleAlignment: any;
  mediaType: MediaType;
  durationTimeValue: any;
  handleToggleBtn: any;
  tagValue: any;
  tagArray: any;
  loading: boolean;
  mediaData: any;
  likedMediaCard: (text: string) => void;
  whichPage: string;
  updateMediaData?: any;
  searchValue?: string;
  searchPlaceHolder: string;
  loadMoreCards?: any;
  cardTyped?: any;
  willShowLoader?: boolean;
  imageToggle?: boolean;
}

const MediaDetail = ({
  handleAlignment,
  handleToggleBtn,
  tagValue,
  mediaType,
  durationTimeValue,
  tagArray,
  loading,
  mediaData,
  likedMediaCard,
  whichPage,
  updateMediaData,
  loadMoreCards,
  willShowLoader,
  cardTyped,
  imageToggle,
  searchValue,
  searchPlaceHolder,
}: IMediaDetail) => {
  const [hasMore, setHasMore] = useState(true);
  const [itemLength, setItemLength] = useState<number>(pageSize);
  const [mediaValue, setMediaValue] = useState<any>(null);
  const [ariaLive, setAriaLive] = useState<string | null>(null);
  const [fetchMore, setFetchMore] = useState<boolean>(false);
  const [showCustomLoader, setCustomLoader] = useState<boolean>(false);
  const [pageNo, setPageNo] = useState<number>(1);
  const unFavouriteCardsFilter = (id: number) => {
    updateMediaData(id);
  };
  const fetchMoreData = () => {
    setFetchMore(true);
  };
  useEffect(() => {
    if (!willShowLoader && fetchMore) {
      setFetchMore(false);
      setPageNo(pageNo + 1);
      setItemLength(itemLength + pageSize);
    }
  }, [willShowLoader, fetchMore]);

  useEffect(() => {
    //if (searchValue) {
    setItemLength(pageSize);
    setHasMore(true);
    setPageNo(1);
    // }
  }, [searchValue]);

  useEffect(() => {
    setHasMore(true);
    setPageNo(1);
  }, [tagValue]);

  useEffect(() => {
    if (pageNo && pageNo > 1 && mediaValue) {
      if (mediaValue.length >= mediaData?.totalCount) {
        setHasMore(false);
        setCustomLoader(false);
      } else {
        setCustomLoader(true);
        setHasMore(true);
        loadMoreCards(pageSize, pageNo);
      }
    }
  }, [pageNo]);

  useEffect(() => {
    if (!willShowLoader) {
      setCustomLoader(false);
    }
  }, [willShowLoader]);
  useEffect(() => {
    if (durationTimeValue) {
      if (pageNo !== 1) {
        setPageNo(1);
        setMediaValue(null);
        setItemLength(pageSize);
        setHasMore(true);
      }
    }
  }, [durationTimeValue]);

  // useEffect(() => {
  //   if (loading && mediaData?.data) {
  //     if (mediaData?.totalCount < pageSize) {
  //       setLoadingSkeleton(false);
  //     } else {
  //       setLoadingSkeleton(willShowLoader ?? loading);
  //     }
  //   }
  // }, [loading, mediaData, willShowLoader]);

  useEffect(() => {
    if (mediaData) {
      if (pageNo > 1 && mediaValue) {
        const newData = mediaData.data;
        const LoadpreData = mediaValue;
        const newMergedData = [...LoadpreData, ...newData];
        setMediaValue(newMergedData);
      } else {
        setMediaValue(mediaData?.data);
      }

      setAriaLive(
        Number(mediaData?.totalCount) === 0
          ? `No ${searchPlaceHolder} Found`
          : `${Number(mediaData?.totalCount)} ${searchPlaceHolder}${
              mediaData?.totalCount === 1 ? "" : "s"
            } found`
      );
    }
  }, [mediaData]);

  useEffect(() => {
    if (ariaLive) {
      setTimeout(() => {
        setAriaLive(null);
      }, 5000);
    }
  }, [ariaLive]);

  return (
    <>
      <Box
        sx={{
          marginTop: whichPage == MediaDetailPages.Explore ? "30px" : "0px",
        }}
      >
        <Stack spacing="20px">
          {mediaType !== MediaType.FrequencyWave ? (
            <ToggleButtonGroup
              className="aqua-toggle"
              exclusive
              value={durationTimeValue}
              onChange={handleAlignment}
              sx={{ gap: 1 }}
            >
              {["Any Time", "5 min", "12 min", "22 min"].map(
                (value: string) => {
                  const [a, b] = value.split(" ");
                  return (
                    <ToggleButton tabIndex={0} value={value}>
                      <Card sx={styles.card}>
                        <CardMedia
                          image={
                            !imageToggle
                              ? CircleTimeToggle
                              : CircleTimeToggleWhite
                          }
                          style={styles.media}
                          className="media-image"
                        />
                        <SLTypography
                          variant="extraSmallBody"
                          sx={{
                            position: "absolute",
                            flexDirection: "column",
                            top: 0,
                            display: "flex",
                            textAlign: "center",
                            alignItems: "center",
                            justifyContent: "center",
                            left: "10px",
                            right: "10px",
                            height: "100%",
                            textTransform: "capitalize",
                            color: "var(--sl-aqua)",
                          }}
                        >
                          {a === "Any" ? (
                            <>
                              <span>{a}</span>
                            </>
                          ) : (
                            <span className="number-style-change">{a}</span>
                          )}
                          {b === "Time" ? (
                            <span>{b}</span>
                          ) : (
                            <span className="min-value">{b}</span>
                          )}
                        </SLTypography>
                      </Card>
                    </ToggleButton>
                  );
                }
              )}
            </ToggleButtonGroup>
          ) : null}

          <ToggleButtonGroup
            className="deeppurple-toggle joyride-target-toggle"
            value={tagValue}
            disabled={mediaData === null}
            onChange={handleToggleBtn}
            exclusive
            sx={{
              gap: 1,
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
            }}
          >
            {tagArray?.map((i: any) => (
              <ToggleButton
                key={"artist_catid" + i.id}
                value={i.title}
                aria-label={i.title}
                className="toggleBtn"
              >
                <SLTypography variant="extraSmallBody">{i.title}</SLTypography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>

          <Stack role="list" className="meditationListingContainer">
            {!loading ? (
              <InfiniteScroll
                dataLength={itemLength}
                next={fetchMoreData}
                hasMore={hasMore}
                scrollThreshold={-500}
                loader={
                  showCustomLoader && pageNo > 1 ? (
                    <Box className="loaderContainerListing">
                      <Box className="loaderContainerListing2">
                        <Avatar
                          className="rotate"
                          alt="Loading Image"
                          src={SoulPurpleIcon}
                          sx={{ width: 120, height: 120, margin: "auto" }}
                        />
                      </Box>
                    </Box>
                  ) : null
                }
              >
                {mediaValue?.map((res: any) => (
                  <MediationCard
                    selectedCardData={res}
                    cardType={cardTyped}
                    likedMediaCard={likedMediaCard}
                    filterUnFavouriteMediaItem={unFavouriteCardsFilter}
                  />
                ))}
                {mediaValue?.length === 0 && (
                  <NotFoundMessage
                    notFoundMessage={`No 
                    ${
                      String(searchPlaceHolder)[0]?.toUpperCase() +
                      String(searchPlaceHolder).slice(1)
                    }
                     Found`}
                  />
                )}
              </InfiniteScroll>
            ) : (
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "15px",
                  overflow: "hidden",
                  marginRight: "0px",
                  flexWrap: "wrap",
                }}
              >
                <ListingPageSkeleton
                  showSkeleton={loading}
                  type="card"
                  count={8}
                />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>

      <div
        aria-live="assertive"
        style={{ height: 0, width: 0, overflow: "hidden" }}
        dangerouslySetInnerHTML={{ __html: ariaLive || "" }}
      />
    </>
  );
};

export default MediaDetail;
