import { Box, ButtonProps, CircularProgress, IconButton } from "@mui/material";
import clsx from "clsx";

type ISLRoundButton = ButtonProps & {
  icon?: React.ElementType<any> | string;
  width?: string;
  loading?: boolean;
};

export function SLRoundButton(props: ISLRoundButton) {
  const size = props.width || "40px";

  return (
    <IconButton
      {...props}
      sx={{
        width: size,
        height: size,
        flexShrink: 0,
        flexGrow: 0,
        minWidth: "unset",
        ...(props?.sx || {}),
      }}
      className={clsx("sl-round-button", props.className)}
    >
      {props?.loading ? (
        <CircularProgress size="30px" />
      ) : typeof props?.icon === "string" ? (
        <Box
          component="img"
          src={props.icon}
          aria-hidden={true}
          sx={{ pointerEvents: "none" }}
        />
      ) : props.icon ? (
        <props.icon />
      ) : null}
    </IconButton>
  );
}
