import EmailIcon from "@mui/icons-material/Email";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkIcon from "@mui/icons-material/Link";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { SLTypography } from "components/slTypography";
import { SLVerificationMessage } from "components/verificationMessage";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { webShareClose, webShareState } from "store/webShareSlice";

const IconSizeSx = {
  height: { xs: "50px", sm: "35px" },
  width: { xs: "50px", sm: "35px" },
};

export const RWebShare = () => {
  const dispatch = useDispatch();
  const { show, title, text, url } = useSelector(webShareState);
  const [copiedSucessfully, setCopiedSuccessfully] = useState(false);

  const copyText = `${title}\n${text}`
    .trim()
    .replace(/(\.+ )+/, ". ")
    .replace(/[\s\t]+/g, " ")
    .replace(/\. \./, ". ")
    .trim();

  const copyPlainText = `${copyText}\n\n${url}`.trim();

  const copyMessage = `${text}`
    .trim()
    .replace(/(\.+ )+/, ". ")
    .replace(/[\s\t]+/g, " ")
    .replace(/\. \./, ". ")
    .concat(`\n\n${url}`)
    .trim();

  const copyLink = url || copyPlainText;

  const close = () => dispatch(webShareClose());

  return (
    <>
      <Dialog
        open={Boolean(show)}
        // open={true}
        className="backdrop-blur"
        onClose={close}
        aria-labelledby="sl-dialog-title"
        aria-describedby="sl-dialog-description"
        PaperProps={{ sx: { margin: 2 } }}
      >
        <Stack
          direction="column"
          gap={2}
          sx={{
            background: "var(--bg-gradient)",
            padding: { xs: 2, sm: 3 },
            paddingBottom: 1,
            width: { xs: "280px", sm: "340px" },
            boxSizing: "border-box",
            borderRadius: "10px",
          }}
        >
          <SLTypography
            tabIndex={0}
            variant="subhead"
            sx={{
              textAlign: "center",
              fontSize: { xs: "17px", sm: "19px" },
              lineHeight: { xs: "20px", sm: "23px" },
              fontWeight: 700,
            }}
          >
            Share
          </SLTypography>
          <Grid gap={1} display="flex" flexWrap="wrap" justifyContent="center">
            <IconButton
              color="secondary"
              title="Share on Whatsapp"
              onClick={() => {
                window.open(
                  `https://api.whatsapp.com/send?phone=&text=${encodeURIComponent(
                    copyPlainText
                  )}`,
                  "_blank"
                );
                close();
              }}
            >
              <WhatsAppIcon color="secondary" sx={IconSizeSx} />
            </IconButton>

            <IconButton
              color="secondary"
              title="Share on Twitter"
              onClick={() => {
                window.open(
                  `https://twitter.com/intent/tweet?${[
                    url ? "url=" + encodeURIComponent(url) : "",
                    copyText ? "text=" + encodeURIComponent(copyText) : "",
                  ]
                    .filter((r) => !!r)
                    .join("&")}`,
                  "_blank"
                );
                close();
              }}
            >
              <TwitterIcon color="secondary" sx={IconSizeSx} />
            </IconButton>

            <IconButton
              color="secondary"
              title="Share on Email"
              onClick={() => {
                window.open(
                  `mailto:?${[
                    title ? "subject=" + encodeURIComponent(title) : "",
                    copyMessage
                      ? "body=" + encodeURIComponent(copyMessage)
                      : "",
                  ]
                    .filter((r) => !!r)
                    .join("&")}`,
                  "_blank"
                );
                close();
              }}
            >
              <EmailIcon color="secondary" sx={IconSizeSx} />
            </IconButton>

            <IconButton
              color="secondary"
              title="Copy to clipboard"
              onClick={() => {
                if (window.navigator.clipboard) {
                  window.navigator.clipboard.writeText(copyLink);
                  setTimeout(() => {
                    setCopiedSuccessfully(true);
                    setTimeout(() => setCopiedSuccessfully(false), 2000);
                  }, 200);
                }
                close();
              }}
            >
              <LinkIcon color="secondary" sx={IconSizeSx} />
            </IconButton>

            {!url ? null : (
              <IconButton
                color="secondary"
                title="Share on Facebook"
                onClick={() => {
                  window.open(
                    `https://facebook.com/sharer/sharer.php?${[
                      url ? "u=" + encodeURIComponent(url) : "",
                    ]
                      .filter((r) => !!r)
                      .join("&")}`,
                    "_blank"
                  );
                  close();
                }}
              >
                <FacebookIcon color="secondary" sx={IconSizeSx} />
              </IconButton>
            )}
          </Grid>
        </Stack>
      </Dialog>
      <SLVerificationMessage open={copiedSucessfully} message="Link Copied!" />
    </>
  );
};
