import { PageHeading } from "components/pageHeading";
import MyJournalScreen from "components/profile/notes";
import { lang } from "helpers/common/lang";
import ProfilePages from "layouts/profilePages";
import React from "react";

const MyJournalPage: React.FC = () => {
  return (
    <>
      <ProfilePages title={lang("app.myNotes")}>
        <>
          <PageHeading messageId="app.myNotes" />

          <MyJournalScreen />
        </>
      </ProfilePages>
    </>
  );
};

export default MyJournalPage;
