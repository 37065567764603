import { Box, Grid, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { AboutUsLogo } from "helpers/enum/constants";

const AboutUsHead = () => {
  return (
    <>
      <Grid container>
        <Grid item lg={12} sx={{ paddingTop: { xs: "20px", sm: "0px" } }}>
          <SLTypography
            tabIndex={0}
            role="heading"
            variant="largeHeader"
            sx={{ fontSize: { xs: "19px", sm: "21px", md: "37px" } }}
          >
            About Us
          </SLTypography>
        </Grid>
        <Grid item lg={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            spacing={{ lg: "25px", md: "25px", sm: "20px", xs: "20px" }}
            sx={{
              paddingTop: { xs: "20px", sm: "25px", md: "40px" },
              alignItems: "center",
            }}
          >
            <Box
              component="img"
              src={AboutUsLogo}
              sx={{ width: "120px" }}
              alt="soul link app logo"
              tabIndex={0}
            />
            <SLTypography
              tabIndex={0}
              variant="homeParagraphText"
              sx={{
                fontSize: { xl: "21px", sm: "18px", xs: "19px" },
                fontWeight: { xl: "500", sm: "600" },
                textAlign: { xl: "left", sm: "center" },
                marginTop: "10px",
              }}
            >
              Soul Link revolutionizes transcendence by bringing it directly to
              your fingertips. With its unique blend of sound healing, immersive
              visuals, and focus-enhancing frequencies, Soul Link empowers you
              to unlock your peak potential, achieve lasting peace, happiness,
              and harmony, taking your meditation experience to new heights.
            </SLTypography>
          </Stack>
        </Grid>
      </Grid>
    </>
  );
};

export default AboutUsHead;
