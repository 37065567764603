import { Box } from "@mui/material";

export function HeaderTopGradient() {
  return (
    <>
      {[
        "/app/home",
        "/app/explore",
        "/app/meditation/create",
        "/app/how-it-works",
        "/app/my-list",
      ].includes(location.pathname) ||
      location.pathname.startsWith("/app/file-detail") ||
      location.pathname.startsWith("/app/meditation-details") ? (
        <Box
          className="header-top-gradient"
          sx={{
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.60) 100%)",
            height: { lg: "180px", xs: "130px" },
            zIndex: 1,
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
          }}
        />
      ) : null}
    </>
  );
}
