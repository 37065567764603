import { Grid } from "@mui/material";
import LovingAndKindness from "components/popUps/lovingKindnessListSection";
import Notes from "components/popUps/notes";
import ChangePassword from "components/popUps/resetPassword";
import SetUpAchievement from "components/popUps/setUpAcheivement";
import MeditationTimer from "components/popUps/timer";
import VideoQualityPopUp from "components/popUps/videoQuality";
// import Notes from "components/popUps/notes";

const PopUp = () => {
  return (
    <>
      {/* <Header /> */}
      <Grid container>
        <Grid item xs={12} md={12} lg={12}>
          {/* <MeditaionSummaryPopUp /> */}
        </Grid>
        <Grid item xs={12} md={12} lg={9} mt="100px">
          <VideoQualityPopUp />
        </Grid>
        <Grid item xs={12} md={12} lg={12} mt="100px">
          <MeditationTimer seconds={60} />
        </Grid>

        <Grid item xs={12} md={12} lg={12} mt="100px">
          <LovingAndKindness />
        </Grid>
        <Grid item xs={12} md={12} lg={12} mt="100px">
          <ChangePassword />
        </Grid>
        <Grid item xs={12} md={12} lg={12} mt="100px">
          <SetUpAchievement />
          <Notes />
        </Grid>
      </Grid>
    </>
  );
};

export default PopUp;
