import ListScreen from "components/list/parentList";
import Page from "components/pageTitle";
import React from "react";
import { Step } from "react-joyride";

interface State {
  run: boolean;
  steps: Step[];
}
const MyList: React.FC = () => {
  return (
    <Page className="" title="My List">
      <ListScreen />
    </Page>
  );
};

export default MyList;
