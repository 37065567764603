import { Box } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { useState } from "react";
import Slider from "react-slick";
import { meditationSlickSettings } from "utils/meditationSlickSettings";
import MediationCard from "../../mediationCard";
import "./index.css";

interface MediaSectionProps {
  name: string;
  data: any;
  sliderRef: any;
  setSliderRef: any;
  addedToFavourites?: any;
  successMessageText?: any;
}

const MediaSection = ({
  name,
  data,
  sliderRef,
  setSliderRef,
  addedToFavourites,
  successMessageText,
  ...props
}: MediaSectionProps) => {
  //Manage States

  const [pageNo, setPageNo] = useState(1);

  // Manage Functions
  const handleLikedMediaCard = (text: string) => {
    addedToFavourites(true);
    successMessageText(text);
    setTimeout(() => {
      addedToFavourites(false);
    }, 1500);
  };

  const nextClick = (currentSlide: any) => {
    const elementSelect = document.querySelector(".slick-next.slick-disabled");
    if (elementSelect?.matches(".slick-disabled")) {
      if (data?.length < 0) {
        setPageNo(pageNo + 1);
      }
    }
  };
  const settings = meditationSlickSettings(nextClick);

  return (
    <>
      <Box
        sx={{
          paddingLeft: { lg: "0px", md: "0px", xs: "18px" },
          paddingRight: { lg: "0px", md: "0px", xs: "12px" },
        }}
        {...props}
        className="media-audio"
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            paddingTop: "50px",
            paddingBottom: "25px",
          }}
        >
          <SLTypography
            tabIndex={0}
            role="heading"
            variant="heading"
            sx={{ color: "var(--sl-deep-purple)" }}
            letterSpacing={0}
          >
            {name}
          </SLTypography>
        </Box>
        <Slider
          ref={setSliderRef}
          {...settings}
          className="meditationSlider myCreatedMeditation"
        >
          {data.map((data: any) => (
            <div>
              <MediationCard
                likedMediaCard={handleLikedMediaCard}
                cardType="Media"
                selectedCardData={data}
                cardSelection="authorDetail"
              />
            </div>
          ))}
        </Slider>
      </Box>
    </>
  );
};

export default MediaSection;
