import { Box, CircularProgress, Dialog } from "@mui/material";
import { makeStyles } from "@mui/styles";
import AudioControls from "components/audioControls";
import StopCastingDialog from "components/dialogBoxes/stopCastingDialog";
import MediaSummaryPopUp from "components/popUps/mediaSummery";
import { IQualityLevel } from "components/videoControls/QualitySelector";
import { MiniplayerContext } from "contexts/miniplayerContext";
import { convertSecondsToTime } from "helpers/common/time";
import Hls from "hls.js";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import screenfull from "screenfull";
import {
  videoControlsActions,
  videoControlsState,
} from "store/videoControlsSlice";
import { useCjs } from "utils/cjs/useCjs";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLMediaFileDetail } from "utils/slRTKHelper/slAPI.models";

let hls: Hls | undefined;
let cjsAttached = false;
interface IAudioPlayerScreen {
  mediaData: SLMediaFileDetail;
  mediaType: string;
  playBackUrl: string;
  playBackThumbnail: string;
  dynamicLinkUrlResponse: string;
}
export interface ICastingState {
  available: boolean;
  device: string;
  state: string;
}
export interface ReactControlerProps {
  playing: boolean;
  muted: boolean;
  duration: number;
  volume: number;
  buffer: boolean;
  captions: boolean;
  loop: boolean;
}

export const AudioPlayerScreen = (props: IAudioPlayerScreen) => {
  const { meditationSession } = useContext(MiniplayerContext);
  const cjs = useCjs();
  const mixpanel = useMixpanel();
  const dispatch = useDispatch();
  const [interactionTime, setInteractionTime] = useState<number>(Date.now());
  const videoControls = useSelector(videoControlsState);
  const [timeDisplayFormat, setTimeDisplayFormat] = React.useState("normal");
  const [hslInit, setHslInit] = useState(false);
  const [state, setState] = useState<ReactControlerProps>({
    playing: false,
    muted: false,
    loop: false,
    duration: 0,
    volume: videoControls?.playerVolume ?? 0.5,
    buffer: true,
    captions: false,
  });
  const [isFullScreen, setIsFullScreen] = useState(false);
  const videoRef = useRef<HTMLVideoElement>(null);
  const controlsRef = useRef<HTMLDivElement | null>(null);
  const videoErrorTimeout = useRef<any>();
  const fullscreenRef = useRef<any>();
  const mediaPlayed = useRef({
    start: 0,
    end: 0,
  });
  const mediaPlayedFromBuild = sessionStorage.getItem("fromBuildMeditation");
  const onDetailPage = sessionStorage.getItem("onDetailPage");
  const [videoCompletion, openCompletionPopup] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>("");
  const [qualityLevels, setQualityLevels] = useState<IQualityLevel[]>([]);
  const [selectedQualityLevel, setSelectedQualityLevel] = useState<number>(-1);
  const [castConnected, setCastConnected] = useState<boolean>(false);
  const [castingAvailable, setCastingAvailable] = useState<boolean>(false);
  const [captionsAvailable, setCaptionsAvailable] = useState<boolean>(false);
  const [stopCastingDialog, setStopCastingDialog] = useState<boolean>(false);
  const castingTime = useRef<number>(0);
  const [casting, setCasting] = useState<ICastingState>({
    available: false,
    device: "",
    state: "",
  });
  const hlsStarting = useRef(false);
  const hlsError = useRef(false);
  const castStarting = useRef(false);
  const [currentQualityLevel, setCurrentQualityLevel] = useState<number>(-1);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [currentTimePretty, setCurrentTimePretty] = useState("00:00");
  const [durationPretty, setDurationPretty] = useState("00:00");
  const [completion, setCompletion] = useState(0);
  const handleVideoTime = () => {
    if (videoRef.current) {
      const ct = videoRef.current.currentTime || 0;
      setCurrentTimePretty(convertSecondsToTime(ct));

      let c = 0;

      if (!duration) {
        const d = videoRef.current.duration || 0;
        c = ct / d;
        setDuration(d);
        setDurationPretty(convertSecondsToTime(d));
      } else {
        c = ct / duration;
      }

      setCurrentTime(ct);
      c = Math.floor(isNaN(c) ? 0 : c * 100) / 100;
      setCompletion(c);
    }
  };
  const useStyles = makeStyles(() => ({
    fullScreenWrapper: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "100%",
    },
    playerWrapper: {
      width: "100%",
      position: "relative",
      aspectRatio: "16/9",
      overflow: "hidden",
    },
  }));
  const classes = useStyles();
  const handleVideoError = (e: any) => {
    if (videoRef.current) {
      const videoUrl = videoRef.current.src;
      const currentTime = Number(videoRef.current.currentTime);

      if (videoErrorTimeout.current) {
        clearTimeout(videoErrorTimeout.current);
      }

      videoErrorTimeout.current = setTimeout(() => {
        if (videoUrl && videoRef.current) {
          videoRef.current.src = ""; // Reset the video source
          videoRef.current.src = videoUrl; // Set the source URL again
          videoRef.current.load(); // Reload the video
          videoRef.current.currentTime = Math.floor(currentTime);
          videoRef.current.play(); // Resume playback
        }
      }, 2000);
    }
  };

  const handleVideoBuffering = () => {
    if (videoRef.current) {
      setState((prv) => ({
        ...prv,
        buffer: true,
      }));
    }
  };

  function handleVideoPlaying() {
    if (videoRef.current) {
      const playing = !videoRef.current.paused;
      setTimeout(() => {
        setState((prv) => ({ ...prv, playing, buffer: false }));
      });
    }
  }
  const handleCastEvent = (e?: string) => {
    // if (["volumechange" /* "mute", "unmute" */].includes(e || "")) {
    //   handleCastVolume();
    // } else if ("timeupdate" === e) {
    //   handleCastTime();
    // }
  };

  const checkCastingAvailable = () => {
    if (cjs) {
      const available = cjs.available;
      setCastingAvailable(available);

      return true;
    } else {
      // console.warn("Cjs not available");

      return false;
    }
  };

  function attachCjs() {
    if (checkCastingAvailable()) {
      if (!cjsAttached) {
        // console.warn("ATTACH CJS EVENTS");
        cjs.on("available", handleCastAvailable); // Casting is available
        cjs.on("connect", handleCastConnection); // Connected with device
        cjs.on("disconnect", handleCastDisconnection); // Disconnected with device
        cjs.on("timeupdate", handleCastTime); // Current time changed
        cjs.on("volumechange", handleCastVolume); // Volume changed
        cjs.on("mute", handleCastMute); // Muted state changed
        cjs.on("unmute", handleCastUnmute); // Muted state changed
        cjs.on("playing", handleCastPlaying); // Media is playing
        cjs.on("pause", handleCastPaused); // Media is paused
        cjs.on("end", handleCastCompletion); // Media ended
        cjs.on("buffering", handleCastBuffering); // Media is buffering / seeking
        // cjs.on("event", console.warn); // Catch all events except 'error'
        // cjs.on("error", console.error); // Catch any errors

        cjsAttached = true;
      } else {
        // console.error("cjs already attached");
      }
    } else {
      // console.error("no cjs 1");
    }
  }

  function destroyCjs() {
    if (cjsAttached) {
      setCastConnected(false);
      // console.warn("DETTACH CJS EVENTS");

      cjs.off("available", handleCastAvailable);
      cjs.off("connect", handleCastConnection);
      cjs.off("disconnect", handleCastDisconnection);
      cjs.off("timeupdate", handleCastTime);
      cjs.off("volumechange", handleCastVolume);
      cjs.off("mute", handleCastMute);
      cjs.off("unmute", handleCastUnmute);
      cjs.off("playing", handleCastPlaying);
      cjs.off("pause", handleCastPaused);
      cjs.off("end", handleCastCompletion);
      cjs.off("buffering", handleCastBuffering);
      // cjs.off("event", console.warn);
      // cjs.off("error", console.error);
      cjs.disconnect();

      cjsAttached = false;
    } else {
      setCastConnected(false);
      cjsAttached = false;
      try {
        cjs.disconnect();
      } catch (error) {}
      // console.error("no cjs 2");
    }
  }

  const handleCastConnect = () => {
    if (checkCastingAvailable()) {
      if (cjs.connected) {
        setStopCastingDialog(true);
        return;
      } else {
        castingTime.current = currentTime;
        castStarting.current = true;
        hlsStarting.current = false;
        attachCjs();

        cjs.cast(props.playBackUrl, {
          title: props.mediaData.title,
          description: props.mediaData.description,
          poster: props.playBackThumbnail,
        });
      }
    }
  };

  const handleCastConnection = () => {
    setCastConnected(true);

    destroyHls();
    setCasting((prv) => ({ ...prv, device: cjs.device }));
    //showControls();

    setTimeout(() => {
      cjs.volume(0.1);
      cjs.unmute();
      handleCastVolume();
    });
  };

  const handleCastDisconnection = () => {
    castingTime.current = cjs.time;
    hlsStarting.current = true;
    castStarting.current = false;
    setCasting((prv) => ({ ...prv, device: "" }));
    destroyCjs();
    // console.warn(`🚀 > handleCastDisconnection`);
    // handlePause();
  };

  const handleCastAvailable = () => {
    setCastingAvailable(cjs.available);
  };

  const handleCastCompletion = () => {
    if (cjs.connected) {
      // handleCompleteMeditation();
    }
  };

  const handleCastPaused = () => {
    if (cjs.connected) {
      handlePause();
    }
  };

  const handleCastBuffering = () => {
    if (cjs.connected) {
      setState((prv) => ({
        ...prv,
        buffer: true,
      }));
    }
  };

  const handleCastPlaying = () => {
    if (cjs.connected) {
      if (castingTime.current) {
        cjs.seek(castingTime.current);
        castingTime.current = 0;
      }
      hlsStarting.current = false;
      castStarting.current = false;

      handleResume();
      setState((prv) => ({ ...prv, buffer: false }));
      handleCastMute();
    }
  };

  const handleCastMute = () => {
    if (cjs.connected) {
      const muted = cjs.muted;

      setState((prv) => ({ ...prv, muted }));
    }
  };

  const handleCastUnmute = () => {
    if (cjs.connected) {
      const muted = cjs.muted;
      const volume = !cjs.muted && !cjs.volumeLevel ? 10 : cjs.volumeLevel;

      setState((prv) => ({ ...prv, muted, volume }));
    }
  };

  const handleCastVolume = () => {
    if (cjs.connected) {
      const volume = cjs.volumeLevel;
      const muted = cjs.muted;

      setState((prv) => ({ ...prv, volume, muted }));
    }
  };

  const handleStopCasting = () => {
    handleCastDisconnection();
    setStopCastingDialog(false);
  };

  const handleKeepOnCasting = () => {
    setStopCastingDialog(false);
  };

  const handleCastTime = () => {
    if (cjs.connected) {
      setCurrentTime(cjs.time);
      setCurrentTimePretty(convertSecondsToTime(cjs.time));
      setDuration(cjs.duration);
      setDurationPretty(convertSecondsToTime(cjs.duration));

      let c = cjs.time / cjs.duration;
      c = Math.floor(isNaN(c) ? 0 : c * 100) / 100;

      setCompletion(c);
    }
  };
  useEffect(() => {
    if (videoRef.current) {
      if (state.playing) {
        mediaPlayed.current.start = Math.floor(videoRef.current.currentTime);
        videoRef.current.play().catch((e) => {
          // console.error(`Playing failed`, e);
          setState((prv) => ({ ...prv, playing: false }));
        });
      } else {
        videoRef.current.pause();
        mixpanelWatched(videoRef.current.currentTime);
      }
    }
  }, [state.playing]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.volume = state.volume;
    }
  }, [state.volume]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.muted = state.muted;
    }
  }, [state.muted]);

  const handleVideoVolume = () => {
    if (videoRef.current) {
      setState((prv) => ({
        ...prv,
        muted: videoRef.current?.muted || false,
        volume: videoRef.current?.volume || 0,
      }));
    }
  };

  useEffect(() => {
    if (hls) {
      hls.nextLevel = selectedQualityLevel;
    }
  }, [selectedQualityLevel]);

  const hanldeClosedCaptions = () => {
    if (captionsAvailable && hls) {
      const captionState = !state.captions;

      setState((prv) => ({ ...prv, captions: captionState }));

      hls.subtitleDisplay = captionState;
      dispatch(videoControlsActions.subtitleToggle(captionState));
    }
  };

  // useEffect(() => {
  //   return () => {
  //     removeVideoListeners();
  //     removeVideoListenersSafari();
  //     destroyHls();
  //   };
  // }, []);

  useEffect(() => {
    if (videoRef.current && hslInit) {
      if (props.mediaType === "video") {
        videoRef.current.muted = true;
      }

      videoRef.current
        .play()
        .then(() => {
          const newState: any = { playing: true };

          if (hls) {
            setQualityLevels(hls?.levels || []);

            setCaptionsAvailable(hls?.subtitleTracks?.length > 0);

            newState.captions = videoControls?.subtitleToggle;
          }

          setState((prv) => ({ ...prv, ...newState }));
        })
        .catch((e) => {
          setState((prv) => ({ ...prv, playing: false }));
        });
    }
  }, [hslInit]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.disablePictureInPicture = true;
      const isiPhone = /iPhone/i.test(navigator.userAgent);
      if (isiPhone) {
        //(videoRef.current.canPlayType("application/vnd.apple.mpegurl")) {
        videoRef.current.autoplay = false;
        setState((prv) => ({ ...prv, buffer: false, playing: true }));

        attachVideoListeners();
        attachVideoListenersSafari();

        // For Safari
        videoRef.current.src = props.playBackUrl;
      } else if (Hls.isSupported()) {
        attachHls();
      }
    }
  }, []);
  useEffect(() => {
    const isiPhone = /iPhone/i.test(navigator.userAgent);

    if (!isiPhone) {
      userInteracted();

      if (isFullScreen) {
        screenfull.request(fullscreenRef.current || undefined);
        if (fullscreenRef.current) {
          fullscreenRef.current.style.backgroundColor = "black";
        }
      } else {
        if (fullscreenRef.current) {
          fullscreenRef.current.style.backgroundColor = "";
        }
        screenfull.exit();
      }
    }
  }, [isFullScreen]);

  function attachHls() {
    if (videoRef.current) {
      if (hls) {
        // console.warn(`🚀 > attachHls > ALREADY:`, hls);
      } else {
        attachVideoListeners();

        hls = new Hls({
          enableWorker: true,
          lowLatencyMode: true,
          capLevelToPlayerSize: true,
          maxMaxBufferLength: 15,
          abrEwmaDefaultEstimate: 5000000,
          startLevel: selectedQualityLevel,
        });
        hls.subtitleDisplay = videoControls?.subtitleToggle;
        /* eslint-disable indent */
        hls.on(Hls.Events.ERROR, function (event, data) {
          if (navigator.onLine) {
            if (hls && data.fatal) {
              switch (data.type) {
                case Hls.ErrorTypes.MEDIA_ERROR:
                  // console.error("Media error occurred, attempting recovery...");

                  handlePause();
                  break;
                case Hls.ErrorTypes.NETWORK_ERROR:
                  // console.error("Network error occurred");
                  // Handle network error as desired
                  handlePause();
                  break;
                default:
                  // console.error("Unhandled error occurred");
                  // Handle other errors as desired
                  break;
              }
            }
          } else {
            handlePause();
          }
        });

        hls.on(Hls.Events.MANIFEST_PARSED, function () {
          setHslInit(true);
        });

        hls.on(Hls.Events.LEVEL_SWITCHED, function (event, data) {
          setCurrentQualityLevel(data.level);
        });

        hls.loadSource(props.playBackUrl);
        hls.attachMedia(videoRef.current);
      }
    } else {
      // console.error(`🚀 > attachHls > videoref not available`);
    }
  }

  function destroyHls() {
    try {
      if (hls) {
        hls.removeAllListeners();
        hls.destroy();
        setHslInit(false);
        hls = undefined;
      }
    } catch (error) {
      // console.error(`🚀 > destroyHls > error:`, error);
    }
  }
  const userInteracted = () => {
    setInteractionTime(Date.now());
  };
  function attachVideoListeners() {
    if (videoRef.current) {
      videoRef.current.addEventListener("volumechange", handleVideoVolume);
      videoRef.current.addEventListener("pause", handlePause);
      videoRef.current.addEventListener("stalled", handleVideoPlaying);
      window.addEventListener("offline", handlePause);
      // videoRef.current.addEventListener("progress", handleVideoBuffering);
      videoRef.current.addEventListener("playing", handleVideoPlaying);
      videoRef.current.addEventListener("waiting", handleVideoBuffering);
      videoRef.current.addEventListener("timeupdate", handleVideoTime);
    }
  }

  function removeVideoListeners() {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = "";
      videoRef.current.removeEventListener("volumechange", handleVideoVolume);
      videoRef.current.removeEventListener("pause", handlePause);
      videoRef.current.removeEventListener("stalled", handleVideoPlaying);
      window.removeEventListener("offline", handlePause);
      // videoRef.current.removeEventListener("progress", handleVideoBuffering);
      videoRef.current.removeEventListener("playing", handleVideoPlaying);
      videoRef.current.removeEventListener("waiting", handleVideoBuffering);
      videoRef.current.removeEventListener("timeupdate", handleVideoTime);
    }
  }
  const handleLoopValue = (bool: boolean) => {
    userInteracted();

    setState((prv) => ({ ...prv, loop: bool }));
  };

  function attachVideoListenersSafari() {
    if (videoRef.current) {
      videoRef.current.addEventListener("error", handleVideoError);
      videoRef.current.addEventListener("abort", handleVideoError);
    }
  }

  function removeVideoListenersSafari() {
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.src = "";
      videoRef.current.removeEventListener("error", handleVideoError);
      videoRef.current.removeEventListener("abort", handleVideoError);
    }
  }

  const handleResume = () => {
    setState((prv) => ({ ...prv, playing: true }));
  };

  const handleCompletionSummary = (message: string) => {
    userInteracted();

    setSuccess(true);
    setToastMessage(message);

    setTimeout(() => {
      setSuccess(false);
      setToastMessage("");
    }, 2000);
  };

  useEffect(() => {
    if (completion == 1) {
      if (!state.loop) {
        setState((prv) => ({ ...prv, playing: false }));
      }
      openCompletionPopup(true);
      // mixpanel.triggerMediaSessionCompletedEvent(
      //   props.mediaData,
      //   props.mediaData.type === "audio" ? "audio" : "video"
      // );
      meditationSession.end();
    }
  }, [completion]);

  const handlePause = () => {
    setState((prv) => ({ ...prv, playing: false }));
  };

  const handleSeekMouseUp = (e: any, value: any) => {
    if (videoRef.current) {
      const percentage = value / 100;
      seekVideo(Math.floor(duration * percentage));

      setState((prv) => ({ ...prv, seeking: false, playing: true }));
    }
  };

  const handleRewind = () => {
    if (videoRef.current !== null) {
      seekVideo(videoRef.current.currentTime - 10);
    }
  };

  const handleFastForward = () => {
    if (videoRef.current !== null) {
      seekVideo(videoRef.current.currentTime + 10);
    }
  };

  function seekVideo(seekTo: number) {
    if (videoRef.current !== null) {
      mixpanelWatched(videoRef.current.currentTime);
      videoRef.current.currentTime = seekTo;
    }
  }

  function mixpanelWatched(time: number) {
    mediaPlayed.current.end = Math.floor(time);

    if (mediaPlayed.current.start !== mediaPlayed.current.end) {
      if (props.mediaType === "audio") {
        mixpanel.triggerAudioTrackPlayedEvent(
          props.mediaData,
          mediaPlayed.current.start,
          mediaPlayed.current.end
        );
      } else {
        mixpanel.triggerVideoTrackPlayedEvent(
          props.mediaData,
          mediaPlayed.current.start,
          mediaPlayed.current.end
        );
      }

      mediaPlayed.current.start = Math.floor(time);
    }
  }

  const handleVolumeChange = (e: any, value: any) => {
    const vol = parseFloat(value) / 100;
    dispatch(videoControlsActions.playerVolume(vol));
    setState((prv) => ({
      ...prv,
      volume: vol,
      muted: Number(vol) === 0 ? true : false,
    }));
  };

  const handleMute = () => {
    setState((prv) => {
      const muted = !prv.muted;
      const volume = !muted && !prv.volume ? 10 : prv.volume;

      return { ...prv, muted, volume };
    });
  };

  const handleDisplayFormat = () => {
    setTimeDisplayFormat(
      timeDisplayFormat === "normal" ? "remaining" : "normal"
    );
  };
  const toggleFullScreen = () => {
    setIsFullScreen(!isFullScreen);
  };
  const handleEsc = (event: any) => {
    if (event.keyCode === 27) {
      setIsFullScreen(false);
    }
  };
  function handleFullscreen() {
    if (videoRef.current) {
      if (document.fullscreenElement === fullscreenRef.current) {
        setIsFullScreen(true);
      } else {
        setIsFullScreen(false);
      }
    }
  }

  useEffect(() => {
    //checkHlsAutoplayable();
    checkCastingAvailable();

    window.addEventListener("keydown", handleEsc);
    document.addEventListener("fullscreenchange", handleFullscreen);

    return () => {
      //destroyCjs();
      removeVideoListeners();
      removeVideoListenersSafari();
      destroyHls();
      window.removeEventListener("keydown", handleEsc);
      document.removeEventListener("fullscreenchange", handleFullscreen);

      const isiPhone = /iPhone/i.test(navigator.userAgent);
      if (!isiPhone) {
        screenfull.exit;
      }
    };
  }, []);
  return (
    <div
      // onMouseMove={handleMouseMove}
      // onClick={handleMouseMove}
      // onMouseLeave={hanldeMouseLeave}
      ref={fullscreenRef}
      className={classes.fullScreenWrapper}
    >
      <div className="showControlsViaKeyboard" tabIndex={0} />
      <div
        className={classes.playerWrapper}
        style={{
          backgroundImage: `url(${props?.playBackThumbnail})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        {castConnected ? (
          <Box
            sx={{
              width: "100%",
              aspectRatio: "16/9",
              backgroundColor: "#0009",
              fontFamily: "'Figtree'",
              color: "white",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontSize: "large",
              zIndex: 2,
            }}
          >
            <Box paddingTop="4rem">Casting to {casting.device}</Box>
          </Box>
        ) : (
          <Box sx={{ zIndex: 2, position: "relative" }}>
            <video
              style={{
                width: "100%",
                aspectRatio: "16/9",
                opacity: currentTime < 1 || currentTime >= duration ? 0 : 1,
              }}
              crossOrigin="anonymous"
              ref={videoRef}
              controls={false}
              autoPlay={false}
              muted={true}
              playsInline={true}
              disablePictureInPicture={true}
            />
          </Box>
        )}
        <CircularProgress
          tabIndex={-1}
          size={50}
          sx={{
            display: state.buffer ? "initial" : "none",
            position: "absolute",
            zIndex: 100,
            color: "#0008",
            left: "calc(50% - 25px)",
            top: "calc(50% - 27px)",
          }}
        />
        <AudioControls
          castingAvailable={castingAvailable}
          castingConnected={cjs.connected}
          onCastConnect={handleCastConnect}
          controlsRef={controlsRef}
          captionsAvailable={captionsAvailable}
          captionsShowing={state.captions}
          onClosedCaptions={hanldeClosedCaptions}
          onSeekChange={handleSeekMouseUp}
          loop={state.loop}
          setLoopValue={handleLoopValue}
          onSeekMouseUp={handleSeekMouseUp}
          onRewind={handleRewind}
          onFastForward={handleFastForward}
          onPlay={handleResume}
          onPause={handlePause}
          playing={state.playing}
          elapsedTime={currentTimePretty}
          totalDuration={durationPretty}
          onMute={handleMute}
          muted={state.muted}
          buffer={state.buffer}
          onToggleFullScreen={toggleFullScreen}
          isFullScreen={isFullScreen}
          onVolumeChange={handleVolumeChange}
          onChangeDispayFormat={handleDisplayFormat}
          volume={state.volume}
          completion={completion}
          mediaData={props.mediaData}
          mediaType={props.mediaType}
          qualityLevels={qualityLevels}
          selectedQualityLevel={selectedQualityLevel}
          setSelectedQualityLevel={setSelectedQualityLevel}
          currentQualityLevel={currentQualityLevel}
          openCompletionPopup={openCompletionPopup}
          onSeek={handleSeekMouseUp}
        />
      </div>
      {mediaPlayedFromBuild === "true" ? (
        <Dialog
          open={videoCompletion}
          className="meditation-summary-popup"
          fullWidth={true}
          maxWidth={"sm"}
        >
          <MediaSummaryPopUp
            mediaData={props.mediaData}
            openCompletionPopup={openCompletionPopup}
            handleCompletion={handleCompletionSummary}
            dynamicLinkUrlResponse={props.dynamicLinkUrlResponse}
          />
        </Dialog>
      ) : null}
      <StopCastingDialog
        open={stopCastingDialog}
        deviceName={casting.device}
        handleStopCasting={handleStopCasting}
        handleKeepOnCasting={handleKeepOnCasting}
      />
    </div>
  );
};
