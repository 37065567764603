import { Grid } from "@mui/material";
import clsx from "clsx";
import { AuthBackgroundImage } from "helpers/enum/constants";
import { ReactNode } from "react";
import { useSelector } from "react-redux";
import { reduceMotionState } from "store/reduceMotionSlice";
import Page from "../pageTitle";

interface IAuthenticationLayout {
  pageTitle: string;
  children: ReactNode;
  colorChange?: string;
}

const AuthenticationLayout = ({
  pageTitle,
  children,
  colorChange,
}: IAuthenticationLayout) => {
  const reduceMotion = useSelector(reduceMotionState);

  return (
    <>
      <style>
        {/* Hide default media controls */}
        video::-webkit-media-controls{" "}
        {"display: none!important; -webkit-appearance: none;"}
        {/* Hide play/pause button specifically (might need adjustment) */}
        video::-webkit-media-controls-play-button {"display: none!important"}
      </style>
      <Page title={pageTitle} className="">
        {colorChange != "background-color-change" ? (
          <Grid
            container
            component="main"
            columns={12}
            className={clsx("gridItemContainer", colorChange)}
            sx={{ backgroundImage: `url(${AuthBackgroundImage})` }}
          >
            {!reduceMotion ? (
              <video autoPlay={true} muted loop playsInline id="myVideo">
                <source src="/assets/videos/auth.webm" />
              </video>
            ) : null}

            {children}
          </Grid>
        ) : (
          <Grid item className="background-color-change" xs={10}>
            {children}
          </Grid>
        )}
      </Page>
    </>
  );
};

export default AuthenticationLayout;
