import { ClickAwayListener, Grid, Menu, MenuItem } from "@mui/material";
import {
  VideoQualityCheckIcon,
  VideoSettingsIcon,
} from "helpers/enum/constants";
import React, { useEffect, useRef, useState } from "react";

export interface IQualityLevel {
  bitrate: number;
  height: number;
}

interface IQualitySelector {
  qualityLevels: IQualityLevel[];
  selectedQualityLevel: number;
  setSelectedQualityLevel: (n: number) => any;
  currentQualityLevel: number;
}

export const QualitySelector = (props: IQualitySelector) => {
  const [autoLabel, setAutoLabel] = useState("Auto");
  const timeoutRef = useRef<any>();
  const containerRef = useRef<any>();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleQualitySelect = (q: number) => {
    props.setSelectedQualityLevel(q);

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      handleClose();
    }, 3000);
  };

  const handleDelayClose = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    timeoutRef.current = setTimeout(() => {
      handleClose();
    }, 2000);
  };

  useEffect(() => {
    if (
      props.selectedQualityLevel == -1 &&
      props.currentQualityLevel >= 0 &&
      props.qualityLevels[props.currentQualityLevel] != undefined
    ) {
      setAutoLabel(
        `Auto (${props.qualityLevels[props.currentQualityLevel].height}p)`
      );
    } else {
      setAutoLabel("Auto");
    }
  }, [props.currentQualityLevel]);

  return props.qualityLevels.length > 0 ? (
    <span ref={containerRef} style={{ height: "20px", width: "20px" }}>
      <img
        aria-controls={open ? "quality-selector-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        id="quality-selector-button"
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        tabIndex={0}
        src={VideoSettingsIcon}
        aria-label="Quality Selector"
        title="Quality Selector"
        style={{ width: "20px", cursor: "pointer", aspectRatio: "1" }}
      />

      <Menu
        disableScrollLock={true}
        MenuListProps={{ dense: true }}
        id="quality-selector-menu"
        aria-labelledby="quality-selector-button"
        anchorEl={anchorEl}
        container={containerRef.current}
        open={open}
        onClose={handleClose}
        onMouseMove={handleDelayClose}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        sx={{
          "& .MuiPaper-root": {
            backgroundColor: "rgba(0, 0, 0, 0.6)",
          },
          "& .MuiButtonBase-root.MuiMenuItem-root": {
            color: "white",
          },
        }}
      >
        <ClickAwayListener onClickAway={handleClose}>
          <>
            <MenuItem sx={{ opacity: "1 !important" }} disabled={true}>
              Video Quality
            </MenuItem>

            <MenuItem
              selected={props.selectedQualityLevel == -1}
              onClick={() => handleQualitySelect(-1)}
              onKeyPress={() => handleQualitySelect(-1)}
              tabIndex={0}
              aria-label=""
            >
              <Grid display="flex" width="120px" justifyContent="space-between">
                <span>{autoLabel}</span>
                {props.selectedQualityLevel == -1 ? (
                  <img src={VideoQualityCheckIcon} alt="Checked" />
                ) : null}
              </Grid>
            </MenuItem>

            {props.qualityLevels.map((q, index) => (
              <MenuItem
                key={index}
                selected={props.selectedQualityLevel == index}
                onClick={() => handleQualitySelect(index)}
                onKeyPress={() => handleQualitySelect(index)}
                tabIndex={0}
              >
                <Grid
                  display="flex"
                  width="120px"
                  justifyContent="space-between"
                  tabIndex={0}
                >
                  <span tabIndex={0}>{q.height}p</span>
                  {props.selectedQualityLevel == index ? (
                    <img src={VideoQualityCheckIcon} alt="Checked" />
                  ) : null}
                </Grid>
              </MenuItem>
            ))}
          </>
        </ClickAwayListener>
      </Menu>
    </span>
  ) : (
    <></>
  );
};
