import { generateRedux } from "./slAPIReduxGenerator";
import { SLSelectors, SLThunks } from "./types";
import APIs from "./apis";

const generatedRedux = generateRedux(APIs);

export const SLSelector = generatedRedux.selectors as SLSelectors;
export const SLThunk = generatedRedux.thunks as SLThunks;
export const SLReducers: any = generatedRedux.reducers;
export const SLWhiteListedReducers: any = generatedRedux.whiteListedReducers;
