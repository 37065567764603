import { Container } from "@mui/material";
import PageTitle from "components/pageTitle/pageTitle";
import PushNotification from "components/setting/pushNotifications";
import React from "react";

const Settings: React.FC = () => {
  return (
    <>
      <PageTitle title="Settings" />
      <Container
        className="main-container"
        sx={{ width: { xl: "93.2%", lg: "95%" } }}
        maxWidth={false}
      >
        <PushNotification />
      </Container>
    </>
  );
};

export default Settings;
