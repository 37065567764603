import { Box, FormControl, Stack, TextField } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { Formik } from "formik";
import { lang } from "helpers/common/lang";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { contactUsFormDataPayload } from "utils/slRTKHelper/slAPI.models";
import * as Yup from "yup";
import { SLButton } from "../../../slButton";
import { InputStandardField } from "../../../slTextField";
import { SLVerificationMessage } from "../../../verificationMessage";
import "./index.css";

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .matches(
      /^[a-z\._\d]{1,}@[a-z0-9]{2,}\.[a-z0-9]{2,}$/,
      "Invalid email address"
    )
    .required("Fill the obligatory field, please"),
  fullName: Yup.string().required("Fill the obligatory field, please"),
  message: Yup.string()
    .required("Fill the obligatory field, please")
    .min(2, "The message is too Short.")
    .max(500, "The message is too Long."),
});

const FeedbackMessage = () => {
  const mixpanel = useMixpanel();
  const { data: profileData, loading: userProfileLoading } = useSelector(
    SLSelector.getUserProfile
  );

  const [sentSuccessfullyMessage, setSentSuccessfullyMessage] =
    useState<boolean>(false);
  const [showForm, setShowForm] = useState<boolean>(false);
  const initialValues = useRef({
    email: "",
    fullName: "",
    message: "",
  });

  useEffect(() => {
    if (!userProfileLoading) {
      if (profileData) {
        initialValues.current = {
          email: profileData.email,
          fullName: profileData.fullName,
          message: "",
        };
        setShowForm(true);
      } else {
        setShowForm(true);
      }
    }
  }, [profileData]);

  const signInForm = async (values: any) => {
    const payLoad: contactUsFormDataPayload = {
      email: values.email,
      fullName: values.fullName,
      message: values.message,
    };
    // dispatch(SLThunk.postAuthContactUs.call(payLoad));
    const makeCallObj: any = {
      method: "post",
      url: "/auth/contactUs",
      urlParams: "",
      payload: payLoad,
      params: "",
    };
    const response = await makeSLAPICall(makeCallObj);
    if (response) {
      setSentSuccessfullyMessage(true);
      mixpanel.triggerUserFeedbackEvent(
        payLoad.fullName,
        payLoad.email,
        payLoad.message
      );
    }

    setTimeout(() => {
      setSentSuccessfullyMessage(false);
    }, 2000);
  };

  const handleSubmit = async (values: any) => {
    signInForm(values);
  };
  return (
    <>
      <Box
        id="sending-feedback-component "
        className="seding-report-messege-form"
        sx={{
          background: "var(--sl-deep-purple)",
          padding: { xs: "30px 20px", sm: "30px 30px" },
          borderRadius: "10px",
          marginBottom: "15px",
        }}
      >
        <Stack>
          <SLTypography
            variant="title"
            sx={{
              color: "#FFFFFF",
              paddingBottom: "10px",
              fontSize: { xs: "14px", sm: "15px" },
            }}
            tabIndex={0}
          >
            Send Us a Message
          </SLTypography>
          <SLTypography
            variant="smallBodyCopy"
            sx={{
              color: "#FFFFFF",
              paddingBottom: { xs: "15px", sm: "20px" },
              fontSize: { xs: "12px", sm: "14px" },
            }}
            tabIndex={0}
          >
            We are here to answer any questions you may have about Soul Link.
            Reach out to us and we’ll respond as quickly as possible.
          </SLTypography>

          {/* text mail */}
          <div className="form-for-sending-mail">
            {showForm && (
              <Formik
                initialValues={initialValues.current}
                validationSchema={validationSchema}
                onSubmit={async (values, { resetForm }) => {
                  await handleSubmit(values);
                  setTimeout(() => {
                    resetForm();
                  }, 1400);
                }}
                validateOnChange={false}
                validateOnBlur={false}
              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                  values,
                  setErrors,
                }) => (
                  <>
                    <form
                      autoComplete="off"
                      onSubmit={handleSubmit}
                      // onMouseEnter={() => handleFormInitialValues(values)}
                    >
                      <InputStandardField
                        props={{
                          className: "contactFormField",
                          inputProps: {
                            "aria-label":
                              "Full name, " +
                              (touched.fullName && errors.fullName
                                ? errors.fullName
                                : ""),
                          },
                        }}
                        error={Boolean(touched.fullName && errors.fullName)}
                        onChange={(e: any) => {
                          handleChange(e);
                          setErrors({ fullName: "" });
                        }}
                        onBlur={() => {
                          handleBlur("fullName");
                        }}
                        value={values.fullName}
                        id="fullName"
                        variant="filled"
                        labelId="Full Name"
                        type="text"
                      />
                      {touched.fullName && errors.fullName ? (
                        <SLTypography
                          aria-live="assertive"
                          sx={{
                            color: "var(--errorDark)",
                            paddingBottom: "5px",
                            fontSize: "14px",
                            paddingLeft: "14px",
                          }}
                        >
                          {errors.fullName}
                        </SLTypography>
                      ) : null}
                      <InputStandardField
                        props={{
                          className: "contactFormField",
                          inputProps: {
                            "aria-label":
                              "Email, " +
                              (touched.email && errors.email
                                ? errors.email
                                : ""),
                          },
                        }}
                        onChange={(e: any) => {
                          handleChange(e);

                          setErrors({ email: "" });
                        }}
                        error={Boolean(touched.email && errors.email)}
                        onBlur={() => {
                          handleBlur("email");
                        }}
                        // helperText={touched.email && errors.email}
                        value={values.email}
                        // value={profileData?.email}
                        id="email"
                        variant="filled"
                        labelId="Email"
                        type="text"
                      />
                      {touched.email && errors.email ? (
                        <SLTypography
                          aria-live="polite"
                          sx={{
                            color: "var(--errorDark)",
                            paddingBottom: "5px",
                            fontSize: "14px",
                            paddingLeft: "14px",
                          }}
                        >
                          {errors.email}
                        </SLTypography>
                      ) : null}
                      <Box>
                        <TextField
                          inputProps={{
                            "aria-label":
                              "Message, " +
                              (touched.message && errors.message
                                ? errors.message
                                : ""),
                          }}
                          type="text"
                          id="message"
                          label="Message"
                          multiline
                          rows={5}
                          defaultValue=""
                          variant="filled"
                          className="contactFormField contact-us-message"
                          sx={{
                            width: "100%",
                            marginTop: "4px",
                            fontFamily: "Figtree",
                          }}
                          // defaultValue: "",

                          onChange={(e: any) => {
                            handleChange(e);
                            setErrors({ message: "" });
                          }}
                          error={Boolean(touched.message && errors.message)}
                          onBlur={handleBlur("message")}
                          // helperText={touched.message && errors.message}
                          value={values.message}
                        />
                        {touched.message && errors.message ? (
                          <SLTypography
                            aria-live="polite"
                            sx={{
                              color: "var(--errorDark)",
                              paddingTop: "5px",
                              fontSize: "14px",
                              paddingLeft: "14px",
                            }}
                          >
                            {errors.message}
                          </SLTypography>
                        ) : null}
                      </Box>
                      <FormControl
                        variant="standard"
                        fullWidth
                        sx={{
                          paddingTop: "14px",
                          display: "flex",
                          justifyContent: { xs: "center", sm: "flex-start" },
                          alignItems: { xs: "center", sm: "flex-start" },
                        }}
                      >
                        <SLButton
                          className="send-message-button"
                          aria-label={lang("general.sendMessage")
                            .concat(" button, ")
                            .concat(
                              Object.values(errors).length > 0
                                ? `${Object.keys(errors)[0]}: ${
                                    Object.values(errors)[0]
                                  }`
                                : ""
                            )}
                          type="submit"
                          sx={{
                            width: { xs: "180px", sm: "200px" },
                            letterSpacing: "-.3 !important",
                            maxWidth: "200px",
                          }}
                          messageId="general.sendMessage"
                        />
                      </FormControl>
                    </form>
                  </>
                )}
              </Formik>
            )}
          </div>
        </Stack>
      </Box>
      <SLVerificationMessage
        open={sentSuccessfullyMessage}
        message="Message Sent"
      />
    </>
  );
};

export default FeedbackMessage;
