import { Box, Grid, Paper, Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import FeedbackMessage from "./contact-us-form-section";
import NavigationContactUs from "./navigateLinks";

const ContactUsSection = () => {
  return (
    <>
      <Box
        className="contact-us-wrapper"
        id="contact-us"
        sx={{ color: "var(--sl-deep-purple)", minHeight: "100vh" }}
      >
        <Grid container>
          <Grid
            item
            lg={12}
            // className="grid-container-main"
          >
            <Stack>
              <SLTypography
                role="heading"
                tabIndex={0}
                variant="largeHeader"
                sx={{
                  paddingBottom: { xs: "12px", sm: "30px", md: "40px" },
                  paddingTop: { xs: "30px", sm: "0px" },
                  fontSize: { xs: "19px", md: "37px" },
                }}
              >
                Contact Us
              </SLTypography>
              <SLTypography
                variant="smallBody"
                sx={{
                  paddingBottom: { xs: "20px", md: "30px" },
                  fontSize: { xs: "14px", sm: "15px", md: "17px" },
                }}
                tabIndex={0}
              >
                Have a question, feedback, or need assistance? We're here to
                help. Reach out to our dedicated support team at Soul Link. Our
                friendly experts are ready to assist you with any inquiries or
                concerns you may have. Simply fill out the form below or contact
                us directly using the provided information. Your journey towards
                transcendence and well-being is our priority, and we're
                committed to providing you with exceptional support along the
                way.
              </SLTypography>
            </Stack>
            <FeedbackMessage />
            <NavigationContactUs />
            <Paper sx={{ boxShadow: "none", marginBottom: { xs: "20px" } }}>
              <Box>
                <Stack
                  sx={{
                    padding: "30px",
                    color: "var(--sl-deep-purple)",
                  }}
                  tabIndex={0}
                >
                  <SLTypography
                    variant="extraSmallBody"
                    sx={{ paddingBottom: "10px" }}
                  >
                    Mailing Address:
                  </SLTypography>
                  <SLTypography variant="smallBody">
                    Supernal World Creations, Inc.
                  </SLTypography>
                  <SLTypography variant="smallBody">
                    3463 Pine Ridge Rd.
                  </SLTypography>
                  <SLTypography variant="smallBody">Unit 101</SLTypography>
                  <SLTypography variant="smallBody">
                    Naples, FL 34109
                  </SLTypography>
                </Stack>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ContactUsSection;
