import { Auth } from "../authService";
import {
  AddFavouriteMediaPayload,
  AddFavouriteMeditationPayload,
  AddFavouriteResponse,
  AllMediaByType,
  AllMediaByTypePayLoad,
  CreateNotesPayload,
  CreateReminderPayload,
  DailyInspiration,
  DailyInspirationPayload,
  DeleteFavouritesMeditationPayload,
  DeleteMeditationFavourite,
  DeleteSocialLoginUserPayload,
  DeleteUserMeditationUniqueID,
  DeleteUserprofilePayload,
  FaqCategories,
  Faqs,
  FaqsPayload,
  FavouritesMediaPayload,
  ForgotPasswordRequestPayload,
  GetAllArtistPayload,
  GuestAuth,
  GuestLoginPayload,
  LoginRequestPayload,
  MediaTags,
  MeditationCompletion,
  MeditationDeleteFavouritesAuth,
  MeditationSession,
  MeditationSessionPayload,
  NotesMy,
  NotesReactions,
  PopularMediaPayload,
  ProfileImageRequestPayload,
  ProfileUpdateRequestPayload,
  RegisterRequestPayload,
  ResetPasswordRequestPayload,
  SLAPIResponse,
  SLArtist,
  SLAuth,
  SLConfigs,
  SLDeleteUser,
  SLFavouritesMedia,
  SLForgotPassword,
  SLMediaFileDetail,
  SLMeditationDetail,
  SLPaginationResponse,
  SLPopularMedia,
  SLPreferences,
  SLProfileInfo,
  SLReminder,
  SLResetPassword,
  SLUser,
  SLUserImage,
  SLUserSubscription,
  SLWebPage,
  SLWebPagePayload,
  SocialLoginFileName,
  SocialLoginPayload,
  SocialLoginRequestPayload,
  SocialLoginUserImagePayload,
  SuggestedMediaPayload,
  SupportRequest,
  SupportRequestPayload,
  UserUpdateSettingsPayload,
  changePasswordPayload,
  contactUsFormData,
  contactUsFormDataPayload,
  createCustomAchievement,
  createMeditation,
  createMeditationPayload,
  createdMeditations,
  defaultScene,
  getDailyInspiration,
  getStats,
  meditationFiles,
  notesPayload,
  reportProblemDataPayload,
  updateScene,
} from "./slAPI.models";
import { PayloadRequest, QueryRequest, Request } from "./types";

const saveAuth = (auth: SLAuth) => {
  Auth.user.login(auth, "email");
};

const loginSocial = (auth: SLAuth) => {
  Auth.user.login(auth, "social");
};

const loginGuest = (auth: GuestAuth) => {
  Auth.guest.login(auth);
};

const logout = (auth: SLUser) => {
  Auth.user.logout();
};

const storeProfile = (profile: SLUser) => {
  Auth.user.profile(profile);
};

const storeSubscription = (subscription: SLUserSubscription) => {
  Auth.user.subscription(subscription);
};

export default {
  get: {
    "/config": Request<SLConfigs>(true),
    "/user/profile": Request<SLUser>(true, storeProfile),
    "/subscription": Request<SLUserSubscription>(true, storeSubscription),
    "/users/images": QueryRequest<{ image: string }, { id: string }>(),
    "/daily-inspiration": QueryRequest<
      SLPaginationResponse<DailyInspiration>,
      DailyInspirationPayload
    >(true),
    "/faq": QueryRequest<SLPaginationResponse<Faqs>, FaqsPayload>(),
    "/faq/category": Request<FaqCategories[]>(true),
    "/artist": QueryRequest<
      SLPaginationResponse<SLArtist>,
      GetAllArtistPayload
    >(),
    "/artist/{id}": Request<SLArtist>(),
    "/media/{id}": Request<SLMediaFileDetail>(),
    "/meditation/{id}": Request<SLMeditationDetail>(),
    "/media/by-type": QueryRequest<
      SLPaginationResponse<AllMediaByType>,
      AllMediaByTypePayLoad
    >(false),
    "/admin/tag": Request<MediaTags[]>(true),
    "/filters": Request<MediaTags[]>(true),
    "/preferences": Request<SLPreferences[]>(),
    "/meditation/my-meditations": QueryRequest<
      SLPaginationResponse<AllMediaByType>,
      createdMeditations
    >(),
    "/media/popular/{type}": QueryRequest<
      // "/media/popular/{type}": QueryRequest<
      SLPaginationResponse<SLPopularMedia>,
      PopularMediaPayload
    >(),
    "/meditation/my-favourites": QueryRequest<
      SLPaginationResponse<SLFavouritesMedia>,
      FavouritesMediaPayload
    >(),
    "/meditation/suggested": QueryRequest<
      SLPaginationResponse<AllMediaByType>,
      SuggestedMediaPayload
    >(),
    "/notes/reactions": Request<NotesReactions[]>(false),
    "/notes/my": QueryRequest<SLPaginationResponse<NotesMy>, notesPayload>(),
    "/report/my": QueryRequest<
      SLPaginationResponse<SupportRequest>,
      SupportRequestPayload
    >(),
    "/daily-inspiration/get-todays-quote": Request<getDailyInspiration>(false),
    "/media/default-scene": Request<defaultScene>(false),
    "/meditation/popular": QueryRequest<
      SLPaginationResponse<AllMediaByType>,
      SuggestedMediaPayload
    >(),
    "/meditation/daily": Request<AllMediaByType>(false),
    "/stats/all": Request<getStats>(false),
    "/web-pages": QueryRequest<
      SLPaginationResponse<SLWebPage>,
      SLWebPagePayload
    >(),
    "/web-pages/{id}": Request<SLWebPage>(),
    "/reminders": Request<SLReminder[]>(),
  },
  post: {
    "/auth/login-web": PayloadRequest<SLAuth, LoginRequestPayload>(
      false,
      saveAuth
    ),
    "/auth/register": PayloadRequest<SLAuth, RegisterRequestPayload>(
      false,
      saveAuth
    ),
    "/auth/email/forgotpassword": PayloadRequest<
      SLForgotPassword,
      ForgotPasswordRequestPayload
    >(),
    "/auth/logout": Request<SLUser>(false, logout),
    "/file-upload/userimage": PayloadRequest<
      SLUserImage,
      ProfileImageRequestPayload
    >(),
    "/auth/register/social": PayloadRequest<SLAuth, SocialLoginPayload>(
      false,
      loginSocial
    ),
    "/auth/login/social": PayloadRequest<SLAuth, SocialLoginRequestPayload>(
      false,
      loginSocial
    ),
    "/auth/guest/login": PayloadRequest<GuestAuth, GuestLoginPayload>(
      false,
      loginGuest
    ),
    "/file-upload/userimage/social": PayloadRequest<
      SocialLoginFileName,
      SocialLoginUserImagePayload
    >(false),
    "/custom-achievement": PayloadRequest<
      createCustomAchievement,
      createCustomAchievement
    >(false),
    "/meditation/create": PayloadRequest<
      createMeditation,
      createMeditationPayload
    >(false),
    "/media/add-favourites": QueryRequest<
      AddFavouriteResponse,
      AddFavouriteMediaPayload
    >(),
    "/meditation/add-favourites": QueryRequest<
      AddFavouriteResponse,
      AddFavouriteMeditationPayload
    >(),
    "/notes": PayloadRequest<SLAPIResponse<string>, CreateNotesPayload>(false),
    "/auth/contactUs": PayloadRequest<
      createMeditation,
      contactUsFormDataPayload
    >(),
    "/meditation/merge/{id}": Request<createMeditation>(false),
    "/report": PayloadRequest<createMeditation, reportProblemDataPayload>(),
    "/reminders": PayloadRequest<null, CreateReminderPayload>(),
    "meditation-session": PayloadRequest<
      null | MeditationSession,
      MeditationSessionPayload
    >(),
  },
  patch: {
    "/auth/reset-password": PayloadRequest<
      SLResetPassword,
      ResetPasswordRequestPayload
    >(),
    "/user/update-profile": PayloadRequest<
      SLProfileInfo,
      ProfileUpdateRequestPayload
    >(true),
    "/user/update-settings": PayloadRequest<SLUser, UserUpdateSettingsPayload>(
      false,
      storeProfile
    ),
    "/user/change-password": PayloadRequest<SLUser, changePasswordPayload>(),
    "user/update-scene": PayloadRequest<SLUser, updateScene>(),
    "meditation/files": PayloadRequest<createMeditation, meditationFiles>(),
    "meditation/played/{id}": Request<contactUsFormData>(),
    "stats/meditation-time": Request<contactUsFormData>(),
    "stats/complete-session": Request<MeditationCompletion>(),
  },
  put: {},
  delete: {
    "/user": PayloadRequest<SLDeleteUser, DeleteUserprofilePayload>(),
    "/user/social": PayloadRequest<
      SLDeleteUser,
      DeleteSocialLoginUserPayload
    >(),
    "/meditation/favourites/{id}": Request<DeleteMeditationFavourite>(),
    "/media/favourites/{id}": Request<DeleteMeditationFavourite>(),
    "/meditation/favourites": PayloadRequest<
      MeditationDeleteFavouritesAuth,
      DeleteFavouritesMeditationPayload
    >(),
    "/meditation/cancel/{id}": PayloadRequest<
      SLUser,
      DeleteUserMeditationUniqueID
    >(),
    "/notes/{id}": Request<DeleteMeditationFavourite>(),
    "/custom-achievement/{id}": Request<MeditationDeleteFavouritesAuth>(),
    "/reminders/{id}": Request<null>(),
    "/meditation-session/{id}": Request<null>(),
  },
};
