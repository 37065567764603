import { Box, Container } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { Props } from "react-select";
import { SLDailyInspiration } from "utils/slRTKHelper/slAPI.models";
import { DailyMeditation } from "./DailyMeditation";

type TExploreSection = {
  dailyMeditation?: SLDailyInspiration;
  setGuestError?: any;
} & Props;

const ExploreSection = ({
  dailyMeditation,
  setGuestError,
}: TExploreSection) => {
  return (
    <>
      <Container
        className="main-container"
        sx={{ width: { xl: "93.2%", lg: "95%" } }}
        maxWidth={false}
      >
        <Box
          sx={{
            display: { md: "flex", xs: "block" },
            justifyContent: "space-between",
            alignItems: "start",
            paddingTop: { md: "50px", sm: "15px", xs: "8px" },
          }}
        >
          <Box sx={{ maxWidth: "500px" }}>
            <div className="myListHeading">
              <SLTypography
                role="heading"
                tabIndex={0}
                sx={{
                  fontSize: { md: "37px", sm: "30px", xs: "28px" },
                  fontWeight: "600",
                  lineHeight: { xs: "28px", lg: "43px" },
                  color: "var(--sl-deep-purple)",
                }}
              >
                Explore
              </SLTypography>
            </div>
            <SLTypography
              sx={{
                fontSize: { xl: "21px", lg: "20px", md: "17px", xs: "14px" },
                fontWeight: "500",
                lineHeight: { lg: "30px", md: "22px", xs: "18px" },
                color: "var(--sl-deep-purple)",
                paddingTop: { md: "20px", sm: "14px", xs: "10px" },
                paddingBottom: { md: "0", sm: "16px", xs: "25px" },
              }}
              tabIndex={0}
            >
              Discover a vast collection of soul-enriching content designed to
              elevate your meditation experience. Browse through an extensive
              library of carefully curated sound healing musical compilations,
              immersive earth visuals, & focus enhancing frequency waves that
              resonate with your inner self.
            </SLTypography>
          </Box>

          <DailyMeditation
            dailyMeditation={dailyMeditation}
            setGuestError={setGuestError}
          />
        </Box>
      </Container>
    </>
  );
};

export default ExploreSection;
