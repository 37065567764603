import { Box } from "@mui/material";
import axios from "axios";
import { kebabCase } from "helpers/common/changeCase";
import { useAppUrls } from "helpers/url";
import { memo, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { SLSelector } from "utils/slRTKHelper";
import { SLWebPage } from "utils/slRTKHelper/slAPI.models";
import { PageHeading } from "../../pageHeading";
import PageTitle from "../../pageTitle/pageTitle";
import { DynamicAboutPageSkeleton } from "./skeleton";

export const dynamicPageSx = {
  color: "var(--sl-deep-purple)",
  fontFamily: "Figtree !important",
  fontSize: "17px !important",
  fontWeight: "500",
  lineHeight: "22px",
  "& p,& span,& li": {
    fontFamily: "Figtree !important",
    color: "var(--sl-deep-purple) !important",
  },
  "& h1,& h2,& h3,& h4,& h1 *,& h2 *,& h3 *,& h4 *": {
    fontFamily: "Figtree !important",
    color: "var(--sl-deep-purple) !important",
  },
  "& table": {
    borderCollapse: "collapse",
  },
  "& table td,& table th": {
    border: "1px solid",
    padding: "0.5rem",
  },
};
export const DynamicAboutPageScreen = memo(() => {
  const navigate = useNavigate();
  const { id } = useParams();
  const appUrls = useAppUrls();

  const { data: config } = useSelector(SLSelector.getConfig);
  const [pageData, setPageData] = useState<SLWebPage>();
  const [pageContent, setPageContent] = useState<string>();

  useEffect(() => {
    if (id && config) {
      setPageContent("");
      const page = { title: "", url: "" };

      // if (id === kebabCase(lang("general.terms"))) {
      //   // handle hardcoded page
      //   page.title = lang("general.terms");
      //   page.url = config.termsAndCondition;
      // } else if (id === kebabCase(lang("general.privacy"))) {
      //   // handle hardcoded page
      //   page.title = lang("general.privacy");
      //   page.url = config.privacyPolicy;
      // } else {
      const found = config.webPages.find((w) => {
        if (kebabCase(w.title) == kebabCase(id)) {
          return w.id;
        }
      });

      if (found) {
        Object.assign(page, found);
      }
      // }

      if (page.url) {
        setPageData(page as any);
        getDynamicPage(appUrls.webPageUrl(page.url));
        return;
      }

      navigate("/app/home");
    }
  }, [id, config]);

  const getDynamicPage = async (url: any) => {
    const response: any = await axios.get(url);
    if (response) {
      setPageContent(response.data);
    }
  };

  return (
    <>
      <Box sx={{ minHeight: "100vh" }}>
        {pageContent ? (
          <>
            <PageTitle title={pageData?.title || ""} />
            <PageHeading>{pageData?.title}</PageHeading>

            <Box
              sx={dynamicPageSx}
              dangerouslySetInnerHTML={{ __html: pageContent || "" }}
            />
          </>
        ) : (
          <DynamicAboutPageSkeleton />
        )}
      </Box>
    </>
  );
});
