import { frequencyDefaultVolume } from "helpers/enum/constants";
import { fadeVolume } from "./fadeVolume";

export class Oscillator {
  private _defaultFrequency = 200;
  private _defaultVolume = frequencyDefaultVolume / 100;
  private _frequency = this._defaultFrequency;
  private _volume = this._defaultVolume;
  private gainNode: GainNode;
  private osc: OscillatorNode;
  private static audioContext: AudioContext;
  private static instance: Oscillator;
  private _volumeDivider = 1 / 2.5;
  private _unfadeInterval: NodeJS.Timer | undefined;
  private _fadeInterval: NodeJS.Timer | undefined;
  private _doneFadeTimeout: NodeJS.Timeout | undefined;

  constructor(frequency?: number, volume?: number) {
    const audioContext = Oscillator.getContext();

    this.osc = audioContext.createOscillator();
    this.gainNode = audioContext.createGain();
    this.osc.type = "sine";

    this.frequency(frequency === undefined ? this._frequency : frequency);
    this.volume(volume === undefined ? this._volume : volume);

    this.osc.connect(this.gainNode);
    this.gainNode.connect(audioContext.destination);
  }

  public static getInstance(frequency?: number, volume?: number): Oscillator {
    if (!Oscillator.instance) {
      Oscillator.instance = new Oscillator(frequency, volume);
    }

    return Oscillator.instance;
  }

  static getContext() {
    if (!Oscillator.audioContext) {
      Oscillator.audioContext = new AudioContext();
    }

    return Oscillator.audioContext;
  }

  getFrequency() {
    return this._frequency;
  }

  frequency(frequency?: number) {
    if (this.osc && frequency != undefined) {
      if (frequency >= 0) {
        this._frequency = frequency;

        if (this.osc.frequency.value != frequency) {
          this.osc.frequency.value = frequency;
        }
      }
    }
  }

  volume(volume?: number) {
    if (this.gainNode && volume != undefined) {
      if (volume >= 0 && volume <= 100) {
        this._volume = Math.round(volume);

        this.gainNode.gain.value = (this._volume * this._volumeDivider) / 100;
      }
    }

    this.unSuspend();

    return new Promise<boolean>((resolve) => {
      setTimeout(() => {
        resolve(!this.isSuspended());
      }, 300);
    });
  }

  isSuspended() {
    const audioContext = Oscillator.getContext();
    return audioContext.state !== "running";
  }

  unSuspend() {
    const audioContext = Oscillator.getContext();

    if (this.isSuspended()) {
      try {
        audioContext.resume();
      } catch (error) {}
      try {
        this.osc.start();
      } catch (error) {}
    }

    return this.isSuspended();
  }

  resume(volume?: number, frequency?: number) {
    this.frequency(frequency);
    this.volume(volume);

    return new Promise<boolean>((resolve) => {
      setTimeout(() => {
        resolve(!this.isSuspended());
      }, 600);
    });
  }

  volumeFadeSetter(volume: number) {
    if (this.gainNode) {
      this.gainNode.gain.value = Math.round(volume) / 100;
    }
  }

  fadeVolume() {
    const fv = fadeVolume(
      Math.round(this.gainNode.gain.value * 100),
      0,
      this.volumeFadeSetter.bind(this),
      "freq vol down"
    );
    this._fadeInterval = fv.interval;

    return fv.completed;
  }

  unfadeVolume(targetVolume: number, frequency: number) {
    const fv = fadeVolume(
      0,
      Math.round(targetVolume * this._volumeDivider),
      this.volumeFadeSetter.bind(this),
      "freq vol up"
    );
    this._unfadeInterval = fv.interval;
    setTimeout(() => {
      this.frequency(frequency);
    });

    return fv.completed;
  }

  playTone(volume: number, frequency: number) {
    this.volume(volume);
    this.frequency(frequency);
  }

  pause() {
    clearInterval(this._unfadeInterval);
    clearInterval(this._fadeInterval);
    clearTimeout(this._doneFadeTimeout);

    try {
      const audioContext = Oscillator.getContext();
      audioContext.suspend();
      this.frequency(0);
      this.volume(0);
    } catch (error) {}
  }

  // destroy() {
  //   if (Oscillator.audioContext) {
  //     this.osc.disconnect();
  //     this.gainNode.disconnect();
  //     Oscillator.audioContext.close();
  //   }
  // }
}
