import { Box, Divider } from "@mui/material";
import InfoAboutCeo from "./ceoSoulLink";
import AboutUsHead from "./head";
import "./index.css";
import CreationOfSoulLink from "./soulLinkCreation";

export const AboutUsScreen = () => {
  return (
    <>
      <Box id="about-us" sx={{ color: "var(--sl-deep-purple)" }}>
        <AboutUsHead />
        <Divider sx={{ marginTop: "50px", marginBottom: "30px" }} />
        <CreationOfSoulLink />
        <Divider sx={{ marginTop: "30px" }} />
        <InfoAboutCeo />
      </Box>
    </>
  );
};
