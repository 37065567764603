import { Avatar, Box, Stack } from "@mui/material";
import ListingPageSkeleton from "components/listingSkeleton";
import { useDragger } from "helpers/common/useDragger";
import { MediaCardType } from "helpers/enum";
import { SoulPurpleIcon } from "helpers/enum/constants";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import { meditationSlickSettings } from "utils/meditationSlickSettings";
import { SLSelector } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import MediationCard from "../../mediationCard";
import { NotFoundMessage } from "../../slTypography/NotFoundMessage";
import "./index.css";

export const Media = (props: any) => {
  const {
    addedToFavourites,
    successMessageText,
    mediaType,
    mediaUrl,
    setExploreAllButton,
    isFavourite,
    myMeditation,
    notFoundMessage,
    meditationDetailNavigate,
    lastPlayed,
    plusIcon,
  } = props;
  const sliderRef: any = useRef(null);
  const addedToFavouritesTimeout: any = useRef();
  const dragger = useDragger();
  const [pageNo, setPageNo] = useState(1);
  const [meditationData, setMeditationData] = useState<any>();
  const [customLoader, setCustomLoader] = useState(false);
  const [initialCount, setInitialCount] = useState(0);
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState<any>(false);

  const { data: unlikedMeditation } = useSelector(
    SLSelector.deleteMeditationFavouritesId
  );
  const { data: likedMeditation } = useSelector(
    SLSelector.postMeditationAddFavourites
  );
  const { data: unlikedMediaFile } = useSelector(
    SLSelector.deleteMediaFavouritesId
  );
  const { data: likedMediaFile } = useSelector(
    SLSelector.postMediaAddFavourites
  );

  const getAllMediaData = async () => {
    setLoading(true);

    // Reminder: Added text based check in sortBy temporarily will remove in sprint 5
    const params: any = {
      limit: 20,
      page: pageNo,
      sort: "DESC",
      sortBy: lastPlayed ? "lastPlayed" : "createdAt",
      isFavourite: isFavourite,
    };
    if (isFavourite && !myMeditation) {
      params.type = mediaType;
    }
    const makeCallObj: any = {
      method: "get",
      url: mediaUrl,
      urlParams:
        mediaType !== MediaCardType.MeditationCard ? { type: mediaType } : {},
      payload: {},
      params: params,
    };
    const response = await makeSLAPICall(makeCallObj);
    setData(response);
    setLoading(false);
  };
  useEffect(() => {
    getAllMediaData();
  }, []);
  useEffect(() => {
    if (pageNo > 1) {
      getAllMediaData();
    }
  }, [pageNo]);
  useEffect(() => {
    if (meditationData) {
      setCustomLoader(false);
    }
  }, [meditationData]);
  useEffect(() => {
    if (data?.data?.length > 0) {
      const newType = mediaType?.toLowerCase();
      setExploreAllButton((prevState: any) => ({
        ...prevState,
        [newType]: true,
      }));
    }
    if (data && pageNo < 2) {
      setMeditationData(data.data);
      //  dispatch(SLThunk.getMeditationMyMeditations.reset());
      setInitialCount(data.totalCount);
    } else if (data && pageNo > 1) {
      const newMeditationData = data?.data;
      setMeditationData([...meditationData, ...newMeditationData]);
    }
  }, [data]);

  useEffect(() => {
    if (isFavourite && unlikedMeditation) {
      let favCardData: any = sessionStorage.getItem("favCardData");
      favCardData = JSON.parse(favCardData);

      if (favCardData?.meditationType) {
        filterUnFavouriteMediaItem(favCardData?.id);
      }
    }
  }, [unlikedMeditation]);

  useEffect(() => {
    if (isFavourite && likedMeditation) {
      let favCardData: any = sessionStorage.getItem("favCardData");
      favCardData = JSON.parse(favCardData);

      if (favCardData?.meditationType) {
        pushFavouriteMediaItem(favCardData);
      }
    }
  }, [likedMeditation]);

  useEffect(() => {
    if (isFavourite && likedMediaFile) {
      let favCardData: any = sessionStorage.getItem("favCardData");

      if (favCardData) {
        favCardData = JSON.parse(favCardData);

        if (favCardData?.type == mediaType) {
          pushFavouriteMediaItem(favCardData);
        }
      }
    }
  }, [likedMediaFile]);

  useEffect(() => {
    if (isFavourite && unlikedMediaFile) {
      let favCardData: any = sessionStorage.getItem("favCardData");

      if (favCardData) {
        favCardData = JSON.parse(favCardData);

        if (favCardData?.type == mediaType) {
          filterUnFavouriteMediaItem(favCardData?.id);
        }
      }
    }
  }, [unlikedMediaFile]);

  const handleLikedMediaCard = (text: string) => {
    addedToFavourites(true);
    successMessageText(text);

    if (addedToFavouritesTimeout.current) {
      clearTimeout(addedToFavouritesTimeout.current);
    }

    addedToFavouritesTimeout.current = setTimeout(() => {
      addedToFavourites(false);
    }, 1500);
  };

  const filterUnFavouriteMediaItem = async (id: string) => {
    sessionStorage.removeItem("favCardData");
    setMeditationData((prv: any) => {
      return prv.filter((obj: any) => obj.id != id);
    });
  };

  const pushFavouriteMediaItem = async (data: any) => {
    sessionStorage.removeItem("favCardData");
    meditationData.unshift(data);
  };

  const nextClick = (currentSlide: any) => {
    const elementSelect = document.querySelector(".slick-next.slick-disabled");
    if (elementSelect?.matches(".slick-disabled")) {
      if (meditationData?.length < initialCount) {
        setCustomLoader(true);
        setPageNo(pageNo + 1);
      }
    }
  };
  const settings = meditationSlickSettings(nextClick);

  const evaluateCardType = () => {
    return mediaType === MediaCardType.MeditationCard
      ? MediaCardType.MeditationCard
      : MediaCardType.MediaCard;
  };
  return (
    <Box className="listScreenLoader">
      <div
        onMouseMove={dragger.preventClickWhenDragging}
        onMouseUp={dragger.detectStopDragging}
        onMouseLeave={dragger.detectStopDragging}
        className={`meditationContainer mt-6`}
      >
        {customLoader ? (
          <Box className="loaderContainer">
            <div className="loader" />
            <Avatar
              className="rotate"
              alt="Loading Image"
              src={SoulPurpleIcon}
              sx={{ width: 120, height: 120, margin: "auto" }}
            />
          </Box>
        ) : (
          ""
        )}

        {!loading || customLoader ? (
          meditationData?.length ? (
            <Slider
              ref={sliderRef}
              {...settings}
              className="meditationSlider myCreatedMeditation slickArrows"
            >
              {meditationData?.map((data: any, index: number) => (
                <MediationCard
                  key={(data.title || "").concat(data.id || "").concat(index)}
                  selectedCardData={data}
                  cardSelection="mySelectedMaditation"
                  meditationDetailNavigate={meditationDetailNavigate}
                  cardType={evaluateCardType()}
                  likedMediaCard={handleLikedMediaCard}
                  plusIcon={plusIcon}
                  dragging={dragger.isDragging}
                  // setSelectedMeditationData={selectedMeditation}
                />
              ))}
            </Slider>
          ) : (
            <NotFoundMessage notFoundMessage={notFoundMessage} height="100px" />
          )
        ) : (
          <Stack
            sx={{
              flexDirection: "row",
              gap: "15px",
              overflow: "hidden",
              marginRight: "0px",
            }}
          >
            <ListingPageSkeleton
              showSkeleton={loading}
              type="card"
              count={15}
            />
          </Stack>
        )}
      </div>
    </Box>
  );
};

export default Media;
