import SupportRequestsHistoryScreen from "components/helpCenterScreens/supportRequestsHistory";
import HelpCenterPages from "../../../layouts/helpPages";

const SupportRequestsHistory = () => {
  return (
    <HelpCenterPages title="Support Requests History">
      <SupportRequestsHistoryScreen />
    </HelpCenterPages>
  );
};

export default SupportRequestsHistory;
