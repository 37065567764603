import { Box, Dialog } from "@mui/material";
import SubscriptionDetail from "pages/subscription/subscriptionDetail";

const SubcriptionDialog = (props: any) => {
  return (
    <>
      <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box className="mainDialog-box">
          <SubscriptionDetail />
        </Box>
      </Dialog>
    </>
  );
};

export default SubcriptionDialog;
