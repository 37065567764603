import { Box } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { ArrowForwardIosIcon } from "helpers/enum/constants";

const ChangePassword = (props: any) => {
  return (
    <>
      <Box
        className="change-password-main-container"
        sx={{
          display: "flex",
          justifyContent: "space-between",
          color: "var(--sl-deep-purple)",
          background: "var( --colorWhite)",
          padding: { lg: "16px 13px", xs: "16px" },
          alignItems: "center",
          marginTop: "10px",
          borderRadius: "10px",
          cursor: "pointer",
        }}
      >
        <Box sx={{ alignItems: "center", display: "flex", gap: "10px" }}>
          {props.icon}
          <SLTypography
            variant="title"
            messageId={props.messageId}
            sx={{ fontSize: { lg: "15", md: "15px", sm: "14px", xs: "14px" } }}
          />
        </Box>
        <ArrowForwardIosIcon sx={{ fontSize: { lg: "24px", xs: "15px" } }} />
      </Box>
    </>
  );
};

export default ChangePassword;
