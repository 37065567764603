import { Box, Button, Container, Divider } from "@mui/material";
import ErrorDialogBox from "components/dialogBoxes/errorDialog";
import ExploreSection from "components/explore/exploreSection";
import ExploreHeader from "components/explore/heroHeader";
import Media from "components/explore/media";
import CardInformation from "components/mediationCard/cardInformation";
import { SLVerificationMessage } from "components/verificationMessage";
import { MediaCardType, MeditationType } from "helpers/enum";
import {
  AudioMusicIcon,
  CrossIconTutorial,
  ExploreAltHeader,
  FrequencyMusicIcon,
  SuggestedMeditationIcon,
  VideoPlayMusicIcon,
} from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useState } from "react";
import Joyride, { CallBackProps, STATUS, Step } from "react-joyride";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { useSetState } from "react-use";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import "./index.css";

interface State {
  run: boolean;
  steps: Step[];
}
const ExploreSoulLink = (data: any) => {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const { data: config } = useSelector(SLSelector.getConfig);
  const [addedToFavouriteList, setAddedToFavouriteList] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [exploreAllButton, setExploreAllButton] = useState({});
  const [dailyMeditation, setDailyMeditation] = useState<any>();
  const guestLogin = SLStorage.getItem("guestLogin");
  const { data: getPreferences, loading } = useSelector(
    SLSelector.getPreferences
  );
  const [guestErrorMessage, setGuestErrorMessage] = useState<any>("");
  const dispatch = useDispatch();
  const [guestError, setGuestError] = useState(false);
  useEffect(() => {
    dispatch(SLThunk.getPreferences.call());
  }, []);
  const {
    data: userSubscription,
    loading: subscriptionLoading,
    error: subscrioptionError,
  } = useSelector(SLSelector.getSubscription);
  //to reset the state to avoid overlapping
  useEffect(() => {
    if (getPreferences && getPreferences?.length > 0) {
      const dailyMedi = getPreferences.find(
        (obj: any) => obj.type === "dailyMeditation"
      );

      if (dailyMedi?.dailyMeditation) {
        setDailyMeditation(dailyMedi.dailyMeditation);
      }
      dispatch(SLThunk.getPreferences.reset());
    }
  }, [getPreferences]);
  useEffect(() => {
    if (guestLogin) {
      setGuestErrorMessage({
        errorHeading: "Oops! You Need to Log In",
        errorBody:
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed.",
      });
    } else {
      if (!userSubscription) {
        setGuestErrorMessage({
          errorHeading: "Oops! This is for Subscribers Only",
          errorBody:
            "Elevate your meditation practice with our premium features. Join Soul Link today to unlock personalized playlists, guided meditations, and more.",
        });
      }
    }
  }, [userSubscription]);
  const ExploreMediaTutorial = SLStorage.getItem("ExploreMediaTutorial");
  const hideTolltip = () => {
    if (!ExploreMediaTutorial) {
      SLStorage.setItem("ExploreMediaTutorial", true);
    }
    setState({ run: false });
  };
  const [{ run, steps }, setState] = useSetState<State>({
    run: true,
    steps: [
      {
        content: (
          <>
            <object
              tabIndex={0}
              aria-label="Visit the Explore page to easily discover new content and view
              everything the Soul Link app has to offer!"
            >
              <p className="bodyTextTutorial">
                Visit the Explore page to easily discover new content and view
                everything the Soul Link app has to offer!
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 1 of 5"
              >
                Walkthrough – Step 1 of 5
              </object>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} tabIndex={0} /> },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "right",
        target: ".myListHeading p",
        title: (
          <object tabIndex={0} aria-label="Explore">
            <p className="tutorialHeading">Explore</p>
          </object>
        ),
        disableBeacon: true,
      },
      {
        content: (
          <>
            <object
              aria-label="Want to see all the available files for Videos, Audios and
                Frequency Waves? You can tap Explore All"
              tabIndex={0}
            >
              <p className="bodyTextTutorial">
                Want to see all the available files for Videos, Audios and
                Frequency Waves? You can tap "Explore All".
              </p>
              <object
                className="footerTextTutorial"
                tabIndex={0}
                aria-label="Walkthrough – Step 2 of 5"
              >
                Walkthrough – Step 2 of 5
              </object>
              <Button
                type="button"
                aria-live="off"
                aria-label=""
                data-action="skip"
                role="button"
                title=""
                className="lastStepBtn"
                onClick={hideTolltip}
                onKeyPress={hideTolltip}
              >
                <img src={CrossIconTutorial} tabIndex={0} />
              </Button>
            </object>
          </>
        ),
        locale: { skip: <img src={CrossIconTutorial} />, last: "Next" },
        floaterProps: {
          disableAnimation: true,
        },
        placement: "top",
        target: ".exploreLink",
        title: (
          <object tabIndex={0} aria-label="All Files">
            <p className="tutorialHeading">All Files</p>
          </object>
        ),
        disableBeacon: true,
      },
    ],
  });

  const handleJoyrideCallback = (data: CallBackProps) => {
    const { status, type, index } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];
    const button = document.querySelector('button[data-action="skip"]');
    if (button) {
      button.setAttribute("title", "Skip tutorial");
      button.setAttribute("aria-label", "Skip tutorial");
    }
    if (index == 1 && type === "step:after") {
      navigate("/app/explore/audio");
    }
    if (finishedStatuses.includes(status)) {
      setState({ run: false });
      if (index != 1 && !ExploreMediaTutorial) {
        //  alert(true);
        SLStorage.setItem("ExploreMediaTutorial", true);
      }
    }
  };
  return (
    <>
      <ExploreHeader
        staticImage={
          appUrls.mediaImage(config?.explorePageScene?.thumbnail) ||
          ExploreAltHeader
        }
        videoSource={appUrls.mediaScene(config?.explorePageScene?.url)}
      />
      {!guestLogin && !ExploreMediaTutorial ? (
        <Joyride
          callback={handleJoyrideCallback}
          continuous
          hideCloseButton={true}
          run={run}
          scrollToFirstStep={false}
          showProgress={false}
          showSkipButton={true}
          disableOverlayClose={true}
          steps={steps}
          styles={{
            options: {
              zIndex: 10000,
            },
          }}
        />
      ) : null}
      <Box
        sx={{
          background: "var(--bg-gradient)",
          borderRadius: "20px 20px 0px 0px",
          position: "relative",
          top: "-15px",
        }}
      >
        <ExploreSection
          dailyMeditation={dailyMeditation}
          setGuestError={setGuestError}
        />

        <Container
          className="main-container"
          sx={{
            width: { xl: "93.2%", lg: "95%" },
          }}
          maxWidth={false}
        >
          <Divider
            sx={{
              background: "#CBC8CD",
              marginTop: { xs: "15px", sm: "20px", md: "40px", lg: "50px" },
              marginBottom: { xs: "20px", md: "40px", lg: "50px" },
            }}
          />
          <Box>
            <CardInformation
              suggestedMeditation={AudioMusicIcon}
              title="Audio"
              anchorText="Explore All"
              mediaType={"audio"}
              urlGenerated="audio"
              type={undefined}
              cardDiscription="  Our unique blend of ancient auditory progressions raise vibrations in
            the brain and enhance your emotional, mental, and physical
            performance."
              popularText="Popular Audio"
              exploreAllButton={exploreAllButton}
            />
            <Media
              addedToFavourites={setAddedToFavouriteList}
              successMessageText={setSuccessMessage}
              mediaType={"audio"}
              mediaUrl={"/media/popular/{type}"}
              setExploreAllButton={setExploreAllButton}
              notFoundMessage="No Audio Found"
              plusIcon={false}
              isFavourite={false}
            />
          </Box>
          <Divider
            sx={{
              background: "#CBC8CD",
              marginTop: { xs: "25px", sm: "20px", md: "40px", lg: "50px" },
              marginBottom: { xs: "10px", sm: "20px", md: "40px", lg: "50px" },
            }}
          />
          <Box>
            <CardInformation
              suggestedMeditation={VideoPlayMusicIcon}
              title="Video"
              anchorText="Explore All"
              urlGenerated="video"
              mediaType={"video"}
              type={undefined}
              cardDiscription=" Immersive imagery creates a timeless experience of calming landscapes, cosmic journeys, and cinematic escapes that transport your soul to new sceneries."
              popularText="Popular Video"
              exploreAllButton={exploreAllButton}
            />
            <Media
              addedToFavourites={setAddedToFavouriteList}
              successMessageText={setSuccessMessage}
              mediaType={"video"}
              mediaUrl={"/media/popular/{type}"}
              setExploreAllButton={setExploreAllButton}
              notFoundMessage="No Video Found"
              plusIcon={false}
              isFavourite={false}
            />
          </Box>
          <Divider
            sx={{
              background: "#CBC8CD",
              marginTop: { xs: "30px", sm: "20px", md: "40px", lg: "50px" },
              marginBottom: { xs: "25px", sm: "20px", md: "40px", lg: "50px" },
            }}
          />
          <Box>
            <CardInformation
              suggestedMeditation={FrequencyMusicIcon}
              title="Frequency Waves"
              anchorText="Explore All"
              urlGenerated="frequencyWave"
              mediaType={"frequencyWave"}
              type={undefined}
              cardDiscription="Transformative frequency files stimulate the brain and induce specific states of consciousness designed to calm anxiety, reduce stress, and improve cognition."
              popularText="Popular Frequency Waves"
              exploreAllButton={exploreAllButton}
            />
            <Media
              addedToFavourites={setAddedToFavouriteList}
              successMessageText={setSuccessMessage}
              mediaType={"frequencyWave"}
              mediaUrl={"/media/popular/{type}"}
              setExploreAllButton={setExploreAllButton}
              notFoundMessage="No Frequence Wave Found"
              isFavourite={false}
              plusIcon={false}
            />
          </Box>
          {!guestLogin && userSubscription ? (
            <>
              <Divider
                sx={{
                  background: "#CBC8CD",
                  marginTop: { xs: "25px", sm: "20px", md: "40px", lg: "50px" },
                  marginBottom: {
                    xs: "10px",
                    sm: "20px",
                    md: "40px",
                    lg: "50px",
                  },
                }}
              />
              <Box>
                <CardInformation
                  suggestedMeditation={SuggestedMeditationIcon}
                  title="Suggested Meditations"
                  mediaType={MediaCardType.MeditationCard}
                  anchorText="Show All"
                  type={undefined}
                  cardDiscription=""
                  popularText=""
                  exploreAllButton={exploreAllButton}
                  urlGenerated={MeditationType.SuggestedMeditation}
                />

                <Media
                  addedToFavourites={setAddedToFavouriteList}
                  successMessageText={setSuccessMessage}
                  mediaType={MediaCardType.MeditationCard}
                  mediaUrl={"/meditation/suggested"}
                  setExploreAllButton={setExploreAllButton}
                  notFoundMessage="No Meditation Found"
                  isFavourite={false}
                  plusIcon={false}
                />
              </Box>
            </>
          ) : null}
        </Container>
        <SLVerificationMessage
          open={addedToFavouriteList}
          sx={{ zIndex: 9999 }}
          message={successMessage}
        />
        <ErrorDialogBox
          errorDialog={guestError}
          setErrorDialog={setGuestError}
          errorMessage={guestErrorMessage.errorBody}
          errorHeaderMessage={guestErrorMessage.errorHeading}
        />
      </Box>
    </>
  );
};

export default ExploreSoulLink;
