import { Box, Button, Container, Grid } from "@mui/material";
import { SLTypography } from "components/slTypography";
import {
  ArrowDownWhite,
  DailyMeditationIcon,
  HeaderLeftArrowIcon,
  HeaderRightArrowIcon,
  SelectMeditationImage,
} from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { reduceMotionState } from "store/reduceMotionSlice";
import { Auth } from "utils/authService";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { AllMediaByType } from "utils/slRTKHelper/slAPI.models";
import { SLStorage } from "utils/slStorage";
import { HeaderBottomGradient } from "../../appHeader/HeaderBottomGradient";
import ErrorDialogBox from "../../dialogBoxes/errorDialog";
import { NotFoundMessage } from "../../slTypography/NotFoundMessage";
import { DailyInspirationQuote } from "./DailyInspirationQuote";
import "./index.css";

const HeroSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const appUrls = useAppUrls();

  const reduceMotion = useSelector(reduceMotionState);

  const guestLogin = SLStorage.getItem("guestLogin");
  const [whiteLoaderState, setWhiteLoaderState] = useState(false);

  const { data: sceneData } = useSelector(SLSelector.getMediaByType);
  const [showSceneChanger, setShowSceneChanger] = useState(false);
  const [modifiedSceneData, setModifiedSceneData] =
    useState<AllMediaByType[]>();
  const [headerBackground, setHeaderBackground] = useState("");
  const [headerBackgroundImage, setHeaderBackgroundImage] = useState("");

  const { data: profileData } = useSelector(SLSelector?.getUserProfile);
  const { data: dailyMeditationData, error: dailyMeditationError } =
    useSelector(SLSelector?.getMeditationDaily);
  const [guestError, setGuestError] = useState(false);
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [guestErrorMessage, setGuestErrorMessage] = useState<any>("");

  useEffect(() => {
    setTimeout(() => {
      dispatch(
        SLThunk.getMediaByType.call({
          type: "scene",
          scenePage: "home",
          active: true,
          page: 1,
          limit: 20,
          isFavourite: false,
        })
      );
    }, 600);
  }, []);

  useEffect(() => {
    if (guestLogin) {
      setGuestErrorMessage({
        errorHeading: "Oops! You Need to Log In",
        errorBody:
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed.",
      });
    } else {
      if (!userSubscription) {
        setGuestErrorMessage({
          errorHeading: "Oops! This is for Subscribers Only",
          errorBody:
            "Elevate your meditation practice with our premium features. Join Soul Link today to unlock personalized playlists, guided meditations, and more.",
        });
      }
    }
  }, [userSubscription]);

  useEffect(() => {
    if (dailyMeditationError) {
      dispatch(SLThunk.getMeditationDaily.reset());
      setWhiteLoaderState(false);
    }
  }, [dailyMeditationError]);

  const getDaliyMedtation = () => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
    } else {
      setWhiteLoaderState(true);
      dispatch(SLThunk.getMeditationDaily.call());
    }
  };

  useEffect(() => {
    if (dailyMeditationData) {
      setWhiteLoaderState(false);
      navigate(`/app/play-back/${dailyMeditationData?.id}`);
      dispatch(SLThunk.getMeditationDaily.reset());
    }
  }, [dailyMeditationData]);

  useEffect(() => {
    if (
      sceneData &&
      Array.isArray(sceneData.data) &&
      sceneData.data.length > 0
    ) {
      let selectedScene =
        SLStorage.getItem("sceneId") || profileData?.scene?.id;

      if (!selectedScene && sceneData?.data[0]) {
        selectedScene = sceneData?.data[0].id;
      }

      setModifiedSceneData(
        sceneData?.data?.map((obj) => ({
          ...obj,
          isSelected: Number(selectedScene) === obj.id,
        }))
      );
    }

    dispatch(SLThunk.getMediaByType.reset());
  }, [sceneData]);

  useEffect(() => {
    if (modifiedSceneData && Array.isArray(modifiedSceneData)) {
      const urlSelected = modifiedSceneData?.find((obj) => obj.isSelected);

      if (urlSelected) {
        setHeaderBackground(appUrls.mediaScene(urlSelected?.url));
        setHeaderBackgroundImage(appUrls.mediaImage(urlSelected?.image));
        modifiedSceneData.sort(
          (a, b) => Number(b.isSelected) - Number(a.isSelected)
        );
      }
    }
  }, [modifiedSceneData]);

  const handleOpenSceneChanger = () => {
    if (!showSceneChanger) {
      setTimeout(() => {
        const containerWidth = containerRef?.current?.clientWidth || 0;
        const items = Array.from(itemsRef.current?.children || []);
        let occupiedWidth = 0;
        for (let i = 0; i < items.length; i++) {
          const item = items[i] as HTMLDivElement;
          if (item.offsetWidth > 0) {
            occupiedWidth += item.offsetWidth + 20;
          }
        }
        const doesOverflow = occupiedWidth > containerWidth;
        setIsOverflowing(doesOverflow);

        if (containerRef.current) {
          containerRef.current.focus();
        }
      }, 100);
    }

    setShowSceneChanger(!showSceneChanger);
  };

  const handleSelectScene = (e: any, id: string) => {
    if (Auth.user.loggedin()) {
      dispatch(SLThunk.patchUserUpdateScene.call({ sceneId: id }));
      dispatch(SLThunk.getUserProfile.call()); // for this tab: re-call user profile to get updated scene.id
    }
    SLStorage.setItem("sceneId", id); // for other tabs: save in SLStorage

    setShowSceneChanger(false);

    if (modifiedSceneData) {
      setModifiedSceneData(
        modifiedSceneData?.map((obj) => ({
          ...obj,
          isSelected: Number(id) === obj.id,
        }))
      );
    }
  };

  return (
    <>
      <Box
        id="hero-section"
        sx={{
          height: { lg: "500px", md: "350px", xs: "350px" },
          backgroundImage: ` linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.3)),url(${headerBackgroundImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          backgroundSize: "cover",
          width: "auto",
          backgroundOrigin: "center-box",
          position: "relative",
        }}
      >
        <Box
          role="dialog"
          tabIndex={0}
          aria-label="Change Scene Dialog"
          className="backgroundVideoContainer"
          ref={containerRef}
          sx={{
            display: `${showSceneChanger ? "flex !important" : "none"}`,
            overflowX: "auto",
            width: "100%",
          }}
        >
          <SLTypography
            tabIndex={0}
            variant="smallBody"
            sx={{
              color: "white",
              zIndex: 1,
              textAlign: "center",
              cursor: "pointer",
              fontSize: { xs: "14px", md: "16px", lg: "17px" },
            }}
            onClick={handleOpenSceneChanger}
            title="Select Background Scene"
            onKeyPress={handleOpenSceneChanger}
          >
            Select Background Scene
          </SLTypography>
          <img
            role="button"
            tabIndex={0}
            aria-label="Close Dialog"
            className="arrowDownWhite"
            onClick={handleOpenSceneChanger}
            onKeyPress={handleOpenSceneChanger}
            src={ArrowDownWhite}
            width="10"
            style={{ padding: "10px" }}
          />
          <div
            className="backgroundScene"
            ref={itemsRef}
            style={{
              justifyContent: !isOverflowing ? "center" : "flex-start",
            }}
          >
            {modifiedSceneData?.length ? (
              modifiedSceneData?.map((data: any, index: number) => (
                <div
                  className={`sceneCard p-relative ${
                    data?.isSelected ? `selectedCard` : ``
                  }`}
                  key={data.id}
                >
                  <img
                    role="listitem"
                    tabIndex={0}
                    className="sceneCardImage"
                    aria-label={
                      data?.isSelected
                        ? `Selected, background scene ${index + 1} of ${
                            modifiedSceneData.length
                          }`
                        : `Background scene ${index + 1} of ${
                            modifiedSceneData.length
                          }, tap to select`
                    }
                    onClick={(e) => handleSelectScene(e, data.id)}
                    onKeyPress={(e) => handleSelectScene(e, data.id)}
                    src={appUrls.mediaImage(data.image)}
                  />
                  {data?.isSelected ? (
                    <img
                      className="sceneSelected"
                      src={SelectMeditationImage}
                    />
                  ) : null}
                </div>
              ))
            ) : (
              <NotFoundMessage
                notFoundMessage={`No Scene Found`}
                height="100px"
                color="text-white"
              />
            )}
          </div>
        </Box>

        {!reduceMotion ? (
          <video
            autoPlay={true}
            muted
            loop
            playsInline
            id="myVideo"
            src={headerBackground}
          />
        ) : null}

        <HeaderBottomGradient />

        <Container
          className="main-container p-relative"
          sx={{
            width: { xl: "93.2%", lg: "95%" },
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
          maxWidth={false}
        >
          <Grid className="main-div" container item xs={12}>
            <Box sx={{ position: "relative", width: "100%  " }}>
              <Grid item xs={12} md={12} lg={12}>
                <DailyInspirationQuote />
                <Button
                  sx={{
                    marginTop: "20px",
                    fontFamily: "Figtree",
                    marginBottom: {
                      xs: "18px",
                      sm: "20px",
                      md: "32px",
                      xl: "35px",
                    },
                    fontSize: { xs: "14px", md: "15px" },
                    fontWeight: "600",
                  }}
                  className="btnDailyMeditation"
                  onClick={getDaliyMedtation}
                  onKeyPress={getDaliyMedtation}
                >
                  <img
                    aria-hidden="true"
                    style={{ marginRight: "10px" }}
                    src={DailyMeditationIcon}
                  />
                  {!whiteLoaderState ? (
                    "Daily Meditation"
                  ) : (
                    <div id="cover-spin" />
                  )}
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  justifyContent: { xs: "start", md: "flex-end" },
                  paddingRight: { xs: "0px", md: "20px", lg: "5px" },
                  paddingLeft: { xs: "20px", lg: "0px" },
                  position: "absolute",
                  right: "0px",
                  bottom: { xs: "15.5px", sm: "15px", md: "30px" },
                }}
              >
                <Box
                  role="button"
                  tabIndex={0}
                  aria-label="Change Scene"
                  className="paper-text-change-screen"
                  onClick={handleOpenSceneChanger}
                  onKeyPress={handleOpenSceneChanger}
                  sx={{
                    width: { xs: "50px", md: "62px", lg: "70px" },
                    height: { xs: "50px", md: "62px", lg: "70px" },
                    borderRadius: "50%",
                    display: "flex",
                    flexDirection: "column",
                    color: "#FFFFFF",
                    background: "rgba(0, 0, 0, 0.5)",
                    backdropFilter: "blur(-10px)",
                    cursor: "pointer",
                    boxShadow: "none",
                  }}
                >
                  <Box
                    component="img"
                    src={HeaderRightArrowIcon}
                    sx={{
                      width: { xs: "10px", md: "12px" },
                      paddingTop: { xs: "6px", md: "0px", xl: " 0px" },
                      paddingBottom: { xs: "0px", xl: " 0px" },
                    }}
                  />
                  <SLTypography
                    variant="extraSmallBody"
                    sx={{
                      marginTop: { xs: "0px", sm: "4px" },
                      marginBottom: { xs: "0px", sm: "4px" },
                      lineHeight: "14px",
                      fontSize: { xs: "10px", md: "12px" },
                    }}
                  >
                    Change Scene
                  </SLTypography>
                  <Box
                    component="img"
                    src={HeaderLeftArrowIcon}
                    sx={{
                      width: { xs: "10px", md: "12px" },
                      paddingBottom: { xs: "6px", md: "0px", xl: " 0px" },
                    }}
                  />
                </Box>
              </Grid>
            </Box>
          </Grid>
        </Container>
      </Box>
      <ErrorDialogBox
        errorDialog={guestError}
        setErrorDialog={setGuestError}
        errorMessage={guestErrorMessage.errorBody}
        errorHeaderMessage={guestErrorMessage.errorHeading}
      />
    </>
  );
};

export default HeroSection;
