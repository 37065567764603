import FaqScreen from "components/helpCenterScreens/faq";
import HelpCenterPages from "../../../layouts/helpPages";

const FAQ = () => {
  return (
    <HelpCenterPages title="FAQ">
      <FaqScreen />
    </HelpCenterPages>
  );
};

export default FAQ;
