import { IconButton } from "@mui/material";
import { MediaCardType } from "helpers/enum";
import { EmptyHeart, FillHeart } from "helpers/enum/constants";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import "./index.css";

interface IFavouriteMediaIcon {
  cardData: any;
  cardId: number;
  cardType: string;
  likedMediaCard?: any;
  emptyHeartClass?: string;
  fillHeartClass?: string;
  setGuestError?: any;
}

const FavouriteMediaIcon = ({
  cardData,
  cardId,
  cardType,
  emptyHeartClass,
  likedMediaCard,
  fillHeartClass,
  setGuestError,
}: IFavouriteMediaIcon) => {
  const dispatch = useDispatch();
  const addMediaFavourite = useSelector(SLSelector.postMediaAddFavourites);
  const addMeditationFavourite = useSelector(
    SLSelector.postMeditationAddFavourites
  );
  const guestLogin = SLStorage.getItem("guestLogin");
  const deleteMediaFavourite = useSelector(SLSelector.deleteMediaFavouritesId);
  const deleteMeditationFavourite = useSelector(
    SLSelector.deleteMeditationFavouritesId
  );
  const { data: userSubscription } = useSelector(SLSelector.getSubscription);
  const [liked, setLiked] = useState<boolean>(cardData.isLikedByMe);

  useEffect(() => {
    if (addMediaFavourite?.data) {
      likedMediaCard("Added to My Favorites");
      dispatch(SLThunk.postMediaAddFavourites.reset());
    }
  }, [addMediaFavourite]);

  useEffect(() => {
    if (addMeditationFavourite?.data) {
      if (
        cardData?.meditationType &&
        addMeditationFavourite?.data?.meditationId &&
        cardData?.id == addMeditationFavourite.data?.meditationId
      ) {
        setLiked(true);

        try {
          cardData.isLikedByMe = true;
        } catch (error) {}
      }

      likedMediaCard("Added to My Favorites");
      dispatch(SLThunk.postMeditationAddFavourites.reset());
    }
  }, [addMeditationFavourite]);

  useEffect(() => {
    if (deleteMediaFavourite?.data) {
      likedMediaCard("Removed from My Favorites");
      dispatch(SLThunk.deleteMediaFavouritesId.reset());
    }
  }, [deleteMediaFavourite]);

  useEffect(() => {
    if (deleteMeditationFavourite?.data) {
      if (
        cardData?.meditationType &&
        deleteMeditationFavourite?.data?.meditationId &&
        cardData?.id == deleteMeditationFavourite.data?.meditationId
      ) {
        setLiked(false);

        try {
          cardData.isLikedByMe = false;
        } catch (error) {}
      }

      likedMediaCard("Removed from My Favorites");
      dispatch(SLThunk.deleteMeditationFavouritesId.reset());
    }
  }, [deleteMeditationFavourite]);

  // Manage Functions
  const favouriteItem = (
    event: React.MouseEvent<HTMLDivElement>,
    likeStatus: boolean
  ) => {
    event.stopPropagation();
    event.preventDefault();

    setLiked(likeStatus);

    try {
      cardData.isLikedByMe = likeStatus;
    } catch (error) {}

    if (likeStatus) {
      if (cardType === MediaCardType.MediaCard) {
        dispatch(
          SLThunk.postMediaAddFavourites.call({
            mediaId: cardId,
          })
        );
      } else {
        dispatch(
          SLThunk.postMeditationAddFavourites.call({
            meditationId: cardId,
          })
        );
      }
    } else {
      if (cardType === MediaCardType.MediaCard) {
        dispatch(
          SLThunk.deleteMediaFavouritesId.call({
            id: cardId.toString(),
          })
        );
      } else {
        dispatch(
          SLThunk.deleteMeditationFavouritesId.call({
            id: cardId.toString(),
          })
        );
      }
    }
  };

  const handleFavouriteMedia = (e: any, isLiked: boolean, id: number) => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return false;
    }
    favouriteItem(e, isLiked);

    if (isLiked) {
      likedMediaCard("Added to My Favorites");
    } else {
      likedMediaCard("Removed from My Favorites");
    }

    sessionStorage.setItem("favCardData", JSON.stringify(cardData));
  };

  return (
    <IconButton
      type="button"
      tabIndex={0}
      title={`Favourite button for ${cardData.title}`}
      aria-pressed={liked ? "true" : "false"}
      onClick={(e: any) => {
        e.stopPropagation();
        e.preventDefault();
        handleFavouriteMedia(e, !liked, cardId);
      }}
      onKeyPress={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.key === "Enter") {
          handleFavouriteMedia(e, !liked, cardId);
        }
      }}
    >
      {liked ? (
        <FillHeart className={fillHeartClass} />
      ) : (
        <EmptyHeart className={emptyHeartClass} />
      )}
    </IconButton>
  );
};

export default FavouriteMediaIcon;
