import { Box, IconButton, Stack, Typography } from "@mui/material";
import SLDialog from "components/dialogBoxes/slDialog";
import { useDialog } from "components/dialogBoxes/slDialog/useDialog";
import { SLTypography } from "components/slTypography";
import { lang } from "helpers/common/lang";
import { CardSelection, MediaCardType, MediaType } from "helpers/enum";
import { AddSvgIcon, LockIcon, SelectMediation } from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { SLSelector } from "utils/slRTKHelper";
import { SLStorage } from "utils/slStorage";
import ErrorDialogBox from "../dialogBoxes/errorDialog";
import FavouriteMediaIcon from "../favouriteMedia";
import "./index.css";

interface MediationProps {
  selectedCardData: any;
  onSelectionChanged?: any;
  addedToFavouriteMessage?: any;
  cardType?: string;
  likedMediaCard?: any;
  cardSelection?: string;
  filterUnFavouriteMediaItem?: any;
  setSelectedMeditationData?: any;
  updatedMedia?: any;
  setInitialMeditationCounter?: any;
  initialMeditationCounter?: boolean;
  setCardExistingData?: any;
  cardExistingData?: string;
  cardSelected?: string;
  meditationDetailNavigate?: boolean;
  meditationPlayerNavigate?: boolean;
  plusIcon?: boolean;
  dragging?: React.MutableRefObject<boolean>;
}
const MediationCard = ({
  selectedCardData,
  cardType,
  onSelectionChanged,
  likedMediaCard,
  cardSelection,
  updatedMedia,
  setSelectedMeditationData,
  setInitialMeditationCounter,
  initialMeditationCounter,
  setCardExistingData,
  cardExistingData,
  cardSelected,
  meditationDetailNavigate,
  meditationPlayerNavigate,
  plusIcon,
  dragging,
  ...props
}: MediationProps) => {
  const navigate = useNavigate();
  const appUrls = useAppUrls();
  const guestLogin = SLStorage.getItem("guestLogin");

  const { data: userSubscription } = useSelector(SLSelector.getSubscription);

  const [mediaCardImage, setMediaCardImage] = useState("");
  const [mediaCardType, setMediaCardType] = useState("");
  const [confirmDataChange, setconfirmDataChange] = useState<any>("");
  const confirmChangeDialog = useDialog({
    title: lang("meditation.creator.confirmChange.title"),
  });
  const [newMeditationId, setNewMeditationId] = useState("");
  const [guestError, setGuestError] = useState(false);
  const [guestErrorMessage, setGuestErrorMessage] = useState<any>("");

  useEffect(() => {
    if (selectedCardData) {
      setMediaCardImage(appUrls.mediaImage(selectedCardData.image));
    }
  }, [selectedCardData]);

  useEffect(() => {
    if (cardType) {
      setMediaCardType(cardType);
    }
  }, [cardType]);

  const handleSelectedMedia = (
    e: any,
    id: string,
    clickSource?: "image" | "title"
  ) => {
    if (dragging && dragging.current === true) {
      return false;
    }

    if (e.target.classList.contains("MuiSvgIcon-root")) {
      return false;
    }

    const pathName = location.pathname;

    if (meditationPlayerNavigate) {
      if (!selectedCardData?.locked) {
        navigate(`/app/play-back/${id}`);
      }
    } else if (
      id &&
      clickSource === "title" &&
      (selectedCardData?.type === MediaType.Audio ||
        selectedCardData?.type === MediaType.Video ||
        selectedCardData?.type === MediaType.FrequencyWave) &&
      [
        "/app/home",
        "/app/meditation/create",
        "/app/meditation-details/[0-9]+",
      ].some((url) => pathName.match(`^${url}\$`))
    ) {
      SLStorage.setItem("previousURLStore", window.location.pathname);
      navigate(`/app/file-detail/${id}`);
    } else if (
      [
        "/app/my-list",
        "/app/explore",
        "/app/my-list/meditation/recent-meditation",
      ].some((url) => pathName.match(`^${url}\$`))
    ) {
      if (selectedCardData.locked) {
        setGuestError(true);
        return false;
      }
      if (cardType === MediaCardType.MediaCard) {
        SLStorage.setItem("previousURLStore", "/app/meditation/create");
        navigate(`/app/file-detail/${selectedCardData.id}`);
      } else if (cardType === MediaCardType.MeditationCard) {
        navigate(`/app/meditation-details/${selectedCardData.id}`);
      }
    } else {
      if (selectedCardData.locked) {
        setGuestError(true);
        return false;
      }
      if (meditationDetailNavigate) {
        navigate("/app/meditation-details/" + selectedCardData.id);
      } else if (CardSelection.CreateMeditation === cardSelection) {
        onSelectionChanged(id, mediaCardImage, selectedCardData);
      } else if (CardSelection.Artist === cardSelection) {
        navigate("/media/detail");
      } else if (CardSelection.MySelectedMaditation === cardSelection) {
        if (cardExistingData !== id) {
          const meditationData = {
            audio: {
              audioId: selectedCardData?.audio?.id,
              title: selectedCardData?.audio?.title,
              image: selectedCardData?.audio?.image,
            },
            video: {
              videoId: selectedCardData?.video?.id,
              title: selectedCardData?.video?.title,
              image: selectedCardData?.video?.image,
            },
            frequencywave: {
              frequencywaveId: selectedCardData?.frequencyWave?.id,
              title: selectedCardData?.frequencyWave?.title,
              image: selectedCardData?.frequencyWave?.image,
            },
          };
          if (initialMeditationCounter) {
            confirmChangeDialog.show();
            setconfirmDataChange(meditationData);
            setNewMeditationId(id);
          } else if (setCardExistingData && setSelectedMeditationData) {
            setCardExistingData(id);
            setSelectedMeditationData(meditationData);
            setInitialMeditationCounter(true);
            window.scrollTo({
              behavior: "smooth",
              left: 0,
              top: 500,
            });
          }
        } else {
          window.scrollTo({
            behavior: "smooth",
            left: 0,
            top: 500,
          });
        }
      } else if (pathName.includes("/app/my-list/meditation")) {
        navigate(`/app/meditation-details/${selectedCardData.id}`);
      } else if (cardType === MediaCardType.MediaCard) {
        SLStorage.setItem("previousURLStore", "/app/meditation/create");
        navigate(`/app/file-detail/${selectedCardData.id}`);
      }
    }
  };
  const confirmDialogue = () => {
    setCardExistingData(newMeditationId);
    setSelectedMeditationData(confirmDataChange);
    confirmChangeDialog.hide();
    window.scrollTo({
      behavior: "smooth",
      left: 0,
      top: 500,
    });
  };

  useEffect(() => {
    if (guestLogin) {
      setGuestErrorMessage({
        errorHeading: "Oops! You Need to Log In",
        errorBody:
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed.",
      });
    } else {
      if (!userSubscription) {
        setGuestErrorMessage({
          errorHeading: "Oops! This is for Subscribers Only",
          errorBody:
            "Elevate your meditation practice with our premium features. Join Soul Link today to unlock personalized playlists, guided meditations, and more.",
        });
      }
    }
  }, [userSubscription]);

  return (
    <>
      <Stack
        direction="column"
        sx={{ cursor: "pointer" }}
        tabIndex={0}
        role="listitem"
        aria-pressed={selectedCardData?.isSelected}
        aria-label={`
        ${selectedCardData?.type.toLowerCase().replace("regular", "Meditation")}
        ${selectedCardData?.title}:
        ${
          selectedCardData?.locked
            ? "Media Locked: "
            : String(
                selectedCardData?.duration
                  ? " Duration " + selectedCardData?.duration + " minutes: "
                  : ""
              )
        }`}
        onClick={(e) => {
          if (e && e.stopPropagation && e.preventDefault) {
            e.stopPropagation();
            e.preventDefault();
          }
          handleSelectedMedia(e, selectedCardData?.id);
        }}
        onKeyPress={(e) => {
          e.preventDefault();
          e.stopPropagation();
          if (e.key === "Enter" || e.key === " ") {
            handleSelectedMedia(e, selectedCardData?.id);
          }
        }}
      >
        <Box
          sx={{
            backgroundColor: "#808080",
            // background: `url(${mediaCardImage}),linear-gradient(180deg, rgb(0 0 0 / 32%) 15%, rgba(0, 0, 0, 0) 100%)`,
            backgroundImage: `url(${mediaCardImage}) ,linear-gradient(180deg, rgb(0 0 0 / 26%) 100%, rgba(0, 0, 0, 0) 100%)`,
            backgroundSize: "cover",
            backgroundBlendMode: "overlay",
          }}
          className={`mediation-card  ${
            selectedCardData?.isSelected && cardSelected === "meditationDetails"
              ? "card-disabled"
              : ""
          }`}
        >
          <Box className="mediation-data">
            <Box
              className="mediation-time"
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span
                aria-label={
                  selectedCardData?.duration
                    ? selectedCardData?.duration + " minutes"
                    : undefined
                }
              >
                <SLTypography
                  variant="extraSmallBody"
                  sx={{
                    color: "white",
                    visibility:
                      selectedCardData?.type === "frequencyWave"
                        ? "hidden"
                        : "",
                  }}
                >
                  {selectedCardData?.duration || "00"}:00
                </SLTypography>
              </span>
              <span style={{ marginRight: "7px" }}>
                {selectedCardData?.locked ? (
                  <img src={LockIcon} alt="Locked" aria-label="Locked" />
                ) : (
                  <FavouriteMediaIcon
                    cardData={selectedCardData}
                    cardId={selectedCardData?.id}
                    cardType={mediaCardType}
                    emptyHeartClass="empty-heart"
                    fillHeartClass="fill-heart"
                    likedMediaCard={likedMediaCard}
                    setGuestError={setGuestError}
                  />
                )}
              </span>
            </Box>
            {selectedCardData.isSelected && (
              <Box className="selected-icon">
                <img src={SelectMediation} />
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
            }}
          >
            <IconButton
              onClick={(e) => {
                if (e && e.stopPropagation && e.preventDefault) {
                  e.stopPropagation();
                  e.preventDefault();
                }
                handleSelectedMedia(e, selectedCardData?.id);
              }}
              onKeyPress={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (e.key === "Enter") {
                  e.nativeEvent.stopImmediatePropagation();
                  handleSelectedMedia(e, selectedCardData?.id);
                }
              }}
              aria-label={`${selectedCardData?.type
                .toLowerCase()
                .replace("regular", "Meditation")}
                  ${
                    selectedCardData?.isSelected ? "selected" : "not selected"
                  }`}
              aria-pressed={selectedCardData?.isSelected}
            >
              <Box
                aria-hidden="true"
                component="img"
                src={AddSvgIcon}
                sx={{
                  width: "20px",
                  height: "20px",
                  display: plusIcon ? "flex" : "none !important",
                  justifyContent: "flex-end",
                  paddingBottom: "4px",
                }}
              />
            </IconButton>
          </Box>
        </Box>
        <Box
          sx={{ paddingTop: "4px", paddingBottom: "12px", width: "150px" }}
          onClick={(e) => handleSelectedMedia(e, selectedCardData?.id, "title")}
          onKeyPress={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              handleSelectedMedia(e, selectedCardData?.id, "title");
            }
          }}
        >
          <Typography
            className="mediation-name"
            sx={{
              overflow:
                cardType === MediaCardType.MediaCard &&
                (window.location.pathname === "/app/home" ||
                  window.location.pathname === "/app/meditation/create")
                  ? "visible"
                  : "hidden",
              textOverflow: "ellipsis",
              maxHeight: "54px",
              lineHeight: "6.5",
              whiteSpace: "normal", // Allow text to wrap naturally
              wordBreak: "break-word",
              fontSize: { xs: "14px", md: "15px" },
              fontWeight: "600",
              color: "var(--sl-deep-purple) !important",
              display: "-webkit-box", // Use WebKit box model for multiline ellipsis
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 3, // Limit to 3 lines
            }}
          >
            {selectedCardData.title}
          </Typography>
        </Box>
      </Stack>

      <SLDialog
        dialog={confirmChangeDialog}
        dismissable={true}
        onDismiss={confirmChangeDialog.hide}
        actionButtons={{
          yesMessageId: "general.confirm",
          onYesButtonClicked: confirmDialogue,
          noMessageId: "general.cancel",
          onNoButtonClicked: confirmChangeDialog.hide,
        }}
      />

      <ErrorDialogBox
        errorDialog={guestError}
        setErrorDialog={setGuestError}
        errorMessage={guestErrorMessage.errorBody}
        errorHeaderMessage={guestErrorMessage.errorHeading}
      />
    </>
  );
};

export default MediationCard;
