import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Dialog,
  DialogContent,
  Stack,
} from "@mui/material";
import ListingPageSkeleton from "components/listingSkeleton";
import MeditationTags from "components/meditationTag";
import { SLTypography } from "components/slTypography";
import { snakeCase } from "helpers/common/changeCase";
import { deepLink } from "helpers/common/deeplLink";
import { MediaType } from "helpers/enum";
import {
  ChangeAudio,
  PlayCircleIcon,
  ShareIcon,
  SoulPurpleIcon,
} from "helpers/enum/constants";
import { useAppUrls } from "helpers/url";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { webShareOpen } from "store/webShareSlice";
import { webLink } from "utils/firebase";
import { getMediaSharingText } from "utils/getMediaSharingText";
import { meditationSlickSettings } from "utils/meditationSlickSettings";
import { useMixpanel } from "utils/mixpanel/useMixpanel";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLStorage } from "utils/slStorage";
import ErrorDialogBox from "../dialogBoxes/errorDialog";
import MediationCard from "../mediationCard";
import { SLButton } from "../slButton";
import { NotFoundMessage } from "../slTypography/NotFoundMessage";
import "./index.css";

const MeditationDetailsAccordian = (props: any) => {
  const mixpanel = useMixpanel();
  const appUrls = useAppUrls();
  const {
    mediatags,
    mediatype,
    duration,
    mediatitle,
    addedToFavourites,
    successMessageText,
    accordianhandleChange,
    setAccordian,
    selectedMeditationData,
    setMeditationData,
  } = props;

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const guestLogin = SLStorage.getItem("guestLogin");

  const { data: userSubscription } = useSelector(SLSelector.getSubscription);
  const { data: updatedMeditationData } = useSelector(
    SLSelector.patchMeditationFiles
  );
  const sliderRef: any = useRef(null);

  const [backgroundImageAccordian, setBackgroundImageAccordian] = useState("");
  const [chooseText, setChooseText] = useState("");
  const [guestError, setGuestError] = useState(false);
  const [changedMeditationData, setUpdatedMeditationData] = useState<any>();
  const [getAllMediaByType, setAllMediaByType] = useState<any>();
  const [tagId, setTagId] = useState<any>("all");
  const [initialCount, setInitialCount] = useState(0);
  const [data, setData] = useState<any>();
  const [customLoader, setCustomLoader] = useState(false);
  const [loadings, setLoading] = useState(false);
  const [mediaID, setmediaID] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [openDialog, setOpenDialog] = useState(false);
  const [getSelectedMedia, setSelectedMedia] = useState<any>();
  const [onMeditationSelection, setOnMeditationSelection] = useState<any>("");
  const [dynamicLinkUrlResponse, setDynamicLinkUrlResponse] =
    useState<string>("");
  const [guestErrorMessage, setGuestErrorMessage] = useState<any>("");

  useEffect(() => {
    selectMeditaionIds();
  }, []);

  const nextClick = (newindex: string) => {
    const elementSelect = document.querySelector(".slick-next.slick-disabled");
    if (elementSelect?.matches(".slick-disabled")) {
      if (getSelectedMedia?.data?.length < initialCount) {
        setCustomLoader(true);
        setPageNo(pageNo + 1);
      }
    }
  };
  const settings = meditationSlickSettings(nextClick);

  const selectMeditaionIds = async () => {
    if (
      typeof SLStorage.getItem("selectedMeditationDataDetail") !== "undefined"
    ) {
      const getSelectedMeditation = SLStorage.getItem(
        "selectedMeditationDataDetail"
      );
      const keyType = Object.keys(getSelectedMeditation)[0];
      if (mediatype.toLowerCase() === keyType) {
        setOnMeditationSelection({
          id: getSelectedMeditation[keyType].id,
          mediaCardImage: getSelectedMeditation[keyType].image,
          title: getSelectedMeditation[keyType].title,
        });
        setTimeout(() => {
          SLStorage.removeItem("selectedMeditationDataDetail");
          setOpenDialog(true);
        }, 400);
      }
    }
  };

  useEffect(() => {
    if (selectedMeditationData) {
      setUpdatedMeditationData(selectedMeditationData);
      setTimeout(() => {
        getAllMediaData();
      }, 550);
    }
  }, [selectedMeditationData]);

  useEffect(() => {
    if (changedMeditationData && changedMeditationData != null) {
      setChooseText(changedMeditationData?.[mediatype]?.title);
      setmediaID(changedMeditationData?.[mediatype]?.id);
    }
  }, [changedMeditationData]);

  useEffect(() => {
    if (mediatags && mediatags[0]?.id != "myfavorites") {
      mediatags?.unshift({ id: "myfavorites", title: "My Favorites" });
      mediatags?.unshift({ id: "all", title: "all" });
    }
  }, [mediatags]);

  useEffect(() => {
    if (updatedMeditationData?.id) {
      setMeditationData(updatedMeditationData);
      if (updatedMeditationData?.id != changedMeditationData?.id) {
        setLoading(true);
        navigate("/app/meditation-details/" + updatedMeditationData?.id);
      } else {
        setUpdatedMeditationData(updatedMeditationData);
      }
    }
  }, [updatedMeditationData]);

  useEffect(() => {
    if (tagId) {
      setLoading(true);
      setTimeout(() => {
        getAllMediaData();
      }, 550);
    }
  }, [tagId]);

  useEffect(() => {
    if (pageNo > 1) {
      getAllMediaData();
    }
  }, [pageNo]);

  const getAllMediaData = async () => {
    const params: any = {
      limit: 20,
      page: pageNo,
      sort: "ASC",
      sortBy: "title",
      type: mediatype.trim(),
      duration: duration,
      tag: tagId === "all" || tagId === "My Favorites" ? "" : tagId,
      isFavourite: tagId === "My Favorites" ? true : false,
    };
    const makeCallObj: any = {
      method: "get",
      url: "/media/by-type",
      urlParams: "",
      payload: "",
      params: params,
    };
    const response: any = await makeSLAPICall(makeCallObj);
    if (response) {
      const tagTitle = params.isFavourite ? "My Favorites" : params.tag;
      let screen: keyof typeof mixpanel.constants.SCREENS;

      if (params.type === MediaType.Audio) {
        screen = "MEDITATION_DETAIL_AUDIO";
      } else if (params.type === MediaType.Video) {
        screen = "MEDITATION_DETAIL_VIDEO";
      } else {
        screen = "MEDITATION_DETAIL_FREQUENCY_WAVE";
      }
      if (tagTitle) {
        const tagId2 =
          props?.mediatags.find((r: any) => r.title == tagId || r.id == tagId)
            ?.id || snakeCase(tagTitle);

        mixpanel.triggerTagUsedEvent(screen, tagId2, tagTitle);
      }
      setData(response);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const handleLikedMediaCard = (text: string) => {
    addedToFavourites(true);
    successMessageText(text);
    setTimeout(() => {
      addedToFavourites(false);
    }, 1500);
  };

  useEffect(() => {
    if (data) {
      if (pageNo > 1) {
        const newData = getSelectedMedia.data;
        const LoadpreData = data.data;
        const newMergedData = [...newData, ...LoadpreData];

        setAllMediaByType({
          data: newMergedData,
        });
      } else {
        setInitialCount(data.totalCount);
        setAllMediaByType({
          data: data?.data?.map((obj: any) => {
            return {
              ...obj,
              isSelected: obj.id === mediaID,
            };
          }),
        });
      }
    }
  }, [data]);

  const onTagChange = (tagID: string) => {
    setPageNo(1);
    setTagId(tagID);
  };

  const onSelectionChanged = (
    id: any,
    mediaCardImage: string,
    selectedCardData: any
  ) => {
    setOnMeditationSelection({
      id: id,
      mediaCardImage: mediaCardImage,
      title: selectedCardData?.title,
    });
    setOpenDialog(true);
  };

  const onSelectionCard = (id: any, title: string) => {
    setAllMediaByType({
      ...getSelectedMedia,
      data: getSelectedMedia?.data?.map((obj: any) => {
        return {
          ...obj,
          isSelected: obj.isSelected ? false : obj.id === id,
        };
      }),
    });
    setChooseText(title);
    setmediaID(id);
  };

  useEffect(() => {
    const sortedData = getAllMediaByType?.data?.sort(
      (a: any, b: any) => Number(b.isSelected) - Number(a.isSelected)
    );
    setSelectedMedia({ data: sortedData });

    if (pageNo > 1) {
      setCustomLoader(false);
    }
    getDeepURL();
  }, [getAllMediaByType]);

  const getDeepURL = async () => {
    const getURL = await deepLink(
      `${webLink}media?mediaId=${mediaID}&mediaType=${mediatype}&mediaTitle=${mediatitle}`
    );
    if (getURL !== undefined) {
      setDynamicLinkUrlResponse(getURL);
    }
  };

  const filterUnFavouriteMediaItem = async (id: string) => {
    if (tagId === "111") {
      setLoading(true);
      const filteredMedia = getSelectedMedia?.data?.filter(
        (obj: any) => obj.id !== id
      );
      setSelectedMedia({ data: filteredMedia });
    }
  };

  const playMedia = () => {
    if (getAllMediaByType?.data[0]?.locked) {
      setGuestError(true);
      return false;
    }
    navigate(`/app/media-player/${mediaID}`);
  };

  const closedialogue = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    if (guestLogin) {
      setGuestErrorMessage({
        errorHeading: "Oops! You Need to Log In",
        errorBody:
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed.",
      });
    } else {
      if (!userSubscription) {
        setGuestErrorMessage({
          errorHeading: "Oops! This is for Subscribers Only",
          errorBody:
            "Elevate your meditation practice with our premium features. Join Soul Link today to unlock personalized playlists, guided meditations, and more.",
        });
      }
    }
  }, [userSubscription]);

  const changeMedia = (mediatype: string) => {
    if (guestLogin || !userSubscription) {
      setGuestError(true);
      return false;
    }
    accordianhandleChange(mediatype);
  };

  const confirmSaveMeditation = () => {
    updateMeditationid("saveChanges");
  };

  const confirmSaveAsNewMeditation = () => {
    updateMeditationid("saveAsNewMeditation");
  };

  const updateMeditationid = (saveChanges: string) => {
    let isDaily = false;
    selectedMeditationData?.type != "daily"
      ? (isDaily = false)
      : (isDaily = true);
    const payload = {
      meditationId: changedMeditationData?.id,
      isDaily: isDaily,
      saveMeditationType: saveChanges,
      audioId: 0,
      videoId: 0,
      frequencyWaveId: 0,
    };
    if (mediatype === "audio") {
      payload.audioId = onMeditationSelection?.id;
      payload.videoId = changedMeditationData?.videoId;
      payload.frequencyWaveId = changedMeditationData?.frequencyWaveId;
    } else if (mediatype === "video") {
      payload.audioId = changedMeditationData?.audioId;
      payload.videoId = onMeditationSelection?.id;
      payload.frequencyWaveId = changedMeditationData?.frequencyWaveId;
    } else if (mediatype === "frequencyWave") {
      payload.audioId = changedMeditationData?.audioId;
      payload.videoId = changedMeditationData?.videoId;
      payload.frequencyWaveId = onMeditationSelection?.id;
    }
    if (saveChanges == "saveChanges") {
      onSelectionCard(onMeditationSelection?.id, onMeditationSelection?.title);
    }
    dispatch(SLThunk.patchMeditationFiles.call(payload));
    setOpenDialog(false);
  };

  return (
    <div className="mt-6">
      <Accordion
        defaultExpanded={false}
        className="custom-accordian-meditations"
        expanded={setAccordian}
      >
        <AccordionSummary
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{
            backgroundColor: "white",
            paddingLeft: "0px",
            alignItems: "start",
          }}
          tabIndex={-1}
        >
          <div className="accordianHeader">
            <div className="mediaContainer">
              <img
                src={appUrls.mediaImage(
                  changedMeditationData?.[mediatype]?.image
                )}
                alt=""
              />
            </div>
            {mediatype != "frequencyWave" ? (
              <PlayCircleIcon
                className="audio-icon"
                sx={{
                  fill: "var(--sl-purple)",
                  width: "50px",
                  height: "50px",
                  cursor: "pointer",
                }}
                tabIndex={0}
                aria-label={`Play ${mediatitle} ${chooseText}`}
                onClick={() => playMedia()}
                onKeyPress={() => playMedia()}
              />
            ) : null}
            <Box
              component="button"
              style={{ background: "transparent", border: "none" }}
              className="shareIcon"
              aria-label={`Share ${mediatitle} ${chooseText} `}
              disabled={!dynamicLinkUrlResponse}
              onClick={() => {
                dispatch(
                  webShareOpen({
                    text: getMediaSharingText(chooseText, mediatype),
                    url: dynamicLinkUrlResponse,
                  })
                );
              }}
            >
              <ShareIcon sx={{ color: "var(--sl-deep-purple)" }} />
            </Box>
            <div className="titleContainer">
              <SLTypography
                variant="subhead"
                color="text-deepPurple"
                sx={{
                  width: "95%",
                  maxHeight: "40px",
                  overflow: "hidden",
                  "-webkit-box-orient": "vertical",
                  "-webkit-line-clamp": "2",
                  display: "-webkit-box",
                }}
                tabIndex={chooseText ? 0 : -1}
              >
                {chooseText}
              </SLTypography>
              <SLTypography
                tabIndex={0}
                variant="extraSmallBody"
                color="text-deepPurple"
                sx={{ height: "20px", display: "block", marginTop: "5px" }}
              >
                {mediatitle} • {duration || "00"}:00
              </SLTypography>
            </div>
          </div>
          <div
            className="changeAudioBlock"
            onClick={() => changeMedia(mediatype)}
            onKeyPress={() => changeMedia(mediatype)}
          >
            <img src={ChangeAudio} width="12" />
            <SLTypography
              tabIndex={0}
              variant="smallBodyCopy"
              color="text-deepPurple"
              sx={{ display: "block", marginLeft: "5px" }}
              aria-label={`Change ${mediatitle},${
                !setAccordian ? "Collapsed" : "Expanded"
              }
               , click to ${!setAccordian ? "Expand" : "Collapse"}, button`}
            >
              Change {mediatitle}
            </SLTypography>
          </div>
        </AccordionSummary>
        <AccordionDetails
          sx={{ paddingTop: backgroundImageAccordian ? "15px" : "0px" }}
        >
          <div
            style={{ background: "white", borderRadius: "0px 0px 10px 10px" }}
          >
            <SLTypography
              tabIndex={0}
              variant="extraSmall"
              style={{
                maxWidth: "550px",
                display: "inline-block",
                marginBottom: "20px",
                paddingLeft: "25px",
                paddingTop: "10px",
                paddingBottom: "5px",
              }}
            >
              {mediatype === "audio" &&
                `Our unique blend of ancient auditory progressions raise vibrations
              in the brain and enhance your emotional, mental, and physical
              performance.`}
              {mediatype === "video" &&
                `Immersive imagery creates a timeless experience of calming landscapes, cosmic journeys, and cinematic escapes that transport your soul to new sceneries.`}
              {mediatype === "frequencyWave" &&
                `Transformative frequency files stimulate the brain and induce specific states of consciousness designed to calm anxiety, reduce stress, and improve cognition.`}
            </SLTypography>
          </div>

          <Box
            className="d-flex justify-space-between align-items-center p-relative tagContainer"
            sx={{
              marginBottom: { sm: "20px", md: "0px" },
              paddingLeft: "25px",
              paddingTop: "10px",
            }}
          >
            <MeditationTags
              filteredTags={props?.mediatags}
              onTagChange={onTagChange}
            />
          </Box>
          <div role="list" tabIndex={0} className="meditation-slider-container">
            {customLoader ? (
              <Box className="loaderContainer">
                <div className="loader" />
                <Avatar
                  className="rotate"
                  alt="Loading Image"
                  src={SoulPurpleIcon}
                  sx={{ width: 120, height: 120, margin: "auto" }}
                />
              </Box>
            ) : null}
            {!loadings || customLoader ? (
              getSelectedMedia?.data?.length ? (
                <Slider
                  ref={sliderRef}
                  {...settings}
                  className="meditationSlider"
                >
                  {getSelectedMedia?.data?.map((data: any, index: number) => (
                    <div key={"Cardindex_" + index}>
                      <MediationCard
                        selectedCardData={data}
                        onSelectionChanged={onSelectionChanged}
                        cardSelection="createMeditation"
                        likedMediaCard={handleLikedMediaCard}
                        filterUnFavouriteMediaItem={filterUnFavouriteMediaItem}
                        cardType="Media"
                        cardSelected="meditationDetails"
                        plusIcon={true}
                      />
                    </div>
                  ))}
                </Slider>
              ) : (
                <NotFoundMessage
                  notFoundMessage={`No ${mediatitle} Found`}
                  height="100px"
                />
              )
            ) : (
              <Stack
                sx={{
                  flexDirection: "row",
                  gap: "15px",
                  overflow: "hidden",
                  marginRight: "0px",
                }}
              >
                <ListingPageSkeleton
                  showSkeleton={loadings}
                  type="card"
                  count={15}
                />
              </Stack>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        sx={{ borderRadius: "10px" }}
      >
        <Box
          className="dialog-box-meditation"
          sx={{ background: "var(--bg-gradient)" }}
        >
          <Stack direction="column" textAlign="left" spacing={1}>
            <DialogContent
              sx={{
                fontSize: "18px",
                fontWeight: 400,
                fontStyle: "normal",
                padding: 0,
                color: "var(--sl-deep-purple)",
              }}
            >
              <SLTypography
                tabIndex={0}
                sx={{
                  fontWeight: "600",
                  fontSize: "17px",
                  maxWidth: "320px",
                }}
              >
                Select New {mediatitle} File
              </SLTypography>
            </DialogContent>
          </Stack>
          <Box
            sx={{
              marginTop: "4%",
              textAlign: "center",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              display: "flex",
            }}
          >
            {" "}
            {selectedMeditationData?.type != "daily" ? (
              <SLButton
                sx={{ width: "340px" }}
                variant="contained"
                messageId={"general.saveChanges"}
                onClick={() => confirmSaveMeditation()}
                onKeyPress={() => confirmSaveMeditation()}
              />
            ) : null}
            <SLButton
              sx={{
                width: "340px",
                marginTop: "12px",
                backgroundColor: "var(--sl-aqua) !important",
              }}
              variant="contained"
              messageId={"general.saveAsNewMeditation"}
              onClick={() => confirmSaveAsNewMeditation()}
              onKeyPress={() => confirmSaveAsNewMeditation()}
            />
            <SLTypography
              tabIndex={0}
              onKeyPress={() => setOpenDialog(false)}
              onClick={closedialogue}
              sx={{
                color: "var(--sl-purple)",
                textDecoration: "underline",
                marginTop: "3%",
                cursor: "pointer",
                fontSize: "14px",
              }}
              messageId={"general.cancel"}
            />
          </Box>
        </Box>
      </Dialog>
      {/* <ErrorDialogBox
        errorDialog={guestError}
        setErrorDialog={setGuestError}
        errorMessage={
          "It looks like you're trying to access a section that requires you to be logged in. Don't worry, we've got you covered! Simply login or create an account to proceed."
        }
        errorHeaderMessage="Oops! You Need to Log In"
      /> */}
      <ErrorDialogBox
        errorDialog={guestError}
        setErrorDialog={setGuestError}
        errorMessage={guestErrorMessage.errorBody}
        errorHeaderMessage={guestErrorMessage.errorHeading}
      />
    </div>
  );
};
export default MeditationDetailsAccordian;
