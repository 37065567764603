import HowItWorksScreen from "components/howItWorksScreen";
import PageTitle from "components/pageTitle/pageTitle";

const HowItWorks = () => {
  return (
    <>
      <PageTitle title="How to Use" />
      <HowItWorksScreen />
    </>
  );
};

export default HowItWorks;
