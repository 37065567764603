import { Stack } from "@mui/material";
import { SLTypography } from "components/slTypography";
import { onEnterOrSpace } from "helpers/common/keypress";
import { lang } from "helpers/common/lang";
import { useContext, useEffect, useState } from "react";
import { Props } from "react-intl/src/components/relative";
import { useDispatch, useSelector } from "react-redux";
import { onLogout } from "utils/authHooks";
import { deviceId, deviceInfo } from "utils/firebase";
import { SLSelector, SLThunk } from "utils/slRTKHelper";
import { makeSLAPICall } from "utils/slRTKHelper/axiosConfigs";
import { SLUser } from "utils/slRTKHelper/slAPI.models";
import { MiniplayerContext } from "../../../contexts/miniplayerContext";
import SLDialog from "../../dialogBoxes/slDialog";
import { useDialog } from "../../dialogBoxes/slDialog/useDialog";
import { SLLoader } from "../../loader";
import { SLButton } from "../../slButton";
import "./index.css";

//interface to define the type
type UserProfile = {
  getUserProfile: SLUser;
} & Props;

const LougoutButton = ({ getUserProfile }: UserProfile) => {
  const dispatch = useDispatch();
  const { closePlayer } = useContext(MiniplayerContext);

  const { data: authLogout, loading } = useSelector(SLSelector.postAuthLogout);

  const logoutDialog = useDialog({
    title: lang("general.auth.logout.confirm.title"),
  });
  const [loggingOut, setLoggingOut] = useState(false);

  useEffect(() => {
    if (authLogout) {
      onLogout(dispatch);
    }
  }, [authLogout]);

  const handleLogout = async () => {
    closePlayer();
    logoutDialog.hide();
    setLoggingOut(true);
    // dispatch(SLThunk.postAuthLogout.call());
    await makeSLAPICall({ method: "POST", url: "auth/logout" });
    dispatch(
      SLThunk.postAuthGuestLogin.call({
        platform: "web",
        deviceInfo,
        deviceId,
      })
    );
  };

  return (
    <>
      <Stack sx={{ alignItems: { sm: "flex-start", xs: "center" } }}>
        <SLTypography
          tabIndex={0}
          aria-label="logged in as"
          variant="extraSmallBody"
          messageId="profile.menu.loggedInAS"
          color="text-deepPurple"
          sx={{
            fontWeight: "500",
            fontSize: "12px !important",
            lineHeight: "13px",
            paddingBottom: "4px",
          }}
        />
        <SLTypography
          tabIndex={0}
          color="text-deepPurple"
          sx={{
            fontSize: { md: "15px", xs: "14px" },
            fontWeight: "600",
            lineHeight: "18px",
          }}
        >
          {getUserProfile?.email ?? null}
        </SLTypography>
        <SLButton
          variant="contained"
          slClass="purple"
          messageId={"profile.menu.logOut"}
          onClick={(e) => logoutDialog.show()}
          onKeyPress={onEnterOrSpace((e) => logoutDialog.show())}
          sx={{
            width: "140px",
            height: "50px",
            borderRadius: "25px",
            marginTop: "15px",
            fontSize: "15px",
            fontWeight: "600",
            lineHeight: "18px",
          }}
        />
      </Stack>

      <SLDialog
        dismissable={true}
        dialog={logoutDialog}
        actionButtons={{
          yesMessageId: "general.confirm",
          onYesButtonClicked: () => handleLogout(),
          noMessageId: "general.cancel",
          onNoButtonClicked: () => logoutDialog.hide(),
        }}
      />

      <SLLoader open={loading || loggingOut} />
    </>
  );
};

export default LougoutButton;
