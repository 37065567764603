import MoodBadIcon from "@mui/icons-material/MoodBad";
import SentimentNeutralIcon from "@mui/icons-material/SentimentNeutral";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";
import {
  Box,
  FilledInput,
  FormControl,
  InputAdornment,
  Stack,
} from "@mui/material";
import { SLTypography } from "components/slTypography";
import { SLButton } from "../../slButton";

const Notes = () => {
  return (
    <>
      <Box sx={{ padding: { lg: "", md: "24px", xs: "16px" } }}>
        <SLTypography tabIndex={0} variant="body">
          Add Notes
        </SLTypography>

        <FormControl
          sx={{ width: { lg: "86%", md: "95%", xs: "90.5%" } }}
          variant="filled"
        >
          <FilledInput
            id="filled-adornment-weight"
            // endAdornment={<InputAdornment position="end"><CopyAllIcon /></InputAdornment>}
            aria-describedby="filled-weight-helper-text"
            startAdornment={
              <InputAdornment
                position="start"
                sx={{ padding: "0px 0px 50px 20px" }}
              >
                Enter you Emotion
              </InputAdornment>
            }
            inputProps={{
              "aria-label": "weight",
            }}
          />
        </FormControl>

        <Stack
          direction={{ xs: "column", sm: "row" }}
          spacing={1}
          mt="10px"
          sx={{
            paddingLeft: { lg: "", md: "0px", xs: "3px" },
            paddingRight: { lg: "", xs: "100px" },
          }}
        >
          <SLButton
            variant="contained"
            slClass="gray"
            messageId={"notes.calm"}
          />
          <SLButton
            variant="contained"
            slClass="gray"
            messageId={"notes.concious"}
          />
          <SLButton
            variant="contained"
            slClass="gray"
            messageId={"notes.deep"}
          />
          <SLButton
            variant="contained"
            slClass="gray"
            messageId={"notes.dificult"}
          />
          <SLButton
            variant="contained"
            slClass="gray"
            messageId={"notes.exciting"}
          />
          <SLButton
            variant="contained"
            slClass="gray"
            messageId={"notes.happy"}
          />
          <SLButton
            variant="contained"
            slClass="gray"
            messageId={"notes.light"}
          />

          {/* <Button
            variant="contained"
            sx={{ background: "#ECF0F3", color: "black" }}
          >
            #calm
          </Button>
          <Button
            variant="contained"
            sx={{ background: "#ECF0F3", color: "black" }}
          >
            #concious
          </Button>
          <Button
            variant="contained"
            sx={{ background: "#ECF0F3", color: "black" }}
          >
            #deep
          </Button>
          <Button
            variant="contained"
            sx={{ background: "#ECF0F3", color: "black" }}
          >
            #dificult
          </Button>
          <Button
            variant="contained"
            sx={{ background: "#ECF0F3", color: "black" }}
          >
            #exciting
          </Button>
          <Button
            variant="contained"
            sx={{ background: "#ECF0F3", color: "black" }}
          >
            #happy
          </Button>
          <Button
            variant="contained"
            sx={{ background: "#ECF0F3", color: "black" }}
          >
            #light
          </Button> */}
        </Stack>
        <SLTypography tabIndex={0} variant="subHeading" mt="25px" pl={"3px"}>
          Meditation reaction
        </SLTypography>

        <Stack
          direction="row"
          spacing={1}
          sx={{
            color: "#697382",
            fontSize: "30px",
            marginTop: "15px",
            gap: "10px",
            paddingLeft: { lg: "", md: "0px", xs: "0px" },
          }}
        >
          <SentimentVerySatisfiedIcon sx={{ fontSize: "30px" }} />
          <SentimentSatisfiedAltIcon sx={{ fontSize: "30px" }} />
          <MoodBadIcon sx={{ fontSize: "30px" }} />
          <SentimentNeutralIcon sx={{ fontSize: "30px" }} />
          <SentimentVerySatisfiedIcon sx={{ fontSize: "30px" }} />
          <SentimentSatisfiedAltIcon sx={{ fontSize: "30px" }} />
          <MoodBadIcon sx={{ fontSize: "30px" }} />
        </Stack>
        <Stack spacing={1} direction={{ xs: "row", sm: "row" }} mt="30px">
          <SLButton
            variant="text"
            slClass="none"
            messageId={"general.cancel"}
            fullWidth
          />
          <SLButton variant="contained" messageId={"general.add"} fullWidth />
          {/* <Button
            variant="text"
            sx={{ width: { lg: "28%", md: "28%", xs: "28%" }, height: "64px" }}
          >
            Cancel
          </Button> */}
          {/* <Button
            variant="outlined"
            sx={{ width: { lg: "28%", md: "28%", xs: "38%" }, height: "64px" }}
          >
            Meditation Detail
          </Button>
          <Button
            variant="contained"
            sx={{ width: { lg: "28%", md: "28%", xs: "38     %" } }}
          >
            Outlined
          </Button> */}
        </Stack>
      </Box>
    </>
  );
};

export default Notes;
